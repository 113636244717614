/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unused-class-component-methods */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { Col, Row, } from 'reactstrap';

import { useAuthSlice } from '../slice';

import bg1 from '../../../assets/utils/images/originals/wedding-image-1.jpg';
import bg2 from '../../../assets/utils/images/originals/wedding-image-2.jpg';
import bg3 from '../../../assets/utils/images/originals/wedding-image-3.jpg';

function Login() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // this.submitLoginForm = this.identityLogin.bind(this);
  const { actions } = useAuthSlice();
  
  // const submit = (e) => {
  //   const that = this;
  //   e.preventDefault();   
  //   this.setState({ loading: true });
  //   auth.login(() => {
  //     that.props.history.push('/dashboards/crm'); 
  //   })
  // }

  // const handleChange = (event) => {
  //   this.setState({ [event.target.name]: event.target.value });
  // }

  const identityLogin = () => {
    setLoading(true);
    dispatch(actions.signInRedirect());
  };
 
  return ( 
    <div className="h-100">
      <Row className="h-100 no-gutters">
        <Col lg="4" className="d-none d-lg-block">
          <div className="slider-light">
            <Slider { ...settings }>
              <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                <div
                  className="slide-img-bg"
                  style={ {
                    backgroundImage: `url(${bg1})`,
                  } }
                />
                <div className="slider-content">
                  <h3>100% NO FEES </h3>
                  <p>
                    Our services are truly free. When resorts compensate us for bookings, we invest in a better experience for customers, while listening to feedback.
                  </p>
                </div>
              </div>
              <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                <div
                  className="slide-img-bg"
                  style={ {
                    backgroundImage: `url(${bg3})`,
                  } }
                />
                <div className="slider-content">
                  <h3>Low Deposits, Payment Plans</h3>
                  <p>
                    We work with hundreds of resorts in Mexico and the Caribbean that specialize in affordable destination weddings.
                  </p>
                </div>
              </div>
              <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                <div
                  className="slide-img-bg opacity-6"
                  style={ {
                    backgroundImage: `url(${bg2})`,
                  } }
                />
                <div className="slider-content">
                  <h3>Lowest Rates Guaranteed</h3>
                  <p>
                    We’re able to provide the lowest group rates in the industry for your travel and room accommodations, backed with a 100% price-match guarantee.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </Col>
        <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
          <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                      
            <div className="app-logo">
              <svg width="127" height="36" viewBox="0 0 127 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_28_112)">
                  <path d="M28.8863 1.45654L31.1881 2.70222L32.358 4.95401V5.8739L28.3917 7.67533L26.4893 8.18319L26.3086 7.13874L27.3073 3.60294L27.488 2.18478L28.8863 1.45654Z" fill="#E3F3F5" />
                  <path d="M34.2707 5.4618L31.2364 2.75007L28.1356 0L26.1191 0.335374L26.3379 7.34948C23.5605 5.02103 20.0031 3.62204 16.1224 3.60287C7.26699 3.57413 0.0285949 10.8182 5.98451e-05 19.7487C-0.0189635 26.7245 4.49909 33.0008 11.0431 35.1855C9.09323 34.4573 6.15412 32.033 4.81297 30.3082C2.43505 27.2515 1.62656 23.6487 1.64558 19.7583C1.65509 15.8776 3.17696 12.2268 5.90682 9.48629C8.64618 6.74581 12.2796 5.251 16.1319 5.26058C19.9841 5.27016 23.6081 6.8033 26.3284 9.55337C29.0487 12.313 30.5326 15.9734 30.5231 19.8541C30.5135 23.7349 29.0012 27.3857 26.2618 30.1262C23.8268 32.56 20.6119 34.0453 17.2067 34.304L16.8548 34.3327L16.9309 9.99415C22.4476 10.9619 26.7564 15.8201 26.7374 21.7897C26.7374 22.815 26.4901 23.9265 26.2523 24.8847C26.7469 23.5528 27.0228 22.1155 27.0228 20.6111C27.0418 14.4977 22.6759 9.22758 16.9404 8.64307C16.5314 8.60474 15.3044 8.53766 15.3044 8.53766L15.2188 35.9808C15.5231 36 15.7895 36.0096 16.0368 36.0096C24.9017 36.0383 32.14 28.7943 32.1686 19.8637C32.1876 15.2739 30.2853 11.1248 27.232 8.16396L34.1946 7.53154L34.2802 5.48097L34.2707 5.4618ZM26.7945 7.75193L28.2783 2.2518L29.7336 2.50093L31.1889 2.75007L31.6074 4.1778L32.0259 5.60554L26.785 7.76151L26.7945 7.75193Z" fill="url(#paint0_linear_28_112)" />
                  <path d="M48.6998 15.226C48.909 15.226 49.0802 15.1685 49.1944 15.0631C49.318 14.9481 49.3846 14.7852 49.3846 14.5648C49.3846 14.3636 49.318 14.2103 49.1944 14.0953C49.0707 13.9803 48.909 13.9324 48.6998 13.9324H38.1798C37.9325 13.9324 37.7328 13.9994 37.5901 14.1432C37.4474 14.2869 37.3809 14.4786 37.3809 14.7373V32.8091C37.3809 33.0583 37.4474 33.2595 37.5901 33.3936C37.7328 33.5374 37.923 33.6044 38.1798 33.6044H48.6998C48.909 33.6044 49.0802 33.547 49.1944 33.4416C49.318 33.3266 49.3846 33.1637 49.3846 32.9433C49.3846 32.7421 49.318 32.5887 49.1944 32.4738C49.0707 32.3588 48.909 32.3109 48.6998 32.3109H38.7601V24.2811H48.1481C48.3573 24.2811 48.5286 24.2236 48.6427 24.1182C48.7664 24.0032 48.8329 23.8403 48.8329 23.6199C48.8329 23.4187 48.7664 23.2654 48.6427 23.1504C48.519 23.0354 48.3573 22.9779 48.1481 22.9779H38.7601V15.226H48.6998Z" fill="#15BDC7" />
                  <path d="M61.2072 23.639L58.4964 23.0833C57.1362 22.815 56.1375 22.3742 55.5383 21.7801C54.939 21.186 54.6442 20.362 54.6442 19.3367C54.6442 18.4839 54.8629 17.7173 55.3005 17.0849C55.738 16.4429 56.3468 15.9446 57.1267 15.5901C57.9162 15.2355 58.8483 15.0631 59.9041 15.0631C60.9599 15.0631 61.9111 15.2259 62.7672 15.5422C63.6232 15.8584 64.4507 16.3662 65.2212 17.0466C65.516 17.3436 65.8585 17.3148 66.0677 17.152C66.1628 17.0753 66.2389 16.9699 66.296 16.8262C66.3531 16.6824 66.3531 16.5291 66.315 16.3662C66.2675 16.2033 66.1723 16.0596 66.0392 15.9446C65.2117 15.1876 64.2795 14.6127 63.2618 14.2486C62.2535 13.8845 61.1216 13.7024 59.9137 13.7024C58.934 13.7024 58.0303 13.8365 57.2218 14.1048C56.4134 14.3731 55.7 14.766 55.1007 15.2547C54.5015 15.753 54.0354 16.3471 53.7215 17.0274C53.3982 17.7077 53.2269 18.4839 53.2269 19.3271C53.2269 20.7357 53.6645 21.8664 54.5205 22.6904C55.3671 23.5145 56.6797 24.1086 58.4108 24.4535L61.1216 25.0093C62.634 25.3159 63.7278 25.7471 64.3841 26.2933C65.0309 26.8299 65.3543 27.5869 65.3543 28.5355C65.3543 29.7045 64.9073 30.6627 64.0132 31.3718C63.1191 32.0905 61.7494 32.4546 59.9612 32.4546C58.6676 32.4546 57.5262 32.3013 56.5846 31.9946C55.6429 31.688 54.7393 31.1897 53.9023 30.5286C53.6074 30.2794 53.2745 30.289 53.0462 30.4711C52.9416 30.5573 52.856 30.6723 52.8084 30.8065C52.7514 30.9502 52.7514 31.1035 52.8084 31.2568C52.856 31.4006 52.9606 31.5443 53.1033 31.6689C53.674 32.1288 54.3208 32.5217 55.0342 32.8379C55.738 33.1541 56.518 33.3936 57.3455 33.5661C58.173 33.729 59.0481 33.8152 59.9707 33.8152C61.017 33.8152 61.9682 33.6907 62.8052 33.4415C63.6422 33.1924 64.3651 32.8283 64.9548 32.3588C65.5351 31.8892 66.0011 31.3239 66.315 30.6819C66.6289 30.0303 66.7906 29.3117 66.7906 28.5259C66.7906 27.194 66.3245 26.1304 65.4114 25.3638C64.5078 24.6164 63.1001 24.0319 61.2263 23.6486L61.2072 23.639Z" fill="#15BDC7" />
                  <path d="M83.9123 13.9227H68.9028C68.6936 13.9227 68.5224 13.9802 68.3892 14.0856C68.256 14.2006 68.1895 14.3539 68.1895 14.5551C68.1895 14.7564 68.256 14.9193 68.3892 15.0343C68.5224 15.1588 68.6936 15.2163 68.9028 15.2163H75.7227V33.0391C75.7227 33.2499 75.7798 33.4223 75.9034 33.5565C76.0271 33.6906 76.1983 33.7577 76.4075 33.7577C76.6453 33.7577 76.8165 33.6906 76.9307 33.5565C77.0353 33.4223 77.0924 33.2499 77.0924 33.0391V15.2259H83.9123C84.1405 15.2259 84.3213 15.1684 84.4354 15.063C84.559 14.948 84.6256 14.7851 84.6256 14.5647C84.6256 14.3635 84.559 14.2102 84.4354 14.0952C84.3117 13.9802 84.1405 13.9323 83.9123 13.9323V13.9227Z" fill="#15BDC7" />
                  <path d="M88.8001 13.7599C88.5908 13.7599 88.4196 13.8174 88.296 13.9419C88.1723 14.0665 88.1152 14.239 88.1152 14.4785V33.0487C88.1152 33.2595 88.1723 33.432 88.296 33.5661C88.4196 33.7003 88.5908 33.7673 88.8001 33.7673C89.0379 33.7673 89.2091 33.7003 89.3232 33.5661C89.4278 33.432 89.4849 33.2595 89.4849 33.0487V14.4785C89.4849 14.2486 89.4278 14.0665 89.3042 13.9419C89.1805 13.8174 89.0093 13.7599 88.8001 13.7599Z" fill="#15BDC7" />
                  <path d="M107.615 13.9227H97.3713C97.1239 13.9227 96.9242 13.9898 96.7815 14.1335C96.6388 14.2773 96.5723 14.4689 96.5723 14.7276V32.9624C96.5723 33.2115 96.6388 33.4128 96.7815 33.5565C96.9242 33.7002 97.1144 33.7673 97.3713 33.7673C97.6376 33.7673 97.9515 33.6236 97.9515 32.9624V24.2714H107.073C107.282 24.2714 107.454 24.2139 107.568 24.1085C107.691 23.9935 107.758 23.8306 107.758 23.6102C107.758 23.409 107.691 23.2557 107.568 23.1407C107.444 23.0257 107.282 22.9682 107.073 22.9682H97.9515V15.2259H107.615C107.825 15.2259 107.996 15.1684 108.11 15.063C108.234 14.948 108.3 14.7851 108.3 14.5647C108.3 14.3635 108.234 14.2102 108.11 14.0952C107.986 13.9802 107.825 13.9323 107.615 13.9323V13.9227Z" fill="#15BDC7" />
                  <path d="M126.829 13.894C126.724 13.7982 126.591 13.7503 126.439 13.7503C126.277 13.7503 126.134 13.779 126.02 13.8461C125.906 13.9132 125.792 14.0282 125.697 14.1815L119.333 22.8533L112.98 14.1815C112.875 14.0186 112.761 13.9036 112.637 13.8365C112.399 13.712 112.038 13.6928 111.81 13.8749C111.695 13.9707 111.629 14.1048 111.629 14.2773C111.629 14.4402 111.686 14.6127 111.819 14.7947L118.62 24.0415V32.9912C118.62 33.2212 118.677 33.4032 118.801 33.5374C118.924 33.6715 119.096 33.7386 119.305 33.7386C119.752 33.7386 119.99 33.4703 119.99 32.9912V24.0702L126.81 14.7947C126.952 14.6031 127.019 14.4306 127.009 14.2677C127 14.1048 126.943 13.9707 126.838 13.8749L126.829 13.894Z" fill="#15BDC7" />
                </g>
                <defs>
                  <linearGradient id="paint0_linear_28_112" x1="-1.44572" y1="25.4693" x2="30.8812" y2="15.1636" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F69C99" />
                    <stop offset="0.27" stopColor="#FBD5E2" />
                    <stop offset="0.49" stopColor="#CBB7D3" />
                    <stop offset="0.53" stopColor="#9BB8D0" />
                    <stop offset="0.56" stopColor="#71BACD" />
                    <stop offset="0.59" stopColor="#50BBCB" />
                    <stop offset="0.63" stopColor="#36BBC9" />
                    <stop offset="0.67" stopColor="#23BCC8" />
                    <stop offset="0.7" stopColor="#18BCC7" />
                    <stop offset="0.74" stopColor="#15BDC7" />
                  </linearGradient>
                  <clipPath id="clip0_28_112">
                    <rect width="127" height="36" fill="white" />
                  </clipPath>
                </defs>
              </svg>Admin
            </div>
            <h4 className="mb-0">
              <div>Welcome back,</div>
              <span>Please sign in to your account.</span>
            </h4>
            <Row className="divider" />
            <div>

              <div className="col-md-12 text-center" style={ { marginTop: '30px' } }>
                <button type="button" className="btn btn-primary btn-login" style={ { margin: '10px' } } onClick={ identityLogin }>
                  <i className="lnr-picture btn-icon-wrapper"> </i>{!loading ? 'Sign in to Dashboard' : 'Please wait...'} 
                </button>

              </div>
            
            </div>
          </Col>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
