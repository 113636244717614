import {  formatUtcDateStringToLocalTime } from 'common/utils/dateUtils';
import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import RecommendedResorts from './_RecommendedResorts';

const _PTID = ({ salesLeadDetails, lastPageVisit, weddingDetailsId }) => {
  
  return (
    <Card style={{ marginTop: 10 }}>
      <CardHeader>
        <h5>PTID Step 1</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={12}>
            <label className="list-details">
              Date Last Active:{' '}
              <div className="list-details-value">
                {lastPageVisit.visitedDate ? formatUtcDateStringToLocalTime(lastPageVisit.visitedDate) : "N/A"}
              </div>
            </label>

            <label className="list-details">
              Page Last Visited:{' '}
              <div className="list-details-value">
                {lastPageVisit.pageName ? lastPageVisit.pageName : 'N/A'}
              </div>
            </label>
            <label className="list-details">
              Destination Selected:{' '}
              <div className="list-details-value">
                {salesLeadDetails.destination
                  ? salesLeadDetails.destination
                  : 'N/A'}
              </div>
            </label>

            <label className="list-details">
              City Selected:{' '}
              <div className="list-details-value">
                {salesLeadDetails.city ? salesLeadDetails.city : 'N/A'}
              </div>
            </label>

            <label className="list-details">
              Resort Selected:{' '}
              <div className="list-details-value">
                {salesLeadDetails.resort ? salesLeadDetails.resort : 'N/A'}
              </div>
            </label>

            <label className="list-details">
              Venue Selected:{' '}
              <div className="list-details-value">
                {salesLeadDetails.venue ? salesLeadDetails.venue : 'N/A'}
              </div>
            </label>
            <label className="list-details">
              Package Selected:{' '}
              <div className="list-details-value">
                {salesLeadDetails.weddingPackage
                  ? salesLeadDetails.weddingPackage
                  : 'N/A'}
              </div>
            </label>
          </Col>
          <Col md={12}>
            <RecommendedResorts weddingDetailsId={weddingDetailsId} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default _PTID;
