/* eslint-disable react/prop-types */
import React from 'react';

import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
} from 'reactstrap';

import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import { withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import Slider from 'rc-slider';
import { TextField } from '../../../common/Form/TextInput';
import { TextArea } from '../../../common/Form/TextArea';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import weddingPackage from '../../../api/weddingPackage';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
// const { Handle } = Slider;

// const handle = (props) => {
//   const { value, dragging, index, ...restProps } = props;
//   return (
//     <Tooltip
//       prefixCls="rc-slider-tooltip"
//       overlay={ value }
//       visible={ dragging }
//       placement="top"
//       key={ index }
//     >
//       <Handle value={ value } { ...restProps } />
//     </Tooltip>
//   );
// };

class CreateDestination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        destinationName: '',
        roomRates: '',
        minRoomRate: 0,
        maxRoomRate: 0,
        weddingRates: '',
        description: '',
        flightTimes: 0,
        lBQTFriendly: false,
        beachQuality: '',
      },
      roomRates: [200, 600],
    };
  }

  validationSchema = Yup.object({
    destinationName: Yup.string().required('The Destination is required'),
    //  roomRates: Yup.string().required('The Room rates is required'),
    weddingRates: Yup.string().required('The Wedding rates is required'),
    description: Yup.string().required('The Description is required'),
    flightTimes: Yup.string().required('The Flight Times is required'),
    beachQuality: Yup.string().required('The BeachQuality Times is required'),
  });

  beachQualityList = [
    { title: 'Poor ', value: 'Poor' },
    { title: 'Average ', value: 'Average' },
    { title: 'Excellent ', value: 'Excellent' },
  ];

  componentDidMount() {}

  onSliderChange = (value) => {
    this.setState({
      roomRates: value,
    });
  };

  render() {
    const { initialValues } = this.state;
    return (
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }
      >
        <Container fluid>
          <div>
            <PageTitle
              heading="Country/Destination Management"
              icon="lnr-apartment icon-gradient bg-tempting-azure"
            />
          </div>
        </Container>

        <Formik
          initialValues={ initialValues }
          validationSchema={ this.validationSchema }
          enableReinitialize
          onSubmit={ async (values) => {
            const param = {
              destinationName: values.destinationName,
              roomRates: values.roomRates,
              minRoomRate: this.state.roomRates[0],
              maxRoomRate: this.state.roomRates[1],
              weddingRates: values.weddingRates,
              description: values.description,
              flightTimes: values.flightTimes,
              lBQTFriendly: values.lBQTFriendly,
              beachQuality: values.beachQuality,
            };
            await weddingPackage.destination.saveDestination(param);
            this.props.history.push('/wedding/destination');
            toast.success('Country/Destination saved successfully.');
          } }
        >
          {({ isSubmitting }) => (
            <Card>
              <CardBody>
                <Button
                  className="mb-2 mr-2 btn-icon float-right"
                  color="primary"
                  onClick={ () => {
                    this.props.history.push('/wedding/destination');
                  } }
                >
                  Back To List
                </Button>
                <Form autoComplete="off">
                  <Row>
                    <Col md="3"> </Col>
                    <Col md="6">
                      <Card>
                        <CardHeader>Create New Country/Destination</CardHeader>
                        <CardBody>
                          <FormGroup>
                            <TextField
                              label="Country/Destination Name"
                              name="destinationName"
                            />
                          </FormGroup>

                          <FormGroup>
                            <label htmlFor="Room Rates">Room Rates</label>
                            <Range
                              name="roomRates"
                              className="mb-2"
                              min={ 50 }
                              max={ 2000 }
                              // defaultValue={[100, 500]}
                              value={ this.state.roomRates }
                              onChange={ this.onSliderChange }
                              tipFormatter={ (value) => `$${value}` }
                            />
                          </FormGroup>

                          {/* <FormGroup> */}
                          {/*    <TextField label="Room Rates" name="roomRates" /> */}
                          {/* </FormGroup> */}

                          <FormGroup>
                            <TextField
                              label="Wedding Rates"
                              name="weddingRates"
                            />
                          </FormGroup>

                          <FormGroup>
                            <label> Beach Quality </label>
                            <Field
                              name="beachQuality"
                              className="form-control"
                              as="select"
                            >
                              <option value="">--Select--</option>
                              {this.beachQualityList.map((destination) => (
                                <option
                                  key={ destination.value }
                                  value={ destination.value }
                                >
                                  {destination.title}
                                </option>
                              ))}
                            </Field>
                          </FormGroup>

                          <FormGroup>
                            <TextArea label="Description" name="description" />
                          </FormGroup>

                          <FormGroup>
                            <Row>
                              <Col md={ 4 }>
                                <TextField
                                  type="number"
                                  min="0"
                                  label="Flight Times"
                                  name="flightTimes"
                                />
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Row>
                              <Col md={ 8 }>
                                <label>LBQT Friendly</label>
                                <div className="input-group mt-2">
                                  <Field type="checkbox" name="lBQTFriendly" />
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </CardBody>
                        <Button
                          disabled={ isSubmitting }
                          type="submit"
                          color="primary"
                          className="mt-1"
                        >
                          {isSubmitting ? 'Processing....' : 'Save'}
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Formik>
      </TransitionGroup>
    );
  }
}

export default withRouter(CreateDestination);
