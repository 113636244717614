
import React, { CSSProperties } from 'react';
import avatar from '../../../../assets/utils/images/avatars/default.png';
import { getAdminProfilePictureUrl } from 'utils/utils';
interface Props {
  userId: string;
  style?: CSSProperties;
  className?: string;
}

export const ProfileImage = ({ userId, style, className }: Props): JSX.Element => {

  const placeholderImage = avatar;
  const onImageError = (e: any) => {
      e.target.src = placeholderImage;
  };

  // TODO
  // const BASE_URL = getBaseUrl(userId);    
  const adminUserImageUrl = getAdminProfilePictureUrl(userId);
  return (
        <img 
        src={adminUserImageUrl} 
        id={userId}
        alt="profile" 
        onError={onImageError}  
        style={style} 
        className={className} 
        />
  )
}

export default ProfileImage;
