import React, { useEffect, useMemo } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Container,
} from 'reactstrap';
import { TransitionGroup } from 'react-transition-group';
import { withRouter } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useIngestionLogSlice } from './slice';
import { selectIngestionLogs } from "./slice/selectors"
import { IngestionLogs } from "./slice/types"
import Table,{ Column } from "common/Table";
import PageTitle from '../../../Layout/AppMain/PageTitle';

const StaticPricingIngestionLogList = props => {
  const dispatch = useAppDispatch();

  const { actions } = useIngestionLogSlice();

  const {list,loading}: {list:IngestionLogs[],loading:boolean} = useAppSelector(selectIngestionLogs);

  useEffect(() => {
    console.log({list,loading})
  },[list,loading])

  useEffect(() => {
    dispatch(actions.loadIngestionLogs());
  }, [actions, dispatch]);

  const columns: Array<Column<IngestionLogs>> = useMemo(() => {
    return ([
      {
        Header: "Started date",
        Cell: ({value}) => {
          return <>{new Date(value).toLocaleString('en-US', {timeZone: 'America/Chicago',timeZoneName:'short'})}</>
        },
        accessor: "createdDate"
      },
      {
        Header: "Completion Date",
        Cell: ({value,row:{original:{createdDate}}}) => {
          return <>{new Date(value || createdDate).toLocaleString('en-US', {timeZone: 'America/Chicago',timeZoneName:'short'})}</>
        },
        accessor: "lastUpdatedDate"
      },
      {
        Header: "Processed row count",
        Cell: ({value}) => {
          return <>{value || 0}</>
        },
        accessor: "processedRowCount"
      },
      {
        Header: "Current row #",
        Cell: ({value}) => {
          return <>{value || "N/A"}</>
        },
        accessor: "currentRowId"
      },
      {
        Header: "status",
        Cell: ({value}) => {
          return <span className={`mb-2 mr-2 badge badge-pill badge-${value.toString() ==="ERROR" ? "danger": (value.toString() ==="PROCESSING"  ? "info":"success")}`}>{value}</span>
        },
        accessor: "currentStatus"
      },
      {
        Header: "remarks",
        Cell: ({value}) => {
          return <>{value || "-"}</>
        },
        accessor: "remarks"
      }
    ])
  },[])

  return (
    <>
        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <div>
              <PageTitle
                heading="Ingestion Job Status"
                icon="lnr-clock icon-gradient bg-tempting-azure"
              />
            </div>

          
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Ingestion Job Status</CardTitle>
             <Table data={list} columns={columns} loading={loading} />
             </CardBody>
              </Card>
          </Container>
        </TransitionGroup>
    </>
  );
};

export default withRouter(StaticPricingIngestionLogList);
