/* eslint-disable react/no-array-index-key */
import React from 'react';

import { withRouter } from 'react-router-dom';

function ValidationError(props) {
  return (
        
    <ul>
      {props.errors.map((err, index) => (
        <li key={ index }>
          {err}
        </li>
      ))}
    </ul>

  );
}
export default withRouter(ValidationError)
