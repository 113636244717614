import React,{useCallback} from "react";

interface Options {
  callback?: (...args: any[]) => void;
  toggle?: boolean;
}

const useOutsideClick = <T extends HTMLElement>(initialState = false, options: Options = {}) => {
  const ref = React.useRef<T>(null);
  const [isOpen, setIsOpen] =
    React.useState<boolean>(initialState);

  const handler = useCallback((e:MouseEvent) => {
    if(e.target && ref.current && (e.target === ref.current || ref.current.contains(e.target as HTMLElement))) {
      //open
      if(options.toggle) return setIsOpen(prev => !prev);
      setIsOpen(true);
    }else {
      //close
      setIsOpen(false);
    }
  },[options.toggle])

  const forceClose = () => setIsOpen(false);
  const forceOpen = () => setIsOpen(true);

  React.useEffect(() => {
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, [handler]);

  return {
    targetRef: ref,
    isOpen,
    forceOpen,
    forceClose
  };
};

export default useOutsideClick;
