/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import { toast } from 'react-toastify';

import classnames from 'classnames';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';

import arrayChunk from 'lodash.chunk';

import { TransitionGroup } from 'react-transition-group';
import _ from 'lodash';
import Slider from 'rc-slider';
import { TextField } from '../../../common/Form/TextInput';
import { TextArea } from '../../../common/Form/TextArea';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import weddingPackage from '../../../api/weddingPackage';
import PhotoWidgetDropzone from '../../../common/imageUploader/photoWidgetDropzone';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
// const { Handle } = Slider;
// const handle = (props) => {
//   const { value, dragging, index, ...restProps } = props;
//   return (
//     <Tooltip
//       prefixCls="rc-slider-tooltip"
//       overlay={ value }
//       visible={ dragging }
//       placement="top"
//       key={ index }
//     >
//       <Handle value={ value } { ...restProps } />
//     </Tooltip>
//   );
// };

class EditDestination extends React.Component {
  // destinationId = 0;

  validationSchema = Yup.object({
    destinationName: Yup.string().required(
      'The Country/Destination is required'
    ),
    roomRates: Yup.string().required('The Room rates is required'),
    weddingRates: Yup.string().required('The Wedding rates is required'),
    description: Yup.string().required('The Description is required'),
    flightTimes: Yup.string().required('The Flight Times is required'),
    beachQuality: Yup.string().required('The BeachQuality Times is required'),
  });

  beachQualityList = [
    { title: 'Poor ', value: 'Poor' },
    { title: 'Average ', value: 'Average' },
    { title: 'Excellent', value: 'Excellent ' },
  ];

  constructor(props) {
    super(props);
    // const getUrl = window.location;

    this.state = {
      initialValues: {
        destinationName: '',
        roomRates: '',
        minRoomRate: 0,
        maxRoomRate: 0,
        weddingRates: '',
        description: '',
        flightTimes: 0,
        lBQTFriendly: false,
        beachQuality: '',
      },
      activeTab: '1',
      destinationId: 0,
      images: [],
      chunkedData: [],
      showImageUpload: false,
      //   baseUrl: `${getUrl.protocol}//${getUrl.host}`,
      show: false,
      roomRates: [0, 0],
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id && id > 0) {
      this.setState({ destinationId: id });
      this.getImages(id);
      weddingPackage.destination.getDestinationById(id).then((res) => {
        this.setState({ initialValues: res });
        this.setState({ roomRates: [res.minRoomRate, res.maxRoomRate] });
      });
    }
  }

  updateImage = (index) => {
    const image = this.state.images[index];
    const exceptList = _.reject(
      this.state.images,
      (o) => o.imageContentID === image.imageContentID
    );
    const isOrderExist = exceptList.some(
      (d) => d.imageOrder === image.imageOrder
    );

    if (isOrderExist) {
      toast.error('Image display order already exists.');
      return;
    }

    weddingPackage.destination.updateImageDetails(image).then((response) => {
      // let arr = this.state.images;
      // arr[index] = image.imageURL;
      // this.setState({ images: arr })
      if (response === 'Image updated successfully.') {
        toast.success(
          'The details of country/destination image updated successfully.'
        );
      } else {
        toast.error('Internal Server Error');
      }
    });
  };

  async getImages() {
    const images = await weddingPackage.destination.getImages(
      this.props.match.params.id
    );
    this.setState({ images });
    this.setState({ chunkedData: arrayChunk(images, 3) });
  }

  async addImage(param) {
    await weddingPackage.destination.uploadPhoto(
      param.Id,
      param.imageName,
      param.altTagText,
      param.blob
    );
    toast.success('Image saved successfully for the country/destination.');
    await param.that.getImages();
  }

  showConfirmationToDelete = (id) => {
    this.deleteDestinationId = id;
    this.setState({ show: true });
  };

  deleteImage = () => {
    weddingPackage.destination
      .deleteImages({ imageContentID: this.deleteDestinationId })
      .then(() => {
        toast.success('Image deleted succesfuly.');
        this.getImages();
      });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    this.setState((pS) => {
      const list = [...pS.images];
      list[index][name] = value;
      return { images: list, chunkedData: arrayChunk(list, 3) }
    });
  };

  onSliderChange = (value) => {
    this.setState({
      roomRates: value,
    });
  };

  render() {
    const { initialValues } = this.state;
    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text="Are you sure to delete this image?"
          showCancelButton
          onConfirm={ () => {
            this.deleteImage();
            this.setState({ show: false });
          } }
          onCancel={ () => {
            this.setState({ show: false });
          } }
          onClose={ () => console.log('close') }
        />

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <div>
              <PageTitle
                heading="Country/Destination Management"
                icon="lnr-apartment icon-gradient bg-tempting-azure"
              />
            </div>
          </Container>
          <Card>
            <CardBody>
              <Button
                className="mb-2 mr-2 btn-icon float-right"
                color="primary"
                onClick={ () => {
                  this.props.history.push('/wedding/destination');
                } }
              >
                Back To List
              </Button>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Nav justified>
                <NavItem>
                  <NavLink
                    href="#"
                    className={ classnames({
                      active: this.state.activeTab === '1',
                    }) }
                    onClick={ () => {
                      this.toggle('1');
                    } }
                  >
                    Country/Destination Form
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={ classnames({
                      active: this.state.activeTab === '2',
                    }) }
                    onClick={ () => {
                      this.toggle('2');
                    } }
                  >
                    Country/Destination Images
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={ this.state.activeTab }>
                <TabPane tabId="1">
                  <Formik
                    initialValues={ initialValues }
                    validationSchema={ this.validationSchema }
                    enableReinitialize
                    onSubmit={ async (values) => {
                      const param = {
                        destinationID: this.state.destinationId,
                        destinationName: values.destinationName,
                        roomRates: values.roomRates,
                        minRoomRate: this.state.roomRates[0],
                        maxRoomRate: this.state.roomRates[1],
                        weddingRates: values.weddingRates,
                        description: values.description,
                        flightTimes: values.flightTimes,
                        lBQTFriendly: values.lBQTFriendly,
                        beachQuality: values.beachQuality,
                      };
                      await weddingPackage.destination.saveDestination(param);
                      // this.props.history.push("/wedding/venue");
                      toast.success('Venue saved successfully.');
                    } }
                  >
                    {({
                      isSubmitting,
                    }) => (
                      <Form autoComplete="off">
                        <Row>
                          <Col md="3"> </Col>
                          <Col md="6">
                            <Card>
                              <CardHeader>
                                Create Country/Destination
                              </CardHeader>
                              <CardBody>
                                <FormGroup>
                                  <TextField
                                    label="Destination Name"
                                    name="destinationName"
                                  />
                                </FormGroup>

                                {/* <FormGroup> */}
                                {/* <TextField label="Room Rates" name="roomRates" /> */}
                                {/* </FormGroup> */}

                                <FormGroup>
                                  <label htmlFor="Room Rates">Room Rates</label>
                                  <Range
                                    name="roomRates"
                                    className="mb-2"
                                    min={ 50 }
                                    max={ 2000 }
                                    // defaultValue={[100, 500]}
                                    value={ this.state.roomRates }
                                    onChange={ this.onSliderChange }
                                    tipFormatter={ (value) => `$${value}` }
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <TextField
                                    label="Wedding Rates"
                                    name="weddingRates"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <label> Beach Quality </label>
                                  <Field
                                    name="beachQuality"
                                    className="form-control"
                                    as="select"
                                  >
                                    <option value="">--Select--</option>
                                    {this.beachQualityList.map(
                                      (destination) => (
                                        <option
                                          key={ destination.value }
                                          value={ destination.value }
                                        >
                                          {destination.title}
                                        </option>
                                      )
                                    )}
                                  </Field>
                                </FormGroup>

                                <FormGroup>
                                  <TextArea
                                    label="Description"
                                    name="description"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <Row>
                                    <Col md={ 4 }>
                                      <TextField
                                        type="number"
                                        min="0"
                                        label="Flight Times"
                                        name="flightTimes"
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>

                                <FormGroup>
                                  <Row>
                                    <Col md={ 8 }>
                                      <label>LBQT Friendly</label>
                                      <div className="input-group mt-2">
                                        <Field
                                          type="checkbox"
                                          name="lbqtFriendly"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </CardBody>
                              <Button
                                disabled={ isSubmitting }
                                type="submit"
                                color="primary"
                                className="mt-1"
                              >
                                {isSubmitting ? 'Processing....' : 'Save'}
                              </Button>
                            </Card>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </TabPane>
                <TabPane tabId="2">
                  <Button
                    className="btn btn-primary mb-3"
                    color="primary"
                    onClick={ () => this.setState((pS) => ({
                      showImageUpload: !pS.showImageUpload,
                    })) }
                  >
                    Add Images
                  </Button>

                  <div
                    className={ this.state.showImageUpload ? '' : 'd-none' }
                    id="collapseImage"
                  >
                    <PhotoWidgetDropzone
                      that={ this }
                      Id={ this.state.destinationId }
                      submit={ this.addImage }
                    />
                  </div>

                  <div className="container mt-5">
                    {this.state.chunkedData.map((row, rowIndex) => (
                      <div key={ rowIndex } className="row mb-4">
                        {row.map((col, colIndex) => (
                          <div
                            className="col-lg-4 d-none d-lg-block"
                            key={ colIndex + rowIndex }
                          >
                            <div className="card">
                              <div className="card-body">
                                {/* {col.imageURL} */}
                                <img
                                  // src={this.state.baseUrl + col.imageURL}
                                  src={ col.imageURL }
                                  className="imagesize hover mb-3"
                                  alt={ col.altTagText }
                                />

                                <FormGroup>
                                  Image Name
                                  <input
                                    type="text"
                                    name="imageName"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.imageName }
                                    className="form-control mb-2"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  Alt Tag Image
                                  <input
                                    type="text"
                                    name="altTagText"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.altTagText }
                                    className="form-control mb-2"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  Image Order
                                  <input
                                    type="number"
                                    name="imageOrder"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.imageOrder }
                                    className="form-control mb-2"
                                  />
                                </FormGroup>

                                <button
                                  type="button"
                                  className="mt-2 mb-2 mr-2 btn-icon btn btn-success"
                                  onClick={ () => this.updateImage(rowIndex * 3 + colIndex) }
                                >
                                  <i className="pe-7s-edit btn-icon-wrapper">
                                    {' '}
                                  </i>
                                  Update
                                </button>
                                <button
                                  type="button"
                                  className="mt-2 mb-2 mr-2 btn-icon btn btn-danger"
                                  onClick={ () => this.showConfirmationToDelete(
                                    col.imageContentID
                                  ) }
                                >
                                  <i className="pe-7s-trash btn-icon-wrapper">
                                    {' '}
                                  </i>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </TransitionGroup>
      </>
    );
  }
}
export default withRouter(EditDestination);
