type ValueOf<T> = T[keyof T];

export function getEnumKeyByEnumValue<R extends (string | number), T extends {[key: string] : R}>(myEnum: T, enumValue: ValueOf<T>): string {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : '';
}

/**
 * generic utility function that can take an instance of 1 kind of enum,
 * and convert it to an instance of another kind of enum, 
 * if the keys between both are the same
 * @param sourceEnum 
 * @param targetEnum 
 * @param value 
 * @returns 
 */
export function convertEnum<TSource extends Record<string, string>, TTarget extends Record<string, string>>(
  sourceEnum: TSource,
  targetEnum: TTarget,
  value: TSource[keyof TSource]
): TTarget[keyof TTarget] {
  const key = Object.keys(sourceEnum).find(k => sourceEnum[k] === value);
  if (key && key in targetEnum) {
    return targetEnum[key as keyof TTarget];
  }
  throw new Error(`Value '${value}' not found in target enum`);
}

