import { Link } from "react-router-dom";

const BackButton = () => (
    <Link to={'/user/admin-list'}>
      <button type="button" className="mt-1 ml-2 btn btn-outline-info">
        Back to the list
      </button>
    </Link>
  );

  export default BackButton;