import { IBaseODataApiClient } from './IBaseODataApiClient';
import { escapeSpecialChars } from 'utils/utils';
import { destifyAdminApiRequests } from '../common';


export class AdminODataApiClient implements IBaseODataApiClient {
  async getODataListResponseWithCount<T>(url: string = '') {
    const { data: { value, '@odata.count': count } } = await destifyAdminApiRequests.oDataGet<{ value: T[]; '@odata.count': number }>(escapeSpecialChars(url));  
    return {
      data: value,
      count
    }
  }

  async getData<T>(url: string = '') {
    const { data: { value } } = await destifyAdminApiRequests.oDataGet<{ value: T }>(url);
    return value;
  } 


};

