import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  Label,
  CardTitle,
  Container,
  Row,
  Col
} from 'reactstrap';
import Loader from 'react-loaders';
import { TextField } from 'common/Form/TextInput';
import SelectField from 'common/Form/Select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';

import { TransitionGroup } from 'react-transition-group';

import { useParams, useHistory } from 'react-router-dom';
import usersApi from 'api/user';
import PageTitle from '../../../Layout/AppMain/PageTitle';

import {BackButton} from "./components"

import schema from "./yup_schema"
import ProfileCard from './UserProfile/ProfileCard';

const validationSchema = Yup.object({
  firstName: schema.firstName(),
  lastName: schema.lastName(),
  phoneNumber: schema.phoneNumber(),
  selectedRoles: schema.selectedRoles(),
  calendly: schema.calendly(),
  biography: schema.biography(),
  crmUserId: schema.crmUserId(),
});

const EditAdminUser = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [initialValues, setInitialValues] = useState(null);

  const { id: userId } = useParams<{ id: string }>();
  const history = useHistory();


  const loggedInUser = JSON.parse(localStorage.getItem("loginUser") || "{}");

  const legacyUserId = loggedInUser.profile["custom:DestifyLegacyUserId"].toLowerCase();

  useEffect(() => {
    if (!userId) return;
    (async () => {
      try {
        setError(null);
        const availableRoles = await usersApi.authUsers.listAllAdminRoles();
        const userDetails = await usersApi.authUsers.getUserDetails(
          userId,
          true,
        );
        const { id: value, normalizedName: label } =
          availableRoles.find(
            role => role.id === userDetails.checkedroles?.[0],
          ) ?? {};
        setInitialValues({
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          phoneNumber: userDetails.phoneNumber || '',
          email: userDetails.email,
          isActive: userDetails.isActive,
          selectedRoles: { label, value },
          initialRole: { label, value },
          availableRoles,
          calendly: userDetails.canlendlyLink ?? '',
          biography: userDetails.biography ?? '',
          fullName: `${userDetails.firstName} ${userDetails.lastName}`,
          userId: userDetails.id.toLowerCase() || '',
          crmUserId: userDetails.crmUserId,
        });
      } catch (error) {
        setError(`Unexpected error loading user details: ${error}`);
      } finally {
        setLoading(false);
      }
    })();
  }, [userId]);

  const handleSubmit = async values => {
    setLoading(true);
    try {
      const payload = {
        Id: userId,
        firstname: values.firstName,
        lastname: values.lastName,
        phonenumber: values.phoneNumber,
        canlendlyLink: values.calendly ? values.calendly : null,
        biography: values.biography,
        email: values.email,
        isactive: values.isActive,
        checkedroles: [values.selectedRoles.value],
        isAdminTypeUser: true,
        crmUserId: values.crmUserId
      };

      const updateUserRequest = await usersApi.authUsers.editAdmin(payload);
      if (updateUserRequest.success) {
        toast.success(updateUserRequest.message);
        history.push('/user/admin-list');
      } else {
        setError(
          `Unable to save changes due to: ${
            updateUserRequest.message || 'unknown error'
          }`,
        );
      }
    } catch (e) {
      setError(`Unexpected error saving user details: ${e || 'unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  const onChangeBiography = (e, editor) => {
    const biographyData = editor.getData();
    initialValues.biography = biographyData;
  };

  if (!initialValues) return <Loader active type="ball-pulse" />;

  
  if (error)
    return (
      <div className="mt-3 mb-1">
        <div
          className="invalid-tooltip mt-2"
          style={{ display: 'inline', position: 'initial' }}
        >
          {error}
        </div>
        <BackButton />
      </div>
    );

  return (
    <TransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <Container fluid>
        <div>
          <PageTitle
            heading="Admin Users"
            subheading={`Editing ${initialValues.fullName}`}
            icon="pe-7s-user icon-gradient bg-tempting-azure"
          />
        </div>
        <Row>
          <Col sm={12} md={4}>
          <ProfileCard
                  userId={initialValues.userId} 
                  legacyUserId={legacyUserId}
                  userDeatails={{
                      firstName: initialValues.firstName,
                      lastName:  initialValues.lastName,
                      email:  initialValues.email,
                      phoneNumber:  initialValues.phoneNumber,
                      calendly: initialValues.calendly
                  }}
                  />
          </Col>
          <Col sm={12} md={8}>
          <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>User Details</CardTitle>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              enableReinitialize
              validationSchema={validationSchema}
            >
              {() => {
                return (
                  <Form>
                    <Row>
                      <Col sm={6} ms={12}>
                    <FormGroup>
                      <TextField name="firstName" label={'First Name'} />
                    </FormGroup>
                    </Col>
                    <Col sm={6} ms={12}>
                    <FormGroup>
                      <TextField name="lastName" label={'Last Name'} />
                    </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                      <Col sm={6} ms={12}>
                    <FormGroup>
                      <TextField name="phoneNumber" label={'Phone Number'} />
                    </FormGroup>
                    </Col>
                    <Col sm={6} ms={12}>
                    <FormGroup>
                      <TextField name="calendly" label={'Calendly'} />
                    </FormGroup>
                    </Col>
                    </Row>
                    <FormGroup>
                      <CKEditor
                                name="biography"
                                editor={ ClassicEditor }
                                data={ initialValues.biography }
                                 onChange={ onChangeBiography}
                              />
                    </FormGroup>

                    <FormGroup>
                      <SelectField
                        name="selectedRoles"
                        label={'Assigned Roles *'}
                        items={initialValues.availableRoles as {normalizedName:string,id:string}[]}
                        extractor={item => ({
                          label: item.normalizedName,
                          value: item.id,
                        })}
                        initialValue={initialValues.initialRole}
                        autoClose
                        disabled={loading}
                      />
                    </FormGroup>
                    <FormGroup>
                      <TextField name="crmUserId" label={'CRM User ID'}/>
                    </FormGroup>
                    <hr />
                    <FormGroup>
                      <Label
                        htmlFor="isActive"
                        className="form-check-label form-label d-flex"
                      >
                        <Field type="checkbox" name={'isActive'} />
                        <div>
                          <span>User Active</span>{' '}
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Disabling this user will prevent them from logging in, in the future"
                          >
                            <i className="pe-7s-info" />
                          </span>
                        </div>
                      </Label>
                    </FormGroup>
                    <p>&nbsp;</p>
                    <Button disabled={loading} color="primary" className="mt-1">
                      {!loading ? (
                        <span>Save Changes</span>
                      ) : (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span>Please wait...</span>
                        </>
                      )}
                    </Button>

                    <BackButton />
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
          </Col>
        </Row>

     
      </Container>
    </TransitionGroup>
  );
};
export default EditAdminUser;
