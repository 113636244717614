/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { Row, Card, CardHeader, CardBody, FormGroup } from 'reactstrap';
import { Formik, Form } from 'formik';
import Loader from 'react-loaders';
import { useParams, Link } from 'react-router-dom';

import PageTitle from 'Layout/AppMain/PageTitle';
import { TextField } from 'common/Form/TextInput';
import Select from 'common/Select';
import UserRooms from '../components/_UserRooms';
import UserAssociatedRooms from '../components/_UserAssociatedRooms';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useUsersSlice } from '../../slice';
import {
  selectUserAccountDetails,
  selectUserCommunicationDetails,
} from '../../slice/selectors';
import { phoneRegex } from 'common/utils/constants';
import UserGroups from '../components/UserGroups';

const communicationStyles = [
  { label: 'Phone', value: 'Phone' },
  { label: 'Email', value: 'Email' },
  { label: 'Text', value: 'Text' },
];

const communicationLevels = [
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
];

const validationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  phoneNo: Yup.string()
    .matches(phoneRegex, { message: 'invalid phone number!' })
    .required(),
  communicationLevel: Yup.string().oneOf(communicationLevels.map(c => c.value)),
  communicationStyles: Yup.string().oneOf(
    communicationStyles.map(c => c.value),
  ),
});

const ManageClientAccount = () => {
  const dispatch = useAppDispatch();
  const { details, error, loading } = useAppSelector(selectUserAccountDetails);
  const communication = useAppSelector(selectUserCommunicationDetails);

  const initialFormState = useMemo(() => {
    return {
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
      phoneNo: details.phoneNumber,
      communicationLevel: communication.level,
      communicationStyle: communication.style,
    };
  }, [details, communication]);

  const initialOptions = useMemo(
    () => ({
      communicationLevel: {
        value: communication.level || null,
        label: communication.level,
      },
      communicationStyle: {
        value: communication.style || null,
        label: communication.style,
      },
    }),
    [communication],
  );

  const { actions } = useUsersSlice();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id) return;
    dispatch(actions.loadClientAccountDetails(id));
  }, [id]);

  const submitForm = async (values: typeof initialFormState) => {
    dispatch(actions.updateUserDetails({ id, values }));
  };

  if (error)
    return (
      <div>
        <p>{error}</p>
        <Link to={'/user/guest-list-new'}>Back to the list</Link>
      </div>
    );

  return (
    <>
      <div>
        <PageTitle
          heading="Client Accounts Management"
          subheading="Client account details"
          icon="pe-7s-user icon-gradient bg-tempting-azure"
        />
      </div>

      <button
        type="button"
        className="mb-3 ml-auto btn-pill btn btn-outline-primary"
        style={{ display: 'flex' }}
      >
        <Link to={'/user/guest-list-new'}>Back to the list</Link>
      </button>

      <Card className="main-card mb-3">
        <CardHeader>Client Account</CardHeader>
        <CardBody>
          {loading && <Loader active type="ball-pulse" />}
          <Formik
            initialValues={initialFormState}
            enableReinitialize
            onSubmit={submitForm}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form onSubmit={handleSubmit}>
                <section>
                  <h4>User Details</h4>
                  <FormGroup>
                    <Row className="align-items-end">
                      <label className="control-label col-md-3">
                        First Name:{' '}
                      </label>
                      <div className="col-md-5">
                        <TextField name="firstName" />
                      </div>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row className="align-items-end">
                      <label className="control-label col-md-3">
                        Last Name:{' '}
                      </label>
                      <div className="col-md-5">
                        <TextField name="lastName" />
                      </div>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row className="align-items-end">
                      <label className="control-label col-md-3">
                        Phone No:{' '}
                      </label>
                      <div className="col-md-5">
                        <TextField name="phoneNo" />
                      </div>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row className="align-items-end">
                      <label className="control-label col-md-3">Email: </label>
                      <div className="col-md-5">
                        <TextField name="email" />
                      </div>
                    </Row>
                  </FormGroup>
                </section>

                <section>
                  <h4>Communication</h4>
                  <div>
                    <FormGroup>
                      <Row className="align-items-end">
                        <label className="control-label col-md-3">
                          Communication Style:{' '}
                        </label>
                        <div className="col-md-5">
                          <Select
                            autoClose
                            initialValue={initialOptions.communicationStyle}
                            items={communicationStyles}
                            onChange={value => {
                              setFieldValue('communicationStyle', value.value);
                            }}
                            extractor={item => item}
                          />
                        </div>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row className="align-items-end">
                        <label className="control-label col-md-3">
                          Communication Level:{' '}
                        </label>
                        <div className="col-md-5">
                          <Select
                            autoClose
                            initialValue={initialOptions.communicationLevel}
                            items={communicationLevels}
                            extractor={item => item}
                            onChange={value => {
                              setFieldValue('communicationLevel', value.value);
                            }}
                          />
                        </div>
                      </Row>
                    </FormGroup>
                  </div>
                </section>

                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Submit"
                />
              </Form>
            )}
          </Formik>

          <div>
            <section className="mt-5">
              <h4>User Rooms</h4>
              <div className='col-md-8'>
                <UserRooms id={id} email={details.email}/>
              </div>
            </section>

            <section className='mt-5'>
              <h4>Associated Room IDs</h4>
              <div>
                <UserAssociatedRooms id={id} email={details.email} />
              </div>
            </section>

            <section className="mt-5">
              <h4>Weddings Groups</h4>
              <div>
                <UserGroups id={id} />
              </div>
            </section>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ManageClientAccount;
