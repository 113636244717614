import { IODataFilter } from '@/common/OData/ODataFilters';
import { isIDateRangeValue } from 'common/Form/ODataDateRangePicker';
import { isRangeValue } from 'common/Form/ODataRangePicker';
/**
 * Given a date object, take into account the timezone of the browser
 * and properly apply the necessary time offset, to return a utc localized date. * 
 * @param date 
 * @param isEndDate will set the time of the date object passed in to 23:59:59, before localizing to utc
 * @returns 
 */
const localizeDateToUtcForOdataFiltering = (date:Date, isEndDate:boolean = false):string=>{
  let currentDate:Date = date;
  if(isEndDate){
    currentDate = new Date();
    currentDate.setFullYear(date.getFullYear());
    currentDate.setMonth(date.getMonth());
    currentDate.setDate(date.getDate());
    currentDate.setHours(23,59,59);
  }
  const localISOString = currentDate.toISOString();
  const utcDate = new Date(localISOString);
  return utcDate.toISOString();
  
}

export class ODataTableHelper {
  /**
   *
   * @param pageSize
   * @param currentPage
   * @param dataTableColumnFilters
   * @param dataTableSort
   * @param filters
   * @param includeCount
   * @param freeFormColumnFilterArgs pass any additional custom created OData filtering args. WARNING: only use this if you know what you're doing
   * @returns
   */
  public static constructODataQueryArgs(
    pageSize: number | null,
    currentPage: number,
    dataTableColumnFilters?: Array<{ columnName: string; value: string }>,
    dataTableSort?: Array<{
      columnName: string;
      sortOrder: 'asc' | 'desc' | string;
    }>,
    filters?: IODataFilter[],
    includeCount: boolean = true,
    freeFormColumnFilterArgs?: string,
  ): string {
    const queryStringArgs: string[] = [];
    const filterArgs: string[] = [];
    if (pageSize) {
      queryStringArgs.push(`$top=${pageSize}`);
    }
    if (includeCount) {
      queryStringArgs.push('$count=true');
    }
    if (currentPage > 0) {
      queryStringArgs.push(`$skip=${currentPage * pageSize}`);
    }
    if (dataTableColumnFilters?.length > 0) {
      filterArgs.push(
        ...dataTableColumnFilters.map(
          ({ columnName, value }) =>
            `(contains(${columnName},'${value.replace("'", "''")}'))`,
        ),
      );
    }
    if (filters?.length > 0) {
      filters.forEach(f => {
        //this is a special check where when our filter is intentionally looking for nulls,
        //there's no `value` tied to the filter. So this checks for that condition,
        //in that, if something came down this way, and it *wasn't* trying to do a `eq null` operation and it also didn't have a filer value
        //something went wrong further up, and we cannot continue/support the operation, therefore, return
        if (f.modifier !== 'eq null' && !f.value) return;

        if (f.modifier === 'eq null') {
          filterArgs.push(`(${f.name} ${f.modifier})`);
          return;
        }

        if (f.modifier === 'in list') {
          filterArgs.push(`(${f.name}/any(d:contains(d,'${f.value}')))`);
          return;
        }

        //handle date ranges
        if (f.value instanceof Object && isIDateRangeValue(f.value)) {
          
          switch (f.modifier) {
            case 'between':
              filterArgs.push(
                `(${f.name} ge ${
                  localizeDateToUtcForOdataFiltering(f.value.fromDate)
                } and ${f.name} le ${
                  localizeDateToUtcForOdataFiltering(f.value.toDate, true)
                })`,
              );
              break;
            case 'eq':
                filterArgs.push(
                  `(${f.name} ge ${
                    localizeDateToUtcForOdataFiltering(f.value.fromDate)
                  } and ${f.name} le ${
                    localizeDateToUtcForOdataFiltering(f.value.fromDate, true)
                  })`,
                );
                break;
            case 'lt':
            case 'gt':            
               filterArgs.push(
                 `(${f.name} ${f.modifier} ${
                   localizeDateToUtcForOdataFiltering(f.value.fromDate)
                 })`,
               );              
              break;
          }
          return;
        }

         //handle ranges
         if (f.value instanceof Object && isRangeValue(f.value)) {
          
          switch (f.modifier) {
            case 'between':
              filterArgs.push(
                `(${f.name} ge ${
                  f.value.from
                } and ${f.name} le ${
                 f.value.to
                })`,
              );
              break;
            case 'eq':
            case 'lt':
            case 'gt':            
               filterArgs.push(
                 `(${f.name} ${f.modifier} ${
                   f.value.from
                 })`,
               );              
              break;
          }
          return;
        }

        //handle booleans or numbers
        if (typeof f.value === 'boolean' || typeof f.value === 'number') {
          filterArgs.push(
            `(${f.name} ${f.modifier ? f.modifier : 'eq'} ${f.value})`,
          );
          return;
        }

        //handle arrays
        if (Array.isArray(f.value)) {
          filterArgs.push(
            `(${f.name} in (${"'" + f.value.join("','") + "'"}))`.replace(
              /''/g,
              null,
            ),
          );
          return;
        }

        //finally handle string
        filterArgs.push(
          `(${f.name} eq '${(f.value as string).replace("'", "''")}')`,
        );
      });
    }
    if (freeFormColumnFilterArgs) {
      filterArgs.push(freeFormColumnFilterArgs);
    }
    if (filterArgs.length > 0) {
      queryStringArgs.push(`$filter=${filterArgs.join(' and ')}`);
    }
    if (dataTableSort.length > 0) {
      queryStringArgs.push(
        `$orderby=${dataTableSort
          .map(({ columnName, sortOrder }) => `${columnName} ${sortOrder}`)
          .join(',')}`,
      );
    }
    if (queryStringArgs.length <= 0) {
      return '';
    }

    return queryStringArgs.join('&');
  }
}
