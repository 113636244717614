import { CSSProperties, PropsWithChildren, useState } from 'react';

import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faComment,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import styles from './style.module.scss';
import { NoteModal } from './_Modal';
import { Button } from 'reactstrap';
import { formatUtcDateStringToLocalTime } from 'common/utils/dateUtils';


export interface NoteProps {
  adminID?: string;
  type?: string;
  id?: string;
  note?: string;
  module?: string;
  createdAt?: string;
  createdBy?: string;
  iconVariant?: string;
  iconClassName?: string;
  userId?: string;
  fmt?: string;
  updatedAt?: string;
  updatedBy?: string;
}

export const NoteItem = ({
  id,
  note,
  createdAt,
  createdBy,
  iconVariant,
  iconClassName,
  type,
  module,
  updatedAt,
  updatedBy,
}: NoteProps): JSX.Element => {
  const icon = displayIcon(iconVariant);
  const iconClass = displayClassName(iconClassName);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const capitalizeAdminName = str => {
    if (!str) {
      return;
    }

    const userName = str
      .toLowerCase()
      .split(' ')
      .map(name => {
        return name?.charAt(0).toUpperCase() + name?.slice(1);
      })
      .join(' ');

    return userName;
  };

  return (
    <>
      {type && (
        <>
          <VerticalTimelineElement
            id={id}
            className="vertical-timeline-element"
            iconStyle={embedStyles}
            iconClassName={iconClass}
            icon={icon}
            contentStyle={{ marginLeft: '45px' }}
          >
            <div className={styles.container} style={{ flexWrap: 'wrap' }}>
              <Button onClick={toggle} id={'note-' + id} color="link">
                {type}
              </Button>
            </div>
            {note && <FormattedNote>{note}</FormattedNote>}
            <div>
              {createdAt && (
                <span className="text-success">
                  Created at {formatUtcDateStringToLocalTime(createdAt)}
                </span>
              )}
              {createdBy && (
                <span className={styles.user_name}>
                  by {capitalizeAdminName(createdBy)}
                </span>
              )}
              {updatedAt && (
                <span className="text-info pl-1">
                  {' '}
                  <br />{' '}
                  <i>Updated at {formatUtcDateStringToLocalTime(updatedAt)}</i>
                </span>
              )}
              {updatedBy && (
                <span className="text-info pl-1">
                  {' '}
                  by <i>{capitalizeAdminName(updatedBy)}</i>
                </span>
              )}
            </div>
          </VerticalTimelineElement>
          <NoteModal
            isOpen={modal}
            closeModal={toggle}
            id={id}
            note={note}
            type={type}
            module={module}
            createdAt={createdAt}
          />
        </>
      )}
    </>
  );
};

// iconStyles takes an object for styles
const embedStyles: CSSProperties = {
  width: '34px',
  height: ' 34px',
  borderRadius: '50px',
  boxShadow: '0 0 0 5px #fff',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  left: '-4px',
};

const displayIcon = (type: string) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'text':
      return <FontAwesomeIcon icon={faComment} color="#fff" />;
    case 'email':
      return <FontAwesomeIcon icon={faEnvelope} color="#fff" />;
    case 'phone':
      return <FontAwesomeIcon icon={faPhone} color="#fff" />;
    default:
      return <FontAwesomeIcon icon={faComment} color="#fff" />;
  }
};

const displayClassName = (type: string) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'text':
      return styles.text;
    case 'email':
      return styles.email;
    case 'phone':
      return styles.phone;
    default:
      return styles.text;
  }
};

export const FormattedNote = ({
  children,
}: PropsWithChildren<{ children: string }>): JSX.Element => {
  return (
    <div className={styles.note_container}>
      {children.split(/\\n+/).map((text, i) => {
        return (
          <span className={styles.note} key={i}>
            {text.replaceAll('"', '')}
          </span>
        );
      })}
    </div>
  );
};
