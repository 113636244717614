import React, { useState, memo, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Row, Col, FormGroup, Input, FormFeedback } from 'reactstrap';
import Select from 'react-select';
const dropdownStyles = {
    container: (baseStyles) => ({
        ...baseStyles,
        width: '100%',
    }),
    dropdownIndicator: (baseStyles) => ({
        ...baseStyles,
        color: '#495057',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
    }),
    indicatorsContainer: (baseStyles) => ({
        ...baseStyles,
        color: '#495057',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        color: '#495057',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
    }),
};
const rangeDropdownOptions = [
    { value: 'between', label: 'Is Between' },
    { value: 'gt', label: 'Is Greater Than' },
    { value: 'lt', label: 'Is Less Than' },
    { value: 'eq', label: 'Is Equal To' },
];

export interface IRangeValues {
    from?: string | null | undefined;
    to?: string | null | undefined;
}

export const isRangeValue = (object: any): object is IRangeValues => {
    return 'from' in object;
};
type Props = {
    label: string,
    name: string,
    onChange:Function
  }
const ODataRangePicker = forwardRef(({ label, onChange, name }:Props, ref) => {
    const [modifier, setModifier] = useState(null);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [toValidator, setToValidator] = useState({
        valid: true,
        validationMessage: null,
    });

    useImperativeHandle(ref, () => {
        return {
            onReset() {
                setFrom('');
                setTo('');
                setModifier('')
            }
        };
    }, []);
    useEffect(() => {
        if (modifier === 'between') {
            if (
                from === to || (to && (parseInt(to) < parseInt(from)))
            ) {
                setToValidator({
                    valid: false,
                    validationMessage:
                        'To should be greater than From value',
                });
                onChange({ isValid: false, name, modifier: modifier, value: { from, to } });
                return;
            }
            else {
                setToValidator({
                    valid: true,
                    validationMessage: null,
                });
            }
        }
        onChange({ name, modifier: modifier, value: { from, to } });
    }, [from, to, modifier]);


    const handleModifierChange = (item) => {
        if (item?.value !== 'between') {
            setTo('');
            setToValidator({
                valid: true,
                validationMessage: null,
            });
        } else if (!item.value) {
            setFrom('');
            setTo('');
        }
        setModifier(item?.value)
    };

    return (
        <Row>
            <Col md={3}>
                <label>{label}</label>
            </Col>
            <Col>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Select
                                placeholder="--Select--"
                                styles={dropdownStyles}
                                options={rangeDropdownOptions}
                                hideSelectedOptions
                                isClearable
                                onChange={handleModifierChange}
                                value={
                                    modifier
                                        ? rangeDropdownOptions.find(
                                            i => i.value === modifier,
                                        )
                                        : null
                                }
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Input
                                disabled={!modifier}
                                className="form-control"
                                value={from}
                                placeholder="From"
                                type='number'
                                onChange={event => { setFrom(event.target.value) }}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Input
                                disabled={modifier !== 'between'}
                                className={`form-control ${!toValidator.valid && 'is-invalid'
                                    }`}
                                value={to}
                                placeholder="To"
                                type='number'
                                onChange={event => { setTo(event.target.value) }}
                            />
                            {/*This is a dummy input to leverage formfeedback*/}
                            <Input invalid={!toValidator.valid} hidden />
                            <FormFeedback>
                                {toValidator?.validationMessage}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

});

export default memo(ODataRangePicker);
