import { put, takeLatest } from 'redux-saga/effects';
import hotelContentApi from 'api/hotelContent';
import { IngestionLogsActions as actions } from './index';
import { toast } from 'react-toastify';

function* loadIngestionLogs() {
  try {
    yield actions.setLoading(true);
    const response =
      yield hotelContentApi.staticPricing.staticPricingIngestionLogList();
    yield put(actions.setIngestionLogsList(response));
    yield actions.setLoading(false);
  } catch (e: any) {
    toast.error(e.message);
  }
}

export function* staticPricingIngestionLogSaga() {
  yield takeLatest(actions.loadIngestionLogs.type, loadIngestionLogs);
}
