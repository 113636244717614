import { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';



declare module 'axios' {
  interface AxiosRequestConfig {
    tokenRefreshed?: boolean;
  }
}

export function withAuthentication(client: AxiosInstance) {
  client.interceptors.request.use(async request => {
    const token = getAuthToken();
    if (token == null) return request;

    request.headers['Authorization'] = `Bearer ${token.id_token}`;

    if (request.tokenRefreshed === undefined) request.tokenRefreshed = false;

    return request;
  });

  client.interceptors.response.use(undefined, async error => {
    if (!isAuthError(error) || error.config.tokenRefreshed) throw error;

    const token = getAuthToken();
    if (token?.refresh_token == null) throw error;

    error.config.tokenRefreshed = true;
    

    return await client(error.config);
  });

  return client;
}

function getAuthToken():null|{id_token:string, refresh_token:string} {
  const loggedInUser = JSON.parse(localStorage.getItem("loginUser"));
  if (!loggedInUser) return null;
  return loggedInUser;
}

interface AuthError {
  config: AxiosRequestConfig;
  response: AxiosResponse;
}

export function isAuthError(error: any): error is AuthError {
  const err = error as AuthError;
  return err.config !== undefined && err.response?.status === 401;
}
