/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
} from 'reactstrap';

import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TransitionGroup } from 'react-transition-group';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TextField } from '../../../../common/Form/TextInput';

import PageTitle from '../../../../Layout/AppMain/PageTitle';

import weddingPackage from '../../../../api/weddingPackage';

function PackageCreate({
  weddingPackageId,
  resortId,
  updateListView,
  hotelId,
}) {
  const validationSchema = Yup.object({
    packageName: Yup.string().required('The package name is required.'),
    packageRate: Yup.string().required('The package  rate is required.'),
    packageDepositPrice: Yup.number().required(
      'The package deposite price is required.'
    ),
    minumimNoofGuest: Yup.number().required('Minimum # of Guests is required.'),
    maximumNoofGuest: Yup.number().required('Maximum # of Guests is required.'),
    noofGuestsIncluded: Yup.number().required(
      '# of Guests Included is required.'
    ),
    additionalPricePerHead: Yup.number().required(
      'Additional price per head is required.'
    ),
  });

  const [initialValues, setInitialValues] = useState({
    packageName: '',
    packageRate: '',
    packageDepositPrice: 0,
    destinationID: 0,
    subDestinationID: 0,
    resortID: resortId,
    packageIconClass: '',
    weddingPackageId: 0,
    ptidAdminHotelId: hotelId,
    minumimNoofGuest: 0,
    maximumNoofGuest: 0,
    noofGuestsIncluded: 0,
    additionalPricePerHead: 0,
  });
  const [description, setDescription] = useState('');
  const [resort, updateResort] = useState([]);

  const onChangeDescription = (e, editor) => {
    setDescription(editor.getData());
  };

  const savePackage = async (value) => {
    await weddingPackage.packagedetails.save(value).then((response) => {
      if (typeof response === 'string') toast.error(response);
      else {
        toast.success('Wedding package saved successfully');
        updateListView(true);
      }
    });
  };

  useEffect(() => {
    const getResort = async () => {
      const resorts = await weddingPackage.resort.getResortList();
      updateResort(resorts);
      if (resortId) {
        const resort = resorts.find(
          (x) => x.resortID === parseInt(resortId, 10)
        );
        // loadSubDestination(resort.destinationID);
        setInitialValues({
          ...initialValues,
          destinationID: resort.destinationID,
          subDestinationID: resort.subDestinationID,
        });
      }
    };
    const setResorts = async () => {
      await getResort();
    };
    setResorts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resortId]);

  useEffect(() => {
    const id = weddingPackageId;
    if (id && id > 0) {
      weddingPackage.packagedetails.getpackageById(id).then((res) => {
        const Resort = resort.find(
          (x) => x.resortID === parseInt(res.resortID, 10)
        );
        if (Resort) {
          res.destinationID = Resort.destinationID;
          res.subDestinationID = Resort.subDestinationID;
        }

        setInitialValues(res);

        setDescription(res.packageDescription);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingPackageId]);

  return (
    <TransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={ 0 }
      transitionEnter={ false }
      transitionLeave={ false }
    >
      {!weddingPackageId && (
        <Container fluid>
          <div>
            <PageTitle
              heading="Wedding Package Management"
              icon="lnr-apartment icon-gradient bg-tempting-azure"
            />
          </div>
        </Container>
      )}

      <Formik
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        enableReinitialize
        onSubmit={ async (values) => {
          const newValues = { ...values };
          newValues.packageDescription = description;
          await savePackage(newValues);
        } }
      >
        {({ isSubmitting }) => (
          <Card>
            <CardBody>
              {weddingPackageId == null ? (
                <Button
                  className="mb-2 mr-2 btn-icon float-right"
                  color="primary"
                  onClick={ () => {
                    updateListView(true);
                  } }
                >
                  Back To List
                </Button>
              ) : (
                null
              )}

              <Form autoComplete="off">
                <Row>
                  <Col md="9">
                    <Card>
                      {!weddingPackageId && (
                        <CardHeader>Create Package</CardHeader>
                      )}

                      <CardBody>
                        <FormGroup>
                          <TextField label="Package Name" name="packageName" />
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="Description/Details">
                            {' '}
                            Description/Details{' '}
                          </label>
                          <CKEditor
                            id="Description/Details"
                            editor={ ClassicEditor }
                            data={ description }
                            onChange={ onChangeDescription }
                          />
                        </FormGroup>

                        <FormGroup>
                          <TextField label="Package Rate" name="packageRate" />
                        </FormGroup>

                        <FormGroup>
                          <TextField
                            type="number"
                            label="Package Deposit Price"
                            name="packageDepositPrice"
                          />
                        </FormGroup>

                        <FormGroup>
                          <TextField
                            type="number"
                            min="1"
                            label="Minimum # of Guests"
                            name="minumimNoofGuest"
                          />
                        </FormGroup>

                        <FormGroup>
                          <TextField
                            type="number"
                            min="1"
                            label="Maximum # of Guests"
                            name="maximumNoofGuest"
                          />
                        </FormGroup>

                        <FormGroup>
                          <TextField
                            type="number"
                            min="1"
                            label="# of Guests Included"
                            name="noofGuestsIncluded"
                          />
                        </FormGroup>

                        <FormGroup>
                          <TextField
                            type="number"
                            label="Additional price per head "
                            name="additionalPricePerHead"
                          />
                        </FormGroup>
                      </CardBody>
                      <Button
                        disabled={ isSubmitting }
                        type="submit"
                        color="primary"
                        className="mt-1"
                      >
                        {isSubmitting ? 'Processing....' : 'Save'}
                      </Button>
                    </Card>
                  </Col>
                  <Col md="3"> </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        )}
      </Formik>
    </TransitionGroup>
  );
}

PackageCreate.defaultProps = {
  weddingPackageId: 0,
  resortId: 0,
  updateListView: () => null,
  hotelId: '',
};

PackageCreate.propTypes = {
  weddingPackageId: PropTypes.number,
  resortId: PropTypes.number,
  updateListView: PropTypes.func,
  hotelId: PropTypes.string,
};

export default PackageCreate;
