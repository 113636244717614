/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';

import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
import { withRouter } from 'react-router-dom';
import hotel from '../../../../api/hotelContent';
import { TextCurrency } from '../../../../common/Form/TextInputCurrency';

class TransferShare extends React.Component {
  // state = TransferShare.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  validationSchema = Yup.object({
    // airportToHotel: Yup.array(Yup.object({
    //     value: Yup.string().required('value is required').test('is-decimal',
    //     'Either invalid decimal or the number has more than 2 digits after decimal',
    //     value => (value + "").match(/^(\d{1,5}|\d{0,5}\.\d{1,2})$/),)

    // })),
    // hotelToAirport: Yup.array(Yup.object({
    //     value: Yup.string().required('value is required').test('is-decimal',
    //     'Either invalid decimal or the number has more than 2 digits after decimal',
    //     value => (value + "").match(/^(\d{1,5}|\d{0,5}\.\d{1,2})$/),)
    // })),
    roundTrip: Yup.array(
      Yup.object({
        value: Yup.string()
          .required('value is required')
          .test(
            'is-decimal',
            'Either invalid decimal or the number has more than 6 digits after decimal',
            (value) => `${value}`.match(/^(\d{1,5}|\d{0,5}\.\d{1,6})$/)
          ),
      })
    ),
  });

  constructor(props) {
    super(props);
    this.state = {
      ...ToastContainer.defaultProps,
      initialValues: [],
    };
  }

  async savePrice(value) {
    const newValue = { ...value };
    newValue.HotelID = this.props.match.params.id;
    await hotel.tranferPrice.saveTrasferPrice(newValue);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    hotel.tranferPrice.getTransferPrice(id).then((response) => {
      const initialValues = {};
      // initialValues.airportToHotel=response.airportToHotel;
      // initialValues.hotelToAirport=response.hotelToAirport;
      initialValues.roundTrip = response.roundTrip;

      this.setState({ initialValues });
    });
  }

  render() {
    const { initialValues } = this.state;

    return (
      <Formik
        initialValues={ initialValues }
        validationSchema={ this.validationSchema }
        enableReinitialize
        onSubmit={ async (values) => {
          await this.savePrice(values);
          toast.success('Transfer pricing for the hotel save successfully.');
          // return new Promise(res=>setTimeout(res,2500));
        } }
      >
        {({ values, isSubmitting }) => (
          <Card>
            <CardBody>
              {this.state.initialValues.roundTrip && (
                <Form>
                  {/* <Row className="mt-3">
                                        <Col md="6" >
                                            <Card >
                                                <CardHeader>One way from Airport to Hotel</CardHeader>

                                                <FieldArray name="airportToHotel">
                                                    {({ push, remove }) => (
                                                        <CardBody>
                                                            
                                                            {values.airportToHotel && values.airportToHotel.map((value, index) => (

                                                                <FormGroup key={index}>

                                                                    <label htmlFor={`airportToHotel[${index}].value`}>{value.label}</label>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">$</span>
                                                                        </div>
                                                                        <Field  className={errors &&
                                                                            errors.airportToHotel &&
                                                                            errors.airportToHotel[index] &&
                                                                            errors.airportToHotel[index].value &&
                                                                            touched &&
                                                                            touched.airportToHotel &&
                                                                            touched.airportToHotel[index] &&
                                                                            touched.airportToHotel[index].value ? 'is-invalid form-control' : 'form-control'} name={`airportToHotel[${index}].value`}>
                                                                        </Field>
                                                                        {errors &&
                                                                            errors.airportToHotel &&
                                                                            errors.airportToHotel[index] &&
                                                                            errors.airportToHotel[index].value &&
                                                                            touched &&
                                                                            touched.airportToHotel &&
                                                                            touched.airportToHotel[index] &&
                                                                            touched.airportToHotel[index].value && (
                                                                                <div className="invalid-validation">
                                                                                    {errors.airportToHotel[index].value}
                                                                                </div>
                                                                            )}
                                                                    </div>

                                                                </FormGroup>))}

                                                        </CardBody>

                                                    )}
                                                </FieldArray>

                                            </Card>
                                        </Col>

                                        <Col md="6">
                                            <Card >
                                                <CardHeader>One way from Hotel to Airport</CardHeader>

                                                <FieldArray name="hotelToAirport">
                                                    {({ push, remove }) => (
                                                        <CardBody>
                                                            {values.hotelToAirport && values.hotelToAirport.map((value, index) => (

                                                                <FormGroup key={index}>

                                                                    <label htmlFor={`hotelToAirport[${index}].value`}>{value.label}</label>
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">$</span>
                                                                        </div>
                                                                        <Field className={errors &&
                                                                            errors.hotelToAirport &&
                                                                            errors.hotelToAirport[index] &&
                                                                            errors.hotelToAirport[index].value &&
                                                                            touched &&
                                                                            touched.hotelToAirport &&
                                                                            touched.hotelToAirport[index] &&
                                                                            touched.hotelToAirport[index].value ? 'is-invalid form-control' : 'form-control'} name={`hotelToAirport[${index}].value`}>
                                                                        </Field>
                                                                        {errors &&
                                                                            errors.hotelToAirport &&
                                                                            errors.hotelToAirport[index] &&
                                                                            errors.hotelToAirport[index].value &&
                                                                            touched &&
                                                                            touched.hotelToAirport &&
                                                                            touched.hotelToAirport[index] &&
                                                                            touched.hotelToAirport[index].value && (
                                                                                <div className="invalid-validation">
                                                                                    {errors.hotelToAirport[index].value}
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                </FormGroup>

                                                            ))}

                                                        </CardBody>

                                                    )}
                                                </FieldArray>

                                            </Card>
                                        </Col>

                                    </Row> */}

                  <div className="row mt-3">
                    <div className="col-3" />

                    <div className="col-6">
                      <Card>
                        <CardHeader>
                          Transfer Pricing (All prices are round trip)
                        </CardHeader>

                        <FieldArray name="roundTrip">
                          {() => (
                            <CardBody>
                              {values.roundTrip
                                && values.roundTrip.map((value, index) => (
                                  <Row key={ index }>
                                    <Col md={ 6 }>
                                      <FormGroup key={ index }>
                                        <TextCurrency
                                          label={ value.label }
                                          name={ `roundTrip[${index}].value` }
                                        />
                                        {/* <label htmlFor={`roundTrip[${index}].value`}>{value.label}</label>
                                                                           
                                                                            <div className="input-group">
                                                                                <div className="input-group-prepend">
                                                                                    <span className="input-group-text">$</span>
                                                                                </div>

                                                                                <Field className={errors &&
                                                                                    errors.roundTrip &&
                                                                                    errors.roundTrip[index] &&
                                                                                    errors.roundTrip[index].value &&
                                                                                    touched &&
                                                                                    touched.roundTrip &&
                                                                                    touched.roundTrip[index] &&
                                                                                    touched.roundTrip[index].value ? 'is-invalid form-control' : 'form-control'} name={`roundTrip[${index}].value`}>
                                                                                </Field>
                                                                                {errors &&
                                                                                    errors.roundTrip &&
                                                                                    errors.roundTrip[index] &&
                                                                                    errors.roundTrip[index].value &&
                                                                                    touched &&
                                                                                    touched.roundTrip &&
                                                                                    touched.roundTrip[index] &&
                                                                                    touched.roundTrip[index].value && (
                                                                                        <div className="invalid-validation">
                                                                                            {errors.roundTrip[index].value}
                                                                                        </div>
                                                                                    )}

                                                                            </div> */}
                                      </FormGroup>
                                    </Col>
                                    <Col md={ 3 }>
                                      <label
                                        htmlFor={ `roundTrip[${index}].enabled` }
                                      >
                                        Enabled
                                      </label>
                                      <div className="input-group mt-2">
                                        <Field
                                          type="checkbox"
                                          name={ `roundTrip[${index}].enabled` }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                ))}
                            </CardBody>
                          )}
                        </FieldArray>
                      </Card>
                    </div>
                  </div>
                  <Button
                    disabled={ isSubmitting }
                    type="submit"
                    color="primary"
                    className="mt-1"
                  >
                    {isSubmitting ? 'Processing....' : 'Save Price'}
                  </Button>

                  {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                </Form>
              )}
            </CardBody>
          </Card>
        )}
      </Formik>
    );
  }
}
export default withRouter(TransferShare);
