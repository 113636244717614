import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from 'types/RootState';

const selectSlice = (state: RootState) => state.staticPricingIngestionLogRoot || initialState;
export const selectIngestionLogs = createSelector(
    [selectSlice],
    state => ({
      list: state.ingestionLogsList,
      loading: state.loading
    }),
  );