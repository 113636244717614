import React, { createRef } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	Collapse,
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	FormGroup,
	Input,
	Spinner,
	CardFooter,
	Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import ReactTable from 'react-table';
import usersApi from 'api/user';
import salesLead from 'api/salesLead';
import PageTitle from 'Layout/AppMain/PageTitle';
import { ODataDateRangePicker } from 'common/Form/ODataDateRangePicker'
import { ODataFilterOptions } from 'common/OData/ODataFilters';
import { toast } from 'react-toastify';
import { ODataTableHelper } from 'common/OData/ODataTableHelper';
import ODataDataTableComponent, { IODataDataTableComponentState } from 'common/OData/components/BaseODataDataTableComponent';
import { LegacyLiveBookingODataApiClient } from 'api/OData/LegacyLiveBookingODataApiClient';
import { LegacyLiveBookingODataEntities } from 'api/OData/LegacyLiveBooking/ODataEntities';


const formatDate = (value) => {
	if (!value) {
		return (<span>N/A</span>)
	}
	return (<span>{format(utcToZonedTime(value, 'America/Chicago'), 'MM/dd/yyyy hh:mm:ss a')}</span>)
}


interface ICrmSalesLeadListPageState extends IODataDataTableComponentState {
	data: Array<any>,
	pages?: number,
	loading: boolean
	salesSourceOptions: Array<any>,
	salesStatusOptions: Array<any>,
	totalDataCount: number;
	dataTableFilters: any,
	dataTablePageSize: number,
	dataTableCurrentPage: number,
	dataTableSorting: Array<any>,
	matchingPtidClientRecords: Array<any>,
}

interface ICrmSalesLeadRecord {
	AssignedCrmAgentEmail: string | null;
	AssignedCrmAgentId: string | null;
	CRMLeadId: string | null;
	DateCreated: Date | null;
	DateModified: Date | null;
	EmailAddress: string | null;
	FirstName: string | null;
	FullName: string | null;
	GroupSize: number | null;
	LastName: string | null;
	Phone: string | null;
	SalesSource: string | null;
	Status: string | null;
}


export default class CRMSalesLeadListPage extends ODataDataTableComponent<ICrmSalesLeadRecord, ICrmSalesLeadListPageState> {
	reactTable: React.RefObject<any> = createRef();
	odataApiClient = new LegacyLiveBookingODataApiClient();
	csvExportFileNamePrefix = 'CRM-Sales-Lead-List';
	odataEntityType = LegacyLiveBookingODataEntities.OasisUserDetails;

	private static crmLeadsMinDate = () => {
		const minDate = new Date();
		minDate.setDate(minDate.getDate() - 180);
		return minDate;
	};

	state: ICrmSalesLeadListPageState = {
		totalDataCount: 0,
		data: [],
		dataTableFilters: [],
		pages: null,
		loading: true,
		dataTablePageSize: 10,
		dataTableCurrentPage: 0,
		dataTableSorting: [],
		salesSourceOptions: [],
		salesStatusOptions: [],
		filters: [
			{ name: 'DateCreated', modifier: null, value: null },
			{ name: 'DateModified', modifier: null, value: null },
			{ name: 'Status', value: null },
			{ name: 'GroupSize', modifier: null, value: null },
			{ name: 'SalesSource', value: null },
		],
		matchingPtidClientRecords: []
	}


	async componentDidMount() {
		try { await Promise.all([this.loadSalesSourceOptions(), this.loadSalesStatusOptions()]); }
		catch (err) {
			toast.error("Unexpected server side or network error. Please try again or contact the dev team.");
		}
	}



	loadSalesSourceOptions = async () => {
		const { dropdownList } = await salesLead.salesLeadDetails.getSalesSourceList();
		let options: Array<any> = [];
		for (const [key, value] of Object.entries(dropdownList).filter(([_, value]) => value.toString() !== '')) {
			options.push({ value: key, text: value });
		}
		options = options.sort(({ text: item1 }, { text: item2 }) => item1.localeCompare(item2));
		options = [{ value: '', text: '--Select--' }, ...options]
		this.setState({
			salesSourceOptions: [...options].map(i => (
				<option key={i.value}
					value={i.value}
				>{i.text}</option>))
		});
	}

	loadSalesStatusOptions = async () => {
		const data = await usersApi.ODataCrmDataAccess.get('StatusesList');

		const options: Array<any> = data.map(d => ({ value: d, text: d }));
		this.setState({
			salesStatusOptions: [{ value: '', text: '--Select--' }, ...options].map((i, idx) => (
				<option key={idx}
					value={i.value}
				>{i.text}</option>))
		});
	}

	fetchData = async (reactTableState) => {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState({ loading: true });


		this.setState({
			dataTableFilters: [...reactTableState.filtered],
			dataTablePageSize: reactTableState.pageSize,
			dataTableCurrentPage: reactTableState.page,
			dataTableSorting: [...reactTableState.sorted]
		});


		const queryOptions = {
			pageSize: reactTableState.pageSize,
			currentPage: reactTableState.page,
			dataTableSort: reactTableState.sorted.map(({ id, desc }) => ({ columnName: id, sortOrder: desc ? 'desc' : 'asc' })),
			dataTableFilters: reactTableState.filtered.map(({ id, value }) => ({ columnName: id, value })),
			filters: this.state.filters.filter(f => f.value !== null)
		};

		const queryStringArgs = ODataTableHelper.constructODataQueryArgs(
			queryOptions.pageSize,
			queryOptions.currentPage,
			queryOptions.dataTableFilters,
			queryOptions.dataTableSort,
			queryOptions.filters
		);
		const { data, count } = await usersApi.ODataCrmDataAccess.getListResponse(queryStringArgs) as any;

		//no comment from you Dave W.
		const lookUpEmails = data.map(d => d.EmailAddress).filter(e => !!e);

		if (lookUpEmails.length > 0) {
			//only if we have any emails, should we invoke the api
			const matchingResults = await usersApi.ODataCrmDataAccess.post('GetCorrespondingPtidUserInfoByEmail', lookUpEmails);
			this.setState({ matchingPtidClientRecords: [...matchingResults] });

		}


		const totalDataCount = count;
		this.setState({
			data,
			pages: Math.ceil(totalDataCount / reactTableState.pageSize),
			loading: false,
			totalDataCount,

		});
	}

	private static getGroupSizeFilterOptions = () => ([
		{ value: '', text: '--Select--' },
		{ value: 'gt', text: 'Greater Than' },
		{ value: 'eq', text: 'Equal To' },
		{ value: 'lt', text: 'Less Than' },
	].map(i => (
		<option key={i.value}
			value={i.value}
		>{i.text}</option>)));

	render() {
		const { data, pages, loading, salesSourceOptions,
			filters, totalDataCount, salesStatusOptions } = this.state;

		//pull out our filters
		const dateCreatedFilter = filters.find(f => f.name === 'DateCreated');
		const dateModifiedFilter = filters.find(f => f.name === 'DateModified');
		const groupSizeFilter = filters.find(f => f.name === 'GroupSize');
		const salesSourceFilter = filters.find(f => f.name === 'SalesSource');
		const statusFilter = filters.find(f => f.name === 'Status');

		const columns = [
			{
				Header: 'Full Name',
				accessor: 'FullName',
				maxWidth: 420,
				minWidth: 200,
				Cell: ({ original, value }) => {

					//FUTURE USE:
					const matchingPtidRecord = this.state.matchingPtidClientRecords.find(r => r.email === original.EmailAddress);
					if (!matchingPtidRecord)
						return (value);

					return (


						<span
							className="anchor-link"
							onClick={() => {
								//redirect to client accounts

								const searchValue = {
									PtidUserId: matchingPtidRecord.id
								};
								localStorage.setItem('searchValue', JSON.stringify(searchValue));
								this.props.history.push({ pathname: '/salesleads/guest-list' });

							}
							}
						>
							{`${value}`}
						</span>
					)
				},
				Footer: (
					<>
						<Row>
							<Col>
								<span>
									<strong>Total Sales Leads: </strong>
									{totalDataCount}
								</span>
							</Col>
						</Row>
						<Row>
							<Col>
								<UncontrolledButtonDropdown>
									<DropdownToggle
										caret
										className="mb-2 mr-2"
										color="primary"
									>
										Download .csv
									</DropdownToggle>
									<DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
										<DropdownItem
											onClick={() => this.handleExportToCSV('currentPage')}
										>
											Current Page
										</DropdownItem>
										<DropdownItem
											onClick={() => this.handleExportToCSV('filtered')}
										>
											Filtered Leads
										</DropdownItem>
										<DropdownItem
											onClick={() => this.handleExportToCSV('all')}
										>
											All Leads
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledButtonDropdown>
							</Col>
						</Row>
					</>
				),
			},

			{

				Header: 'Email',
				accessor: 'EmailAddress',
				maxWidth: 420,
				minWidth: 200,

			},

			{

				Header: 'CRM Lead Id',
				accessor: 'CRMLeadId',
				maxWidth: 420,
				minWidth: 200,
				Cell: ({ value }) => {
					return (
						<a href={`${process.env.REACT_APP_CRMLINK.replace('Opportunities', 'Leads')}${value}`}
							title="View record in CRM" target='_blank' rel="noreferrer"
						>
							{`${value}`}
						</a>
					);
				}
			},
			{

				Header: 'Phone',
				accessor: 'Phone',
				maxWidth: 200,
				minWidth: 100,

			},
			{

				Header: 'Group Size',
				accessor: 'GroupSize',
				maxWidth: 200,
				minWidth: 80,
				filterable: false

			},
			{

				Header: 'Sales Source',
				accessor: 'SalesSource',
				maxWidth: 220,
				minWidth: 100,
				filterable: false

			},
			{

				Header: 'Status',
				accessor: 'Status',
				maxWidth: 200,
				minWidth: 120,
				filterable: false
			},
			{

				Header: 'Created Date',
				accessor: 'DateCreated',
				Cell: row => (formatDate(row.value)),
				minWidth: 160,
				filterable: false

			},
			{

				Header: 'Date Modified',
				accessor: 'DateModified',
				Cell: row => (formatDate(row.value)),
				minWidth: 160,
				filterable: false,
			},
			{

				Header: 'Assigned Agent Email',
				accessor: 'AssignedCrmAgentEmail',
				maxWidth: 320,
				minWidth: 200,
			},

		];

		return (
			<>
				<TransitionGroup
					component="div"
				//transitionName="TabsAnimation"
				//transitionAppear
				//transitionAppearTimeout={0}


				>
					<>
						<div>
							<PageTitle
								heading="Legacy CRM Sales Leads"
								subheading="CRM Sales Lead records created in the last 180 days"
								icon="pe-7s-gleam icon-gradient bg-tempting-azure"
							/>
						</div>
						<Row>
							<Col md="12">
								<Card className="main-card mb-3">
									<CardHeader
										style={{ display: 'block', cursor: 'pointer' }}
									>
										<div className="float-right mt-3 mr-3">
											<FontAwesomeIcon
												icon={faAngleDown}
												size="2x"
											/>
										</div>
									</CardHeader>
									<Collapse  isOpen={true}>
										<CardBody>
											<Row>
												<Col>
													<ODataDateRangePicker
														key={JSON.stringify(dateCreatedFilter)}
														label={'Created Date'}
														onChange={this.updateFilters}
														minDate={CRMSalesLeadListPage.crmLeadsMinDate()}
														odataFilter={dateCreatedFilter}
													/>
													<ODataDateRangePicker
														key={JSON.stringify(dateModifiedFilter)}
														label={'Modified Date'}
														onChange={this.updateFilters}
														minDate={CRMSalesLeadListPage.crmLeadsMinDate()}
														odataFilter={dateModifiedFilter}
													/>
												</Col>
												<Col md={4}>
													<Row>
														<Col md={3}>
															<label>Group Size</label>
														</Col>
														<Col md={5}>
															<FormGroup>
																<select
																	name="GroupSizeOption"
																	onChange={e => {
																		e.target.value === '' ? groupSizeFilter.modifier = null : groupSizeFilter.modifier = e.target.value as ODataFilterOptions;
																		this.updateFilters(groupSizeFilter);
																	}}
																	className="form-control"
																	value={groupSizeFilter?.modifier || ''}
																>
																	{CRMSalesLeadListPage.getGroupSizeFilterOptions()}
																</select>
															</FormGroup>
														</Col>
														<Col md={4}>
															<FormGroup>
																<Input
																	value={groupSizeFilter?.value?.toString()|| ''}
																	name="GroupSize"
																	type='number'
																	placeholder="Size"
																	disabled={!groupSizeFilter || !groupSizeFilter.modifier}
																	onChange={e => {
																		groupSizeFilter.value = +e.target.value;
																		this.updateFilters(groupSizeFilter);
																	}}
																/>
															</FormGroup>
														</Col>
													</Row>
												</Col>
												<Col md={3}>
													<Row>
														<Col md={3}>
															<label>Sales Source</label>
														</Col>
														<Col md={9}>
															<FormGroup>
																<Spinner size="sm" hidden={salesSourceOptions.length > 0} />
																<select
																	hidden={salesSourceOptions.length <= 0}
																	name="SalesSource"
																	onChange={e => {
																		salesSourceFilter.value = e.target.value;
																		this.updateFilters(salesSourceFilter)
																	}}
																	className="form-control"
																	value={salesSourceFilter?.value?.toString() || ''}
																>
																	{salesSourceOptions}
																</select>
															</FormGroup>
														</Col>
													</Row>
													<Row>
														<Col md={3}>
															<label>Status</label>
														</Col>
														<Col md={9}>
															<FormGroup>
																<Spinner size="sm" hidden={salesStatusOptions.length > 0} />
																<select
																	hidden={salesStatusOptions.length <= 0}
																	name="Status"
																	onChange={e => {
																		statusFilter.value = e.target.value;
																		this.updateFilters(statusFilter)
																	}}
																	className="form-control"
																	value={statusFilter?.value?.toString() || ''}
																>{salesStatusOptions}
																</select>
															</FormGroup>
														</Col>
													</Row>
												</Col>
											</Row>
										</CardBody>
										<CardFooter style={{ display: 'block' }}>
											<div className="float-left">
												<b>Note:</b>
												{' '}
												this is a compound search. Meaning, it's <code>DateCreated is xyz and DateModified is xyz and...</code>
												{' '}
												<br />
												<i>Not</i> <code>DateCreated is xyz or DateModified is xyz</code>
											</div>
											<div className="float-right">
												<Button outline onClick={this.clearAllFilters.bind(null,true)}>
													Clear All Filters
												</Button>{' '}
												<Button onClick={() => { this.fetchData(this.reactTable.current?.state) }}>Search</Button>
											</div>
											<div className='clearfix'></div>
										</CardFooter>
									</Collapse>

								</Card>
							</Col>
						</Row>

						<Row>
							<Col md="12">
								<ReactTable
									columns={columns}
									manual // Forces table not to paginate or sort automatically, so we can handle it server-side
									data={data}
									pages={pages} // Display the total number of pages
									loading={loading} // Display the loading overlay when we need it
									onFetchData={this.fetchData} // Request new data when things change
									filterable
									ref={this.reactTable}
									//filtered={dataTableFilters}
									defaultPageSize={10}
									pageSizeOptions={[10, 20, 30]}
									className="-striped -highlight"
									multiSort={false}
									defaultSorted={[{ // the sorting model for the table
										id: 'DateCreated',
										desc: true
									}]}
									showPaginationTop
									showPaginationBottom

								/>
							</Col>
						</Row>
					</>
				</TransitionGroup>
			</>
		);
	}
};
