import React, { useMemo } from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import { Button, Col, FormGroup,  Row } from 'reactstrap';

import * as Yup from 'yup';

import FormikSelect from 'common/Form/Select';
import { TextField } from 'common/Form/TextInput';
import { ROOM_ROLES } from '../../slice/types';

import { useAppDispatch } from 'store/hooks';
import { UsersActions as actions } from '../../slice';

const initialValues = {
  newRooms: [
    {
      roomId: '',
      roomRole: {
        label: "",
        value: null
      },
      isGroupLeader: false
    },
  ],
};

const validationSchema = Yup.object({
  newRooms: Yup.array()
    .of(
      Yup.object({
        roomId: Yup.string().required("Please enter a room ID!"),
        roomRole: Yup.object({
          label: Yup.string(),
          value: Yup.string()
        }),
        isGroupLeader: Yup.boolean(),
      }),
    )
    .min(1),
});

const _UserRoomAsc = ({ email, id }) => {
  const dispatch = useAppDispatch();

  const RoomRoleOptions = useMemo(() => {
    return Object.keys(ROOM_ROLES).map(k => ({
      value: k,
      label: ROOM_ROLES[k],
    }));
  }, []);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (!email) return;
    values.newRooms.forEach(room => {
      dispatch(
        actions.assignUserToRoom({
          roomId: room.roomId,
          roomRole: room.roomRole?.value || null,
          userEmail: email,
          userId: id,
          isGroupLeader: room.isGroupLeader,
        }),
      );
    });
    resetForm();
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ values, dirty, isSubmitting }) => {
          return (
            <>
              <Form>
                <FieldArray name="newRooms">
                  {({ push, remove }) => {
                    return (
                      <FormGroup>
                        {values.newRooms.map((room, index) => (
                          <Row className="mt-2" key={index}>
                            <Col className="col-sm-6 col-md-3">
                              <FormikSelect
                                name={`newRooms[${index}].roomRole`}
                                initialValue={values.newRooms[index].roomRole.value}
                                extractor={item => item}
                                items={RoomRoleOptions}
                                label={'Room Role'}
                              />
                            </Col>

                            <Col className="col-sm-6 col-md-4">
                              <TextField
                                label="Room Id"
                                name={`newRooms[${index}].roomId`}
                              />
                            </Col>

                            {index > 0 ? (
                              <Col className="col-sm-2">
                                <Button
                                  style={{ marginTop: '30px' }}
                                  onClick={() => remove(index)}
                                  color="danger"
                                  className="mb-2 mr-2 btn-icon btn-icon-only"
                                >
                                  {' '}
                                  <i className="pe-7s-trash btn-icon-wrapper">
                                    {' '}
                                  </i>
                                </Button>
                              </Col>
                            ) : null}

                            <Col className="col-sm-2">
                              <Button
                                style={{ marginTop: '30px' }}
                                onClick={() => {
                                  push({
                                    roomRole: '',
                                    roomId: '',
                                  });
                                }}
                                color="success"
                              >
                                <i className="pe-7s-plus btn-icon-wrapper"> </i>{' '}
                                Add
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      </FormGroup>
                    );
                  }}
                </FieldArray>
                <input
                  disabled={!dirty || isSubmitting}
                  type="submit"
                  className="btn btn-primary"
                  value="Submit"
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default _UserRoomAsc;
