/* eslint-disable no-extend-native */

declare global {
	interface String {
		/**Given a string, will try to convert its value to a boolean */
		toBoolean(): boolean;
	}
}

/**Given a string, will try to convert its value to a boolean */
String.prototype.toBoolean = function (): boolean {
	switch (this.toLowerCase()) {
		case 'true':
		case '1':
		case 'on':
		case 'yes':
			return true;
		default:
			return false;
	}
}

export { }