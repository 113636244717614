/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import classnames from 'classnames';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import SweetAlert from 'sweetalert-react';
import arrayChunk from 'lodash.chunk';
import { TransitionGroup } from 'react-transition-group';
import _ from 'lodash';
import { TextField } from '../../../../common/Form/TextInput';
import { TextArea } from '../../../../common/Form/TextArea';
import weddingPackage from '../../../../api/weddingPackage';
import PhotoWidgetDropzone from '../../../../common/imageUploader/photoWidgetDropzone';

function Edit({ venueId, resortID, updateListView }) {
  const validationSchema = Yup.object({
    destinationID: Yup.string().required('The destination is required'),
    subDestinationID: Yup.string().required('The sub destination is required'),
    resortID: Yup.string().required('Resort is required'),
    venueName: Yup.string().required('The venue name is required.'),
    capacity: Yup.string().required('Capacity is required.'),
  });

  const [initialValues, setInitialValues] = useState({
    venueName: '',
    destinationID: 0,
    subDestinationID: 0,
    resortID,
    capacity: 1,
    allowReligiousCeremony: false,
  });
  const [activeTab, setActiveTab] = useState('1');
  const [images, setImages] = useState([]);
  const [chunkedData, setChunkedData] = useState([]);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteVenueId, setDeleteVenueId] = useState(0);

  const [cocktailChecked, setCocktailChecked] = useState(false);
  const [ceremonyChecked, setCeremonyChecked] = useState(false);
  const [receptionChecked, setReceptionChecked] = useState(false);
  const [isActive, setIsActiveChecked] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const saveIntegration = async (value) => {
    await weddingPackage.venue.saveVenue(value).then((response) => {
      if (typeof (response) === 'string') toast.error(response);
      else {
        toast.success('Venue saved successfully.');
        updateListView(true);
      }
    });
  };

  const getImages = useCallback(async () => {
    const Images = await weddingPackage.venue.getImages(venueId);
    setImages(Images);
    setChunkedData(arrayChunk(Images, 3));
  }, [venueId]);

  useEffect(() => {
    const id = venueId;
    if (id && id > 0) {
      getImages();
      weddingPackage.venue.getVenueById(id).then((res) => {
        if (res.capacity === null) res.capacity = 1;
        if (res.allowReligiousCeremony === null) res.allowReligiousCeremony = false;
        if (res.ceremony === true) setCeremonyChecked(true);
        if (res.cocktail === true) setCocktailChecked(true);
        if (res.reception === true) setReceptionChecked(true);
        if (res.isActive === true) setIsActiveChecked(true);
        setInitialValues(res);
      });
    }
  }, [venueId, getImages]);

  const addImage = async (param) => {
    await weddingPackage.venue.uploadPhoto(
      param.Id,
      param.imageName,
      param.altTagText,
      param.blob
    );
    toast.success('Image saved successfully for the venue.');
    await getImages();
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...images];
    list[index][name] = value;
    setImages(list);
    setChunkedData(arrayChunk(list, 3));
  };

  const updateImage = (index) => {
    const image = images[index];
    const exceptList = _.reject(images, (o) => o.imageContentID === image.imageContentID);
    const isOrderExist = exceptList.some((d) => d.imageOrder === image.imageOrder);

    if (isOrderExist) {
      toast.error('Image display order already exists.');
      return;
    }

    weddingPackage.venue.updateImageDetails(image).then(() => {
      toast.success('The details of venue image updated successfully.');
    });
  };

  const showConfirmationToDelete = (id) => {
    setDeleteVenueId(id);
    setShow(true);
  };

  const deleteImage = () => {
    weddingPackage.venue
      .deleteImages({ imageContentID: deleteVenueId })
      .then(() => {
        toast.success('Image deleted succesfuly.');
        getImages();
      });
  };

  return (
    <>
      <SweetAlert
        show={ show }
        type="warning"
        title="Confirmation"
        text="Are you sure to delete this image?"
        showCancelButton
        onConfirm={ () => {
          deleteImage();
          setShow(false);
        } }
        onCancel={ () => {
          setShow(false);
        } }
        onClose={ () => console.log('close') }
      />
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }
      >
        <Container fluid />
        <Card>
          <CardBody>
            <Button
              className="mb-2 mr-2 btn-icon float-right"
              color="primary"
              onClick={ () => {
                updateListView(true);
              } }
            >
              Back To List
            </Button>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Nav justified>
              <NavItem>
                <NavLink
                  href="#"
                  className={ classnames({
                    active: activeTab === '1',
                  }) }
                  onClick={ () => {
                    toggle('1');
                  } }
                >
                  Venue Form
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={ classnames({
                    active: activeTab === '2',
                  }) }
                  onClick={ () => {
                    toggle('2');
                  } }
                >
                  Venue Images
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={ activeTab }>
              <TabPane tabId="1">
                <Formik
                  initialValues={ initialValues }
                  validationSchema={ validationSchema }
                  enableReinitialize
                  onSubmit={ async (values) => {
                    saveIntegration(values);
                  } }
                >
                  {({
                    isSubmitting,
                  }) => (
                    <Form autoComplete="off">
                      <Row>
                        <Col md="3"> </Col>
                        <Col md="6">
                          <Card>
                            <CardHeader>Manage Venue</CardHeader>

                            <CardBody>
                              <FormGroup>
                                <TextField label="Venue Name" name="venueName" />
                              </FormGroup>

                              <FormGroup>
                                <TextArea label="Description" name="description" />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Premium Venue Cost" name="premiumVenueCost" />
                              </FormGroup>

                              <FormGroup>
                                <Row>
                                  <Col md={ 12 }>
                                    <Field
                                      type="checkbox"
                                      name="allowReligiousCeremony"
                                    />
                                                                        &nbsp;&nbsp;
                                    <label htmlFor="allowReligiousCeremony">
                                      Allow Religious Ceremony
                                    </label>
                                  </Col>
                                </Row>
                              </FormGroup>

                              <FormGroup>
                                <Row>
                                  <Col md={ 6 }>
                                    <label>Available Events</label>
                                  </Col>
                                </Row>
                              </FormGroup>

                              <FormGroup>
                                <Row>
                                  <Col md={ 12 }>
                                    <Field
                                      type="checkbox"
                                      name="ceremony"
                                      onClick={ () => {
                                        setCeremonyChecked(!ceremonyChecked);
                                      } }
                                    />
                                                                        &nbsp;&nbsp;
                                    <label htmlFor="ceremony">Ceremony</label>
                                  </Col>
                                </Row>
                              </FormGroup>

                              <FormGroup>
                                <Row>
                                  <Col md={ 12 }>
                                    <Field
                                      type="checkbox"
                                      name="cocktail"
                                      onClick={ () => {
                                        setCocktailChecked(!cocktailChecked);
                                      } }
                                    />
                                                                        &nbsp;&nbsp;
                                    <label htmlFor="cocktail">Cocktail</label>
                                  </Col>
                                </Row>
                              </FormGroup>

                              <FormGroup>
                                <Row>
                                  <Col md={ 12 }>
                                    <Field
                                      type="checkbox"
                                      name="reception"
                                      onClick={ () => {
                                        setReceptionChecked(!receptionChecked);
                                      } }
                                    />
                                                                        &nbsp;&nbsp;
                                    <label htmlFor="reception">Reception</label>
                                  </Col>
                                </Row>
                              </FormGroup>

                              <FormGroup>
                                <Row>
                                  <Col md={ 12 }>
                                    <TextField
                                      type="number"
                                      min="1"
                                      label="Capacity"
                                      name="capacity"
                                    />
                                  </Col>
                                </Row>
                              </FormGroup>

                              <FormGroup>
                                <Row>
                                  {ceremonyChecked && (
                                    <Col md={ 12 }>
                                      <TextField
                                        type="number"
                                        min="1"
                                        label="Ceremony Capacity"
                                        name="ceremonyCapacity"
                                      />
                                    </Col>
                                  )}
                                </Row>
                              </FormGroup>

                              <FormGroup>
                                <Row>
                                  {cocktailChecked && (
                                    <Col md={ 12 }>
                                      <TextField
                                        type="number"
                                        min="1"
                                        label="Cocktail Capacity"
                                        name="cocktailCapacity"
                                      />
                                    </Col>
                                  )}
                                </Row>
                              </FormGroup>

                              <FormGroup>
                                <Row>
                                  {receptionChecked && (
                                    <Col md={ 12 }>
                                      <TextField
                                        type="number"
                                        min="1"
                                        label="Reception Capacity"
                                        name="receptionCapacity"
                                      />
                                    </Col>
                                  )}
                                </Row>
                              </FormGroup>
                              <FormGroup>
                                <Row>
                                  <Col md={ 12 }>
                                    <Field
                                      type="checkbox"
                                      name="isActive"
                                      onClick={ () => {
                                        setIsActiveChecked(!isActive);
                                      } }
                                    />
                                                                        &nbsp;&nbsp;
                                    <label htmlFor="isActive">
                                      Venue Active
                                    </label>
                                  </Col>
                                </Row>
                              </FormGroup>

                            </CardBody>
                            <Button
                              disabled={ isSubmitting }
                              type="submit"
                              color="primary"
                              className="mt-1"
                            >
                              {isSubmitting ? 'Processing....' : 'Save'}
                            </Button>
                          </Card>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </TabPane>
              <TabPane tabId="2">
                <Button
                  className="btn btn-primary mb-3"
                  color="primary"
                  onClick={ () => setShowImageUpload(!showImageUpload) }
                >
                  Add Images
                </Button>

                <div
                  className={ showImageUpload ? '' : 'd-none' }
                  id="collapseImage"
                >
                  <PhotoWidgetDropzone
                    that={ this }
                    Id={ venueId }
                    submit={ addImage }
                  />
                </div>
                <div className="container mt-5">
                  {chunkedData.map((row, rowIndex) => (
                    <div key={ rowIndex } className="row mb-4">
                      {row.map((col, colIndex) => (
                        <div
                          className="col-lg-4 d-none d-lg-block"
                          key={ colIndex + rowIndex }
                        >
                          <div className="card">
                            <div className="card-body">
                              {/* {col.imageURL} */}
                              <img
                                                                // src={baseUrl + col.imageURL}
                                src={ col.imageURL }
                                className="imagesize hover mb-3"
                                alt={ col.altTagText }
                              />

                              <FormGroup>
                                Image Name
                                <input
                                  type="text"
                                  name="imageName"
                                  maxLength="200"
                                  onChange={ (e) => handleInputChange(
                                    e,
                                    rowIndex * 3 + colIndex
                                  ) }
                                  value={ col.imageName }
                                  className="form-control mb-2"
                                />
                              </FormGroup>

                              <FormGroup>
                                Alt Tag Image
                                <input
                                  type="text"
                                  name="altTagText"
                                  maxLength="200"
                                  onChange={ (e) => handleInputChange(
                                    e,
                                    rowIndex * 3 + colIndex
                                  ) }
                                  value={ col.altTagText }
                                  className="form-control mb-2"
                                />
                              </FormGroup>

                              <FormGroup>
                                Image Order
                                <input
                                  type="number"
                                  name="imageOrder"
                                  onChange={ (e) => handleInputChange(
                                    e,
                                    rowIndex * 3 + colIndex
                                  ) }
                                  value={ col.imageOrder }
                                  className="form-control mb-2"
                                />
                              </FormGroup>

                              <button
                                type="button"
                                className="mt-2 mb-2 mr-2 btn-icon btn btn-success"
                                onClick={ () => updateImage(rowIndex * 3 + colIndex) }
                              >
                                <i className="pe-7s-edit btn-icon-wrapper">
                                  {' '}
                                </i>
                                Update
                              </button>
                              <button
                                type="button"
                                className="mt-2 mb-2 mr-2 btn-icon btn btn-danger"
                                onClick={ () => showConfirmationToDelete(
                                  col.imageContentID
                                ) }
                              >
                                <i className="pe-7s-trash btn-icon-wrapper">
                                  {' '}
                                </i>
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </TransitionGroup>
    </>
  );
}

Edit.defaultProps = {
  resortID: 0,
  updateListView: () => null
}

Edit.propTypes = {
  venueId: PropTypes.number.isRequired,
  resortID: PropTypes.number,
  updateListView: PropTypes.func
}

export default Edit;
