import { destifyAdminApiRequests } from './common';

const hbsiSearch = {
  list: data => destifyAdminApiRequests.post('Hbsi/All', data),
  bookingDetails: args => destifyAdminApiRequests.get('Hbsi/BookingDetails', { params: { ...args } }),
  hotelOptionList: () => destifyAdminApiRequests.get('Hbsi/HotelOptionList'),
  cancelBooking: param =>
    destifyAdminApiRequests.post('Hbsi/CancelBooking', param),
    listbyhotelChain:data=> destifyAdminApiRequests.post('Hbsi/AllByHotelChainId',data),

};

const hotelcontent = {
  list: data => destifyAdminApiRequests.post('Hbsi/HotelContentList', data),
};

const hbsi = {
  hbsiSearch,
  hotelcontent,
};

export default hbsi;
