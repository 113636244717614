import { CustomDatePicker } from 'common/Form/DatePicker';
import { ErrorMessage, Formik, Field, FieldArray, Form } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import SweetAlert from 'sweetalert-react';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useWeddingDateSlice } from '../../slice';
import {
  selectRequestDetails,
  selectCeremonyTimeList,
  selectResortPackages,
  selectResortVenues,
  selectWeddingStatusList,
  selectWeddingDetailIdentifier,
  selectUpdateRequestDetailsLoading,
  selectResort,
} from '../../slice/selectors';
import { toast } from 'react-toastify';
import isDate from 'date-fns/isDate'
import * as Yup from 'yup';
import { WEDDING_DATE_STATUS } from "../../slice/types"

const confirmDialogs = {
  RESET: 'Are you sure to Reset Request?',
  CONFIRM_DATE: 'Are you sure to confirm Wedding Date Availability?',
  CONFIRM_ALT_DATE:
    'Are you sure to confirm Alternative Wedding Date Availability?',
};

const validationSchema2 = Yup.object({
  confirmWeddingDate: Yup.array()
    .of(
      Yup.object().shape({
        confirmedWeddingDate: Yup.date().nullable(),
        confirmedCeremonyTime: Yup.string().nullable(),
      }),
    )
    .test('has-valid-items', 'Please Enter at least one option', value => {
      return value.some(
        obj => obj.confirmedCeremonyTime && obj.confirmedWeddingDate,
      );
    }),
});

const validationSchema = Yup.object().shape({
  altWeddingDate: Yup.date().required('Please Enter Alternative Wedding Date'),
  weddingEventDate: Yup.date().required('Please Enter Wedding Event Date'),
  chosenHotel: Yup.string().required('Please choose a resort.'),
  resortVenueId: Yup.string().required('Please choose a venue.'),
});

interface IProps {
  showUserDetails?: boolean;
}

const _Request_Details = ({ showUserDetails = true }: IProps) => {
  const { actions } = useWeddingDateSlice();
  const dispatch = useAppDispatch();
  const { items: venues, loading: loadingVenues } =
    useAppSelector(selectResortVenues);
  const { items: weddingPackages, loading: loadingWeddingPackages } =
    useAppSelector(selectResortPackages);
  const ceremonyTime = useAppSelector(selectCeremonyTimeList);
  const weddingDateStatuses = useAppSelector(selectWeddingStatusList);
  const details = useAppSelector(selectRequestDetails);
  const { id, userId } = useAppSelector(selectWeddingDetailIdentifier);
  const resort = useAppSelector(selectResort);
  const isSubmitting = useAppSelector(selectUpdateRequestDetailsLoading);

  const [isReset, setIsReset] = useState(false);
  const [confirmDialogue, setConfirmDialogue] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const isButtonActive = useMemo(
    () => parseInt(details.weddingDateStatus) === WEDDING_DATE_STATUS.REQUESTED,
    [details.weddingDateStatus],
  );

  const { firstName, lastName, email, weddingDateStatusText, ...formValues } =
    details;

  const getPackageVenue = id => {
    dispatch(actions.getResortVenueById(id));
    dispatch(actions.getResortPackageById(id));
  };

  const [weddingStatusID, setWeddingStatusID] = useState<number>(null);

  const showConfirmation = (
    id: number,
    text: string,
    isResetRequest: boolean = false,
  ) => {
    setWeddingStatusID(id);
    if (isResetRequest) setIsReset(true);
    setConfirmDialogue(text);
    setShowAlert(true);
  };

  const onRejectButtonClick = (title: string) => {
    setShowModal(true);
    setModalTitle(title);
  };

  const onConfirmWeddingStatus = () => {
    if (isButtonActive) {
      const param = {
        weddingDateStatus: weddingStatusID,
        rejectSalesLead: [],
      };

      dispatch(actions.updateweddingstatus(param));
      setShowAlert(false);
    }
  };

  const onConfirmResetExpiryRequest = () => {
    dispatch(actions.holdExpiredEmail(id));
    setShowAlert(false);
  };
 
  const ConvertToDateStringOnly = (date: Date) => {
    if(!isDate(date)) return null;
    const dateFormatted= `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
    return dateFormatted;
    };

  const handleSubmit = values => {
    const newValue = {
      userID: userId,
      resortVenueId: values.resortVenueId,
      chosenHotel: values.chosenHotel,
      ceremonyTime: values.ceremonyTime,
      altWeddingTime: values.altWeddingTime,
      weddingDateStatus: values.weddingDateStatus,
      altWeddingDate: ConvertToDateStringOnly(values.altWeddingDate),
      weddingEventDate: ConvertToDateStringOnly(values.weddingEventDate),
    };
    try {
      const { packageName, weddingPackageId } = weddingPackages.find(
        p => p.weddingPackageId === +values.weddingPackageId,
      );
      newValue['weddingPackageId'] = weddingPackageId;
      newValue['weddingPackageName'] = packageName;
    } catch (error) {
      toast.error('cannot find the wedding package!');
      return;
    }
    dispatch(actions.updateweddingdetailsbyuser(newValue));
    setIsEditing(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(actions.getWeddingDetailsById(id));
    }
  }, [dispatch, actions, id]);

  const { items: resortList } = resort;

  const [initialForm] = useState(() => ({
    confirmWeddingDate: Array.from({ length: 3 }, (_, id) => ({
      confirmedWeddingDate: null,
      confirmedCeremonyTime: null,
      id: id + 1,
    })),
  }));

  const onSubmit = (fields,{setSubmitting}) => {
    const RejectSalesLead = fields.confirmWeddingDate.filter(
      field => field.confirmedCeremonyTime && field.confirmedWeddingDate,
    ).map(field => ({
      date: ConvertToDateStringOnly(field.confirmedWeddingDate),
      time: field.confirmedCeremonyTime
    }));

    if (!RejectSalesLead.length) {
      toast.error('Please Enter at least one option!');
      return;
    }

    dispatch(
      actions.updateweddingstatus({
        weddingDateStatus: WEDDING_DATE_STATUS.REJECTED,
        RejectSalesLead,
      }),
    );
    setShowModal(false);
    setSubmitting(false);
  };

  const toggle = () => setShowModal(prev => !prev);

  useEffect(() => {
    dispatch(actions.getCeremonyTimeList());
  }, [actions, dispatch]);

  return (
    <>
      <Formik
        initialValues={initialForm}
        validationSchema={validationSchema2}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Modal isOpen={showModal} toggle={toggle}>
            <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
            <Form autoComplete="off">
              <ModalBody>
                <FieldArray
                  name="confirmWeddingDate"
                  render={helpers => {
                    return (
                      <>
                        {values.confirmWeddingDate.map((field, index) => (
                          <>
                            <Row key={field.id}>
                              <Col md="12">
                                <label>Option {field.id} Wedding Date</label>
                                <InputGroup>
                                  <CustomDatePicker
                                    name={`confirmWeddingDate.${index}.confirmedWeddingDate`}
                                    className="form-control"
                                  />
                                </InputGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup>
                                  <label>Option {field.id} Time </label>
                                  <Field
                                    as="select"
                                    className="form-control"
                                    name={`confirmWeddingDate.${index}.confirmedCeremonyTime`}
                                    onChange={e =>
                                      helpers.replace(index, {
                                        ...values.confirmWeddingDate[index],
                                        confirmedCeremonyTime: e.target.value,
                                      })
                                    }
                                  >
                                    <option value="">--Select--</option>
                                    {ceremonyTime.map(destination => (
                                      <option
                                        key={destination.ceremonyTime}
                                        value={destination.ceremonyTime}
                                      >
                                        {destination.ceremonyTime}
                                      </option>
                                    ))}
                                  </Field>
                                </FormGroup>
                                <div className="divider" />
                              </Col>
                            </Row>
                          </>
                        ))}
                        <ErrorMessage
                          name="confirmWeddingDate"
                          className="is-invalid"
                        />
                      </>
                    );
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  color="link"
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setModalTitle('');
                  }}
                >
                  Cancel
                </Button>
                <Button color="primary">Confirm</Button>
              </ModalFooter>
            </Form>
          </Modal>
        )}
      </Formik>
      <Card className="main-card mb-5 position-relative">
        {/* {loading && (
          <Loader
            innerClassName="position-absolute block-ui-message-container"
            type="ball-pulse"
            active={true}
          />
        )} */}
        <CardHeader>Wedding Date Request Details</CardHeader>
        <CardBody>
          <Formik
            initialValues={formValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, handleChange, handleSubmit, resetForm }) => (
              <>
                <SweetAlert
                  show={showAlert}
                  type="warning"
                  title="Confirmation"
                  text={confirmDialogue}
                  showCancelButton
                  onConfirm={() => {
                    isReset && !isSubmitting
                      ? onConfirmResetExpiryRequest()
                      : onConfirmWeddingStatus();
                  }}
                  onCancel={() => setShowAlert(false)}
                  onClose={() => {}}
                />
                <Form onSubmit={handleSubmit}>
                  {showUserDetails ? (
                    <>
                      <FormGroup>
                        <Row>
                          <label className="control-label col-md-5">
                            Client Name:{' '}
                          </label>
                          <div className="col-md-6">
                            {firstName} {lastName}
                          </div>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Row>
                          <label className="control-label col-md-5">
                            Client Email:{' '}
                          </label>
                          <div className="col-md-6">{email}</div>
                        </Row>
                      </FormGroup>
                    </>
                  ) : null}
                  <FormGroup>
                    <Row>
                      <label className="control-label col-md-5">
                        Resort Property Chosen:{' '}
                      </label>
                      <div className="col-md-6">
                        <select
                          name="chosenHotel"
                          className="form-control-sm form-control"
                          disabled={!isEditing}
                          value={values.chosenHotel}
                          onChange={e => {
                            handleChange(e);
                            getPackageVenue(e.target.value);
                          }}
                        >
                          <option value="">-Select Resort-</option>
                          {resortList
                            ? resortList.map(res => (
                                <option key={res.resortID} value={res.resortID}>
                                  {res.resortName}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <label className="control-label col-md-5">
                        Preferred Venue:{' '}
                      </label>
                      <div className="col-md-6">
                        <select
                          name="resortVenueId"
                          className="form-control-sm form-control"
                          disabled={!isEditing || loadingVenues}
                          value={values.resortVenueId}
                          onChange={handleChange}
                        >
                          <option value="">-Select Venue-</option>
                          {venues
                            ? venues.map(res => (
                                <option
                                  key={res.resortVenueId}
                                  value={res.resortVenueId}
                                >
                                  {res.venueName}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <label className="control-label col-md-5">
                        Wedding Package Chosen:{' '}
                      </label>
                      <div className="col-md-6">
                        <select
                          name="weddingPackageId"
                          className="form-control-sm form-control"
                          disabled={!isEditing || loadingWeddingPackages}
                          value={values.weddingPackageId}
                          onChange={handleChange}
                        >
                          <option value="">-Select Package-</option>
                          {weddingPackages
                            ? weddingPackages.map(res => (
                                <option
                                  key={res.weddingPackageId}
                                  value={res.weddingPackageId}
                                >
                                  {res.packageName}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <label className="control-label col-md-5">
                        Wedding Date:{' '}
                      </label>
                      <div className="col-md-6">
                        <CustomDatePicker
                          className="form-control form-control-sm"
                          name="weddingEventDate"
                          disabled={!isEditing}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy"
                        />
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <label className="control-label col-md-5">
                        Preferred Ceremony Time:{' '}
                      </label>
                      <div className="col-md-6">
                        <select
                          name="ceremonyTime"
                          className="form-control-sm form-control"
                          disabled={!isEditing}
                          value={values.ceremonyTime}
                          onChange={handleChange}
                        >
                          {ceremonyTime
                            ? ceremonyTime.map(res => (
                                <option key={res.id} value={res.ceremonyTime}>
                                  {res.ceremonyTime}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <label className="control-label col-md-5">
                        Alternative Wedding Date:{' '}
                      </label>
                      <div className="col-md-6">
                        <CustomDatePicker
                          className="form-control form-control-sm"
                          name="altWeddingDate"
                          dateFormat="MMMM d, yyyy"
                          disabled={!isEditing}
                        />
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <label className="control-label col-md-5">
                        Alternative Wedding Time:{' '}
                      </label>
                      <div className="col-md-6">
                        <select
                          name="altWeddingTime"
                          className="form-control-sm form-control"
                          disabled={!isEditing}
                          value={values.altWeddingTime}
                          onChange={handleChange}
                        >
                          {ceremonyTime
                            ? ceremonyTime.map(res => (
                                <option key={res.id} value={res.ceremonyTime}>
                                  {res.ceremonyTime}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <label className="control-label col-md-5">
                        Wedding Date Status:{' '}
                      </label>
                      <div className="col-md-6">
                        <select
                          name="weddingDateStatus"
                          className="form-control-sm form-control"
                          disabled={!isEditing}
                          value={values.weddingDateStatus}
                          onChange={handleChange}
                        >
                          {weddingDateStatuses
                            ? weddingDateStatuses.map(res => (
                                <option key={res.value} value={res.value}>
                                  {res.text}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    {isEditing ? (
                      <>
                        <input
                          type="submit"
                          value={isSubmitting ? 'Submitting...' : 'Submit'}
                          disabled={isSubmitting}
                          className="btn btn-primary"
                        />
                        <Button
                          className="mb-2 mr-2 btn-icon float-left"
                          color="danger"
                          onClick={() => {
                            setIsEditing(false);
                            resetForm();
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="mb-2 mr-2 btn-icon float-left"
                        color="primary"
                        disabled={isEditing}
                        onClick={() => setIsEditing(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </FormGroup>
                </Form>
              </>
            )}
          </Formik>
        </CardBody>
        <CardFooter className="d-block text-right">
          <Button
            size="lg"
            className="mb-2 mr-2"
            color="success"
            disabled={
              ![
                'CONFIRMEDDATE',
                'CONFIRMEDALTERNATIVEDATE',
                'CONFIRMEDAVAILABILITYDATE',
              ].includes(weddingDateStatusText)
            }
            onClick={() =>
              showConfirmation(
                WEDDING_DATE_STATUS.CONFIRMEDDATE,
                confirmDialogs.RESET,
                true,
              )
            }
          >
            Reset Request
          </Button>
          <Button
            size="lg"
            className="mb-2 mr-2"
            color="success"
            disabled={!isButtonActive}
            onClick={() =>
              showConfirmation(
                WEDDING_DATE_STATUS.CONFIRMEDDATE,
                confirmDialogs.CONFIRM_DATE,
              )
            }
          >
            Confirm Wedding Date Availability
          </Button>
          <Button
            size="lg"
            className="mb-2 mr-2"
            color="primary"
            disabled={!isButtonActive}
            onClick={() =>
              showConfirmation(
                WEDDING_DATE_STATUS.CONFIRMEDALTERNATIVEDATE,
                confirmDialogs.CONFIRM_ALT_DATE,
              )
            }
          >
            Confirm Alternative Wedding Date Availability
          </Button>
          <Button
            size="lg"
            className="mb-2 mr-2"
            color="danger"
            disabled={!isButtonActive}
            onClick={() =>
              onRejectButtonClick('Reject Wedding Date Availability')
            }
          >
            Reject Wedding Date Availability
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default _Request_Details;
