import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { store } from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import './assets/base.scss';
import Main from './Pages/Main';
import LoginSuccess from './signinoidc';
import LogoutSuccess from './signoutoidc';

const rootElement = document.getElementById('root');

export const history = createBrowserHistory();

// forcing a load event to trigger for cypress
setTimeout(() => {
  dispatchEvent(new Event('load'));
}, 2000);

const renderApp = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/signin-oidc">
            <LoginSuccess />
          </Route>

          <Route path="/signout-oidc">
            <LogoutSuccess />
          </Route>
        </Switch>
        <Component />
      </Router>
    </Provider>,
    rootElement,
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./Pages/Main', () => {
    renderApp(Main);
  });
}
serviceWorker.unregister();
