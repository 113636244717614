import React from 'react'

import ModifyButton from "./_ModifyButton"
import { FormFields } from "../../../../slice/types"

const Description = ({value,data}:{value:string,data:FormFields}) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
        <div className="px-4">{value}</div>
        <div>
        <ModifyButton data={data} />
        </div>
    </div>
  )
}

export default Description