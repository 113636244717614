import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { customerTransferReservationSaga } from './saga';
import { IState } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

export const initialState: IState = {
	customerTransferReservations: {
    	items: [],
      pages:null,
      totalDataCount:0,
  	},
    loading: true,
    customerTransferReservationsByDestifyUserId:[]
};

const slice = createSlice({
  name: 'customerTransferReservations',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    loadCustomerTransferReservations(state,_action:PayloadAction<any>) {
      state.loading=true;
    },
    loadCustomerTransferReservationsByDestifyUserId(state,_action:PayloadAction<any>) {
      state.loading=true;
    },
    clearReservations(state) {
      state.customerTransferReservations.items = [];
    },
    setCustomerTransferReservations(state, action) {
      state.customerTransferReservations.items = action.payload;
      state.loading = false;
    },
    setCustomerTransferReservationsByDestifyUserId(state, action) {
      state.customerTransferReservationsByDestifyUserId = action.payload;
      state.loading = false;
    },
    setPages(state,action){
      state.customerTransferReservations.pages=action.payload;
    },
    setTotalDataCount(state,action){
      state.customerTransferReservations.totalDataCount=action.payload;
    }
  },
});
export const { actions: CustomerTransferReservationActions } = slice;
export const useCustomerTransferReservationSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customerTransferReservationSaga });
  return { actions: slice.actions };
};
