/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import {
  FormGroup,
  Row,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap';
import { Loader } from 'react-loaders';
import cmsAPI from '../../../../api/CMS';
import crmAPI from '../../../../api/crm';
import { formatDate } from 'common/utils/dateUtils';

function WeddingWebsiteInfo({ groupId }) {
  const [loading, setLoading] = useState(false);
  const [websiteDetails, setWebsiteDetails] = useState({
   groupName:'', 
   spouse1FirstName:'',
   spouse1LastName:'', 
   spouse2FirstName:'',
   spouse2LastName:'',
   theme:'',
   websiteName:'',
   websiteUrl:'',
   dateCreated:''
  });
  const [groupDetails, setGroupDetails] = useState({weddingDate:'',hotelNames:[]});

  const getWebsiteDetails = async () => {
    setLoading(true);
    const [websiteInfo, groupInfo] =
      await Promise.all([
        cmsAPI.weddingWebsite.GetWeddingWebsiteByGroupId(groupId),
        crmAPI.groups.groupDetail(groupId),
      ]);
      setWebsiteDetails(websiteInfo);
      setGroupDetails(groupInfo);
      setLoading(false);
    
  }
  
  useEffect(() => {
    getWebsiteDetails();
  }, [])

  return (
    <Card className="main-card mb-3">
      <CardHeader>Wedding Website Info</CardHeader>
      <CardBody>
        {!loading && websiteDetails ? (
          <>
             <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Group Title:{' '}
                </label>
                <div className="col-md-9">{websiteDetails.groupName}</div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Bride Name:{' '}
                </label>
                <div className="col-md-9">{websiteDetails.spouse1FirstName} {websiteDetails.spouse1LastName}</div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Groom Name:{' '}
                </label>
                <div className="col-md-9">{websiteDetails.spouse2FirstName} {websiteDetails.spouse2LastName}</div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Wedding Date:{' '}
                </label>
                <div className="col-md-9">{ formatDate(groupDetails.weddingDate,'MM/dd/yyyy')}</div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Wedding Location:{' '}
                </label>
                <div className="col-md-9">{groupDetails.hotelNames && groupDetails.hotelNames.map((hotelName,index) => (
                <>
                  <p key={index}>{hotelName}</p>
                </>
              ))}
              </div>
              </Row>
            </FormGroup>
           
            <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Website Name:{' '}
                </label>
                <div className="col-md-9">{websiteDetails.websiteName}</div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Website Url:{' '}
                </label>
                <div className="col-md-9"><a href={websiteDetails.websiteUrl} target="_blank" rel="noopener noreferrer">{websiteDetails.websiteUrl}</a></div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Website Theme:{' '}
                </label>
                <div className="col-md-9">{websiteDetails.theme}</div>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Website Created Date:{' '}
                </label>
                <div className="col-md-9">{ formatDate(websiteDetails.dateCreated,'MM/dd/yyyy')}</div>
              </Row>
            </FormGroup>
          </>
        ) : (
          <div style={ { display: 'flex', justifyContent: 'center' } }>
            <Loader type="ball-pulse" />
          </div>
        )}
        
      </CardBody>
    </Card>
  )
}

export default WeddingWebsiteInfo;
