/* eslint-disable react/prop-types */
import { Route, Redirect, withRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import Loader from 'react-loaders';

import { ToastContainer } from 'react-toastify';

import UserPages from '../../Pages/UserPages';
import Dashboards from '../../Pages/Dashboards';
import Users from '../../Pages/Users';
import Hbsi from '../../Pages/Hbsi';
import ManageHotelContent from '../../Pages/ManageHotel';
import GroupLeader from '../../Pages/GroupLeader';
import ManageWedding from '../../Pages/ManageWeddingPackage/index';

import SalesLeadRoute from '../../Pages/SalesLead/index';

interface AppMainProps {
  location: {
    pathname: string;
  };
}

function Fallback() {
  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader active type="ball-scale-ripple-multiple" />
        </div>
        <h6 className="mt-5">
          Please wait while we load all the Components
          <small>
            Your left thumb points to the right and your right thumb points to
            the left. Computing chance of success!
          </small>
        </h6>
      </div>
    </div>
  );
}

function AppMain(props: AppMainProps) {
  const location = props.location.pathname;

  return (
    <>
      <Suspense fallback={<Fallback />}>
        <Route path="/user" component={Users} />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Route path="/Hbsi" component={Hbsi} />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Route path="/hotels" component={ManageHotelContent} />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Route path="/wedding" component={ManageWedding} />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Route path="/group" component={GroupLeader} />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Route path="/salesleads" component={SalesLeadRoute} />
      </Suspense>

      {/* Pages */}
      <Suspense fallback={<Fallback />}>
        <Route path="/pages" component={UserPages} />
      </Suspense>

      {/* Dashboards */}
      <Suspense fallback={<Fallback />}>
        <Route path="/dashboards" component={Dashboards} />
      </Suspense>
      <Redirect to={location} />

      <ToastContainer />
    </>
  );
}

export default withRouter(AppMain);
