import React from 'react';
import { Row, Col, FormGroup, Input, FormFeedback } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { IODataFilter, ODataFilterOptions } from 'common/OData/ODataFilters';
import Select from 'react-select';

const dropdownStyles = {
  container: (baseStyles, state) => ({
    ...baseStyles,
    width: '100%',
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    color: '#495057',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    color: '#495057',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: '#495057',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
  }),
};

export interface IDateRangeValues {
  fromDate?: Date | null | undefined;
  toDate?: Date | null | undefined;
}

export const isIDateRangeValue = (object: any): object is IDateRangeValues => {
  return 'fromDate' in object;
};

interface IODataDateRangePickerState extends IODataFilter<IDateRangeValues> {
  toDateValidator: { valid: boolean; validationMessage?: string | null };
  fromDateValidator: { valid: boolean; validationMessage?: string | null };
}

export interface IDateRangeFilterProps {
  label: string;
  onChange?: (dateRangeFilterValue: IODataFilter<IDateRangeValues>) => void;
  odataFilter?: IODataFilter<IDateRangeValues>;
  minDate?: Date;
}

export class ODataDateRangePicker extends React.Component<
  IDateRangeFilterProps,
  IODataDateRangePickerState
> {
  private static rangeDropdownOptions = [
    { value: 'between', label: 'Is Between' },
    { value: 'gt', label: 'Is After' },
    { value: 'lt', label: 'Is Before' },
    { value: 'eq', label: 'Equals' },
    { value: 'eq null', label: 'Not Requested' },
  ];

  constructor(props: IDateRangeFilterProps) {
    super(props);
    const { odataFilter } = props;    
    this.state = {
      ...odataFilter,
      toDateValidator: { valid: true, validationMessage: null },
      fromDateValidator: { valid: true, validationMessage: null },
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = () => {    
    if (this.state.modifier === 'between') {
      if (
        this.state.value?.toDate?.getTime() ===
        this.state.value?.fromDate?.getTime()
      ) {
        this.setState({
          toDateValidator: {
            valid: false,
            validationMessage:
              'Starting Date and Ending Date cannot be the same value',
          },
        });
        this.setState({ value: { ...this.state.value, toDate: null } });
      }
    }
  };

  handleChange = item => {
    const selectedModifierValue = item?.value as ODataFilterOptions;
    if (!selectedModifierValue || selectedModifierValue === 'eq null') {
      this.props.onChange({ ...this.state, modifier: selectedModifierValue, value: null });
      this.setState({ value: null, modifier: selectedModifierValue });
      return;
    }
    this.setState({ modifier: selectedModifierValue });
    this.setState({ value: { ...this.state.value, toDate: null } });
    this.props.onChange({
      ...this.state,
      modifier: selectedModifierValue,
      value: { ...this.state.value, toDate: null },
    });
  };

  render() {
    const { label, onChange, minDate } = this.props;
    const { value } = this.state;
    return (
      <Row>
        <Col md={3}>
          <label>{label}</label>
        </Col>
        <Col>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Select
                  placeholder="--Select--"
                  styles={dropdownStyles}
                  options={ODataDateRangePicker.rangeDropdownOptions}
                  hideSelectedOptions
                  isClearable
                  onChange={this.handleChange}
                  value={
                    this.props.odataFilter?.modifier
                      ? ODataDateRangePicker.rangeDropdownOptions.find(
                        i => i.value === this.props.odataFilter.modifier,
                      )
                      : null
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <DatePicker
                  className="form-control"
                  disabled={!this.props.odataFilter.modifier || this.props.odataFilter.modifier === 'eq null'}
                  selected={this.props.odataFilter.value?.fromDate}
                  minDate={minDate}
                  placeholderText="Starting Date"
                  showYearDropdown
                  onChange={(d: Date | null | undefined) => {
                    this.setState({ value: { ...value, fromDate: d } });
                    onChange({
                      ...this.state,
                      value: { ...value, fromDate: d },
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <DatePicker
                  disabled={this.props.odataFilter.modifier !== 'between'}
                  className={`form-control ${!this.state.toDateValidator.valid && 'is-invalid'
                    }`}
                  selected={this.props.odataFilter.value?.toDate}
                  minDate={minDate}
                  placeholderText="Ending Date"
                  showYearDropdown
                  onChange={(d: Date | null | undefined) => {
                    this.setState({ value: { ...value, toDate: d } });
                    onChange({ ...this.state, value: { ...value, toDate: d } });
                  }}
                />
                {/*This is a dummy input to leverage formfeedback*/}
                <Input invalid={!this.state.toDateValidator.valid} hidden />
                <FormFeedback>
                  {this.state.toDateValidator.validationMessage}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
