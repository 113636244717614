export const categoryOption = [
  { text: '--Select--', value: '' },
  { text: 'Drinks', value: 'drinks' },
  { text: 'Culture', value: 'culture' },
  { text: 'Film', value: 'film' },
];

export const HotelOption = [
  { text: 'All Hotels', value: '83825-' },
  { text: 'Grand Palladium Jamaica', value: '78517' },
  { text: 'Grand Palladium Lady Hamilton', value: '78518' },
  { text: 'Grand Palladium Kantenah', value: '78954' },
  { text: 'Grand Palladium Vallarta', value: '78868' },
  { text: 'Grand Palladium White Sand Resort', value: '78916' },
  { text: 'Grand Palladium Costa Mujeres', value: '78913' },
  { text: 'Grand Palladium Colonial', value: '78953' },
  { text: 'Grand Palladium Bavaro Resort', value: '78869' },
  { text: 'Majestic Elegance Costa Mujeres', value: '83825' },
  { text: 'TRS Coral Hotel', value: '78914' },
  { text: 'TRS Yucatan', value: '78917' },
];

export const SearchDateOption = [
  { text: 'Check In', value: 'ARRIVAL' },
  { text: 'Date Booked', value: 'BOOKED' },
];

export const HotelChain = [
  { text: '--Select--', value: '' },
  { text: 'RIU Hotels & Resports', value: '1' },
  { text: 'Hilton Hotel and Resorts', value: '2' },
  { text: 'Hyatt Hotel Corporation', value: '3' },
];

export const Country = [
  { text: '--Select--', value: '' },
  { text: 'United States', value: '1' },
  { text: 'Mexico', value: '2' },
  { text: 'Dominican Republic', value: '3' },
];

export const BooleanDropDown = [
  { text: 'Yes', value: true },
  { text: 'No', value: false },
];

export const OccupancyNumber = [
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 },
  { text: '11', value: 11 },
  { text: '12', value: 12 },
];

export const AirportCode = [
  { value: 'ACA', text: 'ACA (Acapulco)' },
  { value: 'AXA', text: 'AXA (Anguilla)' },
  { value: 'ANU', text: 'ANU (Antigua)' },
  { value: 'FPO', text: 'FPO (Bahamas, Freeport)' },
  { value: 'GGT', text: 'GGT (Bahamas, George Town)' },
  { value: 'NAS', text: 'NAS (Bahamas, Nassau)' },
  { value: 'BGI', text: 'BGI (Barbados)' },
  { value: 'BDA', text: 'BDA (Bermuda)' },
  { value: 'SJD', text: 'SJD (Cabo San Lucas)' },
  { value: 'CUN', text: 'CUN (Cancun)' },
  { value: 'CZM', text: 'CZM (Cozumel)' },
  { value: 'CUR', text: 'CUR (Curacao)' },
  { value: 'GCM', text: 'GCM (Grand Cayman Islands)' },
  { value: 'HUX', text: 'HUX (Huatulco)' },
  { value: 'ZIH', text: 'ZIH (Ixtapa)' },
  { value: 'LRM', text: 'LRM (La Romana, Dominican Republic)' },
  { value: 'LIR', text: 'LIR (Liberia, Costa Rica)' },
  { value: 'ZLO', text: 'ZLO (Manzanillo)' },
  { value: 'MZT', text: 'MZT (Mazatlan)' },
  { value: 'MBJ', text: 'MBJ (Montego Bay, Jamaica)' },
  { value: 'AUA', text: 'AUA (Oranjestad, Aruba)' },
  { value: 'MCO', text: 'MCO (Orlando)' },
  { value: 'OGG', text: 'OGG (Maui)' },
  { value: 'PTY', text: 'PTY (Panama City, Panama)' },
  { value: 'POP', text: 'POP (Puerto Plata, Dominican Republic)' },
  { value: 'PVR', text: 'PVR (Puerto Vallarta/Riviera Nayarit)' },
  { value: 'PUJ', text: 'PUJ (Punta Cana, Dominican Republic)' },
  { value: 'AZS', text: 'AZS (Samana, Dominican Republic)' },
  { value: 'SJO', text: 'SJO (San Jose, Costa Rica)' },
  { value: 'SJU', text: 'SJU (San Juan, Puerto Rico)' },
  { value: 'STI', text: 'STI (Santiago, Dominican Republic)' },
  { value: 'SDQ', text: 'SDQ (Santo Domingo, Dominican Republic)' },
  { value: 'SGU', text: 'SGU (St George)' },
  { value: 'STX', text: 'STX (St. Croix)' },
  { value: 'SKB', text: 'SKB (St. Kitts)' },
  { value: 'UVF', text: 'UVF (St. Lucia)' },
  { value: 'SXM', text: 'SXM (St. Martin/St. Maarten)' },
  { value: 'STT', text: 'STT (St. Thomas)' },
  { value: 'PLS', text: 'PLS (Turks and Caicos)' },
  { value: 'HNL', text: 'HNL (Honolulu)' },
  { value: 'LAS', text: 'Las Vegas,NV' },
  { value: 'ITO', text: 'Hilo, HI' },
  { value: 'KOA', text: 'Kailua-Kona, HI' },
  { value: 'GND', text: 'Grenada' },
  { value: 'PDX', text: 'PDX Portland' },
  { value: 'BZE', text: 'BZE (Belize)' },
  { value: 'NAN', text: 'NAN Fiji' },
];

export const Wifi = [
  { text: 'Hourly/Daily Fees', value: 'wififee' },
  { text: 'Free in the Lobby', value: 'freeinthelobby' },
  { text: 'Free in Lobby and Rooms', value: 'freeinlobbyrooms' },
];

export const Rating = [
  { text: '1', value: '1' },
  { text: '1.5', value: '1.5' },
  { text: '2', value: '2' },
  { text: '2.5', value: '2.5' },
  { text: '3', value: '3' },
  { text: '3.5', value: '3.5' },
  { text: '4', value: '4' },
  { text: '4.5', value: '4.5' },
  { text: '5', value: '5' },
];

export const Adults = [
  { text: '1', value: '1' },

  { text: '2', value: '2' },

  { text: '3', value: '3' },

  { text: '4', value: '4' },
  { text: '5', value: '5' },
];

export const Children = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },

  { text: '3', value: '3' },

  { text: '4', value: '4' },
  { text: '5', value: '5' },
];

export const ResortPackageIcon = [
  { text: 'Berries', value: 'berries' },
  { text: 'Fondue', value: 'fondue' },
  { text: 'Coconut Drink', value: 'coconutdrink' },
];

export const VenueFunctions = [
  { text: 'Ceremony', value: 'Ceremony' },
  { text: 'Cocktail Party', value: 'Cocktail Party' },
  { text: 'Reception', value: 'Reception' },
];

export const RoomStatusOption=[
  {text:'Confirmed',value:'Book'},
  {text:'Cancelled',value:'Cancel'}
];