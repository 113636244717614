import { put, takeLatest } from 'redux-saga/effects';
import { SalesLeadActions as actions } from './index';
import usersApi from 'api/user';
import salesLead from 'api/salesLead';
import { toast } from 'react-toastify';
import { AdminRoles } from 'auth/Roles';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

function* loadSalesLeadData() {
  const loggedInUser: any = JSON.parse(localStorage.getItem('loginUser') || '');
  let SalesSpecialistEmails: string[] = [];
  if (loggedInUser.profile.role === AdminRoles.SalesSpecialist) {
    SalesSpecialistEmails = [
      loggedInUser?.profile?.email,
      'sales2@destify.com',
    ];
  }
  try {
    const [bookingConfirmedList, groupSize] = yield Promise.all([
      salesLead.salesLeadDetails.getSalesLeadBookedConfirmUserList(),
      salesLead.salesLeadDetails.getWeddingDetailsGroupsize(),
      // salesLead.salesLeadDetails.getSalesSourceList(),
    ]);

    const options = [];

    // Object.entries(salesSourceList.dropdownList).forEach(
    //   ([objectKey, objectValue]) => {
    //     if (objectValue !== '') {
    //       options.push({ objectKey, objectValue });
    //     }
    //   },
    // );

    if (bookingConfirmedList.isSuccess) {
      yield put(actions.setBookedConfirmUserList(bookingConfirmedList.value));
    }

    if (groupSize.isSuccess) {
      yield put(actions.setWeddingDetailsGroupSize(groupSize.value));
    }

    yield put(actions.setSalesSourceList(options));
  } catch (error:unknown) {
    let errorString = "An error has occurred";
    if (axios.isAxiosError(error)) {
      errorString = `An error has occurred while invoking ${(error.config?.baseURL+error.config?.url)}`
    }
    else if(error instanceof Error){
      errorString = error.message;
    }
    toast.error(errorString);
  }
  finally{
    yield put(actions.setLoading(false));
  }
}

function* loadWeddingDetails(action) {
  const response = yield usersApi.guestUsers.getWeddingDetails(action.payload);
  if (response.isSuccess) yield put(actions.setWeddingDetails(response.data));
}

function* loadUserActivity(action) {
  try {
    const response = yield salesLead.salesLeadDetails.userJourney(
      action.payload,
    );
    if (response) yield put(actions.setUserActivity(response));
  } catch (e: any) {
    toast.error(e.message);
  }
}

function* loadSalesLeadDetails(action) {
  try {
    const response =
      yield salesLead.salesLeadDetails.getSalesLeadDetailsByUserId(
        action.payload,
      );
    if (response) yield put(actions.setSalesLeadDetails(response));
  } catch (e: any) {
    toast.error(e.message);
  }
}

function* loadSalesLeadAccountDetails(action) {
  try {
    const response = yield salesLead.salesLeadDetails.weddingDetailsByUser(
      action.payload,
    );
    if (response.isSuccess)
      yield put(actions.setSalesLeadAccountDetails(response.data));
  } catch (e: any) {
    toast.error(e.message);
  }
}

function* loadAuthUsers() {
  try {
    const users = yield usersApi.authUsers.list();
    yield put(actions.setAuthUsers(users));
  } catch (e: any) {
    toast.error(e.message);
  }
}

function* assignCRMLead(
  action: PayloadAction<{
    salesLeadId: string;
    email: string;
    leadId: string;
    assignedAgentId: string;
    agentName: string | null;
  }>,
) {
  yield put(actions.setIsUpdatingAssignLead(true));
  try {
    const response = yield salesLead.salesLeadDetails.assignLead(
      action.payload,
    );
    if (response.isSuccess) {
      if (!action.payload.agentName) {
        toast.success(`Lead successfully set to Unassigned`);
      } else {
        toast.success(
          `Lead successfully assigned to ${action.payload.agentName}`,
        );
      }
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    toast.error(
      error instanceof Error
        ? error?.message || 'something went wrong'
        : 'something went wrong',
    );
  } finally {
    yield put(actions.setIsUpdatingAssignLead(false));
    yield put(actions.loadLeadInfoCRM(action.payload.leadId));
  }
}

function* loadLeadInfoCRM(action) {
  try {
    const leadInfo = yield usersApi.guestUsers.getLeadInfoByLeadId(
      action.payload,
    );
    yield put(actions.setLeadInfoCRM(leadInfo));
  } catch (e: any) {
    toast.error(e.message);
  }
}

function* assignLead(action) {
  try {
    const response = yield salesLead.salesLeadDetails.assignLead(
      action.payload,
    );
    if (response.responseDesc === 'Error') {
      toast.error('Error assigning user');
    } else {
      toast.success(response.responseDesc);
      yield put(actions.loadSalesLeadAccountDetails(action.payload.id));
      yield put(actions.loadSalesLeadDetails(action.payload.id));
      yield put(actions.loadAuthUsers());
      yield put(actions.loadLeadInfoCRM(action.payload.CRMLeadId));
    }
  } catch (e: any) {
    toast.error(e.message);
  }
  yield put(actions.setLoading(false));
}

function* updateStatus(action) {
  try {
    const res = yield salesLead.salesLeadDetails.updateUserStatus(
      action.payload,
    );
    if (res.isSuccess === true) {
      toast.success('Status Updated Successfully');
      yield put(actions.loadSalesLeadAccountDetails(action.payload.id));
      yield put(actions.loadSalesLeadDetails(action.payload.id));
      yield put(actions.loadAuthUsers());
      yield put(actions.loadLeadInfoCRM(action.payload.leadid));
    } else {
      toast.error('Error Updating Status');
    }
  } catch (e: any) {
    toast.error(e.message);
  }
}

function* loadImpersonationLink(action) {
  try {
    const impersonationLink = yield usersApi.authUsers.impersonate(
      action.payload,
    );
    yield put(actions.setImpersonationLink(impersonationLink));
  } catch (e: any) {
    toast.error(e.message);
  }
}

function* loadSalesLeadStatus() {
  try {
    const data = yield salesLead.salesStatus.list();
    yield put(actions.setSalesStatusList({ list: data }));
  } catch (error) {
    yield put(
      actions.setSalesStatusList({
        list: [],
        error: 'unable to load status list',
      }),
    );
  }
}

function* updateSalesLeadStatus(action) {
  try {
    yield salesLead.salesStatus.update(action.payload);
    toast.success('Status has been updated successfully');
  } catch (error) {
    toast.error('Something went wrong. Please try again later.');
  }
}

function* getClosedReasonAll() {
  try {
    const data = yield salesLead.salesStatus.closedReasonList();
    yield put(actions.setClosedReasons({ list: data }));
  } catch (error) {
    yield put(
      actions.setClosedReasons({ list: [], error: 'something went wrong' }),
    );
  }
}

function* updateSalesLeadAccountWeddingDetails(action) {
  try {
    const data =
      yield salesLead.salesLeadDetails.updateSalesLeadAccountWeddingDetails(
        action.payload,
      );
    if (data.isSuccess) {
      toast.success(
        data.value || 'wedding account details updated successfully',
      );
    } else {
      toast.error(data.error);
    }
  } catch (error) {
    toast.error('Something went wrong. Please try again later.');
  }
  yield put(actions.setLoadingUpdateWeddingDetails(false));
}

function* loadNotesForUser(action) {
  try {
    const data = yield salesLead.notes.getNotesForUser({
      module: action.payload.module,
      DestifyUserID: action.payload.DestifyUserID,
    });
    if (Array.isArray(data)) yield put(actions.setNotes(data));
  } catch (error) {
    toast.error('Something went wrong. Please try again later.');
    yield put(actions.setLoading(false));
  }
}

function* addNote(action) {
  try {
    const response = yield salesLead.notes.addNote(action.payload);
    yield put(actions.setLoading(false));
    if (response.isSuccess)
      yield put(
        actions.loadNotesForUser({
          module: action.payload.module,
          DestifyUserID: action.payload.userId,
        }),
      );
  } catch (error) {
    toast.error('Something went wrong. Please try again later.');
    yield put(actions.setLoading(false));
  }
}

export function* salesLeadSaga() {
  yield takeLatest(actions.loadSalesLeadData.type, loadSalesLeadData);
  yield takeLatest(actions.loadWeddingDetails.type, loadWeddingDetails);
  yield takeLatest(actions.loadImpersonationLink.type, loadImpersonationLink);
  yield takeLatest(actions.loadUserActivity.type, loadUserActivity);
  yield takeLatest(actions.loadSalesLeadDetails.type, loadSalesLeadDetails);
  yield takeLatest(
    actions.loadSalesLeadAccountDetails.type,
    loadSalesLeadAccountDetails,
  );
  yield takeLatest(actions.loadAuthUsers.type, loadAuthUsers);
  yield takeLatest(actions.loadLeadInfoCRM.type, loadLeadInfoCRM);
  yield takeLatest(actions.assignLead.type, assignLead);
  yield takeLatest(actions.updateStatus.type, updateStatus);
  yield takeLatest(actions.getSalesStatusList.type, loadSalesLeadStatus);
  yield takeLatest(actions.updateSalesLeadStatus.type, updateSalesLeadStatus);
  yield takeLatest(actions.getClosedReasonAll.type, getClosedReasonAll);
  // yield takeLatest(actions.loadSalesLeadUpsells.type, loadSalesLeadUpsells);
  // yield takeLatest(actions.handleSavingUpsells.type, handleSavingUpsells);
  // yield takeLatest(actions.handleRemovingUpsells.type, handleRemovingUpsells);
  yield takeLatest(
    actions.updateSalesLeadAccountWeddingDetails.type,
    updateSalesLeadAccountWeddingDetails,
  );
  yield takeLatest(actions.assignCRMLead.type, assignCRMLead);
  yield takeLatest(actions.loadNotesForUser.type, loadNotesForUser);
  yield takeLatest(actions.addNote.type, addNote);
}
