/* eslint-disable react/no-array-index-key */
import React, { } from 'react';
import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';

import { toast } from 'react-toastify';

import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
import { TextField } from '../../../common/Form/TextInput';
import { TextArea } from '../../../common/Form/TextArea';
import { SelectInput } from '../../../common/Form/SelectInput';
import hotel from '../../../api/hotelContent';

class CreateHotelChain extends React.Component {
  // state = CreateHotelChain.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  validationSchema = Yup.object({
    hotelChainName: Yup.string().required('The hotel chain name is required.'),
  });

  constructor(props) {
    super(props);

    this.state = {
      hotelchainId: 0,
      initialValues: {
        id: 0,
        hotelChainName: '',
        hotelChainURL: '',
        description: '',
        phone: '',
        offersRewards: false,
        bonusCommissionsWebsite: '',
        bdm: '',
        weddingPackages: '',
        chainInfoDocumentURL: '',
        supplierCommissions: '',
        preferredSuppliers: '',
        supplierlist: [],
        supplierCommissionsIDPairs: [{ supplierId: '', value: '' }],
        preferredSuppliersIDPairs: [{ supplierId: '' }],
        hotelChainCode: ''
      },
    };
  }

  async getSupplier() {
    return hotel.hotelcontent.supplierlist();
  }

  async saveHotelChain(value) {
    const newValue = { ...value };
    const supplierCommissionsIDPairs = {};
    value.supplierCommissionsIDPairs.forEach((item) => {
      supplierCommissionsIDPairs[item.supplierId] = item.value;
    });
    // debugger;
    newValue.supplierCommissionsIDPairs = supplierCommissionsIDPairs;
    const prefersupplier = newValue.preferredSuppliersIDPairs.map(
      (x) => x.supplierId
    );

    newValue.PreferredSuppliers = prefersupplier.join(',');
    newValue.preferredSuppliersIDPairs = [];
    return hotel.hotelChain.saveHotelChain(newValue);
  }

  async componentDidMount() {
    const response = await this.getSupplier();
    const { id } = this.props.match.params;
    if (id && id > 0) {
      this.setState({ hotelchainId: id });
      const response = await hotel.hotelChain.getHotelChain(id);

      if (
        response.supplierCommissions
        && response.supplierCommissions.trim() !== ''
      ) {
        const suppliercommission = JSON.parse(response.supplierCommissions);
        const arr = Object.keys(JSON.parse(response.supplierCommissions));

        const supplierCommissionsIDPairs = [];
        arr.forEach((x) => {
          const obj = { supplierId: x, value: suppliercommission[x] };
          supplierCommissionsIDPairs.push(obj);
        });
        response.supplierCommissionsIDPairs = supplierCommissionsIDPairs;
      } else {
        response.supplierCommissionsIDPairs = [{ supplierId: '', value: '' }];
      }

      if (
        response.preferredSuppliers
        && response.preferredSuppliers.trim() !== ''
      ) {
        const arr = response.preferredSuppliers.split(',');
        const preferredSuppliersIDPairs = [];
        arr.forEach((x) => {
          const obj = { supplierId: x };
          preferredSuppliersIDPairs.push(obj);
        });
        response.preferredSuppliersIDPairs = preferredSuppliersIDPairs;
      } else {
        response.preferredSuppliersIDPairs = [{ supplierId: '' }];
      }

      this.setState({ initialValues: response });
    }

    this.setState({ supplierlist: response });
  }

  render() {
    const { initialValues } = this.state;
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={this.validationSchema}
        enableReinitialize
        onSubmit={async (values) => {
          const response = await this.saveHotelChain(values);
          toast.success(response);
          this.props.history.push('/hotels/hotel-chain-list');
          // return new Promise(res=>setTimeout(res,2500));
        }}
      >
        {({ values, isSubmitting }) => (
          <Card>
            <CardBody>
              <Form autoComplete="off">
                <Row>
                  <Col md="3"> </Col>
                  <Col md="6">
                    <Card>
                      {this.state.hotelchainId === 0 && (
                        <CardHeader> Add Hotel Chain </CardHeader>
                      )}
                      {this.state.hotelchainId > 0 && (
                        <CardHeader> Update Hotel Chain </CardHeader>
                      )}

                      <CardBody>
                        <FormGroup>
                          <TextField
                            label="Hotel Chain Name"
                            name="hotelChainName"
                          />
                        </FormGroup>
                        <FormGroup>
                          <TextField
                            label="Hotel Chain URL"
                            name="hotelChainURL"
                          />
                        </FormGroup>
                        <FormGroup>
                          <TextArea label="Description" name="description" />
                        </FormGroup>
                        <FormGroup>
                          <TextField label="Phone" name="phone" />
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="offersRewards">Offers Reward</label>
                          <div className="input-group mt-2">
                            <Field type="checkbox" name="offersRewards" />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <TextField
                            label="Bonus Commission Website"
                            name="bonusCommissionsWebsite"
                          />
                        </FormGroup>
                        <FormGroup>
                          <TextField label="BDM" name="bdm" />
                        </FormGroup>
                        <FormGroup>
                          <TextField
                            label="Wedding Packages"
                            name="weddingPackages"
                          />
                        </FormGroup>
                        <FormGroup>
                          <TextField
                            label="Hotel Chain Info Document URL"
                            name="chainInfoDocumentURL"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FieldArray name="supplierCommissionsIDPairs">
                            {({ push, remove }) => (
                              <>
                                {values.supplierCommissionsIDPairs.map(
                                  (value, index) => (
                                    <Row className="mt-2" key={index}>
                                      <Col>
                                        {this.state.supplierlist && (
                                          <SelectInput
                                            label="Supplier"
                                            options={this.state.supplierlist}
                                            name={`supplierCommissionsIDPairs[${index}].supplierId`}
                                          />
                                        )}
                                      </Col>

                                      <Col>
                                        <TextField
                                          label="Commission"
                                          name={`supplierCommissionsIDPairs[${index}].value`}
                                        />
                                      </Col>
                                      <Col>
                                        {index > 0 && (
                                          <Button
                                            style={{ marginTop: '30px' }}
                                            onClick={() => remove(index)}
                                            color="danger"
                                            className="mb-2 mr-2 btn-icon btn-icon-only"
                                          >
                                            {' '}
                                            <i className="pe-7s-trash btn-icon-wrapper">
                                              {' '}
                                            </i>
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  )
                                )}

                                <Button
                                  className="mt-3"
                                  onClick={() => push({ supplierId: '', value: '' })}
                                  color="success"
                                >
                                  <i className="pe-7s-plus btn-icon-wrapper">
                                    {' '}
                                  </i>{' '}
                                  Add{' '}
                                </Button>
                              </>
                            )}
                          </FieldArray>
                        </FormGroup>

                        <FormGroup>
                          <FieldArray name="preferredSuppliersIDPairs">
                            {({ push, remove }) => (
                              <>
                                {values.preferredSuppliersIDPairs.map(
                                  (value, index) => (
                                    <Row className="mt-2" key={index}>
                                      <Col>
                                        {this.state.supplierlist && (
                                          <div>
                                            <label
                                              htmlFor={`preferredSuppliersIDPairs[${index}].supplierId`}
                                            >
                                              Preferred Supplier {index + 1}
                                            </label>
                                            <SelectInput
                                              donotShowlabel
                                              options={this.state.supplierlist}
                                              name={`preferredSuppliersIDPairs[${index}].supplierId`}
                                            />
                                          </div>
                                        )}
                                      </Col>

                                      <Col>
                                        {index > 0 && (
                                          <Button
                                            style={{ marginTop: '30px' }}
                                            onClick={() => remove(index)}
                                            color="danger"
                                            className="mb-2 mr-2 btn-icon btn-icon-only"
                                          >
                                            {' '}
                                            <i className="pe-7s-trash btn-icon-wrapper">
                                              {' '}
                                            </i>
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  )
                                )}

                                <Button
                                  className="mt-3"
                                  onClick={() => push({ supplierId: '' })}
                                  color="success"
                                >
                                  <i className="pe-7s-plus btn-icon-wrapper">
                                    {' '}
                                  </i>{' '}
                                  Add{' '}
                                </Button>
                              </>
                            )}
                          </FieldArray>
                        </FormGroup>
                        <FormGroup>
                          <TextField
                            label="Hotel Chain Code"
                            name="hotelChainCode"
                          />
                        </FormGroup>
                      </CardBody>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        color="primary"
                        className="mt-1"
                      >
                        {isSubmitting ? 'Processing....' : 'Save'}
                      </Button>
                    </Card>
                  </Col>
                </Row>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre>  */}
              </Form>
            </CardBody>
          </Card>
        )}
      </Formik>
    );
  }
}

export default withRouter(CreateHotelChain);
