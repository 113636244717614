/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import SweetAlert from 'sweetalert-react';
import { Formik, Form, Field } from 'formik';

import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody, 
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import classnames from 'classnames';

import { toast } from 'react-toastify';
import arrayChunk from 'lodash.chunk';
import _ from 'lodash';

import { withRouter } from 'react-router-dom';

import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Lightbox from 'react-image-lightbox';
import { SelectInput } from '../../../../common/Form/SelectInput';
import { TextField } from '../../../../common/Form/TextInput';
import 'react-image-lightbox/style.css';

import {
  BooleanDropDown,
  Wifi,
  Rating,
} from '../../../../common/options/categoryoption';
import hotel from '../../../../api/hotelContent';
import ImageCropper from '../imageCropper';
// import '../../assets/app.scss';

class GeneralInfo extends React.Component {
  deleteRoomId;

  deleteImageUrl;

  countryId = 0;

  cityId = 0;

  // state = GeneralInfo.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  validationSchema = Yup.object({
    hotelFriendlyName: Yup.string().required(
      'Hotel Friendly Name is required.'
    ),
    hotelChainID: Yup.string().required('Hotel Chain is required.'),
    address1: Yup.string().required('Address1  is required.'),
    // rating: Yup.string().required('Rating is required.'),
    hotelCityId: Yup.string().required('City is required.'),
    hotelCountryId: Yup.string().required('Country is required.'),
    airportCodes: Yup.string().required('Airport code is required.'),

    adultsOnly: Yup.string().required('Adult only is required.'),
    // bars: Yup.string().required('Bar number is required.'),
    // pools: Yup.string().required('Pool number is required.'),
    // restaurants: Yup.string().required('Restaurant number  is required.'),
    // // dayPassPricing: Yup.string().required('Rating is required.'),
    // wifi: Yup.string().required('Wifi is required.')
  });

  // validationImage = Yup.object({
  //   imageName: Yup.string().required('Image Name is required.'),
  //   imageURL: Yup.string().required('imageURL is required.'),
  //   altTagText: Yup.string().required('altTagText is required.'),
  //   imageDisplayOrder: Yup.number().required('image dsiplay order is required')
  // })

  generalInformation = {
    hotelID: '',
    hotelFriendlyName: '',
    address1: '',
    address2: '',
    address3: '',
    hotelChainID: '',
    hotelCityId: '',
    hotelCountryId: '',
    gpsLat: '',
    gpsLong: '',
    airportCodes: '',
    salesManager: '',
    resortRep: '',
    weddingCoordinator1: '',
    weddingCoordinator2: '',
    weddingDeptPhone: '',
    specialRequests: '',
    frontDeskEmail: '',
    frontDeskPhone: '',
    mainDescription: '',
    shortDescription: '',
    resortFeatures: '',
    adultsOnly: '',
    bars: '',
    pools: '',
    restaurants: '',
    beachfront: '',
    casino: '',
    dayPassPricing: '',
    wifi: '',
    rating: '',
    isTopResort: '',
  };

  async getHotelById() {
    const { id } = this.props.match.params;
    this.generalInformation.hotelID = id;

    // hotel.hotelcontent.gethotelImageList(id).then(response => {
    //     this.setState({ hotelImageArr: response });
    // });

    const response = await hotel.hotelcontent.gethotelbyId(id);
    this.props.onSelectHotel(this.props.this, response);
    this.generalInformation.hotelChainID = response.hotelChainID;
    this.generalInformation.hotelFriendlyName = response.hotelFriendlyName;
    this.generalInformation.address1 = response.address1 == null ? '' : response.address1;
    this.generalInformation.address2 = response.address2 == null ? '' : response.address2;
    this.generalInformation.address3 = response.address3 == null ? '' : response.address3;
    this.generalInformation.hotelCountryId = response.hotelCountryId;
    this.getCitylist(response.hotelCountryId);
    this.generalInformation.hotelCityId = response.hotelCityId;
    this.countryId = response.hotelCountryId;
    this.cityId = response.hotelCityId;
    this.getAiportCode(response.hotelCountryId, response.hotelCityId);
    this.generalInformation.airportCodes = response.airportCodes == null ? '' : response.airportCodes;
    window.localStorage.setItem(
      'airportCodes',
      this.generalInformation.airportCodes
    );
    this.generalInformation.gpsLat = response.gpsLat == null ? '' : response.gpsLat;
    this.generalInformation.gpsLong = response.gpsLong == null ? '' : response.gpsLong;
    this.generalInformation.rating = response.rating == null ? '' : response.rating;

    this.generalInformation.salesManager = response.salesManager == null ? '' : response.salesManager;
    this.generalInformation.resortRep = response.resortRep == null ? '' : response.resortRep;
    this.generalInformation.weddingCoordinator1 = response.weddingCoordinator1 == null ? '' : response.weddingCoordinator1;
    this.generalInformation.weddingCoordinator2 = response.weddingCoordinator2 == null ? '' : response.weddingCoordinator2;
    this.generalInformation.weddingDeptPhone = response.weddingDeptPhone == null ? '' : response.weddingDeptPhone;
    this.generalInformation.specialRequests = response.specialRequests == null ? '' : response.specialRequests;

    this.generalInformation.frontDeskEmail = response.frontDeskEmail == null ? '' : response.frontDeskEmail;

    this.generalInformation.frontDeskPhone = response.frontDeskPhone == null ? '' : response.frontDeskPhone.trim();

    this.generalInformation.adultsOnly = response.adultsOnly == null ? '' : response.adultsOnly.toString();
    this.generalInformation.bars = response.bars == null ? '' : response.bars;
    this.generalInformation.pools = response.pools == null ? '' : response.pools;
    this.generalInformation.pools = response.pools == null ? '' : response.pools;
    this.generalInformation.beachfront = response.beachfront == null ? '' : response.beachfront.toString();
    this.generalInformation.casino = response.casino == null ? '' : response.casino.toString();
    this.generalInformation.dayPassPricing = response.dayPassPricing == null ? '' : response.dayPassPricing;
    this.generalInformation.restaurants = response.restaurants == null ? '' : response.restaurants;

    this.generalInformation.wifi = response.wifi == null ? '' : response.wifi;

    this.generalInformation.isTopResort = response.isTopResort == null ? 'false' : response.isTopResort;
    this.setState({ generalInformation: this.generalInformation });
    this.setState({
      mainDescription:
        response.mainDescription == null ? '' : response.mainDescription,
    });
    this.setState({
      shortDescription:
        response.shortDescription == null ? '' : response.shortDescription,
    });
    this.setState({
      resortFeatures:
        response.resortFeatures == null ? '' : response.resortFeatures,
    });
  }

  getHotelImages() {
    hotel.hotelcontent
      .gethotelImagebyId(this.generalInformation.hotelID)
      .then((response) => {
        const result = _.map(response, 'imageURL');
        this.setState({ hotelImages: result });
        this.setState({ hotelImageDetails: response });
        this.setState({ chunkedData: arrayChunk(response, 3) });
      });
  }

  hotelImageList() {
    hotel.hotelcontent
      .gethotelImageList(this.generalInformation.hotelID)
      .then((response) => {
        this.setState({ hotelImageArr: response });
      });
  }

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.state = {
      showImageUpload: false,
      modal: false,
      chunkedData: [],
      hotelImageArr: [],
      activeTab: '1',
      mainDescription: '',
      shortDescription: '',
      resortFeatures: '',

      hotelChain: [],
      countryList: [],
      cityList: [],
      hotelImages: [],
      hotelImageDetails: [],
      airportList: [],
      generalInformation: this.generalInformation,
      photoIndex: 0,
      isOpen: false,
      loading: false,
      showAddImage: false,

      imageUrl: '',
      imageName: '',
      altTagText: '',
      imageDisplayOrder: '',
      imageUrlrequired: false,
      imageNamerequired: false,
      altTagTextrequired: false,
      imageDisplayOrderrequired: false,
      selectedImage: '',
      selectedImageIndex: -1,
      selectedImageOperation: '',
      isSubmitting: false,
    };
    this.togglepopup = this.togglepopup.bind(this);
    this.togglepopupS3Image = this.togglepopupS3Image.bind(this);
  }

  togglepopup() {
    this.setState({
      modal: false,
    });
  }

  togglepopupS3Image() {
    this.setState({
      showImageUpload: false,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onChangemainDescription = (e, editor) => {
    this.setState({ mainDescription: editor.getData() });
  };

  onChangeshortDescription = (e, editor) => {
    this.setState({ shortDescription: editor.getData() });
  };

  onChangeResortFeatures = (e, editor) => {
    this.setState({ resortFeatures: editor.getData() });
  };

  async getCountrylist() {
    const response = await hotel.hotelcontent.countrylist();
    this.setState({ countryList: response });
  }

  getCitylist = (id) => {
    hotel.hotelcontent.city(id).then((response) => {
      this.setState({ cityList: response });
    });
  };

  getAiportCode = () => {
    if (this.countryId > 0) {
      hotel.hotelcontent
        .airport(this.countryId, this.cityId)
        .then((response) => {
          this.setState({ airportList: response });
        });
    } else {
      this.generalInformation.hotelCountryId = 0;
      this.generalInformation.hotelCityId = '';
      this.generalInformation.airportCodes = '';
      this.setState({
        generalInformation: this.generalInformation,
        cityList: [],
        airportList: [],
      });
    }
  };

  // countryhandleChange = (e) => {
  //   this.generalInformation.hotelCityId = '';
  //   this.generalInformation.airportCodes = '';
  //   this.getCitylist(e.target.value);
  //   this.generalInformation.hotelCountryId = e.target.value;
  //   this.countryId = e.target.value;
  //   this.setState({ generalInformation: this.generalInformation });
  //   this.getAiportCode();
  // }

  async getHotelChain() {
    const response = await hotel.hotelcontent.hotelchainlist();
    this.setState({ hotelChain: response });
  }

  async componentDidMount() {
    await this.getCountrylist();
    await this.getHotelChain();
    await this.getHotelById();
  }

  submitHotelContent = (value) => {
    this.setState({ isSubmitting: true });
    value.mainDescription = this.state.mainDescription;
    value.shortDescription = this.state.shortDescription;
    value.resortFeatures = this.state.resortFeatures;

    hotel.hotelcontent
      .updateHotel(value)
      .then(() => {
        this.setState({ isSubmitting: false });
        toast.success('Hotel saved successfully.');
        // this.props.history.push("/hotels/hotel-content");
      })
      .catch(() => {
        this.setState({ isSubmitting: false });
      });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    this.setState((pS) => {
      const list = [...pS.hotelImageDetails];
      list[index][name] = value;
      return { hotelImageDetails: list };
    });

    // this.setState({ hotelImages: this.state.hotelImageDetails.imageURL });

    this.setState((pS) => ({
      chunkedData: arrayChunk(pS.hotelImageDetails, 3),
    }));
  };

  handleSelectImage = (value) => {
    this.setState((pS) => {
      const list = [...pS.hotelImageDetails];
      list[pS.selectedImageIndex].imageURL = value;
      return { hotelImageDetails: list };
    });
    this.setState((pS) => ({
      chunkedData: arrayChunk(pS.hotelImageDetails, 3),
    }));
  };

  // updateImage = (index) => {

  //     let image = this.state.hotelImageDetails[index];

  //     hotel.hotelcontent.updateImages(image).then(response => {
  //         toast.success("Hotel image details saved successfully.");
  //     })

  // }

  showConfirmationToDelete = (id, imgUrl) => {
    this.deleteRoomId = id;
    this.deleteImageUrl = imgUrl;
    this.setState({ show: true });
  };

  deleteRoom = () => {
    const { id } = this.props.match.params;
    hotel.hotelcontent
      .deleteImages({
        Id: this.deleteRoomId,
        HotelID: id,
        ImageURL: this.deleteImageUrl,
      })
      .then((response) => {
        toast.success(response);
        this.getHotelImages();
      });
  };

  addImage = () => {
    this.setState({
      imageUrl: '',
      imageName: '',
      altTagText: '',
      imageUrlrequired: false,
      imageNamerequired: false,
      altTagTextrequired: false,
      imageDisplayOrderrequired: false,
      showAddImage: true,
    });
    window.location.href = '#divImage';
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleImageSubmit = () => {
    if (!this.isFormValid()) return;
    this.setState({ loading: true });
    const data = {
      hotelId: this.generalInformation.hotelID,
      imageName: this.state.imageName,
      altTagText: this.state.altTagText,
      imageURL: this.state.imageUrl,
      imageDisplayOrder: this.state.imageDisplayOrder,
    };

    hotel.hotelcontent.saveImages(data).then(() => {
      this.setState({ loading: false });
      this.setState({ showAddImage: false });
      toast.success('Image saved successfully.');
      this.getHotelImages();
    });
  };

  isFormValid = () => {
    if (this.isFormEmpty(this.state)) {
      return false;
    }
    return true;
  };

  isFormEmpty({ imageName, imageUrl, altTagText, imageDisplayOrder }) {
    if (!imageName.length) {
      this.setState({ imageNamerequired: true });
    } else {
      this.setState({ imageNamerequired: false });
    }

    if (!imageUrl.length) {
      this.setState({ imageUrlrequired: true });
    } else {
      this.setState({ imageUrlrequired: false });
    }

    if (!altTagText.length) {
      this.setState({ altTagTextrequired: true });
    } else {
      this.setState({ altTagTextrequired: false });
    }
    if (!imageDisplayOrder.length) {
      this.setState({ imageDisplayOrderrequired: true });
    } else {
      this.setState({ imageDisplayOrderrequired: false });
    }

    return !imageName.length || !imageUrl.length || !imageDisplayOrder.length;
  }

  updateImage = (index) => {
    const image = this.state.hotelImageDetails[index];
    hotel.hotelcontent.updateImages(image).then(() => {
      this.setState((pS) => {
        const arr = pS.hotelImages;
        arr[index] = image.imageURL;
        return { hotelImages: arr };
      });
      toast.success('Hotel image details saved successfully.');
    });
  };

  handleSelect = (e) => {
    const { value } = e.target;
    this.setState({ selectedImage: value });
  };

  // componentWillUnmount() {
  //   // fix Warning: Can't perform a React state update on an unmounted component
  //   this.setState = (state, callback) => {

  //   };
  // }

  async submitS3Image(param) {
    const imageUrl = await hotel.hotelcontent.uploadS3Photo(
      param.blob,
      param.this.generalInformation.hotelID
    );

    if (param.selectedImageOperation === 'update') {
      const list = [...param.this.state.hotelImageDetails];
      list[param.this.state.selectedImageIndex].imageURL = imageUrl;
      param.this.setState({ hotelImageDetails: list, showImageUpload: false });
      param.this.setState({
        chunkedData: arrayChunk(param.this.state.hotelImageDetails, 3),
      });
    } else if (param.selectedImageOperation === 'insert') {
      param.this.setState({ imageUrl, showImageUpload: false });
    }
  }

  render() {
    const { imageNamerequired, imageUrlrequired, imageDisplayOrderrequired } = this.state;
    //   const externalCloseBtn = <button type="button" className="close" style={ { position: 'absolute', top: '15px', right: '15px' } }>&times;</button>;
    return (
      <div>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text="Are you sure to delete this image?"
          showCancelButton
          onConfirm={ () => {
            this.deleteRoom();
            this.setState({ show: false });
          } }
          onCancel={ () => {
            // eslint-disable-line no-console
            this.setState({ show: false });
          } }
          onClose={ () => {} }
        />

        <Formik
          validationSchema={ this.validationSchema }
          enableReinitialize
          initialValues={ this.state.generalInformation }
          onSubmit={ (values) => {
            this.submitHotelContent(values);
          } }
        >
          {({ errors, values, touched, handleSubmit, setFieldValue }) => (
            <Row>
              <Col md="12">
                <Form onSubmit={ handleSubmit }>
                  <div className="card">
                    <div className="card-body">
                      <Row>
                        <Col md="6">
                          <Card className="main-card card">
                            <CardHeader>Hotel Info</CardHeader>
                            <CardBody>
                              <FormGroup>
                                <SelectInput
                                  options={ this.state.hotelChain }
                                  label="Hotel Chain"
                                  name="hotelChainID"
                                />
                              </FormGroup>
                              <FormGroup>
                                <TextField
                                  label="Hotel Name"
                                  name="hotelFriendlyName"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Address 1" name="address1" />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Address 2" name="address2" />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Address 3" name="address3" />
                              </FormGroup>

                              <FormGroup>
                                <FormGroup>
                                  <label htmlFor="hotelCountryId">
                                    {' '}
                                    Country{' '}
                                  </label>
                                  <Field
                                    name="hotelCountryId"
                                    className={
                                      errors.hotelCountryId
                                      && touched.hotelCountryId
                                        ? 'is-invalid form-control'
                                        : 'form-control'
                                    }
                                    value={ values.hotelCountryId }
                                    as="select"
                                    onChange={ (e) => {
                                      this.getCitylist(e.target.value);
                                      setFieldValue('hotelCityId', '');
                                      setFieldValue('airportCodes', '');
                                      setFieldValue(
                                        'hotelCountryId',
                                        e.target.value
                                      );
                                      this.countryId = e.target.value;
                                      this.getAiportCode();
                                    } }
                                  >
                                    <option value="">--Select--</option>
                                    {this.state.countryList.map((country) => (
                                      <option
                                        key={ country.value }
                                        value={ country.value }
                                      >
                                        {country.text}
                                      </option>
                                    ))}
                                  </Field>

                                  {errors.hotelCountryId
                                  && touched.hotelCountryId ? (
                                    <div className="invalid-validation">
                                      {errors.hotelCountryId}
                                    </div>
                                    ) : null}
                                </FormGroup>
                              </FormGroup>

                              <FormGroup>
                                <SelectInput
                                  options={ this.state.cityList }
                                  label="City"
                                  name="hotelCityId"
                                />
                              </FormGroup>

                              <FormGroup>
                                <SelectInput
                                  options={ this.state.airportList }
                                  label="Airport Code"
                                  name="airportCodes"
                                />
                              </FormGroup>

                              <FormGroup>
                                <SelectInput
                                  options={ Rating }
                                  label="Rating"
                                  name="rating"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Latitude" name="gpsLat" />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Longitude" name="gpsLong" />
                              </FormGroup>

                              <FormGroup>
                                <SelectInput
                                  donotShowSelect
                                  options={ BooleanDropDown }
                                  label="Top Resort"
                                  name="isTopResort"
                                />
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </Col>

                        <div className="col-md-6">
                          <Card className="main-card card">
                            <CardHeader>Administrative Details</CardHeader>
                            <CardBody>
                              <FormGroup>
                                <TextField
                                  label="Sales Manager"
                                  name="salesManager"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Resort Rep"
                                  name="resortRep"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Wedding Coordinators 1"
                                  name="weddingCoordinator1"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Wedding Coordinators 2"
                                  name="weddingCoordinator2"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Wedding Dept Phone"
                                  name="weddingDeptPhone"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Special Request"
                                  name="specialRequests"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Front Desk Email"
                                  name="frontDeskEmail"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Front Desk Phone"
                                  name="frontDeskPhone"
                                />
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </div>
                      </Row>
                    </div>
                  </div>

                  <Card className="main-card mt-3 mb-3">
                    <CardHeader>
                      <Nav justified>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={ classnames({
                              active: this.state.activeTab === '1',
                            }) }
                            onClick={ () => {
                              this.toggle('1');
                            } }
                          >
                            Resort Description
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            href="#"
                            className={ classnames({
                              active: this.state.activeTab === '2',
                            }) }
                            onClick={ () => {
                              this.getHotelImages();
                              this.hotelImageList();
                              this.toggle('2');
                            } }
                          >
                            Resort Images
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </CardHeader>
                    <CardBody>
                      <TabContent activeTab={ this.state.activeTab }>
                        <TabPane tabId="1">
                          <Row>
                            <div className="col-md-12">
                              <label htmlFor="MainDescription">
                                {' '}
                                Main Description{' '}
                              </label>

                              <CKEditor
                                name="MainDescription"
                                editor={ ClassicEditor }
                                data={ this.state.mainDescription }
                                onChange={ this.onChangemainDescription }
                              />
                            </div>
                          </Row>

                          <Row className="mt-3">
                            <div className="col-md-12">
                              <label htmlFor="ShortDescription">
                                {' '}
                                Short Description{' '}
                              </label>

                              <CKEditor
                                name="ShortDescription"
                                editor={ ClassicEditor }
                                data={ this.state.shortDescription }
                                onChange={ this.onChangeshortDescription }
                              />
                            </div>
                          </Row>

                          <Card className="main-card card mt-3">
                            <CardHeader>Amenities</CardHeader>
                            <CardBody>
                              <Row>
                                <div className="col-md-2">
                                  <FormGroup>
                                    <SelectInput
                                      options={ BooleanDropDown }
                                      label="Adults Only"
                                      name="adultsOnly"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <TextField label="Bars" name="bars" />
                                  </FormGroup>

                                  <FormGroup>
                                    <TextField label="Pools" name="pools" />
                                  </FormGroup>

                                  <FormGroup>
                                    <TextField
                                      label="Restaurants"
                                      name="restaurants"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <SelectInput
                                      donotShowSelect
                                      options={ BooleanDropDown }
                                      label="Beach Front"
                                      name="beachfront"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <SelectInput
                                      donotShowSelect
                                      options={ BooleanDropDown }
                                      label="Casino"
                                      name="casino"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <TextField
                                      label="Day Pass Pricing"
                                      name="dayPassPricing"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <SelectInput
                                      options={ Wifi }
                                      label="Wifi"
                                      name="wifi"
                                    />
                                  </FormGroup>
                                </div>

                                <div className="col-md-10">
                                  {/* <TextArea rows={5} label="Resort Features" name="resortFeatures" /> */}
                                  <label htmlFor="ResortFeature">
                                    {' '}
                                    Resort Feature{' '}
                                  </label>
                                  <CKEditor
                                    name="ResortFeature"
                                    editor={ ClassicEditor }
                                    data={ this.state.resortFeatures }
                                    onChange={ this.onChangeResortFeatures }
                                  />
                                </div>
                              </Row>
                              <Button
                                disabled={ this.state.isSubmitting }
                                color="primary"
                                className="mt-1"
                              >
                                {' '}
                                {this.state.isSubmitting
                                  ? 'Processing....'
                                  : 'Save'}
                              </Button>
                            </CardBody>
                          </Card>
                        </TabPane>
                        <TabPane tabId="2">
                          <Modal
                            isOpen={ this.state.showImageUpload }
                            toggle={ this.togglepopupS3Image }
                            className="modal-xl"
                          >
                            <ModalHeader toggle={ this.togglepopupS3Image }>
                              Upload Image
                            </ModalHeader>
                            <ModalBody
                              style={ {
                                maxHeight: 'calc(100vh - 210px)',
                                overflowY: 'auto',
                              } }
                            >
                              <ImageCropper
                                this={ this }
                                selectedImageOperation={
                                  this.state.selectedImageOperation
                                }
                                submitS3Image={ this.submitS3Image }
                              />
                              {/* <UploadImage this={this} selectedImageOperation={this.state.selectedImageOperation} submitS3Image={this.submitS3Image}/> */}
                            </ModalBody>
                            <ModalFooter />
                          </Modal>

                          <Modal
                            isOpen={ this.state.modal }
                            toggle={ this.togglepopup }
                            className="modal-xl"
                          >
                            <ModalHeader toggle={ this.togglepopup }>
                              BedsOnline Images
                            </ModalHeader>
                            <ModalBody
                              style={ {
                                maxHeight: 'calc(100vh - 210px)',
                                overflowY: 'auto',
                              } }
                            >
                              <div className="row row-flex">
                                {this.state.hotelImageArr.length > 0
                                  && this.state.hotelImageArr.map(
                                    (value, index) => (
                                      <div
                                        className="col-md-4 col-sm-6 col-xs-12"
                                        key={ index }
                                      >
                                        <div className="custom-control custom-radio image-checkbox mb-3">
                                          <input
                                            type="radio"
                                            className="custom-control-input"
                                            id={ index }
                                            onChange={ (e) => this.handleSelect(e) }
                                            name="ck2"
                                            value={ value }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={ index }
                                          >
                                            <img
                                              src={ value }
                                              alt="#"
                                              className="img-fluid"
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    )
                                  )}
                                {this.state.hotelImageArr.length === 0 && (
                                  <span className="ml-3 mt-3 mb-3">
                                    No image found. Either bedonlineId is not
                                    set or the bedonlineId is not accurate.
                                  </span>
                                )}
                              </div>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                color="danger"
                                onClick={ () => {
                                  this.setState({ modal: false });
                                } }
                              >
                                Cancel
                              </Button>
                              {this.state.hotelImageArr.length > 0 && (
                                <Button
                                  color="primary"
                                  onClick={ () => {
                                    if (
                                      this.state.selectedImageOperation
                                      === 'insert'
                                    ) {
                                      this.setState((pS) => ({
                                        imageUrl: pS.selectedImage,
                                        modal: false,
                                      }));
                                    } else if (
                                      this.state.selectedImageOperation
                                      === 'update'
                                    ) {
                                      this.handleSelectImage(
                                        this.state.selectedImage,
                                        this.state.selectedImageIndex
                                      );
                                      this.setState({ modal: false });
                                    }
                                  } }
                                >
                                  Select Image
                                </Button>
                              )}
                            </ModalFooter>
                          </Modal>
                          {this.state.isOpen && (
                            <Lightbox
                              mainSrc={
                                this.state.hotelImages[this.state.photoIndex]
                              }
                              nextSrc={
                                this.state.hotelImages[
                                  (this.state.photoIndex + 1)
                                    % this.state.hotelImages.length
                                ]
                              }
                              prevSrc={
                                this.state.hotelImages[
                                  (this.state.photoIndex
                                    + this.state.hotelImages.length
                                    - 1)
                                    % this.state.hotelImages.length
                                ]
                              }
                              onCloseRequest={ () => this.setState({ isOpen: false }) }
                              onMovePrevRequest={ () => this.setState((pS) => ({
                                photoIndex:
                                    (pS.photoIndex
                                      + pS.hotelImages.length
                                      - 1)
                                    % pS.hotelImages.length,
                              })) }
                              onMoveNextRequest={ () => this.setState((pS) => ({
                                photoIndex:
                                    (pS.photoIndex + 1) % pS.hotelImages.length,
                              })) }
                            />
                          )}

                          <div className="container">
                            {this.state.chunkedData.map((row, rowIndex) => (
                              <div key={ rowIndex } className="row mb-4">
                                {row.map((col, colIndex) => (
                                  <div
                                    className="col-lg-4 d-none d-lg-block"
                                    key={ colIndex }
                                  >
                                    <div className="card">
                                      <div className="card-body">
                                        <Form>
                                          <FormGroup>
                                            <label htmlFor="imageName">
                                              Image Name
                                            </label>
                                            <input
                                              type="text"
                                              name="imageName"
                                              onChange={ (e) => this.handleInputChange(
                                                e,
                                                rowIndex + colIndex
                                              ) }
                                              value={ col.imageName }
                                              className="form-control mb-2"
                                            />
                                            <img
                                              name={ col.altTagText }
                                              onClick={ () => this.setState({ isOpen: true }) }
                                              src={ col.imageURL }
                                              className="imagesize hover"
                                              alt={ col.altTagText }
                                              onError={ (e) => {
                                                e.target.onerror = null;
                                                e.target.src = 'https://via.placeholder.com/350x229?text=No_Image_found';
                                              } }
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <label htmlFor="imageURL">
                                              Image URL
                                            </label>{' '}
                                            <input
                                              type="text"
                                              name="imageURL"
                                              onChange={ (e) => this.handleInputChange(
                                                e,
                                                rowIndex * 3 + colIndex
                                              ) }
                                              value={ col.imageURL }
                                              className="form-control"
                                            />
                                            <button
                                              type="button"
                                              className="mt-2  border-0  btn btn-outline-primary"
                                              onClick={ (e) => {
                                                e.preventDefault();
                                                this.setState({
                                                  selectedImage: '',
                                                  selectedImageOperation:
                                                    'update',
                                                  selectedImageIndex:
                                                    rowIndex * 3 + colIndex,
                                                  modal: true,
                                                });
                                              } }
                                            >
                                              Browse BOL Image
                                            </button>
                                            <button
                                              type="button"
                                              className="mt-2 border-0  btn btn-outline-primary"
                                              onClick={ (e) => {
                                                e.preventDefault();
                                                this.setState({
                                                  selectedImageOperation:
                                                    'update',
                                                  showImageUpload: true,
                                                  selectedImageIndex:
                                                    rowIndex * 3 + colIndex,
                                                });
                                              } }
                                            >
                                              Upload Image
                                            </button>
                                          </FormGroup>

                                          <FormGroup>
                                            <label htmlFor="altTagText">
                                              Alt Text
                                            </label>
                                            <input
                                              type="text"
                                              name="altTagText"
                                              onChange={ (e) => this.handleInputChange(
                                                e,
                                                rowIndex * 3 + colIndex
                                              ) }
                                              value={ col.altTagText }
                                              className="form-control"
                                            />
                                          </FormGroup>
                                          <FormGroup>
                                            <label htmlFor="imageDisplayOrder">
                                              Display Order
                                            </label>
                                            <input
                                              type="number"
                                              name="imageDisplayOrder"
                                              id="imageDisplayOrder"
                                              onChange={ (e) => this.handleInputChange(
                                                e,
                                                rowIndex * 3 + colIndex
                                              ) }
                                              value={ col.imageDisplayOrder }
                                              className="form-control"
                                            />
                                          </FormGroup>

                                          <button
                                            type="button"
                                            className="mt-2 mb-2 mr-2 btn-icon btn btn-success"
                                            onClick={ (e) => {
                                              e.preventDefault();
                                              this.updateImage(
                                                rowIndex * 3 + colIndex
                                              );
                                            } }
                                          >
                                            <i className="pe-7s-edit btn-icon-wrapper">
                                              {' '}
                                            </i>
                                            Update
                                          </button>
                                          <button
                                            type="button"
                                            className="mt-2 mb-2 mr-2 btn-icon btn btn-danger"
                                            onClick={ (e) => {
                                              e.preventDefault();
                                              this.showConfirmationToDelete(
                                                col.id,
                                                col.imageURL
                                              );
                                            } }
                                          >
                                            <i className="pe-7s-trash btn-icon-wrapper">
                                              {' '}
                                            </i>
                                            Delete
                                          </button>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}

                            <Button
                              color="primary"
                              onClick={ () => this.addImage() }
                            >
                              Add New Hotel Image
                            </Button>
                          </div>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                  {/* {<pre>{JSON.stringify({  values,errors }, null, 4)}</pre> } */}
                </Form>
              </Col>
            </Row>
          )}
        </Formik>

        <div id="divImage">
          <Card
            className={
              this.state.showAddImage ? 'mb-4 mt-4' : 'mb-4 mt-4 d-none'
            }
          >
            <CardHeader>Add Image</CardHeader>
            <CardBody>
              <FormGroup>
                <label htmlFor="imageName">Image Name</label>
                <input
                  type="text"
                  className={
                    imageNamerequired
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  value={ this.state.imageName }
                  id="imageName"
                  name="imageName"
                  onChange={ this.handleChange }
                />

                {imageNamerequired && (
                  <div className="invalid-validation">
                    Image Name is required
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <label htmlFor="imageUrl">Image Url</label>
                <input
                  type="text"
                  className={
                    imageUrlrequired
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  value={ this.state.imageUrl }
                  name="imageUrl"
                  onChange={ this.handleChange }
                />

                {imageUrlrequired && (
                  <div className="invalid-validation">
                    Image Url is required
                  </div>
                )}

                <button
                  type="button"
                  className="mt-2 mb-2  border-0 btn btn-outline-primary"
                  onClick={ () => this.setState({
                    modal: true,
                    selectedImageOperation: 'insert',
                  }) }
                >
                  Browse BOL Image
                </button>

                <button
                  type="button"
                  className="mt-2 mb-2  border-0  btn btn-outline-primary"
                  onClick={ (e) => {
                    e.preventDefault();
                    this.setState({
                      selectedImageOperation: 'insert',
                      showImageUpload: true,
                    });
                  } }
                >
                  Upload Image
                </button>
              </FormGroup>
              <FormGroup>
                <label htmlFor="altTagText">Alt Tag Text</label>
                <input
                  type="text"
                  value={ this.state.altTagText }
                  className="form-control"
                  name="altTagText"
                  onChange={ this.handleChange }
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="imageDisplayOrder">Image display order</label>
                <input
                  type="number"
                  name="imageDisplayOrder"
                  value={ this.state.imageDisplayOrder }
                  id="imageDisplayOrder"
                  onChange={ this.handleChange }
                  className={
                    imageDisplayOrderrequired
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                />
                {imageDisplayOrderrequired && (
                  <div className="invalid-validation">
                    Image display order is required
                  </div>
                )}
              </FormGroup>
              {this.state.imageUrl !== '' && (
                <FormGroup>
                  <label htmlFor="imagePreview">Image Preview</label>
                  <br />
                  <img
                    src={ this.state.imageUrl }
                    name="imagePreview"
                    className="img-fluid"
                    alt={ this.state.altTagText }
                  />
                </FormGroup>
              )}
              <button
                type="submit"
                onClick={ () => this.handleImageSubmit() }
                className="btn btn-primary"
              >
                Submit
              </button>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default withRouter(GeneralInfo);
