import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from 'types/RootState';

const selectSlice = (state: RootState) => state.salesLead || initialState;


export const selectBookedConfirmUserList = createSelector(
  [selectSlice],
  state => state.bookedConfirmUserList,
);

export const selectWeddingDetailsGroupSize = createSelector(
  [selectSlice],
  state => state.weddingDetailsGroupSize,
);

export const selectSalesSourceList = createSelector(
  [selectSlice],
  state => state.salesSourceList
)

export const selectSalesStatus = createSelector(
  [selectSlice],
  state => state.status
)

export const selectClosedReasons = createSelector(
  [selectSlice],
  state => state.closedReasons
)

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading
)

export const selectWeddingDetails = createSelector(
  [selectSlice],
  state => state.weddingDetails
)

export const selectImpersonationLink = createSelector(
  [selectSlice],
  state => state.impersonationLink
)

export const selectUserActivity = createSelector(
  [selectSlice],
  state => state.userActivity
)

export const selectSalesLeadDetails = createSelector(
  [selectSlice],
  state => state.salesLeadDetails
)

export const selectSalesLeadAccountDetails = createSelector(
  [selectSlice],
  state => state.salesLeadAccountDetails
)

export const selectAuthUsers = createSelector(
  [selectSlice],
  state => state.authUsers
)

export const selectLeadInfoCRM = createSelector(
  [selectSlice],
  state => state.leadInfoCRM
)

export const selectLoadingUpdateWeddingDetails = createSelector(
  [selectSlice],
  state => state.loadingUpdateWeddingDetails
)

export const selectIsUpdatingAssignLead = createSelector(
  [selectSlice],
  state => state.isUpdatingAssignedAgent
)
export const selectNotes = createSelector(
  [selectSlice],
  state => state.notes
)

