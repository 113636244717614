import React, { useState } from 'react';
import '../../assets/app.scss';
import { useField } from 'formik';

export function TextCurrency({ label, ...props }) {
  const [field, meta, helpers] = useField(props);
  const [previousValue, setPreviousValue] = useState(meta.initialValue);
  // const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
  const rxLive = /^\d*(\.\d{0,6})?$/;
  function isNumberKey(evt) {
    if (rxLive.test(evt.target.value)) {
      helpers.setValue(evt.target.value);
      setPreviousValue(evt.target.value);
    } else if (typeof previousValue === 'undefined') {
      helpers.setValue('');
    } else {
      helpers.setValue(previousValue);
    }
  }
  return (
    <>
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">$</span>
        </div>

        <input
          onKeyUp={event => isNumberKey(event)}
          className={
            meta.touched && meta.error
              ? 'is-invalid form-control'
              : 'form-control'
          }
          {...field}
          {...props}
        />
      </div>

      {meta.touched && meta.error ? (
        <div className="invalid-validation">{meta.error}</div>
      ) : null}
    </>
  );
}
