/* eslint-disable */
// tslint:disable
/**
 * Destify Administration API
 * API environment - staging
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum GuestUserRoleType {
    Guest = 'Guest',
    GroupLeader = 'GroupLeader',
    SalesLead = 'SalesLead'
}



