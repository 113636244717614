import axios from 'axios';
import { AppConfig } from 'config';
import { axiosFailedResponsesHandler } from '../axiosFailedResponsesHandler';
import { IBaseODataApiClient } from './IBaseODataApiClient';
import { escapeSpecialChars } from 'utils/utils';


const destifySalesJourneyApiAxiosClient = axios.create();
destifySalesJourneyApiAxiosClient.defaults.baseURL = AppConfig.Api.SalesJourneyApi.BaseUrl + '/odata/';
destifySalesJourneyApiAxiosClient.interceptors.request.use(config => {
	const newConfig = { ...config };
	const loggedInUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
	const apiKey = AppConfig.Api.SalesJourneyApi.ApiToken;
	if (apiKey) {
		newConfig.headers['Apikey'] = apiKey;
	}
	if (loggedInUser?.profile) {
		newConfig.headers['AdminUserId'] = loggedInUser.profile["custom:DestifyLegacyUserId"];
	}
	return newConfig;
});
destifySalesJourneyApiAxiosClient.interceptors.response.use(null, axiosFailedResponsesHandler);

export class SalesJourneyODataApiClient implements IBaseODataApiClient {
	async getODataListResponseWithCount<T>(url: string = '') {
		const { data: { value, '@odata.count': count } } = await destifySalesJourneyApiAxiosClient.get<{ value: T[]; '@odata.count': number }>(escapeSpecialChars(url));
		return {
			data: value,
			count
		}
	}

	async getData<T>(url: string = '') {
		const { data: { value } } = await destifySalesJourneyApiAxiosClient.get<{ value: T }>(url);
		return value;
	}

};

