import React from 'react';
import { connect } from 'react-redux';

const PageTitle = (props) => {

  const {
    enablePageTitleIcon,
    enablePageTitleSubheading,
    heading,
    icon,
    subheading,
  } = props;

  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div>
            {heading}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
  enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageTitle);
