import {
  startOfDay as startDay,
  endOfDay as endDay,
  addDays as addDay,
  differenceInDays as diffDays,
  formatDistanceToNow as formatDisToNow,
} from 'date-fns';
import {
  format,
  utcToZonedTime as utcToZ,
  zonedTimeToUtc as zToUTC,
} from 'date-fns-tz';
import { UTCDate } from '@date-fns/utc';
import { toast } from 'react-toastify';

export function startOfDay(date) {
  try {
    if (!date) {
      return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
    }

    return startDay(utcToZ(date, null));
  } catch (error) {
    console.error(error);
    toast.error(`Unexpected date passed in. Got ${date}`);
    return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
  }
}

export function endOfDay(date) {
  try {
    if (!date) {
      return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
    }

    return endDay(utcToZ(date, null));
  } catch (error) {
    console.error(error);
    toast.error(`Unexpected date passed in. Got ${date}`);
    return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
  }
}

export function addDays(date, days) {
  try {
    if (!date) {
      return Date.parse('01 Jan 1970 00:00:00 GMT');
    }
    return addDay(utcToZ(date, null), days);
  } catch (error) {
    console.error(error);
    toast.error(`Unexpected date passed in. Got ${date}`);
    return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
  }
}

export function getDate(date) {
  try {
    if (!date) {
      return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
    }
    return utcToZ(date, null);
  } catch (error) {
    console.error(error);
    toast.error(`Unexpected date passed in. Got ${date}`);
    return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
  }
}

export function utcToZonedTime(date) {
  try {
    if (!date) {
      return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
    }
    return utcToZ(date, null);
  } catch (error) {
    console.error(error);
    toast.error(`Unexpected date passed in. Got ${date}`);
    return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
  }
}

export function zonedTimeToUtc(date) {
  try {
    if (!date) {
      return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
    }
    return zToUTC(date, null);
  } catch (error) {
    console.error(error);
    toast.error(`Unexpected date passed in. Got ${date}`);
    return new Date(Date.parse('01 Jan 1970 00:00:00 GMT'));
  }
}

export function getUTCDate() {
  return new UTCDate();
}

export function formatDistanceToNow(date, dateAsUTC = false) {
  try {
    if (!date) {
      return 'Invalid Date';
    }

    if (dateAsUTC) {
      return formatDisToNow(utcToZ(date, null), { addSuffix: true });
    }

    return formatDisToNow(new Date(date), { addSuffix: true });
  } catch (error) {
    console.error(error);
    toast.error(`Unexpected date passed in. Got ${date}`);
    return 'Invalid Date';
  }
}

export function differenceInDays(dateFrom, dateTo) {
  try {
    if (!dateFrom || !dateTo) {
      return -999;
    }

    return diffDays(utcToZ(dateFrom, null), utcToZ(dateTo, null));
  } catch (error) {
    console.error(error);
    toast.error(`Unexpected date passed in. Got From: ${dateFrom}, To: ${dateTo}`);
    return -999;
  }
}

export function formatUtcDateStringToLocalTime(utcDateString:string):string|null{
  if(!utcDateString)
    return null;  
  return new Date((utcDateString.endsWith("Z")?utcDateString:utcDateString+"Z")).toLocaleString();

}

export function formatDate(date, fmt?, tz = "local") {
  tz = tz ?? "local";
  const adjustTZ = tz === "local" ? utcToZ : tz === "UTC" ? zToUTC : null;
  try {
    if (!date) {
      return 'N/A';
    }

    if (date && date !== 'N/A') {
      return format(adjustTZ(date, null), fmt ?? 'MM/dd/yyyy hh:mm:ss a');
    }

    return date;
  } catch (error) {
    console.error(error);
    toast.error(`Unexpected date passed in. Got ${date}`);
    return 'N/A';
  }
}

/**
 * 
 * @param {string} date 
 * @param {string} time 
 * @returns 
 */
export const updateToUTCDate = (date, time) => {
  date.setHours(time.getHours());
  date.setMinutes(time.getMinutes());
  date.setSeconds(time.getSeconds());
  date.setMilliseconds(time.getMilliseconds());  
  return zonedTimeToUtc(date);
}

/**
 * 
 * @param {string | Date} date 
 * @param {OptionsWithTZ | string} config 
 */
export const formatDateWithConfig = (date,config) => {
  try {
    return format(utcToZ(date, null),config);
  }catch(err) {
    console.error(err);
    toast.error(`Unexpected date passed in. Got ${date}`);
  }
}

// export function filterDate(data, dateFrom, dateTo, filterOption) {
//   let filteredData = [...data];
//   let from = utcToZ(dateFrom);
//   let to = utcToZ(dateTo);

//   switch (filterOption) {
//     case 0:
//       if (from && to) {
//         filteredData = filteredData.filter(item => {
//           if (!item.createDate && item.createdDate !== 'N/A') {
//             const date = utcToZ(item.createdDate);
//             return date >= startOfDay(dateFrom) && date <= endOfDay(dateTo);
//           }
//         });
//       }
//       break;
//     case 1:
//       this.setState({ to: 0 });
//       if (from) {
//         filteredData = filteredData.filter(item => {
//           if (!item.createDate && item.createdDate !== 'N/A') {
//             const date = utcToZ(item.createdDate);
//             return date > endOfDay(dateFrom);
//           }
//         });
//       }
//       break;
//     case 2:
//       this.setState({ to: 0 });
//       if (from) {
//         filteredData = filteredData.filter(item => {
//           if (!item.createDate && item.createdDate !== 'N/A') {
//             const date = utcToZ(item.createdDate);
//             return date < startOfDay(dateFrom);
//           }
//         });
//       }
//       break;
//     default:
//   }

//   return filteredData;
// }

export function sortDate(a, b, desc?) {
  if (!a && !b) {
    return 0;
  }

  if (a === 'N/A' && b === 'N/A') {
    return 0;
  }

  if (!a) {
    return desc ? -1 : 1;
  }

  if (a === 'N/A') {
    return desc ? -1 : 1;
  }

  if (!b) {
    return desc ? 1 : -1;
  }

  if (b === 'N/A') {
    return desc ? 1 : -1;
  }

  const x = getDate(a);
  const y = getDate(b);
  const diff = x?.getTime() - y?.getTime();
  return diff;
}
