export interface SalesLeadState {
  loading: boolean;
  loadingUpdateWeddingDetails: boolean;
  bookedConfirmUserList?: null | string[];
  weddingDetailsGroupSize: GroupSize[];
  salesSourceList?: SaleSource[];
  weddingDetails?: null | WeddingDetails;
  userActivity?: null | string;
  salesLeadDetails: null | SalesLeadDetails;
  salesLeadAccountDetails: null | any;
  authUsers: any[];
  leadInfoCRM?: null | any;
  impersonationLink?: null | string;
  status: {
    list: SalesStatus[];
    loading?: boolean;
    error?: null | string;
  };
  isUpdatingAssignedAgent: boolean;
  closedReasons: {
    list: {
      salesLeadClosedReasonID: null;
      reason: string;
    }[];
    loading?: boolean;
    error?: null | string;
  };
  notes: Note[];
}

export interface Note {
  id: string;
  note: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  type: string;
  module: string;
  DestifyUserID: string;
}

/*Todo: maybe in the future this is fetched dynamically?*/
export enum SALES_LEAD_UPSELL_CATEGORIES {
  RecommendedResorts = 1,
}
export interface SalesLeadUpsells {
  upsellCategory: SALES_LEAD_UPSELL_CATEGORIES;
  upsellRecordItemValue: any;
  upsellRecordItemId?: number | null;
  /**
   * this is the value of the Id column in SalesJourney -> WeddingDetails table
   */
  salesLeadWeddingDetailsRecordId: number;
  salesLeadUserGuid?: string | null;
}

export interface IUpdateAccountWeddingDetails {
  Id: number;
  LuxuryLevelIds: number[];
  TravelInformationId: number;
  AdminUserId?: string;
  AnticipatedWeddingDate: Date;
  EstimatedGuest: number;
  SpouseNameOne: string;
  SpouseNameTwo: string;
  WeddingSpouseTwoRoleId: number;
  LGBTQWedding: boolean;
  EstimatedBudget: number;
}

export interface SalesLeadStatusUpdateParams {
  salesLeadID: string;
  statusId: number;
  salesLeadClosedReasonId: number | null;
  reachOutDate: Date | null;
}

export enum SALES_LEAD_STATUS {
  'New',
  'Waiting on Resort',
  'Call Scheduled',
  'Exploring',
  'Requested Booking',
  'Duplicate',
  'Group Created',
  'Not Ready',
  'Closed',
}

export interface SalesStatus {
  disabled: boolean;
  group: null;
  selected: boolean;
  text: SALES_LEAD_STATUS;
  value: number;
}

export interface SaleSource {
  objectKey: string;
  objectValue: string;
}

export interface Filters {
  dateCreateOption: number;
  dateCreateFrom?: null | Date;
  dateCreateTo?: null | Date;
  dateLastActiveOption: number;
  dateLastActiveFrom?: null | Date;
  dateLastActiveTo?: null | Date;
  isBookedFilter: null | string;
  groupSize: string;
  lastVisitPage: string;
  groupSizeOption: number;
  detailsPage: DetailsPage;
}

export interface DetailsPage {
  id: null | number;
  crmLeadId?: null | string;
  agentEmail: null | string;
  name: null | string;
  email: null | string;
  salesSource: null | string;
  phone: null | string;
  status: null | string | boolean;
  userStatus?: boolean;
}

type LuxuryLevel = {
  luxuryLevelId: number;
  luxuryLevel: string;
};

export interface WeddingDetails {
  weddingId: number;
  anticipatedWeddingDate: string;
  weddingRoleId: number;
  weddingRole: string;
  weddingSpouseTwoRoleId: number;
  weddingSpouseTwoRole: string;
  spouseNameOne: string;
  spouseNameTwo: string;
  ceremonyTypeId: number;
  ceremony: string;
  estimatedGuest: number;
  estimatedBudgetId: number;
  estimatedBudget: string;
  travelInformationId: number;
  travelInformation: string;
  isGuestStayingResortProperty: boolean;
  isGuestStayingMinimumThreeNights: boolean;
  lgbtqWedding: boolean;
  userId: string;
  groupId: string;
  crmLeadId: string;
  weddingCoordinatorEmail: string;
  weddingLuxuryLevelList: LuxuryLevel[];
  confirmWeddingDate: string | Date;
  confirmWeddingTime: string | Date;
  confirmNumber: string;
  confirmedWeddingDatetime: null | string;
  weddingConfirmationNumber: null | string;
  assignedWeddingCoordinatorName: null | string;
  assignedWeddingCoordinatorEmail: null | string;
  weddingPlanningSiteLink: null | string;
  copyOfConfirmation: null | string;
  copyOfConfirmationUrl: null | string;
  weddingSiteUserName: null | string;
  weddingSitePassword: null | string;
  awaitingResortConfirmation: boolean;
}

export interface SalesLead {
  id: string;
  crmLeadId: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  userRole: string;
  isActive: true;
  isEmailConfirmed: boolean;
  createdDate: string;
  lastActivityTime: string;
  lastVisitedPage: string;
  userCreatedDate: string;
  userRooms: Array<any>;
  agentEmail: null | string;
  salesSource: string;
  status: string;
}

export interface GroupSize {
  groupSize: number;
  userId: string;
}

export interface SalesLeadDetails {
  salesLeadId: null | string;
  city: null | string;
  destination: null | string;
  resort: null | string;
  venue: null | string;
  weddingPackage: null | string;
  pageLastVisited: null | string;
  dateLastVisited: null | string;
}

export interface SalesLeadAccountDetails {
  anticipatedWeddingDate: null | string;
  spouseNameOne: null | string;
  spouseNameTwo: null | string;
  weddingSpouseTwoRole: null | string;
  lgbtqWedding: null | string;
  estimatedBudget: null | string;
  confirmedWeddingDatetime: null | string;
  weddingConfirmationNumber: null | string;
  assignedWeddingCoordinatorName: null | string;
  assignedWeddingCoordinatorEmail: null | string;
  weddingPlanningSiteLink: null | string;
  copyOfConfirmation?: null | string;
  phone: null | string;
  email: null | string;
  name: null | string;
  estimatedGuest?: null | string;
}

export interface WeddingDateQueryObject {
  IsSortAsc: boolean;
  PageNumber: number;
  PageSize: number;
  sortBy: string;
  ResortID: number;
  venueID: number;
  leadTypeID: number;
  WeddingDateStatusID: number;
  Search: Array<any>;
  totalItems: number;
}
