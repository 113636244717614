/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';

import { toast } from 'react-toastify';

import FilteredMultiSelect from 'react-filtered-multiselect';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { OccupancyNumber as OccupancyNumberOpt } from '../../../../common/options/categoryoption';
import hotel from '../../../../api/hotelContent';

const BOOTSTRAP_CLASSES = {
  filter: 'form-control',
  select: 'form-control',
  button: 'btn btn btn-block btn-default',
  buttonActive: 'btn btn btn-block btn-primary',
};

class Details extends React.Component {
  componentDidMount() {
    const roomdetails = this.props.roomImage;
    if (roomdetails) {
      const roomFeatures = roomdetails.roomFeaturesList;
      const options = roomFeatures.filter((x) => x.selected === false);
      const selectedOptions = roomFeatures.filter((x) => x.selected === true);

      const arr = Object.keys(roomdetails.supplierRoomTypeIDPairs);

      const supplieroomtype = [];
      arr.forEach((x) => {
        const obj = {
          supplierId: x,
          supplierName: roomdetails.supplierRoomTypeIDPairs[x],
        };
        supplieroomtype.push(obj);
      });
      // console.log(roomdetails.occupancyNumber);
      // console.log(roomdetails.adultOccupancyNumber);
      // console.log(roomdetails.childOccupancyNumber);
      roomdetails.adultOccupancyNumber = roomdetails.adultOccupancyNumber === undefined
        || roomdetails.adultOccupancyNumber === null
        ? roomdetails.occupancyNumber || 0
        : roomdetails.adultOccupancyNumber;

      if (
        roomdetails.childOccupancyNumber === undefined
        || roomdetails.childOccupancyNumber === null
      ) {
        if (roomdetails.occupancyNumber && roomdetails.adultOccupancyNumber) {
          roomdetails.childOccupancyNumber = roomdetails.occupancyNumber; // updated to allow max. occupancy value on adult and child
        } else {
          roomdetails.childOccupancyNumber = 0;
        }
      }

      const newAdultOccupancyOpt = [];
      const newChildOccupancyOpt = [];
      if (roomdetails.occupancyNumber) {
        for (let i = 1; i <= roomdetails.occupancyNumber; i++) {
          newAdultOccupancyOpt.push({ value: i, text: i });
        }
        for (
          let j = 1;
          j <= roomdetails.occupancyNumber; // updated to allow max. occupancy value on adult and child
          j++
        ) {
          newChildOccupancyOpt.push({ value: j, text: j });
        }
      }

      this.setState({
        loading: false,
        hotelId: roomdetails.hotelID,
        id: roomdetails.id,
        descriptions: roomdetails.descriptions || '<p></p>',
        selectedOptions,
        roomName: roomdetails.roomTypeFriendlyName.trim(),
        options,
        inputList: supplieroomtype,
        enabled: roomdetails.enabled || false,
        isBestValue: roomdetails.isBestValue || false,
        isBestValueDescription: roomdetails.isBestValueDescription || '',
        occupancyNumber: roomdetails.occupancyNumber,
        adultOccupancyNumber: roomdetails.adultOccupancyNumber,
        adultOccupancyNumberOpt: newAdultOccupancyOpt,
        childOccupancyNumberOpt: newChildOccupancyOpt,
        childOccupancyNumber: roomdetails.childOccupancyNumber,
      });
    } else {
      // this.createRoomFeature();
      hotel.roomtype.features().then((response) => {
        this.setState({ options: response, descriptions: '<p></p>' });
      });
    }
    this.getSupplier();
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hotelId: this.props.hotelId,
      id: '',
      occupancyNumber: '',
      adultOccupancyNumber: '',
      adultOccupancyNumberOpt: [],
      childOccupancyNumber: '',
      childOccupancyNumberOpt: [],
      descriptions: '',
      selectedOptions: [],
      roomName: '',
      options: [],
      supplierlist: [],
      isBestValue: false,
      isBestValueDescription: '',
      inputList: [{ supplierId: '', supplierName: '' }],
      isSubmit: false,
      enabled: false,
    };
  }

  getSupplier = () => {
    hotel.hotelcontent.supplierlist().then((response) => {
      this.setState({ supplierlist: response });
    });
  };

  onChangeDescription = (e, editor) => {
    // had to add a space if you erase all characters as it was causing a null exception
    if (editor.getData() === '') {
      this.setState({ descriptions: ' ' });
    } else {
      this.setState({ descriptions: editor.getData() });
    }
  };

  handleSelectionChange = (selectedOptions) => {
    this.setState({ selectedOptions });
  };

  handleAlreadySelectedSelectionChange = (deselectedOptions) => {
    // const selectedOptions = this.state.selectedOptions.slice()
    // deselectedOptions.forEach((option) => {
    //   selectedOptions.splice(selectedOptions.indexOf(option), 1)
    // })
    this.setState((prevState) => ({
      selectedOptions: prevState.selectedOptions.filter(
        (item) => !deselectedOptions.includes(item)
      ),
    }));
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;

    this.setState((prevState) => {
      const list = [...prevState.inputList];
      list[index][name] = value;
      return { inputList: list };
    });
  };

  // handle click event of the Remove button
  handleRemove = (index) => {   
    this.setState((prevState) => {
      const list = [...prevState.inputList];
      list.splice(index, 1);
      return {
        inputList: list,
      } 
    });
  };

  // handle click event of the Add button
  handleAddClick = () => {
    this.setState((prevState) => ({
      inputList: [...prevState.inputList, { supplierId: '', supplierName: '' }],
    }));
  };

  handleChange = (event) => {
    const newAdultOccupancyOpt = [];
    const newChildOccupancyOpt = [];
    if (event.target.name === 'occupancyNumber') {
      if (event.target.value) {
        for (let i = 1; i <= event.target.value; i++) {
          newAdultOccupancyOpt.push({ value: i, text: i });
          newChildOccupancyOpt.push({ value: i, text: i });
        }
        this.setState({
          adultOccupancyNumberOpt: newAdultOccupancyOpt,
          childOccupancyNumber: 0,
          adultOccupancyNumber: 0,
          childOccupancyNumberOpt: newChildOccupancyOpt,
        });
      } else {
        this.setState({
          adultOccupancyNumberOpt: [],
          childOccupancyNumber: 0,
          adultOccupancyNumber: 0,
          childOccupancyNumberOpt: [],
        });
      }
    }
    if (event.target.name === 'adultOccupancyNumber') {
      for (
        let j = 1;
        j <= this.state.occupancyNumber; // updated to allow max. occupancy value on adult and child
        j++
      ) {
        newChildOccupancyOpt.push({ value: j, text: j });
      }
      this.setState({ childOccupancyNumberOpt: newChildOccupancyOpt });
      
      // this code is to prevent rule, adult + child <= max occupancy and we removing rule so, commenting this code
      // if (
      //   this.state.childOccupancyNumber
      //   && this.childOccupancyNumber
      //     > this.state.occupancyNumber - event.target.value
      // ) {
      //   this.setState((prevState) => ({
      //     childOccupancyNumber: prevState.occupancyNumber - event.target.value,
      //   }));
      // }
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckbox = (event) => {
    this.setState({ enabled: event.target.checked });
  };

  handleBestValueDescription = (event) => {
    this.setState({ isBestValueDescription: event.target.value });
  };

  handleBestValueCheckbox = (event) => {
    this.setState({ isBestValue: event.target.checked });
    if (!event.target.checked) this.setState({ isBestValueDescription: '' });
  };

  submit = () => {
    this.setState({ isSubmit: true });
    if (this.state.roomName === '') return;
    const selectedvalue = this.state.selectedOptions.map((a) => a.value);

    const d = {};
    this.state.inputList.forEach((item) => {
      d[item.supplierId] = item.supplierName;
    });

    const obj = {
      id: this.state.id,
      hotelID: this.state.hotelId,
      roomTypeFriendlyName: this.state.roomName,
      descriptions: this.state.descriptions,
      roomFeatures: selectedvalue,
      supplierRoomTypeIDPairs: d,
      enabled: this.state.enabled,
      isBestValue: this.state.isBestValue,
      isBestValueDescription: this.state.isBestValueDescription,
      // Nulls need to be respected in the database as business rules dont allow for 0
      occupancyNumber:
        this.state.occupancyNumber === 0 || this.state.occupancyNumber === '0'
          ? null
          : this.state.occupancyNumber,
      adultOccupancyNumber:
        this.state.adultOccupancyNumber === 0
        || this.state.adultOccupancyNumber === '0'
          ? null
          : this.state.adultOccupancyNumber,

      childOccupancyNumber:
        this.state.childOccupancyNumber === 0
        || this.state.childOccupancyNumber === '0'
          ? null
          : this.state.childOccupancyNumber,
    };

    const that = this;
    if (this.state.id === '') {
      obj.id = '00000000-0000-0000-0000-000000000000';

      hotel.roomtype.saveRoomType(obj).then(() => {
        toast.success('Room saved successfully.');
        this.setState({ isSubmit: false });
        const route = `/hotels/hotel-content-manage/${that.state.hotelId}`;
        that.props.history.push({
          pathname: route,
          state: {
            from: 'roomdetails',
          },
        });
      });
    } else {
      hotel.roomtype.updateRoomType(obj).then(() => {
        toast.success('Room saved successfully.');
        
        const route = `/hotels/hotel-content-manage/${that.state.hotelId}`;
        that.props.history.push({
          pathname: route,
          state: {
            from: 'roomdetails',
          },
        });
      });
    }
  };

  render() {
    const { selectedOptions, loading } = this.state;
    return (
      <>
        <Card className="main-card mb-3">
          <CardBody>
            <label htmlFor="roomName">Room Type Name</label>
            <input
              type="text"
              name="roomName"
              className={
                this.state.isSubmit && this.state.roomName === ''
                  ? 'is-invalid form-control'
                  : 'form-control'
              }
              value={ this.state.roomName }
              onChange={ this.handleChange }
            />
            {this.state.isSubmit && this.state.roomName === '' && (
              <div className="invalid-validation">Room name is required</div>
            )}
          </CardBody>

          <CardBody>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="occupancyNumber">
                  Maximum Allowed Occupancy
                </label>
                <select
                  name="occupancyNumber"
                  value={ this.state.occupancyNumber || '0' }
                  className="form-control"
                  onChange={ this.handleChange }
                >
                  <option value="0">--Select--</option>
                  {OccupancyNumberOpt.map((value, index) => (
                    <option key={ index } value={ value.value }>
                      {value.text}
                    </option>
                  ))}
                </select>
                {this.state.isSubmit && this.state.occupancyNumber === '' && (
                  <div className="invalid-validation">
                    Maximum Allowed Occupancy Number is required
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <label htmlFor="adultOccupancyNumber">
                  Maximum Adult Occupancy
                </label>
                <select
                  name="adultOccupancyNumber"
                  value={ this.state.adultOccupancyNumber }
                  className="form-control"
                  onChange={ this.handleChange }
                >
                  <option value="0">--Select--</option>
                  {this.state.adultOccupancyNumberOpt.map((value, index) => (
                    <option key={ index } value={ value.value }>
                      {value.text}
                    </option>
                  ))}
                </select>
                {this.state.isSubmit && this.state.occupancyNumber === '' && (
                  <div className="invalid-validation">
                    Maximum Allowed Adult Occupancy Number is required
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <label htmlFor="childOccupancyNumber">
                  Maximum Child Occupancy
                </label>
                <select
                  name="childOccupancyNumber"
                  value={ this.state.childOccupancyNumber }
                  className="form-control"
                  onChange={ this.handleChange }
                >
                  <option value="0">--Select--</option>
                  {this.state.childOccupancyNumberOpt.map((value, index) => (
                    <option key={ index } value={ value.value }>
                      {value.text}
                    </option>
                  ))}
                </select>
                {this.state.isSubmit && this.state.occupancyNumber === '' && (
                  <div className="invalid-validation">
                    Maximum Allowed Child Occupancy Number is required
                  </div>
                )}
              </div>
            </div>
          </CardBody>
          <CardBody>
            <CardTitle>Destify Favorite</CardTitle>
            <Row>
              <Col md="2">
                <div className="form-check">
                  <input
                    name="isBestValue"
                    checked={ this.state.isBestValue }
                    onChange={ (e) => this.handleBestValueCheckbox(e) }
                    id="isBestValue"
                    type="checkbox"
                    className="form-check-input"
                  />
                  <label htmlFor="isBestValue" className="form-check-label">
                    Enable
                  </label>
                </div>
              </Col>
              {this.state.isBestValue && (
                <Col md="10">
                  <label htmlFor="isBestValueDescription">
                    Destify Favorite Description
                  </label>
                  <input
                    type="text"
                    name="isBestValueDescription"
                    className={
                      this.state.isSubmit
                      && this.state.isBestValueDescription === ''
                        ? 'is-invalid form-control'
                        : 'form-control'
                    }
                    value={ this.state.isBestValueDescription }
                    onChange={ this.handleBestValueDescription }
                  />
                </Col>
              )}
            </Row>
          </CardBody>
          <CardBody>
            <CardTitle> Room Features </CardTitle>
            <Row>
              <Col md="6">
                <div className="mb-2 mr-2 badge badge-pill badge-primary">
                  Available
                </div>
                <FilteredMultiSelect
                  classNames={ BOOTSTRAP_CLASSES }
                  onChange={ this.handleSelectionChange }
                  options={ this.state.options }
                  selectedOptions={ selectedOptions }
                />
              </Col>

              <Col md="6">
                <div className="mb-2 mr-2 badge badge-pill badge-success">
                  Selected
                </div>
                <FilteredMultiSelect
                  classNames={ BOOTSTRAP_CLASSES }
                  onChange={ this.handleAlreadySelectedSelectionChange }
                  options={ selectedOptions }
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle> Room Description </CardTitle>
            <Row>
              <Col md="12">
                {this.state.descriptions && (
                  <CKEditor
                    editor={ ClassicEditor }
                    data={ this.state.descriptions }
                    onChange={ this.onChangeDescription }
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle> Supplier and Room Type Codes </CardTitle>
            <table>
              <tbody>
                {this.state.inputList.map((x, i) => (
                  <tr key={ i }>
                    <td>
                      <select
                        name="supplierId"
                        value={ x.supplierId }
                        className="form-control"
                        onChange={ (e) => this.handleInputChange(e, i) }
                      >
                        <option value="">--Select--</option>
                        {this.state.supplierlist
                          && this.state.supplierlist.map((value, index) => (
                            <option value={ value.value } key={ index }>
                              {value.text}
                            </option>
                          ))}
                      </select>
                      {/* <select name="supplierId" value={x.supplierId} className="form-control"  onChange={e => this.handleInputChange(e, i)}>
                                    <option value=''>--Select--</option>
                                        <option value='bol'>BOL</option>
                                        <option value='alg'>ALG</option>
                                        <option value='hsbi'>HBSI</option>
                                        
                                    </select> */}
                    </td>
                    <td>
                      <input
                        name="supplierName"
                        onChange={ (e) => this.handleInputChange(e, i) }
                        value={ x.supplierName }
                        className="form-control"
                        placeholder="Supplier Value"
                      />
                    </td>

                    <td>
                      {this.state.inputList.length !== 1 && (
                        <button
                          type="button"
                          className="mb-2 mr-2 btn-pill btn btn-danger"
                          onClick={ () => this.handleRemove(i) }
                        >
                          Remove
                        </button>
                      )}
                      {this.state.inputList.length - 1 === i && (
                        <button
                          type="button"
                          className="mb-2 mr-2 btn-pill btn btn-success"
                          onClick={ this.handleAddClick }
                        >
                          Add
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>Room Enable/Disable</CardTitle>
            <div className="form-check ml-4 mb-3">
              <input
                name="enabled"
                checked={ this.state.enabled }
                onChange={ (e) => this.handleCheckbox(e) }
                id="enabled"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="enabled" className="form-check-label">
                Enable
              </label>
            </div>
          </CardBody>
        </Card>

        <Button
          disabled={ !!loading }
          color="primary"
          className="mt-3"
          onClick={ this.submit }
        >
          {!loading ? <span>Save</span> : <span>Please wait...</span>}
        </Button>
      </>
    );
  }
}

Details.propTypes = {
  roomImage: PropTypes.shape({
    roomFeaturesList: PropTypes.arrayOf(PropTypes.shape({
      selected: PropTypes.bool,
    })),
    supplierRoomTypeIDPairs: PropTypes.shape(),
    adultOccupancyNumber: PropTypes.number,
    occupancyNumber: PropTypes.number,
    childOccupancyNumber: PropTypes.number,
    hotelID: PropTypes.string,
    id: PropTypes.string,
    descriptions: PropTypes.string,
    enabled: PropTypes.bool,
    roomTypeFriendlyName: PropTypes.string,
    isBestValue: PropTypes.bool,
    isBestValueDescription: PropTypes.string,
  }).isRequired,
  hotelId: PropTypes.string,
};

Details.defaultProps = {
  hotelId: null,
}

export default withRouter(Details);
