import React, { useEffect, useState } from 'react';
import {
  ListGroup,
  ListGroupItem,
  Button,
  UncontrolledTooltip,
} from 'reactstrap';

import { UsersActions } from '../../slice';
import { selectUserGroups } from '../../slice/selectors';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import Loader from 'react-loaders';
import Modal from '../../../../common/Modals';
import { IUserGroup } from '../../slice/types';

const UserGroups = ({ id }) => {
  const dispatch = useAppDispatch();
  const { list, loading } = useAppSelector(selectUserGroups);
  const [selectedGroup, setSelectedGroup] = useState<IUserGroup>(null);

  useEffect(() => {
    dispatch(UsersActions.loadUserGroups({ id }));
  }, [dispatch, id]);

  const toggleGroupLeaderConfirmationModal = (group?: IUserGroup) => {
    setSelectedGroup(group);
  };

  const replaceGroupLeader = () => {
    dispatch(
      UsersActions.makeGroupLeader({
        groupId: selectedGroup.id,
        userId: id,
      }),
    );
    toggleGroupLeaderConfirmationModal();
  };

  const isEmptyGroups = !list.length && !loading;
  const isRefreshingGroups = !!list.length && loading;

  return (
    <>
      <Modal
        title="Replace Group Leader"
        isOpen={!!selectedGroup}
        buttonLabels={{
          save: 'REPLACE GROUP LEADER',
        }}
        onSave={replaceGroupLeader}
        onClose={() => toggleGroupLeaderConfirmationModal()}
      >
        <p>
          This will replace the current group leader (
          {selectedGroup?.leaderEmail})
        </p>
        Are you sure you want to proceed?
      </Modal>
      <ListGroup>
        {isEmptyGroups && (
          <ListGroupItem>
            <p>No weddings associated to this user</p>
          </ListGroupItem>
        )}

        {loading && (
          <ListGroupItem>
            {isRefreshingGroups && <p>Updating weddings groups...</p>}
            <Loader active type="ball-beat" />
          </ListGroupItem>
        )}

        {!loading &&
          list.map((group, index) => {
            return (
              <ListGroupItem key={index}>
                <div className="widget-content p-0">
                  <div className="widget-content-wrapper">
                    <div className="widget-content-left">
                      <div className="widget-heading">{group.name}</div>
                      <div className="widget-subheading">
                        <span className="btn">
                          Group Leader: {group.leaderEmail}
                        </span>
                      </div>
                    </div>
                    <div className="widget-content-right">
                      <Button
                        color="success"
                        onClick={() =>
                          toggleGroupLeaderConfirmationModal(group)
                        }
                        disabled={loading || group.isGroupLeader}
                        id={`make-group-leader-btn-${group.id}`}
                      >
                        Make Group leader
                      </Button>

                      {group.isGroupLeader && (
                        <UncontrolledTooltip
                          target={`make-group-leader-btn-${group.id}`}
                        >
                          This user is already leader of the group.
                        </UncontrolledTooltip>
                      )}
                    </div>
                  </div>
                </div>
              </ListGroupItem>
            );
          })}
      </ListGroup>
    </>
  );
};

export default UserGroups;
