import React, { } from 'react';
import { Route } from 'react-router-dom';
import HbsiSearch from './search';
import TransferReservations from "./Reservations"
// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';
import ManageTransferReservation from './Manage';

// Theme Options

// import ThemeOptions from "../../Layout/ThemeOptions";

function Hbsi() {
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Route path="/hbsi/search" component={ HbsiSearch } />
            <Route exact path="/hbsi/transfers" component={ TransferReservations } />
            <Route path="/hbsi/transfers/:id" component={ ManageTransferReservation } />
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  )
}

export default Hbsi;
