import { destifyAdminApiRequests } from './common';

const transferReservation = {
    getTransferDetails: id =>
    destifyAdminApiRequests.get(`Transfers/${id}`),
};

const updateTransferReservation = {
    updateTransferDetails: (id, body) =>
    destifyAdminApiRequests.put(`Transfers/${id}`, body),
};


const transfersApi = {
    transferReservation,
    updateTransferReservation
};

export default transfersApi;