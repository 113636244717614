import React, { useEffect } from 'react';
import { selectLeadInfoCRM, selectAuthUsers } from '../../slice/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useSalesLeadSlice } from '../../slice';
import ViewMoreWrapper from "common/ViewMoreWrapper"
import { Link } from "react-router-dom"

const _DuplicateList = ({ crmLeadDetails:leadInfo }) => {
  
  

  

  return (
    <>
      <label className="list-details">Possible Duplicates: </label>
      <ViewMoreWrapper limit={3}>
        {leadInfo && leadInfo.duplicateLeads.length
          ? leadInfo.duplicateLeads
              .filter(item => item.email)
              .map(item => (
                <div
                  key={item.email}
                  className="anchor-link"
                >
                    <Link to={`/user/guest-list-new/${item.userId}`}>
                    {item.email}
                    </Link>
                  <br />
                </div>
              ))
          : 'N/A'}
      </ViewMoreWrapper>
    </>
  );
};

export default _DuplicateList;
