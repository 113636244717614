import { BaseWeddingGuestDto } from 'api/adminApi/model';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { memo } from 'react';
import * as Yup from 'yup';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  FormFeedback,
  InputGroup,
} from 'reactstrap';
import { WeddingGuestsApi } from 'api/adminApi';
import { toast } from 'react-toastify';
import axios from 'axios';
import { IoIosAddCircle, IoIosSave } from 'react-icons/io';

const AddNewSchema = Yup.object().shape({
  name: Yup.string()
    .max(500, 'Guest Name must be less than 500 characters')
    .required('Guest Name is required'),
  email: Yup.string()
    .email('Invalid Email')
    .max(100, 'Guest Email must be less than 100 characters')
    .required('Guest Email is required'),
  phone: Yup.string()
    .optional()
    .max(20, 'Guest Phone must be less than 20 characters'),
  numberOfAttendees: Yup.number()
    .min(0, 'Attendee Count must be at least 0')
    .max(10, 'Attendee Count cannot be greater than 10')
    .required('Attendee Count is required'),
});

type AddNewViewModel = Omit<BaseWeddingGuestDto, 'rsvpStatus'> & {
  rsvpStatus: string;
};

const AddNew = ({
  isOpen,
  onClose,
  weddingGroupId,
  onRecordCreated,
}: {
  isOpen: boolean;
  onClose: () => void;
  weddingGroupId: string;
  onRecordCreated?: (WeddingGuestDetailsDto) => void;
}) => {
  return (
    <Modal isOpen={isOpen}>
      <Formik
        initialValues={
          {
            name: '',
            email: '',
            numberOfAttendees: 1,
            phone: '',
            roomId: '',
            rsvpStatus: '',
            weddingGroupId,
          } as AddNewViewModel
        }
        validationSchema={AddNewSchema}
        onSubmit={async (
          {
            name,
            email,
            numberOfAttendees,
            phone,
            roomId,
            weddingGroupId,
            rsvpStatus,
          }: AddNewViewModel,
          { setSubmitting, resetForm }: FormikHelpers<AddNewViewModel>,
        ) => {
          try {
            const payload: BaseWeddingGuestDto = {
              name,
              email,
              numberOfAttendees,
              phone,
              roomId,
              weddingGroupId,
              rsvpStatus: rsvpStatus === '' ? null : rsvpStatus === 'true',
            };

            const {
              data: { data },
            } = await new WeddingGuestsApi().apiWeddingGuestsPost(payload);
            resetForm();
            onClose();
            toast.success(
              <>
                Guest <b>{name}</b> added successfully!
              </>,
            );
            onRecordCreated && onRecordCreated(data);
          } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
              try {
                const {
                  response: {
                    data: { errors },
                  },
                } = error;

                const apiErrors = [];
                if (typeof errors === 'object') {
                  for (const err in errors) {
                    apiErrors.push({ [err]: errors[err].join('\n') });
                  }
                }

                toast.error(
                  <>
                    An API error occurred trying to save this guest.
                    <br />
                    Error Details:
                    <ul>
                      {apiErrors.map(apiError => {
                        return (
                          <li>
                            <b>{Object.keys(apiError)[0]}</b>:{' '}
                            {apiError[Object.keys(apiError)[0]]}
                          </li>
                        );
                      })}
                    </ul>
                  </>,
                );
                return;
              } catch {}
            }
            toast.error(
              <>
                An unknown error occurred trying to save this guest.
                <br />
                Please try again or contact the dev team.
              </>,
            );
          }
        }}
      >
        {({ isSubmitting, errors, touched, handleReset }) => (
          <Form>
            <ModalHeader>Add New Guest</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>
                  Guest Name{' '}
                  <small>
                    <b>required</b>
                  </small>
                </Label>
                <Field name="name">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <>
                      <input
                        maxLength={500}
                        type="text"
                        className={`form-control-sm form-control ${
                          meta.touched && meta.error ? 'is-invalid' : ''
                        }`}
                        aria-invalid={meta.touched && meta.error}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <FormFeedback>{meta.error}</FormFeedback>
                      )}
                    </>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Label>
                  Guest Email{' '}
                  <small>
                    <b>required</b>
                  </small>
                </Label>
                <Field name="email">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <>
                      <input
                        maxLength={100}
                        type="email"
                        className={`form-control-sm form-control ${
                          meta.touched && meta.error ? 'is-invalid' : ''
                        }`}
                        aria-invalid={meta.touched && meta.error}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <FormFeedback>{meta.error}</FormFeedback>
                      )}
                    </>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Label>Guest Phone</Label>
                <Field name="phone">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <>
                      <input
                        maxLength={20}
                        type="tel"
                        className={`form-control-sm form-control ${
                          meta.touched && meta.error ? 'is-invalid' : ''
                        }`}
                        aria-invalid={meta.touched && meta.error}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <FormFeedback>{meta.error}</FormFeedback>
                      )}
                    </>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Label>
                  Attendee Count{' '}
                  <small>
                    <b>required</b>
                  </small>
                </Label>
                <Field name="numberOfAttendees">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <>
                      <input
                        type="number"
                        className={`form-control-sm form-control ${
                          meta.touched && meta.error ? 'is-invalid' : ''
                        }`}
                        aria-invalid={meta.touched && meta.error}
                        {...field}
                      />
                      {meta.touched && meta.error && (
                        <FormFeedback>{meta.error}</FormFeedback>
                      )}
                    </>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <Label className="form-label">RSVP Status</Label>

                <Field
                  as="select"
                  name="rsvpStatus"
                  className={'form-control-sm form-control'}
                >
                  <option value={null}>No Response</option>
                  <option value={'true'}>RSVP Yes</option>
                  <option value={'false'}>RSVP No</option>
                </Field>
              </FormGroup>
              <FormGroup>
                <Label>CRM Room Id</Label>
                <Field name="roomId">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }) => (
                    <>
                      <InputGroup>
                        <input
                          type="text"
                          className={`form-control-sm form-control ${
                            meta.touched && meta.error ? 'is-invalid' : ''
                          }`}
                          aria-invalid={meta.touched && meta.error}
                          {...field}
                        />
                        {field.value && (
                          <a
                            target="_blank"
                            style={{
                              borderBottomLeftRadius: 0,
                              borderTopLeftRadius: 0,
                              marginLeft: '-1px',
                            }}
                            className="btn btn-info btn-sm"
                            href={process.env.REACT_APP_CRMLINK + field.value}
                            rel="noopener noreferrer"
                          >
                            Verify
                          </a>
                        )}
                      </InputGroup>
                    </>
                  )}
                </Field>
              </FormGroup>
            </ModalBody>
            <ModalFooter
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                disabled={isSubmitting}
                color="primary"
                onClick={() => {
                  handleReset();
                  onClose();
                }}
              >
                Close
              </Button>
              <div>
                <Button color="success" type="submit" disabled={isSubmitting}>
                  <IoIosAddCircle /> Submit
                </Button>{' '}
                <Button color="danger" type="reset" disabled={isSubmitting}>
                  Reset
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default memo(AddNew);
