import React from 'react';
import '../../assets/app.scss';
import { useField } from 'formik';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const TextField: React.FC<IProps> = ({ name, label = "", ...props }) => {
  const [field, meta] = useField(name);

  return (
    <>
      {
        label && <label htmlFor={label}>{label}</label>
      }
      <input
        className={
          meta.touched && meta.error
            ? 'is-invalid form-control'
            : 'form-control'
        }
        id={label}
        {...field}
        {...props}
        value={field.value || ""}
      />

      {meta.touched && meta.error ? (
        <div className="invalid-validation">{meta.error}</div>
      ) : null}
    </>
  );
}
