import { utcToZonedTime } from 'common/utils/dateUtils';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { weddingDateSaga } from './saga';
import {
  IWeddingDate,
  IConfirmDetails,
  IRequestDetails,
  IWeddingDateStatus,
  IWeddingDetailIdentifier,
  ICeremonyTime,
  IWeddingRequestHistory,
  IResort,
  IVenue,
  IResortPackage,
  WeddingDateQueryObject,
  WeddingDetailListItem,
} from './types';

export const initialState: IWeddingDate = {
  detailsPageStatus: {
    id: 0,
    status: false,
    userId: null,
  },
  weddingList: [] as WeddingDetailListItem[],
  weddingDateQueryObject: {
    IsSortAsc: true,
    PageNumber: 1,
    PageSize: 10,
    sortBy: '',
    ResortID: 0,
    venueID: 0,
    leadTypeID: 0,
    WeddingDateStatusID: 0,
    Search: [],
    totalItems: 0,
  } as WeddingDateQueryObject,
  loading: true,
  confirmDetails: {} as IConfirmDetails,
  requestDetails: {} as IRequestDetails,
  ceremonyTimeList: [] as ICeremonyTime[],
  updateConfirmDetailsLoading: false,
  updateRequestDetailsLoading: false,
  weddingRequestHistory: [] as IWeddingRequestHistory[],
  resort: {
    items: [] as IResort[],
    loading: false,
  },
  venue: {
    items: [] as IVenue[],
    loading: false,
  },
  packages: {
    items: [] as IResortPackage[],
    loading: false,
  },
  venues: [] as IVenue[],
  weddingPackages: [] as IResortPackage[],
  weddingDateStatuses: [] as IWeddingDateStatus[],
};

const slice = createSlice({
  name: 'weddingDate',
  initialState,
  reducers: {
    setDetailsPageStatus(
      state,
      action: PayloadAction<IWeddingDetailIdentifier>,
    ) {
      state.detailsPageStatus = {
        id: action.payload.id,
        status: action.payload.status,
        userId: action.payload.userId,
      };
    },
    getCeremonyTimeList() {},
    getWeddingRequestHistoryList(_state, _action: PayloadAction<number>) {},
    resortListing(state) {
      state.resort = {
        items: [],
        loading: true,
      };
    },
    getResortVenueById(state, _action: PayloadAction<number>) {
      state.venue = {
        ...state.venue,
        loading: true,
      };
    },
    getResortPackageById(state, _action: PayloadAction<number>) {
      state.packages = {
        ...state.packages,
        loading: true,
      };
    },
    loadWeddingDateStatusList() {},
    getUserDetails(_state, _action: PayloadAction<string>) {},
    updateweddingdetailsbyuser(state, action: PayloadAction<any>) {},
    updateweddingstatus(
      state,
      action: PayloadAction<{
        // SalesLeadID: number,
        weddingDateStatus: number;
        RejectSalesLeadStatusDTO?: any[];
        RejectSalesLead?: any[];
      }>,
    ) {},
    getWeddingDetailsById(_state, _action: PayloadAction<number>) {},
    handleSaveConfirmWeddingDetails() {},
    holdExpiredEmail(_state, _action: PayloadAction<number>) {},
    getWeddingList() {},
    // for the table display?
    setWeddingList(state, action: PayloadAction<WeddingDetailListItem[]>) {
      state.weddingList = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setWeddingDetails() {},
    setCeremonyTimeList(state, action: PayloadAction<ICeremonyTime[]>) {
      state.ceremonyTimeList = action.payload;
    },
    setRequestDetails(state, action: PayloadAction<IRequestDetails>) {
      const data = action.payload;
      state.requestDetails = {
        ...data,
        chosenHotel: data.resortID,
        resortVenueId: data.venueId,
        venue: data.venue,
        weddingPackageId: data.weddingPackageId,
        weddingEventDate: utcToZonedTime(data.weedingDate),
        ceremonyTime: data.preferredCeremonyTime?.toUpperCase(),
        altWeddingDate: utcToZonedTime(data.alternativeWeddingDate),
        altWeddingTime: data.alternativeWeddingTime?.toUpperCase(),
        weddingDateStatus: data.weddingDateStatus,
      };
    },
    setConfirmDetails(state, action: PayloadAction<IConfirmDetails>) {
      const data = action.payload;
      state.confirmDetails = {
        ...data,
        confirmWeddingDate: data.confirmedWeddingDatetime
          ? utcToZonedTime(data.confirmedWeddingDatetime)
          : '',
        confirmWeddingTime: data.confirmedWeddingDatetime
          ? utcToZonedTime(data.confirmedWeddingDatetime)
          : '',
        confirmNumber: data.weddingConfirmationNumber
          ? data.weddingConfirmationNumber
          : '',
        assignedWeddingCoordinatorName: data.assignedWeddingCoordinatorName
          ? data.assignedWeddingCoordinatorName
          : '',
        weddingCoordinatorEmail: data.assignedWeddingCoordinatorEmail
          ? data.assignedWeddingCoordinatorEmail
          : '',
        weddingPlanningSiteLink: data.weddingPlanningSiteLink
          ? data.weddingPlanningSiteLink
          : '',
        copyOfConfirmation: data.copyOfConfirmation
          ? data.copyOfConfirmation
          : null,
        copyOfConfirmationUrl: data.copyOfConfirmationUrl
          ? data.copyOfConfirmationUrl
          : null,
        weddingSiteUserName: data.weddingSiteUserName
          ? data.weddingSiteUserName
          : '',
        weddingSitePassword: data.weddingSitePassword
          ? data.weddingSitePassword
          : '',
        awaitingResortConfirmation: data.awaitingResortConfirmation
          ? data.awaitingResortConfirmation
          : false,
        anticipatedWeddingDate: data.anticipatedWeddingDate,

        /**
       * this is the value of the Id column in SalesJourney -> WeddingDetails table
       */
        weddingId:data.weddingId,
        crmLeadId: data.crmLeadId,
        spouseNameOne: data.spouseNameOne,
        spouseNameTwo: data.spouseNameTwo,
        weddingSpouseTwoRole: data.weddingSpouseTwoRole,
        lgbtqWedding: data.lgbtqWedding,
        estimatedBudget: data.estimatedBudget,
        estimatedGuest: data.estimatedGuest,
      };
    },
    setWeddingDateQueryObject(
      state,
      action: PayloadAction<WeddingDateQueryObject>,
    ) {
      state.weddingDateQueryObject = action.payload;
    },
    setWeddingRequestHistory(
      state,
      action: PayloadAction<IWeddingRequestHistory[]>,
    ) {
      state.weddingRequestHistory = action.payload;
    },
    setResortListing(state, action: PayloadAction<IResort[]>) {
      state.resort = {
        items: action.payload,
        loading: false,
      };
    },
    setWeddingDateStatusList(
      state,
      action: PayloadAction<IWeddingDateStatus[]>,
    ) {
      state.weddingDateStatuses = action.payload;
    },
    setVenues(state, action: PayloadAction<IVenue[]>) {
      state.venue = {
        items: action.payload,
        loading: false,
      };
    },
    setResortPackages(state, action: PayloadAction<IResortPackage[]>) {
      state.packages = {
        items: action.payload,
        loading: false,
      };
    },
    updateWeddingDetails() {},
    updateConfirmWeddingDetailsLoading(state, action: PayloadAction<boolean>) {
      state.updateConfirmDetailsLoading = action.payload;
    },
    updateRequestDetailsLoading(state, action: PayloadAction<boolean>) {
      state.updateRequestDetailsLoading = action.payload;
    },
    setHoldExpiredEmail() {},
  },
});

export const { actions: WeddingDateActions } = slice;

export const useWeddingDateSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: weddingDateSaga });
  return { actions: slice.actions };
};
