import React from 'react';
import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';

import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import hotel from '../../../../api/hotelContent';
import { TextField } from '../../../../common/Form/TextInput';

class Integration extends React.Component {
  // state = Integration.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  validationSchema = Yup.object({
    // algvaxId: Yup.string().required('The algvaxId is required.'),
    // bedsonlineId: Yup.string().required('The bedsonlineId from is required.'),
    // hbsiId: Yup.string().required('The hbsiId is required.'),
    crmHotelID: Yup.string().matches(
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/,
      'Please enter a valid unique identifier value from a CRM hotel. This is a value from a CRM URL for this hotel.'
    ),
  });

  constructor(props) {
    super(props);

    this.state = {
      initialValues: {
        vaxid: '',
        bedsOnlineID: '',
        hbSiID: '',
        crmHotelID: '',
        allowV2StaticPricing: ''
      },
    };
  }

  async saveIntegration(value) {
    await hotel.integration.updateHotelIntegration(value);
  }

  componentDidMount() {
    hotel.integration
      .hotelIntegrationById(this.props.match.params.id)
      .then((res) => {
        res.crmHotelID = res.crmHotelID === null ? '' : res.crmHotelID;
        res.vaxid = res.vaxid === null ? '' : res.vaxid;
        res.bedsOnlineID = res.bedsOnlineID === null ? '' : res.bedsOnlineID;
        res.hbSiID = res.hbSiID === null ? '' : res.hbSiID;
        res.allowV2StaticPricing = res.allowV2StaticPricing === null ? '' : res.allowV2StaticPricing
        this.setState({ initialValues: res });
      });
  }

  render() {
    const { initialValues } = this.state;

    return (
      <Formik
        initialValues={ initialValues }
        validationSchema={ this.validationSchema }
        enableReinitialize
        onSubmit={ async (values) => {
          await this.saveIntegration(values);
          toast.success('Integration details save successfully.');
          // return new Promise(res=>setTimeout(res,2500));
        } }
      >
        {({ isSubmitting }) => (
          <Card>
            <CardBody>
              <Form autoComplete="off">
                <Row>
                  <Col md="3"> </Col>
                  <Col md="6">
                    <Card>
                      <CardHeader>Integration</CardHeader>

                      <CardBody>
                        <FormGroup>
                          <TextField label="ALG/VAX IDs" name="vaxid" />
                        </FormGroup>
                        <FormGroup>
                          <TextField
                            label="BedsOnline ID"
                            name="bedsOnlineID"
                          />
                        </FormGroup>
                        <FormGroup>
                          <TextField label="HBSi ID" name="hbSiID" />
                        </FormGroup>
                        <FormGroup>
                          <TextField label="CRMHotel ID" name="crmHotelID" />
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col md={ 12 }>
                              <Field
                                type="checkbox"
                                name="allowV2StaticPricing"
                              />
                              &nbsp;&nbsp;
                              <label htmlFor="allowV2StaticPricing">
                                Use v2 static pricing ( scrapper )
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>
                      </CardBody>
                      <Button
                        disabled={ isSubmitting }
                        type="submit"
                        color="primary"
                        className="mt-1"
                      >
                        {isSubmitting ? 'Processing....' : 'Save'}
                      </Button>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        )}
      </Formik>
    );
  }
}
export default withRouter(Integration);
