import React from 'react';
import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
} from 'reactstrap';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TransitionGroup } from 'react-transition-group';
import { TextField } from '../../../common/Form/TextInput';
import { TextArea } from '../../../common/Form/TextArea';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import weddingPackage from '../../../api/weddingPackage';

class Create extends React.Component {
  validationSchema = Yup.object({
    destinationID: Yup.string().required('The destination is required'),
    subDestinationID: Yup.string().required('The sub destination is required'),
    resortID: Yup.string().required('Resort is required'),
    venueName: Yup.string().required('The venue name is required.'),
    capacity: Yup.string().required('Capacity is required.'),
  });

  constructor(props) {
    super(props);

    this.state = {
      initialValues: {
        venueName: '',
        destinationID: '',
        subDestinationID: '',
        resortID: '',
        capacity: 1,
        allowReligiousCeremony: false,
      },
      destination: [],
      subDestination: [],
      resort: [],
    };
  }

  async loadDestination() {
    const destination = await weddingPackage.destination.getDestination();
    this.setState({ destination });
  }

  async loadSubDestination(id) {
    const subDestination = await weddingPackage.subDestination.getsubdestinationByDestinationId(id);
    this.setState({ subDestination });
  }

  // async resort(id){
  //    var resort = await weddingPackage.resort.getResortBySubDestinationId(id);
  //    this.setState({resort});
  // }

  async getResort() {
    const resort = await weddingPackage.resort.getResortList();
    this.setState({ resort });
  }

  onchangeResort(id, setFieldValue) {
    if (id !== '') {
      const resort = this.state.resort.find((x) => x.resortID === parseInt(id, 10));
      this.loadSubDestination(resort.destinationID);
      setFieldValue('destinationID', resort.destinationID);
      setFieldValue('subDestinationID', resort.subDestinationID);
    }
  }

  componentDidMount() {
    this.loadDestination();
    this.getResort();
    const { id } = this.props.match.params;
    if (id && id > 0) {
      weddingPackage.venue.getVenueById(id).then((res) => {
        console.log(res);
        this.loadSubDestination(res.destinationID);
        this.resort(res.subDestinationID);
        this.setState({ initialValues: res });
      });
    }
    // hotel.integration.hotelIntegrationById(this.props.match.params.id).then(res=>{
    //     res.crmHotelID=res.crmHotelID==null?'':res.crmHotelID;
    //     res.vaxid=res.vaxid==null?'':res.vaxid;
    //     res.bedsOnlineID=res.bedsOnlineID==null?'':res.bedsOnlineID;
    //     res.hbSiID=res.hbSiID==null?'':res.hbSiID;
    //     this.setState({initialValues:res});

    // })
  }

  render() {
    const { initialValues } = this.state;

    return (
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }
      >
        <Container fluid>
          <div>
            <PageTitle
              heading="Venue Management"
              icon="lnr-apartment icon-gradient bg-tempting-azure"
            />
          </div>
        </Container>

        <Formik
          initialValues={ initialValues }
          validationSchema={ this.validationSchema }
          enableReinitialize
          onSubmit={ async (values) => {
            await weddingPackage.venue.saveVenue(values);
            this.props.history.push('/wedding/venue');
            toast.success('Venue saved successfully.');
          } }
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Card>
              <CardBody>
                <Button
                  className="mb-2 mr-2 btn-icon float-right"
                  color="primary"
                  onClick={ () => {
                    this.props.history.push('/wedding/venue');
                  } }
                >
                  Back To List
                </Button>

                <Form autoComplete="off">
                  <Row>
                    <Col md="3"> </Col>
                    <Col md="6">
                      <Card>
                        <CardHeader>Create Venue</CardHeader>

                        <CardBody>
                          <FormGroup>
                            <TextField label="Venue Name" name="venueName" />
                          </FormGroup>

                          <FormGroup>
                            <label>Resort</label>
                            <Field
                              className="form-control"
                              name="resortID"
                              as="select"
                              onChange={ (e) => {
                                this.onchangeResort(
                                  e.target.value,
                                  setFieldValue
                                );
                                setFieldValue('resortID', e.target.value);
                              } }
                            >
                              <option value="">--Select--</option>
                              {this.state.resort.map((resort) => (
                                <option
                                  key={ resort.resortID }
                                  value={ resort.resortID }
                                >
                                  {resort.resortName}
                                </option>
                              ))}
                            </Field>
                            {/*  <SelectInput options={this.state.resort} label="Resort" name="resortID" /> */}
                          </FormGroup>

                          <FormGroup>
                            <label> Destination </label>
                            <Field
                              name="destinationID"
                              className={
                                errors.destinationID && touched.destinationID
                                  ? 'is-invalid form-control'
                                  : 'form-control'
                              }
                              as="select"
                              disabled="disabled"
                            >
                              <option value="">--Select--</option>
                              {this.state.destination.map((destination) => (
                                <option
                                  key={ destination.value }
                                  value={ destination.value }
                                >
                                  {destination.text}
                                </option>
                              ))}
                            </Field>

                            {errors.destinationID && touched.destinationID ? (
                              <div className="invalid-validation">
                                {errors.destinationID}
                              </div>
                            ) : null}
                          </FormGroup>

                          <FormGroup>
                            <label> Sub Destination </label>
                            <Field
                              name="subDestinationID"
                              className={
                                errors.subDestinationID
                                && touched.subDestinationID
                                  ? 'is-invalid form-control'
                                  : 'form-control'
                              }
                              as="select"
                              disabled="disabled"
                            >
                              <option value="">--Select--</option>
                              {this.state.subDestination.map(
                                (subDestination) => (
                                  <option
                                    key={ subDestination.value }
                                    value={ subDestination.value }
                                  >
                                    {subDestination.text}
                                  </option>
                                )
                              )}
                            </Field>

                            {errors.subDestinationID
                            && touched.subDestinationID ? (
                              <div className="invalid-validation">
                                {errors.subDestinationID}
                              </div>
                              ) : null}
                          </FormGroup>

                          <FormGroup>
                            <Row>
                              <Col md={ 3 }>
                                <TextField
                                  type="number"
                                  min="1"
                                  label="Capacity"
                                  name="capacity"
                                />
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <TextArea label="Description" name="description" />
                          </FormGroup>

                          <FormGroup>
                            <Row>
                              <Col md={ 3 }>
                                <label>Enabled</label>
                                <div className="input-group mt-2">
                                  <Field
                                    type="checkbox"
                                    name="allowReligiousCeremony"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </FormGroup>
                        </CardBody>
                        <Button
                          disabled={ isSubmitting }
                          type="submit"
                          color="primary"
                          className="mt-1"
                        >
                          {isSubmitting ? 'Processing....' : 'Save'}
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Formik>
      </TransitionGroup>
    );
  }
}
export default withRouter(Create);
