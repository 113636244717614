import { ITransferType } from "@/Pages/ManageHotel/TransferContent/slice/types"
import { ICustomerTransferReservation } from "@/Pages/Hbsi/Reservations/slice/types"
import { AppConfig } from 'config';
import { axiosFailedResponsesHandler } from "../axiosFailedResponsesHandler"
import axios from "axios";
import { escapeSpecialChars } from 'utils/utils';

const destifyOdataClient = axios.create({ baseURL: AppConfig.Api.AdminBackendApi.BaseUrl })

destifyOdataClient.interceptors.response.use(null, axiosFailedResponsesHandler);

destifyOdataClient.interceptors.request.use(config => {
    const newConfig = { ...config };
    const token = localStorage.getItem(
        process.env.REACT_APP_IDENTITY_PROVIDER === 'cognito' ? 'idtoken' : 'token',
    );
    if (token) {
        newConfig.headers.Authorization = `Bearer ${token}`;
    }
    return newConfig;
})

const handleSuccess = (response) => {
    try {
        if (response.status >= 200 && response.status < 300) {
            return response.data.value;
        }
    } catch (err) {
        throw new Error()
    }
};

type RawCustomerTransferReservationsResponse = Omit<ICustomerTransferReservation, "PurchaseDate">
            & { PurchaseDate: string | null };

type RawITransferTypeResponse = Omit<ITransferType, "LastModifiedDate" | "CreatedDate">
& { LastModifiedDate: string | null, CreatedDate: string };
const parseTransferTypeDateStringsToDates = (rawResponseData: RawITransferTypeResponse[]): ITransferType[] => {
return rawResponseData.map(rawTransferType => {
    const {
        CreatedDate,
        LastModifiedDate,
        ...rest
    } = rawTransferType;
    return {
        CreatedDate: new Date(CreatedDate), //CreatedDate is never null in db, so not adding null check
        LastModifiedDate: LastModifiedDate ? new Date(LastModifiedDate) : null, //can be null in db, so adding null check
        ...rest
    }
});
}

const parseCustomerTransferReservationsDateStringsToDates = (rawResponseData: RawCustomerTransferReservationsResponse[]): ICustomerTransferReservation[] => {
    return rawResponseData.map(rawTransferType => {
        const {
            PurchaseDate,
            ...rest
        } = rawTransferType;
        return {
            PurchaseDate: PurchaseDate ? new Date(PurchaseDate) : null, //can be null in db, so adding null check
            ...rest
        }
    });
}
class Service  {
   
    async getTransferTypes(): Promise<ITransferType[]> {
        
        try {
            const rawResponse: RawITransferTypeResponse[] =
                handleSuccess(await destifyOdataClient.get<any, RawITransferTypeResponse[]>("odata/OasisDestifyHotelTransfersTypes"));

            //because JSON doesnt support a Date "type", DateTimeStamps are serialized as a strings
            //coming back from the API. So, we need to parse them back into proper Date objects, 
            //and to avoid past Date related mistakes, im intercepting the response from the api
            //and manually converting the DateTimeStamp strings to actual javascript Date objects
            //to avoid ambiguity between is it "02-03-1234" or "02/03/1234" or "02-03-1234T00:00:00" etc..
            //it'll just be an actual Date object, when ITransferType is used in the app. 
            //future to do: handle this with generics and 3rd party libs a la https://medium.com/@dorontohar/a-simple-type-safe-http-client-wrapper-edb7df9317db
            return parseTransferTypeDateStringsToDates(rawResponse);

        } catch (err) {
            axiosFailedResponsesHandler(err)
        }
    }
    async getCustomerTransferReservations(filters = null): Promise<ICustomerTransferReservation[]> {
        try {
            let filterStr = "";
            if(filters) {
                filterStr += "?$filter="
                for (const filter in filters) {
                    filterStr += `(contains(${filter},'${filters[filter].replace("'", "''")}'))`
                }
            }
            const rawResponse: RawCustomerTransferReservationsResponse[] =
                handleSuccess(await destifyOdataClient.get<any, RawCustomerTransferReservationsResponse[]>(`odata/OasisDestifyHotelCustomerTransfers${filterStr}`));
            return parseCustomerTransferReservationsDateStringsToDates(rawResponse);

        } catch (err) {
            axiosFailedResponsesHandler(err)
        }
    }

    async getODataListResponseWithCount<T>(url: string = '') {
        const { data: { value, '@odata.count': count } } = await destifyOdataClient.get<{ value: any, '@odata.count': number }>(escapeSpecialChars(url));  
        return {
          data: parseCustomerTransferReservationsDateStringsToDates(value),
          count
        }
      }
}

const service = new Service();

export default service;