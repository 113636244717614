import React from 'react';
import { Label } from 'reactstrap';
import { useField } from 'formik';

import Select, { Props } from 'common/Select';

export interface IProps<T = any> extends Omit<Props<T>, 'onChange'> {
  name: string;
  label?: string;
  selectLabel?: string;
}

const FormSelect = <T extends unknown>({
  name,
  label,
  selectLabel,
  ...rest
}: IProps<T>) => {
  const [field, meta, helpers] = useField(name);
  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Select {...rest} label={selectLabel} onChange={item => helpers.setValue(item)} initialValue={field.value} />
      {meta.touched && meta.error ? (
        <div className="invalid-validation">{JSON.stringify(meta.error)}</div>
      ) : null}
    </>
  );
};

export default FormSelect;
