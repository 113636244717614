import { put, takeLatest, select, call } from 'redux-saga/effects';
import salesLead from 'api/salesLead';
import weddingPackage from 'api/weddingPackage';
import { toast } from 'react-toastify';
import { WeddingDateActions as actions } from '.';
import { IConfirmDetails } from './types';

import { selectWeddingDateQueryObject,selectWeddingDetailIdentifier } from './selectors';

function* getCeremonyTimeList() {
  const data = yield salesLead.salesStatus.ceremonytimelist();
  if (data) yield put(actions.setCeremonyTimeList([...data]));
}

function* getWeddingDetailsById(action) {
  yield put(actions.setLoading(true));
  const data = yield salesLead.salesLeadDetails.details(action.payload);
  const resortID = data.resortID;
  yield getResortVenueById({ payload: resortID });
  yield getResortPackageById({ payload: resortID });
  if (data) yield put(actions.setRequestDetails(data));
  yield put(actions.setLoading(false));
}

function* handleSaveConfirmWeddingDetails(action) {
  yield put(actions.updateConfirmWeddingDetailsLoading(true));
  try {
    yield salesLead.salesStatus.UpdateWeddingConfirmDetails(action.payload);
    toast.success('Wedding date has been confirmed.');
    const id = action.payload.get('SalesLeadId').toString();
    yield getWeddingDetailsById({ payload: id });
  } catch (error) {
    toast.error('Network error! Please Try again.');
  } finally {
    yield put(actions.updateConfirmWeddingDetailsLoading(false));
  }
}

function* getWeddingRequestHistoryList(action) {
  const historyData = yield call(
    salesLead.salesLeadDetails.getWeddingDateRequestHistoryById,
    action.payload,
  );
  yield put(actions.setWeddingRequestHistory(historyData));
}

function* updateweddingstatus(action) {
  
  const { statusId, ...rest } = action.payload;
  const { id, userId } = yield select(selectWeddingDetailIdentifier)
  try {
     yield salesLead.salesStatus.updateWeddingStatus({
      SalesLeadID: id,
      createdBy: userId,
      weddingDateStatus: statusId,
      ...rest,
    });
    yield getWeddingDetailsById({payload:id});
    toast.success('Wedding Status has been changed successfully.');   
  } catch (error: any) {
    console.log(error.message)
    toast.error('Network error! Please Try again.');
  
  }
}

function* updateweddingdetailsbyuser(action) {
  yield put(actions.updateRequestDetailsLoading(true));
  try {
    yield salesLead.salesLeadDetails.updateWeddingDetailsByUser(action.payload);
    toast.success('Wedding Status has been changed successfully.');
  } catch (error) {
    toast.error('Network error! Please Try again.');
  } finally {
    yield put(actions.updateRequestDetailsLoading(false));
  }
}

function* resortListing() {
  const data = yield weddingPackage.resort.getResortList();
  if (data) yield put(actions.setResortListing([...data]));
}

function* loadWeddingDateStatusList() {
  const data =
    yield weddingPackage.weddingDateStatusType.getWeddingDateStatusType();
  if (data) yield put(actions.setWeddingDateStatusList([...data]));
}

function* getResortVenueById(action) {
  const response = yield weddingPackage.venue.getResortVenueById(
    action.payload,
  );
  if (response.isSuccess) yield put(actions.setVenues([...response.data]));
}

function* getResortPackageById(action) {
  const response = yield weddingPackage.packagedetails.getResortPackageById(
    action.payload,
  );
  if (response.isSuccess)
    yield put(actions.setResortPackages([...response.data]));
}

function* getUserDetails(action) {
  try {
    const response = yield salesLead.salesLeadDetails.weddingDetailsByUser(
      action.payload,
    );
    yield put(actions.setConfirmDetails(response.data));
  } catch (err) {
    yield put(actions.setConfirmDetails({} as IConfirmDetails));
  }
}

function* holdExpiredEmail(action) {
  try {
    const response = yield salesLead.salesLeadDetails.holdExpiredEmail(
      parseInt(action.payload, 10),
    );
    if (response.error) {
      throw new Error(response.message);
    } else {
      toast.success(response.message);
      yield getWeddingDetailsById(action);
    }
  } catch (error) {
    toast.error(
      (error as Error)?.message || 'Network error! Please Try again.',
    );
  }
}

function* getWeddingList(action) {
  const pageNo = action.payload;
  yield put(actions.setLoading(true));
  const queryObject = yield select(selectWeddingDateQueryObject);
  const data = yield salesLead.salesLeadDetails.list(
    pageNo ? { ...queryObject, PageNumber: pageNo } : queryObject,
  );
  yield put(actions.setWeddingList(data.data));
  yield put(actions.setLoading(false));
  yield put(
    actions.setWeddingDateQueryObject({
      ...queryObject,
      PageNumber: data.pagination.currentPage,
      PageSize: data.pagination.itemsPerPage,
      totalItems: data.pagination.totalItems,
    }),
  );
  const resorts = yield weddingPackage.resort.getResortList();
  yield put(actions.setResortListing(resorts));

  const result =
    yield weddingPackage.weddingDateStatusType.getWeddingDateStatusType();
  yield put(actions.setWeddingDateStatusList(result));
}

export function* weddingDateSaga() {
  // takelatest -> listening to the actions
  yield takeLatest(actions.getCeremonyTimeList.type, getCeremonyTimeList);
  yield takeLatest(
    actions.getWeddingRequestHistoryList.type,
    getWeddingRequestHistoryList,
  );
  yield takeLatest(actions.resortListing.type, resortListing);
  yield takeLatest(actions.getResortVenueById.type, getResortVenueById);
  yield takeLatest(actions.getResortPackageById.type, getResortPackageById);
  yield takeLatest(
    actions.loadWeddingDateStatusList.type,
    loadWeddingDateStatusList,
  );
  yield takeLatest(actions.getUserDetails.type, getUserDetails);
  yield takeLatest(
    actions.updateweddingdetailsbyuser.type,
    updateweddingdetailsbyuser,
  );
  yield takeLatest(actions.updateweddingstatus.type, updateweddingstatus);
  yield takeLatest(actions.getWeddingDetailsById.type, getWeddingDetailsById);
  yield takeLatest(
    actions.handleSaveConfirmWeddingDetails.type,
    handleSaveConfirmWeddingDetails,
  );
  yield takeLatest(actions.holdExpiredEmail.type, holdExpiredEmail);
  yield takeLatest(actions.getWeddingList.type, getWeddingList);
}
