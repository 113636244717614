import React, { useEffect, useRef } from 'react';
import { ODataTableHelper } from 'common/OData/ODataTableHelper';
import { Button, Container, Card, CardBody } from 'reactstrap';
import { useOutsideClick } from 'hooks';
import ReactTable from 'react-table'
import ContextMenu from 'common/ContextMenu';
import PageTitle from 'Layout/AppMain/PageTitle';

import { useCustomerTransferReservationSlice } from './slice';
import { selectCustomerTransferReservations, selectLoading } from './slice/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const ModifyButton = ({ data }) => {
  const { targetRef, isOpen } = useOutsideClick<HTMLDivElement>(false, {
    toggle: true,
  });

  return (
    <div ref={targetRef} className="position-relative">
      <Button className="rounded-pill">Modify</Button>
      <ContextMenu
        show={isOpen}
        items={[
          {
            label: 'Manage',
            type: 'url',
            url: `/hbsi/transfers/${data.id}`
          }
        ]}
      />
    </div>
  );
};

const Index = () => {
  const { actions } = useCustomerTransferReservationSlice();
  const dispatch = useAppDispatch();
  const { items,  pages } = useAppSelector(selectCustomerTransferReservations);
  const loading=  useAppSelector(selectLoading);
  const reactTable = useRef();

  useEffect(() => {
    const queryStringArgs = `$count=true&$top=10`;
    dispatch(actions.loadCustomerTransferReservations({ queryString: queryStringArgs, pageSize: 10 }));
    return () => {
      dispatch(actions.clearReservations())
    }
  }, [dispatch, actions]);

  const fetchData = async (reactTableState) => {
    if (!reactTableState) return;

    const queryOptions = {
      pageSize: reactTableState.pageSize,
      currentPage: reactTableState.page,
      dataTableSort: reactTableState.sorted.map(({ id, desc }) => ({
        columnName: id,
        sortOrder: desc ? 'desc' : 'asc',
      })),
      dataTableFilters: reactTableState.filtered.map(({ id, value }) => ({
        columnName: id,
        value,
      })),
      filters: [],
    };
    //we need to treat the fullname column differently when searching, as its a computed filed in the Odata endpoint
    const fullNameFilter = queryOptions.dataTableFilters?.find(
      f => f.columnName.toLowerCase() === 'name',
    );
    let freeFormColumnFilterArgs = '';
    if (fullNameFilter) {
      const { value } = fullNameFilter;
      queryOptions.dataTableFilters = queryOptions.dataTableFilters.filter(
        f => f.columnName.toLowerCase() !== 'name',
      ); //remove fullname

      freeFormColumnFilterArgs = `(contains(concat( concat(PrimaryTravelerFirstName,' '),PrimaryTravelerLastName),'${value}'))`;
    }
    if(queryOptions.dataTableSort.find(f => f.columnName.toLowerCase() === 'name'))
      queryOptions.dataTableSort.find(f => f.columnName.toLowerCase() === 'name').columnName ='PrimaryTravelerFirstName';

    const queryStringArgs = ODataTableHelper.constructODataQueryArgs(
      queryOptions.pageSize,
      queryOptions.currentPage,
      queryOptions.dataTableFilters,
      queryOptions.dataTableSort,
      queryOptions.filters,
      true,
      freeFormColumnFilterArgs,
    );

    dispatch(actions.loadCustomerTransferReservations({ queryString: queryStringArgs, pageSize: queryOptions.pageSize }))

  }

  return (
    <>
      <Container fluid>
        <div>
          <PageTitle
            heading="Booked Customer Transfers"
            icon="pe-7s-car icon-gradient bg-tempting-azure"
          />
        </div>
        <Card className="main-card mb-3">
          <CardBody>
            <ReactTable
              manual
              data={items}
              pages={pages}
              loading={loading}
              onFetchData={fetchData}
              filterable
              defaultPageSize={10}
              className="-striped -highlight"
              multiSort={false}
              defaultSorted={[
                {
                  // the sorting model for the table
                  id: 'PurchaseDate',
                  desc: true,
                },
              ]}
              ref={reactTable}
             
              columns={[
                {
                  Header: 'Reservation #',
                  accessor: 'Id',
                  filterable: true
                },
                {
                  Header: 'Name',
                  accessor: 'Name',
                  Cell: row=> (`${row.original.PrimaryTravelerFirstName} ${row.original.PrimaryTravelerLastName} `),
                  filterable: true
                },
                {
                  Header: 'Ptid Hotel Id #',
                  accessor: 'HotelId',
                  filterable: false
                },
                {
                  Header: 'CRM Group Id',
                  accessor: 'GroupId',
                  filterable: true
                },
                {
                  Header: 'Status',
                  accessor: 'Status',
                  Cell: row => (
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="px-4">{row.original.Status}</div>
                      <div>
                        <ModifyButton data={{ ...row.original, id: row.original.Id }} />
                      </div>
                    </div>
                  ),
                  filterable: true
                },
              ]}
              pageSizeOptions={[10, 20, 30, 100]}
              showPaginationTop
              showPaginationBottom
              defaultFiltered={[]}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Index;
