import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from '../utils/redux-injectors';
import sideBar6 from '../assets/utils/images/sidebar/city1.jpg';
import { themeSaga } from './saga';
// const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';

// const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
// const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';

// const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER';
// const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW';
// const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW';
// const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
// const SET_ENABLE_CLOSED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR';
// const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER';

// const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON';
// const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING';
// const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT';

// const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
// const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
// const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME';
// const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY';

// const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR';
export const initialThemeState = {
  backgroundColor: '',
  headerBackgroundColor: '',
  enableMobileMenuSmall: '',
  enableBackgroundImage: false,
  enableClosedSidebar: false,
  enableFixedHeader: true,
  enableHeaderShadow: true,
  enableSidebarShadow: true,
  enableFixedFooter: true,
  enableFixedSidebar: true,
  colorScheme: 'white',
  backgroundImage: sideBar6,
  backgroundImageOpacity: 'opacity-06',
  enablePageTitleIcon: true,
  enablePageTitleSubheading: true,
  enablePageTabsAlt: true,
};
const themeSlice = createSlice({
  name: 'ThemeOptions',
  initialState: initialThemeState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setEnableFixedHeader(state, action) {
      state.enableFixedHeader = action.payload;
    },

    setEnableHeaderShadow(state, action) {
      state.enableHeaderShadow = action.payload;
    },

    setEnableSidebarShadow(state, action) {
      state.enableSidebarShadow = action.payload;
    },

    setEnablePageTitleIcon(state, action) {
      state.enablePageTitleIcon = action.payload;
    },

    setEnablePageTitleSubheading(state, action) {
      state.enablePageTitleSubheading = action.payload;
    },

    setEnablePageTabsAlt(state, action) {
      state.enablePageTabsAlt = action.payload;
    },

    setEnableFixedSidebar(state, action) {
      state.enableFixedSidebar = action.payload;
    },

    setEnableClosedSidebar(state, action) {
      state.enableClosedSidebar = action.payload;
    },

    setEnableMobileMenu(state, action) {
      state.enableMobileMenu = action.payload;
    },

    setEnableMobileMenuSmall(state, action) {
      state.enableMobileMenuSmall = action.payload;
    },

    setEnableFixedFooter(state, action) {
      state.enableFixedFooter = action.payload;
    },

    setBackgroundColor(state, action) {
      state.backgroundColor = action.payload;
    },

    setHeaderBackgroundColor(state, action) {
      state.headerBackgroundColor = action.payload;
    },

    setColorScheme(state, action) {
      state.colorScheme = action.payload;
    },

    setBackgroundImageOpacity(state, action) {
      state.backgroundImageOpacity = action.payload;
    },

    setBackgroundImage(state, action) {
      state.backgroundImage = action.payload;
    },
  },
});

export const { actions } = themeSlice;

export default themeSlice.reducer;

export const useThemeSlice = () => {
  useInjectReducer({ key: themeSlice.name, reducer: themeSlice.reducer });
  useInjectSaga({ key: themeSlice.name, saga: themeSaga });
  return { actions: themeSlice.actions };
};
