import React, { } from 'react';

import { withRouter } from 'react-router-dom';
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

function Unauthorized() {
  return (

    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-10">
                <div className="card-body">
                  <div data-testid="wrapper" className="_loading_overlay_wrapper css-79elbk">
                    <div className="card-hover-shadow-2x mb-3 card"><div className="card-header"> Unauthorized</div>
                      <div className="card-body">
                        <p><h1>Oops</h1></p>
                        <p className="mb-0"> Sorry, you are not authorize to view this page.</p>
                        <p className='mb-0'>Your session may has expired please login again</p>
                      </div>
                    </div>
                  </div>
                </div>
                            
              </div>
            </div>
          </div>
          <AppFooter />
        </div>
      </div>
    </>

  );
}
export default withRouter(Unauthorized)
