import React, { useEffect, useState } from 'react';

import {  Container } from 'reactstrap';


import PageTitle from 'Layout/AppMain/PageTitle';
import styles from './style.module.scss'
import { FlightInfo } from './_FlightInfo';
import { TransferInfo } from './_TransferInfo';
import { PaymentInfo } from './_PaymentInfo';
import { UsersBooking } from './_UsersBooking';
import data from './mock_data.json';
import { useHistory } from 'react-router-dom';
import transfersApi from '../../../api/transfers';
import {  TransferInfoDetails, FlightInfoDetails } from '../Reservations/slice/types'
import { useParams } from 'react-router-dom';

const ManageTransferReservation = ({}): JSX.Element => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [ transferInformation, setTransferInfo ] = useState<TransferInfoDetails>({
    id: '',
    numberOfAdults: null,
    numberOfChildren: null,
    primaryTravelerFirstName: '',
    primaryTravelerLastName: '',
    status: '',
    transferType: '',
    flightInformation: {
      airlineConfirmationNo: '',
      airlineName: '',
      arrivalDate: '',
      departureDate: '',
      flightNo: ''
    }
  })


  // Mock data for now
  const { paymentInfo } = data;

  useEffect(() => {
    getTransferDetailsById(id)
  },[id])

  const getTransferDetailsById = async (id: string) => {
    const items  = await transfersApi.transferReservation.getTransferDetails(id);

    if(!items) {
      return;
    }

    setTransferInfo(items)
  }

  const { primaryTravelerFirstName, primaryTravelerLastName, status } = transferInformation as TransferInfoDetails;

  return (
    <>
      <Container fluid style={{ margin: '0 0 5rem 0'}}>
        <div>
          <PageTitle
            heading="Manage Transfer Reservations"
            icon="pe-7s-map-2 icon-gradient bg-tempting-azure"
          />
        </div>
        <div>
            <div className={styles.booking}>
                <h3 className={styles.booking_name}>{ `${primaryTravelerFirstName} ${primaryTravelerLastName}` }</h3>
                <div className="mb-2 mr-2 badge badge-success">{ status }</div> 
                <button
                  type="button"
                  className="mb-3 ml-auto btn-pill btn btn-outline-primary"
                  style={{ display: 'flex' }}
                  onClick={() => history.goBack()}
                >
                  Back to the list
                </button>
            </div>
                <h5 className='list-details-value'>{`Reservation # ${ id }`}</h5>
        </div>
        <TransferInfo transferReservation={ transferInformation } />
        <FlightInfo id={id} flightInformation={ transferInformation.flightInformation } />
        <PaymentInfo paymentInfo={ paymentInfo } />
        <UsersBooking key={transferInformation.destifyUserId} id={transferInformation.destifyUserId} reservationId={transferInformation.id} />
      </Container>
    </>
  );
};

export default ManageTransferReservation;
