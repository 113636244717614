import React from 'react';

import { Row, Col, FormGroup } from 'reactstrap';

import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

class PhotoWidgetDropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: '',
      cropResult: null,
      blob: null,
      imageName: '',
      altTagText: '',
      isSubmit: false,
    };
    this.cropImage = this.cropImage.bind(this);
  }

  onDrop = files => {
    if (files.length) {
      const image = URL.createObjectURL(files[0]);
      this.setState({ image });
    }
  };

  onCancel = () => {};

  cropImage() {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    this.cropper.getCroppedCanvas().toBlob(blob => this.setState({ blob }));

    this.setState({
      cropResult: this.cropper.getCroppedCanvas().toDataURL(),
      imageName: '',
      altTagText: '',
    });
  }

  componentWillUnmount() {}

  onSubmit() {
    this.setState({ isSubmit: true });
    if (
      this.state.imageName === '' ||
      this.state.imageName.length > 200 ||
      this.state.altTagText.length > 200
    )
      return;
    this.setState({ image: '', cropResult: null });
    this.props.submit({
      this: this,
      that: this.props.that,
      blob: this.state.blob,
      Id: this.props.Id,
      imageName: this.state.imageName,
      altTagText: this.state.altTagText,
    });
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Row>
        <Col md="4">
          <div className="dropzone-wrapper dropzone-wrapper-lg">
            <Dropzone
              onDrop={value => this.onDrop(value)}
              onFileDialogCancel={this.onCancel()}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dropzone-content">
                    <p>
                      Try dropping some files here, or click to select files to
                      upload.
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </Col>
        <Col md="4">
          {this.state.image !== '' && (
            <>
              <Cropper
                style={{ height: 300, width: '100%', objectFit: 'contain' }}
                preview=".img-preview"
                guides={false}
                src={this.state.image}
                ref={cropper => {
                  this.cropper = cropper;
                }}
                viewMode={1}
                dragMode="move"
                cropBoxMovable={false}
              />

              <button
                type="button"
                className="btn btn-primary mt-3"
                onClick={this.cropImage}
                style={{ float: 'right' }}
              >
                Crop Image
              </button>
            </>
          )}
        </Col>

        <Col md="4">
          {this.state.cropResult !== null && (
            <>
              <img
                style={{ height: 300, width: '100%', objectFit: 'contain' }}
                src={this.state.cropResult}
                alt="cropped img"
                className="img-thumbnail"
              />

              <FormGroup>
                <label htmlFor="imageName">Image Name</label>
                <input
                  type="text"
                  name="imageName"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.imageName}
                  className="form-control mb-2"
                />

                {this.state.isSubmit && this.state.imageName === '' ? (
                  <div className="invalid-validation">
                    Image Name is required.
                  </div>
                ) : null}

                {this.state.isSubmit && this.state.imageName.length > 200 ? (
                  <div className="invalid-validation">
                    Image Name should be less than 200 characters.
                  </div>
                ) : null}
              </FormGroup>

              <FormGroup>
                <label htmlFor="altTagText">Alt Tag Text</label>
                <input
                  type="text"
                  name="altTagText"
                  onChange={e => this.handleInputChange(e)}
                  value={this.state.altTagText}
                  className="form-control mb-2"
                />

                {this.state.isSubmit && this.state.altTagText.length > 200 ? (
                  <div className="invalid-validation">
                    Alt tag text should be less than 200 characters.
                  </div>
                ) : null}
              </FormGroup>

              <button
                type="button"
                onClick={() => this.onSubmit()}
                className="btn btn-primary mt-3"
                style={{ float: 'right' }}
              >
                Save Image
              </button>
            </>
          )}
        </Col>
      </Row>
    );
  }
}

export default PhotoWidgetDropzone;
