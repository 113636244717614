import React, { useEffect, useState, useMemo } from 'react';
import { ODataDateRangePicker } from 'common/Form/ODataDateRangePicker';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectSalesStatus, selectAuthUsers } from '../../slice/selectors';
import { useSalesLeadSlice } from '../../slice';
import { SalesJourneyODataApiClient } from 'api/OData/SalesJourneyODataApiClient';
import { IODataFilter, ODataFilterOptions } from 'common/OData/ODataFilters';
import Select from 'react-select';
import { SALES_LEAD_STATUS } from '../../slice/types';
import {
  faAngleDown,
  faAngleUp,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IoIosFunnel } from 'react-icons/io';
import { AdminRoles } from 'auth/Roles';

import QuickFilter from "./_QuickFilter";

const client = new SalesJourneyODataApiClient();

const dropdownStyles = {
  container: (baseStyles, state) => ({
    ...baseStyles,
    width: '100%',
  }),
  dropdownIndicator: (baseStyles, state) => ({
    ...baseStyles,
    color: '#495057',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
  }),
  indicatorsContainer: (baseStyles, state) => ({
    ...baseStyles,
    color: '#495057',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: '#495057',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
  }),
};

const rangeModifierOptions = [
  { value: 'gt', label: 'Greater Than' },
  { value: 'eq', label: 'Equal To' },
  { value: 'lt', label: 'Less Than' },
];

const YesNoOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

type QuickFilters =
  | null
  | 'SearchedRoomPast24Hrs'
  | 'NewAccountNoWeddingDateRequest'
  | 'ActivePast24HoursNoChanges'
  | 'Booking'
  | 'NewUnassigned';

const _Filter = ({
  updateFilters,
  clearAllFilters,
  filters,
  onSearch,
}: {
  updateFilters: (filter: IODataFilter<unknown>) => Promise<void>;
  clearAllFilters: (refreshData?: boolean) => void;
  filters: IODataFilter<unknown>[];
  onSearch: () => Promise<void>;
}) => {
  const { actions } = useSalesLeadSlice();
  const dispatch = useAppDispatch();
  const { error: salesStatusError } = useAppSelector(selectSalesStatus);
  const authUsers = useAppSelector(selectAuthUsers);
  //TODO: revisit consolidating the static SALES_LEAD_STATUS enum vs invoking /api/SalesLead/SalesLeadStatusAll
  const salesStatusList = Object.values(SALES_LEAD_STATUS)
    .filter(o => isNaN(Number(o)))
    .map(d => ({ value: d, label: d }));

  const [lastPageVisitedList, setLastPageVisitedList] = useState([]);
  const [weddingStatusList, setWeddingStatusList] = useState([]);
  const [error, setError] = useState(null);
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);
  const [selectedQuickFilter, setSelectedQuickFilter] =
    useState<QuickFilters>(null);
  const [salesAgentList, setSalesAgentList] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const [
    dateCreatedFilter,
    groupSizeFilter,
    weddingDateFilter,
    lastEditedDateFilter,
    dateVisitedFilter,
    dateDateRequestedFilter,
    lastPageVisitedFilter,
    lgbtqWeddingFilter,
    weddingStatusFilter,
    salesLeadStatusFilter,
    assignedLeadFilter,
  ] = useMemo(
    () => [
      filters.find(f => f.name === 'CreatedDate'),
      filters.find(f => f.name === 'GroupSize'),
      filters.find(f => f.name === 'AnticipatedWeddingDate'),
      filters.find(f => f.name === 'LastModifiedDate'),
      filters.find(f => f.name === 'LastVisitedDate'),
      filters.find(f => f.name === 'WeddingDateRequestedDate'),
      filters.find(f => f.name === 'LastPageVisited') as IODataFilter<
        string[] | null
      >,
      filters.find(f => f.name === 'LgbtqWedding'),
      filters.find(f => f.name === 'WeddingStatus') as IODataFilter<
        string[] | null
      >,
      filters.find(f => f.name === 'SalesLeadStatus') as IODataFilter<
        (keyof typeof SALES_LEAD_STATUS)[] | null 
      >,
      filters.find(f => f.name === 'AssignedAgentID') as IODataFilter<string[]| null>,
    ],
    [filters],
  );

  useEffect(() => {
    dispatch(actions.loadAuthUsers());
  }, [dispatch, actions]);

  useEffect(() => {
    const isValidRole = (role: string) => role === AdminRoles.SalesSpecialist;
    if (authUsers.length !== 0) {
      const leads = authUsers.filter(
        userItem =>
          userItem.isActive && userItem.checkedroles.some(isValidRole),
      );

      setSalesAgentList(
        [
          {
            label: '--Unassigned--',
            value: null,
          },
        ].concat(
          leads.map(lead => ({
            value: lead.destifyUserId,
            label: `${lead.firstName} ${lead.lastName}`,
          })),
        ),
      );
    }
  }, [authUsers]);

  useEffect(() => {
    (async () => {
      try {
        const [lastPageVisitedResponse, weddingStatusListResponse] =
          await Promise.all([
            client.getData<string[]>('GetLastPageVisitedList'),
            client.getData<string[]>('GetWeddingStatusList'),
          ]);

        setLastPageVisitedList(
          lastPageVisitedResponse.map(d => ({ value: d, label: d })),
        );
        setWeddingStatusList(
          weddingStatusListResponse.map(d => ({ value: d, label: d })),
        );
      } catch (error) {
        setError(
          'Unexpected server side or network error. Please try again or contact the dev team.',
        );
      }
    })();
  }, []);

  const handleFilterChange = (filterProps: IODataFilter) => {
    updateFilters(filterProps);
  };

  const handleQuickFilterChange = async (filter: QuickFilters) => {
    //clear existing filters, in ui, without doing a fresh pull on data
    await clearAllFilters(false);
    setSelectedQuickFilter(prev => (prev === filter ? null : filter));
    let affectedFilters = [];
    switch (filter) {
      case 'SearchedRoomPast24Hrs':
        lastPageVisitedFilter.modifier = 'in';
        lastPageVisitedFilter.value = ['SearchRoomView'];
        dateVisitedFilter.modifier = 'gt';
        dateVisitedFilter.value = {
          fromDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        };
        affectedFilters = [lastPageVisitedFilter, dateVisitedFilter];
        break;
      case 'NewAccountNoWeddingDateRequest':
        dateCreatedFilter.modifier = 'gt';
        dateCreatedFilter.value = {
          fromDate: new Date(new Date().getTime() - 24 * 7 * 60 * 60 * 1000),
        };
        dateDateRequestedFilter.modifier = 'eq null';
        affectedFilters = [dateCreatedFilter, dateDateRequestedFilter];
        break;
      case 'ActivePast24HoursNoChanges':
        dateVisitedFilter.modifier = 'gt';
        dateVisitedFilter.value = {
          fromDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        };
        lastEditedDateFilter.modifier = 'lt';
        lastEditedDateFilter.value = {
          fromDate: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        };
        affectedFilters = [lastEditedDateFilter, dateVisitedFilter];
        break;
      case 'Booking':
        lastPageVisitedFilter.modifier = 'in';
        lastPageVisitedFilter.value = ["PaymentSuccess"];
        salesLeadStatusFilter.modifier = "in";
        weddingStatusFilter.value = ["BOOKINGREQUESTED"];
        affectedFilters = [lastPageVisitedFilter,weddingStatusFilter];
        break;
      case 'NewUnassigned':        
          //this aspect of the filtering is to take the current datetime, subtract 48 hours, and find records that are older than that
          //in other words, find records where `DateCreated gt (Now - 48hours)` 
          //gt = greater than
          dateCreatedFilter.modifier = 'gt'; 
          //this is meant to be last 48 hours in milliseconds
          const FourtyEightHoursInMilliseconds = 48 * 60 * 60 *1000;
          dateCreatedFilter.value = {
            //take current local to user datetime stamp, and subtract 48hours worth of milliseconds
            fromDate: new Date(new Date().getTime() - FourtyEightHoursInMilliseconds),
          };
          assignedLeadFilter.modifier = 'in';
          assignedLeadFilter.value=[null];
          groupSizeFilter.modifier='gt';
          groupSizeFilter.value=9;
          affectedFilters = [dateCreatedFilter, assignedLeadFilter,groupSizeFilter];
          break;
      default:
        break;
    }
    if (affectedFilters.length) {
      affectedFilters.forEach(f => handleFilterChange(f));
      onSearch();
    }
  };

  useEffect(() => {
    dispatch(actions.getSalesStatusList());
  }, [dispatch, actions]);

  if (error) return <>{error}</>;
  if (salesStatusError) return <>{salesStatusError}</>;

  return (
    <>
      <Card className="main-card mb-3">
        <CardHeader style={{ height: 'auto' }}>
          <div className="container-fluid pt-2 pb-2">
            <Row className="align-items-center justify-content-between">
              <Col sm={11} className="pl-0">
                <QuickFilter 
                selectedQuickFilter={selectedQuickFilter}
                handleQuickFilterChange={handleQuickFilterChange}
                clear={() => {
                  clearAllFilters();
                  setSelectedQuickFilter(null);
                }}
                />
              </Col>
              <Col className="text-right" sm={1}>
                <FontAwesomeIcon
                  icon={advancedFiltersOpen ? faAngleUp : faAngleDown}
                  size="2x"
                  id="toggle-advanced-filters"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setAdvancedFiltersOpen(!advancedFiltersOpen)}
                />
                <UncontrolledTooltip
                  placement="top"
                  target="toggle-advanced-filters"
                >
                  {advancedFiltersOpen ? 'Hide ' : 'Show '} Advanced Filters
                </UncontrolledTooltip>
              </Col>
            </Row>
          </div>
        </CardHeader>
        <Collapse isOpen={advancedFiltersOpen}>
          <CardBody>
            <h5 className="mb-2">
              <FontAwesomeIcon icon={faCog} /> Advanced Filters
            </h5>
            <hr />
            <Row className="px-2">
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <label>Last Page Visited</label>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Select
                        placeholder="--Select--"
                        styles={dropdownStyles}
                        isMulti
                        name="LastPageVisited"
                        options={lastPageVisitedList}
                        hideSelectedOptions={true}
                        closeMenuOnSelect={false}
                        isLoading={lastPageVisitedList.length <= 0}
                        onChange={e => {
                          lastPageVisitedFilter.modifier = e.length
                            ? 'in'
                            : null;
                          lastPageVisitedFilter.value = e.length
                            ? [...e.map(o => o.value)]
                            : null;
                          handleFilterChange(lastPageVisitedFilter);
                        }}
                        value={
                          //for this <Select> react control, value has to be set to one of the items available in the list of options
                          //and not the typical literal "value" of a classical HTML select control
                          lastPageVisitedFilter.value?.length
                            ? lastPageVisitedList.filter(item =>
                                lastPageVisitedFilter.value.includes(
                                  item.value,
                                ),
                              )
                            : []
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3} className="align-self-md-center">
                    <label>Group Size</label>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Select
                        name="GroupSizeOption"
                        onChange={e => {
                          if (!e?.value) {
                            groupSizeFilter.modifier = null;
                            groupSizeFilter.value = null;
                          } else {
                            groupSizeFilter.modifier =
                              e.value as ODataFilterOptions;
                          }
                          handleFilterChange(groupSizeFilter);
                        }}
                        value={
                          //for this <Select> react control, value has to be set to one of the items available in the list of options
                          //and not the typical literal "value" of a classical HTML select control
                          groupSizeFilter.modifier
                            ? rangeModifierOptions.find(
                                f => f.value === groupSizeFilter.modifier,
                              )
                            : null
                        }
                        placeholder="--Select--"
                        isClearable
                        styles={dropdownStyles}
                        options={rangeModifierOptions}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        value={(groupSizeFilter?.value as string) || ''}
                        name="GroupSize"
                        type="number"
                        placeholder="Size"
                        disabled={!groupSizeFilter || !groupSizeFilter.modifier}
                        onChange={e => {
                          handleFilterChange({
                            ...groupSizeFilter,
                            value: parseInt(e.target.value),
                          });
                        }}
                        style={{ minWidth: '100px' }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <label>Is LGBTQ Wedding</label>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Select
                        name="LgbtqWedding"
                        onChange={e => {
                          const updatedLgbtqFilterValue = {
                            ...lgbtqWeddingFilter,
                            value: !e?.value
                              ? null
                              : Boolean(JSON.parse(e.value)),
                          };
                          handleFilterChange(updatedLgbtqFilterValue);
                        }}
                        placeholder="--Select--"
                        isClearable
                        value={
                          //for this <Select> react control, value has to be set to one of the items available in the list of options
                          //and not the typical literal "value" of a classical HTML select control
                          lgbtqWeddingFilter.value
                            ? YesNoOptions.find(
                                f =>
                                  f.value ===
                                  lgbtqWeddingFilter.value.toString(),
                              )
                            : null
                        }
                        styles={dropdownStyles}
                        options={YesNoOptions}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <label>Wedding Status</label>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Select
                        placeholder="--Select--"
                        styles={dropdownStyles}
                        name="WeddingStatus"
                        options={weddingStatusList}
                        hideSelectedOptions={true}
                        closeMenuOnSelect={false}
                        isLoading={weddingStatusList.length <= 0}
                        onChange={e => {
                          weddingStatusFilter.modifier = e.value.length ? 'in' : null;
                          weddingStatusFilter.value = e.value;
                          handleFilterChange(weddingStatusFilter);
                        }}
                        value={
                          //for this <Select> react control, value has to be set to one of the items available in the list of options
                          //and not the typical literal "value" of a classical HTML select control
                          weddingStatusFilter.value?.length
                            ? weddingStatusList.filter(item =>
                                weddingStatusFilter.value.includes(item.value),
                              )
                            : []
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />{' '}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <label>Assigned Agent</label>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Select
                        styles={dropdownStyles}
                        name="AssignedAgentID"
                        placeholder="--Select--"
                        onChange={(agents) => {
                          handleFilterChange({
                            ...assignedLeadFilter,
                            value: agents.map(item => item.value )
                          });
                        }}
                        isMulti
                        options={salesAgentList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        hideSelectedOptions={true}
                        closeMenuOnSelect={false}
                        value={
                          assignedLeadFilter?.value
                            ? salesAgentList.filter(l =>
                                assignedLeadFilter.value.some(
                                  item => item === l.value,
                                ),
                              )
                            : []
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <label>Sales Status</label>
                  </Col>
                  <Col md={5}>
                    <FormGroup>
                      <Select
                        placeholder="--Select--"
                        styles={dropdownStyles}
                        isMulti
                        name="SalesLeadStatus"
                        options={salesStatusList}
                        hideSelectedOptions={true}
                        closeMenuOnSelect={false}
                        isLoading={salesStatusList.length <= 0}
                        onChange={e => {
                          salesLeadStatusFilter.modifier = e.length
                            ? 'in'
                            : null;
                          salesLeadStatusFilter.value = e.length
                            ? [
                                ...e.map(
                                  o =>
                                    o.value as keyof typeof SALES_LEAD_STATUS,
                                ),
                              ]
                            : null;
                          handleFilterChange(salesLeadStatusFilter);
                        }}
                        value={
                          //for this <Select> react control, value has to be set to one of the items available in the list of options
                          //and not the typical literal "value" of a classical HTML select control
                          salesLeadStatusFilter.value?.length
                            ? salesStatusList.filter(item =>
                                salesLeadStatusFilter.value.includes(
                                  item.value as keyof typeof SALES_LEAD_STATUS,
                                ),
                              )
                            : []
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <ODataDateRangePicker
                  label={'Created Date'}
                  onChange={updateFilters}
                  odataFilter={dateCreatedFilter}
                />
                <ODataDateRangePicker
                  label={'Anticipated Wedding Date'}
                  onChange={updateFilters}
                  odataFilter={weddingDateFilter}
                />
                <ODataDateRangePicker
                  label={'Active Date'}
                  onChange={updateFilters}
                  odataFilter={dateVisitedFilter}
                />
                <ODataDateRangePicker
                  label={'Last Edited'}
                  onChange={updateFilters}
                  odataFilter={lastEditedDateFilter}
                />
                <ODataDateRangePicker
                  label={'Date Wedding Date Requested'}
                  onChange={updateFilters}
                  odataFilter={dateDateRequestedFilter}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter style={{ display: 'block' }}>
            <div className="float-left">
              <b>Note:</b> this is a compound search. Meaning, it's{' '}
              <code>CreatedDate is xyz and LastActivityTime is xyz and...</code>{' '}
              <br />
              <i>Not</i>{' '}
              <code>CreatedDate is xyz or LastActivityTime is xyz</code>
            </div>
            <div className="float-right">
              <Button
                outline
                onClick={() => {
                  clearAllFilters();
                  setSelectedQuickFilter(null);
                }}
              >
                Clear All Filters
              </Button>{' '}
              <Button
                onClick={() => {
                  setSelectedQuickFilter(null);
                  onSearch();
                }}
              >
                Search
              </Button>
            </div>
            <div className="clearfix"></div>
          </CardFooter>
        </Collapse>
      </Card>
    </>
  );
};

export default _Filter;
