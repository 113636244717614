import { put, takeLatest } from 'redux-saga/effects';
import destifyOdataClient from 'api/OData/DestifyAdminClient';
import { TransferTypesActions as actions } from './index';
import { toast } from 'react-toastify';
import { ITransferType } from './types';

function* loadTransferTypes() {
  try {
    yield actions.setLoading(true);
    const response: ITransferType[] = yield destifyOdataClient.getTransferTypes();
    yield put(actions.setTransferTypes(response));
    yield actions.setLoading(false);
  } catch (e: any) {
    toast.error(e.message);
  }
}

export function* transferTypeSaga() {
  yield takeLatest(actions.loadTransferTypes.type, loadTransferTypes);
}
