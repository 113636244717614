/* eslint-disable quotes */
/* eslint-disable import/no-unresolved */
/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import common from 'store/slice';
import ThemeOptions from 'themeSlice';
import auth from 'Pages/UserPages/slice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers || []).length === 0) {
    return state => state;
  }
  return combineReducers({
    common,
    ThemeOptions,
    auth,
    ...injectedReducers,
  });
}
