/* eslint-disable react/no-array-index-key */
import React from 'react';

import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import {
  toast,
} from 'react-toastify';

import classnames from 'classnames';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import arrayChunk from 'lodash.chunk';
import _ from 'lodash';
import SweetAlert from 'sweetalert-react';
import { TextField } from '../../../common/Form/TextInput';
import { TextArea } from '../../../common/Form/TextArea';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import weddingPackage from '../../../api/weddingPackage';

import PhotoWidgetDropzone from '../../../common/imageUploader/photoWidgetDropzone';

class EditSubDestination extends React.Component {
  validationSchema = Yup.object({
    airportCode: Yup.string()
      .min(3, 'Airport Code must be 3 digits!')
      .max(3, 'Airport Code must be 3 digits!')
  });

  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        subDestinationName: '',
        destinationId: '',
        description: '',
        distanceFromAirport: '',
        airportCode: ''
      },
      destination: [],
      subDestinationId: 0,
      activeTab: '1',
      images: [],
      chunkedData: [],
      showImageUpload: false,
      show: false,
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    this.loadDestination();
    const { id } = this.props.match.params;
    if (id && id > 0) {
      this.setState({ subDestinationId: id });
      this.getImages(id);
      weddingPackage.subDestination.getSubDestinationById(id).then((res) => {
        this.setState({ initialValues: res })
      })
    }
  }

  async loadDestination() {
    const destination = await weddingPackage.destination.getDestination();
    this.setState({ destination });
  }

  async getImages() {
    const images = await weddingPackage.subDestination.getImages(this.props.match.params.id);
    this.setState({ images });
    this.setState({ chunkedData: arrayChunk(images, 3) });
  }

  async addImage(param) {
    await weddingPackage.subDestination.uploadPhoto(param.Id, param.imageName, param.altTagText, param.blob);
    toast.success('Image saved successfully for the city/sub destination.');
    await param.that.getImages();
  }

  async updateImage(index) {
    const image = this.state.images[index];
    const exceptList = _.reject(this.state.images, (o) => o.imageContentID === image.imageContentID);
    const isOrderExist = exceptList.some((d) => d.imageOrder === image.imageOrder);
    if (isOrderExist) {
      toast.error('Image display order already exists.');
      return;
    }
    weddingPackage.subDestination.updateImageDetails(image).then((response) => {
      // let arr = this.state.images;
      // arr[index] = image.imageURL;
      // this.setState({ images: arr })
      if (response === 'Image updated successfully.') { toast.success('The details of city/sub-destination image updated successfully.'); } else { toast.error('Internal Server Error'); }
    })
  }

    showConfirmationToDelete = (id) => {
      this.deleteDestinationId = id;
      this.setState({ show: true });
    }

    deleteImage = () => {
      weddingPackage.subDestination.deleteImages({ imageContentID: this.deleteDestinationId }).then(() => {
        toast.success('Image deleted succesfuly.');
        this.getImages();
      })
    }

    handleInputChange = (e, index) => {
      const { name, value } = e.target;
      this.setState((pS) => {
        const list = [...pS.images];
        list[index][name] = value;
        return { images: list, chunkedData: arrayChunk(list, 3) };
      });
    };

    render() {
      const { initialValues } = this.state;
      return (
        <>

          <SweetAlert
            show={ this.state.show }
            type="warning"
            title="Confirmation"
            text="Are you sure to delete this image?"
            showCancelButton
            onConfirm={ () => {
              this.deleteImage();
              this.setState({ show: false });
            } }
            onCancel={ () => {
              this.setState({ show: false });
            } }
            onClose={ () => console.log('close') }
          />

          <TransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear
            transitionAppearTimeout={ 0 }
            transitionEnter={ false }
            transitionLeave={ false }
          >
            <Container fluid>
              <div>
                <PageTitle heading="Destination Management" icon="lnr-apartment icon-gradient bg-tempting-azure" />
              </div>
            </Container>

            <Card>
              <CardBody>
                <Button className="mb-2 mr-2 btn-icon float-right" color="primary" onClick={ () => { this.props.history.push('/wedding/subdestination') } }>
                  Back To List
                </Button>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <Nav justified>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={ classnames({
                        active: this.state.activeTab === '1',
                      }) }
                      onClick={ () => {
                        this.toggle('1');
                      } }
                    >
                      City/Sub Destination Form
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={ classnames({
                        active: this.state.activeTab === '2',
                      }) }
                      onClick={ () => {
                        this.toggle('2');
                      } }
                    >
                      Images
                    </NavLink>
                  </NavItem>

                </Nav>
              </CardHeader>

              <CardBody>
                <TabContent activeTab={ this.state.activeTab }>

                  <TabPane tabId="1">
                    <Formik
                      initialValues={ initialValues }
                      validationSchema={ this.validationSchema }
                      enableReinitialize
                      onSubmit={ async (values) => {
                        await weddingPackage.subDestination.saveSubDestination(values);
                        this.props.history.push('/wedding/subdestination');
                        toast.success('City/Sub destination saved successfully.');
                      } }
                    >

                      {({ errors, touched, isSubmitting }) => (
                        <Card>
                          <CardBody>
                            <Form autoComplete="off">
                              <Row>
                                <Col md="3"> </Col>
                                <Col md="6">
                                  <Card>
                                    <CardHeader>update city/sub Destination</CardHeader>

                                    <CardBody>

                                      <FormGroup>
                                        <TextField label="City/Sub Destination Name" name="subDestinationName" />
                                      </FormGroup>

                                      <FormGroup>
                                        <label> Destination </label>
                                        <Field
                                          name="destinationId"
                                          className={ errors.destinationID && touched.destinationID ? 'is-invalid form-control' : 'form-control' }
                                          as="select"
                                        >
                                          <option value="">--Select--</option>
                                          {this.state.destination.map((destination) => <option key={ destination.value } value={ destination.value }>{destination.text}</option>)}
                                        </Field>

                                        {errors.destinationID && touched.destinationID ? (
                                          <div className="invalid-validation">{errors.destinationID}</div>
                                        ) : null}

                                      </FormGroup>

                                      <FormGroup>
                                        <TextField label="Distance from airport" name="distanceFromAirport" />
                                      </FormGroup>

                                      <FormGroup>
                                        <TextField label="Airport Code" name="airportCode" />
                                      </FormGroup>

                                      <FormGroup>
                                        <TextArea label="Description" name="description" />
                                      </FormGroup>

                                    </CardBody>
                                    <Button
                                      disabled={ isSubmitting }
                                      type="submit"
                                      color="primary"
                                      className="mt-1"
                                    >

                                      {isSubmitting ? 'Processing....' : 'Save'}

                                    </Button>
                                  </Card>
                                </Col>

                              </Row>

                            </Form>
                          </CardBody>
                        </Card>
                      )}
                    </Formik>
                  </TabPane>

                  <TabPane tabId="2">
                    <Button
                      className="btn btn-primary mb-3"
                      color="primary"
                      onClick={ () => this.setState((pS) => ({ showImageUpload: !pS.showImageUpload })) }
                    >
                      Add Images
                    </Button>

                    <div className={ this.state.showImageUpload ? '' : 'd-none' } id="collapseImage">
                      <PhotoWidgetDropzone that={ this } Id={ this.state.subDestinationId } submit={ this.addImage } />
                    </div>

                    <div className="container mt-5">

                      {this.state.chunkedData.map((row, rowIndex) => (
                        <div key={ rowIndex } className="row mb-4">{
                                            row.map((col, colIndex) => (

                                              <div className="col-lg-4 d-none d-lg-block" key={ colIndex + rowIndex }>
                                                <div className="card">
                                                  <div className="card-body">
                                                    {/* {col.imageURL} */}
                                                    <img
                                                                    // src={this.state.baseUrl + col.imageURL}
                                                      src={ col.imageURL }
                                                      className="imagesize hover mb-3"
                                                      alt={ col.altTagText }
                                                    />

                                                    <FormGroup>
                                                      Image Name
                                                      <input
                                                        type="text"
                                                        name="imageName"
                                                        onChange={ (e) => this.handleInputChange(e, rowIndex * 3 + colIndex) }
                                                        value={ col.imageName }
                                                        className="form-control mb-2"
                                                      />
                                                    </FormGroup>

                                                    <FormGroup>
                                                      Alt Tag Image
                                                      <input
                                                        type="text"
                                                        name="altTagText"
                                                        onChange={ (e) => this.handleInputChange(e, rowIndex * 3 + colIndex) }
                                                        value={ col.altTagText }
                                                        className="form-control mb-2"
                                                      />
                                                    </FormGroup>

                                                    <FormGroup>
                                                      Image Order
                                                      <input
                                                        type="number"
                                                        name="imageOrder"
                                                        onChange={ (e) => this.handleInputChange(e, rowIndex * 3 + colIndex) }
                                                        value={ col.imageOrder }
                                                        className="form-control mb-2"
                                                      />
                                                    </FormGroup>

                                                    <button
                                                      type="button"
                                                      className="mt-2 mb-2 mr-2 btn-icon btn btn-success"
                                                      onClick={ () => this.updateImage(rowIndex * 3 + colIndex) }
                                                    ><i className="pe-7s-edit btn-icon-wrapper"> </i>Update
                                                    </button>
                                                    <button
                                                      type="button"
                                                      className="mt-2 mb-2 mr-2 btn-icon btn btn-danger"
                                                      onClick={ () => this.showConfirmationToDelete(col.imageContentID) }
                                                    ><i className="pe-7s-trash btn-icon-wrapper"> </i>Delete
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            ))
                                        }
                        </div>
                      ))}
                    </div>

                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>

          </TransitionGroup>
        </>
      )
    }
}
export default withRouter(EditSubDestination);
