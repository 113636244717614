/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withRouter } from 'react-router-dom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { toast } from 'react-toastify';

import { Button, Card, CardHeader, CardBody } from 'reactstrap';

import hotel from '../../../../api/hotelContent';

class Description extends React.Component {
  // state = Description.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  constructor(props) {
    super(props);
    this.state = {
      initialValues: [],
      isSubmitting: false,
      airportId: 0,
      airportCode: '',
      responseSuccess: false,
    };
  }

  onChangemainDescription = (data, index) => {
    const value = this.state.initialValues[index];
    value.transferDescription = data;
    // this.setState((pS) => ({ mainDescription: pS.initialValues[index] }));
  };

  saveDescription = () => {
    // debugger;
    this.setState({ isSubmitting: true });
    const description = this.state.initialValues;
    for (let i = 0; i < description.length; i++) {
      description[i].hotelID = null;
      description[i].airportCodeID = this.state.airportId;
    }
    hotel.transferDescription
      .saveTrasferDescription(description)
      .then((res) => {
        this.setState({ isSubmitting: false });
        toast.success(res);
      });
  };

  componentDidMount() {
    // const { id } = this.props.match.params;
    const airportCodes = window.localStorage.getItem('airportCodes');
    // console.log(airportCodes);
    hotel.transferDescription
      .getTransferDescription(airportCodes)
      .then((response) => {
        this.setState({
          responseSuccess: true,
          airportId: response.airportCodeID,
          airportCode: response.airportCode,
        });
        this.setState({ initialValues: response.hotelTransferDescriptionList });
      });
  }

  render() {
    return (
      <Card className="main-card card">
        <CardHeader> Description </CardHeader>
        {this.state.airportId === 0 && this.state.responseSuccess && (
          <div className="m-3 alert alert-info fade show" role="alert">
            The airport code is not set for this hotel.
          </div>
        )}
        {this.state.airportId > 0 && this.state.responseSuccess && (
          <div>
            <div className="m-2 alert alert-info fade show" role="alert">
              Transfer Descriptions for {this.state.airportCode}.
            </div>

            <CardBody>
              {this.state.initialValues.map((value, index) => (
                <div key={ index } className="mt-2">
                  <h6 className="mt-3">
                    {index + 1}) {value.transferTypeName}{' '}
                  </h6>
                  <CKEditor
                    editor={ ClassicEditor }
                    data={ value.transferDescription }
                    onChange={ (event, editor) => {
                      const data = editor.getData();
                      this.onChangemainDescription(data, index);
                    } }
                  />
                </div>
              ))}
            </CardBody>
            <div className="row ml-2">
              <div className="col-md-3">
                <Button
                  color="primary"
                  disabled={ this.state.isSubmitting }
                  className="mt-1"
                  onClick={ () => {
                    this.saveDescription();
                  } }
                >
                  {' '}
                  {this.state.isSubmitting
                    ? 'Processing....'
                    : 'Save Description'}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Card>
    );
  }
}

export default withRouter(Description);
