/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  FormGroup,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';

import { toast } from 'react-toastify';

import SweetAlert from 'sweetalert-react';
import { Loader } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';

import { Formik, Form } from 'formik';
import weddingPackage from '../../../api/weddingPackage';
import PageTitle from '../../../Layout/AppMain/PageTitle';

class SubDestination extends React.Component {
  columns = [
    {
      title: 'City/Sub destination',
      key: 'SubDestinationName',
      isSortable: true,
    },
    { title: 'DestinationName', key: 'DestinationName', isSortable: true },
    { title: 'AirportCode', key: 'AirportCode', isSortable: true },
    { title: 'Status', key: 'isActive', isSortable: true },
    { title: 'Action' },
  ];

  obj = [];

  queryObject = {};

  deleteId;

  constructor(props) {
    super(props);
    this.queryObject.IsSortAsc = true;
    this.queryObject.PageNumber = 1;
    this.queryObject.PageSize = 10;
    this.queryObject.SortBy = '';
    this.queryObject.destinationId = 0;
    this.queryObject.Search = this.obj;

    this.state = {
      subDestination: [],
      statustext: '',
      searchinitialValues: { destinationId: '0' },
      destination: [],
    };
  }

  async componentDidMount() {
    await this.loadDestination();
    await this.loadSubDestination();
  }

  async loadDestination() {
    const destination = await weddingPackage.destination.getDestination();
    this.setState({ destination });
  }

  handleSearch = (e) => {
    this.queryObject.PageNumber = 1;
    const exist = this.obj.some((x) => x.columnSearchName === e.target.name);
    const length = [...e.target.value];

    if (!exist) {
      this.obj.push({
        columnSearchName: e.target.name,
        columnSearchValue: e.target.value,
      });
    } else {
      const index = this.obj.findIndex(
        (p) => p.columnSearchName === e.target.name
      );
      this.obj[index].columnSearchValue = e.target.value;
    }

    if (length.length >= 3 || length.length === 0) {
      this.loadSubDestination();
    } else if (e.target.name === 'AirportCode') {
      this.loadSubDestination();
    }
  };

  async loadSubDestination() {
    this.setState({ active: true });
    const result = await weddingPackage.subDestination.getSubDestinationList(
      this.queryObject
    );
    this.setState({ subDestination: result.data, active: false });
    this.queryObject.PageNumber = result.pagination.currentPage;
    this.queryObject.PageSize = result.pagination.itemsPerPage;
    this.queryObject.totalItems = result.pagination.totalItems;
    this.setState({
      totalPages: result.pagination.totalPages,
      // SortBy: this.queryObject.SortBy,
      // IsSortAsc: this.queryObject.isSortAsc
    });
  }

  handlePageClick = async (data) => {
    const { selected } = data;
    this.queryObject.PageNumber = selected + 1;
    await this.loadSubDestination();
  };

  showConfirmationToDelete = (id) => {
    this.deleteId = id;
    this.setState({
      statustext: 'Are you sure to delete the City/Sub destination?',
    });
    this.setState({ show: true });
  };

  deleteSubDestination() {
    weddingPackage.subDestination
      .deleteSubDestination(this.deleteId)
      .then(() => {
        toast.success(
          'The City/Sub destination deleted successfully successfully.'
        );
        this.setState({ show: false });
        this.loadSubDestination();
      });
  }

  updateStatus=(id) => {
    weddingPackage.subDestination.updateStatus(id).then((response) => {
      toast.success(
        response
      );
      
      this.loadSubDestination();
    });
  }

  render() {
    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text={ this.state.statustext }
          showCancelButton
          onConfirm={ () => {
            this.deleteSubDestination();
          } }
          onCancel={ () => {
            this.setState({ show: false });
          } }
          onClose={ () => {} } // eslint-disable-line no-console
        />

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <div>
              <PageTitle
                heading="City/Sub Destination Management"
                icon="pe-7s-map-2 icon-gradient bg-tempting-azure"
              />
            </div>

            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Filter</CardTitle>

                <Formik
                  initialValues={ this.state.searchinitialValues }
                  enableReinitialize
                  onSubmit={ (values) => {
                    this.queryObject.destinationId = values.destinationId;
                    this.loadSubDestination();
                  } }
                >
                  {({
                    values,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={ handleSubmit }>
                      <div className="form-row ">
                        <Col md={ 3 }>
                          <FormGroup>
                            <label>Destination</label>
                            <select
                              onChange={ (e) => {
                                setFieldValue('destinationId', e.target.value);
                                this.setState({
                                  searchinitialValues: {
                                    destinationId: e.target.value,
                                  },
                                });
                              } }
                              name="destinationId"
                              className="form-control"
                              value={ values.destinationId }
                            >
                              <option value="0">--Select--</option>
                              {this.state.destination.map((d) => (
                                <option key={ d.value } value={ d.value }>
                                  {d.text}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </div>

                      <Button
                        className="mb-2 mr-2 btn-icon float-left"
                        color="primary"
                      >
                        Search
                      </Button>

                      <Button
                        className="mb-2 mr-2 btn-icon float-left"
                        onClick={ (e) => {
                          e.preventDefault();
                          this.setState({
                            searchinitialValues: { destinationId: '0' },
                          });
                          this.queryObject.destinationId = 0;
                          this.searchSubmit();
                        } }
                        color="primary"
                      >
                        Clear
                      </Button>
                      {/* {<pre>{JSON.stringify({  values,errors }, null, 4)}</pre> } */}
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>

            <LoadingOverlay
              tag="div"
              active={ this.state.active }
              styles={ {
                overlay: (base) => ({
                  ...base,
                  background: '#fff',
                  opacity: 0.5,
                }),
              } }
              spinner={ <Loader active /> }
            >
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>City/Subdestination List</CardTitle>
                  <Button
                    className="mb-2 mr-2 btn-icon float-right"
                    color="primary"
                    onClick={ () => {
                      this.props.history.push('/wedding/create-subdestination');
                    } }
                  >
                    Add city/subDestination
                  </Button>

                  <Table
                    size="md"
                    responsive
                    striped
                    bordered
                    hover
                    className="mb-3"
                  >
                    <thead>
                      <tr>
                        {this.columns.map((value, index) => (
                          <th key={ index }>{value.title}</th>
                        ))}
                      </tr>
                      <tr>
                        <th>
                          <input
                            type="text"
                            name="SubDestinationName"
                            onKeyUp={ this.handleSearch }
                            className="searchtext form-control"
                          />{' '}
                        </th>
                        <th> </th>
                        <th>
                          <input
                            type="text"
                            name="AirportCode"
                            onKeyUp={ this.handleSearch }
                            className="searchtext form-control"
                          />{' '}
                        </th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.subDestination.length > 0
                        && this.state.subDestination.map((value, index) => (
                          <tr key={ index }>
                            <td>{value.subDestinationName}</td>
                            <td>{value.destinationName}</td>
                            <td>{value.airportCode}</td>
                            <td>
                              {!value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-danger">
                                  {' '}
                                  Disabled{' '}
                                </div>
                              )}
                              {value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-success">
                                  {' '}
                                  Enabled{' '}
                                </div>
                              )}
                            </td>
                            <td>
                              <UncontrolledButtonDropdown>
                                <DropdownToggle
                                  caret
                                  className="mb-2 mr-2"
                                  color="primary"
                                >
                                  Action
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                                  <DropdownItem
                                    onClick={ () => this.props.history.push(
                                      `/wedding/edit-subdestination/${value.subDestinationID}`
                                    ) }
                                  >
                                    Manage
                                  </DropdownItem>

                                  <DropdownItem divider />

                                  <DropdownItem
                                    onClick={ () => this.showConfirmationToDelete(
                                      value.subDestinationID,
                                      true
                                    ) }
                                  >
                                    Delete
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={ () => this.updateStatus(value.subDestinationID) }
                                  >
                                    {value.isActive ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>

                  {this.state.subDestination.length > 0 && (
                    <nav aria-label="Page navigation">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={ this.state.totalPages }
                        marginPagesDisplayed={ 2 }
                        pageRangeDisplayed={ 5 }
                        onPageChange={ this.handlePageClick }
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        activeClassName="active"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                      />
                    </nav>
                  )}
                </CardBody>
              </Card>
            </LoadingOverlay>
          </Container>
        </TransitionGroup>
      </>
    );
  }
}

export default withRouter(SubDestination);
