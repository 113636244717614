/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Formik, Form } from 'formik';

import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
} from 'reactstrap';

import { ToastContainer } from 'react-toastify';
import { TransitionGroup } from 'react-transition-group';

import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { TextField } from '../../../common/Form/TextInput';
import { TextArea } from '../../../common/Form/TextArea';
import { SelectInput } from '../../../common/Form/SelectInput';
import { CustomDatePicker } from '../../../common/Form/DatePicker';
import { categoryOption } from '../../../common/options/categoryoption';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import { formatDate } from 'common/utils/dateUtils';

class UserFormik extends React.Component {
  // state = UserFormik.getDefaultState();

  static getDefaultState() {
    return {
      ...ToastContainer.defaultProps,
      transition: 'bounce',
      type: 'success',
      disableAutoClose: false,
    };
  }

  validationSchema = Yup.object({
    firstname: Yup.string().required('The first name is required.'),
    lastname: Yup.string().required('The last name is required.'),
    email: Yup.string().required('The email is required.'),
    phonenumber: Yup.string().required('The phone number is required.'),
  });

  // componentDidMount() {
  //   user.authUsers.rolelist().then((res) => {
  //     this.setState({ roles: res });
  //   })
  // }

  // handleCheckbox = (e) => {
  //   const chk = e.target;
  //   if (chk.checked) {
  //     this.rolearr.push(chk.value);
  //     this.setState({ checkedroles: this.rolearr });
  //   } else {
  //     const index = this.rolearr.indexOf(chk.value)
  //     if (index !== -1) {
  //       this.rolearr.splice(index, 1);
  //       this.setState({ checkedroles: this.rolearr });
  //     }
  //   }
  // }

  backToList = (event) => {
    event.preventDefault();
    this.props.history.push('/user/guest-list-new');
  };

  render() {
    const { loading } = this.state;
    return (
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }
      >
        <Container fluid>
          <div>
            <PageTitle
              heading="New Users Registration"
              subheading="PTID Admin users registration ."
              icon="pe-7s-user icon-gradient bg-tempting-azure"
            />
          </div>
          <Row>
            <Col md="6">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Admin User</CardTitle>
                  <Formik
                    validationSchema={ this.validationSchema }
                    enableReinitialize
                    initialValues={ {
                      firstname: '',
                      lastname: '',
                      email: '',
                      phonenumber: '',
                      comment: '',
                      category: '',
                      eventdatatime: null,
                    } }
                    onSubmit={ (values) => {
                      const date = new Date(values.eventdatatime);
                      console.log(formatDate(date, 'MM/dd/yyyy'));
                      console.log(values);
                    } }
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={ handleSubmit }>
                        <FormGroup>
                          <TextField label="First Name" name="firstname" />
                        </FormGroup>

                        <FormGroup>
                          <TextField
                            label="Last Name"
                            name="lastname"
                            placeholder="Last Name"
                          />
                        </FormGroup>

                        <FormGroup>
                          <TextField
                            label="Email"
                            name="email"
                            placeholder="Email"
                          />
                        </FormGroup>

                        <FormGroup>
                          <TextField
                            label="Phone Number"
                            name="phonenumber"
                            placeholder="Phone Number"
                          />
                        </FormGroup>

                        <FormGroup>
                          <SelectInput
                            options={ categoryOption }
                            label="Category"
                            name="category"
                            placeholder="Category"
                          />
                        </FormGroup>

                        <FormGroup>
                          <CustomDatePicker
                            label="DateTime"
                            name="eventdatatime"
                            timeCaption="time"
                            dataFormat="MMMM d, yyyy h:mm aa"
                            placeholder="Enterdate"
                          />
                        </FormGroup>

                        <FormGroup>
                          <TextArea
                            rows={ 5 }
                            label="Comment"
                            name="comment"
                            placeholder="Comment"
                          />
                        </FormGroup>

                        <Button
                          disabled={ !!loading }
                          color="primary"
                          className="mt-1"
                        >
                          {!loading ? (
                            <span>Submit</span>
                          ) : (
                            <span>Please wait...</span>
                          )}
                        </Button>
                        <a
                          className="mt-1 ml-2 btn btn-outline-info"
                          href="#"
                          onClick={ this.backToList }
                        >
                          Back to the list
                        </a>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </TransitionGroup>
    );
  }
}
export default withRouter(UserFormik);
