/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody, FormGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import Loader from 'react-loaders';
import salesLead from 'api/salesLead';
import { NotesTimeline } from '../Notes';
import AccountDetails from './_AccountDetails';
import PTID from './_PTID';
import DuplicateEmailList from './_DuplicateList';
import WeddingDetails from './_WeddingDetails';

import Status from './Status';


import Select from 'react-select';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useWeddingDateSlice } from '../../WeddingDate/slice';

import { selectConfirmDetails } from '../../WeddingDate/slice/selectors';
import { selectAuthUsers, selectIsUpdatingAssignLead, selectLeadInfoCRM } from '../../slice/selectors';
import { useSalesLeadSlice } from '../../slice';
import { AdminRoles } from 'auth/Roles';
const URL = process.env.REACT_APP_CRMLINK!.replace('Opportunities', 'Leads');

const ManageSalesLead = props => {
  const { actions } = useWeddingDateSlice();
  const { actions: salesLeadActions } = useSalesLeadSlice();
  const dispatch = useAppDispatch();
  const id = props.match.params.id;
  const [loading, setLoading] = useState(false);
  const accountDetails = useAppSelector(selectConfirmDetails);
  const authUsers = useAppSelector(selectAuthUsers);
  const { actions: salesLeadSliceActions } = useSalesLeadSlice();
  const crmLeadDetails = useAppSelector(selectLeadInfoCRM);

  const [assignedSalesAgentListOptions, setAssignedSalesAgentListOptions] = useState<{ label: string, value: string }[]>(null);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const assignedAgentEmail = crmLeadDetails?.agentEmail?.toLowerCase();
  const [crmAssignedAgentEmail, setCrmAssignedAgentEmail] = useState<string>('');
  const isUpdatingAssignLead = useAppSelector(selectIsUpdatingAssignLead);

  const [salesLeadDetails, setSalesLeadDetails] = useState({
    salesLeadId: null,
    city: null,
    destination: null,
    resort: null,
    venue: null,
    weddingPackage: null,
    phoneNumber: '',
    statusId: null,
    email: null,
    name: null,
    dateLastVisited: null,
    pageLastVisited: null,
  });
  const [lastPageVisit, setLastPageVisit] = useState({
    visitedDate: null,
    pageName: null,
  });
  const getSalesLeadDetails = async () => {
    if (id) {
      setDetailsVisible(false);

      const salesLeadDetails =
        await salesLead.salesLeadDetails.getSalesLeadDetailsByUserId(id);

      setSalesLeadDetails(prevState => ({
        ...prevState,
        statusId: salesLeadDetails.statusId,
        salesLeadId: salesLeadDetails.salesLeadId,
        city: salesLeadDetails.cityName,
        destination: salesLeadDetails.countryName,
        resort: salesLeadDetails.resort,
        venue: salesLeadDetails.venue,
        weddingPackage: salesLeadDetails.weddingPackage,
        email: salesLeadDetails.email,
        phoneNumber: salesLeadDetails.phoneNumber,
        name:
          `${salesLeadDetails.firstName || ''} ${salesLeadDetails.lastName || ''
            }`.trim() || null,
      }));
      const userLastPageVisit =
        await salesLead.salesLeadDetails.getUserLastPageVisitByUserId([id]);
      if (userLastPageVisit.value)
        setLastPageVisit(
          userLastPageVisit.value[userLastPageVisit.value.length - 1],
        );
      const resortId = salesLeadDetails.resortID;
      dispatch(actions.getResortVenueById(resortId));
      dispatch(actions.getResortPackageById(resortId));
      dispatch(
        actions.setDetailsPageStatus({
          userId: id,
          status: false,
          id: salesLeadDetails.salesLeadId,
        }),
      );
      setDetailsVisible(true);
    }
  };

  const { crmLeadId, weddingId: weddingDetailsId } = accountDetails;
  useEffect(() => {
    if (!authUsers?.length) {
      dispatch(salesLeadActions.loadAuthUsers());
    }
  }, [])


  useEffect(() => {
    if (crmLeadId) {
      dispatch(salesLeadSliceActions.loadLeadInfoCRM(crmLeadId));
    }
  }, [crmLeadId]);

  useEffect(() => {
    const isValidRole = (role: string) => role === AdminRoles.SalesSpecialist;
    if (authUsers?.length) {
      const salesAgentList = authUsers.filter(
        userItem =>
          userItem.isActive && userItem.checkedroles.some(isValidRole),
      ).map(s => ({
        label: `${s.firstName} ${s.lastName}`,
        value: s.email,
      }));
      setAssignedSalesAgentListOptions([{ value: '', label: '--Unassigned--' }, ...salesAgentList]);
    }
  }, [authUsers]);

  const refresh = async () => {
    // dispatch(actions.getUserDetails(id));
    await getSalesLeadDetails();
  };

  const assignLead = (selectedAgent: { label: string, value: string | null }) => {
    if (!selectedAgent.value) {
      //null/no value = unassigned
      dispatch(salesLeadActions.assignCRMLead({
        assignedAgentId: null,
        email: null,
        leadId: crmLeadId,
        agentName: null,
        salesLeadId: salesLeadDetails.salesLeadId
      }));
    }
    else {
      const selectedSalesAgentDetails = authUsers.find(u => u.email?.toLowerCase() === selectedAgent.value.toLowerCase());
      if (!selectedSalesAgentDetails?.destifyUserId) {
        toast.error("Unable to proceed. Selected agent is not properly setup in the system. Please contact the dev team.");
        return;
      }
      dispatch(salesLeadActions.assignCRMLead({
        assignedAgentId: selectedSalesAgentDetails.destifyUserId,
        email: selectedSalesAgentDetails.email,
        leadId: crmLeadId,
        agentName: `${selectedSalesAgentDetails.firstName} ${selectedSalesAgentDetails.lastName}`,
        salesLeadId: salesLeadDetails.salesLeadId
      }));
    }
  };

  useEffect(() => {
    dispatch(actions.resortListing());
    dispatch(actions.loadWeddingDateStatusList());
    const fetchSalesLeadData = async () => {
      try {
        await refresh();
      } catch (error) {
        console.error(error);
        toast.error(
          <div>
            <p>
              <b>Unable to fully load record details</b>
            </p>
            <p>
              Due to the volatile nature of test data, this error is to be
              expected in the staging environment.
            </p>
            <p>
              If you're seeing this in production, please try again or contact
              the dev team.
            </p>
          </div>,
          {
            autoClose: false,
            position: 'top-center',
            onClose: () => {
              setLoading(false);
              setDetailsVisible(true);
            },
          },
        );
      }
    };
    fetchSalesLeadData();
  }, []);

  useEffect(() => {
    setCrmAssignedAgentEmail(assignedAgentEmail || '');
  }, [assignedAgentEmail]);

  const { name, email, phoneNumber } = salesLeadDetails;
  const shouldShowAssignedSalesAgentDropDownLoader = isUpdatingAssignLead || !(crmLeadDetails && assignedSalesAgentListOptions);

  return (
    <>
      {!detailsVisible && (
        <Loader innerClassName="text-center" active={true} type="ball-pulse" />
      )}
      <h1>Manage Page</h1>
      <button
        type="button"
        className="mb-3 ml-auto btn-pill btn btn-outline-primary"
        style={{ display: 'flex' }}
      >
        <Link to={'/salesleads/guest-list'}>Back to the list</Link>
      </button>
      {id ? (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            opacity: detailsVisible ? '1' : '.5',
          }}
        >
          <Col md={12} lg={8}>
            <Card className="main-card mb-3">
              <CardHeader>Manage Leads</CardHeader>
              <CardBody>
                {accountDetails ? (
                  <>
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Col xs={12} className="col-md">
                        <label className="list-details">
                          Name :{' '}
                          <div className="list-details-value">
                            {name ? name : 'N/A'}
                          </div>
                        </label>
                        <label className="list-details">
                          Email :{' '}
                          <div className="list-details-value">
                            {email ? email : 'N/A'}
                          </div>
                        </label>
                        <label className="list-details">
                          Phone :{' '}
                          <div className="list-details-value">
                            {phoneNumber ? phoneNumber : 'N/A'}
                          </div>
                        </label>
                        <label className="list-details">
                          <a
                            href={`${URL}${crmLeadId}`}
                            target="_blank"
                            style={{
                              pointerEvents: crmLeadId ? 'all' : 'none',
                            }}
                            rel="noopener noreferrer"
                          >
                            CRM Lead
                          </a>
                        </label>
                      </Col>
                      <Col xs={8} md={4}>
                        <label
                          className="list-details"
                          style={{ marginTop: '1rem' }}
                        >
                          Assigned Agent:{' '}
                        </label>

                        {!shouldShowAssignedSalesAgentDropDownLoader ? (
                          <FormGroup>
                            <Select
                              name="AssignedSalesLead"
                              onChange={item => {
                                assignLead(item);
                              }}
                              value={assignedSalesAgentListOptions?.find(l => l.value === crmAssignedAgentEmail)}
                              options={assignedSalesAgentListOptions}
                            />
                          </FormGroup>) : (
                          <Loader
                            active
                            type="ball-pulse" />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4}>
                        <DuplicateEmailList crmLeadDetails={crmLeadDetails} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={8} md={4}>
                        <Status
                          statusId={salesLeadDetails.statusId}
                          salesLeadId={salesLeadDetails.salesLeadId}
                        />
                      </Col>
                    </Row>

                    {loading ? (
                      <Loader active={loading} type="ball-pulse" />
                    ) : (
                      <div>
                        <AccountDetails accountDetails={accountDetails} />
                        <PTID
                          salesLeadDetails={salesLeadDetails}
                          lastPageVisit={lastPageVisit}
                          weddingDetailsId={weddingDetailsId}
                        />
                        <WeddingDetails key={id} />
                      </div>
                    )}
                  </>
                ) : null}
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={4}>
            <NotesTimeline userId={id} />
          </Col>
        </Row>
      ) : (
        <p>Record not found...</p>
      )}
    </>
  );
};

export default withRouter(ManageSalesLead);
