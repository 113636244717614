import React, { createRef } from 'react';
import * as Yup from 'yup';
import SweetAlert from 'sweetalert-react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    CardFooter,
    ListGroup,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ListGroupItem,
    Form,
    UncontrolledTooltip,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ReactTable from 'react-table';
import PageTitle from 'Layout/AppMain/PageTitle';
import { format } from 'date-fns-tz';
import { ODataDateRangePicker } from 'common/Form/ODataDateRangePicker';
import { toast } from 'react-toastify';
import { ODataTableHelper } from 'common/OData/ODataTableHelper';
import { LegacyLiveBookingODataEntities } from 'api/OData/LegacyLiveBooking/ODataEntities';
import { LegacyLiveBookingODataApiClient } from 'api/OData/LegacyLiveBookingODataApiClient';
import ODataDataTableComponent, {
    IODataDataTableComponentState,
} from 'common/OData/components/BaseODataDataTableComponent';
import usersApi from 'api/user';
import { convertEnum } from 'utils/enumHelper';
import { ClientAccountsHeaderTileSummaryCard } from './ClientAccountsHeaderTileSummaryCard';
import IBaseCrmRoomDetails from './models/IBaseCrmRoomDetails';
import { ClientAccountsUserRole } from './models/ClientAccountsUserRoleEnum';
import IUserRoomDetailsRecord from './models/IUserRoomDetailsRecord';
import { RoomRoles } from './models/RoomRolesEnum';
import { ClientRoomDetailsCard } from './ClientRoomDetailsCard';
import { SelectInput } from 'common/Form/SelectInput';
import { TextField } from 'common/Form/TextInput';
import { Formik, FieldArray } from 'formik';
import { exportToCSV } from 'common/Export/ExportToExcel';
import { IoIosFunnel } from 'react-icons/io';
import { ILegacyLiveBookingOasisUserDetails } from 'api/OData/LegacyLiveBooking/ODataModels/ODataUserDetailsModel';
import { getDebouncedFunc } from "utils/utils"
import ImpersonationModal from 'common/Modals/ImpersonationModal';
import { UserApi } from 'api/adminApi/api/user-api';
import { GuestUserRoleType } from 'api/adminApi/model/guest-user-role-type';

const debounceFun = getDebouncedFunc()

interface IClientAccountsPageState extends IODataDataTableComponentState {
    totalDataCount: number;
    tableData: ILegacyLiveBookingOasisUserDetails[];
    pages: number | null;
    loading: boolean;
    dataTablePageSize: number;
    dataTableCurrentPage: number;
    dataTableSorting: Array<{ id: string; desc: boolean }>;
    incomingFilter: { id: keyof ILegacyLiveBookingOasisUserDetails; value: any };
    activeClientAccountRecord: any;
    isOpen?: boolean;

    guestRoleTotal: number;
    groupLeaderRoleTotal: number;
    salesLeadsTotal: number;

    associatedCrmRoomDetails: Array<IBaseCrmRoomDetails>;

    userRoleChangeModal: {
        show: boolean;
        messageText: string | null;
        ptidUserId?: string;
        role?: ClientAccountsUserRole;
    };

    showImpersonationModal: boolean;
    generatedImpersonationLink: string | null;

    displayUserRoomDetailsModal: boolean;
    userRoomDetails: IUserRoomDetailsRecord[] | null;
    activeRoomDetails: IUserRoomDetailsRecord | null;

    //needs cleanup. ran out of time.
    //this is for the manage popup
    detailsPage: {
        /**
         * this is the PtidUserId from legacy dashboard
         */
        id: string;
        crmLeadId: string | null;
        agentEmail: string | null;
        firstName: string | null;
        lastName: string | null;
        name: string | null;
        address: string | null;
        email: string | null;
        phone: string | null;
        phoneNo: string | null;
        status: boolean | null;
        communicationStyle: string | null;
        communicationLevel: string | null;
    } | null;

    //this is used for the form submission data, when attempting to add more rooms to a user
    newUserRoomAssociationsModel: {
        userId: string;
        userEmail: string;
        newRooms: { roomRole: RoomRoles | null; roomId: string | null }[];
    } | null;
}



const formatDate = (value?: string) => {
    if (!value) {
        return <span>N/A</span>;
    }
    return <span>{format(new Date(value), 'MM/dd/yyyy hh:mm:ss a')}</span>;
};

export default class ClientAccountsListPage extends ODataDataTableComponent<
    ILegacyLiveBookingOasisUserDetails,
    IClientAccountsPageState
> {
    reactTable: React.RefObject<any> = createRef();
    odataApiClient = new LegacyLiveBookingODataApiClient();
    csvExportFileNamePrefix = 'PTID-Client-User-Accounts';
    odataEntityType = LegacyLiveBookingODataEntities.OasisUserDetails;

    private legacyLiveBookingOdataApiClient =
        new LegacyLiveBookingODataApiClient();

    private adminApiUserManagementClient = new UserApi();

    private static AddRoomsValidationSchema = Yup.object({
        associatedRoomID: Yup.array().of(
            Yup.object().shape({
                roomId: Yup.string().required('Room ID is required.'),
                roomRole: Yup.string().required('Room Role is required.'),
            }),
        ),
    });

    /**Generate options for the role dropdown in the table header's user role column, used for filtering */
    private static GetUserRoleOptions = () =>
        [
            { value: '', text: 'All' },
            ...Object.keys(ClientAccountsUserRole).map(k => ({
                value: k,
                text: ClientAccountsUserRole[k],
            })),
        ].map(i => (
            <option key={i.value} value={i.value}>
                {i.text}
            </option>
        ));

    private static RoomRoleOptions = [
        ...Object.keys(RoomRoles).map(k => ({ value: k, text: RoomRoles[k] })),
    ];

    state: IClientAccountsPageState = {
        totalDataCount: 0,
        tableData: [],
        pages: null,
        loading: true,
        dataTablePageSize: 10,
        dataTableCurrentPage: 0,
        dataTableSorting: [],
        incomingFilter: null,
        filters: [
            { name: 'CreatedDate', modifier: null, value: null },
            { name: 'LastActivityTime', modifier: null, value: null },
            { name: 'UserRoles', value: null, modifier: 'in list' },
        ],
        activeClientAccountRecord: null,

        guestRoleTotal: 0,
        groupLeaderRoleTotal: 0,
        salesLeadsTotal: 0,

        associatedCrmRoomDetails: [],

        userRoleChangeModal: {
            show: false,
            messageText: '',
        },

        showImpersonationModal: false,
        generatedImpersonationLink: null,

        displayUserRoomDetailsModal: false,
        userRoomDetails: null,
        activeRoomDetails: null,

        //viewmodel/props for the manage popup
        detailsPage: null,

        //null on load, but when we click view on a user record
        //we'll initially populate this in fetchUserRoomsInfo
        //with a dummy empty "ViewModel" object
        //for the formik form
        newUserRoomAssociationsModel: null,
        isOpen: false,
    };

    /**
     * Overriding the base implementation, because the FullName column
     * from the legacy dashboard is a "virtual/soft" column, that's a combo
     * of FirstName and LastName. So we have to account for the possibility
     * of column filtering on the FullName column, which has to be handled differently
     * @param scope
     */
    override handleExportToCSV = async (
        scope: 'all' | 'currentPage' | 'filtered',
    ) => {
        let csvData = [];
        if (scope === 'currentPage') {
            csvData = [...this.reactTable.current?.resolvedData];
        } else {
            let queryStringArgs = '';
            if (scope === 'filtered') {
                const queryOptions = {
                    currentPage: this.reactTable.current?.state.page,
                    dataTableSort: this.reactTable.current?.state.sorted.map(
                        ({ id, desc }) => ({
                            columnName: id,
                            sortOrder: desc ? 'desc' : 'asc',
                        }),
                    ),
                    dataTableFilters: this.reactTable.current?.state.filtered.map(
                        ({ id, value }) => ({ columnName: id, value }),
                    ),
                    filters: this.state.filters.filter(f => f.value !== null),
                };

                //handle fullname differently
                const fullNameFilter = queryOptions.dataTableFilters?.find(
                    f => f.columnName.toLowerCase() === 'fullname',
                );
                let freeFormColumnFilterArgs = '';
                if (fullNameFilter) {
                    const { value } = fullNameFilter;
                    queryOptions.dataTableFilters = queryOptions.dataTableFilters.filter(
                        f => f.columnName.toLowerCase() !== 'fullname',
                    ); //remove fullname

                    freeFormColumnFilterArgs = `(contains(concat( concat(FirstName,' '),LastName),'${value}'))`;
                }

                queryStringArgs = ODataTableHelper.constructODataQueryArgs(
                    null,
                    queryOptions.currentPage,
                    queryOptions.dataTableFilters,
                    queryOptions.dataTableSort,
                    queryOptions.filters,
                    true,
                    freeFormColumnFilterArgs,
                );
            }
            const { data } =
                await this.odataApiClient.getODataListResponseWithCount<ILegacyLiveBookingOasisUserDetails>(
                    `${this.odataEntityType}?${queryStringArgs}`,
                );
            csvData = [...data];
        }

        csvData = csvData.map((row: ILegacyLiveBookingOasisUserDetails & { UserRole?: string | null }) => {
            //need to flatten UserRoles array, when exporting to csv
            const cleanedRow = { ...row, UserRoles: Array.isArray(row.UserRoles) ? row.UserRoles.join(",") : '' };

            //additionally UserRole was supplanted by UserRoles in https://github.com/destify/Destify.LegacyLiveBooking/pull/506
            //but not removed from the api for backwards compatibility. so strip it out, on export
            if ('UserRole' in cleanedRow) {
                delete cleanedRow.UserRole;
            }
            return cleanedRow;
        })

        exportToCSV(
            {
                dataArray: csvData,
                fileName: `${this.csvExportFileNamePrefix}-${new Date().toISOString()}`,
            },
            { forceQuotes: true },
        );
    };

    //used to populate the gridview with data
    fetchData = async reactTableState => {
        if (!reactTableState) return;
        this.setState({ loading: true });

        this.setState({
            dataTablePageSize: reactTableState.pageSize,
            dataTableCurrentPage: reactTableState.page,
            dataTableSorting: [...reactTableState.sorted],
        });

        const queryOptions = {
            pageSize: reactTableState.pageSize,
            currentPage: reactTableState.page,
            dataTableSort: reactTableState.sorted.map(({ id, desc }) => ({
                columnName: id,
                sortOrder: desc ? 'desc' : 'asc',
            })),
            dataTableFilters: reactTableState.filtered.map(({ id, value }) => ({
                columnName: id,
                value,
            })),
            filters: this.state.filters.filter(f => f.value !== null),
        };

        //we need to treat the fullname column differently when searching, as its a computed filed in the Odata endpoint
        const fullNameFilter = queryOptions.dataTableFilters?.find(
            f => f.columnName.toLowerCase() === 'fullname',
        );
        let freeFormColumnFilterArgs = '';
        if (fullNameFilter) {
            const { value } = fullNameFilter;
            queryOptions.dataTableFilters = queryOptions.dataTableFilters.filter(
                f => f.columnName.toLowerCase() !== 'fullname',
            ); //remove fullname

            freeFormColumnFilterArgs = `(contains(concat( concat(FirstName,' '),LastName),'${value}'))`;
        }
        if (queryOptions.dataTableSort.find(f => f.columnName.toLowerCase() === 'fullname'))
            queryOptions.dataTableSort.find(f => f.columnName.toLowerCase() === 'fullname').columnName = 'FirstName';
        const queryStringArgs = ODataTableHelper.constructODataQueryArgs(
            queryOptions.pageSize,
            queryOptions.currentPage,
            queryOptions.dataTableFilters,
            queryOptions.dataTableSort,
            queryOptions.filters,
            true,
            freeFormColumnFilterArgs,
        );

        const { data, count } =
            await this.legacyLiveBookingOdataApiClient.getODataListResponseWithCount<ILegacyLiveBookingOasisUserDetails>(
                `OasisUserDetails?${queryStringArgs}`,
            );

        //now fetch room details
        let associatedCrmRoomDetails = [];
        if (count > 0) {
            try {
                associatedCrmRoomDetails =
                    await usersApi.guestUsers.getClientAccountsRoomDetails(
                        data.map(d => d.Id),
                    );
            } catch {
                //do nothing
                // toast.info(
                //   'Attention: none of the results in the gridview have any rooms associated with their account',
                // );

            }
        }

        const totalDataCount = count;
        this.setState({
            tableData: data,
            pages: Math.ceil(totalDataCount / reactTableState.pageSize),
            loading: false,
            totalDataCount,
            associatedCrmRoomDetails,
        });
    };

    async componentDidMount() {
        const [
            { count: salesLeadsTotal },
            { count: groupLeaderRoleTotal },
            { count: guestRoleTotal },
        ] = await Promise.all([
            this.legacyLiveBookingOdataApiClient.getODataListResponseWithCount<ILegacyLiveBookingOasisUserDetails>(
                `OasisUserDetails?$filter=UserRoles/any(d:contains(d, 'SalesLead'))&$count=true&$top=0`,
            ),
            this.legacyLiveBookingOdataApiClient.getODataListResponseWithCount<ILegacyLiveBookingOasisUserDetails>(
                `OasisUserDetails?$filter=UserRoles/any(d:contains(d, 'GroupLeader'))&$count=true&$top=0`,
            ),
            this.legacyLiveBookingOdataApiClient.getODataListResponseWithCount<ILegacyLiveBookingOasisUserDetails>(
                `OasisUserDetails?$filter=UserRoles/any(d:contains(d, 'Guest'))&$count=true&$top=0`,
            ),
        ]);

        this.setState({
            guestRoleTotal,
            groupLeaderRoleTotal,
            salesLeadsTotal,
        });
        if (localStorage.getItem('searchValue')) {
            const ptiUserIdFilter = JSON.parse(localStorage.getItem('searchValue'));
            this.setState({
                incomingFilter: {
                    id: 'Id',
                    value: ptiUserIdFilter.PtidUserId,
                },
            });
            localStorage.removeItem('searchValue');
        }
    }

    showSelectedRoomDetails = (room: IUserRoomDetailsRecord) => {
        this.setState({ activeRoomDetails: room });
    };

    openFilters = toggle => {
        this.setState({ isOpen: toggle });
    };

    //this gets a user's rooms in the modal, when clicking Actions -> View
    fetchUserRoomsInfo = async (userId: string, userEmail: string) => {
        this.setState({ userRoomDetails: null, displayUserRoomDetailsModal: true });
        try {
            const userRoomDetails: IUserRoomDetailsRecord[] =
                await usersApi.guestUsers.getGuestRoomDetail({ userId });
            userRoomDetails.forEach(r => {
                r.userEmail = userEmail;
            });
            this.setState({
                userRoomDetails,
                newUserRoomAssociationsModel: {
                    userId,
                    userEmail,
                    newRooms: [
                        {
                            roomId: null,
                            roomRole: null,
                        },
                    ],
                },
            });
        } catch (e) {
            toast.error(
                `Unexpected error while attempting to get room details. Please try again or contact the dev team.`,
            );
        }
    };

    confirmRoleChange = (
        ptidUserId: string,
        userName: string,
        //yes, this is janky. our system allows a user ending up with more than 1 role (operative words being 'ending up' ಠ_ಠ), because nothing stops you (ADMIN-1231)
        currentRoles: ClientAccountsUserRole[],
        //but, we don't want to intentionally allow making users have multiple roles. they *should* just have one. so we're limiting it here
        newRole: ClientAccountsUserRole,
    ) => {
        this.setState({
            userRoleChangeModal: {
                show: true,
                messageText: `Are you sure you want to change the permissions of '${userName}', from ${currentRoles.join(',')} to ${newRole}?`,
                ptidUserId,
                role: newRole,
            },
        });
    };

    changeRole = async (ptidUserId: string, role: ClientAccountsUserRole) => {
        try {
            await this.adminApiUserManagementClient
                .apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch(convertEnum(ClientAccountsUserRole, GuestUserRoleType, role), ptidUserId);
            toast.success('User\'s permissions updated successfully!');
        } catch (error) {
            console.error({ error });
            toast.error(
                `Unexpected error while attempting to make change. Please try again or contact the dev team.`,
            );
        } finally {
            this.fetchData(this.reactTable.current?.state);
        }
    };

    impersonateUser = async (ptidUserId: string) => {
        try {
            const generatedImpersonationLink = await usersApi.authUsers.impersonate(
                ptidUserId,
            );
            this.setState({
                showImpersonationModal: true,
                generatedImpersonationLink,
            });
        } catch (e) {
            toast.error(
                `Unable to generate impersonation link. Please try again or contact the dev team.`,
            );
        }
    };

    closeImpersonatePopup = () => {
        this.setState({
            showImpersonationModal: false,
            generatedImpersonationLink: null,
        });
    };

    closeUserRoomDetailsPopup = () => {
        this.setState({ displayUserRoomDetailsModal: false });
    };

    disassociateRoomFromUser = async (
        userId: string,
        crmRoomId: string,
        userEmail: string,
    ) => {
        try {
            const payload = {
                groupId: '',
                userEmail,
                roomId: crmRoomId,
                roomRole: '',
            };

            const result = await usersApi.guestUsers.unassignUserFromRoom(payload);
            if (result.isSuccess) {
                toast.success(
                    `User: '${userEmail}' has been disassociated from Crm Room: '${crmRoomId}'`,
                );
            } else {
                toast.error(
                    `Unexpected error while attempting to disassociate User: '${userEmail}' from Crm Room: '${crmRoomId}'. Please try again or contact the dev team.`,
                );
            }
        } catch (e) {
            toast.error(
                `Unexpected error while attempting to disassociate User: '${userEmail}' from Crm Room: '${crmRoomId}'. Please try again or contact the dev team.`,
            );
        } finally {
            //refresh user's room associations, regardless of outcome
            await this.fetchUserRoomsInfo(userId, userEmail);
        }
    };

    manageUserRoomDetails = async (row: ILegacyLiveBookingOasisUserDetails) => {
        let userCommunicationPrefsLookup:
            | {
                id: string | null;
                communicationLevel: string | null;
                communicationStyle: string | null;
            }[]
            | null = null;
        try {
            userCommunicationPrefsLookup =
                await usersApi.destifyLegacyDashboardAccess.post(
                    'api/UserGroup/GetCommunicationsForUsers',
                    [row.Id],
                );
        } catch {
            toast.warn(
                `Unable to get communication preferences for '${row.Email}' due to a failed lookup. Setting to defaults.`,
            );
        }

        this.setState(prevState => ({
            detailsPage: {
                ...prevState.detailsPage,
                id: row.Id,
                firstName: row.FirstName,
                lastName: row.LastName,
                phoneNo: row.PhoneNo,
                address: null,
                email: row.Email,
                status: true,
                communicationStyle:
                    userCommunicationPrefsLookup?.length > 0
                        ? userCommunicationPrefsLookup[0].communicationStyle
                        : '',
                communicationLevel:
                    userCommunicationPrefsLookup?.length > 0
                        ? userCommunicationPrefsLookup[0].communicationLevel
                        : '',
            },
        }));
    };

    handleAddingRoomsToUser = async (
        newUserRoomAssociationsModel: typeof this.state.newUserRoomAssociationsModel,
        { resetForm },
    ) => {
        try {
            await Promise.all(
                newUserRoomAssociationsModel.newRooms.map(
                    async assignUserToRoomRequest => {
                        const payload = {
                            ...assignUserToRoomRequest,
                            userEmail: newUserRoomAssociationsModel.userEmail,
                        };
                        const assignUserToRoomResult =
                            await usersApi.guestUsers.assignUserToRoom(payload);
                        if (assignUserToRoomResult?.isSuccess) {
                            toast.success(assignUserToRoomResult.value);
                            this.fetchUserRoomsInfo(
                                newUserRoomAssociationsModel.userId,
                                newUserRoomAssociationsModel.userEmail,
                            );
                            resetForm();
                        } else {
                            toast.error(
                                assignUserToRoomResult?.error ||
                                `Unable to assign room '${assignUserToRoomRequest.roomId}' to user '${newUserRoomAssociationsModel.userEmail}', due to an error. Please try again or contact the dev team.`,
                            );
                        }
                    },
                ),
            );
        } catch (e) {
            toast.error(
                'Unable to save specified room changes to user record, due to an error. Please try again or contact the dev team.',
            );
        }
    };

    render() {
        const {
            tableData,
            pages,
            loading,
            filters,
            incomingFilter,
            guestRoleTotal,
            groupLeaderRoleTotal,
            salesLeadsTotal,
            associatedCrmRoomDetails,
            userRoleChangeModal,
            showImpersonationModal,
            generatedImpersonationLink,
            displayUserRoomDetailsModal,
            userRoomDetails,
            activeRoomDetails,
            isOpen,
        } = this.state;

        const dateCreatedFilter = filters.find(f => f.name === 'CreatedDate');
        const dateLastActivityFilter = filters.find(
            f => f.name === 'LastActivityTime',
        );
        const userRoleFilter = filters.find(f => f.name === 'UserRoles');

        return (
            <>
                {/** User Impersonation Modal */}
                <ImpersonationModal
                    isOpen={showImpersonationModal}
                    closeUserDetailPopup={this.closeImpersonatePopup.bind(this)}
                    impersonationLink={generatedImpersonationLink}
                />

                {/** Change User Role Confirmation Modal */}
                <SweetAlert
                    show={userRoleChangeModal.show}
                    type="warning"
                    title="Confirm Permission Change"
                    text={userRoleChangeModal.messageText}
                    showCancelButton
                    onConfirm={() => {
                        this.changeRole(
                            userRoleChangeModal.ptidUserId,
                            userRoleChangeModal.role,
                        );
                        this.setState({
                            userRoleChangeModal: {
                                show: false,
                                messageText: '',
                            },
                        });
                    }}
                    onCancel={() => {
                        this.setState({
                            userRoleChangeModal: {
                                show: false,
                                messageText: '',
                            },
                        });
                    }}
                    //onClose={() => null}
                    onClose={() => {
                        this.setState({
                            userRoleChangeModal: {
                                show: false,
                                messageText: '',
                            },
                        });
                    }}
                />

                {/**View User Rooms Modal */}
                <Modal
                    size="lg"
                    isOpen={displayUserRoomDetailsModal}
                    className={this.props.className}
                    scrollable
                >
                    <ModalHeader>User Rooms</ModalHeader>
                    <ModalBody>
                        <ListGroup>
                            {(userRoomDetails ?? []).length === 0 && (
                                <ListGroupItem>
                                    <p>No room association found for user</p>
                                </ListGroupItem>
                            )}
                            {userRoomDetails?.map((room, index) => (
                                <ListGroupItem key={index}>
                                    <div className="widget-content p-0">
                                        <div className="widget-content-wrapper">
                                            <div className="widget-content-left mr-3">
                                                <div className="icon-wrapper border-light rounded m-0">
                                                    <div className="icon-wrapper-bg bg-light" />
                                                    <i className="lnr-rocket icon-gradient bg-happy-itmeo" />
                                                </div>
                                            </div>
                                            <div className="widget-content-left">
                                                <div className="widget-heading">{room.roomName}</div>
                                                <div className="widget-subheading">
                                                    <span className="text-dark">{room.groupName} </span>
                                                </div>
                                                <div className="widget-subheading">
                                                    {room.roomDetail && (
                                                        <Button
                                                            color="link"
                                                            onClick={() => this.showSelectedRoomDetails(room)}
                                                        >
                                                            View Room Details
                                                        </Button>
                                                    )}
                                                    <Button
                                                        color="link"
                                                        href={room.roomUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        View Room In CRM
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        color="error"
                                                        style={{ color: 'rgb(211, 47, 47)' }}
                                                        onClick={() =>
                                                            this.disassociateRoomFromUser(
                                                                room.userId,
                                                                room.roomId,
                                                                room.userEmail,
                                                            )
                                                        }
                                                    >
                                                        Remove Room
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className="widget-content-right">
                                                <div className="badge badge-success">
                                                    {RoomRoles[room.roomRole]}
                                                </div>
                                            </div>
                                        </div>
                                        {room.roomDetail ? (
                                            <Collapse
                                                isOpen={activeRoomDetails?.roomId === room.roomId}
                                            >
                                                <ClientRoomDetailsCard roomDetails={room.roomDetail} />
                                            </Collapse>
                                        ) : null}
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </ModalBody>
                    <ModalHeader>Associated Room IDs</ModalHeader>
                    <ModalBody>
                        <Formik
                            validationSchema={ClientAccountsListPage.AddRoomsValidationSchema}
                            enableReinitialize
                            initialValues={this.state.newUserRoomAssociationsModel}
                            onSubmit={this.handleAddingRoomsToUser}
                        >
                            {({ values, handleSubmit, isSubmitting }) => (
                                <Form onSubmit={handleSubmit} autoComplete="off">
                                    <FieldArray name="newRooms">
                                        {({ push, remove }) => (
                                            <>
                                                {values?.newRooms &&
                                                    values.newRooms.map((_, index) => (
                                                        <Row className="mt-2" key={index}>
                                                            <Col className="col-md-3">
                                                                <SelectInput
                                                                    options={
                                                                        ClientAccountsListPage.RoomRoleOptions
                                                                    }
                                                                    label="Room Role"
                                                                    name={`newRooms[${index}].roomRole`}
                                                                    placeholder="Room Role"
                                                                />
                                                            </Col>

                                                            <Col className="col-md-5">
                                                                <TextField
                                                                    label="Room Id"
                                                                    name={`newRooms[${index}].roomId`}
                                                                />
                                                            </Col>

                                                            {index > 0 ? (
                                                                <Col className="col-md-1">
                                                                    <Button
                                                                        style={{ marginTop: '30px' }}
                                                                        onClick={() => remove(index)}
                                                                        color="danger"
                                                                        className="mb-2 mr-2 btn-icon btn-icon-only"
                                                                    >
                                                                        {' '}
                                                                        <i className="pe-7s-trash btn-icon-wrapper">
                                                                            {' '}
                                                                        </i>
                                                                    </Button>
                                                                </Col>
                                                            ) : null}

                                                            <Col className="col-md-2">
                                                                <Button
                                                                    style={{ marginTop: '30px' }}
                                                                    onClick={() => {
                                                                        //this is seemingly needed to feed the FieldArray, but doesnt actually do anything
                                                                        //with regards to our form submission data
                                                                        push({
                                                                            roomRole: '',
                                                                            roomId: '',
                                                                        });
                                                                    }}
                                                                    color="success"
                                                                >
                                                                    <i className="pe-7s-plus btn-icon-wrapper">
                                                                        {' '}
                                                                    </i>{' '}
                                                                    Add
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                            </>
                                        )}
                                    </FieldArray>

                                    <Row className="mt-3">
                                        <Button
                                            style={{ marginLeft: '10px' }}
                                            type="submit"
                                            disabled={isSubmitting}
                                            color="primary"
                                        >
                                            {isSubmitting ? (
                                                <span>Processing....</span>
                                            ) : (
                                                <span>Save</span>
                                            )}
                                        </Button>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.closeUserRoomDetailsPopup.bind(this)}
                        >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                <div>
                    <div>
                        <PageTitle
                            heading="Client Accounts"
                            icon="pe-7s-user icon-gradient bg-tempting-azure"
                            subheading="Destify Web App Client Accounts"
                        />
                    </div>

                    <Row>
                        <Col md="12">
                            <ClientAccountsHeaderTileSummaryCard
                                totalGroupLeaderAccounts={groupLeaderRoleTotal}
                                totalGuestRoleAccounts={guestRoleTotal}
                                totalSalesLeadAccounts={salesLeadsTotal}
                            />
                        </Col>
                        <Col md="12">
                            <Card className="main-card mb-3">
                                <CardHeader
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => this.openFilters(!isOpen)}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <h6
                                            style={{ display: 'flex', marginBottom: 0 }}
                                            className="text-uppercase"
                                        >
                                            <IoIosFunnel
                                                size={'20px'}
                                                style={{ marginRight: '5px' }}
                                            />
                                            <span style={{ color: '#6610f2' }}>Advanced Filters</span>
                                        </h6>
                                        <div className=" mr-3">
                                            <FontAwesomeIcon
                                                icon={isOpen ? faAngleUp : faAngleDown}
                                                size="xl"
                                                onClick={() => this.openFilters(!isOpen)}
                                            />
                                        </div>
                                    </div>
                                </CardHeader>
                                <Collapse isOpen={isOpen}>
                                    <CardBody>
                                        <Row>
                                            <Col xl={6} lg={12}>
                                                <ODataDateRangePicker
                                                    key={JSON.stringify(dateCreatedFilter)}
                                                    label={'Created Date'}
                                                    onChange={this.updateFilters}
                                                    odataFilter={dateCreatedFilter}
                                                />
                                                <ODataDateRangePicker
                                                    key={JSON.stringify(dateLastActivityFilter)}
                                                    label={'Last Active Date'}
                                                    onChange={this.updateFilters}
                                                    odataFilter={dateLastActivityFilter}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter style={{ display: 'block' }}>
                                        <div className="float-left">
                                            <b>Note:</b> this is a compound search. Meaning, it's{' '}
                                            <code>
                                                CreatedDate is xyz and LastActivityTime is xyz and...
                                            </code>{' '}
                                            <br />
                                            <i>Not</i>{' '}
                                            <code>CreatedDate is xyz or LastActivityTime is xyz</code>
                                        </div>
                                        <div className="float-right">
                                            <Button
                                                outline
                                                onClick={this.clearAllFilters.bind(null, true)}
                                            >
                                                Clear All Filters
                                            </Button>{' '}
                                            <Button
                                                onClick={() => {
                                                    this.fetchData(this.reactTable.current?.state);
                                                }}
                                            >
                                                Search
                                            </Button>
                                        </div>
                                        <div className="clearfix"></div>
                                    </CardFooter>
                                </Collapse>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card className="main-card mb-3">
                                <CardHeader>
                                    <div className="btn-actions-pane-right"></div>
                                </CardHeader>
                                <CardBody>
                                    <ReactTable
                                        manual
                                        data={tableData}
                                        pages={pages}
                                        loading={loading}
                                        onFetchData={debounceFun(this.fetchData)}
                                        filterable
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        multiSort={false}
                                        defaultSorted={[
                                            {
                                                // the sorting model for the table
                                                id: 'CreatedDate',
                                                desc: true,
                                            },
                                        ]}
                                        ref={this.reactTable}
                                        getTrProps={(state, rowInfo, column) => {
                                            if (!rowInfo) return { style: { background: 'inherit' } };
                                            return {
                                                style: {
                                                    //if they row/user has more than 1 role, highlight the row in yellow. otherwise, dont
                                                    background:
                                                        Array.isArray(rowInfo.row.UserRoles) &&
                                                            rowInfo.row.UserRoles.length > 1
                                                            ? '#e0f8004d'
                                                            : rowInfo.index % 2
                                                                ? '#00000008'
                                                                : '#fff',
                                                },
                                            };
                                        }}
                                        columns={[
                                            {
                                                //hiding this since it might be useful for developers to easily find corresponding
                                                //record id in the database
                                                Header: 'Id',
                                                accessor: 'Id',
                                            },
                                            {
                                                Header: 'Full Name',
                                                accessor: 'FullName',
                                            },
                                            {
                                                Header: 'Email',
                                                accessor: 'Email',
                                            },
                                            {
                                                Header: 'Phone Number',
                                                accessor: 'PhoneNo',
                                            },
                                            {
                                                Header: 'Created Date',
                                                accessor: 'CreatedDate',
                                                Cell: props => formatDate(props.value),
                                                filterable: false,
                                            },
                                            {
                                                Header: 'Date Last Active',
                                                accessor: 'LastActivityTime',
                                                Cell: props => formatDate(props.value),
                                                filterable: false,
                                            },
                                            {
                                                Header: 'Last Edited',
                                                accessor: 'LastModifiedDate',
                                                Cell: props => formatDate(props.value),
                                                filterable: false,
                                            },

                                            {
                                                Header: 'User Permission Level',
                                                accessor: 'UserRoles',
                                                Cell: ({ row }: { row: ILegacyLiveBookingOasisUserDetails }) => {
                                                    if (!row.UserRoles) return null;
                                                    if (row.UserRoles.length === 1) return row.UserRoles;
                                                    return (
                                                        <>
                                                            <b

                                                                id={'UserRoles-' + row.Id}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {row.UserRoles.map((r, idx) => (
                                                                    <React.Fragment key={'UserRoles-' + row.Id + idx}>
                                                                        {r}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </b>
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={'UserRoles-' + row.Id}
                                                            >
                                                                This user has more than 1 role assigned to them,
                                                                which might cause problems.
                                                                <br />
                                                                Consider using the Impersonation functionality
                                                                to see what they see, when they login
                                                            </UncontrolledTooltip>
                                                        </>
                                                    );
                                                },
                                                filterable: true,
                                                Filter: ({ filter, onChange }) => (
                                                    <select
                                                        onChange={e => {
                                                            userRoleFilter.value =
                                                                e.target.value === '' ? null : e.target.value;
                                                            this.updateFilters(userRoleFilter);
                                                            this.fetchData(this.reactTable.current?.state);
                                                        }}
                                                        style={{ width: '100%' }}
                                                        value={userRoleFilter?.value?.toString() || ''}
                                                    >
                                                        {ClientAccountsListPage.GetUserRoleOptions()}
                                                    </select>
                                                ),
                                            },
                                            {
                                                Header: 'Rooms',
                                                filterable: false,
                                                Cell: ({
                                                    original,
                                                }: {
                                                    original: ILegacyLiveBookingOasisUserDetails;
                                                }) => {
                                                    const matchingRoomDetails =
                                                        associatedCrmRoomDetails?.filter(
                                                            r => r.userId === original.Id,
                                                        );
                                                    if (!matchingRoomDetails) {
                                                        return null;
                                                    }
                                                    return matchingRoomDetails.map((item, idx) => (
                                                        <span key={item.roomId + idx}>
                                                            <a
                                                                className="anchor-link"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                                href={item.roomUrl}
                                                            >
                                                                {item.roomName}{' '}
                                                            </a>
                                                            <br />
                                                        </span>
                                                    ));
                                                },
                                            },

                                            {
                                                Header: 'Action',
                                                columns: [
                                                    {
                                                        Header: 'Settings',
                                                        sortable: false,
                                                        filterable: false,
                                                        Cell: ({
                                                            original,
                                                        }: {
                                                            original: ILegacyLiveBookingOasisUserDetails;
                                                        }) => {
                                                            return (
                                                                <UncontrolledButtonDropdown>
                                                                    <DropdownToggle
                                                                        caret
                                                                        className="mb-2 ml-2"
                                                                        color="primary"
                                                                    >
                                                                        Action
                                                                    </DropdownToggle>
                                                                    <DropdownMenu
                                                                        positionFixed={true}
                                                                        className="dropdown-menu-rounded dropdown-menu-lg"
                                                                    >
                                                                        <DropdownItem
                                                                            data-testid={`manage-user-room`}
                                                                            onClick={() =>
                                                                                this.manageUserRoomDetails(original)
                                                                            }
                                                                        >
                                                                            <Link
                                                                                to={`/user/guest-list-new/${original.Id}`}
                                                                            >
                                                                                Manage
                                                                            </Link>
                                                                        </DropdownItem>
                                                                        {/*There used to be a check to only show this if the current logged in user's role was !== 'Guest Services'
                                      but, per Briana, current roles are pretty meaningless. So, show the damn thing to everyone. */}
                                                                        <DropdownItem
                                                                            data-testid={`impersonate-user`}
                                                                            onClick={() =>
                                                                                this.impersonateUser(original.Id)
                                                                            }
                                                                        >
                                                                            Impersonate
                                                                        </DropdownItem>
                                                                        <DropdownItem divider />
                                                                        <DropdownItem header style={{ fontWeight: 'normal' }} className="mb-1 text-muted">
                                                                            Change Account Permissions
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            data-testid={`make-sales-lead`}
                                                                            onClick={() =>
                                                                                this.confirmRoleChange(
                                                                                    original.Id,
                                                                                    original.Email,
                                                                                    original.UserRoles,
                                                                                    ClientAccountsUserRole.SalesLead,
                                                                                )
                                                                            }
                                                                        >
                                                                            Make Sales Lead
                                                                        </DropdownItem>

                                                                        <DropdownItem
                                                                            data-testid={`make-guest`}
                                                                            onClick={() =>
                                                                                this.confirmRoleChange(
                                                                                    original.Id,
                                                                                    original.Email,
                                                                                    original.UserRoles,
                                                                                    ClientAccountsUserRole.Guest,
                                                                                )
                                                                            }
                                                                        >
                                                                            Make Guest
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            data-testid={`make-group-leader`}
                                                                            onClick={() =>
                                                                                this.confirmRoleChange(
                                                                                    original.Id,
                                                                                    original.Email,
                                                                                    original.UserRoles,
                                                                                    ClientAccountsUserRole.GroupLeader,
                                                                                )
                                                                            }
                                                                        >
                                                                            Make Group Leader
                                                                        </DropdownItem>


                                                                    </DropdownMenu>
                                                                </UncontrolledButtonDropdown>
                                                            );
                                                        },
                                                    },
                                                ],
                                            },
                                        ]}
                                        pageSizeOptions={[10, 20, 30, 100]}
                                        showPaginationTop
                                        showPaginationBottom
                                        defaultFiltered={incomingFilter ? [incomingFilter] : []}
                                    />
                                    <>
                                        <Row>
                                            <Col>
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle
                                                        caret
                                                        className="mb-2 mr-2"
                                                        color="primary"
                                                    >
                                                        Download .csv
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                                                        <DropdownItem
                                                            onClick={() =>
                                                                this.handleExportToCSV('currentPage')
                                                            }
                                                        >
                                                            Current Page
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => this.handleExportToCSV('filtered')}
                                                        >
                                                            Filtered Leads
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={() => this.handleExportToCSV('all')}
                                                        >
                                                            All Leads
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </Col>
                                        </Row>
                                    </>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
