import React, { useEffect, useState } from 'react';
import { Button, Container, Card, CardBody } from 'reactstrap';

import PageTitle from 'Layout/AppMain/PageTitle';
import { SupplierTable } from "./components"

import { useTransferTypeSlice } from '../slice';
// TODO
const Index = () => {
  const { actions } = useTransferTypeSlice();

  return (
    <>
      <Container fluid>
        <div>
          <PageTitle
            heading="Transfer Supplier"
            icon="pe-7s-map-2 icon-gradient bg-tempting-azure"
          />
        </div>
        <Card className="main-card mb-3">
          <CardBody>
            <SupplierTable loading={false} data={[]}/>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Index;
