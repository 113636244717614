import React from 'react';
import { IODataFilter } from '../ODataFilters';
import { ODataTableHelper } from '../ODataTableHelper';
import { exportToCSV } from 'common/Export/ExportToExcel';
import { IBaseODataApiClient } from 'api/OData/IBaseODataApiClient';
import { ODataEntityCollection } from 'api/OData/LegacyLiveBooking/ODataEntities';


export interface IODataDataTableComponentState {
	filters: IODataFilter[];
}

/**
 * This is an abstract base class to be inherited from, when authoring new OData 
 * Page components with react-table. It harbors common functionality such as clearing filters, csv export, and updating filters
 */
export default abstract class ODataDataTableComponent<TRowDataType, TState extends IODataDataTableComponentState>
	extends React.Component<any, TState>
{
	protected abstract reactTable: React.RefObject<any>;
	protected abstract fetchData(reactTableState: any): Promise<void>;
	protected abstract odataApiClient: IBaseODataApiClient;
	protected abstract csvExportFileNamePrefix: string;
	protected abstract odataEntityType: ODataEntityCollection;

	clearAllFilters = (shouldRefreshData:boolean = true) => {
		const clearedTableFilterCallback = () => {
			const filters = this.state.filters.map(f => ({
				...f,
				modifier: null,
				value: null
			}));
			const refreshData = () => {
				if (shouldRefreshData) {
					this.fetchData(this.reactTable.current?.state);
				}
			}
			this.setState({ filters }, refreshData);
		}
		this.reactTable.current?.setState({ filtered: [], pageSize:10, page:0 }, clearedTableFilterCallback);
	};

	updateFilters = async (filter: IODataFilter) => {
		let filters = this.state.filters.filter(f => f.name !== filter.name);
		filters = [...filters, filter];
		this.setState({ filters });
	};

	handleExportToCSV = async (scope: 'all' | 'currentPage' | 'filtered') => {
		let csvData = [];
		if (scope === 'currentPage') {
			csvData = [...this.reactTable.current?.resolvedData];
		} else {
			let queryStringArgs = '';
			if (scope === 'filtered') {
				const queryOptions = {
					currentPage: this.reactTable.current?.state.page,
					dataTableSort: this.reactTable.current?.state.sorted.map(
						({ id, desc }) => ({
							columnName: id,
							sortOrder: desc ? 'desc' : 'asc',
						}),
					),
					dataTableFilters: this.reactTable.current?.state.filtered.map(
						({ id, value }) => ({ columnName: id, value }),
					),
					filters: this.state.filters.filter(f => f.value !== null),
				};

				queryStringArgs = ODataTableHelper.constructODataQueryArgs(
					null,
					queryOptions.currentPage,
					queryOptions.dataTableFilters,
					queryOptions.dataTableSort,
					queryOptions.filters,
				);
			}
			const { data } =
				await this.odataApiClient.getODataListResponseWithCount<TRowDataType>(
					`${this.odataEntityType}?${queryStringArgs}`,
				);
			csvData = [...data];
		}
		exportToCSV(
			{
				dataArray: csvData,
				fileName: `${this.csvExportFileNamePrefix}-${new Date().toISOString()}`,
			},
			{ forceQuotes: true },
		);
	}
}