import React from 'react';
import { CreateUpdateForm } from './_Form';

import { Props } from 'common/Modals';

type ModalProps = Pick<Props, 'isOpen' | 'onClose'>;

const formId = 'create-transfer-type-form';

const CreateForm: React.FC<ModalProps> = ({ onClose, isOpen }) => {

  const handleClose = () => {
    onClose();
  };

  const submitHandler = values => {
    // TODO: add submit handler
    console.log(values);
  };

  return (
    <>
      <CreateUpdateForm
        formId={formId}
        onClose={handleClose}
        title={'Add New Transfer Type'}
        isOpen={isOpen}
        submitHandler={submitHandler}
      />
    </>
  );
};

export default CreateForm;
