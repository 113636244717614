/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import {
  Button,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  Container,
  Label,
  Input,
  Form, FormFeedback,
  Modal, ModalHeader, ModalFooter
} from 'reactstrap';
import ReactTable from 'react-table';

import { TransitionGroup } from 'react-transition-group';
import {
  ToastContainer,
  toast,
} from 'react-toastify';
import PageTitle from '../../../Layout/AppMain/PageTitle';

import hotel from '../../../api/hotelContent';

class RoomFeature extends React.Component {
  // roomFeatureId;

  // state = RoomFeature.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  constructor(props) {
    super(props);

    this.state = {
      ...ToastContainer.defaultProps,
      modal: false,
      list: [],
      feature: '',
      featurerequired: false
    };

    this.togglepopup = this.togglepopup.bind(this);
  }

  togglepopup() {
    this.setState({
      modal: false
    });
  }

  getfeaturelist() {
    hotel.roomtype.features().then((response) => {
      this.setState({ list: response });
    })
  }

  componentDidMount() {
    this.getfeaturelist();
  }

    handleChange=(event) => {
      this.setState({ [event.target.name]: event.target.value });
    }
    
    addFeatures=(e) => {
      e.preventDefault();   
        
      if (this.isFormValid()) {
        const data = { featureName: this.state.feature };        
        hotel.roomtype.saveFeature(data).then((res) => {
          this.setState({ modal: false });
          toast.success(res);
          this.getfeaturelist();
        })
      } 
    }

    isFormValid=() => {
      if (!this.state.feature.length) {
        this.setState({ featurerequired: true });
      } else {
        this.setState({ featurerequired: false });
      }

      return this.state.feature.trim().length > 0
    }

    filterCaseInsensitive = (filter, row) => {
      const id = filter.pivotId || filter.id;
      const content = row[id];
      if (typeof content !== 'undefined') {
        if (filter.value.length < 3) {
          // filter by text in the table or if it's a object, filter by key
          if (typeof content === 'object' && content !== null && content.key) {
            return String(content.key).toLowerCase().startsWith(filter.value.toLowerCase());
          } 
          return String(content).toLowerCase().startsWith(filter.value.toLowerCase());
        } 
        // filter by text in the table or if it's a object, filter by key
        if (typeof content === 'object' && content !== null && content.key) {
          return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
        } 
        return String(content).toLowerCase().includes(filter.value.toLowerCase());
      }

      return true;
    };

    render() {
      const { featurerequired, feature } = this.state;

      return (

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >

          <Container fluid>
            <div>
              <PageTitle
                heading="Room Features"
                icon="pe-7s-signal icon-gradient bg-tempting-azure"
              />
            </div>

            <Modal isOpen={ this.state.modal } toggle={ this.togglepopup }>
              <ModalHeader toggle={ this.togglepopup }>Add Features</ModalHeader>
              <Form onSubmit={ this.addFeatures } className="m-3" autoComplete="false">
                <FormGroup>
                  <Label for="feature">Feature</Label>
                  <Input invalid={ featurerequired } type="text" name="feature" id="feature" value={ feature } onChange={ this.handleChange } />
                  <FormFeedback>
                    feature is required.
                  </FormFeedback>
                </FormGroup>  
                <ModalFooter>
                  <Button color="danger" onClick={ (e) => { e.preventDefault(); this.setState({ modal: false }) } }>
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Submit
                  </Button>

                </ModalFooter>
              </Form>
            </Modal>
            <Card className="main-card mt-3 mb-3">

              <CardHeader>
                <Button className="mb-2 mr-2 btn-icon float-right" color="primary" onClick={ () => { this.setState({ modal: true }); } }>
                  Add Features
                </Button>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={ this.state.list }
                  loading={ this.state.loading }
                  columns={ [
                    {
                      columns: [
                        {
                          Header: 'S.N',
                          id: 'sn',
                          filterable: false,
                          sortable: false,
                          maxWidth: 80,
                          Cell: (row) => <div>{row.index + 1}</div>
                        },
                        {
                          Header: 'Features Name',
                          accessor: 'text'
                        },

                      ],
                    }
                                       
                  ] }
                  defaultPageSize={ 10 }
                  filterable
                  defaultFilterMethod={ this.filterCaseInsensitive }
                  className="-striped -highlight"
                />
                                        
              </CardBody>
            </Card>
          </Container>

        </TransitionGroup>
      );
    }
}

export default RoomFeature;
