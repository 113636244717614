import { UserApi } from 'api/adminApi';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { IoIosInformationCircle } from 'react-icons/io';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
} from 'reactstrap';
import axios from 'axios';

const adminApiUserManagementClient = new UserApi();

export const ManualGroupLeaderAssociationComponent = ({
  onSuccess,
}: Readonly<{
  onSuccess: () => void;
}>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [groupId, setGroupId] = useState<string>('');

  const manuallySetGroupLeader = async () => {
    try {
      await adminApiUserManagementClient.apiUserSetGroupLeaderGroupIdPatch(
        groupId,
        emailAddress,
      );
      toast.success(
        <div>
          Success!
          <br />
          <b>{emailAddress}</b> is now marked as Group Leader of{' '}
          <b>{groupId}</b>
        </div>,
      );
      setGroupId('');
      setEmailAddress('');
      onSuccess();
    } catch (error: unknown) {
      console.error({ error });
      let errorMessage =
        'An error has occurred. Please try again or contact the platforms team.';
      if (axios.isAxiosError(error)) {
        const {
          response: {
            data: { detail },
          },
        } = error;

        if (detail) {
          errorMessage = detail;
        }
      }
      toast.error(
        <div>
          <b>Error!</b>
          <br />
          {errorMessage}
        </div>,
      );
    }
  };

  return (
    <>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardHeader
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <h6
                  style={{ display: 'flex', marginBottom: 0 }}
                  className="text-uppercase"
                >
                  <div>
                    <i className="pe-7s-bandaid"></i> Manual GL Association
                  </div>
                </h6>
                <div className=" mr-3">
                  {
                    <FontAwesomeIcon
                      icon={isOpen ? faAngleUp : faAngleDown}
                      size="xl"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                  }
                </div>
              </div>
            </CardHeader>
            <Collapse isOpen={isOpen}>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <IoIosInformationCircle /> Use the inputs below to manually
                    set a specific user, based on their email address, as the
                    group leader for the specified group.<br/>Any current user(s) set
                    as the group leader, will be overridden.
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={9}>
                    <FormGroup row>
                      <Label for="manualGlEmail" sm={4}>
                        User Email
                      </Label>
                      <Col sm={8}>
                        <Input
                          value={emailAddress}
                          id="manualGlEmail"
                          name="manualGlEmail"
                          placeholder=""
                          type="email"
                          onChange={e => setEmailAddress(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={9}>
                    <FormGroup row>
                      <Label for="manualGlGroupId" sm={4}>
                        CRM Group Id
                      </Label>
                      <Col sm={8}>
                        <Input
                          value={groupId}
                          id="manualGlGroupId"
                          name="manualGlGroupId"
                          placeholder=""
                          type="text"
                          onChange={e => setGroupId(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter style={{ display: 'block' }}>
                <div className="float-right">
                  <Button
                    outline
                    onClick={() => {
                      setGroupId('');
                      setEmailAddress('');
                    }}
                  >
                    Reset
                  </Button>{' '}
                  <Button
                    disabled={!emailAddress || !groupId}
                    onClick={manuallySetGroupLeader}
                  >
                    Update
                  </Button>
                </div>
                <div className="clearfix"></div>
              </CardFooter>
            </Collapse>
          </Card>
        </Col>
      </Row>
    </>
  );
};
