import React from 'react';
import { Button } from 'reactstrap';
import { IoIosFunnel } from 'react-icons/io';

const filters = [
    {
      label: `Searched Room < 24 Hours`,
      value: 'SearchedRoomPast24Hrs',
    },
    {
      label: 'New Accounts, No Wedding Date Request',
      value: 'NewAccountNoWeddingDateRequest',
    },
    {
      label: `Active < 24 Hours, Not Modified`,
      value: 'ActivePast24HoursNoChanges',
    },
    {
      label: "Booking",
      value: 'Booking',
    },
    {
      label: "New Unassigned",
      value: 'NewUnassigned',
    },
  ];

const _QuickFilter = ({
  selectedQuickFilter,
  handleQuickFilterChange,
  clear
}) => {
  return (
    <>
      <IoIosFunnel />{' '}
      <span style={{ textTransform: 'none', fontWeight: 500 }}>
        Apply Quick Filters:
      </span>
      {filters.map((filter, i) => {
        return (
          <Button
            key={i}
            outline={selectedQuickFilter !== filter.value}
            className="btn-wide ml-2 mr-1 btn-outline-2x mb-1 mt-1 btn-dashed"
            onClick={handleQuickFilterChange.bind(null, filter.value)}
            color="focus"
          >
            {filter.label}
          </Button>
        );
      })}
      <Button
        onClick={clear}
        color="link"
      >
        Clear
      </Button>
    </>
  );
};

export default _QuickFilter;
