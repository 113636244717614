/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Table,
  Button,
  Spinner,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import hotel from '../../../../api/hotelContent';
import { formatDate } from 'common/utils/dateUtils';


const RoomTypeRow = ({ room, toggleRoomSelected, selectedRows, changeTfoSettings, makeFavourite, manageRoomType, isBulkLoading }) => {
  const [isLoading, setIsLoading] = useState(false);


  const handleTfoChange = (room, isEnabled) => {
    setIsLoading(true);
    changeTfoSettings(room, isEnabled).finally(() => setIsLoading(false));
  }

  const isSelected = selectedRows?.includes(room.id);
  const isTfoLoading = (isBulkLoading && isSelected) || isLoading;
  const isTfoButtonDisabled = isSelected || isTfoLoading;

  const ToggleButton = useMemo(() => {
    return ({ color, onClick, children }) => (
      <Button
        color={color}
        disabled={isTfoButtonDisabled}
        onClick={onClick}
      >
        <Spinner size="sm" color="light" hidden={!isTfoLoading} />
        {!isTfoLoading && children}
      </Button>
    );
  }, [isTfoButtonDisabled, isTfoLoading]);

  const supplier = useMemo(() => JSON.parse(room.supplier === '' ? JSON.stringify({}) : room.supplier ), [room.supplier]);

  return (<tr>
    <td className="mr-2">
      <input
        type="checkbox"
        name="select"
        checked={selectedRows.includes(room.id)}
        onChange={() => toggleRoomSelected(room)}
      />
    </td>
    <td onClick={() => toggleRoomSelected(room)}>{room.roomTypeFriendlyName}</td>
    <td>
      <ul>
        {Object.keys(supplier).filter(key => !!key).map(key =>
          <li key={key}>
            {key} : {supplier[key]}
          </li>
        )}
      </ul>
    </td>
    <td>
      <div className="mb-2 mr-2 ">
        {room.tooFarOutSettings?.isEnabled ? (
          <ToggleButton color="success" onClick={() => handleTfoChange(room, false)}>
            Enabled
          </ToggleButton>
        ) : (
          <ToggleButton color="danger" onClick={() => handleTfoChange(room, true)}>
            Disabled
          </ToggleButton>
        )}
      </div>
    </td>
    <td>
      {room.isBestValue && (
        <div className="mb-2 mr-2 ">
          {' '}
          Yes <i className="pe-7s-ticket text-info" />
        </div>
      )}
      {!room.isBestValue && <div className="mb-2 mr-2"> No </div>}
    </td>
    <td>{formatDate(room.updatedDate, 'MM/dd/yyyy')}</td>
    <td>
      {room.enabled && (
        <div className="mb-2 mr-2 badge badge-pill badge-success">
          {' '}
          Enabled{' '}
        </div>
      )}
      {!room.enabled && (
        <div className="mb-2 mr-2 badge badge-pill badge-danger">
          {' '}
          Disabled{' '}
        </div>
      )}
    </td>
    <td>
      <UncontrolledButtonDropdown>
        <DropdownToggle
          caret
          className="mb-2 ml-2"
          color="primary"
        >
          Action
        </DropdownToggle>
        <DropdownMenu
          right
          className="dropdown-menu-rounded dropdown-menu-lg"
        >
          <DropdownItem
            onClick={() => manageRoomType(room.id)}
          >
            Manage
          </DropdownItem>
          <DropdownItem onClick={() => makeFavourite(room)}>
            Make Destify Favorite
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </td>
  </tr>);
}

class RoomTypes extends React.Component {
  queryObject = {};

  constructor(props) {
    super(props);

    this.queryObject.IsSortAsc = true;
    this.queryObject.PageNumber = 1;
    this.queryObject.PageSize = 100000;
    this.queryObject.SortBy = 'RoomTypeFriendlyName';

    this.state = {
      withoutFilterRoomType: [],
      roomType: [],
      IsSortAsc: true,
      sortByColumn: 'RoomTypeFriendlyName',
      roomFilter: '',
      selectedRows: [],
      isBulkLoading: false
    };
  }

  loadRoomType() {
    const { id } = this.props.match.params;
    this.queryObject.hotelId = id;
    hotel.roomtype.list(this.queryObject).then(response => {
      let supplier = '';
      response.forEach(element => {
        const newElement = { ...element };
        supplier = '<ul>';
        newElement.supplier = JSON.parse(newElement.supplier === '' ? JSON.stringify({}) : newElement.supplier);
        Object.keys(newElement.supplier).forEach(key => {
          if (Object.prototype.hasOwnProperty.call(newElement.supplier, key)) {
            if (key === '' && element.supplier[key] === '') {
              return;
            }
            supplier += `<li>${key} : ${newElement.supplier[key]}</li>`;
          }
        });
        newElement.supplier = `${supplier}</ul>`;
      });
      this.setState({
        IsSortAsc: this.queryObject.IsSortAsc,
      });
      this.setState({ roomType: response, withoutFilterRoomType: response });
    });
  }

  sortBy = columnName => {
    this.setState({ roomFilter: '' });
    if (this.queryObject.SortBy === columnName) {
      this.queryObject.IsSortAsc = !this.queryObject.IsSortAsc;
    } else {
      this.queryObject.SortBy = columnName;
      this.queryObject.IsSortAsc = true;
    }
    this.setState({
      sortByColumn: columnName,
      IsSortAsc: this.queryObject.IsSortAsc,
    });

    this.loadRoomType();
  };

  getSupplier = () => {
    hotel.hotelcontent.supplierlist().then(() => {
      // this.setState({ supplierlist: response });
      this.loadRoomType();
    });
  };

  manageRoomType = id => {
    this.props.history.push(`/hotels/hotel-roomType-manage/${id}`);
  };

  createRoomType = id => {
    this.props.history.push(`/hotels/create-hotel-roomType/${id}`);
  };

  componentDidMount() {
    this.getSupplier();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => { };
  }

  handleSearch = e => {
    const filter = e.target.value.trim().toLowerCase();
    const length = [...e.target.value];

    this.setState({ roomFilter: e.target.value });
    if (filter === '') {
      this.setState(pS => ({ roomType: pS.withoutFilterRoomType }));
    }

    if (length.length >= 3 || length.length === 0) {
      this.setState(pS => {
        const filterlist = pS.withoutFilterRoomType.filter(x =>
          x.roomTypeFriendlyName.toLowerCase().includes(filter),
        );
        return { roomType: filterlist };
      });
    }
  };

  makeFavourite(details) {
    const data = {
      id: details.id,
      hotelID: details.hotelID,
    };

    hotel.roomtype.makeDestifyFavourite(data).then(response => {
      this.loadRoomType();
      console.log(response);
    });
  }

  changeTfoSettings = (room, isEnabled) => {
    return hotel.roomtype.changeTfoSettings(room.id, { isEnabled }).then(response => {
      this.setState({
        roomType: this.state.roomType.map(x => x.id === room.id ? { ...x, tooFarOutSettings: { isEnabled } } : x)
      });
    });
  }

  toggleRoomSelected = (room) => {
    if (this.state.isBulkLoading)
      return;

    const selected = !this.state.selectedRows.includes(room.id);
    this.setState({ selectedRows: selected ? [...this.state.selectedRows, room.id] : this.state.selectedRows.filter(x => x !== room.id) })
  }

  toggleSelectedAll = () => {
    if (this.state.isBulkLoading)
      return;

    const selected = this.state.selectedRows.length !== this.state.roomType.length;
    this.setState({ selectedRows: selected ? this.state.roomType.map(x => x.id) : [] });
  }

  bulkChangeTfo = (isEnabled) => {
    this.setState({ isBulkLoading: true })
    const data = this.state.selectedRows.map(x => ({ id: x, isEnabled }));

    const promises = data.map(x => hotel.roomtype.changeTfoSettings(x.id, { isEnabled }));

    Promise.all(promises).then(() => {
      this.loadRoomType();
    }).finally(() => this.setState({ isBulkLoading: false, selectedRows: [] }));
  }

  render() {
    return (
      <Card className="main-card mb-3">
        <CardBody>
          <button
            type="button"
            className="mb-2 mr-2 btn-icon float-right btn btn-primary"
            onClick={() => this.createRoomType(this.props.match.params.id)}
          >
            Create Room
          </button>

          {this.state.selectedRows.length > 0 && (
            <UncontrolledButtonDropdown direction='right'>
              <DropdownToggle
                caret
                className="mb-2 ml-2"
                color="primary"
              >
                Bulk Actions
              </DropdownToggle>
              <DropdownMenu
                right
                className="dropdown-menu-rounded"
              >
                <DropdownItem
                  onClick={() => this.bulkChangeTfo(true)}
                >
                  Enable TFO
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.bulkChangeTfo(false)}
                >
                  Disable TFO
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>)}

          {/* <CardTitle>Hotel Name: Dreams Punta Cana</CardTitle> */}
          <Table className="mb-10" bordered>
            <thead>
              <tr>
                <th onClick={this.toggleSelectedAll}>#</th>
                <th
                  className={
                    this.state.sortByColumn === 'RoomTypeFriendlyName' &&
                      this.state.IsSortAsc
                      ? 'asc pointer'
                      : this.state.sortByColumn === 'RoomTypeFriendlyName' &&
                        !this.state.IsSortAsc
                        ? 'desc pointer'
                        : 'pointer'
                  }
                  onClick={() => this.sortBy('RoomTypeFriendlyName')}
                >
                  Room Package Name
                </th>
                <th>Supplier</th>
                <th>TFO Room</th>
                <th>Destify Favorite?</th>
                <th
                  className={
                    this.state.sortByColumn === 'UpdatedDate' &&
                      this.state.IsSortAsc
                      ? 'asc pointer'
                      : this.state.sortByColumn === 'UpdatedDate' &&
                        !this.state.IsSortAsc
                        ? 'desc pointer'
                        : 'pointer'
                  }
                  onClick={() => this.sortBy('UpdatedDate')}
                >
                  Modified
                </th>
                <th>Status</th>
                <th> Action</th>
              </tr>
              {this.state.withoutFilterRoomType.length > 0 && (
                <tr>
                  <th>
                    <input
                      type="text"
                      autoComplete="off"
                      value={this.state.roomFilter}
                      name="roomTypeName"
                      onChange={e => this.handleSearch(e)}
                      className="searchtext form-control"
                    />
                  </th>
                  <th />
                  <th />
                </tr>
              )}
            </thead>
            <tbody>
              {this.state.roomType.map((room, index) => (
                <RoomTypeRow key={room.id}
                  room={room}
                  toggleRoomSelected={this.toggleRoomSelected}
                  selectedRows={this.state.selectedRows}
                  changeTfoSettings={this.changeTfoSettings}
                  makeFavourite={this.makeFavourite}
                  manageRoomType={this.manageRoomType}
                  isBulkLoading={this.state.isBulkLoading}
                />

              ))}
            </tbody>
          </Table>

          {/* <Pagination className="pagination-rounded" aria-label="Page navigation example">
                        <PaginationItem>
                            <PaginationLink previous href="#" />
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink href="#">1</PaginationLink>
                        </PaginationItem>
                        <PaginationItem active>
                            <PaginationLink href="#">2</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink href="#">3</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink href="#">4</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink href="#">5</PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                            <PaginationLink next href="#" />
                        </PaginationItem>
                    </Pagination> */}
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(RoomTypes);
