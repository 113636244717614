import React, { } from 'react';
import { PropTypes } from 'prop-types';
import Tabs from 'react-responsive-tabs';
import { withRouter } from 'react-router-dom';
import GeneralInfo from './GeneralInfo/generalInfo';
import RoomTypes from './RoomTypes/roomTypes';
import TransferShare from './TransferPricing';
import Description from './TransferPricing/description';
import PricingMarkup from './PricingMarkup';
import Integration from './Integration';
import Wedding from './Wedding';
import Venue from './Venue';

class Manage extends React.Component {
  getTabs() {
    return this.tabsContent.map((tab, index) => ({
      title: tab.title,
      getContent: () => tab.content,
      key: index,
    }));
  }

  tabsContent = [
    {
      title: 'General Information',
      content: (
        <GeneralInfo this={ this } onSelectHotel={ this.handleHotelInformation } />
      ),
    },
    {
      title: 'Wedding',
      content: <Wedding />,
    },
    {
      title: 'Venue',
      content: <Venue />,
    },
    {
      title: 'Room Type',
      content: <RoomTypes />,
    },
    {
      title: 'Transfer Pricing',
      content: <TransferShare />,
    },
    {
      title: 'Transfer Description',
      content: <Description />,
    },
    {
      title: 'Static Pricing Markup',
      content: <PricingMarkup />,
    },

    {
      title: 'Integration',
      content: <Integration />,
    },
  ];

  // expanstate = { expanded: false };

  constructor(props) {
    super(props);
   
    this.toggle = this.toggle.bind(this);
    const activeTab = '3';
    let selectedtab = 0;
    if (
      this.props.location.state
      && this.props.location.state.from === 'roomdetails'
    ) {
      selectedtab = 3;
    }

    this.state = {
      activeTab,
      selectedTabKey: selectedtab,
      hotelName: '',
    };

    this.setSelectedKey();
  }

  componentDidMount() {
    // TO-DO: If possible remove RoomType Details in seperate route and handle component mount within Tabs
    if (
      this.props.location.state
      && this.props.location.state.from === 'roomdetails'
    ) {
      const hotelName = localStorage.getItem('hotelName');
      this.setState({
        hotelName 
      })
    }
  }

  setSelectedKey() {
    // if (this.mounted) {
    // }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  backToList = () => {
    this.props.history.push('/hotels/hotel-content');
  };

  handleHotelInformation(that, response) {
    that.setState({ hotelName: response.hotelFriendlyName });
    localStorage.setItem('hotelName', response.hotelFriendlyName)
  }

  render() {
    return (
      <div className="app-inner-layout">
        <div className="app-inner-layout__header-boxed p-0">
          <div className="app-inner-layout__header page-title-icon-rounded text-white bg-midnight-bloom mb-4">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <div className="page-title-icon">
                    <i className="lnr-apartment icon-gradient bg-sunny-morning " />
                  </div>
                  <div className="h2">
                    {this.state.hotelName}
                    <div className="page-title-subheading">
                      Hotel Content Management
                    </div>
                  </div>
                </div>
                <div className="page-title-actions" />
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="mb-3  ml-3 btn-pill btn btn-outline-primary"
          onClick={ this.backToList }
        >
          Back to the list
        </button>

        <Tabs
          selectedTabKey={ this.state.selectedTabKey }
          tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big"
          transform={ false }
          showInkBar
          items={ this.getTabs() }
        />
      </div>
    );
  }
}

Manage.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};
export default withRouter(Manage);
