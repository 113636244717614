import React from 'react';
import { TextArea } from 'common/Form/TextArea';
import { TextField } from 'common/Form/TextInput';
import { Form, Formik } from 'formik';
import { Button, FormGroup, Row } from 'reactstrap';

import Modal, { Props as ModalProps } from 'common/Modals';

import {
  IUpdateFormProps,
} from '../../slice/types';
import * as Yup from 'yup';


const validationSchema = Yup.object({
  TransferTypeName: Yup.string().required('transfer type name is required!'),
  MaxNumGuests: Yup.number().positive(
    'max guests number should be greater than 0',
  ),
  MinNumGuests: Yup.number().positive(
    'min guests number should be greater than 0',
  ),
  TransferTypeDescription: Yup.string(),
});

const DEFAULT_VALUES = {
  MaxNumGuests: 0,
  MinNumGuests: 0,
  TransferTypeName: '',
  TransferTypeDescription: '',
};

export const CreateUpdateForm: React.FC<
  IUpdateFormProps &
    Omit<ModalProps,"children">
> =
  (
    {
      formId,
      submitHandler,
      initialValues = { ...DEFAULT_VALUES },
      title,
      isOpen,
      buttonLabels,
      disabled,
      onClose,
    }
  ) => {

    return (
      <Formik
        onSubmit={submitHandler}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ resetForm, dirty }) => {
          return (
            <>
              <Modal
                targetForm={formId}
                isOpen={isOpen}
                title={title}
                onClose={onClose}
                onSave={() => {}}
                buttonLabels={buttonLabels}
                disabled={!dirty || disabled}
              >
                <div className="p-4">
                  <Form id={formId}>
                    <FormGroup>
                      <Row>
                        <TextField
                          label={'Transfer Type Name'}
                          name="TransferTypeName"
                          autoComplete="off"
                        />
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <TextField
                          type="number"
                          label={'Maximum Occupancy'}
                          name="MaxNumGuests"
                        />
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <TextField
                          type="number"
                          label={'Minimum Occupancy'}
                          name="MinNumGuests"
                        />
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <TextArea
                          rows={5}
                          label={'Description'}
                          name="TransferTypeDescription"
                        />
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Button
                          type="button"
                          onClick={() => {
                            resetForm({ values: DEFAULT_VALUES });
                          }}
                        >
                          CLEAR
                        </Button>
                      </Row>
                    </FormGroup>
                  </Form>
                </div>
              </Modal>
            </>
          );
        }}
      </Formik>
    );
  };
