import React from 'react';
import { Formik, Form, Field } from 'formik';

import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';

import { toast } from 'react-toastify';
import { TransitionGroup } from 'react-transition-group';

import { withRouter } from 'react-router-dom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as Yup from 'yup';
import { SelectInput } from '../../../../common/Form/SelectInput';
import { TextField } from '../../../../common/Form/TextInput';
import PageTitle from '../../../../Layout/AppMain/PageTitle';
import {
  BooleanDropDown,
  Wifi,
  Rating,
} from '../../../../common/options/categoryoption';
import hotel from '../../../../api/hotelContent';

class CreateHotel extends React.Component {
  countryId = 0;

  cityId = 0;

  // state = CreateHotel.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  validationSchema = Yup.object({
    hotelFriendlyName: Yup.string().required(
      'Hotel Friendly Name is required.'
    ),
    hotelChainID: Yup.string().required('Hotel Chain is required.'),
    address1: Yup.string().required('Address1  is required.'),
    // rating: Yup.string().required('Rating is required.'),
    hotelCityId: Yup.string().required('City is required.'),
    hotelCountryId: Yup.string().required('Country is required.'),
    airportCodes: Yup.string().required('Airport code is required.'),

    adultsOnly: Yup.string().required('Adult only is required.'),
    // bars: Yup.string().required('Bar number is required.'),
    // pools: Yup.string().required('Pool number is required.'),
    // restaurants: Yup.string().required('Restaurant number  is required.'),
    // //dayPassPricing: Yup.string().required('Rating is required.'),
    // wifi: Yup.string().required('Wifi is required.')
  });

  generalInformation = {
    hotelID: '00000000-0000-0000-0000-000000000000',
    hotelFriendlyName: '',
    address1: '',
    address2: '',
    address3: '',
    hotelCityId: '',
    hotelCountryId: '',
    hotelChainID: '',
    gpsLat: '',
    gpsLong: '',
    airportCodes: '',
    salesManager: '',
    resortRep: '',
    weddingCoordinator1: '',
    weddingCoordinator2: '',
    weddingDeptPhone: '',
    specialRequests: '',
    frontDeskEmail: '',
    frontDeskPhone: '',
    mainDescription: '',
    shortDescription: '',
    resortFeatures: '',
    adultsOnly: '',
    bars: '',
    pools: '',
    restaurants: '',
    beachfront: 'true',
    casino: 'false',
    dayPassPricing: '',
    wifi: '',
    rating: '',
    isTopResort: 'true',
  };

  constructor(props) {
    super(props);
    // this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      mainDescription: '',
      shortDescription: '',
      resortFeatures: '',
      hotelChain: [],
      countryList: [],
      cityList: [],
      airportList: [],
      generalInformation: this.generalInformation,
      isSubmitting: false,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onChangemainDescription = (e, editor) => {
    this.setState({ mainDescription: editor.getData() });
  };

  onChangeshortDescription = (e, editor) => {
    this.setState({ shortDescription: editor.getData() });
  };

  onChangeResortFeatures = (e, editor) => {
    this.setState({ resortFeatures: editor.getData() });
  };

  async getHotelChain() {
    const response = await hotel.hotelcontent.hotelchainlist();
    this.setState({ hotelChain: response });
  }

  async getCountrylist() {
    const response = await hotel.hotelcontent.countrylist();
    this.setState({ countryList: response });
  }

  getCitylist = (id) => {
    hotel.hotelcontent.city(id).then((response) => {
      this.setState({ cityList: response });
    });
  };

  getAiportCode = () => {
    if (this.countryId > 0) {
      hotel.hotelcontent
        .airport(this.countryId, this.cityId)
        .then((response) => {
          this.setState({ airportList: response });
        });
    } else {
      this.generalInformation.hotelCountryId = 0;
      this.generalInformation.hotelCityId = '';
      this.generalInformation.airportCodes = '';
      this.setState({
        generalInformation: this.generalInformation,
        cityList: [],
        airportList: [],
      });
    }
  };

  // cityhandleChange = (e) => {
  //   // this.cityId= e.target.value;
  //   // this.generalInformation.hotelCityId = e.target.value;
  //   // this.setState({ generalInformation: this.generalInformation });

  // }

  // countryhandleChange = (e) => {
  //   this.generalInformation.hotelCityId = '';
  //   this.generalInformation.airportCodes = '';
  //   this.countryId = e.target.value;
  //   this.getCitylist(e.target.value);
  //   e.target.value = e.target.value === 0 ? '' : e.target.value;
  //   this.generalInformation.hotelCountryId = e.target.value;
  //   this.setState({ generalInformation: this.generalInformation, countryselected: true });
  //   this.getAiportCode();
  // }

  async componentDidMount() {
    await this.getCountrylist();
    await this.getHotelChain();
  }

  submitHotelContent = (value) => {
    const newValue = { ...value };
    this.setState({ isSubmitting: true });
    newValue.mainDescription = this.state.mainDescription;
    newValue.shortDescription = this.state.shortDescription;
    newValue.resortFeatures = this.state.resortFeatures;
    // value.hotelCountryId= this.generalInformation.hotelCountryId;
    // value.hotelCityId= this.generalInformation.hotelCityId;

    hotel.hotelcontent.saveHotel(newValue).then((response) => {
      this.setState({ isSubmitting: false });
      toast.success('Hotel saved successfully.');
      this.props.history.push(`/hotels/hotel-content-manage/${response}`);
    });
  };

  render() {
    return (
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }
      >
        <div>
          <PageTitle
            heading="Create Hotel Content"
            icon="lnr-apartment icon-gradient bg-tempting-azure"
          />
        </div>
        <button
          type="button"
          className="mb-3  ml-3 btn-pill btn btn-outline-primary"
          onClick={ () => this.props.history.push('/hotels/hotel-content') }
        >
          Back to the list
        </button>
        <Formik
          validationSchema={ this.validationSchema }
          enableReinitialize
          initialValues={ this.state.generalInformation }
          onSubmit={ (values) => {
            this.submitHotelContent(values);
          } }
        >
          {({ errors, values, touched, handleSubmit, setFieldValue }) => (
            <Row>
              <Col md="12">
                <Form onSubmit={ handleSubmit } autoComplete="off">
                  <div className="card">
                    <div className="card-body">
                      <Row>
                        <Col md="6">
                          <Card className="main-card card">
                            <CardHeader>Hotel Info</CardHeader>
                            <CardBody>
                              <FormGroup>
                                <SelectInput
                                  options={ this.state.hotelChain }
                                  label="Hotel Chain"
                                  name="hotelChainID"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Hotel Name"
                                  name="hotelFriendlyName"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Address 1" name="address1" />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Address 2" name="address2" />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Address 3" name="address3" />
                              </FormGroup>

                              <FormGroup>
                                <label htmlFor="hotelCountryId">
                                  {' '}
                                  Country{' '}
                                </label>
                                <Field
                                  name="hotelCountryId"
                                  className={
                                    errors.hotelCountryId
                                    && touched.hotelCountryId
                                      ? 'is-invalid form-control'
                                      : 'form-control'
                                  }
                                  value={ values.hotelCountryId }
                                  as="select"
                                  onChange={ (e) => {
                                    this.getCitylist(e.target.value);
                                    setFieldValue('hotelCityId', '');
                                    setFieldValue('airportCodes', '');
                                    setFieldValue(
                                      'hotelCountryId',
                                      e.target.value
                                    );
                                    this.countryId = e.target.value;
                                    this.getAiportCode();
                                  } }
                                >
                                  <option value="">--Select--</option>
                                  {this.state.countryList.map((country) => (
                                    <option
                                      key={ country.value }
                                      value={ country.value }
                                    >
                                      {country.text}
                                    </option>
                                  ))}
                                </Field>

                                {errors.hotelCountryId
                                && touched.hotelCountryId ? (
                                  <div className="invalid-validation">
                                    {errors.hotelCountryId}
                                  </div>
                                  ) : null}
                              </FormGroup>

                              <FormGroup>
                                <SelectInput
                                  options={ this.state.cityList }
                                  label="City"
                                  name="hotelCityId"
                                />
                              </FormGroup>

                              <FormGroup>
                                <SelectInput
                                  options={ this.state.airportList }
                                  label="Airport Code"
                                  name="airportCodes"
                                />
                              </FormGroup>
                              <FormGroup>
                                <SelectInput
                                  options={ Rating }
                                  label="Rating"
                                  name="rating"
                                />
                              </FormGroup>
                              <FormGroup>
                                <TextField label="Latitude" name="gpsLat" />
                              </FormGroup>

                              <FormGroup>
                                <TextField label="Longitude" name="gpsLong" />
                              </FormGroup>
                              <FormGroup>
                                <SelectInput
                                  donotShowSelect
                                  options={ BooleanDropDown }
                                  label="Top Resort"
                                  name="isTopResort"
                                />
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </Col>

                        <div className="col-md-6">
                          <Card className="main-card card">
                            <CardHeader>Administrative Details</CardHeader>
                            <CardBody>
                              <FormGroup>
                                <TextField
                                  label="Sales Manager"
                                  name="salesManager"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Resort Rep"
                                  name="resortRep"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Wedding Coordinators 1"
                                  name="weddingCoordinator1"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Wedding Coordinators 2"
                                  name="weddingCoordinator2"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Wedding Dept Phone"
                                  name="weddingDeptPhone"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Special Request"
                                  name="specialRequests"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Front Desk Email"
                                  name="frontDeskEmail"
                                />
                              </FormGroup>

                              <FormGroup>
                                <TextField
                                  label="Front Desk Phone"
                                  name="frontDeskPhone"
                                />
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </div>
                      </Row>
                    </div>
                  </div>

                  <Card className="main-card mt-3 mb-3">
                    <CardHeader>
                      <Nav justified>
                        <NavItem>
                          <NavLink
                            href="#"
                            className={ classnames({
                              active: this.state.activeTab === '1',
                            }) }
                            onClick={ () => {
                              this.toggle('1');
                            } }
                          >
                            Resort Description
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </CardHeader>
                    <CardBody>
                      <TabContent activeTab={ this.state.activeTab }>
                        <TabPane tabId="1">
                          <Row>
                            <div className="col-md-12">
                              <label htmlFor="mainDescription">
                                {' '}
                                Main Description{' '}
                              </label>

                              <CKEditor
                                name="mainDescription"
                                editor={ ClassicEditor }
                                data={ this.state.mainDescription }
                                onChange={ this.onChangemainDescription }
                              />
                            </div>
                          </Row>

                          <Row className="mt-3">
                            <div className="col-md-12">
                              <label htmlFor="shortDescription">
                                {' '}
                                Short Description{' '}
                              </label>

                              <CKEditor
                                name="shortDescription"
                                editor={ ClassicEditor }
                                data={ this.state.shortDescription }
                                onChange={ this.onChangeshortDescription }
                              />
                            </div>
                          </Row>

                          <Card className="main-card card mt-3">
                            <CardHeader>Amenities</CardHeader>
                            <CardBody>
                              <Row>
                                <div className="col-md-2">
                                  <FormGroup>
                                    <SelectInput
                                      options={ BooleanDropDown }
                                      label="Adults Only"
                                      name="adultsOnly"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <TextField label="Bars" name="bars" />
                                  </FormGroup>

                                  <FormGroup>
                                    <TextField label="Pools" name="pools" />
                                  </FormGroup>

                                  <FormGroup>
                                    <TextField
                                      label="Restaurants"
                                      name="restaurants"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <SelectInput
                                      donotShowSelect
                                      options={ BooleanDropDown }
                                      label="Beach Front"
                                      name="beachfront"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <SelectInput
                                      donotShowSelect
                                      options={ BooleanDropDown }
                                      label="Casino"
                                      name="casino"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <TextField
                                      label="Day Pass Pricing"
                                      name="dayPassPricing"
                                    />
                                  </FormGroup>

                                  <FormGroup>
                                    <SelectInput
                                      options={ Wifi }
                                      label="Wifi"
                                      name="wifi"
                                    />
                                  </FormGroup>
                                </div>

                                <div className="col-md-10">
                                  {/* <TextArea rows={5} label="Resort Features" name="resortFeatures" /> */}
                                  <label htmlFor="resortFeature">
                                    {' '}
                                    Resort Feature{' '}
                                  </label>
                                  <CKEditor
                                    name="resortFeature"
                                    editor={ ClassicEditor }
                                    data={ this.state.resortFeatures }
                                    onChange={ this.onChangeResortFeatures }
                                  />
                                </div>
                              </Row>
                              <Button
                                type="submit"
                                disabled={ this.state.isSubmitting }
                                color="primary"
                                className="mt-1"
                              >
                                {' '}
                                {this.state.isSubmitting
                                  ? 'Processing....'
                                  : 'Save'}
                              </Button>
                            </CardBody>
                          </Card>
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                  {/* {<pre>{JSON.stringify({  values,errors }, null, 4)}</pre> } */}
                </Form>
              </Col>
            </Row>
          )}
        </Formik>
      </TransitionGroup>
    );
  }
}

export default withRouter(CreateHotel);
