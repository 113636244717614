import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import './App.css';
import { Row, Col } from 'reactstrap';

class ImageCropper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      blob: null,
      imgSrc: null,
      crop: {
        unit: '%',
      }
    };
    this.onSelectFile = this.onSelectFile.bind(this);
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => 
        this.setState(prevState => ({
          imgSrc: reader.result?.toString() || ''
        }))
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (e) => {
    this.imageRef = e.currentTarget;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // Set width and height
    canvas.width =  e.currentTarget.width;
    canvas.height =  e.currentTarget.height;
    // Draw the image
    ctx.drawImage( e.currentTarget, 0, 0);
    canvas.toBlob(
      (blob) => {
        const newBlob = blob 
        if (!blob) {
          return;
        }
        this.setState(prevState => ({
          blob: newBlob
        }));
        newBlob.name = 'newFile.jpeg';
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(newBlob);
        this.setState(prevState => ({
          croppedImageUrl:  this.fileUrl
        }));
      },
      'image/jpeg',
      1
    );
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState(prevState => ({
        croppedImageUrl:  croppedImageUrl
      }));
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          const newBlob = blob 
          if (!blob) {
            // reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          this.setState({ blob: newBlob })
          newBlob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(newBlob);
          resolve(this.fileUrl);
        },
        'image/jpeg',
        1
      );
    });
  }

  onSubmit() {
    this.setState({ isSubmit: true });
   
    // this.setState({image:'', cropResult: null});
    this.props.submitS3Image(
      {
       
        this: this.props.this,
        blob: this.state.blob,
        selectedImageOperation: this.props.selectedImageOperation
      
      } 
    );
  }

  render() {
    const { crop, croppedImageUrl, imgSrc } = this.state;

    return (
      <div>
        <div>
          <input type="file" accept="image/*" onChange={ this.onSelectFile } />
        </div>

        <Row>
          <Col>
            {imgSrc && (
              <ReactCrop
                crop={ crop }
                ruleOfThirds
                onComplete={ this.onCropComplete }
                onChange={ this.onCropChange }
              >
                 <img src={imgSrc} alt='crop me' onLoad={this.onImageLoaded }/>
              </ReactCrop>
            )}
          </Col>
        </Row>

        {croppedImageUrl && !this.state.isSubmit && (
          <button
            type="button"
            onClick={ () => this.onSubmit() }
            className="btn btn-primary mt-3"
            style={ { float: 'right' } }
          >
            Save Image
          </button>
        )}

        {croppedImageUrl && this.state.isSubmit && (
          <button type="button" className="btn btn-primary mt-3" style={ { float: 'right' } }>
            Processing...
          </button>
        )}
      </div>
    );
  }
}

export default ImageCropper;
