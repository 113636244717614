import React from 'react';
import { useField } from 'formik';
import '../../assets/app.scss';

export function TextArea({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={label}>{label}</label>
      <textarea
        className={
          meta.touched && meta.error
            ? 'is-invalid form-control'
            : 'form-control'
        }
        name={label}
        {...field}
        {...props}
      />

      {meta.touched && meta.error ? (
        <div className="invalid-validation">{meta.error}</div>
      ) : null}
    </>
  );
}
