import React from 'react';

import { PaginationProps } from './types';

import { BsThreeDots, BsChevronLeft, BsChevronRight } from 'react-icons/bs';

interface IProps {
  breakLabels?: React.ReactNode;
  nextLabelHandle?: React.ReactNode;
  prevLabelHandle?: React.ReactNode;
}

const Pagination = (props: PaginationProps & IProps) => {
  const {
    breakLabels = <BsThreeDots />,
    nextLabelHandle = <BsChevronRight />,
    prevLabelHandle = <BsChevronLeft />,
    ...pagination
  } = props;
  return (
    <div className="pagination justify-content-center">
      {pagination.hasPrev && (
        <span
          className="page-link page-navigator"
          onClick={pagination.prevPage}
        >
          {prevLabelHandle}
        </span>
      )}
      {pagination.labels.map(label => {
        if (label > 0) {
          return (
            <span
              className={`page-item ${
                label === pagination.page ? 'active' : ''
              }`}
              onClick={() => pagination.jumpPage(label)}
            >
              <button className="page-link">{label}</button>
            </span>
          );
        }
        return <span className="page-item page-seperator">{breakLabels}</span>;
      })}
      {pagination.hasNext && (
        <span
          className="page-link page-navigator"
          onClick={pagination.nextPage}
        >
          {nextLabelHandle}
        </span>
      )}
    </div>
  );
};

export default Pagination;
