import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from 'types/RootState';

const selectSlice = (state: RootState) => state.weddingDate || initialState;

export const selectWeddingDetailIdentifier = createSelector(
  selectSlice,
  state => state.detailsPageStatus,
);

export const selectRequestDetails = createSelector(
  [selectSlice],
  state => state.requestDetails,
);

export const selectWeddingDateQueryObject= createSelector(
  [selectSlice],
  state=> state.weddingDateQueryObject
)

export const selectWeddingList = createSelector(
  [selectSlice],
  state=> state.weddingList
)

export const selectConfirmDetails = createSelector(
  [selectSlice],
  state => state.confirmDetails,
);

export const selectUpdateConfirmDetailsLoading = createSelector(
  [selectSlice],
  state => state.updateConfirmDetailsLoading,
);

export const selectUpdateRequestDetailsLoading = createSelector(
  [selectSlice],
  state => state.updateRequestDetailsLoading,
);

export const selectResort = createSelector(
  [selectSlice],
  state => state.resort,
);

export const selectResortVenues = createSelector(
  [selectSlice],
  state => state.venue,
);

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectResortPackages = createSelector(
  [selectSlice],
  state => state.packages,
);

export const selectCeremonyTimeList = createSelector(
  [selectSlice],
  state => state.ceremonyTimeList,
);

export const selectWeddingStatusList = createSelector(
  [selectSlice],
  state => state.weddingDateStatuses,
);

export const selectRequestHistory = createSelector(
  [selectSlice],
  state => state.weddingRequestHistory,
);
