import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import Loader, { LoaderType } from 'react-loaders';

interface ColumnType<T extends Record<string, any>, K extends keyof T> {
  Header: string | ((item:T) => React.ReactNode);
  accessor: K | ((item: T) => string);
  id?: string | number;
  Cell?: (props: { value: K; row: { original: T } }) => React.ReactNode;
  // default to false
  isSortable?: boolean;
  align?: 'left' | 'right' | 'center',
  width?: string;
}

export interface Column<D extends Record<string,any>> {
  Header: string | ((item:D) => React.ReactNode);
  accessor: keyof D | ((item: D) => string);
  id?: string | number;
  Cell?: (props: { value: keyof D; row: { original: D } }) => React.ReactNode;
  // default to false
  isSortable?: boolean;
  align?: 'left' | 'right' | 'center'
  width?: string;
}

interface Props<T extends Record<string, any>, K extends keyof T> {
  data: Array<T>;
  columns: Array<ColumnType<T, K>>;
  loading: boolean;
  loaderType?: LoaderType;
}

const styling = {
  overlay: base => ({
    ...base,
    background: '#fff',
    opacity: 0.5,
  }),
};

const Spinner = ({ type }: { type: LoaderType }) => (
  <Loader active type={type} />
);

const TableComponent = <T extends Record<string, any>, K extends keyof T>({
  data,
  columns,
  loading,
  loaderType = 'ball-pulse',
}: Props<T, K>) => {
  const [current, setCurrent] = useState<Array<T & {_id:string}>>([]);

  const refId = useRef(1);

  useEffect(() => {
    if(!data) return;
    setCurrent(data.map(item => ({
      ...item,
      _id: `T-${refId.current++}`
    })))
  }, [data])
  
  // const [sortObj,setSortObbj] = useState({} as Record<Partial<keyof T>,{asc:boolean}>)
  return (
    <div className='overflow-auto pb-5'>
      <Table striped bordered hover className="mb-10 w-100">
        <thead>
          <tr>
            {columns.map((column,i) => {
              const item = current.find(item => {
                if(typeof column.accessor === "function") {
                  try {
                    column.accessor(item);
                    return true;
                  }catch(error) {
                    return false;
                  }
                }
                return column.accessor in item;
              })
              const value = typeof column.Header === 'function' ? column.Header(item) : column.Header;
              return (
                <th key={i} className={`text-uppercase ${column.isSortable ? "pointer" : ""}`}>
                  {value}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={columns.length} className='text-center'>
                <LoadingOverlay
                  tag="div"
                  active
                  styles={styling}
                  spinner={<Spinner type={loaderType} />}
                />
                <pre>{''}</pre>
              </td>
            </tr>
          ) : current.length ? (
            <>
              {current.map((item) => {
                return (
                  <tr key={item._id}>
                    {columns.map(column => {
                      const value =
                        typeof column.accessor !== 'function'
                          ? item[column.accessor]
                          : item[column.accessor(item)];
                      return (
                        <td style={{
                          maxWidth: column.width ?? "auto"
                        }} key={`${column.Header}-${item._id}`} className={`text-${column.align || 'center'}`}>
                          {
                            // for custom cell component inside table cell
                            column.Cell
                              ? column.Cell({ value, row: { original: item } })
                              : value
                          }
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          ) : (
            <tr>
              <td colSpan={columns.length} className='text-center'>no records...</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableComponent;
