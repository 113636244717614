// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Input,
} from 'reactstrap';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NoteProps, NoteItem } from './_Note';
import { CreateNoteInput } from 'API';
import { ModalProps } from './_Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useSalesLeadSlice } from '../../slice';
import { selectUser } from '../../../../Pages/UserPages/slice/selectors';
import { Note } from '../../slice/types';
import { selectNotes, selectAuthUsers } from '../../slice/selectors';

export const NotesTimeline = ({ userId }: NoteProps) => {
  const dispatch = useDispatch();

  const { actions } = useSalesLeadSlice();

  const notes: Note[] = useSelector(selectNotes);
  const authUsers = useSelector(selectAuthUsers);
  /// MODALS
  const [isTextModal, setIsTextModal] = useState(false);
  const [isPhoneModal, setIsPhoneModal] = useState(false);
  const [isEmailModal, setIsEmailModal] = useState(false);

  const closeTextModal = () => setIsTextModal(false);
  const closePhoneModal = () => setIsPhoneModal(false);
  const closeEmailModal = () => setIsEmailModal(false);

  const userDetails = useSelector(selectUser);

  const adminUserById = useCallback(
    (adminId?: string) => {
      if (!authUsers.length > 0 || !adminId) return;
      const adminUser = authUsers.find(
        item => item.destifyUserId.toLowerCase() === adminId.toLowerCase(),
      );
      if (adminUser) return `${adminUser.firstName} ${adminUser.lastName}`;
      else return 'N/A';
    },
    [authUsers],
  );

  useEffect(() => {
    dispatch(
      actions.loadNotesForUser({ DestifyUserID: userId, module: 'sales' }),
    );
  }, [userId]);

  // Create note modal
  const CreateNoteModal = ({
    type,
    isOpen,
    closeModal,
  }: NoteProps & ModalProps) => {
    const initialState: CreateNoteInput = {
      adminID: userId,
      module: 'sales',
      type: type,
      note: '',
      createdBy: userDetails.profile.name,
    };

    const [formState, setFormState] = useState(initialState);

    const createNote = async () => {
      if (!formState.note) return;
      const formData = {
        DestifyUserID: formState.adminID,
        module: 'sales',
        type: formState.type,
        note: JSON.stringify(formState.note),
        CreatedBy: userDetails.profile['custom:DestifyLegacyUserId'],
        userId: userId,
      };

      dispatch(actions.addNote(formData));
      setFormState(initialState);
      closeModal();
    };

    const handleChange = e => {
      setFormState({ ...formState, note: e.target.value });
    };

    return (
      <span>
        <Modal
          isOpen={isOpen}
          toggle={closeModal}
          size={'md'}
          autoFocus={false}
        >
          <ModalHeader>
            <span className="popover-header-title">Note Details</span>
          </ModalHeader>
          <ModalBody>
            <div className="w-75 m-auto">
              <FormGroup>
                <Row className="mb-4">
                  <Col md={12}>
                    <InputGroup>
                      <Input type="hidden" disabled value={formState.adminID} />
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md={2}>
                    <Label htmlFor="noted">Note</Label>
                  </Col>
                  <Col md={10}>
                    <InputGroup>
                      <Input
                        name="note"
                        type="textarea"
                        value={formState.note}
                        onChange={handleChange}
                        autoFocus={true}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="outline-success" onClick={closeModal}>
              Cancel
            </Button>
            <Button color="success" onClick={createNote}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </span>
    );
  };

  return (
    <Card className="main-card mb-3">
      <CardHeader>Manage Notes</CardHeader>
      <CardBody>
        <div className="d-grid gap-2 d-flex justify-content-around no-wrap">
          <Button
            onClick={() => setIsTextModal(true)}
            className="mb-2  btn-pill btn btn-primary col-3 col-md-3"
            type="button"
          >
            Text
          </Button>
          <Button
            onClick={() => setIsPhoneModal(true)}
            className="mb-2  btn-pill btn btn-primary col-3 col-md-33"
            type="button"
          >
            Call
          </Button>
          <Button
            onClick={() => setIsEmailModal(true)}
            className="mb-2 btn-pill btn btn-primary col-3 col-md-3"
            type="button"
          >
            Email
          </Button>
        </div>

        {notes.length > 0 && (
          <div className="scroll-area-lg">
            <PerfectScrollbar>
              <div className="p-3">
                <VerticalTimeline
                  layout="1-column"
                  className="vertical-without-time"
                >
                  {notes?.map(note => {
                    return (
                      <NoteItem
                        key={note.id}
                        type={note.type}
                        id={note.id}
                        note={note.note}
                        module={note.module}
                        createdAt={note.createdAt}
                        iconClassName={note.type}
                        iconVariant={note.type}
                        createdBy={adminUserById(note.createdBy)}
                        updatedAt={note.updatedAt}
                        updatedBy={adminUserById(note.updatedBy)}
                      />
                    );
                  })}
                </VerticalTimeline>
              </div>
            </PerfectScrollbar>
          </div>
        )}
      </CardBody>

      {isTextModal ? (
        <CreateNoteModal
          type="text"
          isOpen={isTextModal}
          closeModal={closeTextModal}
        />
      ) : null}
      {isPhoneModal ? (
        <CreateNoteModal
          type="phone"
          isOpen={isPhoneModal}
          closeModal={closePhoneModal}
        />
      ) : null}
      {isEmailModal ? (
        <CreateNoteModal
          type="email"
          isOpen={isEmailModal}
          closeModal={closeEmailModal}
        />
      ) : null}
    </Card>
  );
};
