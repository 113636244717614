/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';
import { Loader } from 'react-loaders';
import { ToastContainer, toast } from 'react-toastify';
import { TransitionGroup } from 'react-transition-group';
import { useParams } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import PackageCreate from './create';
import Edit from './edit';
import weddingPackage from '../../../../api/weddingPackage';

function Wedding() {
  const { id } = useParams();
  const hotelId = id;
  const columns = [
    { title: 'Package Name', key: 'PackageName', isSortable: true },
    { title: 'Resort Name', key: 'ResortName', isSortable: true },
    { title: 'Deposit Price', key: 'packageDepositPrice', isSortable: true },
    { title: 'Status', key: 'IsActive', isSortable: true },
    { title: 'Action' },
  ];

  const obj = [];
  const [disablePackageId, setDisablePackageId] = useState(null);
  const [enablePackageId, setEnablePackageId] = useState(null);

  const queryObject = {
    IsSortAsc: true,
    PageNumber: 1,
    PageSize: 10,
    SortBy: '',
    destinationId: 0,
    subDestinationId: 0,
    resortId: 0,
    Search: obj,
    ptidAdminHotelId: hotelId,
  };

  const [active, setActive] = useState(false);
  const [islistView, updateListView] = useState(true);
  const [packageId, setPackageId] = useState(0);
  const [packagesList, setPackagesList] = useState([]);
  const [resortId, setResortId] = useState(0);
  const [statustext, setStatustext] = useState('');
  const [show, setShow] = useState(false);
  const [toastStates, setToastStates] = useState({
    ...ToastContainer.defaultProps,
    transition: 'bounce',
    type: 'success',
    disableAutoClose: false,
  });

  const showConfirmationToDelete = (id, enable) => {
    setDisablePackageId(id);
    setEnablePackageId(enable);
    if (enable) {
      setStatustext('Are you sure to enable the package?');
    } else {
      setStatustext('Are you sure to disable the package?');
    }
    setShow(true);
  };

  const searchPackage = async () => {
    setActive(true);
    const result = await weddingPackage.packagedetails.list(queryObject);
    const packageListforHotel = result.data.filter(
      (item) => item.ptidAdminHotelId.toLowerCase() === hotelId.toLowerCase()
    );
    setPackagesList(packageListforHotel);
    if (packageListforHotel.length) {
      setResortId(packageListforHotel[0].resortId);
      queryObject.resortId = packageListforHotel[0].resortId;
    }
    setActive(false);

    queryObject.PageNumber = result.pagination.currentPage;
    queryObject.PageSize = result.pagination.itemsPerPage;
    queryObject.totalItems = result.pagination.totalItems;

    setToastStates({
      totalItems: result.pagination.totalItems,
      totalPages: result.pagination.totalPages,
      SortBy: queryObject.SortBy,
      IsSortAsc: queryObject.isSortAsc,
    });
  };

  useEffect(() => {
    const loadInitial = async () => {
      await searchPackage();
    };
    if (islistView) loadInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islistView]);

  const handlePageClick = async (data) => {
    const { selected } = data;
    queryObject.PageNumber = selected + 1;
    await searchPackage();
  };

  const handleSearch = (e) => {
    queryObject.PageNumber = 1;
    const exist = obj.some((x) => x.columnSearchName === e.target.name);
    const length = [...e.target.value];

    if (!exist) {
      obj.push({
        columnSearchName: e.target.name,
        columnSearchValue: e.target.value,
      });
    } else {
      const index = obj.findIndex((p) => p.columnSearchName === e.target.name);
      obj[index].columnSearchValue = e.target.value;
    }
    if (length.length >= 3 || length.length === 0) {
      searchPackage();
    }
  };

  const sortBy = (columnName) => {
    if (queryObject.sortBy === columnName) {
      queryObject.isSortAsc = !queryObject.isSortAsc;
    } else {
      queryObject.sortBy = columnName;
      queryObject.isSortAsc = true;
    }
    setToastStates({
      ...toastStates,
      sortByColumn: columnName,
      IsSortAsc: queryObject.isSortAsc,
    });
    searchPackage();
  };

  const deleteWeddingPackage = () => {
    weddingPackage.packagedetails
      .disable({ id: disablePackageId, isEnabled: enablePackageId })
      .then(() => {
        toast.success('The status of wedding package saved successfully.');
        setShow(false);
        searchPackage();
      });
  };
  return (
    <div>
      {islistView ? (
        <>
          <SweetAlert
            show={ show }
            type="warning"
            title="Confirmation"
            text={ statustext }
            showCancelButton
            onConfirm={ () => {
              deleteWeddingPackage();
            } }
            onCancel={ () => {
              setShow(false);
            } }
            onClose={ () => {} } // eslint-disable-line no-console
          />

          <TransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear
            transitionAppearTimeout={ 0 }
            transitionEnter={ false }
            transitionLeave={ false }
          >
            <Container fluid>
              <LoadingOverlay
                tag="div"
                active={ active }
                styles={ {
                  overlay: (base) => ({
                    ...base,
                    background: '#fff',
                    opacity: 0.5,
                  }),
                } }
                spinner={ <Loader active /> }
              >
                <Card className="main-card mb-3">
                  <CardBody>
                    <CardTitle>Wedding package List</CardTitle>
                    <Button
                      className="mb-2 mr-2 btn-icon float-right"
                      color="primary"
                      onClick={ () => {
                        updateListView(false);
                        setPackageId(null);
                      } }
                    >
                      Add Wedding Package
                    </Button>
                    <Table
                      size="md"
                      responsive
                      striped
                      bordered
                      hover
                      className="mb-3"
                    >
                      <thead>
                        <tr>
                          {columns.map((value, index) => {
                            if (value.isSortable) {
                              return (
                                <th
                                  key={ index }
                                  className={
                                    toastStates.sortByColumn === value.key
                                    && toastStates.IsSortAsc
                                      ? 'asc pointer'
                                      : toastStates.sortByColumn
                                          === value.key && !toastStates.IsSortAsc
                                        ? 'desc pointer'
                                        : 'pointer'
                                  }
                                  onClick={ () => sortBy(value.key) }
                                >
                                  {value.title}
                                </th>
                              );
                            }
                            return <th key={ index }>{value.title}</th>;
                          })}
                        </tr>

                        <tr>
                          <th>
                            <input
                              type="text"
                              name="PackageName"
                              onKeyUp={ handleSearch }
                              className="searchtext form-control"
                            />{' '}
                          </th>
                          <th> </th>
                          <th> </th>
                          <th> </th>
                          <th />
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {packagesList.length > 0
                          && packagesList.map((value, index) => (
                            <tr key={ index }>
                              <td>{value.packageName}</td>
                              <td>{value.resortName}</td>
                              <td>{value.packageDepositPrice}</td>
                              <td>
                                {!value.isActive && (
                                  <div className="mb-2 mr-2 badge badge-pill badge-danger">
                                    {' '}
                                    Disabled{' '}
                                  </div>
                                )}
                                {value.isActive && (
                                  <div className="mb-2 mr-2 badge badge-pill badge-success">
                                    {' '}
                                    Enabled{' '}
                                  </div>
                                )}
                              </td>
                              <td>
                                <UncontrolledButtonDropdown>
                                  <DropdownToggle
                                    caret
                                    className="mb-2 mr-2"
                                    color="primary"
                                  >
                                    Action
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                                    <DropdownItem
                                      onClick={ () => {
                                        setPackageId(value.weddingPackageID);
                                        updateListView(false);
                                      } }
                                    >
                                      Manage
                                    </DropdownItem>

                                    <DropdownItem divider />

                                    {!value.isActive && (
                                      <DropdownItem
                                        onClick={ () => showConfirmationToDelete(
                                          value.weddingPackageID,
                                          true
                                        ) }
                                      >
                                        Enable
                                      </DropdownItem>
                                    )}
                                    {value.isActive && (
                                      <DropdownItem
                                        onClick={ () => showConfirmationToDelete(
                                          value.weddingPackageID,
                                          false
                                        ) }
                                      >
                                        Disable
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledButtonDropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {packagesList.length > 0 && (
                      <nav aria-label="Page navigation">
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          breakLabel="..."
                          breakClassName="break-me"
                          pageCount={ toastStates.totalPages }
                          marginPagesDisplayed={ 2 }
                          pageRangeDisplayed={ 5 }
                          onPageChange={ handlePageClick }
                          containerClassName="pagination justify-content-center"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          nextClassName="page-item"
                          activeClassName="active"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                        />
                      </nav>
                    )}
                  </CardBody>
                </Card>
              </LoadingOverlay>
            </Container>
          </TransitionGroup>
        </>
      ) : packageId ? (
        <Edit
          weddingPackageId={ packageId }
          updateListView={ updateListView }
          resortId={ resortId }
          hotelId={ hotelId }
        />
      ) : (
        <PackageCreate
          weddingPackageId={ packageId }
          updateListView={ updateListView }
          resortId={ resortId }
          hotelId={ hotelId }
        />
      )}
    </div>
  );
}

export default Wedding;
