/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import { TextField } from '../../../../common/Form/TextInput';
import { SelectInput } from '../../../../common/Form/SelectInput';
import { CustomDatePicker } from '../../../../common/Form/DatePicker';
import hotel from '../../../../api/hotelContent';
import { formatDate } from 'common/utils/dateUtils';
import { getDate } from 'common/utils/dateUtils';

class PricingMarkup extends React.Component {
  // state = PricingMarkup.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      supplierlist: [],
      supplier: {
        supplierID: '',
        effectiveDate: '',
        expirationDate: '',
        percentageMarkup: '',
        enabled: false,
      },
      pricinglist: [],
      pricingId: '',
    };
  }

  componentDidMount() {
    this.getSupplier();
    this.getPricing();
  }

  validationSchema = Yup.object({
    supplierID: Yup.string().required('The supplier is required.'),
    effectiveDate: Yup.string().required('The effective from is required.'),
    expirationDate: Yup.string().required('The expires is required.'),
    percentageMarkup: Yup.string().required('The mark percentage is required.'),
    airportToHotel: Yup.array(
      Yup.object({
        value: Yup.number('value must be a number.').required(
          'value is required',
        ),
      }),
    ),
    hotelToAirport: Yup.array(
      Yup.object({
        value: Yup.number('value must be a number.').required(
          'value is required',
        ),
      }),
    ),
    roundTrip: Yup.array(
      Yup.object({
        value: Yup.number('value must be a number.').required(
          'value is required',
        ),
      }),
    ),
  });

  updatestaticpricing = id => {
    this.setState({ pricingId: id });
    hotel.staticPricing.getStatictPricing(id).then(response => {
      const pricing = {
        supplierID: response.supplierID,
        effectiveDate: response.effective,
        expirationDate: response.expiration,
        percentageMarkup: response.percentageMarkup,
        enabled: response.enabled,
      };
      this.setState({ supplier: pricing });
      this.setState({ modal: true });
    });
  };

  getPricing = () => {
    const { id } = this.props.match.params;
    hotel.staticPricing.getStatictPricingList(id).then(response => {
      this.setState({ pricinglist: response });
    });
  };

  getSupplier = () => {
    hotel.hotelcontent.supplierlist().then(response => {
      this.setState({ supplierlist: response });
    });
  };

  saveTrasferPrice = value => {
    const newValue = { ...value };
    this.setState({ loading: true });
    newValue.HotelID = this.props.match.params.id;

    newValue.effectiveDate = formatDate(value.effectiveDate, 'yyyy/MM/dd');
    newValue.expirationDate = formatDate(value.expirationDate, 'yyyy/MM/dd');

    const check =
      getDate(newValue.effectiveDate) < getDate(newValue.expirationDate);
    if (!check) {
      toast.error('Expiration date must be greater than the effective date');
      newValue.effectiveDate = formatDate(newValue.effectiveDate, 'MM/dd/yyyy');
      newValue.expirationDate = formatDate(
        newValue.expirationDate,
        'MM/dd/yyyy',
      );
      return;
    }

    if (this.state.pricingId !== '') {
      newValue.staticPricingMarkupID = this.state.pricingId;
      hotel.staticPricing.updateStaticPricing(newValue).then(response => {
        this.setState({ loading: false });
        this.setState({ modal: false });
        this.getPricing();
        toast.success(response);
      });
    } else {
      hotel.staticPricing.saveStaticPricing(newValue).then(response => {
        this.setState({ loading: false });
        this.setState({ modal: false });
        this.getPricing();
        toast.success(response);
      });
    }
  };

  render() {
    const { loading } = this.state.loading;
    return (
      <>
        <Card>
          <CardBody>
            <button
              type="button"
              className="mb-2 mr-2 btn-icon float-right btn btn-primary"
              onClick={() => {
                this.setState({ pricingId: '' });
                this.setState({ modal: true });
                const supplier = {
                  supplierID: '',
                  effectiveDate: '',
                  expirationDate: '',
                  percentageMarkup: '',
                  enabled: false,
                };
                this.setState({ supplier });
              }}
            >
              Add Pricing
            </button>

            <CardTitle>Static Pricing Markup list</CardTitle>

            <Table size="sm" striped bordered hover className="mb-3">
              <thead>
                <tr>
                  <td>Supplier</td>
                  <td>Effective</td>
                  <td>Expires</td>
                  <td>Markup %</td>

                  <td>Enabled</td>
                  <td>Modified</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {this.state.pricinglist.length > 0 &&
                  this.state.pricinglist.map((value, index) => (
                    <tr key={index}>
                      <td>{value.supplierName}</td>
                      <td>{value.effective}</td>
                      <td>{value.expiration}</td>
                      <td>{value.percentageMarkup}</td>
                      <td>{value.enabled ? 'Y' : 'N'}</td>
                      <td>{value.lastModifiedStr}</td>
                      <td>
                        <button
                          type="button"
                          className="mb-2 mr-2 btn-icon btn-pill btn btn-outline-primary"
                          onClick={() =>
                            this.updatestaticpricing(
                              value.staticPricingMarkupID,
                            )
                          }
                        >
                          <i className="pe-7s-tools btn-icon-wrapper"> </i>Edit
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          {this.state.pricingId === '' && (
            <ModalHeader> Add Static Pricing Markup </ModalHeader>
          )}
          {this.state.pricingId !== '' && (
            <ModalHeader>Update Static Pricing Markup </ModalHeader>
          )}
          <Formik
            validationSchema={this.validationSchema}
            enableReinitialize
            initialValues={this.state.supplier}
            onSubmit={values => {
              this.saveTrasferPrice(values);
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormGroup>
                    <SelectInput
                      options={this.state.supplierlist}
                      label="Supplier"
                      name="supplierID"
                    />
                  </FormGroup>

                  <FormGroup>
                    <CustomDatePicker label="Effective" name="effectiveDate" />
                  </FormGroup>

                  <FormGroup>
                    <CustomDatePicker label="Expires" name="expirationDate" />
                  </FormGroup>

                  <FormGroup>
                    <TextField
                      type="number"
                      min="0"
                      label="Mark %"
                      name="percentageMarkup"
                    />
                  </FormGroup>

                  <FormGroup>
                    <label htmlFor="enabled">Enabled</label>

                    <Field type="checkbox" name="enabled" />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    onClick={() => this.setState({ modal: false })}
                  >
                    Close
                  </Button>

                  <Button type="submit" disabled={!!loading} color="primary">
                    {!loading ? <span>Save</span> : <span>Processing....</span>}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      </>
    );
  }
}
export default withRouter(PricingMarkup);
