/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';

// import Ionicon from 'react-ionicons';

import { IoIosCalendar } from 'react-icons/io';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Col,
  Row,
  Button,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import { toast, Bounce } from 'react-toastify';

import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withRouter } from 'react-router-dom';
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import avatar1 from '../../../assets/utils/images/avatars/default.png';
import { userSignedOut } from '../../../auth/authActions';
import { signoutRedirect } from '../../../auth/AuthService';
import ProfileImage from '../../../Pages/Users/AdminUser/UserProfile/ProfileImage';
import styles from '../../../Pages/Users/AdminUser/UserProfile/profile.module.scss'


class UserBox extends React.Component {
  constructor(props) {
    super(props);
    // var userInfo = JSON.parse( localStorage.getItem( "token" ) );
    // console.log(props.user);
    if (props.user) {
      this.state = {
        displayName: props.user.profile.name,
        userEmail: props.user.profile.email,
        userRole: props.user.profile.role,
      };
    }
  }

  notify2 = () => {
    this.toastId = toast(
      "You don't have any new items in your calendar for today! Go out and play!",
      {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'bottom-center',
        type: 'success',
      },
    );
  };

  logout = () => {
    signoutRedirect();
  };
  

  render() {

    const loggedInUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
    const legacyUserId = loggedInUser.profile["custom:DestifyLegacyUserId"].toLowerCase();

    return (
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
        
                   <ProfileImage userId={legacyUserId}  className={styles.userbox_avatar} />
                  <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div
                        className="menu-header-image opacity-2"
                        style={{
                          backgroundImage: `url(${city3})`,
                        }}
                      />

                      {this.state && (
                        <div className="menu-header-content text-left">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                            
                                 <ProfileImage userId={legacyUserId} className={styles.userbox_avatar}/>
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  {this.state.displayName}
                                </div>
                                <div className="widget-subheading opacity-8">
                                  {this.state.userEmail}
                                </div>
                              </div>
                              <div className="widget-content-right mr-2">
                                <Button
                                  className="btn-pill btn-shadow btn-shine"
                                  color="focus"
                                  onClick={this.logout}
                                >
                                  Logout
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="scroll-area-xs"
                    style={{
                      height: '150px',
                    }}
                  >
                    <PerfectScrollbar>
                      <Nav vertical>
                        {/*<NavItem className="nav-item-header">
                          My Account
                        </NavItem>*/}
                        {loggedInUser?.profile && (<NavItem>
                          <NavLink href={`/user/admin-edit/${loggedInUser.profile['cognito:username']}`} >Manage Profile</NavLink>
                        </NavItem>)}
                          {/* <NavItem>
                            <NavLink href={`${process.env.REACT_APP_IDENTITYSERVER}/Manage/ChangePassword`} target="_blank">Change Password</NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink href={`${process.env.REACT_APP_IDENTITYSERVER}/Account/ForgotPasswordForm`} target="_blank">Recover Password</NavLink>
                          </NavItem> */}
                      </Nav>
                    </PerfectScrollbar>
                  </div>
                  <Nav vertical>
                    <NavItem className="nav-item-divider mb-0" />
                  </Nav>
                  <div className="grid-menu grid-menu-2col">
                    <Row className="no-gutters">
                      <Col sm="6">
                        <Button
                          className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline
                          color="warning"
                        >
                          <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2">
                            {' '}
                          </i>
                          Message Inbox
                        </Button>
                      </Col>
                      <Col sm="6">
                        <Button
                          className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                          outline
                          color="danger"
                        >
                          <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                            {' '}
                          </i>
                          <b>Support Tickets</b>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Nav vertical>
                    <NavItem className="nav-item-divider" />
                    <NavItem className="nav-item-btn text-center">
                      <Button size="sm" className="btn-wide" color="primary">
                        Open Messages
                      </Button>
                    </NavItem>
                  </Nav>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
            {this.state && (
              <div className="widget-content-left  ml-3 header-user-info">
                <div className="widget-heading">{this.state.displayName}</div>
                <div className="widget-subheading">{this.state.userEmail}</div>
                <div className="widget-subheading">{this.state.userRole}</div>
              </div>
            )}

            <div className="widget-content-right header-user-info ml-3">
              <Button
                className="btn-shadow p-1"
                size="sm"
                onClick={this.notify2}
                color="info"
                id="Tooltip-1"
              >
                <IoIosCalendar color="#ffffff" fontSize="20px" />
              </Button>
              <UncontrolledTooltip placement="bottom" target="Tooltip-1">
                Click for Toastify Notifications!
              </UncontrolledTooltip>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProp = state => ({
  user: state.auth.user,
  isLoadingUser: state.auth.isLoadingUser,
});

export default withRouter(connect(mapStateToProp, { userSignedOut })(UserBox));
