/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Badge,
} from 'reactstrap';
import ReactTable from 'react-table';
import Filters from './components/Filters';
import { exportToCSV } from 'common/Export/ExportToExcel';
import { ODataTableHelper } from 'common/OData/ODataTableHelper';
import { IODataFilter } from 'common/OData/ODataFilters';
import ImpersonationModal from 'common/Modals/ImpersonationModal';
import { toast } from 'react-toastify';
import { UserApi, WeddingGuestsApi } from 'api/adminApi';
import { isAuthError } from 'api/auth/withAuthentication';
import { signoutRedirect } from 'auth/AuthService';
import { isAxiosError } from 'axios';
import { IoIosInformationCircle } from 'react-icons/io';
import { AdminODataApiClient } from 'api/OData/AdminODataApiClient';
import { OasisAdminGroupLeadersDto } from 'api/adminApi/model';
import { ManualGroupLeaderAssociationComponent } from './components/manual-gl-association';

const API_ENDPOINT = 'OasisAdminGroupLeaders';

const adminOdataApiClient = new AdminODataApiClient();

function GroupLeaderList() {
  const history = useHistory();

  const reactTable = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pages, setPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [impersonationModalVisible, setImpersonationModalVisible] =
    useState<boolean>(false);
  const [impersonationLink, setImpersonationLink] = useState<string | null>(
    null,
  );
  const initialFilters: IODataFilter[] = useMemo(
    () => [
      {
        name: 'WeddingDate',
        modifier: null,
        value: null,
      },
      {
        name: 'NumberRSVPNotBooked',
        modifier: null,
        value: null,
      },
    ],
    [],
  );

  const [filters, setFilters] = useState<IODataFilter[]>(initialFilters);

  const getGroupDetailsUrl = useCallback(
    (groupId: string, userId: string) =>
      `/group/group-leader/${groupId}/user/${userId}`,
    [],
  );

  const fetchData = async (reactTableState, filters) => {
    if (!reactTableState) return;
    setLoading(true);
    let sortData = reactTableState.sorted.map(({ id, desc }) => ({
      columnName: id,
      sortOrder: desc ? 'desc' : 'asc',
    }));
    /*     if (sortData.find(item => item.columnName.toLowerCase() === 'groupname')) {
      const isDesc = reactTableState.sorted.find(item => item.id.toLowerCase() === 'groupname').desc;
      sortData = sortData.filter(item => item.columnName.toLowerCase() !== 'groupname');
      sortData = [...sortData, {
        columnName: 'SpouseNameOne',
        sortOrder: isDesc ? 'desc' : 'asc',
      }, {
        columnName: 'SpouseNameTwo',
        sortOrder: isDesc ? 'desc' : 'asc',
      }, {
        columnName: 'WeddingDate',
        sortOrder: isDesc ? 'desc' : 'asc',
      }]
    } */

    if (sortData.find(item => item.columnName.toLowerCase() === 'fullname')) {
      const isDesc = reactTableState.sorted.find(
        item => item.id.toLowerCase() === 'fullname',
      ).desc;
      sortData = sortData.filter(
        item => item.columnName.toLowerCase() !== 'fullname',
      );
      sortData = [
        ...sortData,
        {
          columnName: 'FirstName',
          sortOrder: isDesc ? 'desc' : 'asc',
        },
        {
          columnName: 'LastName',
          sortOrder: isDesc ? 'desc' : 'asc',
        },
      ];
    }
    const queryOptions = {
      pageSize: reactTableState.pageSize,
      currentPage: reactTableState.page,
      dataTableSort: sortData,
      dataTableFilters: reactTableState.filtered.map(({ id, value }) => ({
        columnName: id,
        value,
      })),
      filters,
    };

    //we need to treat the fullname column differently when searching, as its a computed filed in the Odata endpoint
    const fullNameFilter = queryOptions.dataTableFilters?.find(
      f => f.columnName.toLowerCase() === 'fullname',
    );
    let freeFormColumnFilterArgs;
    if (fullNameFilter) {
      const { value } = fullNameFilter;
      queryOptions.dataTableFilters = queryOptions.dataTableFilters.filter(
        f => f.columnName.toLowerCase() !== 'fullname',
      ); //remove fullname

      freeFormColumnFilterArgs = `(contains(concat( concat(FirstName,' '),LastName),'${value}'))`;
    }

    //we need to treat the groupName column differently when searching, as its a computed filed in the Odata endpoint
    /*     const groupNameFilter = queryOptions.dataTableFilters?.find(
      f => f.columnName.toLowerCase() === 'groupname',
    );
    if (groupNameFilter) {
      const { value } = groupNameFilter;
      queryOptions.dataTableFilters = queryOptions.dataTableFilters.filter(
        f => f.columnName.toLowerCase() !== 'groupname',
      ); //remove fullname

      const groupNameQuery = `contains(concat(concat(concat(concat(SpouseNameOne,' and '),SpouseNameTwo),' Wedding - '),cast(WeddingDate,Edm.String)),'${value}')`
      if (freeFormColumnFilterArgs) {
        freeFormColumnFilterArgs = freeFormColumnFilterArgs + ' and '
        freeFormColumnFilterArgs = freeFormColumnFilterArgs + groupNameQuery;
      }
      else {
        freeFormColumnFilterArgs = groupNameQuery;
      }
    } */

    const queryStringArgs = ODataTableHelper.constructODataQueryArgs(
      queryOptions.pageSize,
      queryOptions.currentPage,
      queryOptions.dataTableFilters,
      queryOptions.dataTableSort,
      queryOptions.filters,
      true,
      freeFormColumnFilterArgs,
    );

    const { data, count } =
      await adminOdataApiClient.getODataListResponseWithCount<OasisAdminGroupLeadersDto>(
        `${API_ENDPOINT}?${queryStringArgs}`,
      );

    const totalDataCount = count;
    setTotalCount(count);
    setTableData(data);
    setPages(Math.ceil(totalDataCount / reactTableState.pageSize));
    setLoading(false);
  };

  const impersonateUser = async (userId: string) => {
    setLoading(true);
    try {
      const { data: link } =
        await new UserApi().apiUserGenerateImpersonationTokenGet(userId);
      setImpersonationLink(link);
      setImpersonationModalVisible(true);
    } catch (e) {
      console.error(e);
      toast.error('Could not generate impersonation link');
    } finally {
      setLoading(false);
    }
  };

  const onSearch = useCallback(() => {
    fetchData(reactTable.current?.state, filters);
  }, [reactTable, filters]);

  const clearAllFilters = useCallback(() => {
    const clearedTableFilterCallback = () => {
      setFilters(initialFilters);
      fetchData(reactTable?.current?.state, initialFilters);
    };

    reactTable.current?.setState(
      { filtered: [], page: 0, pageSize: 10 },
      clearedTableFilterCallback,
    );
  }, [reactTable, initialFilters]);

  const handleExportToCSV = async (
    scope: 'all' | 'currentPage' | 'filtered' | 'rsvp-not-booked',
  ) => {
    setLoading(true);
    let defaultExportFilename = `Group-Leaders-${new Date().toISOString()}`;
    try {
      let csvData = [];
      if (scope === 'currentPage') {
        csvData = [...reactTable.current?.resolvedData];
      }
      if (scope === 'filtered') {
        const reactTableState = reactTable.current?.state;
        let sortData = reactTableState.sorted.map(({ id, desc }) => ({
          columnName: id,
          sortOrder: desc ? 'desc' : 'asc',
        }));
        // if (sortData.find(item => item.columnName.toLowerCase() === 'groupname')) {
        //   const isDesc = reactTableState.sorted.find(item => item.id.toLowerCase() === 'groupname').desc;
        //   sortData = sortData.filter(item => item.columnName.toLowerCase() !== 'groupname');
        //   sortData = [...sortData, {
        //     columnName: 'SpouseNameOne',
        //     sortOrder: isDesc ? 'desc' : 'asc',
        //   }, {
        //     columnName: 'SpouseNameTwo',
        //     sortOrder: isDesc ? 'desc' : 'asc',
        //   }, {
        //     columnName: 'WeddingDate',
        //     sortOrder: isDesc ? 'desc' : 'asc',
        //   }]
        // }

        if (
          sortData.find(item => item.columnName.toLowerCase() === 'fullname')
        ) {
          const isDesc = reactTableState.sorted.find(
            item => item.id.toLowerCase() === 'fullname',
          ).desc;
          sortData = sortData.filter(
            item => item.columnName.toLowerCase() !== 'fullname',
          );
          sortData = [
            ...sortData,
            {
              columnName: 'FirstName',
              sortOrder: isDesc ? 'desc' : 'asc',
            },
            {
              columnName: 'LastName',
              sortOrder: isDesc ? 'desc' : 'asc',
            },
          ];
        }
        const queryOptions = {
          pageSize: reactTableState.pageSize,
          currentPage: reactTableState.page,
          dataTableSort: sortData,
          dataTableFilters: reactTableState.filtered.map(({ id, value }) => ({
            columnName: id,
            value,
          })),
          filters,
        };

        //we need to treat the fullname column differently when searching, as its a computed filed in the Odata endpoint
        const fullNameFilter = queryOptions.dataTableFilters?.find(
          f => f.columnName.toLowerCase() === 'fullname',
        );
        let freeFormColumnFilterArgs;
        if (fullNameFilter) {
          const { value } = fullNameFilter;
          queryOptions.dataTableFilters = queryOptions.dataTableFilters.filter(
            f => f.columnName.toLowerCase() !== 'fullname',
          ); //remove fullname

          freeFormColumnFilterArgs = `(contains(concat( concat(FirstName,' '),LastName),'${value}'))`;
        }

        //we need to treat the groupName column differently when searching, as its a computed filed in the Odata endpoint
        // const groupNameFilter = queryOptions.dataTableFilters?.find(
        //   f => f.columnName.toLowerCase() === 'groupname',
        // );
        // if (groupNameFilter) {
        //   const { value } = groupNameFilter;
        //   queryOptions.dataTableFilters = queryOptions.dataTableFilters.filter(
        //     f => f.columnName.toLowerCase() !== 'groupname',
        //   ); //remove fullname

        //   const groupNameQuery = `contains(concat(concat(concat(concat(SpouseNameOne,' and '),SpouseNameTwo),' Wedding - '),cast(WeddingDate,Edm.String)),'${value}')`
        //   if (freeFormColumnFilterArgs) {
        //     freeFormColumnFilterArgs = freeFormColumnFilterArgs + ' and '
        //     freeFormColumnFilterArgs = freeFormColumnFilterArgs + groupNameQuery;
        //   }
        //   else {
        //     freeFormColumnFilterArgs = groupNameQuery;
        //   }
        // }

        const queryStringArgs = ODataTableHelper.constructODataQueryArgs(
          null,
          queryOptions.currentPage,
          queryOptions.dataTableFilters,
          queryOptions.dataTableSort,
          queryOptions.filters,
          true,
          freeFormColumnFilterArgs,
        );

        const { data } =
          await adminOdataApiClient.getODataListResponseWithCount<OasisAdminGroupLeadersDto>(
            `${API_ENDPOINT}?${queryStringArgs}`,
          );
        csvData = [...data];
      }

      if (scope === 'all') {
        const { data } =
          await adminOdataApiClient.getODataListResponseWithCount<OasisAdminGroupLeadersDto>(
            `${API_ENDPOINT}?$count=true&$orderby=CreatedDate%20desc`,
          );
        csvData = [...data];
      }
      if (scope === 'rsvp-not-booked') {
        defaultExportFilename = `RSVP-Guests-Not-Booked-${new Date().toISOString()}`;
        toast.info(
          <>
            <p>
              Starting report generation. Please wait and do not close your
              browser...(this will take some time)
            </p>
            <p>
              <b>PLEASE ENSURE POPUPS ARE NOT DISABLED</b>
            </p>
          </>,
        );
        try {
          const { data: s3DownloadUrl } =
            await new WeddingGuestsApi().apiWeddingGuestsLegacyRSVPReportGet();
          const aElement = document.createElement('a');
          aElement.setAttribute('download', defaultExportFilename);
          aElement.href = s3DownloadUrl;
          aElement.setAttribute('target', '_blank');
          aElement.click();
        } catch (err) {
          if (isAxiosError(err)) {
            if (isAuthError(err)) {
              toast.error(
                'Unauthorized request detected (login token likely expired)',
              );
              await signoutRedirect();
              return;
            }
          }
          toast.error(
            'An unexpected error has occurred. Please try again or contact the dev team.',
          );
        }
        return;
      }

      exportToCSV(
        {
          dataArray: csvData,
          fileName: defaultExportFilename,
        },
        { forceQuotes: true },
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <ManualGroupLeaderAssociationComponent
              onSuccess={() => {
                fetchData(reactTable?.current?.state, initialFilters);
              }}
            />
            <Filters
              filters={filters}
              clearAllFilters={clearAllFilters}
              onSearch={onSearch}
              setFilters={setFilters}
            />

            <Row>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardBody>
                    <Row>
                      <Col>
                        <span>
                          <strong>Total Group Leaders: </strong>
                          {totalCount}
                        </span>
                      </Col>
                    </Row>
                    <ReactTable
                      manual
                      data={tableData}
                      pages={pages}
                      loading={loading}
                      onFetchData={reactTableState =>
                        fetchData(reactTableState, filters)
                      }
                      filterable
                      defaultPageSize={10}
                      className="-striped -highlight"
                      multiSort={false}
                      defaultSorted={[
                        {
                          // the sorting model for the table
                          id: 'CreatedDate',
                          desc: true,
                        },
                      ]}
                      ref={reactTable}
                      columns={[
                        {
                          Header: 'Date Created',
                          accessor: 'CreatedDate',
                          filterable: false,
                          Cell: ({ row }) =>
                            new Date(
                              row._original.CreatedDate,
                            ).toLocaleString(),
                        },
                        {
                          Header: 'Full Name',
                          accessor: 'FullName',
                          Cell: ({ row }) => (
                            <Link
                              to={getGroupDetailsUrl(
                                row._original.GroupId,
                                row._original.Id,
                              )}
                            >
                              {row._original.FullName}
                            </Link>
                          ),
                        },
                        {
                          Header: 'Email',
                          accessor: 'Email',
                          className: 'text-center',
                        },
                        {
                          Header: 'Wedding Date',
                          accessor: 'WeddingDate',
                          className: 'text-center',
                          //Cell: props => formatDate(props.value),
                          Cell: ({ row }) =>
                            row._original.WeddingDate
                              ? new Date(
                                  row._original.WeddingDate,
                                ).toLocaleString()
                              : 'Not Available',
                          filterable: false,
                        },
                        // {
                        //   Header: 'Wedding Website Build Date',
                        //   accessor: 'WebsiteBuildDate',
                        //   Cell: props => formatDate(props.value),
                        //   filterable: false,
                        // },
                        // {
                        //   Header: 'Wedding Website Theme',
                        //   accessor: 'WebsiteThemeName',
                        //   Cell: ({ row }) => (
                        //     <>
                        //       {row._original.WebsiteThemeName
                        //         ? row._original.WebsiteThemeName
                        //         : 'N/A'}
                        //     </>
                        //   ),
                        // },
                        // {
                        //   Header: () => (
                        //     <>
                        //       <span
                        //         id="isActiveToolTip"
                        //         style={{
                        //           color: 'blue',
                        //           textDecoration: 'underline',
                        //           cursor: 'pointer',
                        //         }}
                        //       >
                        //         <IoIosInformationCircle
                        //           size={'20px'}
                        //           style={{ marginRight: '5px' }}
                        //         />
                        //       </span>
                        //       Active?
                        //       <UncontrolledTooltip
                        //         placement="top"
                        //         target="isActiveToolTip"
                        //       >
                        //         At present, this field currently is not being
                        //         used for any business logic anywhere in the
                        //         Destify ecosystem.
                        //         <br />
                        //         It merely exists in the database and is being
                        //         shown to you for display purposes.
                        //       </UncontrolledTooltip>
                        //     </>
                        //   ),
                        //   accessor: 'IsActive',
                        //   filterable: false,
                        //   width: 75,
                        //   className: 'text-center',
                        //   Cell: ({ row }) => (
                        //     <>
                        //       {row._original.IsActive == null ? (
                        //         <Badge color={'danger'}>UNKNOWN</Badge>
                        //       ) : row._original.IsActive === true ? (
                        //         'YES'
                        //       ) : (
                        //         'NO'
                        //       )}
                        //     </>
                        //   ),
                        // },
                        {
                          Header: 'Group Id',
                          accessor: 'GroupId',
                          className: 'text-center',
                          Cell: ({ row }) => (
                            <>
                              {row._original.GroupId ? (
                                <a
                                  href={`${process.env.REACT_APP_CRMLINK.toString().replace(
                                    'Opportunities',
                                    'Accounts',
                                  )}${row._original.GroupId}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {row._original.GroupId}
                                </a>
                              ) : (
                                'N/A'
                              )}
                            </>
                          ),
                        },
                        // {
                        //   Header: 'Resort Name',
                        //   accessor: 'ResortName',
                        //   Cell: ({ row }) => (
                        //     <>
                        //       {row._original.ResortName
                        //         ? row._original.ResortName
                        //         : 'N/A'}
                        //     </>
                        //   ),
                        // },
                        {
                          Header: () => (
                            <>
                              <span
                                id="activeRoomHeaderToolTip"
                                style={{
                                  color: 'blue',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                              >
                                <IoIosInformationCircle
                                  size={'20px'}
                                  style={{ marginRight: '5px' }}
                                />
                              </span>
                              # of Active Rooms Booked
                              <UncontrolledTooltip
                                placement="top"
                                target="activeRoomHeaderToolTip"
                              >
                                This information comes from the CRM and cannot
                                be filtered/sorted
                              </UncontrolledTooltip>
                            </>
                          ), //'# of Active Rooms Booked',
                          accessor: 'NumberOfRoom',
                          filterable: false,
                          sortable: false,
                          className: 'text-center',
                          width: 130,
                          Cell: row =>
                            !row.original.NumberOfRoom ? (
                              <Badge color={'danger'}>MISSING DATA</Badge>
                            ) : (
                              row.original.NumberOfRoom
                            ),
                        },
                        {
                          Header: '# of RSVP YES',
                          accessor: 'NumberOfRSVPYes',
                          className: 'text-center',
                          width: 150,
                          filterable: false,
                        },
                        {
                          Header: '# RSVP YES Not Booked',
                          accessor: 'NumberRSVPNotBooked',
                          className: 'text-center',
                          width: 150,
                          filterable: false,
                        },
                        {
                          columns: [
                            {
                              Header: 'Action',
                              sortable: false,
                              filterable: false,
                              className: 'text-center',
                              Cell: ({ row }) => (
                                <UncontrolledButtonDropdown>
                                  <DropdownToggle
                                    caret
                                    className="mb-2 mr-2"
                                    color="primary"
                                  >
                                    Action
                                  </DropdownToggle>
                                  <DropdownMenu
                                    positionFixed={true}
                                    className="dropdown-menu-rounded dropdown-menu-lg"
                                  >
                                    <DropdownItem
                                      onClick={() =>
                                        history.push(
                                          getGroupDetailsUrl(
                                            row._original.GroupId,
                                            row._original.Id,
                                          ),
                                        )
                                      }
                                    >
                                      Manage
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        impersonateUser(row._original.Id)
                                      }
                                    >
                                      Impersonate
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledButtonDropdown>
                              ),
                            },
                          ],
                        },
                      ]}
                      pageSizeOptions={[10, 20, 30, 100]}
                      showPaginationTop
                      showPaginationBottom
                    />

                    <>
                      <Row>
                        <Col>
                          <span>
                            <strong>Total Group Leaders: </strong>
                            {totalCount}
                          </span>
                        </Col>
                      </Row>
                      <UncontrolledButtonDropdown>
                        <DropdownToggle
                          caret
                          className="mb-2 mr-2"
                          color="primary"
                        >
                          Download .csv
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                          <DropdownItem
                            onClick={() => handleExportToCSV('currentPage')}
                          >
                            Current Page
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleExportToCSV('filtered')}
                          >
                            Filtered Leads
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleExportToCSV('all')}
                          >
                            All Leads
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => handleExportToCSV('rsvp-not-booked')}
                          >
                            RSVP Guests Not Booked
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <ImpersonationModal
        isOpen={impersonationModalVisible}
        closeUserDetailPopup={() => setImpersonationModalVisible(false)}
        impersonationLink={impersonationLink}
      />
    </>
  );
}

export default withRouter(GroupLeaderList);
