import React from 'react';
import { Card, CardSubtitle, CardBody } from 'reactstrap';
import IRoomDetailsRecord from './models/IRoomDetailsRecord';


export const ClientRoomDetailsCard: React.FC<{ roomDetails: IRoomDetailsRecord }> =
    ({
        roomDetails
    }) => {

        if (!roomDetails) return null;

        return (
            <Card>
                <CardBody>
                    <CardSubtitle className="mb-0">
                        <span className="pe-2">
                            Room Type:{' '}
                            <b className="text-dark">
                                {roomDetails.roomType}
                            </b>
                        </span>{' '}
                    </CardSubtitle>
                    <CardSubtitle className="mb-0">
                        <span className="pe-2">
                            Travel Date:{' '}
                            <b className="text-dark">
                                {' '}
                                {roomDetails.travelDate}
                            </b>
                        </span>
                    </CardSubtitle>
                    <CardSubtitle className="mb-0">
                        <span className="pe-2">
                            Travel Start Date:{' '}
                            <b className="text-dark">
                                {roomDetails.travelStartDate}
                            </b>
                        </span>
                    </CardSubtitle>
                    <CardSubtitle className="mb-0">
                        <span className="pe-2">
                            Travel End Date:{' '}
                            <b className="text-dark">
                                {roomDetails.travelEndDate}
                            </b>
                        </span>
                    </CardSubtitle>
                    <CardSubtitle className="mb-0">
                        <span className="pe-2">
                            Total Package Amount:
                            <b className="text-dark">
                                {' $'}
                                {roomDetails.totalPackageAmount}
                            </b>
                        </span>
                    </CardSubtitle>
                    <CardSubtitle className="mb-0">
                        <span className="pe-2">
                            Room Status:{' '}
                            <b className="text-dark">
                                {roomDetails.roomStatus}
                            </b>
                        </span>
                    </CardSubtitle>
                </CardBody>
            </Card>
        )
    }