import { Formik, Form } from 'formik';
import { TextField } from 'common/Form/TextInput';
import { CustomDatePicker } from 'common/Form/DatePicker';
import SelectField from 'common/Form/Select';
import React, { useMemo, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, FormGroup } from 'reactstrap';
import * as Yup from 'yup';
import { useSalesLeadSlice } from '../../slice';
import { IUpdateAccountWeddingDetails, WeddingDetails } from '../../slice/types';
import { selectLoadingUpdateWeddingDetails } from '../../slice/selectors';
import { useAppDispatch,useAppSelector } from 'store/hooks';
import { getUserFromLocalStorage } from 'auth/AuthService';

const isLgbtqOptions = [ true, false ];
const spouseOptions = [
  { weddingRoleId: 0, roleName: '--UNSELECT--' },
  { weddingRoleId: 1, roleName: 'Bride' },
  { weddingRoleId: 2, roleName: 'Groom' },
];

const luxuryLevelOptions = [
  { luxuryLevelId: 1, luxuryLevel: 'Budget' },

  { luxuryLevelId: 2, luxuryLevel: 'Mid-Tier' },
  { luxuryLevelId: 3, luxuryLevel: 'High-End' },

  { luxuryLevelId: 4, luxuryLevel: 'Luxury' },
];

const estimatedBudgetOptions = [
  { estimatedBudgetId: 1, estimatedBudget: '$1,000-$3,000' },

  { estimatedBudgetId: 2, estimatedBudget: '$3,001-$5,000' },

  { estimatedBudgetId: 3, estimatedBudget: '$5,001-$10,000' },

  { estimatedBudgetId: 4, estimatedBudget: '$10,001-$25,000' },

  { estimatedBudgetId: 5, estimatedBudget: '$25,000+' },
];

const travelInformationOptions = [
  { travelInformationId: 1, travelInformation: 'Adults Only (18+)' },
  { travelInformationId: 2, travelInformation: 'Family friendly' },
];

const MixedOptionSchema = (options: any[], valueKey: string, isNullable = false) => {
  const Schema = Yup.number().oneOf(options.map(option => option[valueKey]),'Please select a valid option!')
  return Yup.lazy(value => {
    return typeof value === 'object'
      ? Yup.object({
        label: Yup.string(),
        value: Yup.number().nullable()
          .oneOf(options.map(option => option[valueKey]),'Please select a valid option!')
      })
      : isNullable ? Schema.nullable() : Schema.nonNullable()
  });
}

const validationSchema = Yup.object({
  estimatedGuest: Yup.number().required(
    'Please enter estimated number of guests!',
  ),
  estimatedBudget: MixedOptionSchema(
    estimatedBudgetOptions,
    'estimatedBudgetId',
    true
  ),
  spouseNameOne: Yup.string().required('Spouse name one is required!'),
  spouseNameTwo: Yup.string().required('Spouse name two is required!'),  
  weddingSpouseTwoRole: MixedOptionSchema(spouseOptions, 'weddingRoleId'),
  travelInformation: MixedOptionSchema(
    travelInformationOptions,
    'travelInformationId',
  ),
  weddingLuxuryLevel: MixedOptionSchema(luxuryLevelOptions, 'luxuryLevelId', true),
  anticipatedWeddingDate: Yup.date().required(
    'Anticipated wedding date is required!',
  ),
});

const _AccountDetails = ({
  accountDetails,
}: {
  accountDetails: WeddingDetails;
}) => {
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const { actions } = useSalesLeadSlice();
  const user = getUserFromLocalStorage();
  const isLoadingUpdateDetails = useAppSelector(selectLoadingUpdateWeddingDetails)

  const updateAccountDetailsHandler = (data) => {
    const reqObj: IUpdateAccountWeddingDetails = {
      EstimatedGuest: data.estimatedGuest,
      EstimatedBudget: data.estimatedBudget.value,
      TravelInformationId: data.travelInformation.value,
      SpouseNameOne: data.spouseNameOne,
      SpouseNameTwo: data.spouseNameTwo,
      AnticipatedWeddingDate: data.anticipatedWeddingDate,
      LGBTQWedding: Boolean(data.lgbtqWedding.value),
      LuxuryLevelIds: data.weddingLuxuryLevel?.value ?  [data.weddingLuxuryLevel.value] : null,
      WeddingSpouseTwoRoleId: data.weddingSpouseTwoRole.value,
      Id: accountDetails.weddingId,
      AdminUserId: user.profile.username,
    };

    dispatch(actions.updateSalesLeadAccountWeddingDetails(reqObj));

    setIsEditing(false);
  };

  const initialFormValues = useMemo(() => {
    if (!Object.keys(accountDetails).length) return {};
    const {
      anticipatedWeddingDate,
      estimatedGuest,
      spouseNameOne,
      spouseNameTwo,
      lgbtqWedding,
      weddingSpouseTwoRoleId,
      weddingSpouseTwoRole,
      estimatedBudgetId,
      estimatedBudget,
      travelInformationId,
      travelInformation,
      weddingLuxuryLevelList,
    } = accountDetails;
    return {
      estimatedGuest,
      estimatedBudget: {
        value: estimatedBudgetId || null,
        label: estimatedBudget || ""
      },
      spouseNameOne,
      spouseNameTwo,
      lgbtqWedding: {
        value: lgbtqWedding || null,
        label: lgbtqWedding || ""
      },
      weddingSpouseTwoRole: {
        value: weddingSpouseTwoRoleId || null,
        label: weddingSpouseTwoRole || ""
      },
      travelInformation: {
        value: travelInformationId || null,
        label: travelInformation || ""
      },
      anticipatedWeddingDate,
      weddingLuxuryLevel: {
        value: weddingLuxuryLevelList?.[0]?.luxuryLevelId || null,
        label: weddingLuxuryLevelList?.[0]?.luxuryLevel || "",
      }
    };
  }, [accountDetails]);

  return (
    <Card>
      <CardHeader>
        <h5>Account Details</h5>
      </CardHeader>
      <CardBody>
        <Formik
          onSubmit={updateAccountDetailsHandler}
          initialValues={initialFormValues}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ resetForm, dirty }) => {
            return (
              <Form>
                <Row>
                  <Col sm={12} md={4}>
                    <FormGroup>
                      <CustomDatePicker
                        disabled={!isEditing}
                        dateFormat={'MM/dd/yyyy'}
                        label={'Anticipated Wedding Date:'}
                        name={'anticipatedWeddingDate'}
                        className={"form-control form-control-sm"}
                      />
                    </FormGroup>

                    <FormGroup>
                      <TextField
                        type="number"
                        disabled={!isEditing}
                        label={'# of Guests:'}
                        name={'estimatedGuest'}
                        className={"form-control form-control-sm"}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={12} md={4}>
                    <FormGroup>
                      <TextField
                        disabled={!isEditing}
                        label={'Spouse 1 Name:'}
                        name={'spouseNameOne'}
                        className={"form-control form-control-sm"}
                      />
                    </FormGroup>

                    <FormGroup>
                      <TextField
                        disabled={!isEditing}
                        label={'Spouse 2 Name:'}
                        name={'spouseNameTwo'}
                        className={"form-control form-control-sm"}
                      />
                    </FormGroup>

                    <FormGroup>
                      <SelectField
                        disabled={!isEditing}
                        label={'Spouse 2 Role:'}
                        // showClearOption
                        name={'weddingSpouseTwoRole'}
                        // toggleSelect
                        items={spouseOptions}
                        extractor={item => ({
                          label: item.roleName,
                          value: item.weddingRoleId,
                        })}
                        size={"small"}
                      />
                    </FormGroup>

                    <FormGroup>
                      <SelectField
                        disabled={!isEditing}
                        label={'LGBTQ Wedding:'}
                        name={'lgbtqWedding'}
                        items={isLgbtqOptions}
                        extractor={item => ({
                          label: item.toString(),
                          value: item,
                        })}
                        size={"small"}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={12} md={4}>
                    <FormGroup>
                      <SelectField
                        disabled={!isEditing}
                        label={'Est. Wedding Budget:'}
                        name={'estimatedBudget'}
                        showClearOption
                        items={estimatedBudgetOptions}
                        extractor={item => ({
                          label: item.estimatedBudget,
                          value: item.estimatedBudgetId,
                        })}
                        size={"small"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <SelectField
                        disabled={!isEditing}
                        label={'Luxury Level:'}
                        name={'weddingLuxuryLevel'}
                        showClearOption
                        items={luxuryLevelOptions}
                        extractor={item => ({
                          label: item.luxuryLevel,
                          value: item.luxuryLevelId,
                        })}
                        render={item => {
                          return (
                            <span
                              className="badge badge-pill badge-info"
                              key={item.luxuryLevelId}
                            >
                              {item.luxuryLevel}
                            </span>
                          );
                        }}
                        size={"small"}
                      />
                    </FormGroup>
                    <FormGroup>
                      <SelectField
                        disabled={!isEditing}
                        label={'Travel Information:'}
                        name={'travelInformation'}
                        items={travelInformationOptions}
                        extractor={item => ({
                          label: item.travelInformation,
                          value: item.travelInformationId,
                        })}
                        size={"small"}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={4}>
                    <FormGroup>
                      {!isEditing && (
                        <Button
                          disabled={isLoadingUpdateDetails}
                          onClick={() => setIsEditing(true)}
                          type={'button'}
                        >
                          {isLoadingUpdateDetails ? 'processing...' : 'Edit'}
                        </Button>
                      )}
                      {isEditing && (
                        <>
                          <Button
                            className="mb-2 mr-2 btn-icon float-left"
                            color={'danger'}
                            type={'reset'}
                            onClick={() => {
                              resetForm();
                              setIsEditing(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="mb-2 mr-2 btn-icon float-left"
                            color={'primary'}
                            disabled={!dirty || isLoadingUpdateDetails}
                            type={'submit'}
                          >
                            Submit
                          </Button>
                        </>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default _AccountDetails;
