import React, { useState, useEffect, memo, useCallback } from 'react';
import { Button, Col, Row } from 'reactstrap';
import {
  SALES_LEAD_UPSELL_CATEGORIES,
  SalesLeadUpsells,
} from 'Pages/SalesLead/slice/types';
import { destifyAdminApiRequests } from 'api/common';
import { toast } from 'react-toastify';
import salesLeadApi from 'api/salesLead';
import Loader from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay';
import { useParams } from "react-router"

import RecommendedResort from "./_RecommendResort"

//sigh.... https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined;

interface SelectlistItem {
  label: string;
  value: string;
}

/**
 * weddingDetailsId = Id column on the SalesJourney-> WeddingDetails table
 * @returns
 */
const _RecommendedResorts = ({
  weddingDetailsId,
  maxRecommendedResorts = 5,
}: {
  weddingDetailsId: number;
  maxRecommendedResorts?: number;
}) => {
  //houses our raw api objects
  const [recommendedResortUpsells, setRecommendedResortUpsells] = useState<
    SalesLeadUpsells[]
  >([]);

  const {id:userId} = useParams<{id:string}>()

  //network loading indicator
  const [loading, setIsLoading] = useState<boolean>(false);

  //this is important, because we want to "refresh" the list on load and on changes.
  //on load, if we fail, we show an overlay on the component "surface" with a try again button
  //and imho, it's important we ensure things are correctly refreshed on both successful/failed change attempts
  const [loadingError, setLoadingError] = useState<boolean>(false);

  //hard coded list of controls to render.
  //fill up with nulls when not all 5 are being used, just to still render all 5 dropdowns
  const [dropdownControlList, setDropdownControlList] = useState<
    SelectlistItem[]
  >(new Array(maxRecommendedResorts).fill(null));

  const loadRecommendedResortUpsells = useCallback(async () => {
    setIsLoading(true);
    setLoadingError(false);
    try {
      const recommendedResorts =
        await salesLeadApi.salesLeadDetails.getSalesLeadUpsells(
          weddingDetailsId,
          SALES_LEAD_UPSELL_CATEGORIES.RecommendedResorts,
        );
      setRecommendedResortUpsells(recommendedResorts);
    } catch (error) {
      setLoadingError(true);
    } finally {
      setIsLoading(false);
    }
  }, [weddingDetailsId]);


  const saveResortRecommendation = async (resortGuid: string) => {
    if (!resortGuid) return;
    setIsLoading(true);
    try {
      const recommendedResortUpsell = [
        {
          upsellCategory: SALES_LEAD_UPSELL_CATEGORIES.RecommendedResorts,
          salesLeadWeddingDetailsRecordId: weddingDetailsId,
          upsellRecordItemValue: resortGuid,
        },
      ];
      await salesLeadApi.salesLeadDetails.saveSalesLeadUpsells(
        recommendedResortUpsell,
      );
      toast.success('Recommended resort added successfully!');
    } catch (error) {
      toast.error(
        'Unable to add recommended resort. Please try again or contact the dev team.',
      );
    } finally {
      setIsLoading(false);
      loadRecommendedResortUpsells();
    }
  };

  const removeResortRecommendation = async (
    upsellDbRecordId: number | undefined,
  ) => {
    if (!upsellDbRecordId) return;
    setIsLoading(true);
    try {
      await salesLeadApi.salesLeadDetails.removeSalesLeadUpsells({
        upsellRecordItemIds: [upsellDbRecordId],
      });
      toast.success('Recommended resort removed successfully!');
    } catch (error) {
      toast.error(
        'Unable to remove recommended resort. Please try again or contact the dev team.',
      );
    } finally {
      setIsLoading(false);
      loadRecommendedResortUpsells();
    }
  };

  //load existing resort recommendations
  useEffect(() => {
    if (weddingDetailsId) {
      loadRecommendedResortUpsells();
    }
  }, [loadRecommendedResortUpsells, weddingDetailsId]);

  //when we've loaded the existing list of recommended resorts, then, get their corresponding
  //hotel name, to pre-select the corresponding option in the dropdown
  useEffect(() => {
    const controller = new AbortController();
    const populateRecommendedResortDropdowns = async () => {
      setLoadingError(false);
      setIsLoading(true);
      try {
        const findResortMatch = async (resortIds: string[]) => {
          try {
            const resortDetailRequests = await Promise.all(
              resortIds.map(r =>
                destifyAdminApiRequests.get(`Hotel/${r}`, {
                  signal: controller.signal,
                }),
              ),
            );
            const results = resortDetailRequests.map(r => ({
              label: r.hotelFriendlyName,
              value: r.hotelID,
            }));
            return results;
          } catch (error) {
            if (!controller.signal.aborted) throw error;
          }
          return [];
        };
        //pass the hotelIds/resortIds to lookup service
        let resortMatches = [];
        if (recommendedResortUpsells?.length > 0) {
          resortMatches = await findResortMatch([
            ...recommendedResortUpsells.map(u => u.upsellRecordItemValue),
          ]);
        }
        while (resortMatches.length < maxRecommendedResorts) {
          //fill up the delta with nulls, to ensure controls are still rendered
          resortMatches.push(null);
        }
        setDropdownControlList([...resortMatches]);
      } catch (error) {
        setLoadingError(true);
      } finally {
        setIsLoading(false);
      }
    };
    populateRecommendedResortDropdowns();
    return () => {
      controller.abort();
    };
  }, [recommendedResortUpsells, maxRecommendedResorts]);

  return (
    <>
      <hr></hr>
      <h5>Recommend Resorts</h5>
      <LoadingOverlay
        tag="div"
        active={loading || loadingError}
        styles={{
          overlay: base => ({
            ...base,
            background: '#c9c9c9',
            opacity: 0.9,
            marginRight: '-15px',
            marginLeft: '-15px',
            width: '105%',
            zIndex: 5,
          }),
        }}
        text={
          !loading &&
          loadingError && (
            <p>
              Could not load list of recommended resorts.
              <br />
              Please{' '}
              <Button size="small" onClick={loadRecommendedResortUpsells}>
                try again
              </Button>{' '}
              or contact the dev team.
            </p>
          )
        }
        spinner={
          loading && !loadingError ? (
            <Loader active type={'ball-pulse'} />
          ) : null
        }
      >
        {dropdownControlList.map((val, idx) => {
          const upsellRecordItemId = recommendedResortUpsells?.find(
            u => u.upsellRecordItemValue === val?.value,
          )?.upsellRecordItemId;
          return (
            <Row key={idx} style={{ background: idx % 2 ? '' : '#f9f9f9' }}>
              <Col xs={12}>
                  <RecommendedResort 
                  userId={userId}
                  removeResortRecommendation={removeResortRecommendation}
                  saveResortRecommendation={saveResortRecommendation}
                  upsellRecordItemId={upsellRecordItemId}
                  initialValue={val}
                  id={idx + 1}
                  />
              </Col>
            </Row>
          );
        })}
      </LoadingOverlay>
    </>
  );
};

export default memo(_RecommendedResorts);
