import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

export interface Props {
  targetForm?: string;
  children: React.ReactNode;
  title: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onSave?: () => void;
  buttonLabels?: {
    save?: string,
    cancel?: string,
  },
  disabled?:boolean
}

const Index: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  onSave = null,
  title,
  disabled = false,
  buttonLabels = {},
  targetForm
}) => {
  const {cancel: CancelLabel = "CANCEL",save: SaveLabel = "SAVE"} = buttonLabels;
  return (
    <>
      <Modal isOpen={isOpen}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button color="danger" type="button" onClick={onClose}>
            {CancelLabel}
          </Button>
          {onSave && <Button color="primary" type='submit' form={targetForm} disabled={disabled} onClick={onSave}>
            {SaveLabel}
          </Button>}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Index;
