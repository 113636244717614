import React from 'react';

import { Card, CardBody, Row, Col } from 'reactstrap';

import styles from './style.module.scss';
import { FlightInfoDetails, TransferInfoDetails } from '../Reservations/slice/types';
import { getFormattedDate } from '../../../utils/utils';


export interface TransferInfoProps {
    transferReservation: TransferInfoDetails;

}

export const TransferInfo = ({ transferReservation }: TransferInfoProps): JSX.Element => {

  return (
    <>

    <Row>
        <Col xs={12} md={6}>
        <Card className={styles.custom_card} >
            
            <CardBody>
                <label className="list-details">
                Transfer Type:{' '}
                <div className="list-details-value">
                    { transferReservation.transferType ? transferReservation.transferType : 'N/A'}
                </div>
                </label>

                <label className="list-details">
                # of Adults:{' '}
                <div className="list-details-value">
                { transferReservation.numberOfAdults}
                </div>
                </label>
                <label className="list-details">
                # of Children:{' '}
                <div className="list-details-value">
                { transferReservation.numberOfChildren}
                </div>
                </label>

                <label className="list-details">
                Arrival Date{' '}
                <div className="list-details-value">
                     { getFormattedDate(transferReservation.flightInformation.arrivalDate, { year: 'numeric', month: 'long', day: 'numeric'}) }
                 
                </div>
                </label>

                <label className="list-details">
                    Departure Date:{' '}
                <div className="list-details-value">
                { getFormattedDate(transferReservation.flightInformation.departureDate, { year: 'numeric', month: 'long', day: 'numeric'}) }
                </div>
                </label>
    
                </CardBody>
            </Card>
            </Col>
        </Row>
            
    </>
  );
};

