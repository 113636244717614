import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Button,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  Input,
  InputGroup,
  FormFeedback,
} from 'reactstrap';
import ReactTable, { Column } from 'react-table';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { WeddingGuestsApi } from 'api/adminApi/api/wedding-guests-api';
import { WeddingGuestDetailsDto } from 'api/adminApi/model';
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
  IoIosEye,
  IoIosHelpCircleOutline,
  IoIosList,
  IoIosPersonAdd,
  IoIosTrash,
  IoIosUndo,
} from 'react-icons/io';
import { NoteItem } from 'Pages/SalesLead/SalesLead/Notes/_Note';
import styles from './style.module.scss';
import { toast } from 'react-toastify';
import AddNew from './components/addNew';
import axios from 'axios';
import { LegacyRSVPList } from './components/legacyRSVPList';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalendarAlt, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faCalendarAlt, faUndo);

interface Props {
  groupId:string;
}

type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>>
    ? T[P]
    : T[P] | undefined;
};

export const RSVP: React.FC<Props> = ({ groupId }) => {
  const [loading, setLoading] = useState(false);
  const [rsvpData, setRsvpData] = useState<WeddingGuestDetailsDto[]>([]);
  const [editingRows, setEditingRows] = useState<WeddingGuestDetailsDto[]>([]);
  const [tablePage, setTablePage] = useState<number>(0);
  const [addNewIsOpen, setAddNewIsOpen] = useState<boolean>(false);
  const [rsvpView, setRsvpView] = useState<'legacy' | 'new'>('new');
  const [hoveringLegacyButton, setHoveringLegacyButton] = useState(false);

  const updateRecord = <K extends keyof WeddingGuestDetailsDto>(
    currentRecordId: number,
    fieldName: K,
    value: WeddingGuestDetailsDto[K],
  ) => {
    const updatedRecords = editingRows.filter(r => r.id !== currentRecordId);
    const recordToEdit = editingRows.find(r => r.id === currentRecordId);
    recordToEdit[fieldName] = value;
    setEditingRows([...updatedRecords, recordToEdit]);
  };

  const toggleEdit = (weddingGuestRecord: WeddingGuestDetailsDto) => {
    if (isEditing(weddingGuestRecord)) {
      setEditingRows(editingRows.filter(r => r.id !== weddingGuestRecord.id));
    } else {
      setEditingRows([...editingRows, weddingGuestRecord]);
    }
  };

  const toggleSoftDelete = async (
    weddingGuestRecord: WeddingGuestDetailsDto,
  ) => {
    try {
      await new WeddingGuestsApi().apiWeddingGuestsIdPut(
        weddingGuestRecord.id,
        {
          ...(weddingGuestRecord as Complete<WeddingGuestDetailsDto>),
          isDeleted: !weddingGuestRecord.isDeleted,
        },
      );
      await loadRsvpData();
      setTablePage(0);
      toast.success(
        <>
          Record <b>{weddingGuestRecord.id}</b> successfully{' '}
          {!weddingGuestRecord.isDeleted ? 'soft-deleted' : 'restored'} !
        </>,
      );
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        try {
          const {
            response: {
              data: { errors },
            },
          } = error;

          const apiErrors = [];
          if (typeof errors === 'object') {
            for (const err in errors) {
              apiErrors.push({ [err]: errors[err].join('\n') });
            }
          }

          toast.error(
            <>
              An API error occurred.
              <br />
              Error Details:
              <ul>
                {apiErrors.map(apiError => {
                  return (
                    <li>
                      <b>{Object.keys(apiError)[0]}</b>:{' '}
                      {apiError[Object.keys(apiError)[0]]}
                    </li>
                  );
                })}
              </ul>
            </>,
          );
          return;
        } catch {}
      }
      toast.error(
        <>
          An unknown API error occurred.
          <br />
          Please try again or contact the dev team.
        </>,
      );
    }
  };

  const isEditing = (weddingGuestRecord: WeddingGuestDetailsDto) =>
    editingRows.map(r => r.id).includes(weddingGuestRecord.id);

  const saveUpdatedRecord = async (originalRecord: WeddingGuestDetailsDto) => {
    //call api
    try {
      await new WeddingGuestsApi().apiWeddingGuestsIdPut(originalRecord.id, {
        ...editingRows.find(r => r.id === originalRecord.id),
      });
      toggleEdit(originalRecord);
      await loadRsvpData();
      setTablePage(0);
      toast.success(
        <>
          Changes successfully saved to record <b>{originalRecord.id}</b>!
        </>,
      );
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        try {
          const {
            response: {
              data: { errors },
            },
          } = error;

          const apiErrors = [];
          if (typeof errors === 'object') {
            for (const err in errors) {
              apiErrors.push({ [err]: errors[err].join('\n') });
            }
          }

          toast.error(
            <>
              An API error occurred.
              <br />
              Error Details:
              <ul>
                {apiErrors.map(apiError => {
                  return (
                    <li>
                      <b>{Object.keys(apiError)[0]}</b>:{' '}
                      {apiError[Object.keys(apiError)[0]]}
                    </li>
                  );
                })}
              </ul>
            </>,
          );
          return;
        } catch {}
      }
      toast.error(
        <>
          Unable to save changes to record <b>{originalRecord.id}</b>!
          <br />
          Please try again or contact the dev team.
        </>,
      );
    }
  };

  const columns: Column<WeddingGuestDetailsDto>[] = [
    {
      Header: 'Id',
      id: 'id',
      minWidth: 60,
      className: 'text-center',
      accessor: (data: WeddingGuestDetailsDto) => data.id,
    },
    {
      Header: 'Guest Name',
      className: 'text-center',
      id: 'name',
      minWidth: 150,
      accessor: (data: WeddingGuestDetailsDto) => data.name,
      Cell: ({ original }: { original: WeddingGuestDetailsDto }) => {
        if (isEditing(original)) {
          return (
            <Input
              value={editingRows.find(r => r.id === original.id).name}
              onChange={({ target: { value } }) => {
                updateRecord(original.id, 'name', value);
              }}
              maxLength={500}
              placeholder=""
              type="text"
              bsSize="sm"
            />
          );
        }
        return original.name;
      },
    },
    {
      Header: 'Guest Email',
      id: 'email',
      minWidth: 150,
      className: 'text-center',
      accessor: (data: WeddingGuestDetailsDto) => data.email,
      Cell: ({ original }: { original: WeddingGuestDetailsDto }) => {
        if (isEditing(original)) {
          return (
            <Input
              value={editingRows.find(r => r.id === original.id).email}
              onChange={({ target: { value } }) => {
                updateRecord(original.id, 'email', value);
              }}
              maxLength={100}
              type="email"
              bsSize="sm"
            />
          );
        }
        return original.email;
      },
    },
    {
      Header: 'Phone',
      id: 'phone',
      minWidth: 150,
      className: 'text-center',
      accessor: (data: WeddingGuestDetailsDto) => data.phone,
      Cell: ({ original }: { original: WeddingGuestDetailsDto }) => {
        if (isEditing(original)) {
          return (
            <Input
              value={editingRows.find(r => r.id === original.id).phone}
              onChange={({ target: { value } }) => {
                updateRecord(original.id, 'phone', value);
              }}
              maxLength={20}
              type="tel"
              bsSize="sm"
            />
          );
        }
        return original.phone;
      },
    },
    {
      Header: 'Guest Count',
      id: 'numberOfAttendees',
      className: 'text-center',
      accessor: (data: WeddingGuestDetailsDto) => data.numberOfAttendees,
      Footer: (
        <span>
          <strong>Total Active Guests: </strong>
          {rsvpData
            ? rsvpData
                .filter(r => !r.isDeleted)
                .map(r => r.numberOfAttendees || 0)
                .reduce((partialSum, a) => partialSum + a, 0)
            : '0'}
        </span>
      ),
      Cell: ({ original }: { original: WeddingGuestDetailsDto }) => {
        if (isEditing(original)) {
          return (
            <>
              <Input
                invalid={
                  editingRows.find(r => r.id === original.id)
                    .numberOfAttendees < 0 ||
                  editingRows.find(r => r.id === original.id)
                    .numberOfAttendees > 10
                }
                value={
                  editingRows.find(r => r.id === original.id).numberOfAttendees
                }
                onChange={({ target: { value } }) => {
                  updateRecord(original.id, 'numberOfAttendees', Number(value));
                }}
                type="number"
                bsSize="sm"
                max={10}
                min={0}
              />
              <FormFeedback>Must be between 1 and 10</FormFeedback>
            </>
          );
        }
        return original.numberOfAttendees;
      },
    },
    {
      Header: 'RSVP Status',
      id: 'rsvpStatus',
      className: 'text-center',
      minWidth: 150,
      accessor: (data: WeddingGuestDetailsDto) => data.rsvpStatus,
      Cell: ({ original }: { original: WeddingGuestDetailsDto }) => {
        if (isEditing(original)) {
          return (
            <Input
              value={String(
                editingRows.find(r => r.id === original.id).rsvpStatus,
              )}
              onChange={({ target: { value } }) => {
                const coercedValue = value === '' ? null : value === 'true';
                updateRecord(original.id, 'rsvpStatus', coercedValue);
              }}
              type="select"
              bsSize="sm"
            >
              <option value={''}>No Response</option>
              <option value={'true'}>RSVP Yes</option>
              <option value={'false'}>RSVP No</option>
            </Input>
          );
        }
        return (
          <span>
            {original.rsvpStatus == null ? (
              <IoIosHelpCircleOutline size={25} />
            ) : (
              <Badge color={original.rsvpStatus ? 'success' : 'danger'}>
                {original.rsvpStatus ? (
                  <IoIosCheckmarkCircleOutline size={20} />
                ) : (
                  <IoIosCloseCircleOutline size={20} />
                )}{' '}
                {original.rsvpStatus ? 'RSVP - YES' : 'RSVP - NO'}
              </Badge>
            )}
          </span>
        );
      },
    },
    {
      Header: 'Comments',
      accessor: 'comments',
      minWidth: 300,
      className: 'text-center',
      Cell: ({ original }: { original: WeddingGuestDetailsDto }) => (
        <>
          {original.comments?.filter(c => !!c.comment).length > 0 && (
            <div className="scroll-area-lg" style={{ height: '200px' }}>
              <PerfectScrollbar>
                <div
                  className="p-3"
                  style={{
                    textAlign: 'left',
                    width: '99%',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <VerticalTimeline
                    layout="1-column"
                    className={[
                      'vertical-without-time',
                      styles['vertical-timeline'],
                    ].join(' ')}
                  >
                    {original.comments?.map((comment, idx) => {
                      return (
                        <NoteItem
                          key={`${original.id}-${idx}`}
                          type={'text'}
                          id={comment.createdDate}
                          note={comment.comment}
                          module={'rsvp'}
                          createdAt={comment.createdDate}
                          iconClassName={'text'}
                          iconVariant={'text'}
                          createdBy={original.name}
                        />
                      );
                    })}
                  </VerticalTimeline>
                </div>
              </PerfectScrollbar>
            </div>
          )}
        </>
      ),
    },
    {
      Header: 'CRM Room Id',
      id: 'roomId',
      accessor: (data: WeddingGuestDetailsDto) => data.roomId,
      minWidth: 260,
      className: 'text-center',
      Cell: ({ original }: { original: WeddingGuestDetailsDto }) => {
        if (isEditing(original)) {
          return (
            <InputGroup>
              <Input
                style={{ fontSize: '0.8rem' }}
                bsSize="sm"
                value={editingRows.find(r => r.id === original.id).roomId}
                onChange={({ target: { value } }) => {
                  updateRecord(original.id, 'roomId', value);
                }}
                type="text"
              />
              {/* add better verification */}
              {editingRows.find(r => r.id === original.id).roomId && (
                <a
                  target="_blank"
                  className="btn btn-primary btn-sm"
                  href={
                    process.env.REACT_APP_CRMLINK +
                    editingRows.find(r => r.id === original.id).roomId
                  }
                  rel="noopener noreferrer"
                  style={{
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                    marginLeft: '-1px',
                  }}
                >
                  Verify
                </a>
              )}
            </InputGroup>
          );
        }
        return (
          <a
            target="_blank"
            style={{ fontSize: '0.8rem' }}
            href={process.env.REACT_APP_CRMLINK + original.roomId}
            rel="noopener noreferrer"
          >
            {original.roomId}
          </a>
        );
      },
    },
    {
      Header: 'Created Date',
      id: 'createdDate',
      accessor: (data: WeddingGuestDetailsDto) => data.createdDate,
      className: 'text-center',
      minWidth: 155,
      Cell: ({ original: { createdDate } }) => (
        <small className="opacity-8">
          <FontAwesomeIcon icon={faCalendarAlt} className="pr-1" />
          {new Date(`${createdDate}z`).toLocaleString()}
        </small>
      ),
    },
    {
      Header: 'Updated Date',
      id: 'lastModifiedDate',
      accessor: (data: WeddingGuestDetailsDto) => data.lastModifiedDate,
      className: 'text-center',
      minWidth: 155,
      Cell: ({ original: { lastModifiedDate } }) =>
        lastModifiedDate && (
          <small className="opacity-8">
            <FontAwesomeIcon icon={faCalendarAlt} className="pr-1" />
            {new Date(`${lastModifiedDate}z`).toLocaleString()}
          </small>
        ),
    },
    {
      Header: 'State',
      id: 'isDeleted',
      accessor: (data: WeddingGuestDetailsDto) => data.isDeleted,
      className: 'text-center',
      minWidth: 85,
      Cell: ({ original: { isDeleted } }) => (
        <Badge color={isDeleted ? 'danger' : 'success'}>
          {isDeleted ? 'DELETED' : 'ACTIVE'}
        </Badge>
      ),
    },
    {
      Header: '',
      className: 'text-center',
      filterable: false,
      minWidth: 140,
      Cell: ({ original }: { original: WeddingGuestDetailsDto }) =>
        !isEditing(original) ? (
          <>
            <UncontrolledDropdown>
              <DropdownToggle>
                {' '}
                Actions <IoIosList />
              </DropdownToggle>
              <DropdownMenu
                style={{ minWidth: '10rem' }}
                className={styles['dropdown-menu']}
              >
                <DropdownItem onClick={() => toggleEdit(original)}>
                  <span style={{ transform: 'scaleX(-1) scaleY(-1)' }}>✐</span>
                  &nbsp; Edit Row
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => toggleSoftDelete(original)}
                  className={[
                    '',
                    original.isDeleted ? '' : 'btn-outline-danger',
                  ].join(' ')}
                >
                  {original.isDeleted ? (
                    <IoIosUndo size={20} color="#089d6b" />
                  ) : (
                    <IoIosTrash />
                  )}
                  &nbsp;
                  {original.isDeleted ? ' Restore' : ' Soft Delete'}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ) : (
          <>
            <Button
              style={{ fontSize: '0.6rem' }}
              className="mb-2 me-2 btn-icon btn-sm"
              color="primary"
              onClick={() => saveUpdatedRecord(original)}
            >
              <i className="pe-7s-diskette btn-icon-wrapper"> </i>
              Save
            </Button>{' '}
            <Button
              style={{ fontSize: '0.6rem' }}
              className="mb-2 me-2 btn-icon btn-sm"
              onClick={() => toggleEdit(original)}
              color="danger"
            >
              <i className="pe-7s-close-circle btn-icon-wrapper"> </i>
              Cancel
            </Button>
          </>
        ),
    },
  ];

  const loadRsvpData = async () => {
    setLoading(true);

   
    const {
      data: { data },
    } = await new WeddingGuestsApi().apiWeddingGuestsByWeddingGroupIdGet(
      groupId,
    );

    setRsvpData(data);
    setLoading(false);
  };

  useEffect(() => {
    loadRsvpData();
  }, []);

  return (
    <>
      {groupId && (
        <AddNew
          weddingGroupId={groupId}
          onRecordCreated={async record => {
            await loadRsvpData();
          }}
          isOpen={addNewIsOpen}
          onClose={() => setAddNewIsOpen(false)}
        />
      )}
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardHeader
              style={{
                display: 'flex',
                justifyContent:
                  rsvpView === 'legacy' ? 'flex-start' : 'space-between',
              }}
            >
              {groupId && rsvpView === 'new' && (
                <>
                  <div>
                    <Button
                      onMouseOver={() => setHoveringLegacyButton(true)}
                      onMouseLeave={() => setHoveringLegacyButton(false)}
                      color={'danger'}
                      onClick={() => {setHoveringLegacyButton(false);setRsvpView('legacy');}}
                    >
                      {' '}
                      <FontAwesomeIcon
                        icon={['fas', 'undo']}
                        spinReverse
                        spin={hoveringLegacyButton}
                        fixedWidth={false}
                      />{' '}
                      View Legacy RSVP Data
                    </Button>
                  </div>
                </>
              )}

              {groupId && rsvpView === 'legacy' && (
                <Button color={'success'} onClick={() => setRsvpView('new')}>
                  {' '}
                  <IoIosEye /> View New RSVP Data
                </Button>
              )}
            </CardHeader>
            <CardBody>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {rsvpView === 'new' && (
                  <>
                    <h3>Invited Guests/RSVPs</h3>
                    <div>
                      {groupId && (
                        <>
                          <Button
                            color={'info'}
                            onClick={() => setAddNewIsOpen(true)}
                          >
                            {' '}
                            <IoIosPersonAdd /> + Add New Record
                          </Button>{' '}
                          <Button
                            type="button"
                            className="btn-icon btn-icon-only btn btn-primary"
                            onClick={() => loadRsvpData()}
                          >
                            <i className="lnr-sync btn-icon-wrapper" />
                          </Button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              {rsvpView === 'new' && (
                <ReactTable
                  page={tablePage}
                  onPageChange={pageIndex => setTablePage(pageIndex)}
                  data={rsvpData}
                  loading={loading}
                  defaultSorted={[
                    {
                      // the sorting model for the table
                      id: 'lastModifiedDate',
                      desc: true,
                    },
                  ]}
                  columns={columns}
                  defaultPageSize={10}
                  pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                  rowsText="rows per page"
                  filterable={false}
                  className="-striped -highlight"
                />
              )}
              {rsvpView === 'legacy' && <LegacyRSVPList groupId={groupId} />}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
