import React, { useState, useEffect } from 'react';
import { Row, Col, Badge } from 'reactstrap';
import ReactTable, { Column } from 'react-table';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { WeddingGuestsApi } from 'api/adminApi/api/wedding-guests-api';
import { ClientCSVDetail } from 'api/adminApi/model';

import { NoteItem } from 'Pages/SalesLead/SalesLead/Notes/_Note';
import styles from '../style.module.scss';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import { isAuthError } from 'api/auth';
import { signoutRedirect } from 'auth/AuthService';

interface Props {
  groupId: string;
}

export const LegacyRSVPList: React.FC<Props> = ({ groupId }: Props) => {
  const [loading, setLoading] = useState(false);
  const [rsvpData, setRsvpData] = useState<ClientCSVDetail[]>([]);
  const [tablePage, setTablePage] = useState<number>(0);

  const columns: Column<ClientCSVDetail>[] = [
    {
      Header: 'Id',
      id: 'id',
      minWidth: 60,
      className: 'text-center',
      accessor: (data: ClientCSVDetail) => data.id,
    },
    {
      Header: 'Guest Name',
      id: 'fullName',
      className: 'text-center',
      minWidth: 150,
      accessor: (data: ClientCSVDetail) => data.fullName,
    },
    {
      Header: 'Guest Email',
      id: 'email',
      minWidth: 150,
      className: 'text-center',
      accessor: (data: ClientCSVDetail) => data.email,
    },
    {
      Header: 'Phone',
      id: 'phone',
      minWidth: 150,
      className: 'text-center',
      accessor: (data: ClientCSVDetail) => data.phone,
    },
    {
      Header: 'Guest Count',
      id: 'guests',
      className: 'text-center',
      accessor: (data: ClientCSVDetail) => data.guests,
    },
    {
      Header: 'RSVP Response',
      className: 'text-center',
      id: 'status',
      minWidth: 150,
      accessor: (data: ClientCSVDetail) => data.status,
    },
    {
      Header: 'Comments',
      id: 'comments',
      minWidth: 300,
      className: 'text-center',
      Cell: ({ original }: { original: ClientCSVDetail }) => {
        const comments = [original.textArea, original.guestResponse]?.filter(
          c => !!c,
        );
        if (comments.length <= 0) return null;
        return (
          <div className="scroll-area-lg" style={{ height: '200px' }}>
            <PerfectScrollbar>
              <div
                className="p-3"
                style={{
                  textAlign: 'left',
                  width: '99%',
                  wordWrap: 'break-word',
                  whiteSpace: 'pre-wrap',
                }}
              >
                <VerticalTimeline
                  layout="1-column"
                  className={[
                    'vertical-without-time',
                    styles['vertical-timeline'],
                  ].join(' ')}
                >
                  {comments.map((comment, idx) => {
                    return (
                      <NoteItem
                        key={`${original.id}-${idx}`}
                        type={'text'}
                        id={`${original.id}-${idx}`}
                        note={comment}
                        module={'rsvp'}
                        createdAt={original.dateModified}
                        iconClassName={'text'}
                        iconVariant={'text'}
                        createdBy={original.fullName}
                      />
                    );
                  })}
                </VerticalTimeline>
              </div>
            </PerfectScrollbar>
          </div>
        );
      },
    },
    {
      Header: 'Date Modified',
      id: 'dateModified',
      accessor: (data: ClientCSVDetail) => data.dateModified,
      className: 'text-center',
      minWidth: 155,
      Cell: ({ original: { dateModified } }: { original: ClientCSVDetail }) =>
        dateModified && (
          <small className="opacity-8">
            <FontAwesomeIcon icon={faCalendarAlt} className="pr-1" />
            {new Date(`${dateModified}`).toLocaleString().split(',')[0]}{' '}
            <b>CST</b>
          </small>
        ),
    },
    {
      Header: 'Booked?',
      id: 'booked',
      accessor: (data: ClientCSVDetail) => data.booked,
      className: 'text-center',
      minWidth: 85,
      Cell: ({ original: { booked } }: { original: ClientCSVDetail }) => booked,
    },
  ];

  const loadLegacyRSVPData = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } =
        await new WeddingGuestsApi().apiWeddingGuestsLegacyRSVPRecordsGroupIdGet(
          groupId,
        );
      setRsvpData(data);
    } catch (error) {
      try {
        //check for axios error
        if (axios.isAxiosError(error)) {
          //check for auth error, first
          if (isAuthError(error)) {
            toast.error(
              'Unauthorized request detected (login token likely expired)',
            );
            await signoutRedirect();
            return;
          }
          if ((error as AxiosError)?.response?.status === 404) {
            return;
          }
          const {
            response: {
              data: { errors },
            },
          } = error;
          const apiErrors = [];
          if (typeof errors === 'object') {
            for (const err in errors) {
              apiErrors.push({ [err]: errors[err].join('\n') });
            }
          }
          toast.error(
            <>
              An API error occurred.
              <br />
              Error Details:
              <ul>
                {apiErrors.map(apiError => {
                  return (
                    <li>
                      <b>{Object.keys(apiError)[0]}</b>:{' '}
                      {apiError[Object.keys(apiError)[0]]}
                    </li>
                  );
                })}
              </ul>
            </>,
          );
          return;
        }
      } catch {}

      toast.error(
        <>
          An unknown API error occurred.
          <br />
          Please try again or contact the dev team.
        </>,
      );
    } finally {      
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLegacyRSVPData().catch(()=>{
      toast.error(
        <>
          An unknown error occurred.
          <br />
          Please try again or contact the dev team.
        </>,
      )
    });
  }, [groupId]);

  return (
    <>
      <Row>
        <Col md="12">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3>
              Invited Guests/RSVPs <Badge color={'warning'}>LEGACY</Badge>
            </h3>
            <div>
              <button
                type="button"
                className="btn-icon btn-icon-only btn btn-primary"
                onClick={() => loadLegacyRSVPData()}
              >
                <i className="lnr-sync btn-icon-wrapper" />
              </button>
            </div>
          </div>
          <ReactTable
            page={tablePage}
            onPageChange={pageIndex => setTablePage(pageIndex)}
            data={rsvpData}
            loading={loading}
            defaultSorted={[
              {
                // the sorting model for the table
                id: 'dateModified',
                desc: true,
              },
            ]}
            sortable={false}
            columns={columns}
            defaultPageSize={10}
            pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            rowsText="rows per page"
            filterable={false}
            className="-striped -highlight"
          />
        </Col>
      </Row>
    </>
  );
};
