import { destifyAdminApiRequests } from './common';

const hotelcontent = {
  list: data =>
    destifyAdminApiRequests.postPagination('Hotel/HotelContentList', data),
  autoComplete: data =>
    destifyAdminApiRequests.postPagination(
      'Hotel/HotelAutoCompleteContentList',
      data,
    ),
  countrylist: () => destifyAdminApiRequests.get('Hotel/CountryList'),
  supplierlist: () => destifyAdminApiRequests.get('Hotel/SupplierList'),
  allsupplierlist: () => destifyAdminApiRequests.get('Hotel/AllSupplierList'),
  hotelchainlist: () => destifyAdminApiRequests.get('Hotel/HotelChainList'),
  city: id => destifyAdminApiRequests.get(`Hotel/city/${id}`),
  airport: (countryId, cityId) =>
    destifyAdminApiRequests.get(`Hotel/AirportList/${countryId}/${cityId}`),
  destinationList: () =>
    destifyAdminApiRequests.get('Hotel/AllCityDestination/'),
  gethotelbyId: id => destifyAdminApiRequests.get(`Hotel/${id}`),
  gethotelImagebyId: id => destifyAdminApiRequests.get(`Hotel/Images/${id}`),
  gethotelImageList: id =>
    destifyAdminApiRequests.get(`Hotel/ImagesList/${id}`),
  updateHotel: data =>
    destifyAdminApiRequests.post('Hotel/UpdateHotelContent', data),
  saveHotel: data =>
    destifyAdminApiRequests.post('Hotel/SaveHotelContent', data),
  deleteHotel: data =>
    destifyAdminApiRequests.post('Hotel/DeleteHotelContent', data),

  saveImages: data =>
    destifyAdminApiRequests.post('Hotel/SaveHotelImageCommand', data),
  updateImages: data =>
    destifyAdminApiRequests.post('Hotel/UpdateHotelImageCommand', data),

  uploadS3Photo: (file, id) => {
    const formData = new FormData();
    formData.append('File', file);
    return destifyAdminApiRequests.post(
      `Hotel/${id}/SaveS3ImageCommand`,
      formData,
      {
        headers: { 'Content-type': 'multipart/form-data' },
      },
    );
  },

  uploadRoomS3Photo: (file, id) => {
    const formData = new FormData();
    formData.append('File', file);
    return destifyAdminApiRequests.post(
      `Hotel/${id}/SaveRoomS3ImageCommand`,
      formData,
      {
        headers: { 'Content-type': 'multipart/form-data' },
      },
    );
  },

  deleteImages: data => destifyAdminApiRequests.post('Hotel/DeleteHotelImageCommand', data)
};

const roomtype = {
  roomtypebyId: id => destifyAdminApiRequests.get(`Room/${id}`),
  list: data => destifyAdminApiRequests.post('Room/All', data),
  saveRoomType: data => destifyAdminApiRequests.post('Room/SaveRoomType', data),
  updateRoomType: data =>
    destifyAdminApiRequests.post('Room/UpdateRoomType', data),
  images: id => destifyAdminApiRequests.get(`Room/Images/${id}`),
  features: () => destifyAdminApiRequests.get('Room/Features'),
  saveFeature: data => destifyAdminApiRequests.post('Room/SaveFeature', data),
  roomImagesList: id =>
    destifyAdminApiRequests.get(`Room/RoomImagesList/${id}`),

  saveStaticPricing: data =>
    destifyAdminApiRequests.post('Room/SaveStaticPricing', data),
  updateStaticPricing: data =>
    destifyAdminApiRequests.post('Room/UpdateStaticPricing', data),
  makeDestifyFavourite: data =>
    destifyAdminApiRequests.post('Room/MakeDestifyFavourite', data),
  getStaticPricing: (hotelId, roomTypeId) =>
    destifyAdminApiRequests.get(`Room/StaticPricingList/${hotelId}/${roomTypeId}`),
  adultlist: () =>
    destifyAdminApiRequests.get(`Room/RoomOccupancyList/${false}`),
  childlist: () =>
    destifyAdminApiRequests.get(`Room/RoomOccupancyList/${true}`),
  changeTfoSettings: (roomTypeId, tfoSettings) => destifyAdminApiRequests.post(`Room/${roomTypeId}/TooFarOutSettings`, tfoSettings),
};

const tranferPrice = {
  getTransferPrice: id =>
    destifyAdminApiRequests.get(`Hotel/TransferPrice/${id}`),
  saveTrasferPrice: data =>
    destifyAdminApiRequests.post('Hotel/SaveTrasferPrice', data),
};



const transferDescription = {
  getTransferDescription: id =>
    destifyAdminApiRequests.get(`Hotel/TransferDescription/${id}`),
  saveTrasferDescription: data =>
    destifyAdminApiRequests.post('Hotel/SaveTrasferDescriptionCommand', data),
};

const staticPricing = {
  getStatictPricingList: id =>
    destifyAdminApiRequests.get(`Hotel/StaticPricingList/${id}`),
  getStatictPricing: id =>
    destifyAdminApiRequests.get(`Hotel/StaticPricing/${id}`),
  saveStaticPricing: data =>
    destifyAdminApiRequests.post('Hotel/SaveStaticPricing', data),
  updateStaticPricing: data =>
    destifyAdminApiRequests.post('Hotel/UpdateStaticPricing', data),
  staticPricingIngestionLogList: () => destifyAdminApiRequests.get(`Hotel/AllStaticPricingJobLog`)
};

const integration = {
  updateHotelIntegration: data =>
    destifyAdminApiRequests.post('Hotel/UpdateHotelIntegration', data),
  hotelIntegrationById: id =>
    destifyAdminApiRequests.get(`Hotel/HotelIntegrationById/${id}`),
};

const hotelChain = {
  allHotelChainList: () =>
    destifyAdminApiRequests.get('Hotel/AllHotelChainList'),
  getHotelChain: id => destifyAdminApiRequests.get(`Hotel/HotelChain/${id}`),
  saveHotelChain: data =>
    destifyAdminApiRequests.post('hotel/SaveHotelChain', data),
  updateHotelChainStatus: data =>
    destifyAdminApiRequests.post('hotel/UpdateHotelChainStatus', data),
};

const supplier = {
  saveSupplier: data =>
    destifyAdminApiRequests.post('hotel/SaveSupplier', data),
  updateSupplier: data =>
    destifyAdminApiRequests.post('hotel/UpdateSupplier', data),
  getSupplierById: id => destifyAdminApiRequests.get(`hotel/Supplier/${id}`),
};

const hotel = {
  hotelcontent,
  roomtype,
  tranferPrice,
  staticPricing,
  integration,
  transferDescription,
  hotelChain,
  supplier,
};

export default hotel;
