import React, { } from 'react';

import { withRouter } from 'react-router-dom';

import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

class ServerError extends React.Component {  
  constructor(props) {    
    super(props);  
    const location = this.props.location.state;
       
    this.state = { errors: location }
  }
 
  render() {    
    const { errors } = this.state;   

    return (        

      <>
        <AppHeader />
        <div className="app-main">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mt-10">
                  <div className="card-body">
                    <div data-testid="wrapper" className="_loading_overlay_wrapper css-79elbk">
                      <div className="card-hover-shadow-2x mb-3 card"><div className="card-header">Server Error</div>
                        <div className="card-body">
                          <p><h1>Stack Trace</h1></p>
                          <p>{errors.detail.details}</p>
                                  
                        </div>
                      </div>
                    </div>
                  </div>
                            
                </div>
              </div>
            </div>
            <AppFooter />
          </div>
        </div>
      </>

    );
  }
}
export default withRouter(ServerError)
