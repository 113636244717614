
import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody } from 'reactstrap';
import avatar from '../../../../assets/utils/images/avatars/default.png';
import styles from './profile.module.scss';

import usersApi from '../../../../api/user';

import Loader from 'react-loaders';
import { getAdminProfilePictureUrl } from 'utils/utils';

interface Props {
  userId?: string;
  legacyUserId?: string;
  imageUrl?: string;
  userDeatails?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    calendly?: string;
  }
}

const ProfileCard = ({ userId, legacyUserId,  userDeatails }: Props): JSX.Element => {

  const [image, setImage] = useState<string>();
  const [imagePreview, setImagePreview] = useState<any>('');
  const [base64, setBase64] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // TODO: Watch image url in state, to detect potential changes and update all components throughout
  
  const adminUserImageUrl = getAdminProfilePictureUrl(userId); 
  const placeholderImage = avatar;

  const onImageError = (e: any) => {
      e.target.src = placeholderImage;
  };

  const handleChange = (e: any) => {

    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader()
      reader.onload = _handleReaderLoaded
      reader.readAsBinaryString(file)
    }

  }

  const _handleReaderLoaded = (e: any) => {
    let imageData = e.target.result
    setBase64(btoa(imageData))
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);    

    const payload = { 
      destifyLegacyUserId: legacyUserId,
      base64ImageData: base64,
    }

    try {
      await usersApi.adminUsers.uploadImage(payload);
      setImage(imagePreview);    
    } catch (e) {
      console.log(`Unexpected error uploading image: ${e || 'unknown error'}`);
    }
    setIsLoading(false);
  }

  const handlePhotoUpload = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
   
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        setImage(file);
        setImagePreview(reader.result);
      }
      reader.readAsDataURL(file);
    }
  }

  const deleteImage = async (id) => {
    try {
      await usersApi.adminUsers.deleteImage(id);
      setImage('');
      setImagePreview(avatar);
      setBase64('');
      setImage(imagePreview);    
    } catch (e) {
      console.log(`Unexpected error deleting image: ${e || 'unknown error'}`);
    }
  
  }
  const calendlyLink = userDeatails.calendly.replace(/(^\w+:|^)\/\//, '');

  return (
    <Card className={styles.profileCard}>
        <CardBody>
          <form onSubmit={(e) => handleSubmit(e)} onChange={(e) => handleChange(e)}>
            <div className={styles.profileWrapper}>
              <div className={styles.avatar}>
                  <img src={imagePreview ? imagePreview : adminUserImageUrl} alt='profile' onError={onImageError}/>
                  <input
                    type='file'
                    name='avatar'
                    id='file'
                    accept="image/png"
                    onChange={handlePhotoUpload}
                    src={imagePreview}
                  />
              </div>

                <div className={styles.buttonGroup}>
                  <Button type='submit' color="secondary">
                    {isLoading ? (
                      <div>
                      <Loader active type="ball-clip-rotate" innerClassName={styles.spinner} /> 
                      </div>
                    ) : (
                      <>Save</>
                    )}
                  </Button>
                <Button type='button' outline color="danger" onClick={() => deleteImage(legacyUserId)}>
                    Remove
                  </Button>
                </div>
                <div className={styles.profileCard_content}>
                    {userDeatails.firstName && userDeatails.lastName && <h3>{`${userDeatails.firstName} ${userDeatails.lastName}`}</h3>}
                    {userDeatails.email && <p>{userDeatails.email}</p>}
                    {userDeatails.phoneNumber && <p>{userDeatails.phoneNumber}</p>}
                    {userDeatails.calendly && <a href={userDeatails.calendly}>{calendlyLink}</a>}
                </div>
            </div>
          </form>
        </CardBody>
    </Card>
  
  )
}

  

  export default ProfileCard;
  