/* eslint-disable */
// tslint:disable
/**
 * Destify Administration API
 * API environment - staging
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { AddWeddingGuestResponse } from '../model';
import { BaseWeddingGuestDto } from '../model';
import { GetWeddingGuestResponse } from '../model';
import { GetWeddingGuestsResponse } from '../model';
import { LegacyRSVPRecordsResponse } from '../model';
import { UpdateWeddingGuestRequest } from '../model';
/**
 * WeddingGuestsApi - axios parameter creator
 * @export
 */
export const WeddingGuestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsByWeddingGroupIdGet(groupId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/WeddingGuests/ByWeddingGroupId`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsIdDelete(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiWeddingGuestsIdDelete.');
            }
            const localVarPath = `/api/WeddingGuests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiWeddingGuestsIdGet.');
            }
            const localVarPath = `/api/WeddingGuests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateWeddingGuestRequest} [updateWeddingGuestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsIdPut(id: number, updateWeddingGuestRequest?: UpdateWeddingGuestRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiWeddingGuestsIdPut.');
            }
            const localVarPath = `/api/WeddingGuests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof updateWeddingGuestRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateWeddingGuestRequest !== undefined ? updateWeddingGuestRequest : {}) : (updateWeddingGuestRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsLegacyRSVPRecordsGroupIdGet(groupId: string, options: any = {}): RequestArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiWeddingGuestsLegacyRSVPRecordsGroupIdGet.');
            }
            const localVarPath = `/api/WeddingGuests/LegacyRSVPRecords/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsLegacyRSVPReportGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/WeddingGuests/LegacyRSVPReport`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BaseWeddingGuestDto} [baseWeddingGuestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsPost(baseWeddingGuestDto?: BaseWeddingGuestDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/WeddingGuests`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof baseWeddingGuestDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(baseWeddingGuestDto !== undefined ? baseWeddingGuestDto : {}) : (baseWeddingGuestDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeddingGuestsApi - functional programming interface
 * @export
 */
export const WeddingGuestsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsByWeddingGroupIdGet(groupId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWeddingGuestsResponse> {
            const localVarAxiosArgs = WeddingGuestsApiAxiosParamCreator(configuration).apiWeddingGuestsByWeddingGroupIdGet(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsIdDelete(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WeddingGuestsApiAxiosParamCreator(configuration).apiWeddingGuestsIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWeddingGuestResponse> {
            const localVarAxiosArgs = WeddingGuestsApiAxiosParamCreator(configuration).apiWeddingGuestsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateWeddingGuestRequest} [updateWeddingGuestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsIdPut(id: number, updateWeddingGuestRequest?: UpdateWeddingGuestRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = WeddingGuestsApiAxiosParamCreator(configuration).apiWeddingGuestsIdPut(id, updateWeddingGuestRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsLegacyRSVPRecordsGroupIdGet(groupId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegacyRSVPRecordsResponse> {
            const localVarAxiosArgs = WeddingGuestsApiAxiosParamCreator(configuration).apiWeddingGuestsLegacyRSVPRecordsGroupIdGet(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsLegacyRSVPReportGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = WeddingGuestsApiAxiosParamCreator(configuration).apiWeddingGuestsLegacyRSVPReportGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {BaseWeddingGuestDto} [baseWeddingGuestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsPost(baseWeddingGuestDto?: BaseWeddingGuestDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddWeddingGuestResponse> {
            const localVarAxiosArgs = WeddingGuestsApiAxiosParamCreator(configuration).apiWeddingGuestsPost(baseWeddingGuestDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * WeddingGuestsApi - factory interface
 * @export
 */
export const WeddingGuestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsByWeddingGroupIdGet(groupId?: string, options?: any): AxiosPromise<GetWeddingGuestsResponse> {
            return WeddingGuestsApiFp(configuration).apiWeddingGuestsByWeddingGroupIdGet(groupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsIdDelete(id: number, options?: any): AxiosPromise<void> {
            return WeddingGuestsApiFp(configuration).apiWeddingGuestsIdDelete(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsIdGet(id: number, options?: any): AxiosPromise<GetWeddingGuestResponse> {
            return WeddingGuestsApiFp(configuration).apiWeddingGuestsIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateWeddingGuestRequest} [updateWeddingGuestRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsIdPut(id: number, updateWeddingGuestRequest?: UpdateWeddingGuestRequest, options?: any): AxiosPromise<void> {
            return WeddingGuestsApiFp(configuration).apiWeddingGuestsIdPut(id, updateWeddingGuestRequest, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsLegacyRSVPRecordsGroupIdGet(groupId: string, options?: any): AxiosPromise<LegacyRSVPRecordsResponse> {
            return WeddingGuestsApiFp(configuration).apiWeddingGuestsLegacyRSVPRecordsGroupIdGet(groupId, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsLegacyRSVPReportGet(options?: any): AxiosPromise<string> {
            return WeddingGuestsApiFp(configuration).apiWeddingGuestsLegacyRSVPReportGet(options)(axios, basePath);
        },
        /**
         * 
         * @param {BaseWeddingGuestDto} [baseWeddingGuestDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWeddingGuestsPost(baseWeddingGuestDto?: BaseWeddingGuestDto, options?: any): AxiosPromise<AddWeddingGuestResponse> {
            return WeddingGuestsApiFp(configuration).apiWeddingGuestsPost(baseWeddingGuestDto, options)(axios, basePath);
        },
    };
};

/**
 * WeddingGuestsApi - object-oriented interface
 * @export
 * @class WeddingGuestsApi
 * @extends {BaseAPI}
 */
export class WeddingGuestsApi extends BaseAPI {
    /**
     * 
     * @param {string} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingGuestsApi
     */
    public apiWeddingGuestsByWeddingGroupIdGet(groupId?: string, options?: any) {
        return WeddingGuestsApiFp(this.configuration).apiWeddingGuestsByWeddingGroupIdGet(groupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingGuestsApi
     */
    public apiWeddingGuestsIdDelete(id: number, options?: any) {
        return WeddingGuestsApiFp(this.configuration).apiWeddingGuestsIdDelete(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingGuestsApi
     */
    public apiWeddingGuestsIdGet(id: number, options?: any) {
        return WeddingGuestsApiFp(this.configuration).apiWeddingGuestsIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateWeddingGuestRequest} [updateWeddingGuestRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingGuestsApi
     */
    public apiWeddingGuestsIdPut(id: number, updateWeddingGuestRequest?: UpdateWeddingGuestRequest, options?: any) {
        return WeddingGuestsApiFp(this.configuration).apiWeddingGuestsIdPut(id, updateWeddingGuestRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingGuestsApi
     */
    public apiWeddingGuestsLegacyRSVPRecordsGroupIdGet(groupId: string, options?: any) {
        return WeddingGuestsApiFp(this.configuration).apiWeddingGuestsLegacyRSVPRecordsGroupIdGet(groupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingGuestsApi
     */
    public apiWeddingGuestsLegacyRSVPReportGet(options?: any) {
        return WeddingGuestsApiFp(this.configuration).apiWeddingGuestsLegacyRSVPReportGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {BaseWeddingGuestDto} [baseWeddingGuestDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeddingGuestsApi
     */
    public apiWeddingGuestsPost(baseWeddingGuestDto?: BaseWeddingGuestDto, options?: any) {
        return WeddingGuestsApiFp(this.configuration).apiWeddingGuestsPost(baseWeddingGuestDto, options)(this.axios, this.basePath);
    }

}
