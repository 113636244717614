//todo: turn this page into typescript and use enums
import { AdminRoles } from 'auth/Roles';
// import { AppConfig } from "config";

import { IPage, ISubModule } from './types';

const allRoles = [
  AdminRoles.Admin,
  AdminRoles.GuestServices,
  AdminRoles.GuestServicesManager,
  AdminRoles.SalesLeader,
  AdminRoles.SalesManager,
  AdminRoles.SalesSpecialist,
  AdminRoles.GuestServicesSpecialist,
];

const [
  ADMIN,
  GUEST_SERVICES,
  GUEST_SERVICES_MANAGER,
  SALES_LEADER,
  SALES_MANAGER,
  SALES_SPECIALIST,
  GUEST_SERVICES_SPECIALIST
] = [...allRoles];

const getAllowedRoles = ({
  roles = [],
  inverse = false,
}: {
  roles?: AdminRoles[];
  inverse?: boolean;
}) => {
  if (inverse) return allRoles.filter(role => roles.every(r => r !== role));
  return allRoles.filter(role => roles.some(r => r === role));
};


export const MainNav: IPage[] = [
  {
    icon: 'pe-7s-home',
    label: 'Dashboard',
    to: '/dashboards/home',
    roles: getAllowedRoles({ inverse: true }),
  },
];

export const HotelBookingNav: IPage[] = [
  {
    icon: 'pe-7s-graph2',
    label: 'HBSI Booking',
    to: '/hbsi/search',
    roles: getAllowedRoles({
      roles: [ADMIN, GUEST_SERVICES, SALES_LEADER, GUEST_SERVICES_MANAGER, GUEST_SERVICES_SPECIALIST],
    }),
  },
  {
    label: 'Transfers',
    to: '/hbsi/transfers',
    roles: getAllowedRoles({ inverse: true }),
  },
];

export const HotelManagementNav: (ISubModule | IPage)[] = [
  {
    icon: 'pe-7s-notebook',
    label: 'Hotel Content',
    content: [
      {
        label: 'Hotel Destinations',
        to: '/hotels/hotel-destinations',
        roles: getAllowedRoles({ inverse: true }),
      },
      {
        icon: 'pe-7s-map-2',
        label: 'Destination',
        to: '/wedding/destination',
        roles: getAllowedRoles({ inverse: true }),
      },
      {
        icon: 'pe-7s-map-marker',
        label: 'City',
        to: '/wedding/subdestination',
        roles: getAllowedRoles({ inverse: true }),
      },
      {
        label: 'Hotels',
        to: '/hotels/hotel-content',
        roles: getAllowedRoles({ inverse: true }),
      },
      {
        icon: 'pe-7s-shopbag',
        label: 'Sales View Resorts',
        to: '/wedding/resort',
        roles: getAllowedRoles({ inverse: true }),
      },
      {
        label: 'Hotel Chains',
        to: '/hotels/hotel-chain-list',
        roles: getAllowedRoles({ inverse: true }),
      },
      {
        label: 'Room Feature',
        to: '/hotels/room-feature',
        roles: getAllowedRoles({ inverse: true }),
      },
      {
        label: 'Ingestion Job Status',
        to: '/hotels/ingestion-job-status',
        roles: getAllowedRoles({ inverse: true }),
      },
    ],
  },
  {
    label: 'Transfer Content',
    icon: '',
    content: [
      {
        label: 'Transfer Types',
        to: '/hotels/transfer-types',
        roles: getAllowedRoles({ inverse: true }),
      },
    ],
  },
  {
    label: 'Suppliers',
    icon: '',
    roles: getAllowedRoles({ inverse: true }),
    to: '/hotels/transfer-suppliers'
  }
];

export const GroupLeaderNav: IPage[] = [
  {
    icon: 'pe-7s-users',
    label: 'Group Leader',
    to: '/group/group-leader',
    roles: getAllowedRoles({
      roles: [ADMIN, GUEST_SERVICES_MANAGER, SALES_LEADER, GUEST_SERVICES,GUEST_SERVICES_SPECIALIST],
    }),
  },
];

export const WeddingManagementNav: IPage[] = [
  {
    icon: 'pe-7s-headphones',
    label: 'Leads',
    to: '/salesleads/guest-list',
    roles: getAllowedRoles({
      roles: [ADMIN, SALES_MANAGER, SALES_LEADER, SALES_SPECIALIST],
    }),
  },
  {
    icon: 'pe-7s-gleam',
    label: 'Legacy CRM Sales Leads',
    to: '/salesleads/crm-sales-leads',
    roles: getAllowedRoles({
      roles: [ADMIN, SALES_MANAGER, SALES_LEADER, SALES_SPECIALIST],
    }),
  },
];

export const SalesLeadNav: IPage[] = [
  {
    icon: 'pe-7s-wallet',
    label: 'Sales Lead',
    to: '/salesleads/guest-list',
    roles: getAllowedRoles({ inverse: true }),
  },
];

export const UsersNav: IPage[] = [
  {
    icon: 'pe-7s-user',
    label: 'Client Accounts',
    to: '/user/guest-list-new',
    roles: getAllowedRoles({ inverse: true }),
  },
  {
    icon: 'pe-7s-add-user',
    label: 'Admin Users',
    to: '/user/admin-list',
    roles: getAllowedRoles({
      roles: [ADMIN, GUEST_SERVICES_MANAGER, SALES_LEADER,SALES_MANAGER,SALES_SPECIALIST]

    }),
  },
];
