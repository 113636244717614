import { destifyAdminApiRequests, destifyServicesApiRequests, destifySalesJourneyApiRequests } from './common';

const salesLeadDetails = {
  list: queryObject =>
    destifyAdminApiRequests.postPagination(
      'SalesLead/SalesLeadAll',
      queryObject,
    ),
  loggedInList: emails =>
    destifyAdminApiRequests.get(`CRMSales/GetListOfLeads?emails=${emails}`),
  // assignLead: queryObject =>
  //   destifyAdminApiRequests.get(
  //     `CRMSales/AssignCRMLead?CRMLeadId=${queryObject.CRMLeadId}&AssignmentUserEmail=${queryObject.AssignmentUserEmail}`,
  //   ),
  updateStatus: param =>
    destifyAdminApiRequests.put('SalesLead/SalesLeadUpdate', param),
  updateWeddingDetailsByUser: data =>
    destifyAdminApiRequests.put('SalesLead/SalesLeadUpdateWeddingResort', data),
  details: id =>
    destifyAdminApiRequests.get(`SalesLead/SalesLeadDetailsById/${id}`),
  weddingDetailsByUser: id =>
    destifyAdminApiRequests.get(`SalesLead/WeddingDetailsByUserId/${id}`),

  getSalesLeadUpsells: (weddingDetailsId, upsellCategory) =>
    destifySalesJourneyApiRequests.get(`SalesLeadUpsells?UpsellCategory=${upsellCategory}&SalesLeadWeddingDetailsRecordId=${weddingDetailsId}`),
  saveSalesLeadUpsells: (upsells) =>
    destifySalesJourneyApiRequests.post(`SalesLeadUpsells`, { upsells }),
  removeSalesLeadUpsells: (upsells) =>
    destifySalesJourneyApiRequests.delete(`SalesLeadUpsells`, upsells),

  holdExpiredEmail: id =>
    destifyAdminApiRequests.put('SalesLead/WeddingDateHoldExpiredEmail', {
      salesLeadId: id,
    }),
  userJourney: id =>
    destifyAdminApiRequests.get(
      `SalesLead/GetSalesLeadJourneyStateByUserID/${id}`,
    ),
  getSalesLeadBookedConfirmUserList: () =>
    destifyAdminApiRequests.get('SalesLead/SalesLeadBookedConfirmUserList'),
  getWeddingDetailsGroupsize: () =>
    destifyAdminApiRequests.get('SalesLead/WeddingDetailsGroupsize'),
  getUserLastPageVisitList: () =>
    destifyAdminApiRequests.get('SalesLead/UserLastPageVisit'),
  getSalesSourceList: () =>
    destifyServicesApiRequests.get(
      'Addons/GetDropdownList?DropdownList=leads_source_list',
    ),
  getSalesLeadDetailsByUserId: id =>
    destifyAdminApiRequests.get(`SalesLead/SalesLeadDetailsByUserId/${id}`),
    getUserLastPageVisitByUserId: data =>
    destifyAdminApiRequests.post(`SalesLead/UserLastPageVisitByUserId`,data),
  updateUserStatus: data =>
    destifyAdminApiRequests.post('CRMSales/UpdateCRMLead', data),
  assignLead: data => destifyAdminApiRequests.post('CRMSales/AssignCRMLead',data),
  getWeddingDateRequestHistoryById: salesLeadId =>
    destifyAdminApiRequests.get(
      `SalesLead/EventDateRequestHistory/${salesLeadId}`,
    ),
  updateSalesLeadAccountWeddingDetails: (data) => destifyAdminApiRequests.put('SalesLead/UpdateSalesLeadAccountWeddingDetails',data),
  resortRoomPrice: (resortId, userId) => destifySalesJourneyApiRequests.get(`Resort/ResortRoomPrice?ResortId=${resortId}&UserId=${userId}`),
  resortByHotelId: (id) => destifySalesJourneyApiRequests.get(`Resort/ResortByHotelId?HotelId=${id}`)
};

const salesStatus = {
  list: () => destifyAdminApiRequests.get('SalesLead/SalesLeadStatusAll'),
  update: (param) => destifyAdminApiRequests.put('SalesLead/SalesLeadStatusUpdate', param),
  closedReasonList: () => destifyAdminApiRequests.get('SalesLead/SalesLeadClosedReasonAll'),
  ceremonytimelist: () =>
    destifyAdminApiRequests.get('SalesLead/SalesLeadCeremonytimeAll'),
  updateWeddingStatus: param =>
    destifyAdminApiRequests.put('SalesLead/SalesLeadWeddingDateUpdate', param),
  UpdateWeddingConfirmDetails: param =>
    destifyAdminApiRequests.put('SalesLead/UpdateWeddingConfirmDetails', param),
};

const notes={
  list:()=> destifyAdminApiRequests.get('Notes'),
  addNote:(data)=> destifyAdminApiRequests.post('Notes',data),
  getNote:(id)=>destifyAdminApiRequests.get(`Notes/${id}`),
  updateNote:({id,data})=> destifyAdminApiRequests.put(`Notes/${id}`,data),
  deleteNote:id=>destifyAdminApiRequests.delete(`Notes/${id}`),
  getNotesForUser:({module,DestifyUserID})=>destifyAdminApiRequests.get(`Notes/user/${module}/${DestifyUserID}`)
}

const salesLead = {
  salesLeadDetails,
  salesStatus,
  notes
};
export default salesLead;
