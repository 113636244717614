/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  FormGroup,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';

import { toast } from 'react-toastify';

import SweetAlert from 'sweetalert-react';
import { Loader } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';

import { Formik, Form } from 'formik';
import weddingPackage from '../../../api/weddingPackage';
import PageTitle from '../../../Layout/AppMain/PageTitle';

class Resort extends React.Component {
  columns = [
    { title: 'ResortName', key: 'ResortName', isSortable: false },
    { title: 'Destination', key: 'Destination', isSortable: false },
    { title: 'Sub Destination', key: 'SubDestination', isSortable: false },
    { title: 'Status', key: 'isActive', isSortable: false },
    { title: 'Action' },
  ];

  obj = [];

  queryObject = {};

  resortDeleteId;

  constructor(props) {
    super(props);

    this.queryObject.IsSortAsc = true;
    this.queryObject.PageNumber = 1;
    this.queryObject.PageSize = 10;
    this.queryObject.SortBy = '';
    this.queryObject.Search = this.obj;

    this.queryObject.destinationId = 0;
    this.queryObject.subDestinationId = 0;
    this.state = {
      resort: [],
      destination: [],
      subdestination: [],
      statustext: '',
      searchinitialValues: {
        destinationId: 0,
        subDestinationId: 0,
      },
    };
  }

  async componentDidMount() {
    await this.loadDestination();
    await this.loadResort();
  }

  async loadDestination() {
    const destination = await weddingPackage.destination.getDestination();
    this.setState({ destination });
  }

  async loadSubDestination(e) {
    const id = e.target.value;

    // if (id == 0) {
    // var resort = await weddingPackage.resort.getResortBySubDestinationId(id);
    // this.setState({ resort });
    // }
    const subdestination = await weddingPackage.subDestination.getsubdestinationByDestinationId(id);
    this.setState({ subdestination });
  }

  async loadResort() {
    this.setState({ active: true });
    const result = await weddingPackage.resort.getResort(this.queryObject);
    this.setState({ resort: result.data, active: false });
    this.queryObject.PageNumber = result.pagination.currentPage;
    this.queryObject.PageSize = result.pagination.itemsPerPage;
    this.queryObject.totalItems = result.pagination.totalItems;
    this.setState({
      totalPages: result.pagination.totalPages,
      // SortBy: this.queryObject.SortBy,
      // IsSortAsc: this.queryObject.isSortAsc
    });
  }

  handlePageClick = async (data) => {
    const { selected } = data;
    this.queryObject.PageNumber = selected + 1;
    await this.loadResort();
  };

  showConfirmationToDelete = (id) => {
    this.resortDeleteId = id;
    this.setState({ statustext: 'Are you sure to delete the resort?' });
    this.setState({ show: true });
  };

  deleteResort() {
    weddingPackage.resort.deleteResort(this.resortDeleteId).then(() => {
      toast.success('The resort deleted successfully.');
      this.setState({ show: false });
      this.loadResort();
    });
  }

  updateStatus(id) {
    weddingPackage.resort.updateStatus(id).then((response) => {
      toast.success(
        response
      );
      this.loadResort();
    });
  }

  render() {
    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text={ this.state.statustext }
          showCancelButton
          onConfirm={ () => {
            this.deleteResort();
          } }
          onCancel={ () => {
            this.setState({ show: false });
          } }
          onClose={ () => {} } // eslint-disable-line no-console
        />

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <div>
              <PageTitle
                heading="Sales View Resorts"
                icon="pe-7s-map-2 icon-gradient bg-tempting-azure"
              />
            </div>

            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Filter</CardTitle>

                <Formik
                  initialValues={ this.state.searchinitialValues }
                  enableReinitialize
                  onSubmit={ (values) => {
                    this.queryObject.destinationId = values.destinationId;
                    this.queryObject.subDestinationId = values.subDestinationId;
                    this.loadResort();
                  } }
                >
                  {({
                    values,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={ handleSubmit }>
                      <div className="form-row ">
                        <Col md={ 3 }>
                          <FormGroup>
                            <label>Destination</label>

                            <select
                              onChange={ (e) => {
                                this.loadSubDestination(e);
                                setFieldValue('destinationId', e.target.value);
                                setFieldValue('subDestinationId', 0);
                                this.setState({
                                  searchinitialValues: {
                                    destinationId: e.target.value,
                                    subDestinationId: 0,
                                  },
                                });
                              } }
                              name="destinationId"
                              className="form-control"
                              value={ values.destinationId }
                            >
                              <option value="0">--Select--</option>
                              {this.state.destination.map((d) => (
                                <option key={ d.value } value={ d.value }>
                                  {d.text}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md={ 3 }>
                          <FormGroup>
                            <label>Sub Destination</label>

                            <select
                              onChange={ (e) => {
                                setFieldValue(
                                  'subDestinationId',
                                  e.target.value
                                );
                              } }
                              name="subDestinationId"
                              className="form-control"
                            >
                              <option value="0">--Select--</option>
                              {this.state.subdestination.map((sub) => (
                                <option key={ sub.value } value={ sub.value }>
                                  {sub.text}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </div>

                      <Button
                        className="mb-2 mr-2 btn-icon float-left"
                        color="primary"
                      >
                        Search
                      </Button>

                      <Button
                        className="mb-2 mr-2 btn-icon float-left"
                        onClick={ (e) => {
                          e.preventDefault();
                          this.setState({ resort: [] });

                          this.setState({
                            searchinitialValues: {
                              destinationId: '0',
                              subDestinationId: 0,
                            },
                          });
                          this.queryObject.destinationId = 0;
                          this.queryObject.subDestinationId = 0;
                          this.loadResort();
                        } }
                        color="primary"
                      >
                        Clear
                      </Button>
                      {/* {<pre>{JSON.stringify({  values,errors }, null, 4)}</pre> } */}
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>

            <LoadingOverlay
              tag="div"
              active={ this.state.active }
              styles={ {
                overlay: (base) => ({
                  ...base,
                  background: '#fff',
                  opacity: 0.5,
                }),
              } }
              spinner={ <Loader active /> }
            >
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Resort List</CardTitle>
                  <Button
                    className="mb-2 mr-2 btn-icon float-right"
                    color="primary"
                    onClick={ () => {
                      this.props.history.push('/wedding/create-resort');
                    } }
                  >
                    Add Resort
                  </Button>
                  <Table
                    size="md"
                    responsive
                    striped
                    bordered
                    hover
                    className="mb-3"
                  >
                    <thead>
                      <tr>
                        {this.columns.map((value, index) => (
                          <th key={ index }>{value.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.resort.length > 0
                        && this.state.resort.map((value, index) => (
                          <tr key={ index }>
                            <td>{value.resortName}</td>
                            <td>{value.destination}</td>
                            <td>{value.subDestination}</td>
                            <td>
                              {!value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-danger">
                                  {' '}
                                  Disabled{' '}
                                </div>
                              )}
                              {value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-success">
                                  {' '}
                                  Enabled{' '}
                                </div>
                              )}
                            </td>
                            <td>
                              <UncontrolledButtonDropdown>
                                <DropdownToggle
                                  caret
                                  className="mb-2 mr-2"
                                  color="primary"
                                >
                                  Action
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                                  <DropdownItem
                                    onClick={ () => this.props.history.push(
                                      `/wedding/edit-resort/${value.resortID}`
                                    ) }
                                  >
                                    Manage
                                  </DropdownItem>

                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={ () => this.props.history.push(
                                      `/hotels/hotel-content-manage/${value.ptidAdminHotelID}`
                                    ) }
                                  >
                                    View Content
                                  </DropdownItem>

                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={ () => this.showConfirmationToDelete(
                                      value.resortID,
                                      true
                                    ) }
                                  >
                                    Delete
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  <DropdownItem
                                    onClick={ () => this.updateStatus(value.resortID) }
                                  >
                                    {value.isActive ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {this.state.resort.length > 0 && (
                    <nav aria-label="Page navigation">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={ this.state.totalPages }
                        marginPagesDisplayed={ 2 }
                        pageRangeDisplayed={ 5 }
                        onPageChange={ this.handlePageClick }
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        activeClassName="active"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                      />
                    </nav>
                  )}
                </CardBody>
              </Card>
            </LoadingOverlay>
          </Container>
        </TransitionGroup>
      </>
    );
  }
}

export default withRouter(Resort);
