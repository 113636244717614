/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  FormGroup,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';
import { Loader } from 'react-loaders';
import { Formik, Form } from 'formik';

import { toast } from 'react-toastify';

import { TransitionGroup } from 'react-transition-group';

import { withRouter } from 'react-router-dom';

import SweetAlert from 'sweetalert-react';

import LoadingOverlay from 'react-loading-overlay';

import ReactPaginate from 'react-paginate';
import weddingPackage from '../../../api/weddingPackage';
import PageTitle from '../../../Layout/AppMain/PageTitle';

class WeddingPackage extends React.Component {
  columns = [
    { title: 'Package Name', key: 'PackageName', isSortable: true },
    { title: 'Resort Name', key: 'ResortName', isSortable: true },
    { title: 'Rate', key: 'Rate', isSortable: false },
    { title: 'Deposit Price', key: 'packageDepositPrice', isSortable: true },
    { title: 'Status', key: 'IsActive', isSortable: true },
    { title: 'Action' },
  ];

  obj = [];

  showConfirmationToDelete = (id, enable) => {
    this.deletePackageId = id;
    this.enable = enable;
    if (this.enable) {
      this.setState({ statustext: 'Are you sure to enable the package?' });
    } else {
      this.setState({ statustext: 'Are you sure to disable the pacakge?' });
    }
    this.setState({ show: true });
  };

  deletePackageId;

  queryObject = {};

  constructor(props) {
    super(props);

    this.queryObject.IsSortAsc = true;
    this.queryObject.PageNumber = 1;
    this.queryObject.PageSize = 10;
    this.queryObject.SortBy = '';

    this.queryObject.destinationId = 0;
    this.queryObject.subDestinationId = 0;
    this.queryObject.resortId = 0;
    this.queryObject.Search = this.obj;

    this.state = {
      active: false,
      packagesList: [],
      destination: [],
      subDestination: [],
      resort: [],
      statustext: '',
      searchinitialValues: {
        destinationId: '0',
        subDestinationId: 0,
        resortId: 0,
      },
    };
  }

  async componentDidMount() {
    await this.searchPackage();
    await this.loadDestination();
  }

  async loadDestination() {
    const destination = await weddingPackage.destination.getDestination();
    this.setState({ destination });
  }

  async loadSubDestination(e) {
    const id = e.target.value;

    if (id === 0) {
      const resort = await weddingPackage.resort.getResortBySubDestinationId(
        id
      );
      this.setState({ resort });
    }
    const subDestination = await weddingPackage.subDestination.getsubdestinationByDestinationId(id);
    this.setState({ subDestination });
  }

  handlePageClick = async (data) => {
    const { selected } = data;
    this.queryObject.PageNumber = selected + 1;
    await this.searchPackage();
  };

  async resort(e) {
    const resort = await weddingPackage.resort.getResortBySubDestinationId(
      e.target.value
    );
    this.setState({ resort });
  }

  async searchPackage() {
    this.setState({ active: true });
    const result = await weddingPackage.packagedetails.list(this.queryObject);
    this.setState({ packagesList: result.data, active: false });

    this.queryObject.PageNumber = result.pagination.currentPage;
    this.queryObject.PageSize = result.pagination.itemsPerPage;
    this.queryObject.totalItems = result.pagination.totalItems;
    this.setState({
      totalPages: result.pagination.totalPages,
      IsSortAsc: this.queryObject.isSortAsc,
    });
  }

  handleSearch = (e) => {
    this.queryObject.PageNumber = 1;
    const exist = this.obj.some((x) => x.columnSearchName === e.target.name);
    if (!exist) {
      this.obj.push({
        columnSearchName: e.target.name,
        columnSearchValue: e.target.value,
      });
    } else {
      const index = this.obj.findIndex(
        (p) => p.columnSearchName === e.target.name
      );
      this.obj[index].columnSearchValue = e.target.value;
    }
    this.searchPackage();
  };

  sortBy = (columnName) => {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.setState({
      sortByColumn: columnName,
      IsSortAsc: this.queryObject.isSortAsc,
    });

    this.searchPackage();
  };

  deleteWeddingPackage() {
    weddingPackage.packagedetails.delete(this.deletePackageId).then(() => {
      toast.success('The status of wedding pacakge saved successfully.');
      this.setState({ show: false });
      this.searchPackage();
    });
  }

  render() {
    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text={ this.state.statustext }
          showCancelButton
          onConfirm={ () => {
            this.deleteWeddingPackage();
          } }
          onCancel={ () => {
            this.setState({ show: false });
          } }
          onClose={ () => {} }
        />

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <div>
              <PageTitle
                heading="Wedding Package Management"
                icon="pe-7s-shopbag icon-gradient bg-tempting-azure"
              />
            </div>

            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Filter</CardTitle>

                <Formik
                  initialValues={ this.state.searchinitialValues }
                  enableReinitialize
                  onSubmit={ (values) => {
                    this.queryObject.destinationId = values.destinationId;
                    this.queryObject.subDestinationId = values.subDestinationId;
                    this.queryObject.resortId = values.resortId;
                    this.searchPackage();
                  } }
                >
                  {({
                    values,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={ handleSubmit }>
                      <div className="form-row ">
                        <Col md={ 3 }>
                          <FormGroup>
                            <label>Destination</label>

                            <select
                              onChange={ (e) => {
                                this.loadSubDestination(e);
                                setFieldValue('destinationId', e.target.value);
                                setFieldValue('subDestinationId', 0);
                                setFieldValue('resortId', 0);
                                this.setState({
                                  searchinitialValues: {
                                    destinationId: e.target.value,
                                    subDestinationId: 0,
                                    resortId: 0,
                                  },
                                });
                              } }
                              name="destinationId"
                              className="form-control"
                              value={ values.destinationId }
                            >
                              <option value="0">--Select--</option>
                              {this.state.destination.map((d) => (
                                <option key={ d.value } value={ d.value }>
                                  {d.text}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md={ 3 }>
                          <FormGroup>
                            <label>Sub Destination</label>

                            <select
                              onChange={ (e) => {
                                this.resort(e);
                                setFieldValue(
                                  'subDestinationId',
                                  e.target.value
                                );
                                setFieldValue('resortId', 0);
                              } }
                              name="subDestinationId"
                              className="form-control"
                            >
                              <option value="0">--Select--</option>
                              {this.state.subDestination.map((sub) => (
                                <option key={ sub.value } value={ sub.value }>
                                  {sub.text}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>

                        <Col md={ 3 }>
                          <FormGroup>
                            <label> Resort </label>

                            <select
                              name="resortId"
                              onChange={ (e) => {
                                setFieldValue('resortId', e.target.value);
                              } }
                              className="form-control"
                            >
                              <option value="0">--Select--</option>
                              {this.state.resort.map((res) => (
                                <option key={ res.value } value={ res.value }>
                                  {res.text}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </div>

                      <Button
                        className="mb-2 mr-2 btn-icon float-left"
                        color="primary"
                      >
                        Search
                      </Button>

                      <Button
                        className="mb-2 mr-2 btn-icon float-left"
                        onClick={ (e) => {
                          e.preventDefault();
                          this.setState({ subDestination: [], resort: [] });

                          this.setState({
                            searchinitialValues: {
                              destinationId: '0',
                              subDestinationId: 0,
                              resortId: 0,
                            },
                          });
                          this.queryObject.destinationId = 0;
                          this.queryObject.subDestinationId = 0;
                          this.queryObject.resortId = 0;
                          this.searchPackage();
                        } }
                        color="primary"
                      >
                        Clear
                      </Button>
                      {/* {<pre>{JSON.stringify({  values,errors }, null, 4)}</pre> } */}
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>

            <LoadingOverlay
              tag="div"
              active={ this.state.active }
              styles={ {
                overlay: (base) => ({
                  ...base,
                  background: '#fff',
                  opacity: 0.5,
                }),
              } }
              spinner={ <Loader active /> }
            >
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Wedding package List</CardTitle>
                  <Button
                    className="mb-2 mr-2 btn-icon float-right"
                    color="primary"
                    onClick={ () => {
                      this.props.history.push('/wedding/create-package');
                    } }
                  >
                    Add Wedding Package
                  </Button>
                  <Table
                    size="md"
                    responsive
                    striped
                    bordered
                    hover
                    className="mb-3"
                  >
                    <thead>
                      <tr>
                        {this.columns.map((value, index) => {
                          if (value.isSortable) {
                            return (
                              <th
                                key={ index }
                                className={
                                  this.state.sortByColumn === value.key
                                  && this.state.IsSortAsc
                                    ? 'asc pointer'
                                    : this.state.sortByColumn === value.key
                                      && !this.state.IsSortAsc
                                      ? 'desc pointer'
                                      : 'pointer'
                                }
                                onClick={ () => this.sortBy(value.key) }
                              >
                                {value.title}
                              </th>
                            );
                          }
                          return <th key={ index }>{value.title}</th>;
                        })}
                      </tr>

                      <tr>
                        <th>
                          <input
                            type="text"
                            name="PackageName"
                            onKeyUp={ this.handleSearch }
                            className="searchtext form-control"
                          />{' '}
                        </th>
                        <th> </th>
                        <th> </th>
                        <th> </th>
                        <th />
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.packagesList.length > 0
                        && this.state.packagesList.map((value, index) => (
                          <tr key={ index }>
                            <td>{value.packageName}</td>
                            <td>{value.resortName}</td>
                            <td>{value.packageRate}</td>
                            <td>{value.packageDepositPrice}</td>
                            <td>
                              {!value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-danger">
                                  {' '}
                                  Disabled{' '}
                                </div>
                              )}
                              {value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-success">
                                  {' '}
                                  Enabled{' '}
                                </div>
                              )}
                            </td>
                            <td>
                              <UncontrolledButtonDropdown>
                                <DropdownToggle
                                  caret
                                  className="mb-2 mr-2"
                                  color="primary"
                                >
                                  Action
                                </DropdownToggle>
                                <DropdownMenu positionFixed={true} className="dropdown-menu-rounded dropdown-menu-lg">
                                  <DropdownItem
                                    onClick={ () => this.props.history.push(
                                      `/wedding/edit-package/${value.weddingPackageID}`
                                    ) }
                                  >
                                    Manage
                                  </DropdownItem>

                                  <DropdownItem divider />

                                  {!value.isActive && (
                                    <DropdownItem
                                      onClick={ () => this.showConfirmationToDelete(
                                        value.weddingPackageID,
                                        true
                                      ) }
                                    >
                                      Enable
                                    </DropdownItem>
                                  )}
                                  {value.isActive && (
                                    <DropdownItem
                                      onClick={ () => this.showConfirmationToDelete(
                                        value.weddingPackageID,
                                        false
                                      ) }
                                    >
                                      Disable
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {this.state.packagesList.length > 0 && (
                    <nav aria-label="Page navigation">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={ this.state.totalPages }
                        marginPagesDisplayed={ 2 }
                        pageRangeDisplayed={ 5 }
                        onPageChange={ this.handlePageClick }
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        activeClassName="active"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                      />
                    </nav>
                  )}
                </CardBody>
              </Card>
            </LoadingOverlay>
          </Container>
        </TransitionGroup>
      </>
    );
  }
}

export default withRouter(WeddingPackage);
