export interface IAccountDetails {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: string;
}

export interface ClientAccountDetailResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  phoneNumber: any;
  roles: Role[];
  isActive: boolean;
  city: any;
  country: any;
  state: any;
}

export interface Role {
  id: string;
  name: string;
}

export interface IUserCommunicationPrefsLookup {
  id: string | null;
  communicationLevel: string | null;
  communicationStyle: string | null;
}

export interface IUserGroup {
  id: string;
  name: string;
  leaderEmail: string;
  leaderName: string;
  isGroupLeader: boolean;
}

export default interface initialState {
  loading: boolean;
  error?: null | string;
  details: IAccountDetails;
  communication: {
    style: string;
    level: string;
  };
  userRoomDetails: {
    loading: boolean;
    list: IUserRoomDetailsRecord[];
  };
  userGroups: {
    loading: boolean;
    list: IUserGroup[];
  };
}

export interface IRoomDetailsRecord {
  id: string;
  roomName: string | null;
  roomStatus: string | null;
  roomType: string | null;
  supplier: string | null;

  /**
   * this is in the form of dollars and cents
   * for instance, totalPackageAmount: "2,257.48"
   */
  totalPackageAmount: string | null;

  /**
   * this is in the form of dollars and cents
   * for instance, totalPaidToTz: "150.00"
   */
  totalPaidToTz: string | null;

  /**
   * this is in the form of dollars and cents
   * for instance, tzPackage: "150.00"
   */
  tzPackage: string | null;

  /**
   * this is in the form of "2022-10-01"
   */
  travelDate: string | null;

  /**
   * this is in the form of "10/07/2022"
   */
  travelEndDate: string | null;

  /**
   * this is in the form "10/01/2022"
   */
  travelStartDate: string | null;

  /**
   * this isin the form of "2022-07-28"
   */
  finalPaymentDue: string | null;
}

export enum ROOM_ROLES {
  RoomManager = 'Room Manager',
  ViewAndPay = 'View & Pay',
}

export interface IBaseCrmRoomDetails {
  roomId: string;
  roomName: string | null;
  roomUrl: string | null;
  userId: string;
}

export interface IUserRoomDetailsRecord extends IBaseCrmRoomDetails {
  groupId: string | null;
  groupName: string | null;
  roomDetail: IRoomDetailsRecord | null;
  roomRole: ROOM_ROLES | null;
  userEmail?: string;
  isGroupLeader?:boolean;
}
