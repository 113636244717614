import { WeddingDetails } from "../../slice/types"
export interface IRequestDetails {
  chosenHotel: string;
  resortVenueId: string;
  venue: string;
  weddingPackageId: string;
  weddingEventDate: Date;
  ceremonyTime: string;
  altWeddingDate: Date;
  altWeddingTime: string;
  weddingDateStatus: string;
  preferredCeremonyTime: string;
  alternativeWeddingDate: string;
  alternativeWeddingTime: string;
  weedingDate: Date;
  resortID: string;
  venueId: string;
  firstName:string,
  lastName:string,email:string,
  weddingDateStatusText: string;
}

export enum WEDDING_DATE_STATUS {
  REQUESTED = 1,
  REJECTED = 4,
  EXPIRED = 8,
  CONFIRMEDDATE = 2,
  CONFIRMEDAVAILABILITYDATE = 5,
  CONFIRMEDALTERNATIVEDATE = 3,
  BOOKINGREQUESTED = 6,
  BOOKINGCONFIRM = 7
}

export interface WeddingDateQueryObject {
  IsSortAsc: boolean;
  PageNumber: number;
  PageSize: number;
  sortBy: string;
  ResortID: number;
  venueID: number;
  leadTypeID: number;
  WeddingDateStatusID: number;
  Search: Array<any>;
  totalItems: number;
}

export interface IConfirmDetails extends WeddingDetails {}
export interface ICeremonyTime {
  id: number;
  ceremonyTime: 'MORNING' | 'AFTERNOON' | 'SUNSET';
}

export interface IWeddingRequestHistory {
  createdBy: string;
  createdDate: Date | string;
  status: number;
  narrationText: string;
  userType: null;
  resort: string;
  venue: string;
  weddingPackage: string;
  weddingDate: Date | string;
  weddingTime: 'Afternoon';
  altWeddingDate: Date | string;
  altWeddingTime: string;
  weddingDateStatus: WeddingDateStatusType;
}

type WeddingDateStatusType =
  | 'BOOKINGCONFIRM'
  | 'BOOKINGREQUESTED'
  | 'CONFIRMEDALTERNATIVEDATE'
  | 'CONFIRMEDAVAILABILITYDATE'
  | 'CONFIRMEDDATE'
  | 'EXPIRED'
  | 'REJECTED'
  | 'REQUESTED';

export interface IWeddingDateStatus {
  disabled: boolean;
  group: null;
  selected: boolean;
  text: WeddingDateStatusType;
  value: string;
}

interface Image {
  imageContentID: number;
  imageName: string;
  imageURL: string;
  altTagText: string;
  contentType: null;
  contentReferenceID: number;
  imageOrder: number;
}

export interface IVenue {
  resortVenueId: number;
  venueName: string;
  resortId: number;
  description: string;
  capacity: number;
  allowReligiousCeremony: boolean;
  images: Array<Image>;
  country: string;
  city: string;
  resortName: string;
}

export interface IResortPackage {
  weddingPackageId: number;
  packageName: string;
  packageRate: number;
  packageDepositPrice: number;
  packageDescription: string;
  packageIconClass: string;
  resortId: number;
  minumimNoofGuest: number;
  maximumNoofGuest: number;
  noofGuestsIncluded: number;
  additionalPricePerHead: number;
  images: Array<Image>;
  isActive: boolean;
}

export interface IResort {
  resortID: number;
  resortName: string;
  destinationID: number;
  subDestinationID: number;
  isActive: boolean;
}

export interface IWeddingDetailIdentifier {
  id: number;
  status: boolean;
  userId: string | null;
}

export interface IWeddingDate {
  detailsPageStatus: IWeddingDetailIdentifier;
  loading: boolean;
  confirmDetails: IConfirmDetails;
  requestDetails: IRequestDetails;
  ceremonyTimeList: ICeremonyTime[];
  weddingDateQueryObject: WeddingDateQueryObject;
  weddingList: WeddingDetailListItem[];
  weddingRequestHistory: any[];
  updateConfirmDetailsLoading: boolean;
  updateRequestDetailsLoading: boolean;
  resort: {
    items: IResort[],
    loading: boolean
  },
  venue: {
    items: IVenue[],
    loading: boolean
  },
  packages: {
    items: IResortPackage[],
    loading: boolean
  },
  venues: IVenue[];
  weddingPackages: IResortPackage[];
  weddingDateStatuses: IWeddingDateStatus[];
}

export interface IAction<T> {
  payload: T;
  type: string;
}

export interface WeddingDetailListItem {
  id: number;
  weddingPackageId: number;
  packageName: string;
  venueName: string;
  resortVenueId: number;
  resort: string;
  resortID: number;
  eventDate: Date;
  eventDateString: string;
  eventDateAlt: Date;
  eventDateAltString: string;
  preferPartOfDay: string;
  preferPartOfDayAlt: string;
  leadTypeName: string;
  leadTypeId: number;
  statusId: number;
  salesLeadStatus: string;
  createdDate: Date;
  createdBy: string;
  firstName: string;
  lastName: string;
  email: string;
  weddingDateStatusId: number;
  weddingDateStatusText: string;
  fullName: string;
  isDeleted: boolean;
  isActive: boolean;
}
