import { signoutRedirect } from 'auth/AuthService';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { history } from 'index';

export const axiosFailedResponsesHandler = (
  error: AxiosError | Error | unknown,
) => {
  //ensure we have an actual Axios error and not something else
  if (!axios.isAxiosError(error)) {
    toast.error(
      'Unexpected server side or network error. Please try again or contact the dev team.',
    );
    return Promise.reject(error);
  }

  // all non-2xx response codes will come through here
  const data = error.response?.data;
  const status = error.response?.status ?? 400;

  switch (status) {
    //TODO: since these might indicate no data returned, we may want to decide
    //to not have this be in the error interceptor, but instead force
    //callers of api endpoints to handle their own not found results with more granular error messaging.
    case 404:
    case 400:
      //is it error? errors? message? place your bets!
      if (typeof data === 'string') {
        toast.error(data);
        break;
      }
      if (data?.errors) {
        const modelStateErrors = [];
        Object.keys(data.errors).forEach(key => {
          if (data.errors[key]) {
            modelStateErrors.push(data.errors[key]);
          }
        });
        toast.error(modelStateErrors.flat());
        break;
      }
      if (data?.error) {
        toast.error(data?.error);
        break;
      }
      if (data?.message) {
        toast.error(data?.message);
        break;
      }
      //disabling this for now, as its obnoxious
      // toast.warn(
      //   'An attempt to lookup data returned no results (400 or 404). This may or may not indicate an error.',
      // );
      break;
    case 401:
    case 403:
      const authenticate = error.response.headers['www-authenticate'];
      if (authenticate) {
        if (authenticate.includes('invalid_token')) {
          signoutRedirect();
          break;
        } else {
          toast.error('Unauthorized request detected');
          history.push('/unauthorized');
        }
      } else {
        toast.error('Unauthorized request detected');
        history.push('/unauthorized');
      }

      break;
    case 500:
    default:
      toast.error(
        'Unexpected server side or network error. Please try again or contact the dev team.',
      );
      break;
  }

  return Promise.reject(error);
};
