import React, { useState } from 'react';
import { Button } from 'reactstrap';
interface Props {
  children: React.ReactNode;
  limit: number;
  viewMoreLabel?: string;
}

const ViewMoreWrapper = ({
  children,
  limit,
  viewMoreLabel = 'view more',
}: Props) => {
  const [viewMore, setViewMore] = useState(false);
  const childArr = React.Children.toArray(children);
  const sliced = childArr.slice(0, limit);
  if (!childArr || !childArr.length) return null;
  return (
    <>
      <div className="position-relative">
        {viewMore ? children : sliced}
        <div
          className="position-absolute"
          style={{
            backgroundImage: !viewMore
              ? 'linear-gradient(to bottom, transparent, white)'
              : 'none',
            bottom: 0,
            left: 0,
            right: 0,
            height: !viewMore ? '30%' : '0',
            userSelect: 'none',
            pointerEvents: 'none',
          }}
        />
      </div>
      {!viewMore && childArr.length > limit && (
        <div className="mt-4">
          <Button
            color="primary"
            className="text-uppercase"
            onClick={() => setViewMore(true)}
          >
            {viewMoreLabel}
          </Button>
        </div>
      )}
    </>
  );
};

export default ViewMoreWrapper;
