import { calendlyLinkRegex, phoneRegex } from 'common/utils/constants';
import * as Yup from 'yup';

const YupSchema = () => ({
firstName: () => Yup.string().required('First Name is required'),
lastName: () => Yup.string().required('Last Name is required.'),
email: () => Yup.string().email().required('Email is required'),
phoneNumber: () => Yup.string()
    .matches(phoneRegex, { message: 'Invalid format for Phone Number!' })
    .nullable(),
selectedRoles: () => Yup.object({
    label: Yup.string().required(),
    value: Yup.string().required(),
}).required('At least 1 role is required'),
crmUserId: () => Yup.string(),
calendly: () => Yup.string()
    .matches(calendlyLinkRegex, {
    message: 'Invalid URL! Please enter a calendly link.',
    })
    .nullable(),
biography: () => Yup.string(),
})

const schema = YupSchema();

Object.freeze(schema);

export default schema;