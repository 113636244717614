/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import SweetAlert from 'sweetalert-react';
import { Formik, Form } from 'formik';
import { Loader } from 'react-loaders';
import ReactPaginate from 'react-paginate';

import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';

import { ToastContainer, toast } from 'react-toastify';

import { TransitionGroup } from 'react-transition-group';
// import bg2 from "../../../../assets/utils/images/dropdown-header/abstract2.jpg";

import { withRouter } from 'react-router-dom';

import hotel from '../../../api/hotelContent';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import { formatDate } from 'common/utils/dateUtils';

class HotelContent extends React.Component {
  columns = [
    { title: 'Hotel Name', key: 'HotelFriendlyName', isSortable: true },
    { title: 'Airport Code', key: 'AirportCodes', isSortable: true },
    { title: 'City', key: 'City', isSortable: true },
    { title: 'Country', key: 'Country', isSortable: true },
    { title: 'Top Resort', key: 'TopResort', isSortable: true },
    { title: 'Modified', key: 'UpdatedDate', isSortable: true },
    { title: 'Status', key: 'IsDelete', isSortable: true },
    { title: 'Action' },
  ];

  // state = HotelContent.getDefaultState();

  obj = [];

  static getDefaultState() {
    return {
      ...ToastContainer.defaultProps,
      transition: 'bounce',
      type: 'success',
      disableAutoClose: false,
    };
  }

  // expanstate = { expanded: false };

  deleteHotleId;

  enable;

  queryObject = {};

  constructor(props) {
    super(props);

    this.queryObject.IsSortAsc = true;
    this.queryObject.PageNumber = 1;
    this.queryObject.PageSize = 10;
    this.queryObject.SortBy = 'HotelFriendlyName';
    this.queryObject.hotelChainID = 0;
    this.queryObject.HotelCountryId = 0;
    this.queryObject.HotelSearch = this.obj;

    this.state = {
      ...ToastContainer.defaultProps,
      hotelContentList: null,
      hotelChain: [],
      countryList: [],
      loaderType: 'line-scale-pulse-out-rapid',
      statustext: '',
      totalPages: 0,
    };
  }

  getCountrylist() {
    hotel.hotelcontent.countrylist().then((response) => {
      this.setState({ countryList: response });
    });
  }

  getHotelChain() {
    hotel.hotelcontent.hotelchainlist().then((response) => {
      this.setState({ hotelChain: response });
    });
  }

  sortBy = (columnName) => {
    if (this.queryObject.sortBy === columnName) {
      this.queryObject.isSortAsc = !this.queryObject.isSortAsc;
    } else {
      this.queryObject.sortBy = columnName;
      this.queryObject.isSortAsc = true;
    }
    this.setState({
      sortByColumn: columnName,
      IsSortAsc: this.queryObject.isSortAsc,
    });

    this.searchSubmit();
  };

  searchSubmit = () => {
    this.setState({ active: true });

    hotel.hotelcontent.list(this.queryObject).then((response) => {
      this.setState({ active: false, hotelContentList: null });
      this.setState({ hotelContentList: response.data });
      this.queryObject.PageNumber = response.pagination.currentPage;
      this.queryObject.PageSize = response.pagination.itemsPerPage;
      this.queryObject.totalItems = response.pagination.totalItems;
      this.setState({
        totalPages: response.pagination.totalPages,
        IsSortAsc: this.queryObject.isSortAsc,
      });
    });
  };

  componentDidMount() {
    this.getCountrylist();
    this.getHotelChain();
    this.searchSubmit();
  }

  manageHotel = (id) => {
    this.props.history.push(`/hotels/hotel-content-manage/${id}`);
  };

  showConfirmationToDelete = (id, enable) => {
    this.deleteHotleId = id;
    this.enable = enable;
    if (this.enable) {
      this.setState({ statustext: 'Are you sure to enable the hotel?' });
    } else {
      this.setState({ statustext: 'Are you sure to disable the hotel?' });
    }
    this.setState({ show: true });
  };

  deleteHotel = () => {
    hotel.hotelcontent
      .deleteHotel({ hotelID: this.deleteHotleId, isEnabled: this.enable })
      .then((response) => {
        toast.success(response);
        this.searchSubmit();
      });
  };

  handleChange = (e) => {
    if (e.target.name === 'country') {
      this.queryObject.hotelCountryID = e.target.value;
    }
    if (e.target.name === 'hotelchain') {
      this.queryObject.hotelChainID = e.target.value;
    }
    this.queryObject.HotelSearch = this.obj;
    const elements = document.getElementsByClassName('searchtext');
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = '';
    }

    this.searchSubmit();
  };

  handleSearch = (e) => {
    this.queryObject.PageNumber = 1;
    const exist = this.obj.some((x) => x.columnSearchName === e.target.name);
    const length = [...e.target.value];

    if (!exist) {
      this.obj.push({
        columnSearchName: e.target.name,
        columnSearchValue: e.target.value,
      });
    } else {
      const index = this.obj.findIndex(
        (p) => p.columnSearchName === e.target.name
      );
      this.obj[index].columnSearchValue = e.target.value;
    }
    if (length.length >= 3 || length.length === 0) {
      this.searchSubmit();
    } else if (e.target.name === 'AirportCodes') {
      this.searchSubmit();
    }
  };

  handlePageClick = (data) => {
    const { selected } = data;
    this.queryObject.PageNumber = selected + 1;
    this.searchSubmit();
  };

  render() {
    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text={ this.state.statustext }
          showCancelButton
          onConfirm={ () => {
            this.deleteHotel();
            this.setState({ show: false });
          } }
          onCancel={ () => {
            console.log('cancel');
            this.setState({ show: false });
          } }
          onClose={ () => console.log('close') }
        />

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <div>
              <PageTitle
                heading="Hotel Content Management"
                icon="lnr-apartment icon-gradient bg-tempting-azure"
              />
            </div>

            <Card className="main-card mb-3">
              <CardBody>
                <button
                  type="button"
                  className=" mb-3 btn btn-primary"
                  onClick={ () => this.props.history.push('/hotels/create') }
                >
                  Add Hotel
                </button>
                <CardTitle>Hotel List</CardTitle>

                <Formik initialValues={ { hotelchain: '', Country: '' } }>
                  <Form>
                    <div className="form-row ">
                      <Col md={ 3 }>
                        <FormGroup>
                          {/* <SelectInput  options={this.state.hotelChain} label="Hotel Chain" name="hotelchain"  /> */}
                          <label htmlFor="hotelchain">Hotel Chain</label>

                          <select
                            onChange={ this.handleChange }
                            name="hotelchain"
                            className="form-control"
                          >
                            <option value="0">--Select--</option>
                            {this.state.hotelChain.map((hotelchain) => (
                              <option
                                key={ hotelchain.value }
                                value={ hotelchain.value }
                              >
                                {hotelchain.text}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>

                      <Col md={ 3 }>
                        <FormGroup>
                          <label htmlFor="country">Country</label>

                          <select
                            onChange={ this.handleChange }
                            name="country"
                            className="form-control"
                          >
                            <option value="0">--Select--</option>
                            {this.state.countryList.map((country) => (
                              <option key={ country.value } value={ country.value }>
                                {country.text}
                              </option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                    </div>
                  </Form>
                </Formik>
              </CardBody>
            </Card>

            <LoadingOverlay
              tag="div"
              active={ this.state.active }
              styles={ {
                overlay: (base) => ({
                  ...base,
                  background: '#fff',
                  opacity: 0.5,
                }),
              } }
              spinner={ <Loader active type={ this.state.loaderType } /> }
            >
              <Card className="main-card mb-3">
                {
                  <CardBody>
                    <CardTitle>Hotel Content</CardTitle>
                    <Table size="sm" striped bordered hover className="mb-10">
                      <thead>
                        <tr>
                          {this.columns.map((value, index) => {
                            if (value.isSortable) {
                              return (
                                <th
                                  key={ index }
                                  className={
                                    this.state.sortByColumn === value.key
                                    && this.state.IsSortAsc
                                      ? 'asc pointer'
                                      : this.state.sortByColumn === value.key
                                        && !this.state.IsSortAsc
                                        ? 'desc pointer'
                                        : 'pointer'
                                  }
                                  onClick={ () => this.sortBy(value.key) }
                                >
                                  {value.title}
                                </th>
                              );
                            }
                            return <th key={ index }>{value.title}</th>;
                          })}
                        </tr>

                        <tr>
                          <th>
                            {' '}
                            <input
                              type="text"
                              name="HotelFriendlyName"
                              onKeyUp={ this.handleSearch }
                              className="searchtext form-control"
                            />{' '}
                          </th>
                          <th style={ { width: '10%' } }>
                            {' '}
                            <input
                              type="text"
                              name="AirportCodes"
                              onKeyUp={ this.handleSearch }
                              className="searchtext form-control"
                            />{' '}
                          </th>
                          <th>
                            <input
                              type="text"
                              name="City"
                              onKeyUp={ this.handleSearch }
                              className="searchtext form-control"
                            />{' '}
                          </th>
                          <th>
                            <input
                              type="text"
                              name="Country"
                              onKeyUp={ this.handleSearch }
                              className="searchtext form-control"
                            />{' '}
                          </th>
                          <th />
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.hotelContentList
                          && this.state.hotelContentList.map((hotel) => (
                            <tr key={ hotel.hotelID }>
                              <td>{hotel.hotelFriendlyName} </td>
                              <td style={ { width: '10%' } }>
                                {hotel.airportCodesStr}
                              </td>
                              <td>{hotel.city}</td>
                              <td>{hotel.country}</td>
                              <td>
                                {hotel.isTopResort && 'Y'}
                                {!hotel.isTopResort && 'N'}
                              </td>
                              <td>
                                {formatDate(hotel.updatedDate, 'MM/dd/yyyy')}
                              </td>
                              <td>
                                {hotel.isEnabled && (
                                  <div className="mb-2 mr-2 badge badge-pill badge-success">
                                    {' '}
                                    Enabled{' '}
                                  </div>
                                )}
                                {!hotel.isEnabled && (
                                  <div className="mb-2 mr-2 badge badge-pill badge-danger">
                                    {' '}
                                    Disabled{' '}
                                  </div>
                                )}
                              </td>
                              <td>
                                {/* <button className="mb-2 mr-2 btn-icon btn-pill btn btn-outline-primary" onClick={()=>this.manageHotel(hotel.hotelID)}>
                                                                <i className="pe-7s-tools btn-icon-wrapper"> </i>Manage</button> */}
                                <UncontrolledButtonDropdown>
                                  <DropdownToggle
                                    caret
                                    className="mb-2 mr-2"
                                    color="primary"
                                  >
                                    Action
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                                    <DropdownItem
                                      onClick={ () => this.manageHotel(hotel.hotelID) }
                                    >
                                      Manage
                                    </DropdownItem>

                                    <DropdownItem divider />
                                    {hotel.isEnabled && (
                                      <DropdownItem
                                        onClick={ () => this.showConfirmationToDelete(
                                          hotel.hotelID,
                                          false
                                        ) }
                                      >
                                        Disable
                                      </DropdownItem>
                                    )}
                                    {!hotel.isEnabled && (
                                      <DropdownItem
                                        onClick={ () => this.showConfirmationToDelete(
                                          hotel.hotelID,
                                          true
                                        ) }
                                      >
                                        Enable
                                      </DropdownItem>
                                    )}
                                  </DropdownMenu>
                                </UncontrolledButtonDropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    <nav aria-label="Page navigation">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={ this.state.totalPages }
                        marginPagesDisplayed={ 2 }
                        pageRangeDisplayed={ 5 }
                        onPageChange={ this.handlePageClick }
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        activeClassName="active"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                      />
                    </nav>
                  </CardBody>
                }
              </Card>
            </LoadingOverlay>
          </Container>
        </TransitionGroup>
      </>
    );
  }
}
export default withRouter(HotelContent);
