/* eslint-disable react/prop-types */
import React from 'react';

import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
} from 'reactstrap';

import { toast } from 'react-toastify';

import { Formik, Form, Field } from 'formik';

import { withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { Typeahead } from 'react-bootstrap-typeahead';
import { TextArea } from '../../../common/Form/TextArea';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import weddingPackage from '../../../api/weddingPackage';

import hotel from '../../../api/hotelContent';

class CreateResort extends React.Component {
  queryObject = {};

  obj = [];

  constructor(props) {
    super(props);
    this.queryObject.IsSortAsc = true;
    this.queryObject.PageNumber = 1;
    this.queryObject.PageSize = 10;
    this.queryObject.SortBy = 'HotelFriendlyName';
    this.queryObject.hotelChainID = 0;
    this.queryObject.HotelCountryId = 0;
    this.queryObject.HotelSearch = this.obj;
    this.queryObject.IsEnabled = true;
    this.state = {
      initialValues: {
        ResortID: 0,
        description: '',
        destinationID: 0,
        subDestinationID: 0,
        PTIDAdminHotelID: '',
        resortName: '',
        isActive: false,
      },
      hotelContentList: [],
      selectedContent: [],
      destination: [],
      subDestination: [],
      multiple: false,
    };
  }

  componentDidMount() {
    this.loadDestination();
    const { id } = this.props.match.params;
    if (id && id > 0) {
      weddingPackage.resort.getResortByResortId(id).then((res) => {
        this.loadSubDestination(res.destinationID);
        this.getSearchHotelName(res.resortName);
        this.setState({ initialValues: res });
      });
    }
  }

  async loadDestination() {
    const destination = await weddingPackage.destination.getDestination();
    this.setState({ destination });
  }

  async loadSubDestination(id) {
    const subDestination = await weddingPackage.subDestination.getsubdestinationByDestinationId(id);
    this.setState({ subDestination });
  }

  handleChange = (e) => {
    if (e.length > 0) {
      const { hotelID, hotelFriendlyName } = e[0];
      const { initialValues } = this.state;
      initialValues.PTIDAdminHotelID = hotelID;
      initialValues.resortName = hotelFriendlyName;
      this.setState({ initialValues, selectedContent: e.slice(0, 1) });
    } else {
      this.setState({ selectedContent: [] });
    }
  };

  searchSubmit = () => {
    hotel.hotelcontent.list(this.queryObject).then((response) => {
      this.setState({ hotelContentList: response.data, selectedContent: [] });
    });
  };

  //   handleSearch = (e) => {
  //     const exist = this.obj.some(
  //       (x) => x.columnSearchName == 'HotelFriendlyName'
  //     );
  //     if (!exist) {
  //       this.obj.push({
  //         columnSearchName: 'HotelFriendlyName',
  //         columnSearchValue: e.target.value,
  //       });
  //     } else {
  //       const index = this.obj.findIndex(
  //         (p) => p.columnSearchName == 'HotelFriendlyName'
  //       );
  //       this.obj[index].columnSearchValue = e.target.value;
  //     }
  //     this.searchSubmit();
  //   };

  onInputChange = (v) => {
    const exist = this.obj.some(
      (x) => x.columnSearchName === 'HotelFriendlyName'
    );
    if (!exist) {
      this.obj.push({
        columnSearchName: 'HotelFriendlyName',
        columnSearchValue: v,
      });
    } else {
      const index = this.obj.findIndex(
        (p) => p.columnSearchName === 'HotelFriendlyName'
      );
      this.obj[index].columnSearchValue = v;
    }
    this.searchSubmit();
  };

  getSearchHotelName(name) {
    const exist = this.obj.some(
      (x) => x.columnSearchName === 'HotelFriendlyName'
    );
    if (!exist) {
      this.obj.push({
        columnSearchName: 'HotelFriendlyName',
        columnSearchValue: name,
      });
    } else {
      const index = this.obj.findIndex(
        (p) => p.columnSearchName === 'HotelFriendlyName'
      );
      this.obj[index].columnSearchValue = name;
    }
    hotel.hotelcontent.list(this.queryObject).then((response) => {
      this.setState({ hotelContentList: response.data });
      this.setState({ selectedContent: response.data.slice(0, 1) });
    });
  }

  render() {
    const { initialValues } = this.state;
    const { multiple } = this.state;
    // const { singleSelections, setSingleSelections } = this.state;
    return (
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }
      >
        <Container fluid>
          <div>
            <PageTitle
              heading="Resort Management"
              icon="lnr-apartment icon-gradient bg-tempting-azure"
            />
          </div>
        </Container>

        <Formik
          initialValues={ initialValues }
          validationSchema={ this.validationSchema }
          enableReinitialize
          onSubmit={ async (values) => {
            const newValues = { ...values };
            newValues.PTIDAdminHotelID = this.state.initialValues.PTIDAdminHotelID;
            newValues.resortName = this.state.initialValues.resortName;
            await weddingPackage.resort.saveResort(newValues);
            this.props.history.push('/wedding/resort');
            toast.success('Resort has been saved successfully.');
          } }
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Card>
              <CardBody>
                <Button
                  className="mb-2 mr-2 btn-icon float-right"
                  color="primary"
                  onClick={ () => {
                    this.props.history.push('/wedding/resort');
                  } }
                >
                  Back To List
                </Button>

                <Form autoComplete="off">
                  <Row>
                    <Col md="3"> </Col>
                    <Col md="6">
                      <Card>
                        <CardHeader>Create Resort</CardHeader>

                        <CardBody>
                          <FormGroup>
                            <Typeahead
                              id="typeID2"
                              labelKey="hotelFriendlyName"
                              name="PTIDAdminHotelID"
                              clearButton
                              // defaultSelected={this.state.selectedContent}
                              multiple={ multiple }
                              options={ this.state.hotelContentList }
                              onChange={ this.handleChange }
                              onInputChange={ this.onInputChange }
                              // onKeyDown={this.handleSearch}
                              selected={ this.state.selectedContent }
                              placeholder="Choose a Resort"
                            />
                          </FormGroup>

                          <FormGroup>
                            <label> Destination </label>
                            <Field
                              name="destinationID"
                              className={
                                errors.destinationID && touched.destinationID
                                  ? 'is-invalid form-control'
                                  : 'form-control'
                              }
                              onChange={ (e) => {
                                this.loadSubDestination(e.target.value);
                                setFieldValue('destinationID', e.target.value);
                              } }
                              as="select"
                            >
                              <option value="">--Select--</option>
                              {this.state.destination.map((destination) => (
                                <option
                                  key={ destination.value }
                                  value={ destination.value }
                                >
                                  {destination.text}
                                </option>
                              ))}
                            </Field>

                            {errors.destinationID && touched.destinationID ? (
                              <div className="invalid-validation">
                                {errors.destinationID}
                              </div>
                            ) : null}
                          </FormGroup>

                          <FormGroup>
                            <label> Sub Destination </label>
                            <Field
                              name="subDestinationID"
                              className={
                                errors.subDestinationID
                                && touched.subDestinationID
                                  ? 'is-invalid form-control'
                                  : 'form-control'
                              }
                              as="select"
                            >
                              <option value="">--Select--</option>
                              {this.state.subDestination.map(
                                (subDestination) => (
                                  <option
                                    key={ subDestination.value }
                                    value={ subDestination.value }
                                  >
                                    {subDestination.text}
                                  </option>
                                )
                              )}
                            </Field>

                            {errors.subDestinationID
                            && touched.subDestinationID ? (
                              <div className="invalid-validation">
                                {errors.subDestinationID}
                              </div>
                              ) : null}
                          </FormGroup>

                          <FormGroup>
                            <TextArea label="Description" name="description" />
                          </FormGroup>

                          <FormGroup>
                            <Row>
                              <Col md={ 12 }>
                                <Field
                                  type="checkbox"
                                  name="isActive"
                                />
                              &nbsp;&nbsp;
                                <label htmlFor="isActive">
                                  Enable
                                </label>
                              </Col>
                            </Row>
                          </FormGroup>
                        </CardBody>
                        <Button
                          disabled={ isSubmitting }
                          type="submit"
                          color="primary"
                          className="mt-1"
                        >
                          {isSubmitting ? 'Processing....' : 'Save'}
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Formik>
      </TransitionGroup>
    );
  }
}
export default withRouter(CreateResort);
