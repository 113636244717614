import { destifyServicesApiRequests } from './common';

const groups = {
  groupDetail: id => destifyServicesApiRequests.get(`groups/${id}`),
  weddingDetail: id => destifyServicesApiRequests.get(`groups/${id}/wedding`),
};

const rsvp = {
  list: id => destifyServicesApiRequests.get(`/groupleader/${id}/rsvp`),
  addGuest: data =>
    destifyServicesApiRequests.post('/RSVPGuest/AddRsvpGuest', data),
  removeGuest: data =>
    destifyServicesApiRequests.post('/RSVPGuest/DeleteRsvpGuest', data),
  sendCannedMail: data =>
    destifyServicesApiRequests.post('/RSVPGuest/RSVPSendCannedEmail', data),
  updateStatus: data =>
    destifyServicesApiRequests.post('/RSVPGuest/UpdateRsvpGuestStatus', data),
};

const guests = {
  list: id => destifyServicesApiRequests.get(`/groupleader/${id}/guestlist`),
  updateUserDetails: (id, value) =>
    destifyServicesApiRequests.put(`/User/UpdateUserProfile/${id}`, value),
};

const crmAPI = {
  groups,
  rsvp,
  guests,
};

export default crmAPI;
