import React from 'react';
import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
} from 'reactstrap';

import {
  toast,
} from 'react-toastify';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';

import { withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TextField } from '../../../common/Form/TextInput';
import { SelectInput } from '../../../common/Form/SelectInput';

import PageTitle from '../../../Layout/AppMain/PageTitle';

import weddingPackage from '../../../api/weddingPackage';
import {
  ResortPackageIcon,
} from '../../../common/options/categoryoption';

class PackageCreate extends React.Component {
  validationSchema = Yup.object({
    packageIconClass: Yup.string().required('Package Icon Class is required.'),
    resortID: Yup.string().required('Resort is required'),
    packageName: Yup.string().required('The package name is required.'),
    packageRate: Yup.string().required('The package  rate is required.'),
    packageDepositPrice: Yup.number().required(
      'The package deposite price is required.'
    ),
  });

  constructor(props) {
    super(props);

    this.state = {
      initialValues: {
        packageName: '',
        packageRate: '',
        packageDepositPrice: 0,
        destinationID: '',
        subDestinationID: '',
        resortID: '',
        packageIconClass: '',
        weddingPackageID: 0,
      },
      description: '',
      destination: [],
      subDestination: [],
      resort: [],
    };
  }

  onChangeDescription = (e, editor) => {
    this.setState({ description: editor.getData() });
  };

  async savePackage(value) {
    await weddingPackage.packagedetails.save(value);
  }

  async loadDestination() {
    const destination = await weddingPackage.destination.getDestination();
    this.setState({ destination });
  }

  async loadSubDestination(id) {
    const subDestination = await weddingPackage.subDestination.getsubdestinationByDestinationId(id);
    this.setState({ subDestination });
  }

  // async getResort(e) {
  // var resort = await weddingPackage.resort.getResortBySubDestinationId(e.target.value);
  // this.setState({ resort: resort });
  // }

  async getResort() {
    const resort = await weddingPackage.resort.getResortList();
    this.setState({ resort });
  }

  onchangeResort(id, setFieldValue) {
    if (id !== '') {
      const resort = this.state.resort.find((x) => x.resortID === parseInt(id, 10));
      this.loadSubDestination(resort.destinationID);
      setFieldValue('destinationID', resort.destinationID);
      setFieldValue('subDestinationID', resort.subDestinationID);
    }
  }

  async componentDidMount() {
    await this.getResort();
    this.loadDestination();
    const { id } = this.props.match.params;
    if (id && id > 0) {
      weddingPackage.packagedetails
        .getpackageById(this.props.match.params.id)
        .then((res) => {
          const resort = this.state.resort.find(
            (x) => x.resortID === parseInt(res.resortID, 10)
          );
          res.destinationID = resort.destinationID;
          res.subDestinationID = resort.subDestinationID;
          this.loadSubDestination(resort.destinationID);

          this.setState({ initialValues: res });
          this.setState({ description: res.packageDescription });
        });
    }
  }

  render() {
    const { initialValues } = this.state;

    return (
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }
      >
        <Container fluid>
          <div>
            <PageTitle
              heading="Wedding Package Management"
              icon="lnr-apartment icon-gradient bg-tempting-azure"
            />
          </div>
        </Container>

        <Formik
          initialValues={ initialValues }
          validationSchema={ this.validationSchema }
          enableReinitialize
          onSubmit={ async (values) => {
            const newValues = { ...values };
            newValues.packageDescription = this.state.description;
            await this.savePackage(newValues);
            this.props.history.push('/wedding/package');
            toast.success('Wedding pacakge saved successfully.');
          } }
        >
          {({ errors, touched, isSubmitting, setFieldValue }) => (
            <Card>
              <CardBody>
                <Button
                  className="mb-2 mr-2 btn-icon float-right"
                  color="primary"
                  onClick={ () => {
                    this.props.history.push('/wedding/package');
                  } }
                >
                  Back To List
                </Button>

                <Form autoComplete="off">
                  <Row>
                    <Col md="9">
                      <Card>
                        <CardHeader>Create Package</CardHeader>

                        <CardBody>
                          <FormGroup>
                            <TextField
                              label="Package Name"
                              name="packageName"
                            />
                          </FormGroup>

                          <FormGroup>
                            <SelectInput
                              options={ ResortPackageIcon }
                              label="Package Icon"
                              name="packageIconClass"
                            />
                          </FormGroup>
                          <FormGroup>
                            <label> Description/Details </label>
                            <CKEditor
                              editor={ ClassicEditor }
                              data={ this.state.description }
                              onChange={ this.onChangeDescription }
                            />
                          </FormGroup>

                          <FormGroup>
                            <label>Resort</label>
                            <Field
                              className="form-control"
                              name="resortID"
                              as="select"
                              onChange={ (e) => {
                                this.onchangeResort(
                                  e.target.value,
                                  setFieldValue
                                );
                                setFieldValue('resortID', e.target.value);
                              } }
                            >
                              <option value="">--Select--</option>
                              {this.state.resort.map((resort) => (
                                <option
                                  key={ resort.resortID }
                                  value={ resort.resortID }
                                >
                                  {resort.resortName}
                                </option>
                              ))}
                            </Field>
                            {/*  <SelectInput options={this.state.resort} label="Resort" name="resortID" /> */}
                          </FormGroup>

                          <FormGroup>
                            <label> Destination </label>
                            <Field
                              name="destinationID"
                              className={
                                errors.destinationID && touched.destinationID
                                  ? 'is-invalid form-control'
                                  : 'form-control'
                              }
                              as="select"
                              onChange={ (e) => {
                                this.loadSubDestination(e.target.value);
                                setFieldValue('destinationID', e.target.value);
                              } }
                              disabled="disabled"
                            >
                              <option value="">--Select--</option>
                              {this.state.destination.map((destination) => (
                                <option
                                  key={ destination.value }
                                  value={ destination.value }
                                >
                                  {destination.text}
                                </option>
                              ))}
                            </Field>

                            {errors.destinationID && touched.destinationID ? (
                              <div className="invalid-validation">
                                {errors.destinationID}
                              </div>
                            ) : null}
                          </FormGroup>

                          <FormGroup>
                            <label> Sub Destination </label>
                            <Field
                              name="subDestinationID"
                              className={
                                errors.subDestinationID
                                && touched.subDestinationID
                                  ? 'is-invalid form-control'
                                  : 'form-control'
                              }
                              as="select"
                              disabled="disabled"
                            >
                              <option value="">--Select--</option>
                              {this.state.subDestination.map(
                                (subDestination) => (
                                  <option
                                    key={ subDestination.value }
                                    value={ subDestination.value }
                                  >
                                    {subDestination.text}
                                  </option>
                                )
                              )}
                            </Field>

                            {errors.subDestinationID
                            && touched.subDestinationID ? (
                              <div className="invalid-validation">
                                {errors.subDestinationID}
                              </div>
                              ) : null}
                          </FormGroup>

                          <FormGroup>
                            <TextField
                              label="Package Rate"
                              name="packageRate"
                            />
                          </FormGroup>

                          <FormGroup>
                            <TextField
                              label="Package Deposit Price"
                              name="packageDepositPrice"
                            />
                          </FormGroup>
                        </CardBody>
                        <Button
                          disabled={ isSubmitting }
                          type="submit"
                          color="primary"
                          className="mt-1"
                        >
                          {isSubmitting ? 'Processing....' : 'Save'}
                        </Button>
                      </Card>
                    </Col>

                    <Col md="3"> </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          )}
        </Formik>
      </TransitionGroup>
    );
  }
}
export default withRouter(PackageCreate);
