/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';
import { Loader } from 'react-loaders';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import SweetAlert from 'sweetalert-react';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import weddingPackage from '../../../../api/weddingPackage';
import Create from './create';
import Edit from './edit';

function Venue() {
  const { id } = useParams();
  const hotelId = id;
  const columns = [
    { title: 'Venue Name', key: 'VenueName', isSortable: true },
    { title: 'Resort Name', key: 'ResortName', isSortable: true },
    { title: 'Status', key: 'isActive', isSortable: true },
    { title: 'Action' },
  ];

  const obj = [];

  const queryObject = {
    IsSortAsc: true,
    PageNumber: 1,
    PageSize: 10,
    SortBy: '',
    destinationId: 0,
    subDestinationId: 0,
    resortId: 0,
    Search: obj,
    ptidAdminHotelId: hotelId,
  };

  const [islistView, updateListView] = useState(true);
  const [venueId, setVenueId] = useState(0);
  const [resortId, setResortId] = useState(0);
  const [venuelist, setVenuelist] = useState([]);
  const [active, setActive] = useState(false);
  const [deleteVenueId, setDeleteVenueID] = useState([]);

  const [pagination, setPagination] = useState({
    IsSortAsc: true,
    totalItems: 0,
    totalPages: 0,
    SortBy: '',
  });
  const [statustext, setStatustext] = useState('');
  const [show, setShow] = useState(false);

  const searchSubmit = async () => {
    setActive(true);
    const result = await weddingPackage.venue.getVenue(queryObject);
    const veneueListforHotel = result.data.filter(
      (item) => item.ptidAdminHotelId === hotelId
    );
    setVenuelist(veneueListforHotel);
    if (veneueListforHotel.length) setResortId(veneueListforHotel[0].resortId);
    setActive(false);
    queryObject.PageNumber = result.pagination.currentPage;
    queryObject.PageSize = result.pagination.itemsPerPage;
    queryObject.totalItems = result.pagination.totalItems;

    setPagination({
      ...pagination,
      totalItems: result.pagination.totalItems,
      totalPages: result.pagination.totalPages,
      SortBy: queryObject.SortBy,
      IsSortAsc: queryObject.isSortAsc,
    });
  };

  useEffect(() => {
    const loadInitially = async () => {
      searchSubmit();
    };
    if (islistView) loadInitially();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [islistView]);

  const handlePageClick = async (data) => {
    const { selected } = data;
    queryObject.PageNumber = selected + 1;
    await searchSubmit();
  };
  
  const handleSearch = (e) => {
    queryObject.PageNumber = 1;
    const exist = obj.some((x) => x.columnSearchName === e.target.name);
    const length = [...e.target.value];

    if (!exist) {
      obj.push({
        columnSearchName: e.target.name,
        columnSearchValue: e.target.value,
      });
    } else {
      const index = obj.findIndex((p) => p.columnSearchName === e.target.name);
      obj[index].columnSearchValue = e.target.value;
    }

    if (length.length >= 3 || length.length === 0) {
      searchSubmit();
    }
  };

  const sortBy = (columnName) => {
    if (queryObject.sortBy === columnName) {
      queryObject.isSortAsc = !queryObject.isSortAsc;
    } else {
      queryObject.sortBy = columnName;
      queryObject.isSortAsc = true;
    }
    setPagination({
      ...pagination,
      sortByColumn: columnName,
      IsSortAsc: queryObject.isSortAsc,
    });
    searchSubmit();
  };

  const showConfirmationToDelete = (id) => {
    setDeleteVenueID(id);
    setStatustext('Are you sure to delete the venue?');
    setShow(true);
  };

  const deleteVenue = () => {
    weddingPackage.venue.deleteVenue(deleteVenueId).then(() => {
      toast.success('The venue deleted successfully.');
      setShow(false);
      searchSubmit();
    });
  };

  const updateStatus = (id) => {
    weddingPackage.venue.updateStatus(id).then((response) => {
      toast.success(
        response
      );
      searchSubmit();
    });
  }

  return (
    <div>
      {islistView ? (
        <>
          <SweetAlert
            show={ show }
            type="warning"
            title="Confirmation"
            text={ statustext }
            showCancelButton
            onConfirm={ () => {
              deleteVenue();
            } }
            onCancel={ () => {
              setShow(false);
            } }
            onClose={ () => {} } // eslint-disable-line no-console
          />

          <TransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear
            transitionAppearTimeout={ 0 }
            transitionEnter={ false }
            transitionLeave={ false }
          >
            <Container fluid>
              <LoadingOverlay
                tag="div"
                active={ active }
                styles={ {
                  overlay: (base) => ({
                    ...base,
                    background: '#fff',
                    opacity: 0.5,
                  }),
                } }
                spinner={ <Loader active /> }
              >
                <Card className="main-card mb-3">
                  <CardBody>
                    <CardTitle>Venue List</CardTitle>
                    <Button
                      className="mb-2 mr-2 btn-icon float-right"
                      color="primary"
                      onClick={ () => {
                        updateListView(false);
                        setVenueId(0);
                      } }
                    >
                      Add Venue
                    </Button>
                    <Table
                      size="md"
                      responsive
                      striped
                      bordered
                      hover
                      className="mb-3"
                    >
                      <thead>
                        <tr>
                          {columns.map((value, index) => {
                            if (value.isSortable) {
                              return (
                                <th
                                  key={ index }
                                  className={
                                    pagination.sortByColumn === value.key
                                    && pagination.IsSortAsc
                                      ? 'asc pointer'
                                      : pagination.sortByColumn === value.key
                                        && !pagination.IsSortAsc
                                        ? 'desc pointer'
                                        : 'pointer'
                                  }
                                  onClick={ () => sortBy(value.key) }
                                >
                                  {value.title}
                                </th>
                              );
                            } 
                            return <th key={ index }>{value.title}</th>;
                          })}
                        </tr>
                        <tr>
                          <th>
                            <input
                              type="text"
                              name="VenueName"
                              onKeyUp={ handleSearch }
                              className="searchtext form-control"
                            />{' '}
                          </th>
                          <th> </th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {venuelist.length > 0
                          && venuelist.map((value, index) => (
                            <tr key={ index }>
                              <td>{value.venueName}</td>
                              <td>{value.resortName}</td>
                              <td>
                                {!value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-danger">
                                  {' '}
                                  Disabled{' '}
                                </div>
                                )}
                                {value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-success">
                                  {' '}
                                  Enabled{' '}
                                </div>
                                )}
                              </td>
                              <td>
                                <UncontrolledButtonDropdown>
                                  <DropdownToggle
                                    caret
                                    className="mb-2 mr-2"
                                    color="primary"
                                  >
                                    Action
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                                    <DropdownItem
                                      onClick={ () => {
                                        updateListView(false);
                                        setVenueId(value.resortVenueID);
                                      } }
                                    >
                                      Manage
                                    </DropdownItem>

                                    <DropdownItem divider />

                                    <DropdownItem
                                      onClick={ () => showConfirmationToDelete(
                                        value.resortVenueID,
                                        true
                                      ) }
                                    >
                                      Delete
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                      onClick={ () => updateStatus(value.resortVenueID) }
                                    >
                                      {value.isActive ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledButtonDropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>

                    {venuelist.length > 0 && (
                      <nav aria-label="Page navigation">
                        <ReactPaginate
                          previousLabel="Previous"
                          nextLabel="Next"
                          breakLabel="..."
                          breakClassName="break-me"
                          pageCount={ pagination.totalPages }
                          marginPagesDisplayed={ 2 }
                          pageRangeDisplayed={ 5 }
                          onPageChange={ handlePageClick }
                          containerClassName="pagination justify-content-center"
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          nextClassName="page-item"
                          activeClassName="active"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                        />
                      </nav>
                    )}
                  </CardBody>
                </Card>
              </LoadingOverlay>
            </Container>
          </TransitionGroup>
        </>
      ) : venueId ? (
        <Edit
          venueId={ venueId }
          resortID={ resortId }
          updateListView={ updateListView }
        />
      ) : (
        <Create
          updateListView={ updateListView }
          resortID={ resortId }
          hotelId={ hotelId }
        />
      )}
    </div>
  );
}

export default Venue;
