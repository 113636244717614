import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
} from 'reactstrap';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TransitionGroup } from 'react-transition-group';
import { TextField } from 'common/Form/TextInput';
import { TextArea } from 'common/Form/TextArea';
import weddingPackage from 'api/weddingPackage';

function Create({ updateListView, resortID, hotelId }) {
  const validationSchema = Yup.object({
    // destinationID: Yup.string().required('The destination is required'),
    // subDestinationID: Yup.string().required('The sub destination is required'),
    // resortID: Yup.string().required('Resort is required'),
    venueName: Yup.string().required('The venue name is required.'),
    capacity: Yup.string().required('Capacity is required.'),
  });

  const [initialValues, setInitialValues] = useState({
    venueName: '',
    destinationID: 0,
    subDestinationID: 0,
    resortID,
    capacity: 1,
    allowReligiousCeremony: false,
    ptidAdminHotelId: hotelId,
  });

  const saveIntegration = async (value) => {
    await weddingPackage.venue.saveVenue(value).then((response) => {
      if (typeof response === 'string') toast.error(response);
      else {
        toast.success('Venue saved successfully.');
        updateListView(true);
      }
    });
  };

  const getResort = useCallback(async () => {
    if (resortID) {
      const resorts = await weddingPackage.resort.getResortByResortId(resortID);
      const resort = resorts.find((x) => x.resortID === parseInt(resortID, 10));
      // loadSubDestination(resort.destinationID);
      setInitialValues({
        ...initialValues,
        destinationID: resort.destinationID,
        subDestinationID: resort.subDestinationID,
      });
    }
  }, [resortID, initialValues]);

  const [cocktailChecked, setCocktailChecked] = useState(false);
  const [ceremonyChecked, setCeremonyChecked] = useState(false);
  const [receptionChecked, setReceptionChecked] = useState(false);

  useEffect(() => {
    const setResorts = async () => {
      await getResort();
    };
    setResorts();
  }, [getResort]);

  return (
    <TransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={ 0 }
      transitionEnter={ false }
      transitionLeave={ false }
    >
      <Container fluid />
      <Formik
        initialValues={ initialValues }
        validationSchema={ validationSchema }
        enableReinitialize
        onSubmit={ async (values) => {
          saveIntegration(values);
        } }
      >
        {({ isSubmitting }) => (
          <Card>
            <CardBody>
              <Button
                className="mb-2 mr-2 btn-icon float-right"
                color="primary"
                onClick={ () => {
                  updateListView(true);
                } }
              >
                Back To List
              </Button>

              <Form autoComplete="off">
                <Row>
                  <Col md="3"> </Col>
                  <Col md="6">
                    <Card>
                      <CardHeader>Create Venue</CardHeader>

                      <CardBody>
                        <FormGroup>
                          <TextField label="Venue Name" name="venueName" />
                        </FormGroup>

                        <FormGroup>
                          <TextArea label="Description" name="description" />
                        </FormGroup>

                        <FormGroup>
                          <TextField label="Premium Venue Cost" name="premiumVenueCost" />
                        </FormGroup>

                        <FormGroup>
                          <Row>
                            <Col md={ 12 }>
                              <Field
                                type="checkbox"
                                name="allowReligiousCeremony"
                              />
                              &nbsp;&nbsp;
                              <label htmlFor="allowReligiousCeremony">
                                Allow Religious Ceremony
                              </label>
                            </Col>
                          </Row>
                        </FormGroup>

                        <FormGroup>
                          <Row>
                            <Col md={ 6 }>
                              <label>Available Events</label>
                            </Col>
                          </Row>
                        </FormGroup>

                        <FormGroup>
                          <Row>
                            <Col md={ 12 }>
                              <Field
                                type="checkbox"
                                name="ceremony"
                                onClick={ () => {
                                  setCeremonyChecked(!ceremonyChecked);
                                } }
                              />
                              &nbsp;&nbsp;
                              <label htmlFor="ceremony">Ceremony</label>
                            </Col>
                          </Row>
                        </FormGroup>

                        <FormGroup>
                          <Row>
                            <Col md={ 12 }>
                              <Field
                                type="checkbox"
                                name="cocktail"
                                onClick={ () => {
                                  setCocktailChecked(!cocktailChecked);
                                } }
                              />
                              &nbsp;&nbsp;
                              <label htmlFor="cocktail">Cocktail</label>
                            </Col>
                          </Row>
                        </FormGroup>

                        <FormGroup>
                          <Row>
                            <Col md={ 12 }>
                              <Field
                                type="checkbox"
                                name="reception"
                                onClick={ () => {
                                  setReceptionChecked(!receptionChecked);
                                } }
                              />
                              &nbsp;&nbsp;
                              <label htmlFor="reception">Reception</label>
                            </Col>
                          </Row>
                        </FormGroup>

                        <FormGroup>
                          <Row>
                            <Col md={ 12 }>
                              <TextField
                                type="number"
                                min="1"
                                label="Capacity"
                                name="capacity"
                              />
                            </Col>
                          </Row>
                        </FormGroup>

                        <FormGroup>
                          <Row>
                            {ceremonyChecked && (
                              <Col md={ 12 }>
                                <TextField
                                  type="number"
                                  min="1"
                                  label="Ceremony Capacity"
                                  name="ceremonyCapacity"
                                />
                              </Col>
                            )}
                          </Row>
                        </FormGroup>

                        <FormGroup>
                          <Row>
                            {cocktailChecked && (
                              <Col md={ 12 }>
                                <TextField
                                  type="number"
                                  min="1"
                                  label="Cocktail Capacity"
                                  name="cocktailCapacity"
                                />
                              </Col>
                            )}
                          </Row>
                        </FormGroup>

                        <FormGroup>
                          <Row>
                            {receptionChecked && (
                              <Col md={ 12 }>
                                <TextField
                                  type="number"
                                  min="1"
                                  label="Reception Capacity"
                                  name="receptionCapacity"
                                />
                              </Col>
                            )}
                          </Row>
                        </FormGroup>
                      </CardBody>
                      <Button
                        disabled={ isSubmitting }
                        type="submit"
                        color="primary"
                        className="mt-1"
                      >
                        {isSubmitting ? 'Processing....' : 'Save'}
                      </Button>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        )}
      </Formik>
    </TransitionGroup>
  );
}

Create.defaultProps = {
  resortId: 0,
  updateListView: () => null,
};

Create.propTypes = {
  resortId: PropTypes.number,
  updateListView: PropTypes.func,
  hotelId: PropTypes.string.isRequired,
};

export default Create;
