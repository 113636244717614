import React, { useState, useEffect } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { IoIosRemove, IoIosSave, IoIosEye } from 'react-icons/io';

import salesLeadApi from 'api/salesLead';
import { destifyAdminApiRequests } from 'api/common';

import { useDebounce } from 'hooks';
import AsyncSelect from 'react-select/async';

const dropdownStyles = {
  container: (baseStyles, state) => ({
    ...baseStyles,
    display: 'inline-block',
    width: '65%',
    marginRight: '0.5rem',
    maxWidth: '300px',
    minWidth: '200px',
  }),
};

interface SelectlistItem {
  label: string;
  value: string;
}

const _RecommendResort = ({
  userId,
  initialValue,
  removeResortRecommendation,
  saveResortRecommendation,
  upsellRecordItemId,
  id,
}) => {
  const [currentDropDownItems, setCurrentDropDownItems] = useState<
    SelectlistItem[]
  >([]);
  const [val, setVal] = useState(null);
  const [resortPriceData, setResortPriceData] = useState(null);
  const [isError, setIsError] = useState(false);
  const [loadingResortPrice, setLoadingResortPrice] = useState(false);

  useEffect(() => {
    if (initialValue) setVal(initialValue);
  }, [initialValue]);

  const fetchHotelList = async search => {
    const queryObject = {
      IsSortAsc: true,
      PageNumber: 1,
      PageSize: 10,
      SortBy: 'HotelFriendlyName',
      hotelChainID: 0,
      HotelCountryId: 0,
      IsEnabled: true,
      HotelSearch: [
        {
          columnSearchName: 'HotelFriendlyName',
          columnSearchValue: search,
        },
      ],
    };
    return await destifyAdminApiRequests.postPagination(
      'Hotel/HotelContentList',
      queryObject,
    );
  };

  const [filterDropdown] = useDebounce(async search => {
    const { data } = await fetchHotelList(search);
    const results = data.map(r => ({
      label: r.hotelFriendlyName,
      value: r.hotelID,
    }));
    setCurrentDropDownItems(results);
    return results;
  });

  const getResortByHotel = async id => {
    return await salesLeadApi.salesLeadDetails.resortByHotelId(id);
  };
  const getResortRoomPrice = async hotelId => {
    setIsError(false);
    setResortPriceData(null);
    setLoadingResortPrice(true);
    try {
      const resortResponse = await getResortByHotel(hotelId);
      if (!resortResponse.isSuccess) {
        setIsError(true);
        throw new Error();
      }
      const resortId = resortResponse.data.resortId;
      const response = await salesLeadApi.salesLeadDetails.resortRoomPrice(
        resortId,
        userId,
      );
      if (response.isSuccess) {
        setResortPriceData(response.data);
      } else {
        setIsError(true);
        throw new Error();
      }
    } catch (error) {
      console.error(
        'Unable to load resort room price. Please try again or contact the dev team.',
      );
    } finally {
      setLoadingResortPrice(false);
    }
  };
  return (
    <>
      <div className="list-details flex-wrap py-2 align-items-center">
        <div>Resort {id}</div>
        <div className="list-details-value" style={{ width: '65%', flex: 1 }}>
          <AsyncSelect
            styles={dropdownStyles}
            loadOptions={filterDropdown}
            placeholder={`Type to load and search for resorts...`}
            isClearable
            defaultOptions={currentDropDownItems}
            value={val}
            onChange={newValue => {
              setVal(newValue);
            }}
          />
          {val ? (
            <>
              <Button
                className="p-1"
                size="sm"
                onClick={() => {
                  saveResortRecommendation(val.value);
                }}
                id={`save-RecommendedResorts-tooltip-${val.value}`}
                disabled={!val.value}
                color="success"
              >
                <IoIosSave color="#ffffff" fontSize="20px" />
              </Button>
              <UncontrolledTooltip
                placement="bottom"
                target={`save-RecommendedResorts-tooltip-${val.value}`}
              >
                Save Resort Recommendation
              </UncontrolledTooltip>
              {upsellRecordItemId && (
                <>
                  <Button
                    className="p-1 ml-1"
                    size="sm"
                    id={`delete-RecommendedResorts-tooltip-${val.value}`}
                    disabled={!val.value}
                    color="danger"
                    onClick={() => {
                      removeResortRecommendation(upsellRecordItemId);
                    }}
                  >
                    <IoIosRemove color="#ffffff" fontSize="20px" />
                  </Button>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`delete-RecommendedResorts-tooltip-${val.value}`}
                  >
                    Remove Resort Recommendation
                  </UncontrolledTooltip>
                </>
              )}
              {
                isError ? (
                  <div style={{color:"red"}} className='ml-2 d-inline'>Price: N/A</div>
                ) : resortPriceData !== null ? (
                  <div style={{color:"red"}} className='ml-2 d-inline'>
                    Price:{' '}
                      <span className="text-bold">
                        ${resortPriceData.toFixed(2)}
                      </span>
                  </div>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        !loadingResortPrice && getResortRoomPrice(val.value);
                      }}
                      className="p-1 ml-1"
                      size="sm"
                      disabled={loadingResortPrice}
                      id={`view-RecommendedResorts-price-tooltip-${val.value}`}
                    >
                      <IoIosEye color="#ffffff" fontSize={'20px'} />
                    </Button>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={`view-RecommendedResorts-price-tooltip-${val.value}`}
                    >
                      View Resort Price
                    </UncontrolledTooltip>
                  </>
                )
              }
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default _RecommendResort;
