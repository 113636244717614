/* eslint-disable quotes */
/* eslint-disable import/no-unresolved */
import { createSlice } from "@reduxjs/toolkit";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { AuthState } from "store/slice/types";
import { authSaga } from "./saga";

export const initialAuthState: AuthState = {
  user: null,
  isLoadingUser: false,
  isLoggedIn: false,
};
const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setUser(state, action) {
      state.isLoadingUser = true;
      state.user = action.payload;
    },
    setIsLoggedUser(state, action) {
      state.isLoadingUser = action.payload;
    },
    signInRedirect() {},
    userSignedOut(state) {
      state.isLoadingUser = false;
      state.user = null;
    },
    userExpired(state) {
      state.isLoadingUser = false;
      state.user = null;
    },
    afterSignIn(state) {
      state.isLoggedIn = true;
    },
  },
});

export const { actions } = authSlice;

export default authSlice.reducer;

export const useAuthSlice = () => {
  useInjectReducer({ key: authSlice.name, reducer: authSlice.reducer });
  useInjectSaga({ key: authSlice.name, saga: authSaga });
  return { actions: authSlice.actions };
};
