import { useCallback, useRef } from "react";

export const getControlledNumber = (
    number: number,
    min: number,
    max: number
  ) => {
    return Math.min(Math.max(min, number), max);
  };

const useDebounce = (listener: (...args: any[]) => void, delay?: number) => {
  const _delay = getControlledNumber(delay || 1, 1, 5);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedListener = useCallback(
    (...args: any[]) => {
      clearTimeout(timerRef.current as NodeJS.Timeout);
      return new Promise((res,rej) => {
        timerRef.current = setTimeout(() => {
          try {
            const data = listener(...args);
            res(data);
          }catch(err) {
            rej(err);
          }
        }, _delay * 1000);
      });
    },
    [listener,_delay]
  );

  const cancel = () => clearTimeout(timerRef.current);

  return [debouncedListener,cancel] as const;
};

export default useDebounce;
