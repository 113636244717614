import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './style.module.scss';

type ActionType = {
  onClick: () => void;
  type: 'action';
} & CommonProps;

type NavigationType = {
  url: string;
  type: 'url';
} & CommonProps;

type CollectionType = {
  items: Array<ActionType | NavigationType | CollectionType>;
  type: 'collection';
  width?: string;
} & Omit<CommonProps, 'icon'>;

type CommonProps = {
  label: string;
  description?: string;
  icon?: React.ReactNode | string;
};

interface ContextMenuProps {
  items: Array<ActionType | NavigationType | CollectionType>;
  show: boolean;
  width?: string;
  align?: 'left' | 'right';
}

const ContextMenu = ({
  items,
  show,
  align = 'right',
  width,
}: ContextMenuProps) => {
  const [showNestedItems, setShowNestedItems] = useState(false);
  if (!items.length) return null;
  return (
    <div
      style={{
        right: align === 'right' ? '50%' : 'unset',
        left: align === 'left' ? '50%' : 'unset',
        transform: `scale(${show ? 1 : 0})`,
        opacity: show ? 1 : 0,
        maxWidth: width ?? '150px',
      }}
      className={`position-absolute ${styles['context-menu-default']}`}
    >
      <ul className="list-unstyled m-0 p-0">
        {items.map((item, i) => {
          const ImageIcon =
            item.type !== 'collection' ? (
              <span className="d-inline-block pr-4">
                {typeof item.icon === 'string' ? (
                  <img
                    style={{ width: '1em' }}
                    alt={item.description}
                    src={item.icon}
                  />
                ) : (
                  <>{item.icon}</>
                )}
              </span>
            ) : null;
          return (
            <li key={i} className={`${styles['context-menu-option-item']}`}>
              {item.type === 'url' ? (
                <div className="d-flex align-items-center">
                  <div style={{width: '1.5em'}}>{item.icon ? ImageIcon : <>&nbsp;</>}</div>
                  <Link
                    className={`text-reset text-decoration-none pr-5 text-left flex-grow-1 px-2 py-2 ml-2 text-nowrap overflow-hidden ${styles['context-menu-link-item']}`}
                    style={{ textOverflow: 'ellipsis' }}
                    to={item.url}
                  >
                    {item.label}
                  </Link>
                </div>
              ) : item.type === 'action' ? (
                <div
                  onClick={item.onClick}
                  className="d-flex align-items-center px-2 py-2"
                >
                  <div style={{width: '1.5em'}}>{item.icon ? ImageIcon : <>&nbsp;</>}</div>
                  <span
                    style={{ textOverflow: 'ellipsis' }}
                    className="text-left pr-5 text-left flex-grow-1 ml-2 text-nowrap overflow-hidden"
                  >
                    {item.label}
                  </span>
                </div>
              ) : item.items.length > 0 ? (
                <div
                  onMouseLeave={() => setShowNestedItems(false)}
                  onMouseEnter={() => setShowNestedItems(true)}
                  className="position-relative d-flex align-items-center px-2 py-2"
                >
                  <div
                  style={{width: '1.5em'}}>
                  <FontAwesomeIcon
                    icon={align === 'left' ? faChevronRight : faChevronLeft}
                  />
                  </div>
                  <span
                    style={{ textOverflow: 'ellipsis' }}
                    className="pr-5 ml-2 text-nowrap overflow-hidden"
                  >
                    {item.label}
                  </span>
                  <div
                    className="position-absolute"
                    style={{
                      top: '0%',
                      right: align === 'left' ? '0%' : 'unset',
                      left: align === 'right' ? '0%' : 'unset',
                    }}
                  >
                    <ContextMenu
                      items={item.items}
                      show={showNestedItems}
                      align={align}
                      width={item.width}
                    />
                  </div>
                </div>
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ContextMenu;
