import React from 'react';

import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container } from 'reactstrap';

import {
  toast,
} from 'react-toastify';

import * as Yup from 'yup';

import { Formik, Form } from 'formik';

import { withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { TextField } from '../../../common/Form/TextInput';
import { TextArea } from '../../../common/Form/TextArea';
import { SelectInput } from '../../../common/Form/SelectInput';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import weddingPackage from '../../../api/weddingPackage';

class CreateSubDestination extends React.Component {
  validationSchema = Yup.object({
    airportCode: Yup.string()
      .min(3, 'Airport Code must be 3 digits!')
      .max(3, 'Airport Code must be 3 digits!'),
    subDestinationName: Yup.string().required('Sub Destinations Name is required!'),
    destinationId: Yup.string().required('Desination is required!')
  });

  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        subDestinationName: '',
        destinationId: '',
        description: '',
        distanceFromAirport: '',
        airportCode: ''
      },
      destination: [],
    }
  }

  componentDidMount() {
    this.loadDestination();
  }

  async loadDestination() {
    const destination = await weddingPackage.destination.getDestination();
    this.setState({ destination });
  }

  render() {
    const { initialValues } = this.state;
    return (
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }
      >
        <Container fluid>
          <div>
            <PageTitle heading="City/Sub-destination Management" icon="lnr-apartment icon-gradient bg-tempting-azure" />
          </div>
        </Container>
        <Formik
          initialValues={ initialValues }
          validationSchema={ this.validationSchema }
          enableReinitialize
          onSubmit={ async (values) => {
            await weddingPackage.subDestination.saveSubDestination(values);
            this.props.history.push('/wedding/subdestination');
            toast.success('City/Sub-destination saved successfully.');
          } }
        >

          {({ isSubmitting }) => (
            <Card>
              <CardBody>
                <Button className="mb-2 mr-2 btn-icon float-right" color="primary" onClick={ () => { this.props.history.push('/wedding/subdestination') } }>
                  Back To List
                </Button>
                <Form autoComplete="off">
                  <Row>
                    <Col md="3"> </Col>
                    <Col md="6">
                      <Card>
                        <CardHeader>Create city/sub destination</CardHeader>

                        <CardBody>
                                                        
                          <FormGroup>
                            <TextField label="City/Sub Destination Name" name="subDestinationName" />
                          </FormGroup>

                          {/* <FormGroup>
                            <label> Destination </label>
                            <Field
                              name="destinationId"
                              className={ errors.destinationID && touched.destinationID ? 'is-invalid form-control' : 'form-control' }
                              as="select"
                              onChange={ (e, selected) => setFieldValue('destinationId', selected.value) }
                            >
                              <option value="">--Select--</option>
                              {this.state.destination.map((destination) => <option key={ destination.value } value={ destination.value }>{destination.text}</option>)}
                            </Field>

                            {errors.destinationID && touched.destinationID ? (
                              <div className="invalid-validation">{errors.destinationID}</div>
                            ) : null}

                            <div className="invalid-validation">Error</div>

                            {touched.destinationID && errors.destinationID
                              ? errors.destinationID
                              : null}

                          </FormGroup> */}

                          <FormGroup>
                            <SelectInput
                              label="Destination"
                              name="destinationId"
                              options={ this.state.destination }
                            />
                          </FormGroup>
                                                
                          <FormGroup>
                            <TextField label="Distance from airport" name="distanceFromAirport" />
                          </FormGroup>

                          <FormGroup>
                            <TextField label="Airport Code" name="airportCode" />
                          </FormGroup>
                                                        
                          <FormGroup>
                            <TextArea label="Description" name="description" />
                          </FormGroup>

                        </CardBody>
                        <Button
                          disabled={ isSubmitting }
                          type="submit"
                          color="primary"
                          className="mt-1"
                        >

                          {isSubmitting ? 'Processing....' : 'Save'}

                        </Button>
                      </Card>
                    </Col>

                  </Row>

                </Form>
              </CardBody>
            </Card>
          )}
        </Formik>

      </TransitionGroup>
    )
  }
}
export default withRouter(CreateSubDestination);
