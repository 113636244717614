import React from 'react';
import { Card, Row, Col } from 'reactstrap';
import CountUp from "react-countup";

interface TotalAccountsProps {
  totalSalesLeadAccounts: number,
  totalGroupLeaderAccounts: number,
  totalGuestRoleAccounts: number,
  totalAccounts?: number;
}


export const ClientAccountsHeaderTileSummaryCard = ({
  totalSalesLeadAccounts = 0,
  totalGroupLeaderAccounts = 0,
  totalGuestRoleAccounts = 0,
  ...props
}: TotalAccountsProps): JSX.Element => {

    let totalAccounts = props.totalAccounts;
    if (!totalAccounts) {
      totalAccounts = totalSalesLeadAccounts + totalGroupLeaderAccounts + totalGuestRoleAccounts;
    }

    const accountsArray =  Array.from([ 
      {
        title: 'New Accounts',
        total: totalAccounts,
        color: 'primary',
   
      },
      {
        title: 'Total Guests',
        total: totalSalesLeadAccounts,
        color: 'warning',
       
      },
      {
        title: "Total Group Leaders",
        total: totalGroupLeaderAccounts,
        color: 'danger',
      
      },
      {
        title: "Total Sales Leads",
        total: totalGuestRoleAccounts,
        color: 'success',
       
      }
  ]);

    return (
      <Row>
      {accountsArray.map((account, idx) => {
        return (
        <Col md="6" xl="3" key={'title-card-'+idx}>
            <div className={`card mb-3 widget-content bg-${account.color}`} style={{ borderRadius: '12px'}}>
              <div className="widget-content-wrapper text-white">
                <div className="widget-content-left">
                  <div className="widget-heading fsize-2">{account.title}</div>
                </div>
                <div className="widget-content-right">
                  <div className="widget-numbers text-white fsize-2">
                    <CountUp end={account.total} duration={1} />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )
      })}
   </Row>
   
    )
  }