import React, {useState} from 'react'
import { Button } from 'reactstrap';

import { UpdateForm } from "../../"
import {FormFields} from "../../../../slice/types"

const ModifyButton = ({ data }: { data: FormFields }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <>
        <UpdateForm
          formId={`updateForm-${data.Id}`}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          // TODO: handle the form submission here
          submitHandler={(values) => console.log(`logging from ${data.Id}`,values)}
          initialValues={data}
        />
        <Button className="rounded-pill" onClick={() => setIsOpen(true)}>
          Modify
        </Button>
      </>
    );
  };

export default ModifyButton