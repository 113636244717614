import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { staticPricingIngestionLogSaga } from './saga';
import { IngestionLogsListState, IngestionLogs } from './types';
export const initialState: IngestionLogsListState = {
  loading: false,
  ingestionLogsList: [],
};

const slice = createSlice({
  name: 'staticPricingIngestionLogRoot',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    loadIngestionLogs(state) {
      state.loading = true;
    },
    setIngestionLogsList(state, action) {
      state.ingestionLogsList = action.payload;
      state.loading = false;
    },
  },
});
export const { actions: IngestionLogsActions } = slice;
export const useIngestionLogSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: staticPricingIngestionLogSaga });
  return { actions: slice.actions };
};
