/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable quotes */
import { createSlice } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from '../../utils/redux-injectors';
import { commonSaga } from './saga';
import { CommonState } from './types';

export const initialCommonState: CommonState = {
  loading: true,
  env: process.env,
  error: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState: initialCommonState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { actions } = commonSlice;

export default commonSlice.reducer;

export const useCommonSlice = () => {
  useInjectReducer({ key: commonSlice.name, reducer: commonSlice.reducer });
  useInjectSaga({ key: commonSlice.name, saga: commonSaga });
  return { actions: commonSlice.actions };
};
