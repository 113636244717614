import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from 'types/RootState';

const selectSlice = (state: RootState) => state.customerTransferReservations || initialState;
export const selectCustomerTransferReservations = createSelector(
    [selectSlice],
    state => state.customerTransferReservations);

export const selectLoading =  createSelector(
    [selectSlice], 
    state=> state.loading);

    export const selectCustomerTransferReservationsByDestifyUserId = createSelector(
        [selectSlice],
        state => state.customerTransferReservationsByDestifyUserId);