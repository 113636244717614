import React from 'react';
import {
  Card,
  CardBody,
} from 'reactstrap';

import 'react-image-lightbox/style.css'; 
import Details from './details';
import hotel from '../../../../api/hotelContent';

class CreateRoomType extends React.Component {
  constructor(props) {
    super(props);
       
    this.state = {
      hotelName: '',
      hotelId: '',
    };
  }

    backToRoomType = () => {
      const route = `/hotels/hotel-content-manage/${this.state.hotelId}`;
      this.props.history.push({
        pathname: route,
        state: { 
          from: 'roomdetails',
        }
      })
      // this.props.history.push("/hotels/hotel-content-manage/"+ this.state.hotelId);
    }

    componentDidMount() {
      const { id } = this.props.match.params;
      this.setState({ hotelId: id });
      hotel.hotelcontent.gethotelbyId(id).then((response) => {
        this.setState({ hotelName: response.hotelFriendlyName })
      })
    }

    render() {
      return (

        <div className="app-inner-layout">
          <div className="app-inner-layout__header-boxed p-0">
            <div className="app-inner-layout__header page-title-icon-rounded text-white bg-midnight-bloom mb-4">
              <div className="app-page-title">
                <div className="page-title-wrapper">
                  <div className="page-title-heading">
                    <div className="page-title-icon">
                      <i className="lnr-apartment icon-gradient bg-sunny-morning " />
                    </div>
                    <div className="h2"> {this.state.hotelName}
                      <div className="page-title-subheading">
                        Hotel Content Management
                      </div>
                    </div>
                  </div>
                  <div className="page-title-actions" />
                </div>
              </div>
            </div>
          </div>
                  
          <button type="button" className="mb-2 mr-2 btn-pill btn btn-outline-primary" onClick={ () => this.backToRoomType() }>Back to Room Type</button>

          <Card className="main-card mt-3 mb-3">         
            <CardBody>
              <Details hotelId={ this.props.match.params.id } />
            </CardBody>
          </Card>
        </div>

      );
    }
}

export default CreateRoomType;
