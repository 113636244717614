import { put, takeLatest } from 'redux-saga/effects';
import usersApi from 'api/user';
import crm from 'api/crm';
import { UsersActions as actions } from './index';
import { toast } from 'react-toastify';
import {
  ClientAccountDetailResponse,
  IUserCommunicationPrefsLookup,
} from './types';
import { PayloadAction } from '@reduxjs/toolkit';

function* loadClientAccountDetails(action) {
  let id = '';
  let e = '';
  try {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      ...rest
    }: ClientAccountDetailResponse =
      yield usersApi.guestUsers.getClientAccountDetails(action.payload);
    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      address: [rest.state, rest.city, rest.country].filter(Boolean).join(','),
    };
    yield put(actions.setClientAccountDetails(data));
    id = rest.id;
    e = email;
  } catch (error) {
    yield put(actions.setError('unable to fetch user details!'));
  }
  try {
    yield put(actions.loadUserCommunicationDetails([id]));
  } catch (error) {
    toast.warn(
      `Unable to get communication preferences for '${e}' due to a failed lookup. Setting to defaults.`,
    );
  }
}

function* userCommunicationPrepLookups(action) {
  try {
    const response: IUserCommunicationPrefsLookup[] =
      yield usersApi.destifyLegacyDashboardAccess.post(
        'api/UserGroup/GetCommunicationsForUsers',
        [...action.payload],
      );
    if (response.length) {
      const { communicationLevel: level, communicationStyle: style } = response
        .slice(0, 1)
        .pop();
      yield put(actions.setUserCommunicationDetails({ level, style }));
    }
  } catch (error) {
    toast.error('errror');
  }
}

function* updateUserDetails(action) {
  yield actions.setLoading(true);
  try {
    const { id, values } = action.payload;
    const accountDetails = yield crm.guests.updateUserDetails(id, values);
    if (accountDetails.success === true) {
      toast.success('User Details updated successfully.');
    } else {
      throw new Error();
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield actions.setLoading(false);
  }
}

function* loadGuestRoomDetail(action) {
  const userId = action.payload.id;
  try {
    const userRoomDetails = yield usersApi.guestUsers.getGuestRoomDetail({
      userId,
    });
    yield put(actions.setUserRoomDetails({list:userRoomDetails,loading:false}));
  } catch (e) {
    toast.error(
      `Unexpected error while attempting to get room details. Please try again or contact the dev team.`,
    );
    yield put(actions.setUserRoomDetails({list:[],loading:false}));
  }
}

function* assignUserToRoom(action) {
  try {
    const {userId,...params} = action.payload;
    const response = yield usersApi.guestUsers.assignUserToRoom(params);
    if (response.isSuccess) {
      toast.success(response.value);
      yield put(actions.getUserRoomDetails({id:userId}))
    } else {
      toast.error(
        response.error ||
          `Unable to assign room '${params.roomId}' to user '${params.userEmail}', due to an error. Please try again or contact the dev team.`,
      );
    }
  } catch (error) {
        toast.error(
      'Unable to save specified room changes to user record, due to an error. Please try again or contact the dev team.',
    );
  }
}

function* disassociateRoomFromUser(action) {
  const { userId, crmRoomId, userEmail } = action.payload;
  try {
    const payload = {
      // groupId: '',
      userEmail,
      roomId: crmRoomId,
      roomRole: '',
    };

    const result = yield usersApi.guestUsers.unassignUserFromRoom(payload);
    if (result.isSuccess) {
      toast.success(
        `User: '${userEmail}' has been disassociated from Crm Room: '${crmRoomId}'`,
      );
    } else {
      toast.error(
        `Unexpected error while attempting to disassociate User: '${userEmail}' from Crm Room: '${crmRoomId}'. Please try again or contact the dev team.`,
      );
    }
  } catch (e) {
    toast.error(
      `Unexpected error while attempting to disassociate User: '${userEmail}' from Crm Room: '${crmRoomId}'. Please try again or contact the dev team.`,
    );
  } finally {
    //refresh user's room associations, regardless of outcome
    yield put(actions.getUserRoomDetails({id:userId}));
  }
}


function* loadUserGroups(action: PayloadAction<{ id: string }>) {
  const { id } = action.payload;

  try {
    const userGroups = yield usersApi.guestUsers.getUserGroups(id);
    yield put(actions.setUserGroups(userGroups));
  } catch (e) {
    console.error('Error fetching user groups', e);
    toast.error(
      `Unexpected error while attempting to get user groups. Please try again or contact the dev team.`,
    );
    yield put(actions.setUserGroups([]));
  }
}

function* makeGroupLeader(
  action: PayloadAction<{ userId: string; groupId: string }>,
) {
  const { userId, groupId } = action.payload;

  try {
    const userGroups = yield usersApi.guestUsers.setGroupLeader(userId, groupId);
    yield put(actions.setUserGroups(userGroups));
    toast.success(`User has been successfully set as the leader of the group.`);
  } catch (e) {
    console.error('Error setting group leader', e);
    toast.error(
      `Unexpected error while attempting to set new group leader. Please try again or contact the dev team.`,
    );
    yield put(actions.setUserGroups([]));
  }
}

export function* usersSaga() {
  yield takeLatest(actions.loadClientAccountDetails, loadClientAccountDetails);
  yield takeLatest(
    actions.loadUserCommunicationDetails,
    userCommunicationPrepLookups,
  );
  yield takeLatest(actions.updateUserDetails, updateUserDetails);
  yield takeLatest(actions.getUserRoomDetails, loadGuestRoomDetail);
  yield takeLatest(actions.disassociateRoomFromUser, disassociateRoomFromUser);
  yield takeLatest(actions.assignUserToRoom, assignUserToRoom);
  yield takeLatest(actions.loadUserGroups, loadUserGroups);
  yield takeLatest(actions.makeGroupLeader, makeGroupLeader);
}
