/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Container,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import {
  ToastContainer,
  toast,
} from 'react-toastify';

import classnames from 'classnames';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';

import arrayChunk from 'lodash.chunk';

import { TransitionGroup } from 'react-transition-group';
import _ from 'lodash';
import { TextField } from '../../../common/Form/TextInput';
import { TextArea } from '../../../common/Form/TextArea';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import weddingPackage from '../../../api/weddingPackage';
import PhotoWidgetDropzone from '../../../common/imageUploader/photoWidgetDropzone';

class Edit extends React.Component {
  static getDefaultState() {
    return {
      ...ToastContainer.defaultProps,
      transition: 'bounce',
      type: 'success',
      disableAutoClose: false,
    };
  }

  validationSchema = Yup.object({
    destinationID: Yup.string().required('The destination is required'),
    subDestinationID: Yup.string().required('The sub destination is required'),
    resortID: Yup.string().required('Resort is required'),
    venueName: Yup.string().required('The venue name is required.'),
    capacity: Yup.string().required('Capacity is required.'),
  });

  constructor(props) {
    super(props);

    this.state = {
      initialValues: {
        venueName: '',
        destinationID: '',
        subDestinationID: '',
        resortID: '',
        capacity: 1,
        allowReligiousCeremony: false,
      },
      destination: [],
      subDestination: [],
      resort: [],
      activeTab: '1',
      venueId: 0,
      images: [],
      chunkedData: [],
      showImageUpload: false,
      show: false,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  //   async saveIntegration(value) {
  //     await weddingPackage.venue.saveVenue(value);
  //   }

  async loadDestination() {
    const destination = await weddingPackage.destination.getDestination();
    this.setState({ destination });
  }

  async loadSubDestination(id) {
    const subDestination = await weddingPackage.subDestination.getsubdestinationByDestinationId(id);
    this.setState({ subDestination });
  }

  async resort(id) {
    const resort = await weddingPackage.resort.getResortBySubDestinationId(id);
    this.setState({ resort });
  }

  async getResort() {
    const resort = await weddingPackage.resort.getResortList();
    this.setState({ resort });
  }

  async getImages() {
    const images = await weddingPackage.venue.getImages(
      this.props.match.params.id
    );
    this.setState({ images });
    this.setState({ chunkedData: arrayChunk(images, 3) });
  }

  componentDidMount() {
    this.loadDestination();
    this.getResort();
    const { id } = this.props.match.params;

    if (id && id > 0) {
      this.setState({ venueId: id });
      this.getImages(id);
      weddingPackage.venue.getVenueById(id).then((res) => {
        this.loadSubDestination(res.destinationID);
        // this.resort(res.subDestinationID);

        if (res.capacity === null) res.capacity = 1;
        if (res.allowReligiousCeremony === null) { res.allowReligiousCeremony = false; }
        this.setState({ initialValues: res });
      });
    }
  }

  async addImage(param) {
    await weddingPackage.venue.uploadPhoto(
      param.Id,
      param.imageName,
      param.altTagText,
      param.blob
    );
    toast.success('Image saved successfully for the venue.');
    // param.this.setState({isSubmit:false});
    // param.that.setState({ showImageUpload:false});
    await param.that.getImages();
  }

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    
    this.setState((pS) => {
      const list = [...pS.images];
      list[index][name] = value;
      return { images: list, chunkedData: arrayChunk(list, 3) };
    });
  };

  updateImage = (index) => {
    const image = this.state.images[index];
    const exceptList = _.reject(this.state.images, (o) => o.imageContentID === image.imageContentID);
    const isOrderExist = exceptList.some((d) => d.imageOrder === image.imageOrder);

    if (isOrderExist) {
      toast.error('Image display order already exists.');
      return;
    }

    weddingPackage.venue.updateImageDetails(image).then(() => {
      // let arr = this.state.images;
      // arr[index] = image.imageURL;
      // this.setState({ images: arr })
      toast.success('The details of venue image updated successfully.');
    });
  };

  showConfirmationToDelete = (id) => {
    this.deleteVenueId = id;
    this.setState({ show: true });
  };

  deleteImage = () => {
    weddingPackage.venue
      .deleteImages({ imageContentID: this.deleteVenueId })
      .then(() => {
        toast.success('Image deleted succesfuly.');
        this.getImages();
      });
  };

  onchangeResort(id) {
    const resort = this.state.resort.find((x) => x.resortID === parseInt(id, 10));
    this.loadSubDestination(resort.destinationID);
    const res = {
      destinationID: resort.destinationID,
      subDestinationID: resort.subDestinationID,
    };
    this.setState({ initialValues: res });
  }

  render() {
    const { initialValues } = this.state;

    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text="Are you sure to delete this image?"
          showCancelButton
          onConfirm={ () => {
            this.deleteImage();
            this.setState({ show: false });
          } }
          onCancel={ () => {
            this.setState({ show: false });
          } }
          onClose={ () => console.log('close') } // eslint-disable-line no-console
        />
        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <div>
              <PageTitle
                heading="Venue Management"
                icon="lnr-apartment icon-gradient bg-tempting-azure"
              />
            </div>
          </Container>
          <Card>
            <CardBody>
              <Button
                className="mb-2 mr-2 btn-icon float-right"
                color="primary"
                onClick={ () => {
                  this.props.history.push('/wedding/venue');
                } }
              >
                Back To List
              </Button>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Nav justified>
                <NavItem>
                  <NavLink
                    href="#"
                    className={ classnames({
                      active: this.state.activeTab === '1',
                    }) }
                    onClick={ () => {
                      this.toggle('1');
                    } }
                  >
                    Venue Form
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={ classnames({
                      active: this.state.activeTab === '2',
                    }) }
                    onClick={ () => {
                      this.toggle('2');
                    } }
                  >
                    Venue Images
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={ this.state.activeTab }>
                <TabPane tabId="1">
                  <Formik
                    initialValues={ initialValues }
                    validationSchema={ this.validationSchema }
                    enableReinitialize
                    onSubmit={ async (values) => {
                      await weddingPackage.venue.saveVenue(values);
                      // this.props.history.push("/wedding/venue");
                      toast.success('Venue saved successfully.');
                    } }
                  >
                    {({
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form autoComplete="off">
                        <Row>
                          <Col md="3"> </Col>
                          <Col md="6">
                            <Card>
                              <CardHeader>Create Venue</CardHeader>

                              <CardBody>
                                <FormGroup>
                                  <TextField
                                    label="Venue Name"
                                    name="venueName"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <label>Resort</label>
                                  <Field
                                    className="form-control"
                                    name="resortID"
                                    as="select"
                                    onChange={ (e) => {
                                      this.onchangeResort(e.target.value);
                                      setFieldValue('resortID', e.target.value);
                                    } }
                                  >
                                    <option value="">--Select--</option>
                                    {this.state.resort.map((resort) => (
                                      <option
                                        key={ resort.resortID }
                                        value={ resort.resortID }
                                      >
                                        {resort.resortName}
                                      </option>
                                    ))}
                                  </Field>
                                  {/*  <SelectInput options={this.state.resort} label="Resort" name="resortID" /> */}
                                </FormGroup>

                                <FormGroup>
                                  <label> Destination </label>
                                  <Field
                                    name="destinationID"
                                    className={
                                      errors.destinationID
                                      && touched.destinationID
                                        ? 'is-invalid form-control'
                                        : 'form-control'
                                    }
                                    as="select"
                                    onChange={ (e) => {
                                      this.loadSubDestination(e.target.value);
                                      setFieldValue(
                                        'destinationID',
                                        e.target.value
                                      );
                                    } }
                                    disabled="disabled"
                                  >
                                    <option value="">--Select--</option>
                                    {this.state.destination.map(
                                      (destination) => (
                                        <option
                                          key={ destination.value }
                                          value={ destination.value }
                                        >
                                          {destination.text}
                                        </option>
                                      )
                                    )}
                                  </Field>

                                  {errors.destinationID
                                  && touched.destinationID ? (
                                    <div className="invalid-validation">
                                      {errors.destinationID}
                                    </div>
                                    ) : null}
                                </FormGroup>

                                <FormGroup>
                                  <label> Sub Destination </label>
                                  <Field
                                    name="subDestinationID"
                                    className={
                                      errors.subDestinationID
                                      && touched.subDestinationID
                                        ? 'is-invalid form-control'
                                        : 'form-control'
                                    }
                                    as="select"
                                    onChange={ (e) => {
                                      this.resort(e.target.value);
                                      setFieldValue(
                                        'subDestinationID',
                                        e.target.value
                                      );
                                    } }
                                    disabled="disabled"
                                  >
                                    <option value="">--Select--</option>
                                    {this.state.subDestination.map(
                                      (subDestination) => (
                                        <option
                                          key={ subDestination.value }
                                          value={ subDestination.value }
                                        >
                                          {subDestination.text}
                                        </option>
                                      )
                                    )}
                                  </Field>

                                  {errors.subDestinationID
                                  && touched.subDestinationID ? (
                                    <div className="invalid-validation">
                                      {errors.subDestinationID}
                                    </div>
                                    ) : null}
                                </FormGroup>

                                <FormGroup>
                                  <Row>
                                    <Col md={ 3 }>
                                      <TextField
                                        type="number"
                                        min="1"
                                        label="Capacity"
                                        name="capacity"
                                      />
                                    </Col>
                                  </Row>
                                </FormGroup>

                                <FormGroup>
                                  <TextArea
                                    label="Description"
                                    name="description"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <Row>
                                    <Col md={ 6 }>
                                      <label>Allow Religious Ceremony</label>
                                      <div className="input-group mt-2">
                                        <Field
                                          type="checkbox"
                                          name="allowReligiousCeremony"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </CardBody>
                              <Button
                                disabled={ isSubmitting }
                                type="submit"
                                color="primary"
                                className="mt-1"
                              >
                                {isSubmitting ? 'Processing....' : 'Save'}
                              </Button>
                            </Card>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </TabPane>
                <TabPane tabId="2">
                  <Button
                    className="btn btn-primary mb-3"
                    color="primary"
                    onClick={ () => this.setState((pS) => ({
                      showImageUpload: !pS.showImageUpload,
                    })) }
                  >
                    Add Images
                  </Button>

                  <div
                    className={ this.state.showImageUpload ? '' : 'd-none' }
                    id="collapseImage"
                  >
                    <PhotoWidgetDropzone
                      that={ this }
                      Id={ this.state.venueId }
                      submit={ this.addImage }
                    />
                  </div>
                  <div className="container mt-5">
                    {this.state.chunkedData.map((row, rowIndex) => (
                      <div key={ rowIndex } className="row mb-4">
                        {row.map((col, colIndex) => (
                          <div
                            className="col-lg-4 d-none d-lg-block"
                            key={ colIndex + rowIndex }
                          >
                            <div className="card">
                              <div className="card-body">
                                {/* {col.imageURL} */}
                                <img
                                      // src={this.state.baseUrl + col.imageURL}
                                  src={ col.imageURL }
                                  className="imagesize hover mb-3"
                                  alt={ col.altTagText }
                                />

                                <FormGroup>
                                  Image Name
                                  <input
                                    type="text"
                                    name="imageName"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.imageName }
                                    className="form-control mb-2"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  Alt Tag Image
                                  <input
                                    type="text"
                                    name="altTagText"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.altTagText }
                                    className="form-control mb-2"
                                  />
                                </FormGroup>

                                <FormGroup>
                                  Image Order
                                  <input
                                    type="number"
                                    name="imageOrder"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.imageOrder }
                                    className="form-control mb-2"
                                  />
                                </FormGroup>

                                <button
                                  type="button"
                                  className="mt-2 mb-2 mr-2 btn-icon btn btn-success"
                                  onClick={ () => this.updateImage(
                                    rowIndex * 3 + colIndex
                                  ) }
                                >
                                  <i className="pe-7s-edit btn-icon-wrapper">
                                    {' '}
                                  </i>
                                  Update
                                </button>
                                <button
                                  type="button"
                                  className="mt-2 mb-2 mr-2 btn-icon btn btn-danger"
                                  onClick={ () => this.showConfirmationToDelete(
                                    col.imageContentID
                                  ) }
                                >
                                  <i className="pe-7s-trash btn-icon-wrapper">
                                    {' '}
                                  </i>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </TransitionGroup>
      </>
    );
  }
}
export default withRouter(Edit);
