import { Field, Formik, Form } from 'formik';
import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';

import { CustomDatePicker } from 'common/Form/DatePicker';

import { TextField } from 'common/Form/TextInput';
import { formatDate, updateToUTCDate } from 'common/utils/dateUtils';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useWeddingDateSlice } from '../../slice';
import {
  selectWeddingDetailIdentifier,
  selectConfirmDetails,
  selectUpdateConfirmDetailsLoading,
} from '../../slice/selectors';

const validationWeddingDetailFormSchema = Yup.object({
  confirmNumber: Yup.string().required('The Confirmation Number is required'),
  confirmWeddingDate: Yup.date().required(
    'The Confirmed Wedding Date is required',
  ),
  confirmWeddingTime: Yup.date().required(
    'The Confirmed Wedding Time is required',
  ),
});

const _Confirmed_Details = () => {
  const dispatch = useAppDispatch();
  const { actions } = useWeddingDateSlice();
  const initialData = useAppSelector(selectConfirmDetails);
  const { id } = useAppSelector(selectWeddingDetailIdentifier);
  const {id: userId} = useParams<{id:string}>()
  const isSubmitting = useAppSelector(selectUpdateConfirmDetailsLoading);

  const handleSubmit = data => {
    dispatch(actions.handleSaveConfirmWeddingDetails(data));
  };

  const handleSaveWeddingDetail = formData => {
    handleSubmit(formData);
  };

  const handleWeddingDetailForm = value => {
    const formData = new FormData();
    formData.append('formFile', value.file);

    const confirmedWeddingDatetime = updateToUTCDate(
      value.confirmWeddingDate,
      value.confirmWeddingTime,
    );

    formData.append('SalesLeadId', id.toString());
    formData.append(
      'ConfirmedWeddingDatetime',
      formatDate(confirmedWeddingDatetime),
    );
    formData.append('weddingSiteUserName', value.weddingSiteUserName);
    formData.append('weddingSitePassword', value.weddingSitePassword);
    formData.append('WeddingConfirmationNumber', value.confirmNumber);
    formData.append(
      'AssignedWeddingCoordinatorName',
      value.assignedWeddingCoordinatorName,
    );
    formData.append(
      'AssignedWeddingCoordinatorEmail',
      value.weddingCoordinatorEmail,
    );
    formData.append('WeddingPlanningSiteLink', value.weddingPlanningSiteLink);
    formData.append(
      'AwaitingResortConfirmation',
      value.awaitingResortConfirmation,
    );

    handleSaveWeddingDetail(formData);
  };

  useEffect(() => {
    if (!userId) return;
    dispatch(actions.getUserDetails(userId));
  }, [dispatch, actions, userId]);

  return (
    <Card className="main-card mb-3">
      <Formik
        initialValues={initialData}
        validationSchema={validationWeddingDetailFormSchema}
        onSubmit={handleWeddingDetailForm}
        enableReinitialize
        validateOnBlur
      >
        {({ setFieldValue, values }) => (
          <Form autoComplete="off">
            <Card>
              <CardHeader>Confirmed Wedding Details</CardHeader>
              <CardBody>
                <FormGroup row>
                  <Label sm={4}>Awaiting Resort Confirmation?</Label>
                  <Col sm={7} className="d-flex">
                    <Field type="checkbox" name="awaitingResortConfirmation" />
                  </Col>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label sm={4}>Confirmed Wedding Date</Label>
                    <Col sm={7}>
                      <InputGroup>
                        <CustomDatePicker
                          name="confirmWeddingDate"
                          className="form-control-sm"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label sm={4}>Confirmed Wedding Time</Label>
                    <Col sm={7}>
                      <InputGroup>
                        <CustomDatePicker
                          name="confirmWeddingTime"
                          showTimeSelect
                          showTimeSelectOnly
                          hideAddon
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="form-control-sm"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label sm={4}>Wedding Planning Site Username</Label>
                    <Col sm={7}>
                      <TextField
                        style={{
                          height: 'calc(1.5em + 0.5rem + 2px)',
                        }}
                        name="weddingSiteUserName"
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label sm={4}>Wedding Planning Site Password</Label>
                    <Col sm={7}>
                      <TextField
                        style={{
                          height: 'calc(1.5em + 0.5rem + 2px)',
                        }}
                        name="weddingSitePassword"
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label sm={4}>Confirm Number</Label>
                    <Col sm={7}>
                      <TextField
                        style={{
                          height: 'calc(1.5em + 0.5rem + 2px)',
                        }}
                        name="confirmNumber"
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label sm={4}>Assigned Wedding Coordinator Name</Label>
                    <Col sm={7}>
                      <TextField
                        style={{
                          height: 'calc(1.5em + 0.5rem + 2px)',
                        }}
                        name="assignedWeddingCoordinatorName"
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label sm={4}>Wedding Coordinator Email</Label>
                    <Col sm={7}>
                      <TextField
                        style={{
                          height: 'calc(1.5em + 0.5rem + 2px)',
                        }}
                        name="weddingCoordinatorEmail"
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label sm={4}>Wedding Planning Site Link</Label>
                    <Col sm={7}>
                      <TextField
                        style={{
                          height: 'calc(1.5em + 0.5rem + 2px)',
                        }}
                        name="weddingPlanningSiteLink"
                      />
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Row>
                    <Label for="exampleFile" sm={4}>
                      Copy of confirmation
                    </Label>
                    <Col sm={7}>
                      <Input
                        type="file"
                        name="file"
                        id="exampleFile"
                        onChange={event => {
                          setFieldValue('file', event.currentTarget.files[0]);
                        }}
                      />
                      {values.copyOfConfirmation ? (
                        <div>
                          <a
                            href={
                              values.copyOfConfirmationUrl
                                ? values.copyOfConfirmationUrl
                                : ''
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {values.copyOfConfirmation}
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </FormGroup>
              </CardBody>
              <CardFooter className="d-block text-right">
                <Button
                  type="submit"
                  size="lg"
                  className="mb-2 mr-2"
                  color="success"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing....' : 'Save'}
                </Button>
              </CardFooter>
            </Card>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default _Confirmed_Details;
