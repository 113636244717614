import React, { memo, useState } from 'react';
import { IoIosClipboard, IoIosEye, IoIosEyeOff, IoIosLogIn } from 'react-icons/io';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
} from 'reactstrap';

const ImpersonationModal = ({
  isOpen,
  className,
  closeUserDetailPopup,
  impersonationLink,
}: {
  isOpen: boolean;
  className?: string | undefined;
  closeUserDetailPopup: () => void;
  impersonationLink: string | null;
}) => {
  const [showLinkWithoutDomain, setShowLinkWithoutDomain] =
    useState<boolean>(false);
  const displayImpersonationLinkValue = (
    impersonationLink: string,
    withoutDomain: boolean = false,
  ) => {
    if (!impersonationLink) return impersonationLink;

    if (withoutDomain) {
      const url = new URL(impersonationLink);
      return '/' + impersonationLink.split(url.hostname)[1].slice(1);
    }
    return impersonationLink;
  };
  return (
    <Modal isOpen={isOpen} className={className}>
      <ModalHeader>User Impersonation</ModalHeader>
      <ModalBody>
        <h5>Link expires after 15 minutes</h5>
        <p />
        <hr />
        <h5>Secure link and cannot be used more than once.</h5>
        <p>This link and that link have tooltips on hover.</p>
        <a
          className="mb-2 mr-2 btn-pill btn btn-primary btn-sm btn-block"
          href={impersonationLink}
          target="_new"
          rel="noreferrer"
        >
          <IoIosLogIn size={20} />{' '}Click here to open in new window
        </a>
        <hr />
        <p>Generated Link (for use with preview branches):</p>

        <div className="pull-right">
          <ButtonGroup size="sm" className="mb-2">
            <Button
              outline
              className="btn-pill btn-dashed"
              color="alternate"
              onClick={() => setShowLinkWithoutDomain(prev => !prev)}
              active={!showLinkWithoutDomain}
            >
             <IoIosEye /> {' '}  Display with domain
            </Button>
            <Button
              outline
              className="btn-pill btn-dashed"
              color="alternate"
              onClick={() => setShowLinkWithoutDomain(prev => !prev)}
              active={showLinkWithoutDomain}
            >
              <IoIosEyeOff /> {' '} Display without domain
            </Button>
          </ButtonGroup>
        </div>

        <FormGroup>
          <Label for="exampleText"></Label>
          <Input
            style={{ height: '30vh' }}
            readOnly
            type="textarea"
            value={displayImpersonationLinkValue(
              impersonationLink,
              showLinkWithoutDomain,
            )}
          />
        </FormGroup>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(
              displayImpersonationLinkValue(
                impersonationLink,
                showLinkWithoutDomain,
              ),
            );
          }}
        >
          <IoIosClipboard /> &nbsp; Copy to Clipboard
        </Button>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={closeUserDetailPopup}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(ImpersonationModal);
