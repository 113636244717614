import {
  configureStore,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { initialCommonState } from 'store/slice';
import { initialAuthState } from 'Pages/UserPages/slice';
import { initialThemeState } from '../themeSlice';
import { createReducer } from './reducers';

export function configureAppStore(initialState) {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) => {
      return [
        ...getDefaultMiddleware({
          serializableCheck: false,
        }),
        ...middlewares,
      ]
    },
    devTools: process.env.REACT_APP_NODE_ENV !== 'production',
    enhancers,
    preloadedState: {
      common: {
        ...initialState,
        nodeEnv: process.env.REACT_APP_NODE_ENV,
      },
      ThemeOptions: {
        ...initialThemeState,
      },
      auth: { ...initialAuthState },
    },
  });

  return store;
}

export const store = configureAppStore(initialCommonState);
