import { createSelector } from '@reduxjs/toolkit';

import { initialThemeState } from './index';

const selectSlice = state => state.ThemeOptions || initialThemeState;

export const selectThemeOptionsState = createSelector(
  [selectSlice],
  state => state,
);
