import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  FormGroup,
  Col,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { CustomDatePicker } from '../../common/Form/DatePicker';

const field = {
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: new Date(),
};

const formatDate = date => moment(date).format('MM/DD/YYYY');

const radioOptions = [
  {
    label: 'GrandView Double $3,500.00',
    id: 'exampleCustomRadio',
  },
  {
    label: 'OceanView Delux $4,500.00',
    id: 'exampleCustomRadio2',
  },
  {
    label: 'GrandView King Suit $5,500.00',
    id: 'exampleCustomRadio3',
  },
  {
    label: 'OceanView King Swimup Suite $6,500.00',
    id: 'exampleCustomRadio4',
  },
];

const m = {
  firstName: 'First Name',
  lastName: 'Last Name',
  middleName: 'Middle Name',
};

const BookingDetails = ({ selectedhotelchain, onClose }) => {
  const {
    bookingDetails: { checkIn, checkOut },
    guestName = [],
  } = selectedhotelchain || {};
  const myGuests = guestName.map(item => ({
    ...item,
    dateOfBirth: new Date(),
  }));
  const [checkAvailability, setCheckAvailability] = useState(false);

  const initialValues = {
    fields: myGuests as typeof field[],
    checkin: formatDate(checkIn),
    checkout: formatDate(checkOut),
  };

  const validationSchema = Yup.object().shape({
    fields: Yup.array().of(
      Yup.object({
        firstName: Yup.string().required('First Name is required'),
        middleName: Yup.string().required('Middle Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        dateOfBirth: Yup.date()
          .max(new Date(), 'invalid date of birth')
          .required('Date of Birth is required'),
      }),
    ),
  });

  const confirmBookingDetails = () => {
    // setIsSubmitting(true);
  };

  return (
    <>
      <ModalBody>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={values => {
            console.log(values);
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className="form-row">
                <Col md={3}>
                  <FormGroup>
                    <CustomDatePicker
                      label="Check-in"
                      name="checkin"
                      timeCaption="time"
                      placeholderText="Enter start date"
                    />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <CustomDatePicker
                      label="Check-out"
                      name="checkout"
                      timeCaption="time"
                      placeholderText="Enter end date"
                    />
                  </FormGroup>
                </Col>
              </div>
              <input
                type="submit"
                value="Check Availability"
                onClick={() => setCheckAvailability(true)}
                className="btn btn-primary"
              />
              <div
                className={checkAvailability ? 'mb-4 mt-4' : 'mb-4 mt-4 d-none'}
              >
                <div className="card-shadow-primary border mb-3 card card-body border-primary">
                  <div className="card-title">Available Packages</div>
                  <FormGroup>
                    {radioOptions.map(option => (
                      <CustomInput
                        key={option.id}
                        type="radio"
                        id={option.id}
                        name="customRadio"
                        label={option.label}
                      />
                    ))}
                  </FormGroup>
                </div>
              </div>
              <div className="form-group">
                <FieldArray
                  name={'fields'}
                  render={helpers => {
                    return (
                      <>
                        {values.fields &&
                          values.fields.map((field, i) => {
                            const { dateOfBirth, ...inputFields } = field;
                            return (
                              <>
                                <div className="form-row mb-2 mt-2" key={i}>
                                  {Object.entries(inputFields).map(
                                    ([name, value]) => {
                                      return (
                                        <div className="col" key={name}>
                                          <input
                                            type="text"
                                            name={name}
                                            onChange={e =>
                                              setFieldValue(
                                                `fields.${i}.${name}`,
                                                e.target.value,
                                              )
                                            }
                                            value={value}
                                            className="form-control"
                                            placeholder={m[name]}
                                          />
                                          <ErrorMessage
                                            name={`fields.${i}.${name}`}
                                          />
                                        </div>
                                      );
                                    },
                                  )}
                                  <div className="col">
                                    <InputGroup>
                                      <InputGroupAddon addonType="prepend">
                                        <div className="input-group-text">
                                          <FontAwesomeIcon
                                            icon={faCalendarAlt}
                                          />
                                        </div>
                                      </InputGroupAddon>
                                      <DatePicker
                                        showYearDropdown
                                        name="dateOfBirth"
                                        selected={dateOfBirth}
                                        onChange={date =>
                                          setFieldValue(
                                            `fields.${i}.dateOfBirth`,
                                            formatDate(date),
                                          )
                                        }
                                        className="form-control"
                                        placeholderText="Date of Birth"
                                      />
                                    </InputGroup>
                                    <ErrorMessage
                                      name={`fields.${i}.dateOfBirth`}
                                    />
                                  </div>
                                  <div className="btn-box">
                                    {values.fields.length > 1 && (
                                      <button
                                        type="button"
                                        className="mb-2 mr-2 btn-pill btn btn-danger"
                                        onClick={() => helpers.remove(i)}
                                      >
                                        Remove
                                      </button>
                                    )}
                                    {values.fields.length - 1 === i && (
                                      <button
                                        type="button"
                                        className="mb-2 mr-2 btn-pill btn btn-success"
                                        onClick={() =>
                                          helpers.push({ ...field })
                                        }
                                      >
                                        Add
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </>
                    );
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={confirmBookingDetails}>
          Confirm Changes
        </Button>
        <Button color="danger" onClick={onClose}>
          Cancel Changes
        </Button>
      </ModalFooter>
    </>
  );
};

export default BookingDetails;
