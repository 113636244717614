/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardHeader,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';

import PropTypes from 'prop-types';

import { Formik, Form, Field, FieldArray } from 'formik';

import { toast } from 'react-toastify';

import classnames from 'classnames';

import SweetAlert from 'sweetalert-react';
import arrayChunk from 'lodash.chunk';

import { TransitionGroup } from 'react-transition-group';
import _ from 'lodash';
import * as Yup from 'yup';
import { CustomDatePicker } from '../../../../common/Form/DatePicker';
import { TextCurrency } from '../../../../common/Form/TextInputCurrency';
import weddingPackage from '../../../../api/weddingPackage';
import PhotoWidgetDropzone from '../../../../common/imageUploader/photoWidgetDropzone';
import PackageCreate from './create';
// import {format} from 'date-fns';


function Edit({ weddingPackageId, resortID, updateListView }) {
  const [activeTab, setActiveTab] = useState('1');
  const [images, setImages] = useState([]);
  const [chunkedData, setChunkedData] = useState([]);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteImageId, setDeleteImageId] = useState(0);
  const [pricing, setPricing] = useState({
    weddingPackageId,
    pricingLists: [
      {
        packageId: 0,
        effectiveDate: '',
        normalPrice: '',
        weekendPrice: 0,
        isWeekend: false,
        isActive: false,
      },
    ],
  });
  const validationSchema = Yup.object({
    pricingLists: Yup.array().of(
      Yup.object().shape({
        effectiveDate: Yup.string().required('Effective Date is required.'),
        normalPrice: Yup.number().required('Normal Price is required.'),
        isWeekend: Yup.boolean(),
        weekendPrice: Yup.number().when('isWeekend', {
          is: true,
          then: Yup.number()
            .required('Normal Price is required.')
            .min(1, 'Amount must not be equal to 0'),
        }),
      }),
    ),
  });

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const getImages = useCallback(async () => {
    const Images = await weddingPackage.packagedetails.getImages(
      weddingPackageId,
    );
    setImages(Images);
    setChunkedData(arrayChunk(Images, 3));
  }, [weddingPackageId]);

  useEffect(() => {
    const id = weddingPackageId;
    if (id && id > 0) {
      getImages();
    }
  }, [weddingPackageId, getImages]);

  const addImage = async param => {
    await weddingPackage.packagedetails.uploadPhoto(
      param.Id,
      param.imageName,
      param.altTagText,
      param.blob,
    );
    toast.success('Image saved successfully for the package.');
    await getImages();
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...images];
    list[index][name] = value;
    setImages(list);
    setChunkedData(arrayChunk(list, 3));
  };

  const updateImage = index => {
    const image = images[index];
    const exceptList = _.reject(
      images,
      o => o.imageContentID === image.imageContentID,
    );
    const isOrderExist = exceptList.some(
      d => d.imageOrder === image.imageOrder,
    );

    if (isOrderExist) {
      toast.error('Image display order already exists.');
      return;
    }

    weddingPackage.packagedetails.updateImageDetails(image).then(() => {
      toast.success('The details of package image updated successfully.');
    });
  };

  const showConfirmationToDelete = id => {
    setDeleteImageId(id);
    setShow(true);
  };

  const deleteImage = () => {
    weddingPackage.packagedetails
      .deleteImages({ imageContentID: deleteImageId })
      .then(() => {
        toast.success('Image deleted succesfuly.');
        getImages();
      });
  };

  const formatDate = (date) => {
    const dateFormatted = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    return dateFormatted;
  }

  const getPricing = async () => {
    await weddingPackage.packagedetails
      .getWeddingPackagePricing(weddingPackageId)
      .then(res => {
        res.pricingLists.forEach(
          price =>
          (price.effectiveDate = formatDate(
            price.effectiveDate
          )),
        );

        setPricing(res);
      });
  };

  const submitPricing = formValues => {
    const newValues = { ...formValues };
    newValues.weddingPackageId = weddingPackageId;
    newValues.pricingLists = newValues.pricingLists.map(value => ({
      ...value,
      effectiveDate: formatDate(value.effectiveDate),
    }));

    return weddingPackage.packagedetails.saveWeddingPackagePricing(newValues);
  };

  useEffect(() => {
    getPricing();
  }, []);

  return (
    <>
      <SweetAlert
        show={show}
        type="warning"
        title="Confirmation"
        text="Are you sure to delete this image?"
        showCancelButton
        onConfirm={() => {
          deleteImage();
          setShow(false);
        }}
        onCancel={() => {
          setShow(false);
        }}
        onClose={() => console.log('close')}
      />
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid />
        <Card>
          <CardBody>
            <Button
              className="mb-2 mr-2 btn-icon float-right"
              color="primary"
              onClick={() => {
                updateListView(true);
              }}
            >
              Back To List
            </Button>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Nav justified>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === '1',
                  })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Package Form
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === '2',
                  })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Package Images
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({
                    active: activeTab === '3',
                  })}
                  onClick={() => {
                    toggle('3');
                  }}
                >
                  Package Pricing
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <PackageCreate
                  weddingPackageId={weddingPackageId}
                  resortId={resortID}
                  updateListView={updateListView}
                />
              </TabPane>
              <TabPane tabId="2">
                {chunkedData.length < 1 && (
                  <>
                    <Button
                      className="btn btn-primary mb-3"
                      color="primary"
                      onClick={() => setShowImageUpload(!showImageUpload)}
                    >
                      Add Images
                    </Button>

                    <div
                      className={showImageUpload ? '' : 'd-none'}
                      id="collapseImage"
                    >
                      <PhotoWidgetDropzone
                        that={this}
                        Id={weddingPackageId}
                        submit={addImage}
                      />
                    </div>
                  </>
                )}

                <div className="container mt-5">
                  {chunkedData.map((row, rowIndex) => (
                    <div key={rowIndex} className="row mb-4">
                      {row.map((col, colIndex) => (
                        <div
                          className="col-lg-4 d-none d-lg-block"
                          key={colIndex + rowIndex}
                        >
                          <div className="card">
                            <div className="card-body">
                              {/* {col.imageURL} */}
                              <img
                                // src={baseUrl + col.imageURL}
                                src={col.imageURL}
                                className="imagesize hover mb-3"
                                alt={col.altTagText}
                              />

                              <FormGroup>
                                Image Name
                                <input
                                  type="text"
                                  name="imageName"
                                  maxLength="200"
                                  onChange={e =>
                                    handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex,
                                    )
                                  }
                                  value={col.imageName}
                                  className="form-control mb-2"
                                />
                              </FormGroup>

                              <FormGroup>
                                Alt Tag Image
                                <input
                                  type="text"
                                  name="altTagText"
                                  maxLength="200"
                                  onChange={e =>
                                    handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex,
                                    )
                                  }
                                  value={col.altTagText}
                                  className="form-control mb-2"
                                />
                              </FormGroup>

                              <FormGroup>
                                Image Order
                                <input
                                  type="number"
                                  name="imageOrder"
                                  onChange={e =>
                                    handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex,
                                    )
                                  }
                                  value={col.imageOrder}
                                  className="form-control mb-2"
                                />
                              </FormGroup>

                              <button
                                type="button"
                                className="mt-2 mb-2 mr-2 btn-icon btn btn-success"
                                onClick={() =>
                                  updateImage(rowIndex * 3 + colIndex)
                                }
                              >
                                <i className="pe-7s-edit btn-icon-wrapper"> </i>
                                Update
                              </button>
                              <button
                                type="button"
                                className="mt-2 mb-2 mr-2 btn-icon btn btn-danger"
                                onClick={() =>
                                  showConfirmationToDelete(col.imageContentID)
                                }
                              >
                                <i className="pe-7s-trash btn-icon-wrapper">
                                  {' '}
                                </i>
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <div className="col-md-12">
                    <Card className="mb-4 mt-4">
                      <CardHeader>
                        <h6>Pricing</h6>
                      </CardHeader>
                      <CardBody>
                        <Formik
                          validationSchema={validationSchema}
                          enableReinitialize
                          initialValues={pricing}
                          onSubmit={async values => {
                            try {
                              await submitPricing(values);
                              toast.success(
                                'Wedding Package Price saved successfully',
                              );
                              getPricing();
                              // eslint-disable-next-line no-empty
                            } catch (e) {
                              toast.error('Some error occured');
                            }
                          }}
                        >
                          {({ values, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit} autoComplete="off">
                              <FieldArray name="pricingLists">
                                {({ push, remove }) => (
                                  <>
                                    {values.pricingLists.map((value, index) => (
                                      <Row className="mt-2" key={index}>
                                        <Col className="col-md-5">
                                          <CustomDatePicker
                                            label="Effective"
                                            name={`pricingLists[${index}].effectiveDate`}
                                          />
                                        </Col>

                                        <Col className="col-md-3">
                                          <TextCurrency
                                            label="Normal Price"
                                            name={`pricingLists[${index}].normalPrice`}
                                          />
                                          {value.isWeekend ? (
                                            <TextCurrency
                                              label="Weekend Price"
                                              name={`pricingLists[${index}].weekendPrice`}
                                            />
                                          ) : (
                                            <p style={{ display: 'none' }}>
                                              {(value.weekendPrice = 0)}
                                            </p>
                                          )}
                                        </Col>

                                        <div>
                                          <label
                                            htmlFor={`pricingLists[${index}].isWeekend`}
                                            className="ml-3 form-check-label"
                                          >
                                            Weekend
                                          </label>
                                          <Field
                                            type="checkbox"
                                            style={{ marginTop: '40px' }}
                                            className="form-check-input"
                                            name={`pricingLists[${index}].isWeekend`}
                                          />
                                        </div>

                                        <div className="ml-4">
                                          <label
                                            htmlFor={`pricingLists[${index}].isActive`}
                                            className=" form-check-label"
                                          >
                                            Enable
                                          </label>
                                          <Field
                                            type="checkbox"
                                            style={{ marginTop: '40px' }}
                                            className="form-check-input"
                                            name={`pricingLists[${index}].isActive`}
                                          />
                                        </div>

                                        <Col style={{ textAlign: 'right' }}>
                                          <Button
                                            style={{ marginTop: '30px' }}
                                            onClick={() => {
                                              remove(index);

                                              if (
                                                values.pricingLists[index]
                                                  .packageId !== 0
                                              ) {
                                                const packageID =
                                                  pricing.pricingLists[index]
                                                    .packageId;
                                                weddingPackage.packagedetails
                                                  .deleteWeddinPackagePricing(
                                                    packageID,
                                                  )
                                                  .then(() =>
                                                    toast.success(
                                                      'Package pricing deleted successfully',
                                                    ),
                                                  );
                                              }
                                            }}
                                            color="danger"
                                            className="mb-2 mr-2 btn-icon btn-icon-only"
                                          >
                                            {' '}
                                            <i className="pe-7s-trash btn-icon-wrapper">
                                              {' '}
                                            </i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    ))}

                                    <Button
                                      className="mt-3"
                                      onClick={() =>
                                        push({
                                          packageId: 0,
                                          effectiveDate: '',
                                          normalPrice: '',
                                          weekendPrice: 0,
                                          isWeekend: false,
                                          isActive: false,
                                        })
                                      }
                                      color="success"
                                    >
                                      <i className="pe-7s-plus btn-icon-wrapper">
                                        {' '}
                                      </i>{' '}
                                      Add{' '}
                                    </Button>
                                  </>
                                )}
                              </FieldArray>

                              <Row className="mt-3">
                                <Button
                                  style={{ marginLeft: '10px' }}
                                  type="submit"
                                  disabled={isSubmitting}
                                  color="primary"
                                >
                                  {isSubmitting ? (
                                    <span>Processing....</span>
                                  ) : (
                                    <span>Save</span>
                                  )}
                                </Button>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      </CardBody>
                    </Card>
                  </div>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </TransitionGroup>
    </>
  );
}

Edit.defaultProps = {
  resortID: 0,
  updateListView: () => null,
};

Edit.propTypes = {
  weddingPackageId: PropTypes.number.isRequired,
  resortID: PropTypes.number,
  updateListView: PropTypes.func,
};
export default Edit;
