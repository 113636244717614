import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useOutsideClick } from 'hooks';

import styles from './style.module.scss';

export interface Option {
  label: string | number | boolean;
  value: string | number | boolean;
}

export interface Props<T> {
  items: T[];
  label?: string;
  onChange: (item: Option) => void;
  extractor: (item: T) => Option;
  initialValue?: Option | string | number | boolean;
  onClear?: () => void;
  showClearOption?: boolean;
  toggleSelect?: boolean;
  defaultValue?: Option;
  autoClose?: boolean;
  disabled?: boolean;
  render?: (item: T) => JSX.Element;
  isMulti?: boolean;
  size?: "small" | "medium";
}

const Index = <T extends unknown>({
  onChange,
  items,
  extractor,
  initialValue,
  defaultValue,
  toggleSelect = false,
  disabled = false,
  label = 'Select...',
  render,
  autoClose = true,
  showClearOption = false,
  onClear = () => {},
  isMulti = false,
  size = "medium"
}: Props<T>) => {
  const [selected, setSelected] = useState<Option>(
    defaultValue ? defaultValue : ({} as Option),
  );

  const { targetRef, isOpen } = useOutsideClick<HTMLDivElement>(false, {
    toggle: autoClose,
  });

  const clear = useCallback(() => {
    setSelected({} as Option);
    onChange({ value: null, label: "" });
    onClear();
  },[onClear,onChange]);

  // useEffect(() => {
  //   console.log({selected})
  // },[selected])

  const handleChange = useCallback(
    (item: Option) => {
      if (item.value === selected.value) {
        if (toggleSelect) clear();
        console.log("clearing and returning...")
        return;
      }
      setSelected(item);
      onChange(item);
    },
    [toggleSelect, selected, clear, onChange],
  );

  useEffect(() => {
    if(initialValue === undefined || initialValue === "") return;
    if(initialValue === null) {
      return setSelected({} as Option);
    }
    if(typeof initialValue === 'object') {
      setSelected(initialValue);
    }else {
      //treat as value
      const item = (items.find(item => extractor(item).value === initialValue) ?? {}) as Option
      setSelected(extractor(item as T))
    }
    // eslint-disable-next-line
  }, [initialValue]);

  const handleKeyDown = useCallback(
    (item: Option) =>
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (item.value === selected.value) {
          if (toggleSelect) clear();
          return;
        }
        if (e.code === 'Enter') {
          handleChange(item);
        }
    },
    [toggleSelect, selected, clear, handleChange],
  );

  const memoizedList = useMemo(() => {
    if(!items.length) return <div>This list is empty</div>
    return (
      <>
        {showClearOption && selected.value ? (
          <div
            tabIndex={0}
            title={'clear selection'}
            onClick={clear}
            onKeyDown={clear}
          >
            --UNSELECT--
          </div>
        ) : null}
        {items.map(item => {
          const option = extractor(item);
          const isSelected = selected.value === option.value;
          return (
            <div
              tabIndex={0}
              key={option.value.toString()}
              className={isSelected ? styles['custom-option-selected'] : ''}
              onClick={() => handleChange(option)}
              onKeyDown={handleKeyDown(option)}
              title={option.label.toString()}
            >
              {render ? render(item) : option.label}
            </div>
          );
        })}
      </>
    );
    // eslint-disable-next-line
  }, [showClearOption,items, selected]);
  return (
    <>
      <div ref={targetRef} className={`${styles['custom-select-container']}`}>
        <div
          className={`${styles['custom-select-input']} ${
            disabled ? styles['input-disabled'] : ''
          } form-control ${size === "small" ? 'form-control-sm' : ''}`}
          tabIndex={0}
        >
          <div
            className="flex-grow-1"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {selected.value != null ? selected.label : label}
          </div>
          <FontAwesomeIcon
            icon={!disabled && isOpen ? faCaretUp : faCaretDown}
          />
        </div>
        {!disabled && isOpen && (
          <div className={styles['custom-select-dropdown']}>{memoizedList}</div>
        )}
      </div>
    </>
  );
};

export default Index;
