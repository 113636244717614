import React, { } from 'react';

import { withRouter } from 'react-router-dom';
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

function NotFound() {
  return (

    <>
      {/* <ThemeOptions /> */}
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-10">
                <div className="card-body">
                  <div data-testid="wrapper" className="_loading_overlay_wrapper css-79elbk">
                    <div className="card-hover-shadow-2x mb-3 card"><div className="card-header"> 404 Not Found</div>
                      <div className="card-body">
                        <p><h1>Oops</h1></p>
                        <p className="mb-0"> Sorry, an error has occured, Requested page not found!</p>
                      </div>
                    </div>
                  </div>
                </div>
                                    
              </div>
            </div>
          </div>
          <AppFooter />
        </div>
      </div>
    </>

  );
}
export default withRouter(NotFound)
