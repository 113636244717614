import React, { memo, useState,useMemo } from "react";
import {
    Button,
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    CardFooter,
  } from 'reactstrap';
  import { IoIosFunnel } from 'react-icons/io';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { ODataDateRangePicker } from 'common/Form/ODataDateRangePicker';
  import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { IODataFilter } from "@/common/OData/ODataFilters";
import ODataRangePicker from 'common/Form/ODataRangePicker';
  interface Props{
    setFilters:Function;
    filters:IODataFilter[];
    clearAllFilters:()=>void;
    onSearch:()=>void;
  }
const Filters=({filters,clearAllFilters,onSearch,setFilters}:Props)=>{
    const [isOpen,openFilters]=useState<boolean>(false);

    const weddingDateFilter = useMemo(()=>filters.find(f => f.name === 'WeddingDate'),[filters]);

    const updateFilters=(filter)=>{
      let newFilters = filters.filter(f => f.name !== filter.name);
      newFilters = [...newFilters, filter];
      setFilters( newFilters);
    }

    const numberRSVPNotBookedRef = React.createRef<any>();

    const isDisabled=useMemo(()=>{
      return !!filters.filter(item=>item.modifier && ( item.name === 'NumberRSVPNotBooked')).find((item :any)=> !item.value.from || (item.modifier==='between' && !item.value.to))
    },[filters])

    const onClear=()=>{
      numberRSVPNotBookedRef?.current?.onReset();
      clearAllFilters();
    }

    return(
        <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  onClick={() => openFilters(!isOpen)}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <h6
                      style={{ display: 'flex', marginBottom: 0 }}
                      className="text-uppercase"
                    >
                      <IoIosFunnel
                        size={'20px'}
                        style={{ marginRight: '5px' }}
                      />
                      <span style={{ color: '#6610f2' }}>Advanced Filters</span>
                    </h6>
                    <div className=" mr-3">
                      <FontAwesomeIcon
                        icon={isOpen ? faAngleUp : faAngleDown}
                        size="xl"
                        onClick={() => openFilters(!isOpen)}
                      />
                    </div>
                  </div>
                </CardHeader>
                <Collapse isOpen={isOpen}>
                  <CardBody>
                    <Row>
                      <Col md={9} >
                        <ODataDateRangePicker
                          key={JSON.stringify(weddingDateFilter)}
                          label={'Wedding Date'}
                          onChange={updateFilters}
                          odataFilter={weddingDateFilter}
                        />
                      </Col>
                    </Row>                    
                    <Row>
                    <Col md={9} >
                        <ODataRangePicker
                          key='NumberRSVPNotBooked'
                          ref={numberRSVPNotBookedRef}
                          {...{
                            label:'Number of RSVP Yes Not Booked',
                            onChange:updateFilters,
                            name:'NumberRSVPNotBooked',}}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter style={{ display: 'block' }}>
                    <div className="float-right">
                      <Button
                        outline
                        onClick={onClear}
                      >
                        Clear All Filters
                      </Button>{' '}
                      <Button
                      disabled={isDisabled}
                        onClick={onSearch}
                      >
                        Search
                      </Button>
                    </div>
                    <div className="clearfix"></div>
                  </CardFooter>
                </Collapse>
              </Card>
            </Col>
          </Row>
    )
};

export default memo(Filters);