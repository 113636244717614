import React from 'react';

import { Button,Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';

import styles from './style.module.scss';
import { PaymentInfoDetails } from '../Reservations/slice/types';

export interface PaymentInfoProps {
    paymentInfo?: PaymentInfoDetails;
}

export const PaymentInfo = ({  paymentInfo }: PaymentInfoProps): JSX.Element => {

  return (
        <Row>
        <Col xs={12}>
            <Card className={styles.table }>
                <CardHeader className={styles.background}>Payment Info</CardHeader>
                <CardBody>
                    <Row>
                    <Col xs={6}>
                    <label className="list-details">
                        Destify Total Price:{' '}
                    <div className="list-details-value">
                        {paymentInfo.destifyTotalPrice && `$${paymentInfo.destifyTotalPrice}`}
                    </div>
                    </label>

                    <label className="list-details">
                        Destify Total Paid:{' '}
                    <div className="list-details-value">
                        {`$${paymentInfo.destifyTotalPaid}`}
                    </div>
                    </label>
                    </Col>
                    <Col xs={6}>
                    <label className="list-details">
                        Supplier Total Price:{' '}
                    <div className="list-details-value">
                        {paymentInfo.supplierTotalPrice && `$${paymentInfo.supplierTotalPrice}`}
                    </div>
                    </label>
                    <label className="list-details">
                        Supplier Total Paid:{' '}
                    <div className="list-details-value">
                        {`$${paymentInfo.supplierTotalPaid}`}
                    </div>
                    </label>
                    </Col>
                    </Row>
                    </CardBody>
                    <CardFooter>
                            <Button size='lg'>View Payment</Button>
                    </CardFooter>
                </Card>
            </Col>
        </Row>
  );
};

