/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import TestErrors from 'Pages/Error/TestError';
import SalesJourneySalesLeadsPage from './SalesLead/SalesJourneySalesLeadsPage';
import ManageSalesLeadDetailPage from './SalesLead/Manage';
import UserFormik from './SalesLead/userformik';
import { useDispatch } from 'react-redux';
import CRMSalesLeadListPage from './SalesLead/CRMSalesLeadListPage';

// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

import { useSalesLeadSlice } from './slice';

function SalesLeadRoute() {
  const {actions} = useSalesLeadSlice();
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(actions.loadSalesLeadData())
  },[]);
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Route exact path="/salesleads/guest-list" component={SalesJourneySalesLeadsPage} />
            <Route path="/salesleads/guest-list/:id" component={ManageSalesLeadDetailPage} />
            <Route path="/salesleads/crm-sales-leads" component={CRMSalesLeadListPage} />
            <Route path="/salesleads/formik" component={UserFormik} />
            <Route path="/salesleads/error" component={TestErrors} />            
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  );
}

export default SalesLeadRoute;
