/* eslint-disable react/prop-types */
/* eslint-disable import/no-unused-modules */
import React from 'react';
import { Route } from 'react-router-dom';
import TestErrors from 'Pages/Error/TestError';
import AdminUserList from './AdminUser/list';
import RegisterAdminUser from './AdminUser/Register';
import UserFormik from './AdminUser/userformik';
import EditAdminUser from './AdminUser/Edit';
import ClientAccountsListPage from './ClientAccounts/ClientAccountsListPage';
import ClientAccountManage from './ClientAccounts/ManagePage';
// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

function User({ match }) {
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            {/*<Route exact path={`${match.url}/guest-list`} component={ClientAccountsList} />*/}
            <Route
              exact
              path={`${match.url}/guest-list-new`}
              component={ClientAccountsListPage}
            />
            <Route
              exact
              path={`${match.url}/guest-list-new/:id`}
              component={ClientAccountManage}
            />
            <Route path="/user/admin-list" component={AdminUserList} />
            <Route path="/user/admin-register" component={RegisterAdminUser} />
            <Route path="/user/formik" component={UserFormik} />
            <Route path="/user/error" component={TestErrors} />
            <Route path="/user/admin-edit/:id" component={EditAdminUser} />
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  );
}

export default User;
