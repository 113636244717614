import { useState, useEffect } from 'react';

const useLocalStorage = <T>(key: string, value?: T) => {
  const [val, setVal] = useState<T | null>(() => {
    const item = localStorage.getItem(key);
    if (item === null) return value;
    return JSON.parse(item);
  });

  const updateValue = (data: T) => {
    setVal(data);
  };

  const clear = () => {
    localStorage.removeItem(key)
  }

  useEffect(() => {
    if (val !== undefined) {
      localStorage.setItem(key, JSON.stringify(val));
    }
  }, [val, key]);

  return [val, updateValue, clear] as const;
};

export default useLocalStorage;
