/* eslint-disable */
// tslint:disable
/**
 * Destify Administration API
 * API environment - staging
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { CRMGroupDTO } from '../model';
import { CRMTravelerInfo } from '../model';
import { GuestUserRoleType } from '../model';
import { UserGroupWithRoomDetailsDto } from '../model';
import { UserRoomAssociationGroupDto } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assigns the given room Id for that user
         * @param {UserRoomAssociationGroupDto} [userRoomAssociationGroupDto] RoomId - The CRM room Id to be assigned. UserEmail -  The user email address for  which the room will be assigned. RoomRole - The room role for which the room will be assigned. Value can be  RoomManager and ViewAndPay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAssignUserToRoomPost(userRoomAssociationGroupDto?: UserRoomAssociationGroupDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/User/AssignUserToRoom`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof userRoomAssociationGroupDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userRoomAssociationGroupDto !== undefined ? userRoomAssociationGroupDto : {}) : (userRoomAssociationGroupDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client user detail by user guid
         * @param {string} userId user guid identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserClientDetailsUserIdGet(userId: string, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling apiUserClientDetailsUserIdGet.');
            }
            const localVarPath = `/api/User/ClientDetails/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User impersonation link generation api
         * @param {string} [userId] User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGenerateImpersonationTokenGet(userId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/User/GenerateImpersonationToken`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Given a list of user GUIDs from the ptid.guestdashboard database, fetch corresponding CRM room information
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetClientAccountRoomDetailsPost(requestBody?: Array<string>, options: any = {}): RequestArgs {
            const localVarPath = `/api/User/GetClientAccountRoomDetails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof requestBody !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the result of invoking $\"Groups/getTravelerInfoByGroupId?id={groupId}\" in the CRM
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetCrmRoomsByGroupIdGet(groupId?: string, options: any = {}): RequestArgs {
            const localVarPath = `/api/User/GetCrmRoomsByGroupId`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of Sales journey User having user claim Sales journey. This is user coming through sales journey lead  process.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetSalesJourneyUsersGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/User/GetSalesJourneyUsers`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users with associated rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersWithAssociatedRoomsGet(options: any = {}): RequestArgs {
            const localVarPath = `/api/User/GetUsersWithAssociatedRooms`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CRM Group update api
         * @param {CRMGroupDTO} [cRMGroupDTO] Group Update request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGroupUpdatePost(cRMGroupDTO?: CRMGroupDTO, options: any = {}): RequestArgs {
            const localVarPath = `/api/User/groupUpdate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof cRMGroupDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cRMGroupDTO !== undefined ? cRMGroupDTO : {}) : (cRMGroupDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user room details
         * @param {string} [userId] 
         * @param {string} [crmGroupId] 
         * @param {boolean} [userIsGroupLeader] 
         * @param {boolean} [excludeSoftDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGuestRoomDetailsGet(userId?: string, crmGroupId?: string, userIsGroupLeader?: boolean, excludeSoftDeleted?: boolean, options: any = {}): RequestArgs {
            const localVarPath = `/api/User/GuestRoomDetails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (crmGroupId !== undefined) {
                localVarQueryParameter['crmGroupId'] = crmGroupId;
            }

            if (userIsGroupLeader !== undefined) {
                localVarQueryParameter['userIsGroupLeader'] = userIsGroupLeader;
            }

            if (excludeSoftDeleted !== undefined) {
                localVarQueryParameter['excludeSoftDeleted'] = excludeSoftDeleted;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user details for a given GuestUserRoleType
         * @param {GuestUserRoleType} type user type Id value. 1 for guest user . 2 for Group leader. 3 for SalesLead
         * @param {boolean} showUserSalesData flag to determine whether to load sales lead user data or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGuestUserTypeShowUserSalesDataGet(type: GuestUserRoleType, showUserSalesData: boolean, options: any = {}): RequestArgs {
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling apiUserGuestUserTypeShowUserSalesDataGet.');
            }
            // verify required parameter 'showUserSalesData' is not null or undefined
            if (showUserSalesData === null || showUserSalesData === undefined) {
                throw new RequiredError('showUserSalesData','Required parameter showUserSalesData was null or undefined when calling apiUserGuestUserTypeShowUserSalesDataGet.');
            }
            const localVarPath = `/api/User/GuestUser/{type}/{showUserSalesData}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"showUserSalesData"}}`, encodeURIComponent(String(showUserSalesData)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {string} userEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSetGroupLeaderGroupIdPatch(groupId: string, userEmail: string, options: any = {}): RequestArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiUserSetGroupLeaderGroupIdPatch.');
            }
            // verify required parameter 'userEmail' is not null or undefined
            if (userEmail === null || userEmail === undefined) {
                throw new RequiredError('userEmail','Required parameter userEmail was null or undefined when calling apiUserSetGroupLeaderGroupIdPatch.');
            }
            const localVarPath = `/api/User/SetGroupLeader/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Un assigns the given room Id for that user
         * @param {UserRoomAssociationGroupDto} [userRoomAssociationGroupDto] RoomId - The CRM room Id to be unassigned. UserEmail -  The user email address for  which the room will be unassigned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUnassignUserFromRoomPost(userRoomAssociationGroupDto?: UserRoomAssociationGroupDto, options: any = {}): RequestArgs {
            const localVarPath = `/api/User/UnassignUserFromRoom`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (typeof userRoomAssociationGroupDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userRoomAssociationGroupDto !== undefined ? userRoomAssociationGroupDto : {}) : (userRoomAssociationGroupDto || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GuestUserRoleType} permissionLevel 
         * @param {string} userGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch(permissionLevel: GuestUserRoleType, userGuid: string, options: any = {}): RequestArgs {
            // verify required parameter 'permissionLevel' is not null or undefined
            if (permissionLevel === null || permissionLevel === undefined) {
                throw new RequiredError('permissionLevel','Required parameter permissionLevel was null or undefined when calling apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch.');
            }
            // verify required parameter 'userGuid' is not null or undefined
            if (userGuid === null || userGuid === undefined) {
                throw new RequiredError('userGuid','Required parameter userGuid was null or undefined when calling apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch.');
            }
            const localVarPath = `/api/User/UpdateDestifyUserPermissions/{userGuid}/{permissionLevel}`
                .replace(`{${"permissionLevel"}}`, encodeURIComponent(String(permissionLevel)))
                .replace(`{${"userGuid"}}`, encodeURIComponent(String(userGuid)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGroupsGet(userId: string, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling apiUserUserIdGroupsGet.');
            }
            const localVarPath = `/api/User/{userId}/groups`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGroupsGroupIdLeaderPost(userId: string, groupId: string, options: any = {}): RequestArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling apiUserUserIdGroupsGroupIdLeaderPost.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiUserUserIdGroupsGroupIdLeaderPost.');
            }
            const localVarPath = `/api/User/{userId}/groups/{groupId}/leader`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", ["openid", "profile", "email"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assigns the given room Id for that user
         * @param {UserRoomAssociationGroupDto} [userRoomAssociationGroupDto] RoomId - The CRM room Id to be assigned. UserEmail -  The user email address for  which the room will be assigned. RoomRole - The room role for which the room will be assigned. Value can be  RoomManager and ViewAndPay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAssignUserToRoomPost(userRoomAssociationGroupDto?: UserRoomAssociationGroupDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserAssignUserToRoomPost(userRoomAssociationGroupDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get client user detail by user guid
         * @param {string} userId user guid identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserClientDetailsUserIdGet(userId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserClientDetailsUserIdGet(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary User impersonation link generation api
         * @param {string} [userId] User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGenerateImpersonationTokenGet(userId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserGenerateImpersonationTokenGet(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Given a list of user GUIDs from the ptid.guestdashboard database, fetch corresponding CRM room information
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetClientAccountRoomDetailsPost(requestBody?: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserGetClientAccountRoomDetailsPost(requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns the result of invoking $\"Groups/getTravelerInfoByGroupId?id={groupId}\" in the CRM
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetCrmRoomsByGroupIdGet(groupId?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CRMTravelerInfo> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserGetCrmRoomsByGroupIdGet(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get list of Sales journey User having user claim Sales journey. This is user coming through sales journey lead  process.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetSalesJourneyUsersGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserGetSalesJourneyUsersGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all users with associated rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersWithAssociatedRoomsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserGetUsersWithAssociatedRoomsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary CRM Group update api
         * @param {CRMGroupDTO} [cRMGroupDTO] Group Update request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGroupUpdatePost(cRMGroupDTO?: CRMGroupDTO, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserGroupUpdatePost(cRMGroupDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user room details
         * @param {string} [userId] 
         * @param {string} [crmGroupId] 
         * @param {boolean} [userIsGroupLeader] 
         * @param {boolean} [excludeSoftDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGuestRoomDetailsGet(userId?: string, crmGroupId?: string, userIsGroupLeader?: boolean, excludeSoftDeleted?: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserGroupWithRoomDetailsDto>> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserGuestRoomDetailsGet(userId, crmGroupId, userIsGroupLeader, excludeSoftDeleted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user details for a given GuestUserRoleType
         * @param {GuestUserRoleType} type user type Id value. 1 for guest user . 2 for Group leader. 3 for SalesLead
         * @param {boolean} showUserSalesData flag to determine whether to load sales lead user data or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGuestUserTypeShowUserSalesDataGet(type: GuestUserRoleType, showUserSalesData: boolean, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserGuestUserTypeShowUserSalesDataGet(type, showUserSalesData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {string} userEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSetGroupLeaderGroupIdPatch(groupId: string, userEmail: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserSetGroupLeaderGroupIdPatch(groupId, userEmail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Un assigns the given room Id for that user
         * @param {UserRoomAssociationGroupDto} [userRoomAssociationGroupDto] RoomId - The CRM room Id to be unassigned. UserEmail -  The user email address for  which the room will be unassigned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUnassignUserFromRoomPost(userRoomAssociationGroupDto?: UserRoomAssociationGroupDto, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserUnassignUserFromRoomPost(userRoomAssociationGroupDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GuestUserRoleType} permissionLevel 
         * @param {string} userGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch(permissionLevel: GuestUserRoleType, userGuid: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch(permissionLevel, userGuid, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGroupsGet(userId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserUserIdGroupsGet(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGroupsGroupIdLeaderPost(userId: string, groupId: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = UserApiAxiosParamCreator(configuration).apiUserUserIdGroupsGroupIdLeaderPost(userId, groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Assigns the given room Id for that user
         * @param {UserRoomAssociationGroupDto} [userRoomAssociationGroupDto] RoomId - The CRM room Id to be assigned. UserEmail -  The user email address for  which the room will be assigned. RoomRole - The room role for which the room will be assigned. Value can be  RoomManager and ViewAndPay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAssignUserToRoomPost(userRoomAssociationGroupDto?: UserRoomAssociationGroupDto, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserAssignUserToRoomPost(userRoomAssociationGroupDto, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get client user detail by user guid
         * @param {string} userId user guid identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserClientDetailsUserIdGet(userId: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserClientDetailsUserIdGet(userId, options)(axios, basePath);
        },
        /**
         * 
         * @summary User impersonation link generation api
         * @param {string} [userId] User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGenerateImpersonationTokenGet(userId?: string, options?: any): AxiosPromise<string> {
            return UserApiFp(configuration).apiUserGenerateImpersonationTokenGet(userId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Given a list of user GUIDs from the ptid.guestdashboard database, fetch corresponding CRM room information
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetClientAccountRoomDetailsPost(requestBody?: Array<string>, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserGetClientAccountRoomDetailsPost(requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns the result of invoking $\"Groups/getTravelerInfoByGroupId?id={groupId}\" in the CRM
         * @param {string} [groupId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetCrmRoomsByGroupIdGet(groupId?: string, options?: any): AxiosPromise<CRMTravelerInfo> {
            return UserApiFp(configuration).apiUserGetCrmRoomsByGroupIdGet(groupId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get list of Sales journey User having user claim Sales journey. This is user coming through sales journey lead  process.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetSalesJourneyUsersGet(options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserGetSalesJourneyUsersGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all users with associated rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUsersWithAssociatedRoomsGet(options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserGetUsersWithAssociatedRoomsGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary CRM Group update api
         * @param {CRMGroupDTO} [cRMGroupDTO] Group Update request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGroupUpdatePost(cRMGroupDTO?: CRMGroupDTO, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserGroupUpdatePost(cRMGroupDTO, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get user room details
         * @param {string} [userId] 
         * @param {string} [crmGroupId] 
         * @param {boolean} [userIsGroupLeader] 
         * @param {boolean} [excludeSoftDeleted] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGuestRoomDetailsGet(userId?: string, crmGroupId?: string, userIsGroupLeader?: boolean, excludeSoftDeleted?: boolean, options?: any): AxiosPromise<Array<UserGroupWithRoomDetailsDto>> {
            return UserApiFp(configuration).apiUserGuestRoomDetailsGet(userId, crmGroupId, userIsGroupLeader, excludeSoftDeleted, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get user details for a given GuestUserRoleType
         * @param {GuestUserRoleType} type user type Id value. 1 for guest user . 2 for Group leader. 3 for SalesLead
         * @param {boolean} showUserSalesData flag to determine whether to load sales lead user data or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGuestUserTypeShowUserSalesDataGet(type: GuestUserRoleType, showUserSalesData: boolean, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserGuestUserTypeShowUserSalesDataGet(type, showUserSalesData, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {string} userEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSetGroupLeaderGroupIdPatch(groupId: string, userEmail: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserSetGroupLeaderGroupIdPatch(groupId, userEmail, options)(axios, basePath);
        },
        /**
         * 
         * @summary Un assigns the given room Id for that user
         * @param {UserRoomAssociationGroupDto} [userRoomAssociationGroupDto] RoomId - The CRM room Id to be unassigned. UserEmail -  The user email address for  which the room will be unassigned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUnassignUserFromRoomPost(userRoomAssociationGroupDto?: UserRoomAssociationGroupDto, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserUnassignUserFromRoomPost(userRoomAssociationGroupDto, options)(axios, basePath);
        },
        /**
         * 
         * @param {GuestUserRoleType} permissionLevel 
         * @param {string} userGuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch(permissionLevel: GuestUserRoleType, userGuid: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch(permissionLevel, userGuid, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGroupsGet(userId: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserUserIdGroupsGet(userId, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGroupsGroupIdLeaderPost(userId: string, groupId: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).apiUserUserIdGroupsGroupIdLeaderPost(userId, groupId, options)(axios, basePath);
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Assigns the given room Id for that user
     * @param {UserRoomAssociationGroupDto} [userRoomAssociationGroupDto] RoomId - The CRM room Id to be assigned. UserEmail -  The user email address for  which the room will be assigned. RoomRole - The room role for which the room will be assigned. Value can be  RoomManager and ViewAndPay
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAssignUserToRoomPost(userRoomAssociationGroupDto?: UserRoomAssociationGroupDto, options?: any) {
        return UserApiFp(this.configuration).apiUserAssignUserToRoomPost(userRoomAssociationGroupDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get client user detail by user guid
     * @param {string} userId user guid identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserClientDetailsUserIdGet(userId: string, options?: any) {
        return UserApiFp(this.configuration).apiUserClientDetailsUserIdGet(userId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary User impersonation link generation api
     * @param {string} [userId] User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGenerateImpersonationTokenGet(userId?: string, options?: any) {
        return UserApiFp(this.configuration).apiUserGenerateImpersonationTokenGet(userId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Given a list of user GUIDs from the ptid.guestdashboard database, fetch corresponding CRM room information
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetClientAccountRoomDetailsPost(requestBody?: Array<string>, options?: any) {
        return UserApiFp(this.configuration).apiUserGetClientAccountRoomDetailsPost(requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns the result of invoking $\"Groups/getTravelerInfoByGroupId?id={groupId}\" in the CRM
     * @param {string} [groupId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetCrmRoomsByGroupIdGet(groupId?: string, options?: any) {
        return UserApiFp(this.configuration).apiUserGetCrmRoomsByGroupIdGet(groupId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get list of Sales journey User having user claim Sales journey. This is user coming through sales journey lead  process.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetSalesJourneyUsersGet(options?: any) {
        return UserApiFp(this.configuration).apiUserGetSalesJourneyUsersGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all users with associated rooms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUsersWithAssociatedRoomsGet(options?: any) {
        return UserApiFp(this.configuration).apiUserGetUsersWithAssociatedRoomsGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary CRM Group update api
     * @param {CRMGroupDTO} [cRMGroupDTO] Group Update request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGroupUpdatePost(cRMGroupDTO?: CRMGroupDTO, options?: any) {
        return UserApiFp(this.configuration).apiUserGroupUpdatePost(cRMGroupDTO, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get user room details
     * @param {string} [userId] 
     * @param {string} [crmGroupId] 
     * @param {boolean} [userIsGroupLeader] 
     * @param {boolean} [excludeSoftDeleted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGuestRoomDetailsGet(userId?: string, crmGroupId?: string, userIsGroupLeader?: boolean, excludeSoftDeleted?: boolean, options?: any) {
        return UserApiFp(this.configuration).apiUserGuestRoomDetailsGet(userId, crmGroupId, userIsGroupLeader, excludeSoftDeleted, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get user details for a given GuestUserRoleType
     * @param {GuestUserRoleType} type user type Id value. 1 for guest user . 2 for Group leader. 3 for SalesLead
     * @param {boolean} showUserSalesData flag to determine whether to load sales lead user data or not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGuestUserTypeShowUserSalesDataGet(type: GuestUserRoleType, showUserSalesData: boolean, options?: any) {
        return UserApiFp(this.configuration).apiUserGuestUserTypeShowUserSalesDataGet(type, showUserSalesData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} groupId 
     * @param {string} userEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSetGroupLeaderGroupIdPatch(groupId: string, userEmail: string, options?: any) {
        return UserApiFp(this.configuration).apiUserSetGroupLeaderGroupIdPatch(groupId, userEmail, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Un assigns the given room Id for that user
     * @param {UserRoomAssociationGroupDto} [userRoomAssociationGroupDto] RoomId - The CRM room Id to be unassigned. UserEmail -  The user email address for  which the room will be unassigned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUnassignUserFromRoomPost(userRoomAssociationGroupDto?: UserRoomAssociationGroupDto, options?: any) {
        return UserApiFp(this.configuration).apiUserUnassignUserFromRoomPost(userRoomAssociationGroupDto, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {GuestUserRoleType} permissionLevel 
     * @param {string} userGuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch(permissionLevel: GuestUserRoleType, userGuid: string, options?: any) {
        return UserApiFp(this.configuration).apiUserUpdateDestifyUserPermissionsUserGuidPermissionLevelPatch(permissionLevel, userGuid, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserIdGroupsGet(userId: string, options?: any) {
        return UserApiFp(this.configuration).apiUserUserIdGroupsGet(userId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserIdGroupsGroupIdLeaderPost(userId: string, groupId: string, options?: any) {
        return UserApiFp(this.configuration).apiUserUserIdGroupsGroupIdLeaderPost(userId, groupId, options)(this.axios, this.basePath);
    }

}
