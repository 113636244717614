import { destifyServicesApiRequests } from './common';

const weddingWebsite = {
  GetWeddingWebsiteByGroupId: id =>
    destifyServicesApiRequests.get(`/Site/CMSGetWebsiteInfo/${id}`),
};
const cmsAPI = {
  weddingWebsite,
};

export default cmsAPI;
