/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Loader } from 'react-loaders';
import _ from 'lodash';
import { Card, CardBody, CardTitle, Container, Table } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { TransitionGroup } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import hotel from '../../../api/hotelContent';
import PageTitle from '../../../Layout/AppMain/PageTitle';

class HotelDestinationList extends React.Component {
  // expanstate = { expanded: false };

  constructor(props) {
    super(props);
    this.state = {
      destinationList: [],
      countryList: [],
      active: false,
      loaderType: 'line-scale-pulse-out-rapid',
    };
  }

  componentDidMount() {
    this.setState({ active: true });
    let countryData = [];
    hotel.hotelcontent
      .countrylist()
      .then((cresponse) => {
        countryData = cresponse;
        return hotel.hotelcontent.destinationList();
      })
      .then((response) => {
        this.setState({
          destinationList: response,
          countryList: countryData,
          active: false,
        });
      });
  }

  getCountryName(countryId) {
    const country = _.find(
      this.state.countryList,
      (o) => o.value === countryId.toString()
    );
    return country.text;
  }

  render() {
    return (
      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={ 0 }
        transitionEnter={ false }
        transitionLeave={ false }
      >
        <Container fluid>
          <div>
            <PageTitle
              heading="Destinations"
              icon="pe-7s-map-marker icon-gradient bg-tempting-azure"
            />
          </div>
          <LoadingOverlay
            tag="div"
            active={ this.state.active }
            styles={ {
              overlay: (base) => ({
                ...base,
                background: '#fff',
                opacity: 0.5,
              }),
            } }
            spinner={ <Loader active type={ this.state.loaderType } /> }
          >
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Hotel Destinations </CardTitle>
                <Table size="sm" striped bordered hover className="mb-3">
                  <thead>
                    <tr>
                      <th> S.N </th>
                      <th> Destination City </th>
                      {/*  <th>Destination City Code  </th> */}
                      <th>Destination Country </th>
                      <th>Status </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.destinationList.length > 0
                      && this.state.destinationList.map((dest, index) => (
                        <tr key={ index }>
                          <td>{index + 1}</td>
                          <td>{dest.name}</td>
                          <td>{this.getCountryName(dest.countryId)}</td>
                          <td>
                            {!dest.isActive && (
                            <div className="mb-2 mr-2 badge badge-pill badge-danger">
                              {' '}
                              Disabled{' '}
                            </div>
                            )}
                            {dest.isActive && (
                            <div className="mb-2 mr-2 badge badge-pill badge-success">
                              {' '}
                              Enabled{' '}
                            </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>

                {/* <Pagination className="pagination-rounded" aria-label="Page navigation example"> */}
                {/*    <PaginationItem> */}
                {/*        <PaginationLink previous href="#" /> */}
                {/*    </PaginationItem> */}
                {/*         <PaginationItem active> */}
                {/*        <PaginationLink href="#">1</PaginationLink> */}
                {/*    </PaginationItem> */}
                {/*    <PaginationItem > */}
                {/*        <PaginationLink href="#">2</PaginationLink> */}
                {/*    </PaginationItem> */}
                {/*    <PaginationItem> */}
                {/*        <PaginationLink href="#">3</PaginationLink> */}
                {/*    </PaginationItem> */}
                {/*    <PaginationItem> */}
                {/*        <PaginationLink href="#">4</PaginationLink> */}
                {/*    </PaginationItem> */}
                {/*    <PaginationItem> */}
                {/*        <PaginationLink href="#">5</PaginationLink> */}
                {/*    </PaginationItem> */}
                {/*    <PaginationItem> */}
                {/*        <PaginationLink next href="#" /> */}
                {/*    </PaginationItem> */}
                {/* </Pagination>      */}
              </CardBody>
            </Card>
          </LoadingOverlay>
        </Container>
      </TransitionGroup>
    );
  }
}
export default withRouter(HotelDestinationList);
