import { Route, Redirect, withRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import Loader from 'react-loaders';

import UserPages from '../../Pages/UserPages';

function LoginMain() {
  return (
    <>
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-scale-ripple-multiple" />
              </div>
              <h6 className="mt-3">
                Loading...
                {/* <small>Because this is a demonstration we load at once all the Pages . This wouldn't happen in a real live app!</small> */}
              </h6>
            </div>
          </div>
        }
      >
        <Route path="/pages" component={UserPages} />
      </Suspense>

      <Route exact path="/" render={() => <Redirect to="/pages/login" />} />
    </>
  );
}

export default withRouter(LoginMain);
