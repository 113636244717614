/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { selectThemeOptionsState } from 'themeSlice/selectors';

import PerfectScrollbar from 'react-perfect-scrollbar';
import Nav from '../AppNav/VerticalNavWrapper';
import HeaderLogo from '../AppLogo';
import { useThemeSlice } from '../../themeSlice';

function AppSidebar() {
  const dispatch = useDispatch();
  const { actions } = useThemeSlice();
  const themeOptions = useSelector(selectThemeOptionsState);

  const {
    backgroundColor,
    backgroundImage,
    backgroundImageOpacity,
    enableMobileMenu,
    enableSidebarShadow,
    enableBackgroundImage,
  } = themeOptions;

  const toggleMobileSidebar = () => {
    dispatch(actions.setEnableMobileMenu(!enableMobileMenu));
  };

  return (
    <>
      {/* <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} /> */}
      <div
        className={cx('app-sidebar', backgroundColor, {
          'sidebar-shadow': enableSidebarShadow,
        })}
      >
        <HeaderLogo />
        <PerfectScrollbar>
          <div className="app-sidebar__inner">
            <Nav />
          </div>
        </PerfectScrollbar>
        <div
          className={cx('app-sidebar-bg', backgroundImageOpacity)}
          style={{
            backgroundImage: enableBackgroundImage
              ? `url(${backgroundImage})`
              : null,
          }}
        />
      </div>
    </>
  );
}

export default AppSidebar;
