import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { salesLeadSaga } from './saga';
import { SalesLeadState, SalesStatus, SalesLeadStatusUpdateParams, SALES_LEAD_UPSELL_CATEGORIES, SalesLeadUpsells,IUpdateAccountWeddingDetails } from './types';

export const initialState: SalesLeadState = {
  loading: true,
  bookedConfirmUserList: [],
  weddingDetailsGroupSize: [],
  salesSourceList: [],
  status: {
    list: [] as SalesStatus[],
    loading: false,
    error: null
  },
  closedReasons: {
    list: [],
    loading: false,
    error: null
  },
  weddingDetails: null,
  userActivity: null,
  salesLeadDetails: {
    salesLeadId: null,
    city: null,
    destination: null,
    resort: null,
    venue: null,
    weddingPackage: null,
    pageLastVisited: '',
    dateLastVisited: null
  },
  isUpdatingAssignedAgent: false,
  salesLeadAccountDetails: {
    anticipatedWeddingDate: null,
    spouseNameOne: null,
    spouseNameTwo: null,
    weddingSpouseTwoRole: null,
    lgbtqWedding: null,
    estimatedBudget: null,
    confirmedWeddingDatetime: null,
    weddingConfirmationNumber: null,
    assignedWeddingCoordinatorName: null,
    assignedWeddingCoordinatorEmail: null,
    weddingPlanningSiteLink: null,
    copyOfConfirmation: '',
    phone: null,
    email: null,
    name: null,
    estimatedGuest: '',
  },
  authUsers: [],
  leadInfoCRM: null,
  impersonationLink: null,
  // upsells: null,
  loadingUpdateWeddingDetails: false,
  notes:[]
};

const slice = createSlice({
  name: 'salesLead',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    loadSalesLeadData(state) {
      state.loading = true;
    },
    loadWeddingDateData(state) {
      state.loading = true;
    },
    setBookedConfirmUserList(state, action) {
      state.bookedConfirmUserList = action.payload;
    },
    setWeddingDetailsGroupSize(state, action) {
      state.weddingDetailsGroupSize = action.payload;
    },
    setSalesSourceList(state, action) {
      state.salesSourceList = action.payload;
    },
    setSalesStatusList(state, action: PayloadAction<SalesLeadState["status"]>) {
      state.status = {
        list: action.payload.list,
        loading: action.payload.loading || false,
        error: action.payload.error || null
      }
    },
    getSalesStatusList(state) {
      state.status = {
        loading: true,
        error: null,
        list: []
      }
    },
    getClosedReasonAll(state) {
      state.closedReasons = {
        loading: true,
        error: null,
        list: []
      }
    },
    setClosedReasons(state, action: PayloadAction<SalesLeadState["closedReasons"]>) {
      state.closedReasons = {
        list: action.payload.list,
        loading: action.payload.loading || false,
        error: action.payload.error || null
      }
    },
    updateSalesLeadStatus(_state, _action: PayloadAction<SalesLeadStatusUpdateParams>) { },    
    loadWeddingDetails(state, action) {
      state.weddingDetails = null;
    },
    setWeddingDetails(state, action) {
      state.weddingDetails = action.payload;
    },
    loadUserActivity(state) {
      state.userActivity = null;
    },
    setUserActivity(state, action) {
      state.userActivity = action.payload;
    },
    loadSalesLeadDetails(state, action) {
      state.loading = true;
    },
    setSalesLeadDetails(state, action) {
      state.salesLeadDetails = action.payload;
      state.loading = false;
    },
    loadSalesLeadAccountDetails(state, action: PayloadAction<number>) {
      state.loading = true;
    },
    setSalesLeadAccountDetails(state, action) {
      state.salesLeadAccountDetails = {
        ...state.salesLeadAccountDetails,
        anticipatedWeddingDate: action.payload.anticipatedWeddingDate || null,
        estimatedGuest: action.payload.estimatedGuest,
        spouseNameOne: action.payload.spouseNameOne,
        spouseNameTwo: action.payload.spouseNameTwo,
        weddingSpouseTwoRole: action.payload.weddingSpouseTwoRole,
        lgbtqWedding: action.payload.lgbtqWedding,
        estimatedBudget: action.payload.estimatedBudget,
        confirmedWeddingDatetime: action.payload.confirmedWeddingDatetime,
        weddingConfirmationNumber: action.payload.weddingConfirmationNumber,
        assignedWeddingCoordinatorName: action.payload.assignedWeddingCoordinatorName,
        assignedWeddingCoordinatorEmail: action.payload.assignedWeddingCoordinatorEmail,
        weddingPlanningSiteLink: action.payload.weddingPlanningSiteLink,
        copyOfConfirmation: action.payload.copyOfConfirmation,
      };
      state.loading = false;
    },
    loadAuthUsers(state) {
      state.loading = true;
    },
    setAuthUsers(state, action) {
      state.authUsers = action.payload;
      state.loading = false;
    },
    loadLeadInfoCRM(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    setLeadInfoCRM(state, action) {
      state.leadInfoCRM = action.payload;
      state.loading = false;
    },
    assignLead(state, action) {
      state.loading = true;
    },
    setIsUpdatingAssignLead(state,action:PayloadAction<boolean>) {
      state.isUpdatingAssignedAgent = action.payload;
    },
    assignCRMLead(state,_action: PayloadAction<{salesLeadId:string,email:string,leadId:string,assignedAgentId:string, agentName:string|null}>) {
    },
    updateStatus(state, action) {
      state.loading = true;
    },
    setLoadingUpdateWeddingDetails(state,action: PayloadAction<boolean>) {
      state.loadingUpdateWeddingDetails = action.payload
    },
    updateSalesLeadAccountWeddingDetails(state,_action: PayloadAction<IUpdateAccountWeddingDetails>) {
      state.loadingUpdateWeddingDetails = true;
    },
    loadImpersonationLink(state, action) {
      state.loading = true;
      state.impersonationLink = null;
    },
    setImpersonationLink(state, action) {
      state.impersonationLink = action.payload;
      state.loading = false;
    },
    loadNotesForUser(state, action){
      state.loading=true;
    },
    setNotes(state,action){
      state.notes=action.payload;
      state.loading=false;
    },
    addNote(state){
      state.loading=true;
    },
  },
});

export const { actions: SalesLeadActions } = slice;

export const useSalesLeadSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: salesLeadSaga });
  return { actions: slice.actions };
};
