import React from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { useField } from 'formik';
import '../../assets/app.scss';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

export interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  label?: string;
  getSelectDate?: (date: Date) => void;
  hideAddon?: boolean
}

export const CustomDatePicker: React.FC<Props> = ({
  label = null,
  name,
  getSelectDate = null,
  className,
  hideAddon = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <>
      {label ? <label htmlFor={label}>{label}</label> : null}

      <InputGroup>
        {!hideAddon && <InputGroupAddon addonType="prepend">
          <div className="input-group-text">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </div>
        </InputGroupAddon>}

        <DatePicker
          name={name}
          onChange={date => {
            helpers.setValue(date);
            getSelectDate && getSelectDate(date);
          }}
          className={`
          ${className}
          ${
            meta.touched && meta.error
              ? 'is-invalid form-control'
              : 'form-control'
          }
          `}
          onInputClick={() => helpers.setTouched(true)}
          selected={field.value ? new Date(field.value) : null}
          showPreviousMonths
          showYearDropdown
          {...props}
        />
      </InputGroup>

      {meta.touched && meta.error ? (
        <div className="invalid-validation">{meta.error}</div>
      ) : null}
    </>
  );
};
