/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import SweetAlert from 'sweetalert-react';
import {
  Button,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import classnames from 'classnames';
import _ from 'lodash';
import arrayChunk from 'lodash.chunk';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { toast } from 'react-toastify';
import Details from './details';
import StaticPricing from './staticpricing';
import hotel from '../../../../api/hotelContent';

// import UploadImage from '../../HotelContent/uploadImage';
import ImageCropper from '../imageCropper';

class ManageRoomType extends React.Component {
  deleteRoomId;

  deleteImageUrl;

  // state = ManageRoomType.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      modal: false,
      showImageUpload: false,
      activeTab: '1',
      roomImage: [],
      roomImageDetails: [],
      chunkedData: [],
      isOpen: false,
      photoIndex: 0,
      hotelId: '',
      roomtypedetails: {},
      hotelName: '',
      loading: false,
      showAddImage: false,
      roomImageArr: [],
      imageUrl: '',
      imageName: '',
      altTagText: '',
      imageDisplayOrder: '',
      imageUrlrequired: false,
      imageNamerequired: false,
      altTagTextrequired: false,
      imageDisplayOrderrequired: false,
      imageOrderExist: false,
      selectedImage: '',
      selectedImageIndex: -1,
      selectedImageOperation: '',
    };

    this.togglepopup = this.togglepopup.bind(this);
    this.togglepopupS3Image = this.togglepopupS3Image.bind(this);
  }

  togglepopup() {
    this.setState({
      modal: false,
    });
  }

  togglepopupS3Image() {
    this.setState({
      showImageUpload: false,
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  backToRoomType = () => {
    const route = `/hotels/hotel-content-manage/${this.state.hotelId}`;

    this.props.history.push({
      pathname: route,
      state: {
        from: 'roomdetails',
      },
    });
    // this.props.history.push("/hotels/hotel-content-manage/"+ this.state.hotelId);
  };

  loadimages = () => {
    // console.log(this.props.match);
    const { id } = this.props.match.params;

    hotel.roomtype.roomtypebyId(id).then((response) => {
      this.setState({ roomtypedetails: response });
      this.setState({ hotelName: response.hotelName });
      this.setState({ hotelId: response.hotelID });
      hotel.roomtype.roomImagesList(response.hotelID).then((response) => {
        this.setState({ roomImageArr: response });
      });
    });
    this.getImages();
  };

  getImages = () => {
    const { id } = this.props.match.params;
    hotel.roomtype.images(id).then((response) => {
      const result = _.map(response, 'imageURL');
      this.setState({ roomImage: result });
      this.setState({ roomImageDetails: response });
      this.setState({ chunkedData: arrayChunk(response, 3) });
    });
  };

  componentDidMount() {
    this.loadimages();
  }

  showConfirmationToDelete = (id, imgUrl) => {
    this.deleteRoomId = id;
    this.deleteImageUrl = imgUrl;
    this.setState({ show: true });
  };

  deleteRoom = () => {
    const { id } = this.props.match.params;
    hotel.hotelcontent
      .deleteImages({
        Id: this.deleteRoomId,
        HotelID: id,
        ImageURL: this.deleteImageUrl,
      })
      .then((response) => {
        toast.success(response);
        this.getImages();
      });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;

    this.setState((pS) => {
      const list = [...pS.roomImageDetails];
      list[index][name] = value;
      return { roomImageDetails: list };
    });

    // this.setState({ roomImage: this.state.roomImageDetails.imageURL });

    this.setState((pS) => ({
      chunkedData: arrayChunk(pS.roomImageDetails, 3),
    }));
  };

  handleSelectImage = (value) => {
    this.setState((ps) => {
      const list = [...ps.roomImageDetails];
      list[ps.selectedImageIndex].imageURL = value;
      return { roomImageDetails: list };
    });

    this.setState((pS) => ({
      chunkedData: arrayChunk(pS.roomImageDetails, 3),
    }));
  };

  updateImage = (index) => {
    const image = this.state.roomImageDetails[index];
    const exceptList = _.reject(
      this.state.roomImageDetails,
      (o) => o.id === image.id
    );
    const isOrderExist = exceptList.some(
      (d) => d.imageDisplayOrder === image.imageDisplayOrder
    );

    if (isOrderExist) {
      toast.error('Image display order already exists.');
      return;
    }
    hotel.hotelcontent.updateImages(image).then(() => {
      this.setState((pS) => {
        const arr = pS.roomImage;
        arr[index] = image.imageURL;
        return { roomImage: arr };
      });
      toast.success('Room image details updated successfully.');
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleImageSubmit = () => {
    if (!this.isFormValid()) return;
    this.setState({ loading: true });
    const data = {
      roomTypeID: this.props.match.params.id,
      imageName: this.state.imageName,
      altTagText: this.state.altTagText,
      imageURL: this.state.imageUrl,
      imageDisplayOrder: this.state.imageDisplayOrder,
    };

    hotel.hotelcontent.saveImages(data).then(() => {
      this.setState({ loading: false });
      this.setState({ showAddImage: false });
      toast.success('Image saved successfully.');
      this.getImages();
    });
  };

  isFormValid = () => {
    if (this.isFormEmpty(this.state)) {
      return false;
    }
    return true;
  };

  isFormEmpty({ imageName, imageUrl, imageDisplayOrder }) {
    if (!imageName.length) {
      this.setState({ imageNamerequired: true });
    } else {
      this.setState({ imageNamerequired: false });
    }

    if (!imageUrl.length) {
      this.setState({ imageUrlrequired: true });
    } else {
      this.setState({ imageUrlrequired: false });
    }

    // if (!altTagText.length) {
    //     this.setState({ altTagTextrequired: true });
    // } else {
    //     this.setState({ altTagTextrequired: false });
    // }

    if (!imageDisplayOrder.length) {
      this.setState({ imageDisplayOrderrequired: true });
      this.setState({ imageOrderExist: false });
    } else {
      this.setState({ imageDisplayOrderrequired: false });
      const isOrderExist = this.state.roomImageDetails.some(
        (img) => img.imageDisplayOrder === imageDisplayOrder
      );
      if (isOrderExist) {
        this.setState({ imageOrderExist: true });
        // eslint-disable-next-line no-param-reassign
        imageDisplayOrder = '';
      } else {
        this.setState({ imageOrderExist: false });
      }
    }

    return !imageName.length || !imageUrl.length || !imageDisplayOrder.length;
  }

  handleSelect = (e) => {
    const { value } = e.target;

    this.setState({ selectedImage: value });
  };

  async submitS3Image(param) {
    const roomId = param.this.props.match.params.id;
    const imageUrl = await hotel.hotelcontent.uploadRoomS3Photo(
      param.blob,
      roomId
    );

    if (param.selectedImageOperation === 'update') {
      const list = [...param.this.state.roomImageDetails];
      list[param.this.state.selectedImageIndex].imageURL = imageUrl;
      param.this.setState({ roomImageDetails: list, showImageUpload: false });
      param.this.setState({
        chunkedData: arrayChunk(param.this.state.roomImageDetails, 3),
      });
    } else if (param.selectedImageOperation === 'insert') {
      param.this.setState({ imageUrl, showImageUpload: false });
    }
  }

  render() {
    const {
      imageNamerequired,
      imageUrlrequired,
      imageDisplayOrderrequired,
      imageOrderExist,
    } = this.state;
    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text="Are you sure to delete this image?"
          showCancelButton
          onConfirm={ () => {
            this.deleteRoom();
            this.setState({ show: false });
          } }
          onCancel={ () => {
            this.setState({ show: false });
          } }
          onClose={ () => console.log('close') }
        />
        <div className="app-inner-layout">
          <div className="app-inner-layout__header-boxed p-0">
            <div className="app-inner-layout__header page-title-icon-rounded text-white bg-midnight-bloom mb-4">
              <div className="app-page-title">
                <div className="page-title-wrapper">
                  <div className="page-title-heading">
                    <div className="page-title-icon">
                      <i className="lnr-apartment icon-gradient bg-sunny-morning " />
                    </div>
                    <h1>
                      {this.state.roomtypedetails.roomTypeFriendlyName}
                      <div className="page-title-subheading">
                        {this.state.hotelName}
                      </div>
                    </h1>
                  </div>
                  <div className="page-title-actions" />
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="mb-2 mr-2 btn-pill btn btn-outline-primary"
            onClick={ () => this.backToRoomType() }
          >
            Back to Room Type List
          </button>

          <Card className="main-card mt-3 mb-3">
            <CardHeader>
              <Nav justified>
                <NavItem>
                  <NavLink
                    href="#"
                    className={ classnames({
                      active: this.state.activeTab === '1',
                    }) }
                    onClick={ () => {
                      this.toggle('1');
                    } }
                  >
                    Room Images
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={ classnames({
                      active: this.state.activeTab === '2',
                    }) }
                    onClick={ () => {
                      this.toggle('2');
                    } }
                  >
                    Description
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={ classnames({
                      active: this.state.activeTab === '3',
                    }) }
                    onClick={ () => {
                      this.toggle('3');
                    } }
                  >
                    Static Pricing
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={ this.state.activeTab }>
                <TabPane tabId="1">
                  {this.state.isOpen && (
                    <Lightbox
                      mainSrc={ this.state.roomImage[this.state.photoIndex] }
                      nextSrc={
                        this.state.roomImage[
                          (this.state.photoIndex + 1)
                            % this.state.roomImage.length
                        ]
                      }
                      prevSrc={
                        this.state.roomImage[
                          (this.state.photoIndex
                            + this.state.roomImage.length
                            - 1)
                            % this.state.roomImage.length
                        ]
                      }
                      onCloseRequest={ () => this.setState({ isOpen: false }) }
                      onMovePrevRequest={ () => this.setState((pS) => ({
                        photoIndex:
                            (pS.photoIndex + pS.roomImage.length - 1)
                            % pS.roomImage.length,
                      })) }
                      onMoveNextRequest={ () => this.setState((pS) => ({
                        photoIndex: (pS.photoIndex + 1) % pS.roomImage.length,
                      })) }
                    />
                  )}

                  <Modal
                    isOpen={ this.state.showImageUpload }
                    toggle={ this.togglepopupS3Image }
                    className="modal-xl"
                  >
                    <ModalHeader toggle={ this.togglepopupS3Image }>
                      Upload Image
                    </ModalHeader>
                    <ModalBody
                      style={ {
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                      } }
                    >
                      <ImageCropper
                        this={ this }
                        selectedImageOperation={
                          this.state.selectedImageOperation
                        }
                        submitS3Image={ this.submitS3Image }
                      />
                      {/* <UploadImage this={this} selectedImageOperation={this.state.selectedImageOperation} submitS3Image={this.submitS3Image} /> */}
                    </ModalBody>
                    <ModalFooter />
                  </Modal>

                  <Modal
                    isOpen={ this.state.modal }
                    toggle={ this.togglepopup }
                    className="modal-xl"
                  >
                    <ModalHeader toggle={ this.togglepopup }>
                      BedsOnline Images
                    </ModalHeader>
                    <ModalBody
                      style={ {
                        maxHeight: 'calc(100vh - 210px)',
                        overflowY: 'auto',
                      } }
                    >
                      <div className="row">
                        {this.state.roomImageArr.length > 0
                          && this.state.roomImageArr.map((value, index) => (
                            <div className="col-md-3" key={ index }>
                              <div className="custom-control custom-radio image-checkbox mb-3">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  id={ index }
                                  onChange={ (e) => this.handleSelect(e) }
                                  name="ck2"
                                  value={ value }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={ index }
                                >
                                  <img
                                    src={ value }
                                    alt="#"
                                    className="img-fluid"
                                  />
                                </label>
                              </div>
                            </div>
                          ))}
                        {this.state.roomImageArr.length === 0 && (
                          <span className="ml-5 mt-3 mb-3">
                            No image found. Either bedonlineId is not set or the
                            bedonlineId is not accurate.
                          </span>
                        )}
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      {this.state.roomImageArr.length > 0 && (
                        <Button
                          color="primary"
                          onClick={ () => {
                            if (
                              this.state.selectedImageOperation === 'insert'
                            ) {
                              this.setState((pS) => ({
                                imageUrl: pS.selectedImage,
                                modal: false,
                              }));
                            } else if (
                              this.state.selectedImageOperation === 'update'
                            ) {
                              this.handleSelectImage(
                                this.state.selectedImage,
                                this.state.selectedImageIndex
                              );
                              this.setState({ modal: false });
                            }
                          } }
                        >
                          Select Image
                        </Button>
                      )}
                      <Button
                        color="danger"
                        onClick={ () => {
                          this.setState({ modal: false });
                        } }
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <div className="container">
                    {this.state.chunkedData.map((row, rowIndex) => (
                      <div key={ rowIndex } className="row mb-4">
                        {row.map((col, colIndex) => (
                          <div
                            className="col-lg-4 d-none d-lg-block"
                            key={ colIndex + rowIndex }
                          >
                            <div className="card">
                              <div className="card-body">
                                <FormGroup>
                                  Image Name
                                  <input
                                    type="text"
                                    name="imageName"
                                    maxLength="200"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.imageName }
                                    className="form-control mb-2"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <img
                                    onClick={ () => this.setState({ isOpen: true }) }
                                    src={ col.imageURL }
                                    className="imagesize hover"
                                    alt={ col.altTagText }
                                    onError={ (e) => {
                                      e.target.onerror = null;
                                      e.target.src = 'https://via.placeholder.com/350x229?text=No_Image_found';
                                    } }
                                  />
                                </FormGroup>
                                <FormGroup>
                                  Image Url{' '}
                                  <input
                                    type="text"
                                    name="imageURL"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.imageURL }
                                    className="form-control"
                                  />
                                  <button
                                    type="button"
                                    className="mt-2 mb-2  border-0  btn btn-outline-primary"
                                    onClick={ () => this.setState({
                                      selectedImage: '',
                                      selectedImageOperation: 'update',
                                      selectedImageIndex:
                                          rowIndex * 3 + colIndex,
                                      modal: true,
                                    }) }
                                  >
                                    Browse BOL Image
                                  </button>
                                  <button
                                    type="button"
                                    className="mt-2  mb-2 border-0  btn btn-outline-primary"
                                    onClick={ (e) => {
                                      e.preventDefault();
                                      this.setState({
                                        selectedImageOperation: 'update',
                                        showImageUpload: true,
                                        selectedImageIndex:
                                          rowIndex * 3 + colIndex,
                                      });
                                    } }
                                  >
                                    Upload Image
                                  </button>
                                </FormGroup>
                                <FormGroup>
                                  Alt Text
                                  <input
                                    type="text"
                                    name="altTagText"
                                    maxLength="200"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.altTagText }
                                    className="form-control"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <label htmlFor="imageDisplayOrder">
                                    Image Display Order
                                  </label>
                                  <input
                                    type="number"
                                    name="imageDisplayOrder"
                                    id="imageDisplayOrder"
                                    onChange={ (e) => this.handleInputChange(
                                      e,
                                      rowIndex * 3 + colIndex
                                    ) }
                                    value={ col.imageDisplayOrder }
                                    className="form-control"
                                  />
                                </FormGroup>
                                <button
                                  type="button"
                                  className="mt-2 mb-2 mr-2 btn-icon btn btn-success"
                                  onClick={ () => this.updateImage(rowIndex * 3 + colIndex) }
                                >
                                  <i className="pe-7s-edit btn-icon-wrapper">
                                    {' '}
                                  </i>
                                  Update
                                </button>
                                <button
                                  type="button"
                                  className="mt-2 mb-2 mr-2 btn-icon btn btn-danger"
                                  onClick={ () => this.showConfirmationToDelete(
                                    col.id,
                                    col.imageURL
                                  ) }
                                >
                                  <i className="pe-7s-trash btn-icon-wrapper">
                                    {' '}
                                  </i>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <Button
                    color="primary"
                    onClick={ () => {
                      this.setState({ showAddImage: true });
                      this.setState({
                        imageName: '',
                        imageUrl: '',
                        altTagText: '',
                        imageDisplayOrder: '',
                      });
                      window.location.href = '#divImage';
                    } }
                  >
                    Add New Image
                  </Button>

                  <div id="divImage">
                    <Card
                      className={
                        this.state.showAddImage
                          ? 'mb-4 mt-4'
                          : 'mb-4 mt-4 d-none'
                      }
                    >
                      <CardHeader>Add New Room Type Image</CardHeader>
                      <CardBody>
                        <FormGroup>
                          <label htmlFor="imageName">Image Name</label>
                          <input
                            type="text"
                            value={ this.state.imageName }
                            className={
                              imageNamerequired
                                ? 'is-invalid form-control'
                                : 'form-control'
                            }
                            maxLength="200"
                            id="imageName"
                            name="imageName"
                            onChange={ this.handleChange }
                          />

                          {imageNamerequired && (
                            <div className="invalid-validation">
                              Image Name is required
                            </div>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <label htmlFor="imageUrl">Image Url</label>
                          <input
                            type="text"
                            className={
                              imageUrlrequired
                                ? 'is-invalid form-control'
                                : 'form-control'
                            }
                            value={ this.state.imageUrl }
                            name="imageUrl"
                            onChange={ this.handleChange }
                          />

                          {imageUrlrequired && (
                            <div className="invalid-validation">
                              Image Url is required
                            </div>
                          )}

                          <button
                            type="button"
                            className="mt-2 border-0 btn btn-outline-primary"
                            onClick={ () => this.setState({
                              modal: true,
                              selectedImageOperation: 'insert',
                            }) }
                          >
                            Browse BOL Image
                          </button>

                          <button
                            type="button"
                            className="mt-2 border-0  btn btn-outline-primary"
                            onClick={ (e) => {
                              e.preventDefault();
                              this.setState({
                                selectedImageOperation: 'insert',
                                showImageUpload: true,
                              });
                            } }
                          >
                            Upload Image
                          </button>
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="altTagText">Alt Tag Text</label>
                          <input
                            type="text"
                            value={ this.state.altTagText }
                            maxLength="200"
                            className="form-control"
                            name="altTagText"
                            onChange={ this.handleChange }
                          />
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="imageDisplayOrder">
                            Image Display Order
                          </label>
                          <input
                            type="number"
                            name="imageDisplayOrder"
                            value={ this.state.imageDisplayOrder }
                            id="imageDisplayOrder"
                            onChange={ this.handleChange }
                            className={
                              imageDisplayOrderrequired || imageOrderExist
                                ? 'is-invalid form-control'
                                : 'form-control'
                            }
                          />
                          {imageDisplayOrderrequired && (
                            <div className="invalid-validation">
                              Image display order is required
                            </div>
                          )}
                          {imageOrderExist && (
                            <div className="invalid-validation">
                              Image display order already exists.
                            </div>
                          )}
                        </FormGroup>
                        {this.state.imageUrl !== '' && (
                          <FormGroup>
                            <label htmlFor="img">Image Preview</label>
                            <br />
                            <img
                              src={ this.state.imageUrl }
                              className="img-fluid"
                              name="img"
                              alt={ this.state.altTagText }
                            />
                          </FormGroup>
                        )}
                        <button
                          type="submit"
                          onClick={ () => this.handleImageSubmit() }
                          className="btn btn-primary"
                        >
                          Save
                        </button>

                        <button
                          type="submit"
                          onClick={ () => this.setState({
                            selectedImage: '',
                            showAddImage: false,
                            imageNamerequired: false,
                            imageUrlrequired: false,
                            imageDisplayOrderrequired: false,
                          }) }
                          className="btn btn-danger ml-3"
                        >
                          Close
                        </button>
                      </CardBody>
                    </Card>
                  </div>
                </TabPane>

                <TabPane tabId="2">
                  {Object.keys(this.state.roomtypedetails).length > 0 && (
                    <Details roomImage={ this.state.roomtypedetails } />
                  )}
                </TabPane>

                <TabPane tabId="3">
                  {this.state.hotelId && (
                    <StaticPricing hotelId={ this.state.hotelId } />
                  )}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}

export default ManageRoomType;
