import React from 'react';
import { Container } from 'reactstrap';
import { Route } from 'react-router-dom';
import TestErrors from 'Pages/Error/TestError';
import GroupLeaderList from './List/index';
// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';
import PageTitle from '../../Layout/AppMain/PageTitle';
import GroupLeaderDetails from './Details';

const Layout = ({ children }) => (
  <Container fluid>
    <div>
      <PageTitle
        heading="Group Leader Management"
        icon="pe-7s-users icon-gradient bg-tempting-azure"
      />
    </div>
    {children}
  </Container>
);

function GroupLeader() {
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Route path="/group">
              <Layout>
                <Route
                  path="/group/group-leader/:groupId/user/:userId"
                  component={GroupLeaderDetails}
                />
                <Route path="/group/group-leader" exact component={GroupLeaderList} />
                <Route path="/group/error" component={TestErrors} />
              </Layout>
            </Route>
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  );
}

export default GroupLeader;
