import React from "react"

import { Column } from 'common/Table';
import { ITransferSupplier } from '../../../slice/types';

export const COLUMNS: Array<Column<ITransferSupplier>> = [
    {
      Header: 'Name',
      accessor: 'name',
      align: 'center',
    },
    {
      Header: 'Email',
      accessor: 'email',
      align: 'left',
    },
    {
      Header: 'Transfer Type',
      accessor: 'transferType',
    },
    {
      Header: 'Supplier',
      accessor: 'supplier',
    },
  ]