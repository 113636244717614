import {
  Button,
  FormGroup,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  Label,
  Input,
} from 'reactstrap';
import { useState, useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { sortDate, formatDistanceToNow, formatDateWithConfig } from "common/utils/dateUtils"

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useWeddingDateSlice } from '../../slice';
import { selectRequestHistory, selectWeddingDetailIdentifier } from '../../slice/selectors';

import { IWeddingRequestHistory } from '../../slice/types';

const fields = [
  {
    label: 'Resort Property Chosen',
    accessor: 'resort',
  },
  {
    label: 'Preferred Venue',
    accessor: 'venue',
  },
  {
    label: 'Wedding Package Chosen',
    accessor: 'weddingPackage',
  },
  {
    label: 'Wedding Date',
    accessor: 'weddingDate',
    type: 'date',
  },
  {
    label: 'Preferred Ceremony Time',
    accessor: 'weddingTime',
  },
  {
    label: 'Alternative Wedding Date',
    accessor: 'altWeddingDate',
    type: 'date',
  },
  {
    label: 'Alternative Wedding Time',
    accessor: 'altWeddingTime',
  },
  {
    label: 'Wedding Date Status',
    accessor: 'weddingDateStatus',
  },
];

const TimeLine = () => {
  const dispatch = useAppDispatch();
  const { actions } = useWeddingDateSlice();
  const items = useAppSelector(selectRequestHistory);
  const { id } = useAppSelector(selectWeddingDetailIdentifier);
  
  const [currentHistoryItem, setCurrentHistoryItem] =
    useState<IWeddingRequestHistory>(null);

  const closeModal = () => setCurrentHistoryItem(null);

  const formattedDate = (
    date: string | Date,
    options = { format: 'MM/dd/yyyy hh:mm aaa' },
    ) => formatDateWithConfig(date, options.format);
    
  useEffect(() => {
    id && dispatch(actions.getWeddingRequestHistoryList(id));
  }, [dispatch,actions,id]);

  const sorted = useMemo(() => {
    const classArray = [
      'dot-danger',
      'dot-success',
      'dot-primary',
      'dot-info',
      'dot-dark',
      'dot-warning',
    ];
    if(!items.length) return []
    return [...items].sort((i1,i2) => sortDate(i2.createdDate,i1.createdDate)).map((item, i) => {
      const dotclass = classArray[i % classArray.length];
      const relativeTime = formatDistanceToNow(item.createdDate);
      return (
        <VerticalTimelineElement
          key={i}
          className={`vertical-timeline-item ${dotclass}`}
        >
          <h4 className="timeline-title">
            <Button
              className="p-0"
              color={'link'}
              onClick={() => setCurrentHistoryItem(item)}
            >
              {item.narrationText}
            </Button>
            {i === 0 && <span className="badge badge-success ml-2">NEW</span>}
          </h4>
          <p>
            {`${relativeTime}`}
            {` | `}
            <span className="text-success">
              {formattedDate(item.createdDate)}
            </span>
          </p>
        </VerticalTimelineElement>
      );
    });
  }, [items]);

  if (!items.length) return null;

  return (
    <>
      <VerticalTimeline
        layout="1-column"
        className="vertical-time-simple vertical-without-time"
      >
        {sorted}
      </VerticalTimeline>
      {currentHistoryItem && (
        <Modal onClosed={closeModal} isOpen={true} size={'lg'}>
          <ModalHeader
            cssModule={{
              'modal-title': 'flex-grow-1',
            }}
          >
            <p>Wedding Request History Details</p>
            <Row>
              <Col className="text-nowrap">
                {currentHistoryItem.narrationText}
              </Col>
              <Col className="text-right">
                {formattedDate(currentHistoryItem.createdDate)}
              </Col>
            </Row>
          </ModalHeader>
          <ModalBody>
            <div className="w-75 m-auto">
              {fields.map(field => {
                const isDateField = field.type === 'date';
                const value = currentHistoryItem[field.accessor];
                return (
                  <FormGroup key={field.label}>
                    <Row className="mb-4">
                      <Col md={6}>
                        <Label htmlFor={field.label}>{field.label}</Label>
                      </Col>
                      <Col md={6}>
                        <InputGroup>
                          <Input
                            readOnly
                            id={field.label}
                            value={
                              isDateField
                                ? formattedDate(value, { format: 'MM/dd/yyyy' })
                                : value
                            }
                          />
                          {isDateField && (
                            <InputGroupAddon addonType="append">
                              <div className="input-group-text">
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </div>
                            </InputGroupAddon>
                          )}
                        </InputGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                );
              })}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={closeModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default TimeLine;
