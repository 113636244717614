import React from 'react';
import '../../assets/app.scss';
import { useField } from 'formik';

interface Props {
  donotShowlabel?: boolean;
  donotShowSelect?: Boolean;
  placeholder: string;
  label: string;
  options: { text: string; value: string }[];
  name: string;
  onChange?: (value: string) => void;
}

export const SelectInput: React.FC<Props> = ({
  label,
  options,
  placeholder,
  onChange = null,
  donotShowSelect,
  donotShowlabel,
  name,
}) => {
  const [field, meta, helpers] = useField(name);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const item = e.target.value;
    onChange && onChange(item);
    helpers.setValue(item);
  };
  return (
    <>
      {!donotShowlabel && <label htmlFor="selectInput">{label}</label>}
      <select
        className={
          meta.touched && meta.error
            ? 'is-invalid form-control'
            : 'form-control'
        }
        value={field.value || ''}
        onChange={handleChange}
        onBlur={() => helpers.setTouched(true)}
        placeholder={placeholder}
        name="selectInput"
      >
        {!donotShowSelect && (
          <option disabled value="">
            --Select--
          </option>
        )}
        {options.map(team => (
          <option key={team.value} value={team.value}>
            {team.text}
          </option>
        ))}
      </select>

      {meta.touched && meta.error ? (
        <div className="invalid-validation">{meta.error}</div>
      ) : null}
    </>
  );
};
