/* eslint-disable no-case-declarations */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppConfig } from 'config';
import { axiosFailedResponsesHandler as axiosHandleFailedResponses } from './axiosFailedResponsesHandler';

const destifyAdminApiAxiosClient = axios.create();
const destifyServicesApiAxiosClient = axios.create();
const destifyLegacyDashboardApiAxiosClient = axios.create();
const destifySalesJourneyApiAxiosClient = axios.create();

destifyAdminApiAxiosClient.defaults.baseURL = AppConfig.Api.AdminBackendApi.BaseUrl;
destifyServicesApiAxiosClient.defaults.baseURL = AppConfig.Api.DestifyServicesApi.BaseUrl;
destifyLegacyDashboardApiAxiosClient.defaults.baseURL = AppConfig.Api.LegacyLiveBookingApi.BaseUrl;
destifySalesJourneyApiAxiosClient.defaults.baseURL = AppConfig.Api.SalesJourneyApi.BaseUrl;

const responseBody = response => response.data;


destifySalesJourneyApiAxiosClient.interceptors.request.use(config => {
  const newConfig = { ...config };
  const loggedInUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
  const apiKey = AppConfig.Api.SalesJourneyApi.ApiToken;
  if (apiKey) {
    newConfig.headers['Apikey'] = apiKey;
  }
  if (loggedInUser?.profile) {
    newConfig.headers['AdminUserId'] = loggedInUser.profile["custom:DestifyLegacyUserId"];
  }
  return newConfig;
});

destifyLegacyDashboardApiAxiosClient.interceptors.request.use(config => {
  const newConfig = { ...config };
  const apiToken = AppConfig.Api.LegacyLiveBookingApi.ApiToken;
  if (apiToken) {
    newConfig.headers.Authorization = `Token ${apiToken}`;
  }
  return newConfig;
});

destifyAdminApiAxiosClient.interceptors.request.use(config => {
  const newConfig = { ...config };
  const token = localStorage.getItem(
    process.env.REACT_APP_IDENTITY_PROVIDER === 'cognito' ? 'idtoken' : 'token',
  );
  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
});

destifyServicesApiAxiosClient.interceptors.request.use(config => {
  const newConfig = { ...config };
  const apiKey = AppConfig.Api.DestifyServicesApi.ApiToken;
  if (apiKey) {
    newConfig.headers['x-functions-key'] = apiKey;
  }
  return newConfig;
});



const axiosHandleSuccessfulResponses = ({
  data,
  status,
  statusText,
  headers,
  config,
  request,
}: AxiosResponse): AxiosResponse & { pagination?: any } => {
  // all 2xx response codes will come through here

  const isPaginatedResult = headers.Pagination || headers.pagination;
  if (isPaginatedResult) {
    return {
      data,
      pagination: JSON.parse(isPaginatedResult),
      status,
      statusText,
      headers,
      config,
      request,
    };
  }
  return { data, status, statusText, headers, config, request };
};


destifyServicesApiAxiosClient.interceptors.response.use(
  axiosHandleSuccessfulResponses,
  axiosHandleFailedResponses,
);

destifyAdminApiAxiosClient.interceptors.response.use(
  axiosHandleSuccessfulResponses,
  axiosHandleFailedResponses,
);

destifyLegacyDashboardApiAxiosClient.interceptors.response.use(axiosHandleSuccessfulResponses,
  axiosHandleFailedResponses);

destifySalesJourneyApiAxiosClient.interceptors.response.use(axiosHandleSuccessfulResponses,
  axiosHandleFailedResponses);

export const destifyAdminApiRequests = {
  get: (url, axiosConfigParams?: AxiosRequestConfig<any>) =>
    destifyAdminApiAxiosClient
      .get('/api/' + url, axiosConfigParams)
      .then(responseBody),
  post: (url, body, axiosConfigParams?: AxiosRequestConfig<any>) =>
    destifyAdminApiAxiosClient.post('/api/' + url, body,axiosConfigParams).then(responseBody),
  postPagination: (url, body) =>
    destifyAdminApiAxiosClient
      .post('/api/' + url, body)
      .then(response => response),
  put: (url, body?) =>
    destifyAdminApiAxiosClient.put('/api/' + url, body).then(responseBody),
  delete: url =>
    destifyAdminApiAxiosClient.delete('/api/' + url).then(responseBody),
  oDataGet:<T = any, R = AxiosResponse<T>, D = any>(url:string, axiosConfigParams?: AxiosRequestConfig<D>): Promise<R>=>
    destifyAdminApiAxiosClient.get('/odata/' + url, axiosConfigParams),
};

export const destifySalesJourneyApiRequests = {
  get: (url, params?) =>
    destifySalesJourneyApiAxiosClient.get('/api/' + url, { params }).then(responseBody),
  post: (url, body) =>
    destifySalesJourneyApiAxiosClient.post('/api/' + url, body).then(responseBody),
  put: (url, body?) =>
    destifySalesJourneyApiAxiosClient.put('/api/' + url, body).then(responseBody),
  delete: (url, body?) => {
    if (!body) {
      return destifySalesJourneyApiAxiosClient.delete('/api/' + url).then(responseBody);
    }
    return destifySalesJourneyApiAxiosClient.delete('/api/' + url, { data: body }).then(responseBody);
  },

}

export const destifyServicesApiRequests = {
  get: (url, params?) =>
    destifyServicesApiAxiosClient.get(url, { params }).then(responseBody),
  post: (url, body) =>
    destifyServicesApiAxiosClient.post(url, body).then(responseBody),
  postPagination: (url, body) =>
    destifyServicesApiAxiosClient.post(url, body).then(response => response),
  put: (url, body?) =>
    destifyServicesApiAxiosClient.put(url, body).then(responseBody),
  delete: url => destifyServicesApiAxiosClient.delete(url).then(responseBody),
};


//todo: refactor to generic service classes

export const destifyODataAdminApiRequests = {
  getListResponse: url =>
    destifyAdminApiAxiosClient.get('/odata/' + url).then(({ data }) => {
      return { data: data.value, count: data['@odata.count'] || -1 };
    }),
  get: url =>
    destifyAdminApiAxiosClient.get('/odata/' + url).then(({ data }) => {
      return data;
    }),
  post: (url, body) =>
    destifyAdminApiAxiosClient.post('/odata/' + url, body).then(({ data }) => {
      return data;
    }),
};



export const destifyLegacyDashboardApiRequests = {
  get: (url) =>
    destifyLegacyDashboardApiAxiosClient.get(url).then(responseBody),
  post: (url, body?) =>
    destifyLegacyDashboardApiAxiosClient.post(url, body).then(responseBody),
  put: (url, body?) =>
    destifyLegacyDashboardApiAxiosClient.put(url, body).then(responseBody),
  delete: url =>
    destifyLegacyDashboardApiAxiosClient.delete(url).then(responseBody),
}