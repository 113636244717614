import React, { useEffect, useState } from 'react';
import { Button, Container, Card, CardBody } from 'reactstrap';

import PageTitle from 'Layout/AppMain/PageTitle';
import { TransferTypeTable, CreateForm } from './components';

import { useTransferTypeSlice } from '../slice';
import { selectTransferTypes } from '../slice/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Index = () => {
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const { actions } = useTransferTypeSlice();
  const dispatch = useAppDispatch();
  const { items, loading } = useAppSelector(selectTransferTypes);

  useEffect(() => {
    dispatch(actions.loadTransferTypes());
  }, [dispatch, actions]);

  return (
    <>
      <Container fluid>
        <div>
          <PageTitle
            heading="Transfer Type"
            icon="pe-7s-map-2 icon-gradient bg-tempting-azure"
          />
        </div>
        <Card className="main-card mb-3">
          <CardBody>
            <div>
              <div className="d-flex justify-content-end my-4">
                <Button
                  disabled={isCreateFormOpen}
                  onClick={() => setIsCreateFormOpen(true)}
                >
                  Add Transfer Type
                </Button>
              </div>
              <TransferTypeTable data={items} loading={loading} />
            </div>
          </CardBody>
        </Card>
      </Container>
      <CreateForm
        isOpen={isCreateFormOpen}
        onClose={() => setIsCreateFormOpen(false)}
      />
    </>
  );
};

export default Index;
