import React, { useEffect, useState } from 'react';
import { ListGroup, ListGroupItem, Button, Collapse } from 'reactstrap';

import { ClientRoomDetailsCard } from '../ClientRoomDetailsCard';
import { RoomRoles } from '../models/RoomRolesEnum';

import { UsersActions } from '../../slice';
import { selectUserRoomDetails } from '../../slice/selectors';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import Loader from 'react-loaders';

const UserRooms = ({ id, email }) => {
  const dispatch = useAppDispatch();
  const { list, loading } = useAppSelector(selectUserRoomDetails);
  const [activeRoomIds, setActiveRoomIds] = useState([]);
  const [removedRooms,setRemovedRooms] = useState([]);

  useEffect(() => {
    dispatch(UsersActions.getUserRoomDetails({ id }));
  }, [dispatch, id]);

  return (
    <>
      <ListGroup>
        {!list.length && !loading && (
          <ListGroupItem>
            <p>No room association found for user</p>
          </ListGroupItem>
        )}
        {loading && <Loader active type="ball-beat" />}
        {list?.map((room, index) => {
          const isRemoved = removedRooms.some(id => id === room.roomId);
          const isActive = activeRoomIds.some(id => id === room.roomId);
          if(isRemoved) return null;
          return (
            <ListGroupItem key={index}>
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left mr-3">
                    <div className="icon-wrapper border-light rounded m-0">
                      <div className="icon-wrapper-bg bg-light" />
                      <i className="lnr-rocket icon-gradient bg-happy-itmeo" />
                    </div>
                  </div>
                  <div className="widget-content-left">
                    <div className="widget-heading">{room.roomName}</div>
                    <div className="widget-subheading">
                      <span className="text-dark">{room.groupName} </span>
                    </div>
                    <div className="widget-subheading">
                      {room.roomDetail && !isActive && (
                        <Button
                          color="link"
                          onClick={() =>
                            setActiveRoomIds(ids => [...ids, room.roomId])
                          }
                        >
                          View Room Details
                        </Button>
                      )}
                      {room.roomDetail && isActive && (
                        <Button
                          color="link"
                          onClick={() =>
                            setActiveRoomIds(ids => ids.filter(id => id !== room.roomId))
                          }
                        >
                          Hide Room Details
                        </Button>
                      )}
                      <Button
                        color="link"
                        href={room.roomUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Room In CRM
                      </Button>

                      <span className='btn'>Group Leader :  <b>{room.isGroupLeader ? "Yes":"No"}</b></span>
                      
                      <Button
                        variant="text"
                        color="error"
                        style={{ color: 'rgb(211, 47, 47)' }}
                        onClick={() => {
                          console.log(room)
                          dispatch(
                            UsersActions.disassociateRoomFromUser({
                              userEmail: email,
                              userId: room.userId,
                              crmRoomId: room.roomId,
                              roomRole: room.roomRole
                            }),
                          )
                          setRemovedRooms(rooms => [...rooms,room.roomId])
                        }
                        }
                      >
                        Remove Room
                      </Button>
                    </div>
                  </div>

                  <div className="widget-content-right">
                    <div className="badge badge-success">
                      {RoomRoles[room.roomRole]}
                    </div>
                  </div>
                </div>
                {room.roomDetail ? (
                  <Collapse isOpen={isActive}>
                    <ClientRoomDetailsCard roomDetails={room.roomDetail} />
                  </Collapse>
                ) : null}
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </>
  );
};

export default UserRooms;
