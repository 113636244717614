import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

interface IProps {
  paginatedItems?: any[];
  apiData?: any[];
  bookedItems?: any[];
  fileName: string;
  title?: string;
}

export const ExportToExcel = ({
  paginatedItems = null,
  apiData = null,
  bookedItems = null,
  fileName,
  title = 'Export',
}: IProps) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (apiData: unknown[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div className="btn-actions-pane-right">
      <UncontrolledButtonDropdown>
        <DropdownToggle caret className="mb-2 mr-2" color="primary">
          {title}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
          {paginatedItems && (
            <DropdownItem
              onClick={() => exportToExcel(paginatedItems, fileName)}
            >
              Export Page
            </DropdownItem>
          )}
          {apiData && (
            <DropdownItem onClick={() => exportToExcel(apiData, fileName)}>
              Export All
            </DropdownItem>
          )}
          {bookedItems ? (
            <DropdownItem onClick={() => exportToExcel(bookedItems, fileName)}>
              Export Booked
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </div>
  );
};

export const exportToCSV = (
  { dataArray, fileName }: { dataArray: unknown[]; fileName: string },
  options?: XLSX.Sheet2CSVOpts,
) => {
  const fileType = 'text/csv;charset=utf-8';
  const wb = XLSX.utils.json_to_sheet(dataArray);
  const csvBuffer = XLSX.utils.sheet_to_csv(wb, options);
  const data = new Blob([csvBuffer], { type: fileType });
  FileSaver.saveAs(data, `${fileName}.csv`);
};
