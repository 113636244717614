/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Tabs from 'react-responsive-tabs';

import WeddingInfo from './WeddingInfo';
import GroupLeaderTouchPoints from './GroupLeaderTouchPoints';
import WeddingWebsiteInfo from './WeddingWebsiteInfo';
import { RoomsWithGuestDetails } from './GuestList';
import { RSVP } from './RSVP';

type GroupLeaderDetailsParams = {
  groupId: string;
};

function GroupLeaderDetails() {
  const { groupId } = useParams<GroupLeaderDetailsParams>();

  const history = useHistory();

  const tabsContent = useMemo(
    () => [
      { title: 'Wedding Info', content: <WeddingInfo groupId={groupId} /> },
      {
        title: 'Group Leader Touch Points',
        content: <GroupLeaderTouchPoints />,
      },
      {
        title: 'Wedding Website Info',
        content: <WeddingWebsiteInfo groupId={groupId} />,
      },
      { title: 'RSVP', content: <RSVP groupId={groupId} /> },
      {
        title: 'Rooms List',
        content: <RoomsWithGuestDetails groupId={groupId} />,
      },
    ],
    [groupId],
  );

  const getTabs = useMemo(
    () =>
      tabsContent.map((tabItem, index) => ({
        title: tabItem.title,
        getContent: () => tabItem.content,
        key: index,
      })),
    [tabsContent],
  );

  const goToGroupLeaderList = useCallback(
    () => history.push('/group/group-leader'),
    [history],
  );

  return (
    <div>
      <button
        type="button"
        className="mb-3  ml-3 btn-pill btn btn-outline-primary"
        onClick={goToGroupLeaderList}
      >
        Back to the list
      </button>

      <Tabs
        tabsWrapperClass="body-tabs body-tabs-layout body-tabs-big"
        transform={false}
        showInkBar
        items={getTabs}
      />
    </div>
  );
}

export default GroupLeaderDetails;
