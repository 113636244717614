import React, { useMemo } from 'react';

import { COLUMNS } from './columns';
import Table from 'common/Table/Table';
import { ITransferSupplier } from '../../../slice/types';

interface IProps {
  data: ITransferSupplier[];
  loading: boolean;
}

const Index: React.FC<IProps> = ({ data, loading }) => {
  const columns = useMemo(() => COLUMNS, []);
  return <Table columns={columns} data={data} loading={loading} />;
};

export default Index;
