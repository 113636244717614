/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Loader from 'react-loaders';
import {
  FormGroup,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  CardFooter,
} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import { TransitionGroup } from 'react-transition-group';
import * as Yup from 'yup';
import moment from 'moment';
import classnames from 'classnames';
import { addDays, differenceInDays } from 'date-fns';
import { toast } from 'react-toastify';
import { SelectInput } from '../../common/Form/SelectInput';
import { TextField } from '../../common/Form/TextInput';
import { CustomDatePicker } from '../../common/Form/DatePicker';
import { SearchDateOption,RoomStatusOption } from '../../common/options/categoryoption';
import hbsi from '../../api/hbsi';
import queryObject from '../../api/pagination';
import PageTitle from '../../Layout/AppMain/PageTitle';
import HotelList from './hotellist';
import BookingDetails from './booking';

import { InitialValue } from './types';


import hotel from '../../api/hotelContent';
import { axiosFailedResponsesHandler } from "../../api/axiosFailedResponsesHandler";


const initialState: InitialValue = {
  hotelOptions: [] as { text: string; value: string }[],
  checkinDate: new Date(),
  IsSortAsc: true,
  SortBy: 'Hotel',
  hotelChainOptions: [] as { text: string; value: string }[]
};

const loaderType = 'line-scale-pulse-out-rapid';

const validationSchema = Yup.object({
  hotel: Yup.string().required('The hotel selection is required.'),
  datetype: Yup.string().required('The search type is required.'),
  checkin: Yup.string().required('The from date is required.'),
  checkout: Yup.string().required('The from date is required.'),
  roomStatus: Yup.string().required('The room status is required.'),
});

const validationSchema2 = Yup.object({
  bookingNumber: Yup.string().required('The booking number is required.'),
});

const validationSchemaHotelChain = Yup.object({
  hotelChain: Yup.string().required('The hotel chain selection is required.'),
  datetype: Yup.string().required('The search type is required.'),
  checkin: Yup.string().required('The from date is required.'),
  checkout: Yup.string().required('The to date is required.'),
});

function HbsiSearch() {
  const [state, setState] = useState({ ...initialState });

  const [hotels, setHotels] = useState([]);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [activeTab, setActiveTab] = useState('basic');
  const [showModal, setShowModal] = useState(false);

  const updateState = (
    data:
      | Partial<typeof initialState>
      | ((data: typeof initialState) => Partial<typeof initialState>),
  ) => {
    if (typeof data !== 'function') {
      setState(prev => ({ ...prev, ...data }));
    } else {
      const newState = data(state);
      setState(prev => ({ ...prev, ...newState }));
    }
  };

  useEffect(() => {
    const getHotelOptions = async () => {
      setIsLoadingOptions(true);
      try {
        const [hotelOptionResult, hotelChainResult] = await Promise.all([
          hbsi.hbsiSearch.hotelOptionList(),
          getHotelChainList(),
        ]);  
     updateState({
      hotelOptions: hotelOptionResult.map(value => ({
        text: value.hotelName,
        value: value.hbsiid,
      })),
      hotelChainOptions: hotelChainResult.map(value => ({
        text: value.text,
        value: value.value,
      })),
    });
      } catch (err) {
        axiosFailedResponsesHandler(err);
      } finally {
        setIsLoadingOptions(false);
      }
    };
    getHotelOptions();
  }, []);

  const toggleTab = (tab: string) => {
    setActiveTab(activeTab !== tab ? tab : activeTab);
  };

  const getHotelList = async params => {
    const result = await hbsi.hbsiSearch.list(params);
    return result;
  };

  /** this function get the list by hotel chain */
const gethotelListByChainId = async params =>{
  try {
    const result= await hbsi.hbsiSearch.listbyhotelChain(params);
    return result;
  }catch(err){
    axiosFailedResponsesHandler(err);
  }
};

  //** this function get the hotel chain list */
  const getHotelChainList = async() => {
    setIsLoadingOptions(true);
    try {
      const result = await hotel.hotelcontent.hotelchainlist();
   
      return result;
    } catch (err) {
      axiosFailedResponsesHandler(err);
    } finally {
      setIsLoadingOptions(false);
    }
  }

  const getBookingDetails = params =>
    hbsi.hbsiSearch.bookingDetails({ bookingNumber: params.bookingNumber });

  const searchSubmit = async hsbiSearchValue => {
    setIsLoading(true);
    queryObject.HotelCode = hsbiSearchValue.hotel;
    queryObject.DateType = hsbiSearchValue.datetype;
    queryObject.CheckinDate = moment(hsbiSearchValue.checkin).format(
      'YYYY-MM-DD',
    );
    queryObject.SortBy = state.SortBy;
    queryObject.IsSortAsc = state.IsSortAsc;
    queryObject.CheckOutDate = moment(hsbiSearchValue.checkout).format(
      'YYYY-MM-DD',
    );

    queryObject.RoomStatus=hsbiSearchValue.roomStatus;
    const allowReportDays = differenceInDays(
      queryObject.CheckOutDate,
      queryObject.CheckinDate,
    );
    if (allowReportDays > 180) {
      toast.error('Date range cannot be greater than 6 months.');
      setIsLoading(false);
      return;
    }
    const hotelIds = hsbiSearchValue.hotel.trim().split(',');
    const responses:any = await Promise.all(
      hotelIds.map(HotelCode =>
        getHotelList({
          ...queryObject,
          HotelCode,
        }),
      ),
    );

    filterHotelsByRoomStatus(responses,hsbiSearchValue);
  
    setIsLoading(false);
  };

  const searchSubmit2 = async hsbiSearchValue => {
    setIsLoading(true);
    const result = await getBookingDetails(hsbiSearchValue);
    setHotels(result);
    setIsLoading(false);
  };

  const searchbyHotelChain =async hsbiSearchValue => {
    setIsLoading(true);
    queryObject.HotelChainId = parseInt(hsbiSearchValue.hotelChain);
    queryObject.DateType = hsbiSearchValue.datetype;
    queryObject.CheckinDate = moment(hsbiSearchValue.checkin).format(
      'YYYY-MM-DD',
    );
    queryObject.SortBy = state.SortBy;
    queryObject.IsSortAsc = state.IsSortAsc;
    queryObject.CheckOutDate = moment(hsbiSearchValue.checkout).format(
      'YYYY-MM-DD',
    );
    const allowReportDays = differenceInDays(
      queryObject.CheckOutDate,
      queryObject.CheckinDate,
    );
    if (allowReportDays > 180) {
      toast.error('Date range cannot be greater than 6 months.');
      setIsLoading(false);
      return;
    }
   
    const responses = await gethotelListByChainId(queryObject); 
    filterHotelsByRoomStatus(responses,hsbiSearchValue);
    setIsLoading(false);
  }

  const closeBookingDetails = () => {
    setShowModal(false);
  };

  const toggleModal = () => setShowModal(prev => !prev);

  const setMinDate = (date: Date) => {
    updateState({ checkinDate: new Date(date) });
  };
  
 const filterHotelsByRoomStatus=(responses,hsbiSearchValue)=>{
  
  if (!(responses?.isSuccess === false)) {
    const roomStatusFilter = hsbiSearchValue.roomStatus;
    if (roomStatusFilter) {
      // Filter the responses based on roomStatusFilter value
      const filteredResponses = responses
        .flatMap(response => response)
        .filter(booking => booking.status === roomStatusFilter);
      // Set the filtered responses to hotels state
      setHotels([].concat([...filteredResponses]));
    } else {
      // If roomStatusFilter is not provided, set the original responses
      setHotels(responses.flatMap(response => response));
    }
  }

 };

  const { hotelOptions, checkinDate, hotelChainOptions } = state;

  return (
    <LoadingOverlay
      tag="div"
      active={isLoadingOptions}
      styles={{
        overlay: base => ({
          ...base,
          background: '#fff',
          opacity: 0.5,
        }),
      }}
      spinner={<Loader active type={loaderType} />}
    >
      <Modal isOpen={showModal} toggle={toggleModal} className="modal-xl">
        <ModalHeader toggle={toggleModal}>Booking Details</ModalHeader>
        <BookingDetails
          selectedhotelchain={currentSelected}
          onClose={closeBookingDetails}
        />
      </Modal>

      <TransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Container fluid>
          <div>
            <PageTitle
              heading="HBSI Bookings"
              icon=" lnr-magnifier icon-gradient bg-tempting-azure"
            />
          </div>

          <Card className="main-card mb-3">
            <CardBody>
              <Nav tabs>
                {[
                  { label: 'Date Range', id: 'basic' },
                  { label: 'booking #', id: 'advance' },
                   { label: 'Hotel Chain', id: 'hotelchain' },
                ].map(item => (
                  <NavItem key={item.id}>
                    <NavLink
                      className={classnames({ active: activeTab === item.id })}
                      onClick={() => toggleTab(item.id)}
                    >
                      Search by {item.label}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <CardTitle>Search</CardTitle>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="basic">
                  <Row>
                    <Col sm="12">
                      <Formik
                        validationSchema={validationSchema}
                        initialValues={{
                          hotel: '',
                          datetype: '',
                          checkin: new Date(),
                          checkout: addDays(new Date(),30),
                          roomStatus:''
                        }}
                        onSubmit={searchSubmit}
                      >
                        {({ handleSubmit }) => (
                          <Form onSubmit={handleSubmit}>
                            <div className="form-row ">
                              <Col md={3}>
                                <FormGroup>
                                  <SelectInput
                                    options={hotelOptions || []}
                                    onChange={value => {
                                      setCurrentSelected(
                                        hotels.find(
                                          hotel => hotel.id === value,
                                        ) || null,
                                      );
                                    }}
                                    label="Hotel"
                                    name="hotel"
                                    placeholder="Hotel"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <SelectInput
                                    options={SearchDateOption}
                                    label="Search Type"
                                    name="datetype"
                                    placeholder="Date"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <CustomDatePicker
                                    getSelectDate={setMinDate}
                                    label="From"
                                    name="checkin"
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Check-in date"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <CustomDatePicker
                                    label="To"
                                    name="checkout"
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Check-out date"
                                    minDate={checkinDate}
                                    maxDate={addDays(
                                      new Date(checkinDate),
                                      180,
                                    )}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <SelectInput
                                    options={RoomStatusOption}
                                    label="Room Status"
                                    name="roomStatus"
                                    placeholder="Room Status"
                                  />
                                </FormGroup>
                              </Col>
                            </div>

                            <input
                              type="submit"
                              value="Search"
                              className="btn btn-primary"
                            />
                          </Form>
                        )}
                      </Formik>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="advance">
                  <Formik
                    validationSchema={validationSchema2}
                    initialValues={{ bookingNumber: '' }}
                    onSubmit={searchSubmit2}
                  >
                    {({ handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="form-row ">
                          <Col md={3}>
                            <FormGroup>
                              <TextField
                                type="text"
                                label="Booking #"
                                name="bookingNumber"
                                placeholder="Booking #"
                              />
                            </FormGroup>
                          </Col>
                        </div>
                        <input
                          type="submit"
                          value="Search"
                          className="btn btn-primary"
                        />
                      </Form>
                    )}
                  </Formik>
                </TabPane>


                <TabPane tabId="hotelchain">
                 <Row>
                    <Col sm="12">
                    <Formik
                        validationSchema={validationSchemaHotelChain}
                        initialValues={{
                          hotelChain: '',
                          datetype: '',
                          checkin: new Date(),
                          checkout: addDays(new Date(),30),
                          roomStatus:''
                        }}
                        onSubmit={searchbyHotelChain}
                      >
                        {({ handleSubmit }) => (
                          <Form onSubmit={handleSubmit}>
                            <div className="form-row ">
                              <Col md={3}>
                                <FormGroup>
                                  <SelectInput
                                    options={hotelChainOptions || []}                                
                                    label="Hotel Chain"
                                    name="hotelChain"
                                    placeholder="Hotel chain"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md={3}>
                                <FormGroup>
                                  <SelectInput
                                    options={SearchDateOption}
                                    label="Search Type"
                                    name="datetype"
                                    placeholder="Date"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <CustomDatePicker
                                    getSelectDate={setMinDate}
                                    label="From"
                                    name="checkin"
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Check-in date"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <CustomDatePicker
                                    label="To"
                                    name="checkout"
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Check-out date"
                                    minDate={checkinDate}
                                    maxDate={addDays(
                                      new Date(checkinDate),
                                      180,
                                    )}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md={3}>
                                <FormGroup>
                                  <SelectInput
                                    options={RoomStatusOption}
                                    label="Room Status"
                                    name="roomStatus"
                                    placeholder="Date"
                                  />
                                </FormGroup>
                              </Col>

                            </div>

                            <input
                              type="submit"
                              value="Search"
                              className="btn btn-primary"
                            />
                          </Form>
                        )}
                      </Formik>
                    </Col>
                  </Row>
                </TabPane>

              </TabContent>

              <CardFooter className='mt-2'>
              <div className="float-left">
												<b>Warning:</b>
												{' '}
												<code>Due to large amounts of data being returned, if no results populate after performing a search, please narrow the date selection to a smaller window.</code>
										</div>		
              </CardFooter>
            </CardBody>
          </Card>

          <LoadingOverlay
            tag="div"
            active={isLoading}
            styles={{
              overlay: base => ({
                ...base,
                background: '#fff',
                opacity: 0.5,
              }),
            }}
            spinner={<Loader active type={loaderType} />}
          >
            <Card className="main-card mb-3">
              {hotels && !!hotels.length && (
                <CardBody>
                  <CardTitle>
                    <i className="header-icon lnr-laptop-phone icon-gradient bg-plum-plate">
                      {' '}
                    </i>
                    Booking Details
                  </CardTitle>
                  <HotelList
                    data={hotels}
                    onUpdate={updateState}
                    state={state}
                  />
                </CardBody>
              )}
            </Card>
          </LoadingOverlay>
        </Container>

      </TransitionGroup>
    </LoadingOverlay>
  );
}
export default HbsiSearch;
