/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useAuthSlice } from 'Pages/UserPages/slice';

import { Switch,
  Route, withRouter, useHistory, useLocation } from 'react-router-dom';
  
import ResizeDetector from 'react-resize-detector';
import { selectThemeOptionsState } from 'themeSlice/selectors';
import AppMain from '../../Layout/AppMain/index.tsx';
import LoginMain from '../../Layout/AppMain/login';
import userManager, { signinRedirectCallback } from '../../auth/AuthService';

import NotFound from '../Error/NotFound';
import Unauthorized from '../Error/UnAuthorize';
import ServerError from '../Error/ServerError';

function Main() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();

  const ThemeOptions = useSelector(selectThemeOptionsState);
  const {
    colorScheme,
    enableFixedHeader,
    enableFixedSidebar,
    enableFixedFooter,
    enableClosedSidebar,
    closedSmallerSidebar,
    enableMobileMenu,
    enablePageTabsAlt,
    user,
  } = ThemeOptions;

  const [loggedUser, setUser] = useState({});

  const signInRedirect = useCallback((location) => {
    signinRedirectCallback().then((user) => {
      if (user) {
        setUser(user);
        dispatch(actions.setUser(user));
        if (location === '/signin-oidc') {
          history.push({ pathname: '/dashboards/home' });
        } else {
          history.push(location);
        }
      } else {
        history.push('/');
      }
    }).catch((e) => {
      // TODO: FixMe there's an error we ignore when invoking AuthService/signoutRedirect when logging out because we clear out localstorage and session storage
      // too early. this makes the app work "as intended" but is wrong. however, there's no real consequence
      // console.log(e);
      setUser({});
      localStorage.clear();
      history.push('/');
    });
  }, [actions, dispatch, history])

  useEffect(() => {
    const { pathname } = location;
    // AT 01/09/2023: cognito mode is configured to auto attempt to trade our refresh token for a new token
    // so we don't need this. this will only apply to identity server mode, if we ever go back to that
    if (process.env.REACT_APP_IDENTITY_PROVIDER !== 'cognito') {
      userManager.events.addAccessTokenExpired(() => {
        console.log('Token Expired; redirecting  to login');
        localStorage.clear();
        signInRedirect(history);
      });
    }
    
    let loginUser = localStorage.getItem('loginUser');
    loginUser = JSON.parse(loginUser);
    if (loginUser) {
      const expireAt = new Date(parseInt(loginUser.expires_at, 10) * 1000);
       
      // AT 01/09/2023: cognito mode is configured to auto attempt to trade our refresh token for a new token
      // so we don't need this. this will only apply to identity server mode, if we ever go back to that    
      if (process.env.REACT_APP_IDENTITY_PROVIDER !== 'cognito' && new Date().isAfter(new Date(expireAt))) {
        localStorage.clear();
        console.log('Token Expired; redirecting to login');
        signInRedirect(history);
      } else {
        setUser(loginUser);
        dispatch(actions.setUser(loginUser));
        if (pathname === '/signin-oidc') {
          history.push('/dashboards/home');
        } else if (pathname === '/' || pathname === '/pages/login') {
          history.push('/dashboards/home');
        } else {
          history.push(pathname);
        }
      }
    } else {
      signInRedirect(pathname);
    }
  }, []);
 
  return Object.keys(loggedUser).length === 0 ? (
    <ResizeDetector
      handleWidth
      render={ ({ width }) => (
        <div
          className={ cx(
            `app-container app-theme-${colorScheme}`,
            { 'fixed-header': enableFixedHeader },
            { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
            { 'fixed-footer': enableFixedFooter },
            { 'closed-sidebar': enableClosedSidebar || width < 1250 },
            {
              'closed-sidebar-mobile': closedSmallerSidebar || width < 1250,
            },
            { 'sidebar-mobile-open': enableMobileMenu },
            { 'body-tabs-shadow-btn': enablePageTabsAlt }
          ) }
        >
  
          <LoginMain />
              
        </div>
      ) }
    />
  ) : (
    <ResizeDetector
      handleWidth
      render={ ({ width }) => (
        <div
          className={ cx(
            `app-container app-theme-${colorScheme}`,
            { 'fixed-header': enableFixedHeader },
            { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
            { 'fixed-footer': enableFixedFooter },
            { 'closed-sidebar': enableClosedSidebar || width < 1250 },
            {
              'closed-sidebar-mobile': closedSmallerSidebar || width < 1250,
            },
            { 'sidebar-mobile-open': enableMobileMenu },
            { 'body-tabs-shadow-btn': enablePageTabsAlt }
          ) }
        >

          <AppMain user={ user } />
          <Switch>

            {/* <Route path="/signin-oidc">
                  <LoginSuccess />
                </Route> */}

            <Route path="/not-found">
              <NotFound />
            </Route>
            <Route path="/unauthorized">
              <Unauthorized />
            </Route>

            <Route path="/server-error">
              <ServerError />
            </Route>

          </Switch>
            
        </div>
      ) }
    />
  )   
}

export default withRouter(Main);
