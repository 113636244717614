import React from "react"
import Description from "./components/Description"

import { Column } from 'common/Table';
import { ITransferType } from '../../../slice/types';

export const COLUMNS: Array<Column<ITransferType>> = [
    {
      Header: 'Active',
      accessor: 'IsActive',
      align: 'center',
      Cell: ({ value }) => (
        <div
          className={`mb-2 mr-2 badge badge-pill badge-${
            value ? 'primary' : 'danger'
          }`}
        >
          {value.toString()}
        </div>
      ),
    },
    {
      Header: 'Name',
      accessor: 'TransferTypeName',
      align: 'left',
    },
    {
      Header: 'Max Occupancy',
      accessor: 'MaxNumGuests',
    },
    {
      Header: 'Min Occupancy',
      accessor: 'MinNumGuests',
    },
    {
      Header: 'Description',
      accessor: 'TransferTypeDescription',
      width: '300px',
      Cell: ({ row: { original }, value }) => (
        <Description data={original} value={value} />
      ),
    },
  ]