import { destifyAdminApiRequests } from './common';

const destination = {
  getDestinationById: id =>
    destifyAdminApiRequests.get(`WeddingPackage/DestinationById/${id}`),
  getDestination: () =>
    destifyAdminApiRequests.get('WeddingPackage/Destination/'),
  getDestinationList: queryObject =>
    destifyAdminApiRequests.postPagination(
      'WeddingPackage/Destination/All',
      queryObject,
    ),
  getImages: id =>
    destifyAdminApiRequests.get(`WeddingPackage/DestinationImages/${id}`),

  saveDestination: param =>
    destifyAdminApiRequests.post('WeddingPackage/CreateDestination', param),
  deleteDestination: id =>
    destifyAdminApiRequests.put(`WeddingPackage/DeleteDestination/${id}`),

  uploadPhoto: (id, imageName, altTagText, file) => {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('imageName', imageName);
    formData.append('altTagText', altTagText);
    return destifyAdminApiRequests.post(
      `WeddingPackage/${id}/SaveDestinationImage`,
      formData,
      {
        headers: { 'Content-type': 'multipart/form-data' },
      },
    );
  },
  updateImageDetails: params =>
    destifyAdminApiRequests.post(
      'WeddingPackage/UpdateDestinationImage',
      params,
    ),
  deleteImages: data =>
    destifyAdminApiRequests.post('WeddingPackage/DeleteDestinationImage', data),
  updateStatus: id =>
    destifyAdminApiRequests.put(`WeddingPackage/UpdateDestinationStatus/${id}`),
};
const subDestination = {
  getsubdestinationByDestinationId: id =>
    destifyAdminApiRequests.get(`WeddingPackage/SubDestination/${id}`),

  getSubDestinationById: id =>
    destifyAdminApiRequests.get(`WeddingPackage/SubDestinationById/${id}`),

  getSubDestinationList: queryObject =>
    destifyAdminApiRequests.postPagination(
      'WeddingPackage/SubDestination/All',
      queryObject,
    ),
  getImages: id =>
    destifyAdminApiRequests.get(`WeddingPackage/SubDestinationImages/${id}`),

  saveSubDestination: param =>
    destifyAdminApiRequests.post('WeddingPackage/CreatesubDestination', param),
  deleteSubDestination: id =>
    destifyAdminApiRequests.put(`WeddingPackage/DeleteSubDestination/${id}`),

  uploadPhoto: (id, imageName, altTagText, file) => {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('imageName', imageName);
    formData.append('altTagText', altTagText);
    return destifyAdminApiRequests.post(
      `WeddingPackage/${id}/SaveSubDestinationImage`,
      formData,
      {
        headers: { 'Content-type': 'multipart/form-data' },
      },
    );
  },
  updateImageDetails: params =>
    destifyAdminApiRequests.post(
      'WeddingPackage/UpdateSubDestinationImage',
      params,
    ),
  deleteImages: data =>
    destifyAdminApiRequests.post(
      'WeddingPackage/DeletesubDestinationImage',
      data,
    ),
  updateStatus: id =>
    destifyAdminApiRequests.put(
      `WeddingPackage/UpdateSubDestinationStatus/${id}`,
    ),
};
const resort = {
  getResortByResortId: id =>
    destifyAdminApiRequests.get(`WeddingPackage/ResortById/${id}`),
  getResortList: () => destifyAdminApiRequests.get('WeddingPackage/ResortList'),
  getResort: queryObject =>
    destifyAdminApiRequests.postPagination(
      'WeddingPackage/ResortList/All',
      queryObject,
    ),
  getResortBySubDestinationId: id =>
    destifyAdminApiRequests.get(`WeddingPackage/ResortById/${id}`),
  saveResort: param =>
    destifyAdminApiRequests.post('WeddingPackage/CreateResort', param),
  deleteResort: id =>
    destifyAdminApiRequests.put(`WeddingPackage/DeleteResort/${id}`),
  updateStatus: id =>
    destifyAdminApiRequests.put(`WeddingPackage/UpdateResortStatus/${id}`),
};

const venue = {
  getVenueById: id => destifyAdminApiRequests.get(`WeddingPackage/Venue/${id}`),
  getVenue: queryObject =>
    destifyAdminApiRequests.postPagination(
      'WeddingPackage/Venue/All',
      queryObject,
    ),
  getResortVenueById: id =>
    destifyAdminApiRequests.get(`WeddingPackage/ResortListByVenuId/${id}`),
  getVenueAll: () => destifyAdminApiRequests.get('WeddingPackage/Venue/'),
  saveVenue: param =>
    destifyAdminApiRequests.post('WeddingPackage/SaveVenue', param),
  deleteVenue: id =>
    destifyAdminApiRequests.post('WeddingPackage/DeleteVenue', {
      resortVenueID: id,
    }),
  uploadPhoto: (id, imageName, altTagText, file) => {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('imageName', imageName);
    formData.append('altTagText', altTagText);
    return destifyAdminApiRequests.post(
      `WeddingPackage/${id}/SaveImage`,
      formData,
      {
        headers: { 'Content-type': 'multipart/form-data' },
      },
    );
  },
  getImages: id =>
    destifyAdminApiRequests.get(`WeddingPackage/VenueImges/${id}`),
  updateImageDetails: params =>
    destifyAdminApiRequests.post('WeddingPackage/UpdateVenueImage', params),
  deleteImages: data =>
    destifyAdminApiRequests.post('WeddingPackage/DeleteVenueImage', data),
  updateStatus: id =>
    destifyAdminApiRequests.put(`WeddingPackage/UpdateVenueStatus/${id}`),
};

const packagedetails = {
  list: queryObject =>
    destifyAdminApiRequests.postPagination('WeddingPackage/All', queryObject),
  getResortPackageById: id =>
    destifyAdminApiRequests.get(
      `WeddingPackage/WedingPackageListByResortId/${id}`,
    ),
  saveWeddingPackagePricing: queryObject =>
    destifyAdminApiRequests.post(
      'WeddingPackage/UpdateWeddingPackagePricing',
      queryObject,
    ),
  deleteWeddinPackagePricing: id =>
    destifyAdminApiRequests.put(
      `WeddingPackage/DeleteWeddinPackagePricing?id=${id}`,
    ),
  getWeddingPackagePricing: id =>
    destifyAdminApiRequests.get(`WeddingPackage/${id}/WeddingPackagePricing`),
  save: params =>
    destifyAdminApiRequests.post('WeddingPackage/NewPackage', params),
  getpackageById: id => destifyAdminApiRequests.get(`WeddingPackage/${id}`),
  delete: id => destifyAdminApiRequests.post('weddingPackage/Delete', { id }),
  disable: data => destifyAdminApiRequests.post('weddingPackage/Disable', data),
  getPackage: () =>
    destifyAdminApiRequests.get('WeddingPackage/WedingPackage/'),
  getImages: id =>
    destifyAdminApiRequests.get(`WeddingPackage/WeddingPackageImages/${id}`),
  uploadPhoto: (id, imageName, altTagText, file) => {
    const formData = new FormData();
    formData.append('File', file);
    formData.append('imageName', imageName);
    formData.append('altTagText', altTagText);
    return destifyAdminApiRequests.post(
      `WeddingPackage/${id}/SaveWeddingPackageImage`,
      formData,
      {
        headers: { 'Content-type': 'multipart/form-data' },
      },
    );
  },
  updateImageDetails: params =>
    destifyAdminApiRequests.post(
      'WeddingPackage/UpdateWeddingPackageImage',
      params,
    ),
  deleteImages: data =>
    destifyAdminApiRequests.post(
      'WeddingPackage/DeleteWeddingPackageImage',
      data,
    ),
};

const leadType = {
  getLeadType: () => destifyAdminApiRequests.get('WeddingPackage/LeadType/'),
};

const weddingDateStatusType = {
  getWeddingDateStatusType: () =>
    destifyAdminApiRequests.get('WeddingPackage/WeddingDateStatusType'),
};

const weddingPackage = {
  destination,
  subDestination,
  resort,
  venue,
  packagedetails,
  leadType,
  weddingDateStatusType,
};

export default weddingPackage;
