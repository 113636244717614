import { AxiosInstance } from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';

export function withRetry(client: AxiosInstance) {
  axiosRetry(client, {
    retries: 3,
    retryDelay: exponentialDelay,
  });

  return client;
}