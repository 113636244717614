import { createSelector } from '@reduxjs/toolkit';

import { initialAuthState } from './index.ts';
 
const authSlice = (state) => state.auth || initialAuthState;
 
export const selectUser = createSelector(
  [authSlice],
  (state) => state.user,
);
