/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  Row,
  Card,
  CardBody,
  CardHeader
} from 'reactstrap';
import { Loader } from 'react-loaders';

import usersApi from '../../../../api/user';
import salesLead from '../../../../api/salesLead'
import crm from '../../../../api/crm';
import { formatDate } from 'common/utils/dateUtils';

function WeddingInfo({ groupId }) {
  const [loading, setLoading] = useState(false);
  const [weddingInfo, setWeddingInfo] = useState({
    salesSource: '',
    spouseOneName: null,
    spouseOnePhone: null,
    spouseOneEmail: null,
    spouseTwoName: null,
    spouseTwoPhone: null,
    spouseTwoEmail: null,
  });
  const [confirmedWeddingDetails, setConfirmedWeddingDetails] = useState({
    confirmedWeddingDatetime: null,
    weddingSiteUserName: null,
    weddingSitePassword: null,
    weddingConfirmationNumber: null,
    assignedWeddingCoordinatorName: null,
    assignedWeddingCoordinatorEmail: null,
    weddingPlanningSiteLink: null,
    copyOfConfirmation: null,
  });

  const getWeddingInfo = async () => {
    setLoading(true);

    // Fetching GroupDetail
      const crmGroupDetail = await crm.groups.groupDetail(groupId);
     
      // Get Wedding Info
      const weddingInfoData = crmGroupDetail || weddingInfo;

      // Get PTID user Wedding details
      const ptidUserWeddingDetails = await salesLead.salesLeadDetails.weddingDetailsByUser(groupId);

      const confirmedWeddingDetailsData = ptidUserWeddingDetails.data || confirmedWeddingDetails;
      if (weddingInfoData.salesSource === 'ptid') {
        weddingInfoData.spouseOneName = confirmedWeddingDetailsData.spouseNameOne ?? `${crmGroupDetail.brideFirstName} ${crmGroupDetail.brideLastName}`;
        weddingInfoData.spouseTwoName = confirmedWeddingDetailsData.spouseNameTwo ?? `${crmGroupDetail.groomFirstName} ${crmGroupDetail.groomLastName}`;
      }
      setConfirmedWeddingDetails(confirmedWeddingDetailsData);
      setWeddingInfo(weddingInfoData);

      setLoading(false);
    
  }

  useEffect(() => {
    getWeddingInfo();
  }, [])

  return (
    <Card className="main-card mb-3">
      <CardHeader>Wedding Info</CardHeader>
      <CardBody>
        {!loading ? (
          <>
            <Row style={ { marginBottom: '1rem' } }>
              <label className="control-label col-md-3">
                Spouse 1 Name:{' '}
              </label>
              <div className="col-md-9">{weddingInfo.spouseOneName || 'N/A'}</div>

              <label className="control-label col-md-3">
                Spouse 1 Phone Number:{' '}
              </label>
              <div className="col-md-9">{weddingInfo.spouseOnePhone || 'N/A'}</div>

              <label className="control-label col-md-3">
                Spouse 1 Email:{' '}
              </label>
              <div className="col-md-9">{weddingInfo.spouseOneEmail || 'N/A'}</div>
            </Row>

            <Row style={ { marginBottom: '1rem' } }>
              <label className="control-label col-md-3">
                Spouse 2 Name:{' '}
              </label>
              <div className="col-md-9">{weddingInfo.spouseTwoName || 'N/A'}</div>

              <label className="control-label col-md-3">
                Spouse 2 Phone Number:{' '}
              </label>
              <div className="col-md-9">{weddingInfo.spouseTwoPhone || 'N/A'}</div>
              <label className="control-label col-md-3">
                Spouse 2 Email:{' '}
              </label>
              <div className="col-md-9">{weddingInfo.spouseTwoEmail || 'N/A'}</div>
            </Row>

            <FormGroup>
              <Row>
                <label className="control-label col-md-3">
                  Confirmed Wedding Date:{' '}
                </label>
                                
                <div className="col-md-9">{formatDate(confirmedWeddingDetails.confirmedWeddingDatetime, 'MM/dd/yyyy')}
                </div>

                <label className="control-label col-md-3">
                  Confirmed Wedding Time:{' '}
                </label>
                <div className="col-md-9">{formatDate(confirmedWeddingDetails.confirmedWeddingDatetime, 'hh:mm:ss a')}</div>
                <label className="control-label col-md-3">
                  Wedding Planning Site Username:{' '}
                </label>
                <div className="col-md-9">{confirmedWeddingDetails.weddingSiteUserName || 'N/A'}</div>

                <label className="control-label col-md-3">
                  Wedding Planning Site Password:{' '}
                </label>
                <div className="col-md-9">{confirmedWeddingDetails.weddingSitePassword || 'N/A'}</div>

                <label className="control-label col-md-3">
                  Confirm Number:{' '}
                </label>
                <div className="col-md-9">{confirmedWeddingDetails.weddingConfirmationNumber || 'N/A'}</div>

                <label className="control-label col-md-3">
                  Assigned Wedding Coordinator Name:{' '}
                </label>
                <div className="col-md-9">{confirmedWeddingDetails.assignedWeddingCoordinatorName || 'N/A'}</div>

                <label className="control-label col-md-3">
                  Wedding Coordinator Email:{' '}
                </label>
                <div className="col-md-9">{confirmedWeddingDetails.assignedWeddingCoordinatorEmail || 'N/A'}</div>

                <label className="control-label col-md-3">
                  Wedding Planning Site Link: {' '}
                </label>
                <div className="col-md-9">{confirmedWeddingDetails.weddingPlanningSiteLink || 'N/A'}</div>

                <label className="control-label col-md-3">
                  Copy of confirmation:{' '}
                </label>
                <div className="col-md-9">{confirmedWeddingDetails.copyOfConfirmation || 'N/A'}</div>
              </Row>
            </FormGroup>
          </>
        ) : (
          <div style={ { display: 'flex', justifyContent: 'center' } }>
            <Loader type="ball-pulse" />
          </div>
        )}

      </CardBody>
    </Card>
  )
}

export default WeddingInfo;
