import React, { useState, useEffect } from 'react';
import usersApi from 'api/user';

import { useCustomerTransferReservationSlice } from '../Reservations/slice';
import { selectCustomerTransferReservationsByDestifyUserId, selectLoading } from '../Reservations/slice/selectors';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import { Link } from 'react-router-dom';
import { Row, Card, CardBody, Col, CardHeader } from 'reactstrap';
import Loader from 'react-loaders';
import styles from './style.module.scss';
 

export const UsersBooking = ({ id, reservationId }) => {
  const dispatch = useAppDispatch();
  const { actions } = useCustomerTransferReservationSlice();
  const [userRooms, setUserRooms] = useState([]);
  const [loadingUserRooms, setLoadingUserRooms] = useState(true);
  const  transferReservations  =
    useAppSelector(selectCustomerTransferReservationsByDestifyUserId);
    const loadingTransferReservations=  useAppSelector(selectLoading);

  const otherTransferReservations = React.useMemo(() => {
    const reservations = transferReservations.filter(
      item => item.Id !== reservationId,
    );
    if (!reservations.length) return null;
    return reservations.map(reservation => {
      return (
        <label key={reservation.Id} className="list-details">
          Transfer -{' '}
          <div className="list-details-value">
            <Link to={`/hbsi/transfers/${reservation.Id}`}>
              {reservation.PrimaryTravelerFirstName}{' '}
              {reservation.PrimaryTravelerLastName}
            </Link>
          </div>
        </label>
      );
    });
  }, [transferReservations, reservationId]);

  useEffect(() => {
    if (id) {
      dispatch(
        actions.loadCustomerTransferReservationsByDestifyUserId({
          DestifyUserId: id,
        }),
      );
    }
    return () => {
      dispatch(actions.clearReservations())
    }
  }, [id, dispatch, actions]);

  useEffect(() => {
    const getRoomDetailsByUserId = async id => {
      return await usersApi.guestUsers.getClientAccountsRoomDetails([id]);
    };
    if (id) {
      getRoomDetailsByUserId(id)
        .then(res => {
          setUserRooms(res);
          console.log({ res });
        })
        .catch(err => {
          // do nothing
        })
        .finally(() => {
          setLoadingUserRooms(false);
        });
    }
  }, [id]);

  return (
    <Row>
      <Col xs={12} md={6}>
        <Card className={styles.custom_card}>
          <CardHeader className={styles.background}>User's Booking</CardHeader>
          <CardBody>
            {loadingTransferReservations || loadingUserRooms ? (
              <Loader active type="ball-pulse" />
            ) : null}
            {loadingUserRooms
              ? null
              : userRooms.map(room => {
                  return (
                    <label key={room.roomId} className="list-details">
                      Room -{' '}
                      <div className="list-details-value">
                        <Link to={`${room.roomUrl}`}>{room.roomName}</Link>
                      </div>
                    </label>
                  );
                })}
            {loadingTransferReservations ? null : (
              <>{otherTransferReservations}</>
            )}
            {!loadingTransferReservations &&
            !loadingUserRooms &&
            !userRooms.length &&
            !otherTransferReservations
              ? 'N/A'
              : null}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UsersBooking;
