import React from 'react';

function AppFooter() {
  return (
    <div className="app-footer">
      <div className="app-footer__inner">
        <div className="app-footer-left">{/* <FooterDots /> */}</div>
        <div className="app-footer-right">{/* <MegaMenuFooter /> */}</div>
      </div>
    </div>
  );
}

export default AppFooter;
