import React, { } from 'react';
import { useSelector } from 'react-redux';

import { selectUser } from 'Pages/UserPages/slice/selectors';
import { ProtectedRoute } from '../../protectedRouter';

// import { Authenticate, useUserIdentity } from "react-oidc-client";

// DASHBOARDS

import LandingDashboard from './LandingPage/index.tsx';
// import SalesDashboard from "./Sales/";
// import CommerceDashboard from "./Commerce/";
// import CRMDashboard from "./CRM/";
// import MinimalDashboard1 from "./Minimal/Variation1";
// import MinimalDashboard2 from "./Minimal/Variation2";

// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

function Dashboards() {
  const user = useSelector(selectUser);
  
  return (
    <>
      {/* <ThemeOptions /> */}
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
       
        <div className="app-main__outer">
          <div className="app-main__inner">
            <ProtectedRoute exact path="/dashboards/home" component={ LandingDashboard } isAuthenticated={ !!user } />
            {/* <ProtectedRoute exact path={`/dashboards/analytics`} component={AnalyticsDashboard} isAuthenticated={props.isAuthenticated}/> */}
            {/* <Route exact path={`/dashboards/sales`} component={SalesDashboard} /> */}
            {/* <Route path={`/dashboards/commerce`} component={CommerceDashboard} /> */}
            {/* <ProtectedRoute path={`/dashboards/crm`} component={CRMDashboard} isAuthenticated={props.isAuthenticated}/> */}
            {/* <ProtectedRoute path={`/dashboards/minimal-dashboard-1`} component={MinimalDashboard1} isAuthenticated={props.isAuthenticated}/> */}
            {/* <ProtectedRoute path={`/dashboards/minimal-dashboard-2`} component={MinimalDashboard2} isAuthenticated={props.isAuthenticated}/> */}
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  )
}

// export default Dashboards;

export default Dashboards;
