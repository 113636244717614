/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Loader } from 'react-loaders';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';

import { toast } from 'react-toastify';

import SweetAlert from 'sweetalert-react';

import LoadingOverlay from 'react-loading-overlay';

import { TransitionGroup } from 'react-transition-group';

import { withRouter } from 'react-router-dom';
import hotel from '../../../api/hotelContent';

import PageTitle from '../../../Layout/AppMain/PageTitle';

class HotelChainList extends React.Component {
  // state = HotelChainList.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  // expanstate = { expanded: false };

  deleteHotleId;

  constructor(props) {
    super(props);

    this.state = {
      hotelChain: [],
      active: false,
      statustext: '',
      loaderType: 'line-scale-pulse-out-rapid',
    };
  }

  componentDidMount() {
    this.setState({ active: true });
    this.getHotelChain();
  }

  getHotelChain() {
    hotel.hotelChain.allHotelChainList().then((response) => {
      this.setState({ active: false });
      this.setState({ hotelChain: response });
    });
  }

  addHotelChain() {
    this.props.history.push('/hotels/create-hotelchain');
  }

  updateHotelChain(id) {
    this.props.history.push(`/hotels/update-hotelchain/${id}`);
  }

  showConfirmationToDelete = (id, enable) => {
    this.deleteHotleId = id;
    this.enable = enable;
    if (this.enable) {
      this.setState({ statustext: 'Are you sure to enable the hotel chain?' });
    } else {
      this.setState({ statustext: 'Are you sure to disable the hotel chain?' });
    }
    this.setState({ show: true });
  };

  deleteHotelChain = () => {
    hotel.hotelChain
      .updateHotelChainStatus({
        id: this.deleteHotleId,
        IsEnabled: this.enable,
      })
      .then((response) => {
        toast.success(response);
        this.getHotelChain();
      });
  };

  render() {
    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text={ this.state.statustext }
          showCancelButton
          onConfirm={ () => {
            this.deleteHotelChain();
            this.setState({ show: false });
          } }
          onCancel={ () => {
            this.setState({ show: false });
          } }
          onClose={ () => {} }
        />

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <div>
              <PageTitle
                heading="Hotel Chain"
                icon="lnr-apartment icon-gradient bg-tempting-azure"
              />
            </div>

            <LoadingOverlay
              tag="div"
              active={ this.state.active }
              styles={ {
                overlay: (base) => ({
                  ...base,
                  background: '#fff',
                  opacity: 0.5,
                }),
              } }
              spinner={ <Loader active type={ this.state.loaderType } /> }
            >
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Hotel Chain List</CardTitle>
                  <Button
                    className="mb-2 mr-2 btn-icon float-right"
                    color="primary"
                    onClick={ () => {
                      this.addHotelChain();
                    } }
                  >
                    Add Hotel Chain
                  </Button>
                  <Table
                    size="md"
                    responsive
                    striped
                    bordered
                    hover
                    className="mb-3"
                  >
                    <thead>
                      <tr>
                        <th> S.N </th>
                        <th> Hotel Chain </th>
                        <th>Phone </th>
                        <th>BDM </th>
                        <th>Supplier Commission % </th>
                        <th>Offers Rewards</th>
                        <th>Status</th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.hotelChain.length > 0
                        && this.state.hotelChain.map((hotel, index) => (
                          <tr key={ index }>
                            <td>{index + 1}</td>
                            <td>{hotel.hotelChainName} </td>
                            <td>{hotel.phone}</td>
                            <td style={ { width: '15%' } }>{hotel.bdm}</td>
                            <td style={ { width: '15%' } }>
                              {hotel.supplierCommissions}
                            </td>
                            <td style={ { width: '5%' } }>
                              {hotel.offersRewards && 'Y'}
                              {!hotel.offersRewards && 'N'}
                            </td>
                            <td>
                              {!hotel.isEnabled && (
                                <div className="mb-2 mr-2 badge badge-pill badge-danger">
                                  {' '}
                                  Disabled{' '}
                                </div>
                              )}
                              {hotel.isEnabled && (
                                <div className="mb-2 mr-2 badge badge-pill badge-success">
                                  {' '}
                                  Enabled{' '}
                                </div>
                              )}
                            </td>
                            <td>
                              <UncontrolledButtonDropdown>
                                <DropdownToggle
                                  caret
                                  className="mb-2 mr-2"
                                  color="primary"
                                >
                                  Action
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                                  <DropdownItem
                                    onClick={ () => this.updateHotelChain(hotel.id) }
                                  >
                                    Manage
                                  </DropdownItem>

                                  <DropdownItem divider />

                                  {!hotel.isEnabled && (
                                    <DropdownItem
                                      onClick={ () => this.showConfirmationToDelete(
                                        hotel.id,
                                        true
                                      ) }
                                    >
                                      Enable
                                    </DropdownItem>
                                  )}
                                  {hotel.isEnabled && (
                                    <DropdownItem
                                      onClick={ () => this.showConfirmationToDelete(
                                        hotel.id,
                                        false
                                      ) }
                                    >
                                      Disable
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </LoadingOverlay>
          </Container>
        </TransitionGroup>
      </>
    );
  }
}
export default withRouter(HotelChainList);
