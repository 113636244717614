import { UserManager } from 'oidc-client';

// docs available here https://github.com/IdentityModel/oidc-client-js/wiki
const cognitoConfig = {
  authority: `${process.env.REACT_APP_COGNITO_CONFIG_AUTHORITY_BASE_URL}/${process.env.REACT_APP_COGNITO_CONFIG_USER_POOL_ID}`,
  client_id: process.env.REACT_APP_COGNITO_CONFIG_CLIENT_ID,
  redirect_uri: `${window.location.origin}/signin-oidc`,
  response_type: process.env.REACT_APP_COGNITO_CONFIG_RESPONSE_TYPE,
  scope: process.env.REACT_APP_COGNITO_CONFIG_SCOPES,
  post_logout_redirect_uri: `${window.location.origin}/signout-oidc`,
  revokeAccessTokenOnSignout: true,
  automaticSilentRenew: true,
  metadata: {
    end_session_endpoint: `${
      process.env.REACT_APP_COGNITO_CONFIG_CUSTOM_DOMAIN
    }/logout?client_id=${
      process.env.REACT_APP_COGNITO_CONFIG_CLIENT_ID
    }&logout_uri=${encodeURIComponent(
      `${window.location.origin}/signout-oidc`,
    )}`,
    token_endpoint: `${process.env.REACT_APP_COGNITO_CONFIG_CUSTOM_DOMAIN}/oauth2/token`,
    userinfo_endpoint: `${process.env.REACT_APP_COGNITO_CONFIG_CUSTOM_DOMAIN}/oauth2/userInfo`,
    jwks_uri: `${process.env.REACT_APP_COGNITO_CONFIG_AUTHORITY_BASE_URL}/${process.env.REACT_APP_COGNITO_CONFIG_USER_POOL_ID}/.well-known/jwks.json`,
    authorization_endpoint: `${process.env.REACT_APP_COGNITO_CONFIG_CUSTOM_DOMAIN}/oauth2/authorize`,
    response_modes_supported:
      process.env.REACT_APP_COGNITO_CONFIG_SUPPORTED_RESPONSE_TYPE.split(' '),
    scopes_supported:
      process.env.REACT_APP_COGNITO_CONFIG_SUPPORTED_SCOPES.split(' '),
    subject_types_supported:
      process.env.REACT_APP_COGNITO_CONFIG_SUBJECT_TYPES_SUPPORTED.split(' '),
    token_endpoint_auth_methods_supported:
      process.env.REACT_APP_COGNITO_CONFIG_TOKEN_ENDPOINT_AUTH_METHODS.split(
        ' ',
      ),
    issuer: `${process.env.REACT_APP_COGNITO_CONFIG_AUTHORITY_BASE_URL}/${process.env.REACT_APP_COGNITO_CONFIG_USER_POOL_ID}`,
  },
};

const config = {
  authority: process.env.REACT_APP_IDENTITYSERVER,
  client_id: process.env.REACT_APP_IDENTITYSERVER_CLIENT_ID,
  redirect_uri: `${window.location.origin}/signin-oidc`,
  response_type: 'code',
  scope: process.env.REACT_APP_IDENTITYSERVER_SCOPES,
  post_logout_redirect_uri: `${window.location.origin}/signout-oidc`,
};

const userManager = new UserManager(
  process.env.REACT_APP_IDENTITY_PROVIDER === 'cognito'
    ? cognitoConfig
    : config,
);

// this is called after the user signed in, and we get our payload
// _and_ when a refresh token is exchanged for new valid tokens, behind the scenes
userManager.events.addUserLoaded(user => {
  if (user) {
    localStorage.setItem('idtoken', user.id_token);
    localStorage.setItem('token', user.access_token);
    const loginUser = user;
    delete loginUser.state;
    delete loginUser.session_state;
    localStorage.setItem('loginUser', JSON.stringify(loginUser));
  }
});

export function getUserFromLocalStorage() {
  const loginUser = localStorage.getItem('loginUser');
  const user = JSON.parse(loginUser);
  return user;
}

export function signinRedirect() {
  return userManager.signinRedirect();
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  if (process.env.REACT_APP_IDENTITY_PROVIDER === 'cognito') {
    localStorage.clear();
    sessionStorage.clear();
    return userManager.signoutRedirect();
  }
  const idtoken = localStorage.getItem('idtoken');
  localStorage.clear();
  return userManager.signoutRedirect({ id_token_hint: idtoken });
}

export default userManager;
