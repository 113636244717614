import React, { useCallback, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Container,
  UncontrolledTooltip,
  Button,
} from 'reactstrap';
import ReactTable, { RowInfo } from 'react-table';

import { ExportToExcel } from '../../../../common/Export/ExportToExcel';
import { formatDate } from 'common/utils/dateUtils';
import { UserApi } from 'api/adminApi/api/user-api';
import { RSVPRoomInfo } from 'api/adminApi/model';
import { IoIosBed, IoIosInformationCircle, IoIosPerson } from 'react-icons/io';
import { toast } from 'react-toastify';

interface ISummaryData {
  TotalRooms: number;
  TotalActiveRooms: number;
  TotalCancelledRooms: number;
  TotalAdults: number;
  TotalChildren: number;
  TotalGuests: number;
}

const isOver18Years = (travelDate: Date, dateOfBirth: Date): boolean => {
  const diffInMilliseconds = travelDate.getTime() - dateOfBirth.getTime();
  const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Account for leap years
  const yearsDifference = diffInMilliseconds / millisecondsInYear;

  return yearsDifference >= 18;
};

const GenerateSummaryRow = (
  { summaryData }: { summaryData: ISummaryData | null } = null,
) => {
  if (!summaryData) return null;
  return (
    <Row>
      <Col>Active Rooms: {summaryData.TotalActiveRooms}</Col>
      <Col>Cancelled Rooms: {summaryData.TotalCancelledRooms}</Col>
      <Col>Total Rooms: {summaryData.TotalRooms}</Col>
      <Col>Adults: {summaryData.TotalAdults}</Col>
      <Col>Children: {summaryData.TotalChildren}</Col>
      <Col>
        {summaryData.TotalAdults + summaryData.TotalChildren !==
          summaryData.TotalGuests && (
          <>
            <span id="guestCountWarning">
              <IoIosInformationCircle
                color="red"
                size={'20px'}
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </span>
            <UncontrolledTooltip placement="top" target="guestCountWarning">
              This number might not represent the total amount of adults +
              children, if the guest's dob information is missing.
              <p>Please see rows in red, below.</p>
            </UncontrolledTooltip>
          </>
        )}
        Total Guests: {' '} {summaryData.TotalGuests}
      </Col>
    </Row>
  );
};

export const RoomsWithGuestDetails = ({ groupId }) => {
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState<RSVPRoomInfo[]>([]);
  const [compPolicy, setCompPolicy] = useState<string | null>(null);
  const [summaryData, setSummaryData] = useState<ISummaryData | null>(null);
  const [expandedRowIndexes, setExpandedRowIndexes] = useState({});
  const [excelFormattedExport, setExcelFormattedExport] = useState<any[]>();

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await new UserApi().apiUserGetCrmRoomsByGroupIdGet(
        groupId,
      );
      if (data && data.responseCode > 199 && data.responseCode < 300) {
        if (data.roomInfoList) {
          const initialState = {
            cleanedRooms: [],
            exportFormattedRooms: [],
            cancelledRoomsCount: 0,
            totalAdults: 0,
            totalChildren: 0,
            totalGuests: 0,
          };
          const result = data.roomInfoList.reduce((acc, { roomInfo }) => {
            if (roomInfo) {
              //add to the data source for the grid
              acc.cleanedRooms.push(roomInfo);

              //add to the export data source (a mix of rooms and travellers)
              acc.exportFormattedRooms.push({
                ...roomInfo,
              });

              //loop through each traveler in the room
              roomInfo.travelerInfo?.forEach(traveler => {
                //add to the export data source, under the current room
                acc.exportFormattedRooms.push({
                  ...traveler,
                });

                //check if there's dob and travel data info, so we can calculate adults and children
                if (traveler.dob && roomInfo.travelDate) {
                  const dobDate = new Date(`${traveler.dob}T00:00:00`);
                  const travelDate = new Date(
                    `${roomInfo.travelDate}T00:00:00`,
                  );

                  //categorize and group into adult/children categories
                  if (isOver18Years(travelDate, dobDate)) acc.totalAdults++;
                  else acc.totalChildren++;
                }
              });

              //increase total guest count. has to be done this way, because travelers sometimes have null DOBs
              acc.totalGuests += roomInfo.travelerInfo?.length || 0;

              //check and add to list of cancelled rooms
              if (roomInfo.status?.toLowerCase().startsWith('cancel'))
                acc.cancelledRoomsCount++;
            }
            return acc;
          }, initialState);
          setRooms([...result.cleanedRooms]);
          setExcelFormattedExport([...result.exportFormattedRooms]);
          setSummaryData({
            TotalRooms: data.roomInfoList.length,
            TotalActiveRooms:
              data.roomInfoList.length - result.cancelledRoomsCount,
            TotalCancelledRooms: result.cancelledRoomsCount,
            TotalGuests: result.totalGuests,
            TotalAdults: result.totalAdults,
            TotalChildren: result.totalChildren,
          });
          setExpandedRowIndexes({});
        }

        if (data.compPolicy) setCompPolicy(data.compPolicy);
      } else {
        //show an error

        toast.error(
          'An error occurred presenting data. Please try again or contact the dev team.',
        );
      }
    } catch (error) {
      //show an error

      toast.error(
        'An error occurred loading data. Please try again or contact the dev team.',
      );
    } finally {
      setLoading(false);
    }
  }, [groupId]);

  useEffect(() => {
    loadData();
  }, [groupId, loadData]);

  return (
    <Row>
      <Col md="12">
        <Card className="main-card mb-3">
          <CardHeader>
            <Container>
              <GenerateSummaryRow summaryData={summaryData} />
            </Container>
          </CardHeader>
        </Card>
        <Card className="main-card mb-3">
          <CardHeader>
            <div className="btn-actions-pane-right" style={{ display: 'flex' }}>
              {rooms?.length > 0 && (
                <ExportToExcel
                  //bookedItems={exportBooked.length ? exportBooked : null}
                  apiData={excelFormattedExport}
                  fileName={`Group-${groupId}-RoomList-${new Date().toISOString()}`}
                />
              )}
              <button
                type="button"
                className="mb-2 me-2 btn-icon btn-icon-only btn btn-primary"
                onClick={() => loadData()}
              >
                <i className="lnr-sync btn-icon-wrapper" />
              </button>
            </div>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rooms}
              loading={loading}
              expanded={expandedRowIndexes}
              getTrProps={(
                finalState: any,
                rowInfo?: RowInfo,
                column?: undefined,
                instance?: any,
              ) => {
                return {
                  onClick: e => {
                    const expandedRows = { ...expandedRowIndexes };
                    expandedRows[rowInfo.viewIndex] =
                      !expandedRowIndexes[rowInfo.viewIndex];
                    setExpandedRowIndexes(expandedRows);
                  },
                  className: rowInfo?.original.travelerInfo?.some(
                    t => !t.dob || t.dob === '',
                  )
                    ? 'bg-warm-flame opacity-75'
                    : '',
                };
              }}
              //these have to reset the expanded rows, since the functionality is off of viewIndex and not rowIndex, all these interactions
              //affect which row of data belongs to what viewIndex. See https://github.com/TanStack/table/issues/895
              onSortedChange={pageIndex => setExpandedRowIndexes({})}
              onPageSizeChange={pageIndex => setExpandedRowIndexes({})}
              onFilteredChange={pageIndex => setExpandedRowIndexes({})}
              onPageChange={pageIndex => setExpandedRowIndexes({})}
              SubComponent={row => (
                <div style={{ padding: '20px' }}>
                  <ReactTable
                    data={row.original.travelerInfo}
                    minRows={1}
                    showPagination={false}
                    className={'text-center'}
                    getTrProps={(
                      finalState: any,
                      rowInfo?: RowInfo,
                      column?: undefined,
                      instance?: any,
                    ) => ({
                      style: { background: '#FBFADA' },
                    })}
                    columns={[
                      {
                        columns: [
                          {
                            sortable: false,
                            filterable: false,
                            width: 40,
                            Cell: row => (
                              <>
                                <IoIosPerson />
                              </>
                            ),
                          },
                          {
                            Header: 'Traveler First Name',
                            accessor: 'first_Name',
                            Cell: row =>
                              !row.original.first_Name ? (
                                <Badge color={'danger'}>MISSING</Badge>
                              ) : (
                                row.original.first_Name
                              ),
                          },
                          {
                            Header: 'Traveler Middle Name',
                            accessor: 'middle_Name',
                          },
                          {
                            Header: 'Traveler Last Name',
                            accessor: 'last_Name',
                            Cell: row =>
                              !row.original.last_Name ? (
                                <Badge color={'danger'}>MISSING</Badge>
                              ) : (
                                row.original.last_Name
                              ),
                          },
                          {
                            Header: 'Gender',
                            accessor: 'gender',
                            Cell: row =>
                              !row.original.gender ? (
                                <Badge color={'danger'}>MISSING</Badge>
                              ) : (
                                row.original.gender
                              ),
                          },
                          {
                            Header: 'Date of Birth',
                            accessor: 'dob',
                            Cell: row =>
                              !row.original.dob ? (
                                <Badge color={'danger'}>MISSING</Badge>
                              ) : (
                                row.original.dob
                              ),
                          },
                          {
                            Header: 'Email',
                            accessor: 'email',
                            Cell: row =>
                              !row.original.email ? (
                                <Badge color={'danger'}>MISSING</Badge>
                              ) : (
                                row.original.email
                              ),
                          },
                          {
                            Header: 'Phone',
                            accessor: 'phone',
                            Cell: row =>
                              !row.original.phone ? (
                                <Badge color={'danger'}>MISSING</Badge>
                              ) : (
                                row.original.phone
                              ),
                          },
                          {
                            Header: 'Status',
                            accessor: 'travelerStatus',
                            Cell: row =>
                              !row.original.travelerStatus ? (
                                <Badge color={'danger'}>MISSING</Badge>
                              ) : (
                                row.original.travelerStatus
                              ),
                          },
                          // {
                          //   Header: 'CRM Link',
                          //   Cell: row => (
                          //     <a
                          //       target="_blank"
                          //       href={
                          //         process.env.REACT_APP_CRMLINK +
                          //         row.original.traveler_Id
                          //       }
                          //       rel="noopener noreferrer"
                          //     >
                          //       Link to CRM
                          //     </a>
                          //   ),
                          // },
                        ],
                      },
                    ]}
                  />
                </div>
              )}
              columns={[
                {
                  columns: [
                    {
                      headerClassName: 'text-center',
                      sortable: false,
                      filterable: false,
                      width: 60,
                      Cell: row => (
                        <span className="badge rounded-pill bg-light">
                          {row.index + 1}
                        </span>
                      ),
                    },
                    {
                      headerClassName: 'text-left',
                      sortable: false,
                      filterable: false,
                      width: 35,
                      Cell: row => (
                        <>
                          <IoIosBed />
                        </>
                      ),
                    },
                    {
                      Header: 'Room Name',
                      headerClassName: 'text-left',
                      accessor: 'roomName',
                    },
                    {
                      Header: 'Room Type',
                      headerClassName: 'text-left',
                      accessor: 'roomType',
                    },

                    {
                      Header: 'Package Type',
                      headerClassName: 'text-left',
                      accessor: 'packageType',
                    },
                    {
                      Header: 'Travel Date',
                      headerClassName: 'text-left',
                      accessor: 'travelDate',
                      Cell: props => formatDate(props.value, 'MM/dd/yyyy'),
                    },
                    {
                      Header: 'Nights',
                      headerClassName: 'text-left',
                      accessor: 'nights',
                    },
                    {
                      Header: 'Added Date',
                      headerClassName: 'text-left',
                      accessor: 'dateCreated',
                      Cell: props => formatDate(props.value, 'MM/dd/yyyy'),
                    },
                    {
                      Header: 'Status',
                      headerClassName: 'text-left',
                      accessor: 'status',
                      Cell: ({ original: { status } }) =>
                        (status as string)
                          ?.toLowerCase()
                          .startsWith('cancel') ? (
                          <Badge color={'danger'}>{status}</Badge>
                        ) : (
                          <>{status}</>
                        ),
                    },
                    {
                      Header: 'CRM Link',
                      headerClassName: 'text-left',
                      filterable: false,
                      Cell: row => (
                        <a
                          target="_blank"
                          href={
                            process.env.REACT_APP_CRMLINK + row.original.roomId
                          }
                          rel="noopener noreferrer"
                        >
                          Link to CRM
                        </a>
                      ),
                    },
                  ],
                },
              ]}
              defaultPageSize={20}
              pageSizeOptions={[20, 50, 100]}
              rowsText="rows per page"
              filterable
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
