/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-empty */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Button,
  FormGroup,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import 'react-image-lightbox/style.css';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray } from 'formik';
import hotel from '../../../../api/hotelContent';

import { TextField } from '../../../../common/Form/TextInput';
import { TextCurrency } from '../../../../common/Form/TextInputCurrency';
import { SelectInput } from '../../../../common/Form/SelectInput';
import { CustomDatePicker } from '../../../../common/Form/DatePicker';

class StaticPricing extends React.Component {
  // state = StaticPricing.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  validationSchema = Yup.object({
    // supplierID: Yup.string().required('The supplier is required.'),
    numberOfAdult: Yup.string().required('Adult number is required.'),
    // numberOfChild: Yup.string().required('The children number is required.'),

    priceSchedule: Yup.array(
      Yup.object({
        effectiveDate: Yup.string().required('Effective Date is required.'),
        // expirationDate: Yup.string().required('The expiration Date is required.'),
        pricePerPersonPerNight: Yup.string()
          .required('The Price Per Person Per Night is required.')
          .test(
            'is-decimal',
            'Either invalid decimal or the number has more than 6 digits after decimal',
            value => `${value}`.match(/^(\d{1,5}|\d{0,5}\.\d{1,6})$/),
          ),
      }),
    ),
  });

  validationSchemaChildren = Yup.object({
    // supplierID: Yup.string().required('The supplier is required.'),
    // numberOfAdult: Yup.string().required('The adult number is required.'),
    numberOfChild: Yup.string().required('Child number is required.'),

    priceSchedule: Yup.array(
      Yup.object({
        effectiveDate: Yup.string().required('Effective Date is required.'),
        startAge: Yup.string().required('From age is required.'),
        endAge: Yup.string().required('To age is required.'),
        // expirationDate: Yup.string().required('The expiration Date is required.'),
        pricePerPersonPerNight: Yup.string()
          .required('Price Per Person Per Night is required.')
          .test(
            'is-decimal',
            'Either invalid decimal or the number has more than 6 digits after decimal',
            value => `${value}`.match(/^(\d{1,5}|\d{0,5}\.\d{1,6})$/),
          ),
      }),
    ),
  });

  constructor(props) {
    super(props);

    this.state = {
      ...ToastContainer.defaultProps,
      modal: false,
      childrenmodal: false,
      staticPricing: [],
      childrenStaticPricing: [],
      adultlist: [],
      childlist: [],
      initialValues: {
        numberOfAdult: '',
        priceSchedule: [
          {
            pricePerPersonPerNight: '',
            effectiveDate: '',
            isEnabled: false,
            isSoldOut: false,
          },
        ],
      },
      initialValuesChildren: {
        numberOfChild: '',
        priceSchedule: [
          {
            pricePerPersonPerNight: '',
            startAge: '',
            endAge: '',
            effectiveDate: '',
            isEnabled: false,
            isSoldOut: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.getStaticPricing();
    this.getSupplier();
    this.getAdultlist();
    this.getChildlist();
  }

  getChildlist() {
    hotel.roomtype.childlist().then(response => {
      const children = [];
      response.forEach(element => {
        if (element !== 0) {
          const obj = { text: element, value: element };
          children.push(obj);
        }
      });
      this.setState({ childlist: children });
    });
  }

  getAdultlist() {
    hotel.roomtype.adultlist().then(response => {
      const adults = [];
      response.forEach(element => {
        if (element !== 0) {
          const obj = { text: element, value: element };
          adults.push(obj);
        }
      });

      this.setState({ adultlist: adults });
    });
  }

  getSupplier = () => {
    hotel.hotelcontent.supplierlist().then(() => {
      // this.setState({ supplierlist: response });
    });
  };

   formatDate=(date)=>{
    const dateFormatted= `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
    return dateFormatted;
  }

  savePricing = async values => {
    const newValues = { ...values };
    newValues.roomTypeID = this.props.match.params.id;
    newValues.hotelID = this.props.hotelId;
    newValues.numberOfChild = 0;
    newValues.priceSchedule = newValues.priceSchedule.map(value => ({
      ...value,
      effectiveDate: this.formatDate(value.effectiveDate) ,
    }));

    await hotel.roomtype.saveStaticPricing(newValues);
  };

  savePricingChildren = async values => {
    const newValues = { ...values };
    newValues.roomTypeID = this.props.match.params.id;
    newValues.hotelID = this.props.hotelId;
    newValues.numberOfAdult = 0;
    newValues.priceSchedule = newValues.priceSchedule.map(value => ({
      ...value,
      effectiveDate:this.formatDate(value.effectiveDate),
    }));
    await hotel.roomtype.saveStaticPricing(newValues);
  };

  updatePricing = values => {
    const newValues = { ...values };
    newValues.roomTypeID = this.props.match.params.id;
    newValues.hotelID = this.props.hotelId;

    newValues.priceSchedule = newValues.priceSchedule.map(value => ({
      ...value,
      effectiveDate: this.formatDate(value.effectiveDate) ,
    }));
    return hotel.roomtype.updateStaticPricing(newValues);
  };

  getStaticPricing = () => {
    const initialValues = {
      numberOfAdult: '',
      numberOfChild: '',
      priceSchedule: [
        {
          pricePerPersonPerNight: '',
          effectiveDate: '',
          expirationDate: '',
          isEnabled: false,
        },
      ],
    };
    this.setState({ initialValues });
    const roomTypeID = this.props.match.params.id;
    const { hotelId } = this.props;
    hotel.roomtype.getStaticPricing(hotelId, roomTypeID).then(response => {
      for (let i = 0; i < response.length; i++) {
        response[i].priceSchedule = response[i].priceSchedule.map(value => ({
          ...value,
          effectiveDate: new Date(value.effectiveDate),
        }));
      }

      const adultpricing = response.filter(
        x => x.numberOfAdult > 0 && x.numberOfChild === 0,
      );
      const childrenpricing = response.filter(
        x => x.numberOfChild > 0 && x.numberOfAdult === 0,
      );

      this.setState({ staticPricing: adultpricing });
      this.setState({ childrenStaticPricing: childrenpricing });
    });
  };

  ordinalSuffixOf = i => {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
      return `${i}st`;
    }
    if (j === 2 && k !== 12) {
      return `${i}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  };

  render() {
    return (
      <>
        <Card className="mb-4 mt-4">
          <CardHeader>Adult Pricing</CardHeader>
          <CardBody>
            <Button
              className="mt-3"
              color="primary"
              onClick={() => {
                this.setState({ modal: true });
              }}
            >
              Add Adult Price
            </Button>
            {this.state.staticPricing.map((value, index) => (
              <div key={index}>
                <Row>
                  <div className="col-md-2" />

                  <div className="col-md-8">
                    <Card className="mb-4 mt-4">
                      <CardHeader>
                        <h6>
                          Update Pricing - {value.numberOfAdult} adults per room
                        </h6>
                      </CardHeader>
                      <CardBody>
                        <Formik
                          validationSchema={this.validationSchema}
                          enableReinitialize
                          initialValues={{
                            roomTypeStaticPricingID:
                              value.roomTypeStaticPricingID,
                            numberOfAdult: value.numberOfAdult,
                            priceSchedule: value.priceSchedule,
                          }}
                          onSubmit={async values => {
                            try {
                              const response = await this.updatePricing(values);
                              toast.success(response);
                              this.getStaticPricing();
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        >
                          {({ values, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit} autoComplete="off">
                              <FieldArray name="priceSchedule">
                                {({ push, remove }) => (
                                  <>
                                    {values.priceSchedule.map(
                                      (value, index) => (
                                        <Row className="mt-2" key={index}>
                                          <Col className="col-md-5">
                                            <CustomDatePicker
                                              label="Effective"
                                              name={`priceSchedule[${index}].effectiveDate`}
                                            />
                                          </Col>

                                          <Col className="col-md-3">
                                            <TextCurrency
                                              label="Per Person Per Night"
                                              name={`priceSchedule[${index}].pricePerPersonPerNight`}
                                            />
                                          </Col>

                                          <div>
                                            <label
                                              htmlFor={`priceSchedule[${index}].isEnabled`}
                                              className="ml-3 form-check-label"
                                            >
                                              Enable
                                            </label>
                                            <Field
                                              type="checkbox"
                                              style={{ marginTop: '40px' }}
                                              className="form-check-input"
                                              name={`priceSchedule[${index}].isEnabled`}
                                            />
                                          </div>

                                          <div className="ml-4">
                                            <label
                                              htmlFor={`priceSchedule[${index}].isSoldOut`}
                                              className=" form-check-label"
                                            >
                                              {' '}
                                              Sold Out
                                            </label>
                                            <Field
                                              type="checkbox"
                                              style={{ marginTop: '40px' }}
                                              className="form-check-input"
                                              name={`priceSchedule[${index}].isSoldOut`}
                                            />
                                          </div>

                                          <Col>
                                            <Button
                                              style={{ marginTop: '30px' }}
                                              onClick={() => remove(index)}
                                              color="danger"
                                              className="mb-2 mr-2 btn-icon btn-icon-only"
                                            >
                                              {' '}
                                              <i className="pe-7s-trash btn-icon-wrapper">
                                                {' '}
                                              </i>
                                            </Button>
                                          </Col>
                                        </Row>
                                      ),
                                    )}

                                    <Button
                                      className="mt-3"
                                      onClick={() =>
                                        push({
                                          pricePerPersonPerNight: '',
                                          effectiveDate: new Date(),
                                        })
                                      }
                                      color="success"
                                    >
                                      <i className="pe-7s-plus btn-icon-wrapper">
                                        {' '}
                                      </i>{' '}
                                      Add{' '}
                                    </Button>
                                  </>
                                )}
                              </FieldArray>

                              <Row className="mt-3">
                                <Button
                                  style={{ marginLeft: '10px' }}
                                  type="submit"
                                  disabled={isSubmitting}
                                  color="primary"
                                >
                                  {isSubmitting ? (
                                    <span>Processing....</span>
                                  ) : (
                                    <span>Save</span>
                                  )}
                                </Button>
                              </Row>

                              {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                            </Form>
                          )}
                        </Formik>
                      </CardBody>
                    </Card>
                  </div>
                </Row>
              </div>
            ))}
          </CardBody>
        </Card>

        <Card className="mb-4 mt-4">
          <CardHeader>Child Pricing</CardHeader>
          <CardBody>
            <Button
              className="mt-3"
              color="primary"
              onClick={() => {
                this.setState({ childrenmodal: true });
              }}
            >
              Add Child Price
            </Button>
            {this.state.childrenStaticPricing.map((value, index) => (
              <div key={index}>
                <Row>
                  <div className="col-md-2" />
                  <div className="col-md-9">
                    <Card className="mb-4 mt-4">
                      <CardHeader>
                        {' '}
                        <h6>
                          Update Pricing -{' '}
                          {this.ordinalSuffixOf(value.numberOfChild)} child
                          pricing
                        </h6>
                      </CardHeader>
                      <CardBody>
                        <Formik
                          validationSchema={this.validationSchemaChildren}
                          enableReinitialize
                          initialValues={{
                            roomTypeStaticPricingID:
                              value.roomTypeStaticPricingID,
                            numberOfChild: value.numberOfChild,
                            priceSchedule: value.priceSchedule,
                          }}
                          onSubmit={async values => {
                            try {
                              const response = await this.updatePricing(values);
                              toast.success(response);
                              this.getStaticPricing();
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        >
                          {({ values, handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit} autoComplete="off">
                              <FieldArray name="priceSchedule">
                                {({ push, remove }) => (
                                  <>
                                    {values.priceSchedule.map(
                                      (value, index) => (
                                        <Row className="mt-2" key={index}>
                                          <div className="col-md-4">
                                            <CustomDatePicker
                                              label="Effective"
                                              name={`priceSchedule[${index}].effectiveDate`}
                                            />
                                          </div>

                                          <Col className="col-md-2">
                                            <TextField
                                              type="number"
                                              min="0"
                                              label="From Age"
                                              name={`priceSchedule[${index}].startAge`}
                                            />
                                          </Col>

                                          <Col className="col-md-2">
                                            <TextField
                                              type="number"
                                              min="0"
                                              label="To Age"
                                              name={`priceSchedule[${index}].endAge`}
                                            />
                                          </Col>

                                          <Col className="col-md-2">
                                            <TextCurrency
                                              label="Per Person Per Night"
                                              name={`priceSchedule[${index}].pricePerPersonPerNight`}
                                            />
                                          </Col>

                                          <div>
                                            <label
                                              htmlFor="enabled"
                                              className="ml-3 form-check-label"
                                            >
                                              Enable
                                            </label>
                                            <Field
                                              type="checkbox"
                                              style={{ marginTop: '40px' }}
                                              className="form-check-input"
                                              name={`priceSchedule[${index}].isEnabled`}
                                            />
                                          </div>
                                          <Col>
                                            <Button
                                              style={{ marginTop: '30px' }}
                                              onClick={() => remove(index)}
                                              color="danger"
                                              className="mb-2 mr-2 btn-icon btn-icon-only"
                                            >
                                              {' '}
                                              <i className="pe-7s-trash btn-icon-wrapper">
                                                {' '}
                                              </i>
                                            </Button>
                                          </Col>
                                        </Row>
                                      ),
                                    )}

                                    <Button
                                      className="mt-3"
                                      onClick={() =>
                                        push({
                                          pricePerPersonPerNight: '',
                                          effectiveDate: new Date(),
                                          startAge: '',
                                          endAge: '',
                                        })
                                      }
                                      color="success"
                                    >
                                      <i className="pe-7s-plus btn-icon-wrapper">
                                        {' '}
                                      </i>{' '}
                                      Add{' '}
                                    </Button>
                                  </>
                                )}
                              </FieldArray>

                              <Row className="mt-3">
                                <Button
                                  style={{ marginLeft: '10px' }}
                                  type="submit"
                                  disabled={isSubmitting}
                                  color="primary"
                                >
                                  {isSubmitting ? (
                                    <span>Processing....</span>
                                  ) : (
                                    <span>Save</span>
                                  )}
                                </Button>
                              </Row>

                              {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                            </Form>
                          )}
                        </Formik>
                      </CardBody>
                    </Card>
                  </div>
                </Row>
              </div>
            ))}
          </CardBody>
        </Card>

        <Modal
          isOpen={this.state.modal}
          toggle={this.togglepopup}
          className="modal-xl"
        >
          <ModalHeader toggle={this.togglepopup}>Add Adult Pricing</ModalHeader>

          <Formik
            validationSchema={this.validationSchema}
            enableReinitialize
            initialValues={this.state.initialValues}
            onSubmit={async (values, { resetForm }) => {
              try {
                await this.savePricing(values);
                toast.success('Pricing saved successfully.');
                this.setState({ modal: false });
                resetForm();
                this.getStaticPricing();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {({ values, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <ModalBody>
                  <Row>
                    <div className="col-md-4">
                      <FormGroup>
                        <SelectInput
                          options={this.state.adultlist}
                          label="Adults"
                          name="numberOfAdult"
                        />
                      </FormGroup>
                    </div>
                  </Row>
                  <FieldArray name="priceSchedule">
                    {({ push, remove }) => (
                      <>
                        {values.priceSchedule.map((value, index) => (
                          <Row className="mt-2" key={index}>
                            <Col className="col-md-4">
                              <CustomDatePicker
                                label="Effective"
                                name={`priceSchedule[${index}].effectiveDate`}
                              />
                            </Col>
                            <Col className="col-md-2">
                              <TextCurrency
                                label="Per Person Per Night"
                                name={`priceSchedule[${index}].pricePerPersonPerNight`}
                              />
                            </Col>
                            <div>
                              <label
                                htmlFor="enabled"
                                className="form-check-label ml-1"
                              >
                                Enabled
                              </label>
                              <Field
                                type="checkbox"
                                style={{ marginTop: '40px' }}
                                className="form-check-input"
                                name={`priceSchedule[${index}].isEnabled`}
                              />
                            </div>

                            <div className="ml-3">
                              <label
                                htmlFor="enabled"
                                className="ml-1 form-check-label"
                              >
                                Sold Out
                              </label>
                              <Field
                                type="checkbox"
                                style={{ marginTop: '40px' }}
                                className="form-check-input"
                                name={`priceSchedule[${index}].isSoldOut`}
                              />
                            </div>

                            <Col>
                              {index > 0 && (
                                <Button
                                  style={{ marginTop: '30px' }}
                                  onClick={() => remove(index)}
                                  color="danger"
                                  className="mb-2 mr-2 btn-icon btn-icon-only"
                                >
                                  {' '}
                                  <i className="pe-7s-trash btn-icon-wrapper">
                                    {' '}
                                  </i>
                                </Button>
                              )}
                            </Col>
                          </Row>
                        ))}

                        <Button
                          className="mt-3 "
                          onClick={() =>
                            push({
                              pricePerPersonPerNight: '',
                              effectiveDate: new Date(),
                              isEnabled: false,
                              soldOut: false,
                            })
                          }
                          color="success"
                        >
                          <i className="pe-7s-plus btn-icon-wrapper"> </i> Add{' '}
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </ModalBody>

                <ModalFooter>
                  <Row className="mt-3 mr-2">
                    <Button
                      style={{ marginLeft: '10px' }}
                      type="submit"
                      disabled={isSubmitting}
                      color="primary"
                    >
                      {isSubmitting ? (
                        <span>Processing....</span>
                      ) : (
                        <span>Save</span>
                      )}
                    </Button>
                    <Button
                      style={{ marginLeft: '5px' }}
                      color="danger"
                      onClick={() => this.setState({ modal: false })}
                    >
                      Close
                    </Button>
                  </Row>
                </ModalFooter>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
        </Modal>

        <Modal
          isOpen={this.state.childrenmodal}
          toggle={this.togglepopup}
          className="modal-xl"
        >
          <ModalHeader toggle={this.togglepopup}>Add Child Pricing</ModalHeader>

          <Formik
            validationSchema={this.validationSchemaChildren}
            enableReinitialize
            initialValues={this.state.initialValuesChildren}
            onSubmit={async (values, { resetForm }) => {
              try {
                await this.savePricingChildren(values);
                toast.success('Pricing saved successfully.');
                this.setState({ childrenmodal: false });
                resetForm();
                this.getStaticPricing();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            {({ values, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit} autoComplete="off">
                <ModalBody>
                  <Row>
                    <div className="col-md-4">
                      <FormGroup>
                        <SelectInput
                          options={this.state.childlist}
                          label="Child"
                          name="numberOfChild"
                        />
                      </FormGroup>
                    </div>
                  </Row>
                  <FieldArray name="priceSchedule">
                    {({ push, remove }) => (
                      <>
                        {values.priceSchedule.map((value, index) => (
                          <Row className="mt-2" key={index}>
                            <Col className="col-md-4">
                              <CustomDatePicker
                                label="Effective"
                                name={`priceSchedule[${index}].effectiveDate`}
                              />
                            </Col>

                            <Col className="col-md-1">
                              <TextField
                                type="number"
                                min="0"
                                label="From Age"
                                name={`priceSchedule[${index}].startAge`}
                              />
                            </Col>
                            <Col className="col-md-1">
                              <TextField
                                type="number"
                                min="0"
                                label="To Age"
                                name={`priceSchedule[${index}].endAge`}
                              />
                            </Col>
                            <Col className="col-md-2">
                              <TextCurrency
                                label="Per Person Per Night"
                                name={`priceSchedule[${index}].pricePerPersonPerNight`}
                              />
                            </Col>
                            <div>
                              <label
                                htmlFor="enabled"
                                className="form-check-label"
                              >
                                Enabled
                              </label>
                              <Field
                                type="checkbox"
                                style={{ marginTop: '40px' }}
                                className="form-check-input"
                                name={`priceSchedule[${index}].isEnabled`}
                              />
                            </div>

                            {/* <div className="ml-3">
                                                            <label  htmlFor="enabled" className="ml-3 form-check-label">Sold Out</label>
                                                            <Field type="checkbox" style={{ marginTop: "40px" }} className="form-check-input" name={`priceSchedule[${index}].isSoldOut`} />

                                                        </div> */}

                            <Col>
                              {index > 0 && (
                                <Button
                                  style={{ marginTop: '30px' }}
                                  onClick={() => remove(index)}
                                  color="danger"
                                  className="mb-2 mr-2 btn-icon btn-icon-only"
                                >
                                  {' '}
                                  <i className="pe-7s-trash btn-icon-wrapper">
                                    {' '}
                                  </i>{' '}
                                </Button>
                              )}
                            </Col>
                          </Row>
                        ))}

                        <Button
                          className="mt-3 "
                          onClick={() =>
                            push({
                              startAge: '',
                              endAge: '',
                              pricePerPersonPerNight: '',
                              effectiveDate: new Date(),
                              isEnabled: false,
                              soldOut: false,
                            })
                          }
                          color="success"
                        >
                          <i className="pe-7s-plus btn-icon-wrapper"> </i> Add{' '}
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </ModalBody>

                <ModalFooter>
                  <Row className="mt-3 mr-2">
                    <Button
                      style={{ marginLeft: '10px' }}
                      type="submit"
                      disabled={isSubmitting}
                      color="primary"
                    >
                      {isSubmitting ? (
                        <span>Processing....</span>
                      ) : (
                        <span>Save</span>
                      )}
                    </Button>
                    <Button
                      style={{ marginLeft: '5px' }}
                      color="danger"
                      onClick={() => this.setState({ childrenmodal: false })}
                    >
                      Close
                    </Button>
                  </Row>
                </ModalFooter>

                {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
              </Form>
            )}
          </Formik>
        </Modal>
      </>
    );
  }
}

export default withRouter(StaticPricing);