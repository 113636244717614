import React, { createRef } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import ReactTable from 'react-table';
import usersApi from 'api/user';
import { Link, withRouter } from 'react-router-dom';
import PageTitle from 'Layout/AppMain/PageTitle';
import { format } from 'date-fns-tz';
import { toast } from 'react-toastify';
import { ODataTableHelper } from 'common/OData/ODataTableHelper';
import { SalesJourneyODataApiClient } from 'api/OData/SalesJourneyODataApiClient';
import { LegacyLiveBookingODataApiClient } from 'api/OData/LegacyLiveBookingODataApiClient';
import {
  LegacyLiveBookingODataEntities,
  SalesJourneyODataEntities,
} from 'api/OData/LegacyLiveBooking/ODataEntities';
import { ILegacyLiveBookingOasisUserDetails } from 'api/OData/LegacyLiveBooking/ODataModels/ODataUserDetailsModel';
import ODataDataTableComponent, {
  IODataDataTableComponentState,
} from 'common/OData/components/BaseODataDataTableComponent';

import Filter from './components/_Filter';
import { SALES_LEAD_STATUS } from '../slice/types';

import { getDebouncedFunc } from 'utils/utils';
import ImpersonationModal from 'common/Modals/ImpersonationModal';

const debounceFun = getDebouncedFunc();

interface ISalesJourneyLeadsPageState extends IODataDataTableComponentState {
  totalDataCount: number;
  tableData: ISalesLeadsDataRecord[];
  pages: number | null;
  loading: boolean;
  showImpersonationModal: boolean;
  impersonationLink: string | null;
  dataTablePageSize: number;
  dataTableCurrentPage: number;
  dataTableSorting: Array<{ id: string; desc: boolean }>;

  lastPageVisitedOptions: Array<{ value: string; label: string }>;
  weddingStatusOptions: Array<{ value: string; label: string }>;

  matchingLegacyDashboardUserDetails: Array<ILegacyLiveBookingOasisUserDetails>;
  incomingFilter: { id: keyof ISalesLeadsDataRecord; value: any } | null;
  activeSalesLeadRecord: any;
}

export interface ISalesLeadsDataRecord {
  Id: string;
  FullName: string | null;
  Email: string | null;
  CreatedDate: string | Date | null;
  AnticipatedWeddingDate: string | Date | null;
  LastModifiedDate: string | Date | null;
  GroupSize: string | number | null;
  PtidUserId: string | null;
  ActiveCampaignContactId: string | number | null;
  LgbtqWedding: string | boolean | null;
  LastPageVisited: string[] | null; //multi-select
  LastVisitedDate: string | Date | null;
  Spouse1FullName: string | null;
  WeddingDateRequestedDate: string | Date | null;
  WeddingStatus: string[] | null; //multi-select
  Spouse2FullName: string | null;
  SalesLeadStatus: (keyof typeof SALES_LEAD_STATUS)[] | null; //multi-select
}

const formatDate = (value?: string) => {
  if (!value) {
    return <span>N/A</span>;
  }
  return <span>{format(new Date(value), 'MM/dd/yyyy hh:mm:ss a')}</span>;
};

const formatAnticipatedWeddingDate = (value?: string) => {
  if (!value) {
    return <span>N/A</span>;
  }
  // This slices the Z format from date object "2024-09-16T00:00:00Z" to accurately display date.
  // As format Z - This date time has no offset from UTC (or 0 hour offset).
  // https://www.progress.com/blogs/understanding-iso-8601-date-and-time-format

  return <span>{format(new Date(value.slice(0, -1)), 'MM/dd/yyyy')}</span>;
};
class SalesJourneySalesLeadsPage extends ODataDataTableComponent<
  ISalesLeadsDataRecord,
  ISalesJourneyLeadsPageState
> {
  reactTable: React.RefObject<any> = createRef();
  odataApiClient = new SalesJourneyODataApiClient();
  csvExportFileNamePrefix = 'Sales-Journey-Sales-Leads';
  odataEntityType = SalesJourneyODataEntities.OasisSalesLeadWeddingDetails;

  state: ISalesJourneyLeadsPageState = {
    totalDataCount: 0,
    tableData: [],
    pages: null,
    loading: true,
    showImpersonationModal: false,
    impersonationLink: null,
    dataTablePageSize: 10,
    dataTableCurrentPage: 0,
    dataTableSorting: [],
    lastPageVisitedOptions: [],
    weddingStatusOptions: [],
    matchingLegacyDashboardUserDetails: [],
    incomingFilter: null,
    filters: [
      { name: 'CreatedDate', modifier: null, value: null },
      { name: 'GroupSize', modifier: null, value: null },
      { name: 'LgbtqWedding', value: null },
      { name: 'AnticipatedWeddingDate', modifier: null, value: null },
      { name: 'LastModifiedDate', modifier: null, value: null },
      { name: 'LastPageVisited', value: null, modifier: null },
      { name: 'LastVisitedDate', modifier: null, value: null },
      { name: 'WeddingDateRequestedDate', modifier: null, value: null },
      { name: 'WeddingStatus', value: null, modifier: null },
      { name: 'SalesLeadStatus', value: null, modifier: null },
      { name: 'AssignedAgentID', value: null, modifier: null },
    ],
    activeSalesLeadRecord: null,
  };

  private legacyLiveBookingOdataApiClient =
    new LegacyLiveBookingODataApiClient();

  fetchData = async reactTableState => {
    this.setState({ loading: true });

    this.setState({
      dataTablePageSize: reactTableState.pageSize,
      dataTableCurrentPage: reactTableState.page,
      dataTableSorting: [...reactTableState.sorted],
    });

    const queryOptions = {
      pageSize: reactTableState.pageSize,
      currentPage: reactTableState.page,
      dataTableSort: reactTableState.sorted.map(({ id, desc }) => ({
        columnName: id,
        sortOrder: desc ? 'desc' : 'asc',
      })),
      dataTableFilters: reactTableState.filtered.map(({ id, value }) => ({
        columnName: id,
        value,
      })),
      filters: this.state.filters.filter(f => {
        //if the modifier is 'eq null' we want to keep the filter, even though, the value *will* be null
        if (f.modifier === 'eq null') return true;
        //otherwise, if it's any other modifier, filter out null values
        return f.value !== null;
      }),
    };

    const fullNameFilter = queryOptions.dataTableFilters?.find(
      f => f.columnName.toLowerCase() === 'fullname',
    );
    let freeFormColumnFilterArgs = '';
    if (fullNameFilter) {
      const { value } = fullNameFilter;
      queryOptions.dataTableFilters = queryOptions.dataTableFilters.filter(
        f => f.columnName.toLowerCase() !== 'fullname',
      );
      freeFormColumnFilterArgs = `(contains(concat( concat(FirstName,' '),LastName),'${value}'))`;
    }
    if (
      queryOptions.dataTableSort.find(
        f => f.columnName.toLowerCase() === 'fullname',
      )
    )
      queryOptions.dataTableSort.find(
        f => f.columnName.toLowerCase() === 'fullname',
      ).columnName = 'FirstName';
    const queryStringArgs = ODataTableHelper.constructODataQueryArgs(
      queryOptions.pageSize,
      queryOptions.currentPage,
      queryOptions.dataTableFilters,
      queryOptions.dataTableSort,
      queryOptions.filters,
      true,
      freeFormColumnFilterArgs,
    );

    const { data, count } =
      await this.odataApiClient.getODataListResponseWithCount<ISalesLeadsDataRecord>(
        `OasisSalesLeadWeddingDetails?${queryStringArgs}`,
      );

    const lookupPtidUserIds = data.map(d => d.PtidUserId).filter(e => !!e);

    if (lookupPtidUserIds.length > 0) {
      try {
        const matchingLegacyLiveBookingUserDetails =
          await this.legacyLiveBookingOdataApiClient.getBatchData<
            ILegacyLiveBookingOasisUserDetails[]
          >(
            LegacyLiveBookingODataEntities.OasisUserDetails,
            `Id in (${lookupPtidUserIds.map(id => `'${id}'`).join(',')})`,
          );
        if (
          matchingLegacyLiveBookingUserDetails &&
          matchingLegacyLiveBookingUserDetails.length > 0
        ) {
          this.setState({
            matchingLegacyDashboardUserDetails: [
              ...matchingLegacyLiveBookingUserDetails,
            ],
          });
        }
      } catch (error) {
        console.error(
          'Legacy Live Booking OData Batch Invocation Error',
          error,
        );
        toast.error(
          'Unexpected server side or network error. Please try again or contact the dev team.',
        );
      }
    }

    const totalDataCount = count;
    this.setState({
      tableData: data,
      pages: Math.ceil(totalDataCount / reactTableState.pageSize),
      loading: false,
      totalDataCount,
    });
  };

  async componentDidMount() {
    if (localStorage.getItem('searchValue')) {
      const ptiUserIdFilter = JSON.parse(localStorage.getItem('searchValue'));
      this.setState({
        incomingFilter: {
          id: 'PtidUserId',
          value: ptiUserIdFilter.PtidUserId,
        },
      });
      localStorage.removeItem('searchValue');
    }
  }

  async impersonateUser(row) {
    this.setState({ loading: true });
    try {
      const response = await usersApi.authUsers.impersonate(row.PtidUserId);
      this.setState({
        showImpersonationModal: true,
        impersonationLink: response,
      });
    } catch (e) {
      console.error(e);
      toast.error('Could not generate impersonation link');
    } finally {
      this.setState({ loading: false });
    }
  }

  closeImpersonatePopup() {
    this.setState({ showImpersonationModal: false });
  }

  render() {
    const {
      tableData,
      pages,
      loading,
      totalDataCount,
      filters,
      matchingLegacyDashboardUserDetails,
      incomingFilter,
    } = this.state;

    return (
      <>
        <div>
          <>
            <div>
              <PageTitle
                heading="Sales Journey Sales Leads"
                icon="pe-7s-headphones icon-gradient bg-tempting-azure"
                subheading="Sales Leads from the Sales Journey Database"
              />
            </div>

            <Row>
              <Col md="12">
                <Filter
                  updateFilters={this.updateFilters}
                  clearAllFilters={this.clearAllFilters}
                  filters={filters}
                  onSearch={async () => {
                    await this.fetchData(this.reactTable.current?.state);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardHeader>
                    <div className="btn-actions-pane-right"></div>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      manual
                      data={tableData}
                      pages={pages}
                      loading={loading}
                      onFetchData={debounceFun(this.fetchData)}
                      filterable
                      defaultPageSize={10}
                      className="-striped -highlight"
                      multiSort={false}
                      defaultSorted={[
                        {
                          // the sorting model for the table
                          id: 'WeddingDateRequestedDate',
                          desc: true,
                        },
                      ]}
                      ref={this.reactTable}
                      columns={[
                        {
                          //hiding this since it might be useful for developers to easily find corresponding
                          //record id in the database
                          Header: 'Wedding Details Id',
                          filterable: false,
                          accessor: 'Id',
                          show: false,
                        },
                        {
                          Header: 'Full Name',
                          Cell: row => {
                            const match =
                              matchingLegacyDashboardUserDetails.find(
                                u =>
                                  u.Id.toLowerCase() ===
                                  row.original.PtidUserId.toLowerCase(),
                              );
                            if (!match) return '';
                            return (
                              <>
                                <span
                                  id={'link-' + match.Id}
                                  className="anchor-link"
                                  onClick={() => {
                                    const searchValue = {
                                      PtidUserId: match.Id,
                                    };
                                    localStorage.setItem(
                                      'searchValue',
                                      JSON.stringify(searchValue),
                                    );
                                    this.props.history.push({
                                      pathname: `/salesleads/guest-list/${match.Id}`,
                                    });
                                  }}
                                >
                                  {match.FullName}
                                </span>
                              </>
                            );
                          },
                          filterable: true,
                          accessor: 'FullName',
                        },
                        {
                          Header: 'Email',
                          Cell: row => {
                            const match =
                              matchingLegacyDashboardUserDetails.find(
                                u =>
                                  u.Id.toLowerCase() ===
                                  row.original.PtidUserId.toLowerCase(),
                              );
                            return match?.Email ?? '';
                          },
                          accessor: 'Email',
                          filterable: true,
                        },
                        {
                          Header: 'Created Date',
                          accessor: 'CreatedDate',
                          Cell: props => formatDate(props.value),
                          filterable: false,
                        },
                        {
                          Header: 'Anticipated Wedding Date',
                          accessor: 'AnticipatedWeddingDate',
                          Cell: props =>
                            formatAnticipatedWeddingDate(props.value),
                          filterable: false,
                        },
                        {
                          Header: 'Last Active Date',
                          accessor: 'LastVisitedDate',
                          Cell: props => formatDate(props.value),
                          filterable: false,
                        },
                        {
                          Header: 'Last Edited',
                          accessor: 'LastModifiedDate',
                          Cell: props => formatDate(props.value),
                          filterable: false,
                        },
                        {
                          Header: 'Group Size',
                          accessor: 'GroupSize',
                          filterable: false,
                          maxWidth: 75,
                        },
                        {
                          Header: 'Wedding Status',
                          accessor: 'WeddingStatus',
                          filterable: false,
                        },
                        {
                          Header: 'Sales Status',
                          accessor: 'SalesLeadStatus',
                          filterable: false,
                        },
                        {
                          Header: 'Date Wedding Date Requested',
                          accessor: 'WeddingDateRequestedDate',
                          Cell: props => formatDate(props.value),
                          filterable: false,
                        },
                        {
                          Header: 'Last Page Visited',
                          accessor: 'LastPageVisited',
                          filterable: false,
                        },
                        {
                          Header: 'Action',
                          columns: [
                            {
                              Header: 'Settings',
                              sortable: false,
                              filterable: false,
                              Cell: ({ row }) => {
                                return (
                                  <UncontrolledButtonDropdown>
                                    <DropdownToggle
                                      caret
                                      className="mb-2 ml-2"
                                      color="primary"
                                    >
                                      Action
                                    </DropdownToggle>
                                    <DropdownMenu
                                      positionFixed={true}
                                      className="dropdown-menu-rounded dropdown-menu-lg"
                                    >
                                      {row._original.PtidUserId ? (
                                        <>
                                          <DropdownItem>
                                            <Link
                                              to={`/salesleads/guest-list/${row._original.PtidUserId}`}
                                              style={{ width: '100%' }}
                                            >
                                              Manage
                                            </Link>
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() =>
                                              this.impersonateUser(
                                                row._original,
                                              )
                                            }
                                          >
                                            Impersonate
                                          </DropdownItem>
                                        </>
                                      ) : (
                                        <DropdownItem disabled>
                                          Impersonation not available
                                        </DropdownItem>
                                      )}

                                      {row._original.CRMLeadId && (
                                        <>
                                          <DropdownItem>
                                            <a
                                              href={`${process.env.REACT_APP_CRMLINK.replace(
                                                'Opportunities',
                                                'Leads',
                                              )}${row._original.CRMLeadId}`}
                                              title="View Details in CRM"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              View Lead Record in CRM
                                            </a>
                                          </DropdownItem>
                                        </>
                                      )}
                                      {row._original.CRMGroupId && (
                                        <>
                                          <DropdownItem>
                                            <a
                                              href={`${process.env.REACT_APP_CRMLINK.replace(
                                                'Opportunities',
                                                'Accounts',
                                              )}${row._original.CRMGroupId}`}
                                              title="View Details in CRM"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              View Group Record in CRM
                                            </a>
                                          </DropdownItem>
                                        </>
                                      )}
                                    </DropdownMenu>
                                  </UncontrolledButtonDropdown>
                                );
                              },
                            },
                          ],
                        },
                      ]}
                      pageSizeOptions={[10, 20, 30, 100]}
                      showPaginationTop
                      showPaginationBottom
                      defaultFiltered={incomingFilter ? [incomingFilter] : []}
                    />
                    <>
                      <Row>
                        <Col>
                          <span>
                            <strong>Total Sales Leads: </strong>
                            {totalDataCount}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <UncontrolledButtonDropdown>
                            <DropdownToggle
                              caret
                              className="mb-2 mr-2"
                              color="primary"
                            >
                              Download .csv
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                              <DropdownItem
                                onClick={() =>
                                  this.handleExportToCSV('currentPage')
                                }
                              >
                                Current Page
                              </DropdownItem>
                              <DropdownItem
                                onClick={() =>
                                  this.handleExportToCSV('filtered')
                                }
                              >
                                Filtered Leads
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => this.handleExportToCSV('all')}
                              >
                                All Leads
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </Col>
                      </Row>
                    </>

                    <ImpersonationModal
                      isOpen={this.state.showImpersonationModal}
                      closeUserDetailPopup={this.closeImpersonatePopup.bind(
                        this,
                      )}
                      impersonationLink={this.state.impersonationLink}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        </div>
      </>
    );
  }
}

export default withRouter(SalesJourneySalesLeadsPage);
