import React from 'react';

import {
  Button,
  
  Card,
  CardBody,
  CardTitle,
  Container } from 'reactstrap';

import {
} from 'react-toastify';

import { TransitionGroup } from 'react-transition-group';

import { withRouter } from 'react-router-dom';
import usersApi from '../../api/user';
import ValidationError from './ValidationError';

import AppFooter from '../../Layout/AppFooter';

class TestErrors extends React.Component {
  // state = TestErrors.getDefaultState();

  // static getDefaultState() {
  //   return {
  //     ...ToastContainer.defaultProps,
  //     transition: 'bounce',
  //     type: 'success',
  //     disableAutoClose: false,
  //   };
  // }

  // baseUrl = 'http://localhost:5001/api/'

  constructor(props) {    
    super(props);  
    this.state = { errors: null }
  }
 
  handleUnauthorised=() => {
    usersApi.buggy.handleUnauthorised().then(() => {})
  }

  handleServerError = () => {
    usersApi.buggy.handleServerError().then(() => {})   
  }

  handleBadRequest= () => {
    usersApi.buggy.handleBadRequest().then(() => {})         
  }

  handleNotFound= () => {
    usersApi.buggy.handleNotFound().then(() => {})  
  }

  render() {
    const { errors } = this.state;

    return (
      <>
          
        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
    
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Admin User</CardTitle>
                   
                <Button onClick={ this.handleNotFound } className="mt-1 btn btn-primary"><span>Not Found</span> </Button>
                <Button onClick={ this.handleBadRequest } className="mt-1 btn btn-primary"><span>Bad Request</span> </Button>                
                <Button onClick={ this.handleServerError } className="mt-1 btn btn-primary"><span>Server Error</span> </Button>
                <Button onClick={ this.handleUnauthorised } className="mt-1 btn btn-primary"><span>Unauthorised</span> </Button>                
             
              </CardBody>
            </Card>
            {errors && <ValidationError errors={ errors } />}
          </Container>
         
        </TransitionGroup>
        <AppFooter />
      
      </>
    );
  }
}
export default withRouter(TestErrors)
