import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { transferTypeSaga } from './saga';
import { IState } from './types';

export const initialState: IState = {
  transferTypes: {
    loading: true,
    items: []
  }
};

const slice = createSlice({
  name: 'transferTypes',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.transferTypes.loading = action.payload;
    },
    loadTransferTypes() {
    },
    setTransferTypes(state, action) {
      state.transferTypes.items = action.payload;
      state.transferTypes.loading = false;
    },
  },
});
export const { actions: TransferTypesActions } = slice;
export const useTransferTypeSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: transferTypeSaga });
  return { actions: slice.actions };
};
