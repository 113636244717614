import {
  destifyAdminApiRequests,
  destifyODataAdminApiRequests,
  destifyLegacyDashboardApiRequests
} from './common';

const guestUsers = {
  list: (userType, showUserSalesData) =>
    destifyAdminApiRequests.get(
      `user/GuestUser/${userType}/${showUserSalesData}`,
    ),
  getSalesJourneyUsers: () =>
    destifyAdminApiRequests.get('user/GetSalesJourneyUsers'),
  activeEmail: data => destifyAdminApiRequests.post('user/ActivateUser', data),
  deactivate: data => destifyAdminApiRequests.post('user/Deactivate', data),
  saveUser: data => destifyAdminApiRequests.post('user/SaveUser', data),
  getGuestRoomDetail: param =>
    destifyAdminApiRequests.get('user/GuestRoomDetails', { params: { ...param } }),
  getWeddingDetails: id =>
    destifyAdminApiRequests.get(`SalesLead/WeddingDetailsByUserId/${id}`),
  groupDetail: param =>
    destifyAdminApiRequests.get(`User/GuestRoomDetails?userId=${param}`),
  assignUserToRoom: data =>
    destifyAdminApiRequests.post('User/AssignUserToRoom', data),
  unassignUserFromRoom: data =>
    destifyAdminApiRequests.post('User/UnassignUserFromRoom', data),
  getLeadInfoByLeadId: id =>
    destifyAdminApiRequests.get(`CRMSales/LeadInfoByLeadId?CRMLeadId=${id}`),
  getClientAccountsRoomDetails: (ptidUserIds)=>destifyAdminApiRequests.post('User/GetClientAccountRoomDetails',ptidUserIds),
  getClientAccountDetails: id => destifyAdminApiRequests.get(`User/ClientDetails/${id}`),
  getUserGroups: (id: string) => destifyAdminApiRequests.get(`User/${id}/groups`),
  setGroupLeader: (userId: string, groupId: string) => destifyAdminApiRequests.post(`User/${userId}/groups/${groupId}/leader`, {}),
};

const authUsers = {
  login: body => destifyAdminApiRequests.post('Auth/authenticate', body),
  list: () => destifyAdminApiRequests.get('Auth/adminUserList'),
  registerUser: body => destifyAdminApiRequests.post('Auth/register', body),
  listAllAdminRoles: () => destifyAdminApiRequests.get('Auth/rolelist'),
  impersonate: userId =>
    destifyAdminApiRequests.get('User/GenerateImpersonationToken',{params:{userId}}),
  getUserDetails: (id, isAdminTypeUser) => {
    let path = `Auth/UserDetails/${id}`;
    if (isAdminTypeUser) {
      path += '?isAdminTypeUser=true';
    }
    return destifyAdminApiRequests.get(path);
  },
  editAdmin: param => destifyAdminApiRequests.put('Auth/EditUser/', param),
  updateStatus: (id, isAdminTypeUser) => {
    let path = `Auth/EditUserStatus/${id}`;
    if (isAdminTypeUser) {
      path += '?isAdminTypeUser=true';
    }
    return destifyAdminApiRequests.put(path);
  },
  getUserDashboard: () =>
    destifyAdminApiRequests.get('Dashboard/GetUserDashboardTile'),
  changeUserRole: body =>
    destifyAdminApiRequests.post('Auth/ChangeUserRole', body),
};

const buggy = {
  handleUnauthorised: () => destifyAdminApiRequests.get('buggy/unauthorised'),
  handleServerError: () => destifyAdminApiRequests.get('buggy/server-error'),
  handleBadRequest: () => destifyAdminApiRequests.get('buggy/bad-request'),
  handleNotFound: () => destifyAdminApiRequests.get('buggy/not-found'),    
};

const ODataCrmDataAccess = {
  getListResponse: odataFilterArgs =>
    destifyODataAdminApiRequests.getListResponse(
      `OasisSugarCrmLeads?${odataFilterArgs || ''}`,
    ),
  get: url => destifyODataAdminApiRequests.get(url),
  post: (url, body) => destifyODataAdminApiRequests.post(url, body),
};

const destifyLegacyDashboardAccess = {
  get: url => destifyLegacyDashboardApiRequests.get(url),
  post: (url, body) => destifyLegacyDashboardApiRequests.post(url, body),
}

const adminUsers = {
  uploadImage: body => destifyAdminApiRequests.post('AdminProfile/UpsertProfilePicture', body),
  deleteImage: id =>
    destifyAdminApiRequests.delete(`AdminProfile/RemoveProfilePicture/${id}`),
}

const usersApi = {
  guestUsers,
  authUsers,
  buggy,
  ODataCrmDataAccess,
  destifyLegacyDashboardAccess,
  adminUsers
};

export default usersApi;
