import React, { } from 'react';
import { Route } from 'react-router-dom';

// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

import HotelContent from './HotelContent/hotelContent';
import CreateHotel from './HotelContent/GeneralInfo/create-hotel';
import Manage from './HotelContent/manage';
import ManageRoomType from './HotelContent/RoomTypes/manage';
import CreateRoomType from './HotelContent/RoomTypes/create';
import HotelChainList from './HotelChain/list';
import HotelDestinationList from './Destinations/list';
import RoomFeature from './RoomFeature/index'
import StaticPricingIngestionLogs from "./StaticPricingIngestionLogs"
import TransferTypes from "./TransferContent/TransferTypes"
import TransferSuppliers from "./TransferContent/Suppliers"

// HotelChain
import CreateHotelChain from './HotelChain/create';

function ManageHotelContent() {
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Route path="/hotels/hotel-content" component={ HotelContent } />
            <Route path="/hotels/create" component={ CreateHotel } />                    
            <Route path="/hotels/hotel-content-manage/:id" component={ Manage } />
            <Route path="/hotels/hotel-roomType-manage/:id" component={ ManageRoomType } />
            <Route path="/hotels/room-feature" component={ RoomFeature } />
            <Route path="/hotels/create-hotel-roomType/:id" component={ CreateRoomType } />
            <Route path="/hotels/hotel-chain-list" component={ HotelChainList } />
            <Route path="/hotels/hotel-destinations" component={ HotelDestinationList } />                   
            <Route path="/hotels/create-hotelchain" component={ CreateHotelChain } />
            <Route path="/hotels/update-hotelchain/:id" component={ CreateHotelChain } />
            <Route path="/hotels/ingestion-job-status" component={ StaticPricingIngestionLogs } />
            <Route exact path="/hotels/transfer-types" component={ TransferTypes } />
            <Route exact path="/hotels/transfer-types/manage/:id" component={ () => <h1>TODO: Manage Transfer Page</h1> } />
            <Route exact path="/hotels/transfer-suppliers" component={ TransferSuppliers } />
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  )
}

export default ManageHotelContent;
