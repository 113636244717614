/* eslint-disable import/no-unresolved */
import { takeLatest } from 'redux-saga/effects';

import { actions } from '.';
import { signinRedirect } from '../../../auth/AuthService';

export function* signInRedirect() {
  try {
    yield signinRedirect()
  } catch (e) {
    console.log(e)
  }
}

export function* authSaga() {
  yield takeLatest(actions.signInRedirect.type, signInRedirect);
}
