import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './index';
import { RootState } from 'types/RootState';

const selectSlice = (state: RootState) => state.users || initialState;
export const selectUserAccountDetails = createSelector(
  [selectSlice],
  state => ({
    loading: state.loading,
    error: state.error,
    details: state.details,
  }),
);

export const selectIsLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectUserCommunicationDetails = createSelector(
  [selectSlice],
  state => state.communication,
);

export const selectUserRoomDetails = createSelector(
  [selectSlice],
  state => state.userRoomDetails,
);

export const selectUserGroups = createSelector(
  [selectSlice],
  state => state.userGroups,
);
