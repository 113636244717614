import React, { useState, useEffect, useRef } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Select,{Option} from 'common/Select';
import Modal from 'common/Modals';
import ClosedReasonsList from './_ClosedReasons';

import {addDays,zonedTimeToUtc} from "common/utils/dateUtils"

import { useDebounce } from 'hooks';


import { SALES_LEAD_STATUS } from '../../../slice/types';

import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useSalesLeadSlice } from '../../../slice';
import { selectSalesStatus } from '../../../slice/selectors';

const DaysField = ({ value, setValue }) => {
  return <Input value={value} min={0} type={'number'} onChange={e => setValue(e.target.value)} />;
};

const _Status = ({salesLeadId,statusId}:{salesLeadId:number,statusId:number}) => {
  const [status, setStatus] = useState({} as Option);
  const dispatch = useAppDispatch();
  const { actions } = useSalesLeadSlice();
  const { list, loading } = useAppSelector(selectSalesStatus);

  const [daysCount, setDaysCount] = useState("");

  const [showM1, setShowM1] = useState(false);
  const [showM2, setShowM2] = useState(false);

  const [selectedCloseReason,setSelectedCloseReason] = useState({} as Option);

  const [debouncedUpdateStatus,cancelUpdate] = useDebounce(data => {
    dispatch(
      actions.updateSalesLeadStatus(data),
    );
  });

  useEffect(() => {
    if(loading || !list.length) return;
    const item = list.find(item => +item.value === statusId);
    if(!item) return;
    setStatus({
      label: item.text as unknown as string,
      value: item.value
    })
  },[statusId,list,loading])

  const handleCancelDaysField = () => {
    cancelUpdate();
    setStatus(prevStatusRef.current);
    setShowM1(false);
  };

  const handleSaveDaysField = () => {
    if(!parseInt(daysCount)) return;
    const date = zonedTimeToUtc(addDays(new Date(),parseInt(daysCount)));
    const ISOdate = new Date(date).toISOString()
    debouncedUpdateStatus({
        statusId: status.value,
        salesLeadID: salesLeadId,
        reachOutDate: ISOdate,
        salesLeadClosedReasonId: null,
    }).then(() => {
      setShowM1(false);
    }).catch(err => setStatus(prevStatusRef.current))
  }

  const handleCancelReasonToCloseLead = () => {
    cancelUpdate();
    setStatus(prevStatusRef.current);
    setShowM2(false);
  };

  const handleSaveReasonToCloseLead = () => {
    if(!("value" in selectedCloseReason)) return;
    debouncedUpdateStatus({
        statusId: status.value,
        salesLeadID: salesLeadId,
        reachOutDate: null,
        salesLeadClosedReasonId: selectedCloseReason.value,
    })
    .then(() => setShowM2(false))
    .catch(err => {
      setStatus(prevStatusRef.current);
      setSelectedCloseReason(prevClosedReasonRef.current);
    })
    .finally(() => {
      setSelectedCloseReason({} as Option)
    })
  }

  const prevStatusRef = useRef({} as Option);
  const prevClosedReasonRef = useRef({} as Option);

  useEffect(() => {
    dispatch(actions.getSalesStatusList());
    dispatch(actions.getClosedReasonAll());
  }, [dispatch, actions]);

  const updateStatus = async item => {
    prevStatusRef.current = status;
    setStatus(item);
    if (+item.value === SALES_LEAD_STATUS['Not Ready']) {
      //show a modal with input for days
      setShowM1(true);
    } else if (+item.value === SALES_LEAD_STATUS.Closed) {
      //show modal with list of closed reasons
      setShowM2(true);
      //continue to send api request
    }else {
        debouncedUpdateStatus({
            statusId: item.value,
            salesLeadID: salesLeadId,
            reachOutDate: null,
            salesLeadClosedReasonId: null,
          }).catch(err => {
            console.error(err);
            setStatus(prevStatusRef.current);
          })
    }
  };

  const handleChangeCloseReason = (item) => {
    const prev = {...selectedCloseReason};
    prevClosedReasonRef.current = prev;
    setSelectedCloseReason(item)
  }

  return (
    <>
      <Modal
        isOpen={showM1}
        onClose={handleCancelDaysField}
        onSave={handleSaveDaysField}
        title={'Number of Days for Follow up'}
        disabled={!parseInt(daysCount)}
      >
        <DaysField value={daysCount} setValue={setDaysCount} />
      </Modal>
      <Modal
        isOpen={showM2}
        onClose={handleCancelReasonToCloseLead}
        onSave={handleSaveReasonToCloseLead}
        title={'Reason to Close Lead'}
        disabled={!("value" in selectedCloseReason)}
      >
        <ClosedReasonsList value={selectedCloseReason} handleChange={handleChangeCloseReason} />
      </Modal>
      <Label className="list-details" style={{ marginTop: '1rem' }}>
        Status:{' '}
      </Label>
      <FormGroup style={{ marginBottom: '1rem' }}>
        <Select
          items={list}
          initialValue={status}
          autoClose
          onChange={updateStatus}
          extractor={({ text, value }) => ({
            label: text.toString(),
            value,
          })}
          disabled={loading}
        />
      </FormGroup>
    </>
  );
};

export default _Status;
