import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { storeUser } from './auth/authActions';

function LoginSuccess() {
  return <div>Logging you in. Please wait...</div>;
}
const mapStateToProp = state => ({
  user: state.auth.user,
  isLoadingUser: state.auth.isLoadingUser,
});

// export default withRouter(LoginSuccess);
export default withRouter(connect(mapStateToProp, { storeUser })(LoginSuccess));
