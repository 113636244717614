//TODO: maybe return the fallback here? idk
//import avatar from 'assets/utils/images/avatars/default.png';

export const getAdminProfilePictureUrl = (adminUserGuid: string) => {

	//if we decide to return avatar fallback
	/*if (!process.env.REACT_APP_ADMIN_PROFILE_IMAGES_S3_BUCKET || !process.env.REACT_APP_ADMIN_PROFILE_IMAGES_S3_FOLDER_PATH ) {
		return avatar;
    }*/
	
	//if we dont have the info needed to create the s3 url, return just an empty string
	if (!process.env.REACT_APP_ADMIN_PROFILE_IMAGES_S3_BUCKET || !process.env.REACT_APP_ADMIN_PROFILE_IMAGES_S3_FOLDER_PATH) {
		return '';
    }
	return `https://${process.env.REACT_APP_ADMIN_PROFILE_IMAGES_S3_BUCKET}.s3.amazonaws.com/profile-pictures/${process.env.REACT_APP_ADMIN_PROFILE_IMAGES_S3_FOLDER_PATH}/${adminUserGuid}.png`;
	
}

export const getDebouncedFunc = () => {
  let timerId: NodeJS.Timeout = null;
  return (callback: (...args:any[]) => void, delay?: number) => {
	delay = Math.min(Math.max(1,delay ?? 1),5);
    return (...args:any[]) => {
      if (timerId !== null) clearTimeout(timerId);
      timerId = setTimeout(() => {
        callback(...args);
        timerId = null;
      }, delay * 1000);
    };
  };
};
export const getFormattedDate = (
	date: Date | string,
	format: Intl.DateTimeFormatOptions = {
	  month: 'numeric',
	  year: 'numeric',
	  day: 'numeric',
	  hour: 'numeric',
	  minute: 'numeric',
	},
  ): string => {

	if(date === "") return date;
	const dateFormatter = new Intl.DateTimeFormat('en-US', format);
  
	  try {
		if(typeof date === 'string') {
		  return dateFormatter.format(new Date(date));
		} else {
		  return  dateFormatter.format(date);
		}
  
	  } catch (error) {
		console.error(error);
	  }
  }

export const escapeSpecialChars = (str: string) =>
  ['+'].reduce((str, sp) => str.replaceAll(sp, encodeURIComponent(sp)), str);