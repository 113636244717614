/* eslint-disable react/prop-types */
import React from 'react';
import cx from 'classnames';

import { connect } from 'react-redux';

import HeaderLogo from '../AppLogo';

import UserBox from './Components/UserBox';

function Header(props) {
  const { headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow } =
    props;
  return (
    <div
      className={cx('app-header', headerBackgroundColor, {
        'header-shadow': enableHeaderShadow,
      })}
    >
      <HeaderLogo />
      <div
        className={cx('app-header__content', {
          'header-mobile-open': enableMobileMenuSmall,
        })}
      >
        <div className="app-header-left">
          {/* <SearchBox /> */}
          {/* <MegaMenu /> */}
        </div>
        <div className="app-header-right">
          {/* <HeaderDots /> */}
          <UserBox />
          {/* <HeaderRightDrawer /> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
