import './utils/strings'

export interface IAppConfig {
	readonly Api: {
		readonly AdminBackendApi: {
			BaseUrl: string;
		};
		readonly DestifyServicesApi: {
			BaseUrl: string;
			ApiToken: string;
		};
		readonly SalesJourneyApi: {
			BaseUrl: string;
			ApiToken: string;
		};
		readonly LegacyLiveBookingApi: {
			BaseUrl: string,
			ApiToken: string;
		};
	};
	readonly FeatureFlags: {
		CrmSalesLeadListPageEnabled: boolean;
		SalesJourneySalesLeadListPageEnabled: boolean;
	};
	//todo add additional strongly typed settings here
}

const configDefaults:IAppConfig = {
	Api: {
		AdminBackendApi: {
			BaseUrl: process.env.REACT_APP_API_BASEURL
		},
		DestifyServicesApi: {
			BaseUrl: process.env.REACT_APP_BASE_URL,
			ApiToken: process.env.REACT_APP_AUTH_KEY
		},
		SalesJourneyApi: {
			BaseUrl: process.env.REACT_APP_SALESJOURNEY_API_URL,
			ApiToken: process.env.REACT_APP_SALESJOURNEY_API_KEY
		},
		LegacyLiveBookingApi: {
			BaseUrl: process.env.REACT_APP_LEGACY_LIVEBOOKING_URL,
			ApiToken: process.env.REACT_APP_LEGACY_LIVEBOOKING_API_KEY
		}
	},
	FeatureFlags: null,
	
	
};

export const AppConfig = configDefaults;