/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling, otherwise pre-commit hook fails, when linting.
import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import ReactTable from 'react-table';

import SweetAlert from 'sweetalert-react';
import { toast } from 'react-toastify';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import usersApi from '../../../api/user';

export default class AdminUserList extends React.Component {
  userId;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      adminUserList: [],
      statusText: '',
      isStatusEnable: false,

    };
  }

  async userListing() {
    this.setState({ loading: true });
    const adminUserList = await usersApi.authUsers.list();
    this.setState({ adminUserList, loading: false });
  }

  async componentDidMount() {
    await this.userListing();
  }

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    const content = row[id];
    if (typeof content !== 'undefined') {
      if (filter.value.length < 3) {
        // filter by text in the table or if it's a object, filter by key
        if (typeof content === 'object' && content !== null && content.key) {
          return String(content.key)
            .toLowerCase()
            .startsWith(filter.value.toLowerCase());
        }
        return String(content)
          .toLowerCase()
          .startsWith(filter.value.toLowerCase());
      }
      // filter by text in the table or if it's a object, filter by key
      if (typeof content === 'object' && content !== null && content.key) {
        return String(content.key)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      }
      return String(content).toLowerCase().includes(filter.value.toLowerCase());
    }

    return true;
  };

  /** Show a confirmation dialog, before doing the action */
  showToggleUserActiveConfirmation = (row, enable) => {
    this.userId = row.userId;
    this.setState({
      statusText: `Are you sure you want to ${(enable ? 'enable' : 'disable')} this user?`,
      show: true,
      isStatusEnable: true,
    });
  };

  async changeUserStatus() {
    await usersApi.authUsers.updateStatus(this.userId, true);
    toast.success('User status changed successfully.');
    this.setState({ show: false });
    await this.userListing();
  }

  render() {
    const { isStatusEnable } = this.state;
    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text={ this.state.statusText }
          showCancelButton
          onConfirm={ () => {
            if (isStatusEnable) {
              this.changeUserStatus();
            }
          } }
          onCancel={ () => {
            this.setState({ show: false });
          } }
          onClose={ () => { } } // eslint-disable-line no-console
        />

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <div>
            <PageTitle
              heading="Admin Users"
              subheading="Internal Destify User List"
              icon="pe-7s-medal icon-gradient bg-tempting-azure"
            />
          </div>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader>
                  <Button
                    className="mb-2 mr-2 btn-icon float-right"
                    color="primary"
                    onClick={ () => {
                      this.props.history.push('/user/admin-register');
                    } }
                  >
                    Register Admin User
                  </Button>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={ this.state.adminUserList }
                    loading={ this.state.loading }
                    columns={ [
                      {
                        // introduce userId as an idp agnostic prop that our calls will use
                        // this is a hidden column
                        id: 'userId',
                        accessor: 'id', // (this.state.isCognitoMode ? 'cognitoUserId' : 'id'),
                        show: false,
                      },
                      {
                        columns: [
                          {
                            Header: 'First Name',
                            accessor: 'firstName',
                          },
                          {
                            Header: 'Last Name',
                            accessor: 'lastName',
                          },
                        ],
                      },

                      {
                        columns: [
                          {
                            Header: 'Email',
                            accessor: 'email',
                            maxWidth: 420,
                            minWidth: 200,
                          },
                        ],
                      },
                      {
                        columns: [
                          {
                            Header: 'Role',
                            id: 'checkedroles',
                            accessor: (data) => data.checkedroles.join(', '),
                            sortable: false,
                            filterable: false,
                          },
                        ],
                      },
                      {
                        columns: [
                          {
                            Header: 'Status',
                            id: 'status',
                            filterable: false,
                            Cell: ({ row }) => (
                              <div>
                                {row._original.isActive && (
                                  <div className="mb-2 mr-2 badge badge-pill badge-success">
                                    {' '}
                                    Enabled
                                    {' '}
                                  </div>
                                )}
                                {!row._original.isActive && (
                                  <div className="mb-2 mr-2 badge badge-pill badge-danger">
                                    {' '}
                                    Disabled
                                    {' '}
                                  </div>
                                )}
                              </div>
                            ),
                          },
                        ],
                      },
                      {
                        columns: [
                          {
                            Header: 'Action',
                            sortable: false,
                            filterable: false,
                            Cell: ({ row }) => (
                              <UncontrolledButtonDropdown>
                                <DropdownToggle
                                  caret
                                  className="mb-2 mr-2"
                                  color="primary"
                                >
                                  Action
                                </DropdownToggle>
                                <DropdownMenu positionFixed={true} className="dropdown-menu-rounded dropdown-menu-lg">
                                  <DropdownItem
                                    onClick={ () => this.props.history.push(
                                      `/user/admin-edit/${row.userId}`,
                                    ) }
                                  >
                                    Manage
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  {!row._original.isActive && (
                                    <DropdownItem
                                      onClick={ () => this.showToggleUserActiveConfirmation(
                                        row,
                                        true,
                                      ) }
                                    >
                                      Enable
                                      {' '}
                                      {row._original.isActive}
                                    </DropdownItem>
                                  )}
                                  {row._original.isActive && (
                                    <DropdownItem
                                      onClick={ () => this.showToggleUserActiveConfirmation(
                                        row,
                                        false,
                                      ) }
                                    >
                                      Disable
                                    </DropdownItem>
                                  )}

                                </DropdownMenu>
                              </UncontrolledButtonDropdown>

                              // <button className="btn-wide mb-2 mr-2 btn btn-primary" onClick={() => value ? this.deactiveUser(row) : this.activateUser(row)}>{value ? "Deactivate" : "Activate"}</button>
                            ),
                          },
                        ],
                      },
                    ] }
                    defaultPageSize={ 10 }
                    filterable
                    defaultFilterMethod={ this.filterCaseInsensitive }
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TransitionGroup>
      </>
    );
  }
}
