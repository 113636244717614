import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { storeUser } from './auth/authActions';

class LogoutSuccess extends Component {
  componentDidMount() {
    // localStorage.clear();
    this.props.history.push('/pages/login');
  }

  render() {
    return <div>Logging you out. Please wait....</div>;
  }
}
const mapStateToProp = state => ({
  user: state.auth.user,
  isLoadingUser: state.auth.isLoadingUser,
});

export default withRouter(
  connect(mapStateToProp, { storeUser })(LogoutSuccess),
);
