/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Table,
} from 'reactstrap';

import SweetAlert from 'sweetalert-react';
import { Loader } from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify'
import weddingPackage from '../../../api/weddingPackage';
import PageTitle from '../../../Layout/AppMain/PageTitle';

class Destination extends React.Component {
  columns = [
    { title: 'Country', key: 'DestinationName', isSortable: true },
    { title: 'Room Rates', key: 'RoomRates', isSortable: true },
    { title: 'Wedding Rates', key: 'WeddingRates', isSortable: true },
    { title: 'Status', key: 'isActive', isSortable: true },
    { title: 'Action' },
  ];

  obj = [];

  //   state = Destination.getDefaultState();

  //   static getDefaultState() {
  //     return {
  //       ...ToastContainer.defaultProps,
  //       transition: 'bounce',
  //       type: 'success',
  //       disableAutoClose: false,
  //     };
  //   }

  queryObject = {};

  constructor(props) {
    super(props);

    this.queryObject.IsSortAsc = true;
    this.queryObject.PageNumber = 1;
    this.queryObject.PageSize = 10;
    this.queryObject.SortBy = '';
    this.queryObject.Search = this.obj;
    this.state = {
      destination: [],
      statustext: '',
    };
  }

  deleteId;

  async componentDidMount() {
    await this.loadDestination();
  }

  handleSearch = (e) => {
    this.queryObject.PageNumber = 1;
    const exist = this.obj.some((x) => x.columnSearchName === e.target.name);
    const length = [...e.target.value];

    if (!exist) {
      this.obj.push({
        columnSearchName: e.target.name,
        columnSearchValue: e.target.value,
      });
    } else {
      const index = this.obj.findIndex(
        (p) => p.columnSearchName === e.target.name
      );
      this.obj[index].columnSearchValue = e.target.value;
    }

    if (length.length >= 3 || length.length === 0) {
      this.loadDestination();
    }
  };

  async loadDestination() {
    this.setState({ active: true });
    const result = await weddingPackage.destination.getDestinationList(
      this.queryObject
    );
    this.setState({ destination: result.data, active: false });
    this.queryObject.PageNumber = result.pagination.currentPage;
    this.queryObject.PageSize = result.pagination.itemsPerPage;
    this.queryObject.totalItems = result.pagination.totalItems;
    this.setState({
      totalPages: result.pagination.totalPages,
    });
  }

  showConfirmationToDelete = (id) => {
    this.deleteId = id;
    this.setState({
      statustext: 'Are you sure to delete the destination/country?',
    });
    this.setState({ show: true });
  };

  deleteVenue() {
    weddingPackage.destination.deleteDestination(this.deleteId).then(() => {
      toast.success(
        'The destination/country deleted successfully successfully.'
      );
      this.setState({ show: false });
      this.loadDestination();
    });
  }

  handlePageClick = async (data) => {
    const { selected } = data;
    this.queryObject.PageNumber = selected + 1;
    await this.loadDestination();
  };

  updateStatus=(id) => {
    weddingPackage.destination.updateStatus(id).then((response) => {
      toast.success(
        response
      );
      
      this.loadDestination();
    });
  }

  render() {
    return (
      <>
        <SweetAlert
          show={ this.state.show }
          type="warning"
          title="Confirmation"
          text={ this.state.statustext }
          showCancelButton
          onConfirm={ () => {
            this.deleteVenue();
          } }
          onCancel={ () => {
            this.setState({ show: false });
          } }
          onClose={ () => {} } // eslint-disable-line no-console
        />

        <TransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear
          transitionAppearTimeout={ 0 }
          transitionEnter={ false }
          transitionLeave={ false }
        >
          <Container fluid>
            <div>
              <PageTitle
                heading="Country/Destination Management"
                icon="pe-7s-map-2 icon-gradient bg-tempting-azure"
              />
            </div>

            <LoadingOverlay
              tag="div"
              active={ this.state.active }
              styles={ {
                overlay: (base) => ({
                  ...base,
                  background: '#fff',
                  opacity: 0.5,
                }),
              } }
              spinner={ <Loader active /> }
            >
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Country/Destination List</CardTitle>
                  <Button
                    className="mb-2 mr-2 btn-icon float-right"
                    color="primary"
                    onClick={ () => {
                      this.props.history.push('/wedding/create-destination');
                    } }
                  >
                    Add Country/Destination
                  </Button>
                  <Table
                    size="md"
                    responsive
                    striped
                    bordered
                    hover
                    className="mb-3"
                  >
                    <thead>
                      <tr>
                        {this.columns.map((value, index) => (
                          <th key={ index }>{value.title}</th>
                        ))}
                      </tr>
                      <tr>
                        <th>
                          <input
                            type="text"
                            name="DestinationName"
                            onKeyUp={ this.handleSearch }
                            className="searchtext form-control"
                          />{' '}
                        </th>
                        <th> </th>
                        <th />
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.destination.length > 0
                        && this.state.destination.map((value, index) => (
                          <tr key={ index }>
                            <td>{value.destinationName}</td>
                            <td>{value.roomRates}</td>
                            <td>{value.weddingRates}</td>
                            <td>
                              {!value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-danger">
                                  {' '}
                                  Disabled{' '}
                                </div>
                              )}
                              {value.isActive && (
                                <div className="mb-2 mr-2 badge badge-pill badge-success">
                                  {' '}
                                  Enabled{' '}
                                </div>
                              )}
                            </td>
                            <td>
                              <UncontrolledButtonDropdown>
                                <DropdownToggle
                                  caret
                                  className="mb-2 mr-2"
                                  color="primary"
                                >
                                  Action
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg">
                                  <DropdownItem
                                    onClick={ () => this.props.history.push(
                                      `/wedding/edit-destination/${value.destinationID}`
                                    ) }
                                  >
                                    Manage
                                  </DropdownItem>

                                  <DropdownItem divider />

                                  <DropdownItem
                                    onClick={ () => this.showConfirmationToDelete(
                                      value.destinationID,
                                      true
                                    ) }
                                  >
                                    Delete
                                  </DropdownItem>
                                  <DropdownItem divider />
                                  
                                  <DropdownItem
                                    onClick={ () => this.updateStatus(value.destinationID) }
                                  >
                                    {value.isActive ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  
                                </DropdownMenu>
                              </UncontrolledButtonDropdown>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {this.state.destination.length > 0 && (
                    <nav aria-label="Page navigation">
                      <ReactPaginate
                        previousLabel="Previous"
                        nextLabel="Next"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={ this.state.totalPages }
                        marginPagesDisplayed={ 2 }
                        pageRangeDisplayed={ 5 }
                        onPageChange={ this.handlePageClick }
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        activeClassName="active"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                      />
                    </nav>
                  )}
                </CardBody>
              </Card>
            </LoadingOverlay>
          </Container>
        </TransitionGroup>
      </>
    );
  }
}

export default withRouter(Destination);
