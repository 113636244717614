import React, { Fragment, useState, useMemo } from 'react';
import SweetAlert from 'sweetalert-react'; // eslint-disable-line import/no-extraneous-dependencies
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table } from 'reactstrap';
import _ from 'lodash';
import { ExportToExcel } from '../../common/Export/ExportToExcel';
import Pagination, { usePagination } from '../../common/Pagination';
import hbsi from '../../api/hbsi';
import { InitialValue } from './types';
const obj = [
  { columnSearchName: 'tzBookingRef', columnSearchValue: '' },
  { columnSearchName: 'bookingNumber', columnSearchValue: '' },
  { columnSearchName: 'hotel', columnSearchValue: '' },
  { columnSearchName: 'roomType', columnSearchValue: '' },
];
const columns = [
  { title: 'Booking #', key: 'tzBookingRef', isSortable: true },
  { title: 'HBSI Booking #', key: 'bookingNumber', isSortable: true },
  { title: 'Hotel', key: 'hotel', isSortable: true },
  { title: 'Room Type', key: 'roomType', isSortable: true },
  { title: 'Total Price', key: 'totalBoookingCost', isSortable: true },
  { title: 'Booked Date', key: 'bookingDate', isSortable: true },
  { title: 'Check-in Date', key: 'checkInDate', isSortable: true },
];

const statusMappings = {
  book: 'Confirmed',
  cancel: 'Cancelled',
};

const HotelList = ({
  data,
  onUpdate,
  state,
}: {
  data: any[];
  onUpdate: (data: any) => void;
  state: InitialValue;
}) => {
  const items = _.orderBy(data, ['checkInDate'], ['asc']);
  const rawData = useMemo(() => {
    const { IsSortAsc, SortBy } = state || {};
    if (!SortBy) return;
    const result = _.orderBy(items, [SortBy], [IsSortAsc ? 'asc' : 'desc']);
    return result;
  }, [items, state]);
  const { page: initialPage = 1 } = useParams<{ page: any }>();
  const [focusedItem, setFocusedItem] = useState(null);
  const [filters, setFilters] = useState({} as Record<string, string>);
  const [showAlert, setShowAlert] = useState(false);
  const transfromToExcelFormat = <T extends Record<string, any>>(list: T[]) => {
    return list.map(item => ({
      'Booking #': item.tzBookingRef,
      'HBSI Booking #': item.bookingNumber,
      Hotel: item.hotel,
      'Room Type': item.roomType,
      Cost: item.totalBoookingCost,
      'Check-in Date': item.checkInDate,
      Status: statusMappings[item.status.toLowerCase()] || 'Unknown Status',
    }));
  };
  const filteredList = useMemo(() => {
    const fltrs = Object.entries(filters).filter(([_, keyword]) =>
      Boolean(keyword),
    );
    if (!fltrs.length) return rawData;
    const isMatch = (keyword: string, str: string) =>
      new RegExp(keyword, 'gi').test((str || '').toLowerCase());
    return rawData.filter(item =>
      fltrs.every(([fType, keyword]) => isMatch(keyword, item[fType])),
    );
  }, [filters, rawData]);
  const { offset, ...paginationProps } = usePagination({
    total: filteredList.length,
    initialPage,
    handleChange: ({ page }) => console.log({ page }),
  });
  const paginatedList = useMemo(() => {
    const t = filteredList.slice(offset.start, offset.end).map(item => ({
      ...item
    }))
    return t;
  },[filteredList,offset.start,offset.end])
  const excelData = useMemo(
    () => transfromToExcelFormat(filteredList),
    [filteredList],
  );
  const pageExcelData = excelData.slice(offset.start, offset.end);
  const toggleExpander = item => {
    if (focusedItem && focusedItem.bookingNumber === item.bookingNumber) {
      return setFocusedItem(null);
    }
    setFocusedItem(item);
  };
  const cancelBooking = () => {
    if (!focusedItem) return;
    hbsi.hbsiSearch
      .cancelBooking({
        bookingReference: focusedItem.tzBookingRef,
        hotelCode: focusedItem.bookingDetails.hotelCode,
      })
      .then(response => {
        toast.success(response);
      })
      .catch(() => {
        toast.error('Failed to cancel the booking. Server Error.');
      });
  };
  const cancelBookingConfirmation = () => {
    setShowAlert(true);
  };
  const sortBy = columnkey => {
    const { isSortable = false } =
      columns.find(col => col.key === columnkey) || {};
    if (!isSortable) return;
    const isApplied = state && state.SortBy === columnkey;
    if (isApplied) {
      //toggle
      onUpdate(prev => ({
        ...prev,
        IsSortAsc: !prev.sortAsc,
      }));
    } else {
      //add
      onUpdate({
        SortBy: columnkey,
      });
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filter = e.target.value.trim();
    const newFilters = { ...filters, [e.target.name]: filter };
    setFilters(newFilters);
  };
  const { SortBy: sortKey, IsSortAsc: isSortAsc } = state || {};
  return (
    <div>
      <SweetAlert
        show={showAlert}
        type="warning"
        title="Confirmation"
        text="Are you sure to cancel booking?"
        showCancelButton
        onConfirm={() => {
          cancelBooking();
          setShowAlert(false);
        }}
        onCancel={() => setShowAlert(false)}
        onClose={() => {}}
      />
      {rawData.length > 0 && (
        <ExportToExcel
          paginatedItems={pageExcelData}
          apiData={excelData}
          fileName="HBSI-booking-report"
        />
      )}
      <Table className="mb-10" bordered>
        <thead>
          <tr>
            {!!columns.length &&
              columns.map(value => {
                if (value.isSortable) {
                  return (
                    <th
                      key={value.key}
                      className={`pointer ${
                        sortKey !== value.key ? '' : isSortAsc ? 'asc' : 'desc'
                      }`}
                      onClick={() => sortBy(value.key)}
                    >
                      {value.title}
                    </th>
                  );
                }
                return <th key={value.key}>{value.title}</th>;
              })}
          </tr>
          {rawData.length > 0 && (
            <tr>
              {obj.map(item => {
                const name = item.columnSearchName;
                return (
                  <th>
                    <input
                      type="text"
                      autoComplete="off"
                      value={(filters && filters[name]) || ''}
                      name={name}
                      onChange={handleSearch}
                      className="searchtext form-control"
                    />
                  </th>
                );
              })}
            </tr>
          )}
        </thead>
        <tbody>
          {!!paginatedList.length &&
            paginatedList.map(hotel => (
              <Fragment key={hotel.bookingNumber}>
                <tr
                  onClick={() => toggleExpander(hotel)}
                  className={
                    focusedItem &&
                    focusedItem.bookingNumber === hotel.bookingNumber
                      ? 'table-primary'
                      : ''
                  }
                >
                  {columns.map(column => {
                    return <td key={column.key + hotel.bookingNumber}>{hotel[column.key]}</td>;
                  })}
                  <td className="text-center">
                    {hotel.status.toLowerCase() === 'book' && (
                      <div className="badge badge-pill badge-success">
                        {' '}
                        Confirmed{' '}
                      </div>
                    )}
                    {hotel.status.toLowerCase() === 'cancel' && (
                      <div className="badge badge-pill badge-danger">
                        {' '}
                        Cancelled{' '}
                      </div>
                    )}
                  </td>
                </tr>
                <tr
                  className={`${
                    focusedItem &&
                    focusedItem.bookingNumber === hotel.bookingNumber
                      ? ''
                      : 'collapse'
                  } childData`}
                >
                  <td colSpan={columns.length}>
                    <div className="row">
                      <div className="col-3">
                        <p>
                          Booking #:{' '}
                          <span className="font-weight-bold">
                            {hotel.tzBookingRef}
                          </span>
                        </p>
                        <p>
                          HBSI Booking #:
                          <span className="font-weight-bold">
                            {' '}
                            {hotel.bookingNumber}
                          </span>
                        </p>
                        <ul className="list-group">
                          <li className="list-group-item  list-group-item-primary">
                            Guests
                          </li>
                          {hotel.bookingDetails.guestName &&
                            hotel.bookingDetails.guestName.map((gst, index) => (
                              <li className="list-group-item" key={index}>
                                {gst.firstName} {gst.lastName}
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="col-3">
                        <p>Hotel: {hotel.hotel}</p>
                        <p>Room Package: {hotel.bookingDetails.roomPackage}</p>
                        <p>Package Total: $ {hotel.bookingDetails.packageTotal}</p>
                      </div>
                      <div className="col-sm">
                        <p>Check-in: {hotel.bookingDetails.checkIn}</p>
                        <p>Check-out: {hotel.bookingDetails.checkOut}</p>
                        <p>Night number: {hotel.bookingDetails.numberNight}</p>
                        {hotel.status !== 'Cancel' && (
                          <button
                            type="button"
                            className="mb-2 mr-2 btn btn-danger"
                            onClick={cancelBookingConfirmation}
                          >
                            Cancel Booking
                          </button>
                        )}
                      </div>
                      <div className="col-sm" />
                    </div>
                  </td>
                </tr>
              </Fragment>
            ))}
        </tbody>
      </Table>
      <nav aria-label="Page navigation">
        <Pagination
          {...paginationProps}
          prevLabelHandle={'previous'}
          nextLabelHandle={'next'}
        />
      </nav>
      <ToastContainer />
    </div>
  );
};
export default HotelList;