import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { TextField } from 'common/Form/TextInput';

import { Button, Card, CardBody, CardHeader, Col, Row, FormGroup, CardFooter } from 'reactstrap';
import * as Yup from 'yup';
import { FlightInfoDetails, TransferInfoDetails} from '../Reservations/slice/types';

import styles from './style.module.scss';
import { useParams } from 'react-router-dom';
import transfersApi from '../../../api/transfers';
import { toast } from 'react-toastify';


const arrivalValidationSchema = Yup.object({
    airlineName: Yup.string().required('Please enter an airline!'),
    airlineConfirmationNo: Yup.string().required('Confirmation number is required!'),
    flightNo: Yup.string().required('Flight number is required!'),
    // arrivalTime: Yup.string().required('Arrival time is required!'),
  });

  const departureValidationSchema = Yup.object({
    airlineName: Yup.string().required('Please enter an airline!'),
    airlineConfirmationNo: Yup.string().required('Confirmation number is required!'),
    flightNo: Yup.string().required('Flight number is required!'),
    // departureTime: Yup.string().required('Departure time is required!'),
  });


export const FlightInfo = ({}: TransferInfoDetails): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const [isEditingArrival, setIsEditingArrival] = useState(false);
    const [isEditingDeparture, setIsEditingDeparture] = useState(false);


    const [ flightInfo, setFlightInfo ] = useState<TransferInfoDetails>({
      id: id,
      flightInformation: {
        airlineName: null,
        airlineConfirmationNo: null,
        flightNo: '',
        arrivalDate: '',
        departureDate: '',
      }
    })

    const { flightInformation } = flightInfo;

    
    useEffect(() => {
      getTransferDetailsById(id)
    },[])
  
    const getTransferDetailsById = async (id: string) => {
      const items  = await transfersApi.transferReservation.getTransferDetails(id);
  
      if(!items) {
        return;
      }
      setFlightInfo(items)

    }
    const arrivalInitialValues: FlightInfoDetails = {
          airlineName: flightInformation.airlineName,
          airlineConfirmationNo: flightInformation.airlineConfirmationNo,
          flightNo: flightInformation.flightNo,
          // arrivalTime: flightInformation.arrivalTime,
        
      };

      const departureInitialValues:FlightInfoDetails  = {
          airlineName: flightInformation.airlineName,
          airlineConfirmationNo: flightInformation.airlineConfirmationNo,
          flightNo: flightInformation.flightNo,
          // departureTime: flightInformation.departureTime,
      };


      const updateArrivalInfo = async (data) => {
        const values: TransferInfoDetails = {
          id: id,
          flightInformation: {
            airlineName: data.airlineName,
            airlineConfirmationNo: data.airlineConfirmationNo,
            flightNo: data.flightNo,
            arrivalTime: data.arrivalTime,
          },
        };
        try {
          const response = await transfersApi.updateTransferReservation.updateTransferDetails(id, values);
          setFlightInfo(response);
          toast.success('Flight details saved successfully');
          setIsEditingArrival(false);
        } catch (error) {
          console.error(error);
          toast.error('Flight details could not be saved');
          setIsEditingArrival(false);
        }
    
      };



      const updateDepartureInfo = async (data) => {
        const values: TransferInfoDetails = {
          id: id,
          flightInformation: {
            airlineName: data.airlineName,
            airlineConfirmationNo: data.airlineConfirmationNo,
            flightNo: data.flightNo,
            arrivalTime: data.arrivalTime,
          },
        };
        try {
          const response = await transfersApi.updateTransferReservation.updateTransferDetails(id, values);
          setFlightInfo(response);
          setIsEditingDeparture(false);
        } catch (error) {
          console.error(error);
          setIsEditingDeparture(false);
        }
    
      };

  return (
    <>

    <Row>
        <Col xs={12} md={6}>
           
            <Card className={styles.card}>
                <CardHeader className={styles.background}>Arrival Info</CardHeader>
                <Formik
                    onSubmit={async (values) => {
                      updateArrivalInfo(values)
                    }}
             
                    initialValues={arrivalInitialValues}
                    enableReinitialize
                    validationSchema={arrivalValidationSchema}
                >
                {({ resetForm, dirty, isSubmitting }) => {
                 return (
                <>
                <CardBody>
                    <Form>
                    <FormGroup className={styles.form_group}>
                        <label>Airline:{' '}</label>
                        <div className="list-details-value">
                            <TextField
                                type="string"
                                disabled={!isEditingArrival}
                                name={'airlineName'}
                                className={"form-control form-control-sm"}
                               
                                />
                        </div>
                    </FormGroup>
                    <FormGroup className={styles.form_group}>
                        <label>Flight Number:{' '}</label>
                        <div className="list-details-value">
                        <TextField
                            type="string"
                            disabled={!isEditingArrival}  
                            name={'flightNo'}                          
                            className={"form-control form-control-sm"}
                            />
                            </div>
                    </FormGroup>
                    <FormGroup className={styles.form_group}>
                        <label>Confirmation #:{' '}</label>  
                        <div className="list-details-value">
                        <TextField
                            type="string"
                            disabled={!isEditingArrival}
                            name={'airlineConfirmationNo'}
                            className={"form-control form-control-sm"}
                        />
                        </div>
                    </FormGroup>
                    {/* <FormGroup className={styles.form_group}>
                        <label>Arrival Time:{' '}</label>
                        <div className="list-details-value">
                        <TextField
                            type="string"
                            disabled={!isEditingArrival}
                            name={'arrivalTime'}
                            className={"form-control form-control-sm"}
                        />
                        </div>
                    </FormGroup> */}
                    
      
                <CardFooter className={styles.footer}>
                  <FormGroup>
                      {!isEditingArrival && (
                        <>
                        <Button
                          onClick={() => setIsEditingArrival(true)}
                          type={'button'}
                          size='lg'
                        >
            
                          Edit
                        </Button>
                      
                       </>
                      )}
                      {isEditingArrival && (
                        <>
                          <Button
                            className="mb-2 mr-2 btn-icon float-left"
                            color={'danger'}
                            type={'reset'}
                            size='lg'
                            onClick={() => {
                              resetForm();
                              setIsEditingArrival(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="mb-2 mr-2 btn-icon float-left"
                            color={'primary'}
                            disabled={!dirty}
                            type='submit'
                            size='lg'
                          >
                            Submit
                          </Button>
                        </>
                      )}
                     </FormGroup>
                     </CardFooter>
                      </Form>
                      </CardBody>   
                    </>
                    );
                }}
                </Formik>
        
            </Card>
            </Col>
            <Col xs={12} md={6}>
            <Card className={styles.card}>
                    <CardHeader className={styles.background}>Departure Info</CardHeader>
                    <Formik
                    onSubmit={async (values) => {
                      updateDepartureInfo(values)
                    }}
                    initialValues={departureInitialValues}
                    enableReinitialize
                    validationSchema={departureValidationSchema}
                >
                {({ resetForm, dirty, isSubmitting }) => {
                 return (
                <>
                <CardBody>
                    <Form>
                    <FormGroup className={styles.form_group}>
                        <label>Airline:{' '}</label>
                        <div className="list-details-value">
                            <TextField
                                type="string"
                                disabled={!isEditingDeparture}
                                name={'airlineName'}
                                className={"form-control form-control-sm"}
                               
                                />
                        </div>
                    </FormGroup>
                    <FormGroup className={styles.form_group}>
                        <label>Flight Number:{' '}</label>
                        <div className="list-details-value">
                        <TextField
                            type="string"
                            disabled={!isEditingDeparture}  
                            name={'flightNo'}                          
                            className={"form-control form-control-sm"}
                            />
                            </div>
                    </FormGroup>
                    <FormGroup className={styles.form_group}>
                        <label>Confirmation #:{' '}</label>  
                        <div className="list-details-value">
                        <TextField
                            type="string"
                            disabled={!isEditingDeparture}
                            name={'airlineConfirmationNo'}
                            className={"form-control form-control-sm"}
                        />
                        </div>
                    </FormGroup>
                    {/* <FormGroup className={styles.form_group}>
                        <label>Departure Time:{' '}</label>
                        <div className="list-details-value">
                        <TextField
                            type="string"
                            disabled={!isEditingDeparture}
                            name={'departureTime'}
                            className={"form-control form-control-sm"}
                        />
                        </div>
                    </FormGroup>           */}
                    <CardFooter className={styles.footer}>
                  <FormGroup>
                      {!isEditingDeparture && (
                        <>
                        <Button
                          onClick={() => setIsEditingDeparture(true)}
                          type={'button'}
                          size='lg'
                        >
            
                          Edit
                        </Button>
                      
                       </>
                      )}
                      {isEditingDeparture && (
                        <>
                          <Button
                            className="mb-2 mr-2 btn-icon float-left"
                            color={'danger'}
                            type={'reset'}
                            size='lg'
                            onClick={() => {
                              resetForm();
                              setIsEditingDeparture(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="mb-2 mr-2 btn-icon float-left"
                            color={'primary'}
                            disabled={!dirty}
                            type='submit'
                            size='lg'
                          >
                            Submit
                          </Button>
                        </>
                      )}
                     </FormGroup>
                     </CardFooter>
                      </Form>
                      </CardBody>   
                    </>
                    );
                }}
                </Formik>
        
                </Card>
            </Col>
        </Row>
            
    </>
  );
};

