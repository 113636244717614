import React from 'react';
import { CreateUpdateForm } from './_Form';

import { Props } from 'common/Modals';
import { IUpdateFormProps } from '../../slice/types';

type ModalProps = Pick<
  Props,
  'isOpen' | 'onClose'
>;

const UpdateForm: React.FC<IUpdateFormProps & ModalProps> = (props) => {
  return (
    <>
    {
      props.isOpen ? <CreateUpdateForm {...props} title={'Edit Transfer Type'} /> : null 
    }
    </>
  );
};

export default UpdateForm;
