/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
import React, { useLayoutEffect } from 'react';
import { selectThemeOptionsState } from 'themeSlice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Slider } from 'react-burgers';
import { ReactComponent as Logo } from "./logo.svg"

import AppMobileMenu from '../AppMobileMenu';
import { useThemeSlice } from '../../themeSlice';

import { useLocalStorage } from 'hooks'

function HeaderLogo() {
  const dispatch = useDispatch();
  const { actions } = useThemeSlice();
  const themeOptions = useSelector(selectThemeOptionsState);
  const [val,setVal] = useLocalStorage('sidebar-toggle-state',false);

  const toggleEnableClosedSidebar = () => {
    dispatch(actions.setEnableClosedSidebar(!themeOptions.enableClosedSidebar));
    setVal(!themeOptions.enableClosedSidebar);
  };

  useLayoutEffect(() => {
    dispatch(actions.setEnableClosedSidebar(val));
  }, [])

  return (
    <>
      <div className="app-header__logo">
        <div className="logo-src">
          <Logo />
        </div>
        <div className="header__pane ml-auto">
          <div onClick={toggleEnableClosedSidebar}>
            <Slider
              width={26}
              lineHeight={2}
              lineSpacing={5}
              color="#6c757d"
              active={!themeOptions.enableClosedSidebar}
            />
          </div>
        </div>
      </div>
      <AppMobileMenu />
    </>
  );
}
export default React.memo(HeaderLogo);
