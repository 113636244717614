import React from 'react';
import styles from './style.module.scss';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import { NoteProps, FormattedNote } from './_Note';

import { getFormattedDate } from '../../../../utils/utils';
export interface ModalProps {
  isOpen?: boolean;
  closeModal?: (event?: React.SyntheticEvent) => void;
}

export const NoteModal = ({
  id,
  type,
  note,
  module,
  createdAt,
  isOpen,
  adminID,
  closeModal,
}: NoteProps & ModalProps) => {
  return (
    <span>
      <Modal isOpen={isOpen} toggle={closeModal} size={'md'} id={id}>
        <ModalHeader>
          <span className="popover-header-title">Note Details</span>
        </ModalHeader>
        <ModalBody>
          <div className={styles.details}>
            {adminID && <div>{`Admin ID: ${adminID}`}</div>}
            {module && <div>{`Module: ${module}`}</div>}
            {type && <div>{`Type: ${type}`}</div>}
            {createdAt && <div>{`Created at: ${getFormattedDate(createdAt)}`} </div>}
            {note && <FormattedNote>{`Note: ${note}`}</FormattedNote>}
            <div className="popover-divider" />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </span>
  );
};
