import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { usersSaga } from './saga';
import IState, { IUserGroup } from './types';

export const initialState: IState = {
  loading: false,
  error: null,
  details: {
    firstName: "",
    lastName: "",
    address: "",
    email: "",
    phoneNumber: ""
  },
  communication: {
    style: "",
    level: ""
  },
  userRoomDetails: {
    loading: true,
    list: []
  },
  userGroups: {
    loading: true,
    list: [],
  },
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setError(state,action) {
      state.error = action.payload
    },
    setLoading(state,action:PayloadAction<boolean>) {
      state.loading = action.payload
    },
    loadClientAccountDetails(state,_action:PayloadAction<string>) {
      state.error = null;
      state.loading = true
    },
    setClientAccountDetails(state,action) {
      state.details = action.payload;
      state.loading = false
    },
    loadUserCommunicationDetails(_state,_action:PayloadAction<string[]>) {},
    setUserCommunicationDetails(state,action) {
      state.communication = action.payload
    },
    updateUserDetails(_state,_action:PayloadAction<{id:string,values:{}}>) {},
    setUserRoomDetails(state,action: PayloadAction<IState["userRoomDetails"]>) {
      state.userRoomDetails.loading = false;
      state.userRoomDetails.list = action.payload.list
    },
    getUserRoomDetails(state,_action:PayloadAction<{id:string}>) {
      state.userRoomDetails.loading = true;
    },
    disassociateRoomFromUser(_state,_action:PayloadAction<{userId:string, crmRoomId:string, userEmail:string, roomRole: string}>) {

    },
    assignUserToRoom(_state,_action:PayloadAction<{userEmail:string,roomId:string,roomRole:string,userId:string, isGroupLeader:boolean}>) {},
    setUserGroups(state, action: PayloadAction<IUserGroup[]>) {
      state.userGroups.loading = false;
      state.userGroups.list = action.payload;
    },
    loadUserGroups(state, _action: PayloadAction<{ id: string }>) {
      state.userGroups.loading = true;
    },
    makeGroupLeader(
      state,
      _action: PayloadAction<{ userId: string; groupId: string }>,
    ) {
      state.userGroups.loading = true;
    },
  },
});

export const { actions: UsersActions } = slice;

export const useUsersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: usersSaga });
  return { actions: slice.actions };
};
