import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import WeddingRequestHistory from '../../WeddingDate/components/DetailsView/_Request_History';
import WeddingRequestDetails from '../../WeddingDate/components/DetailsView/_Request_Details';
import WeddingConfirmDetails from '../../WeddingDate/components/DetailsView/_Confirmed_Details';

import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { useWeddingDateSlice } from '../../WeddingDate/slice';

const _WeddingDetails = () => {
  const dispatch = useAppDispatch();
  const { actions } = useWeddingDateSlice();
  const {id} = useParams<{id:string}>();

  React.useEffect(() => {
    return () => {
      dispatch(actions.setDetailsPageStatus({
        id: 0,
        status: false,
        userId: null
      }))
    }
  },[dispatch,actions,id])

  return (
    <Card style={{ marginTop: 10 }}>
      <CardHeader>
        <h5>Wedding Details</h5>
      </CardHeader>
      <CardBody>
        <WeddingRequestDetails showUserDetails={false} />
        <WeddingConfirmDetails />
        <WeddingRequestHistory />
      </CardBody>
    </Card>
  );
};

export default _WeddingDetails;
