import { put, takeLatest } from 'redux-saga/effects';
import destifyOdataClient from 'api/OData/DestifyAdminClient';
import { CustomerTransferReservationActions as actions } from './index';
import { toast } from 'react-toastify';

function* loadCustomerTransferReservations(action) {
  try {
    const url='odata/OasisDestifyHotelCustomerTransfers';
    const response  : any = yield destifyOdataClient.getODataListResponseWithCount(`${url}?${action.payload.queryString}`);
    yield put(actions.setCustomerTransferReservations(response.data));
    yield put (actions.setPages(Math.ceil(response.count / action.payload.pageSize)));
    yield put (actions.setTotalDataCount(response.count));
  } catch (e: any) {
    toast.error(e.message);
    yield actions.setLoading(false);
  }
}

function* loadCustomerTransferReservationsByDestifyUserId(action){
  try {
    const response  : any = yield destifyOdataClient.getCustomerTransferReservations(action.payload);
    yield put(actions.setCustomerTransferReservationsByDestifyUserId(response));
  } catch (e: any) {
    toast.error(e.message);
    yield actions.setLoading(false);
  }
}


export function* customerTransferReservationSaga() {
  yield takeLatest(actions.loadCustomerTransferReservations.type, loadCustomerTransferReservations);
  yield takeLatest(actions.loadCustomerTransferReservationsByDestifyUserId.type, loadCustomerTransferReservationsByDestifyUserId);
}

