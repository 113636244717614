import React, { } from 'react';
import { Route } from 'react-router-dom';

// Layout
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

import Venue from './Venue';
import Create from './Venue/create';
import Edit from './Venue/edit';

import WeddingPackage from './WeddingPackage';
import PackageCreate from './WeddingPackage/create';

import Destination from './Destination';
import CreateDestination from './Destination/create';
import EditDestination from './Destination/edit';

import SubDestination from './SubDestination';
import CreateSubDestination from './SubDestination/create';
import EditSubDestination from './SubDestination/edit';

import Resort from './Resort';
import CreateResort from './Resort/create';

function ManageWedding() {
  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Route path="/wedding/venue" component={ Venue } />
            <Route path="/wedding/create-venue" component={ Create } />
            <Route path="/wedding/edit-venue/:id" component={ Edit } />

            <Route path="/wedding/package" component={ WeddingPackage } />
            <Route path="/wedding/create-package" component={ PackageCreate } />
            <Route path="/wedding/edit-package/:id" component={ PackageCreate } />

            <Route path="/wedding/destination" component={ Destination } />
            <Route
              path="/wedding/create-destination"
              component={ CreateDestination }
            />
            <Route
              path="/wedding/edit-destination/:id"
              component={ EditDestination }
            />

            <Route path="/wedding/subdestination" component={ SubDestination } />
            <Route
              path="/wedding/create-subdestination"
              component={ CreateSubDestination }
            />
            <Route
              path="/wedding/edit-subdestination/:id"
              component={ EditSubDestination }
            />

            <Route path="/wedding/resort" component={ Resort } />
            <Route path="/wedding/create-resort" component={ CreateResort } />
            <Route path="/wedding/edit-resort/:id" component={ CreateResort } />
          </div>
          <AppFooter />
        </div>
      </div>
    </>
  )
}

export default ManageWedding;
