import React from 'react'

import { useAppSelector } from 'store/hooks';
import Select,{Option} from "common/Select"

import { selectClosedReasons } from '../../../slice/selectors';

interface Props {
    handleChange: (item:Option) => void,
    value: Option
}

const _ClosedReasons:React.FC<Props> = ({handleChange,value}) => {
  const {list,loading} = useAppSelector(selectClosedReasons);

  return (
    <>
        <Select
        initialValue={value}
        extractor={({reason,salesLeadClosedReasonID}) => ({label:reason,value:salesLeadClosedReasonID})}
        items={list}
        onChange={handleChange}
        disabled={loading}
        />
    </>
  )
}

export default _ClosedReasons