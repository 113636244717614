import React, { useEffect, useState } from 'react';
import {
  Button,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col
} from 'reactstrap';

import { toast } from 'react-toastify';
import { TransitionGroup } from 'react-transition-group';

import { useHistory } from 'react-router-dom';
import usersApi from 'api/user';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import * as Yup from 'yup';
import { TextField } from 'common/Form/TextInput';
import SelectField from 'common/Form/Select';
import { Formik, Form } from 'formik';

import { BackButton } from "./components"
import schema from "./yup_schema"

const validationSchema = Yup.object({
  firstName: schema.firstName(),
  lastName: schema.lastName(),
  email: schema.email(),
  phoneNumber: schema.phoneNumber(),
  selectedRoles: schema.selectedRoles(),
  calendly: schema.calendly(),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  selectedRoles: null,
};

const RegisterAdminUser = () => {
  const [availableRoles, setAvailableRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const res = await usersApi.authUsers.listAllAdminRoles();
        setAvailableRoles(res);
        setLoading(false);
      } catch (error) {
        setError(`Unexpected error: Server error`);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleSubmit = async values => {
    setLoading(true);
    try {
      const payload = {
        firstname: values.firstName,
        lastname: values.lastName,
        phonenumber: values.phoneNumber,
        email: values.email,
        isactive: true,
        checkedroles: [values.selectedRoles.value],
        canlendlyLink: values.calendly,
      };
      const createUserRequest = await usersApi.authUsers.registerUser(payload);
      if (createUserRequest.success) {
        toast.success(createUserRequest.message);
        history.push('/user/admin-list');
      } else {
        toast.error(
          `Unable to create user due to: ${
            createUserRequest.message || 'unknown error'
          }`,
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (error)
    return (
      <div className="mt-3 mb-1">
        <div
          className="invalid-tooltip mt-2"
          style={{ display: 'inline', position: 'initial' }}
        >
          {error}
        </div>
      </div>
    );

  return (
    <TransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <Container fluid>
        <div>
          <PageTitle
            heading="New Admin User Registration"
            subheading="Create a new internal Destify User"
            icon="pe-7s-user icon-gradient bg-tempting-azure"
          />
        </div>

        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>User Details</CardTitle>

            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              enableReinitialize
              validationSchema={validationSchema}
            >
              {() => {
                return (
                  <Form>
                    <Row>
                      <Col xs={12} md={6}>
                    <FormGroup>
                      <TextField name="firstName" label={'First Name *'} />
                    </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                    <FormGroup>
                      <TextField name="lastName" label={'Last Name *'} />
                    </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                    <FormGroup>
                      <TextField name={'email'} label={'Email *'} />
                    </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                    <FormGroup>
                      <TextField name="phoneNumber" label={'Phone Number'} />
                    </FormGroup>
                    </Col>
                    </Row>
                    <FormGroup>
                      <TextField name="calendly" label={'Calendly'} />
                    </FormGroup>

                    <FormGroup>
                      <SelectField
                        name="selectedRoles"
                        label={'Assigned Roles *'}
                        items={availableRoles}
                        extractor={item => ({
                          label: item.normalizedName,
                          value: item.id,
                        })}
                        autoClose
                        disabled={loading}
                      />
                    </FormGroup>
                    <hr />
                    <p>&nbsp;</p>
                    <Button disabled={loading} color="primary" className="mt-1">
                      {!loading ? (
                        <span>Create User</span>
                      ) : (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span>Please wait...</span>
                        </>
                      )}
                    </Button>

                    <BackButton />
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
      </Container>
    </TransitionGroup>
  );
};
export default RegisterAdminUser;
