import axios from 'axios';
import { AppConfig } from 'config';
import { axiosFailedResponsesHandler } from '../axiosFailedResponsesHandler';
import { OData } from "@odata/client";
import { LegacyLiveBookingODataEntities } from './LegacyLiveBooking/ODataEntities';
import { ODataBaseModel } from './LegacyLiveBooking/ODataModels/ODataBaseModel';
import { IBaseODataApiClient } from './IBaseODataApiClient';
import { escapeSpecialChars } from 'utils/utils';


const legacyLiveBookingApiAxiosClient = axios.create();
legacyLiveBookingApiAxiosClient.defaults.baseURL = AppConfig.Api.LegacyLiveBookingApi.BaseUrl + '/odata/';
legacyLiveBookingApiAxiosClient.interceptors.request.use(config => {
  const newConfig = { ...config };
  const apiToken = AppConfig.Api.LegacyLiveBookingApi.ApiToken;
  if (apiToken) {
    newConfig.headers.Authorization = `Token ${apiToken}`;
  }
  return newConfig;
});
legacyLiveBookingApiAxiosClient.interceptors.response.use(null, axiosFailedResponsesHandler);

//https://github.com/Soontao/light-odata
const odataBatchClient = OData.New({
  serviceEndpoint: legacyLiveBookingApiAxiosClient.defaults.baseURL,
  commonHeaders: {
    'Authorization': 'Token ' + AppConfig.Api.LegacyLiveBookingApi.ApiToken,
    'Accept': 'application/json'
  }
});



export class LegacyLiveBookingODataApiClient implements IBaseODataApiClient {
  async getODataListResponseWithCount<T>(url: string = '') {
    const { data: { value, '@odata.count': count } } = await legacyLiveBookingApiAxiosClient.get<{ value: T[]; '@odata.count': number }>(escapeSpecialChars(url));    
    return {
      data: value,
      count
    }
  }

  async getData<T>(url: string = '') {
    const { data: { value } } = await legacyLiveBookingApiAxiosClient.get<{ value: T }>(url);
    return value;
  }

  /**
   * use this to do a $batch request to the odata endpoint, targetting a specific entity collection
   * in the case where the URL would get too long, with a normal GET request
   * @param entityCollection 
   * @param filter 
   * @returns 
   */
  async getBatchData<T extends ODataBaseModel>(entityCollection: LegacyLiveBookingODataEntities,
    filter: string) {
    const batchResponses = await odataBatchClient.execBatchRequestsJson([
      odataBatchClient.newBatchRequest({
        collection: entityCollection,
        params: OData.newOptions().filter(filter),
        method: "GET",
      }),
    ]);

    //check for a successful status code
    //todo: improve me
    if ((batchResponses[0].status < 200) || (batchResponses[0].status > 299)) {
      throw new Error('Non-successful response while invoking Legacy Live Booking OData Endpoint');
    }
    const rawJsonResponse = await batchResponses[0].json();
    return rawJsonResponse['value'] as T;
  }


};

